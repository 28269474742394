import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { DirectedOffersUpdateAction, Types } from './duck';
import * as DirectedOfferService from '../../_core/directedOffers/service';
import { actions } from '../../_core/directedOffers';
import { alertRequest } from '../../alert/actions';
import { actions as actionsLoading } from '../loading';
import { DirectedOffers, VolOffer } from '~/domain/model';
import { actions as actionsList } from '../directedOffersList';
import { getWaitEditOpen } from '../directedOffersList/selectors';
import * as VolOfferService from '~/services/producer/VolOffer';
import { generateErrorMessage } from '~/helpers/util/error';

export function* create(action: PayloadAction<DirectedOffers>): any {
  try {
    yield put(
      actionsLoading.setLoading({
        status: 'fetching'
      })
    );
    const offers = action.payload.offers.map((offer, i) => {
      return {
        index: i + 1,
        volOfferId: offer.volOfferId ?? 0,
        volOfferTypeId: offer.volOfferTypeId ?? 4,
        stockId: offer.stockId,
        parentOfferId: offer.parentOfferId,
        exclusiveQuantity: offer.exclusiveQuantity,
        status: offer.status,
        startDate: offer.startDate,
        endDate: offer.endDate,
        priceByPacking: offer.priceByPacking,
        priceByLayer: offer.priceByLayer,
        priceByTrolley: offer.priceByTrolley,
        voucherIds: offer.voucherIds,
        packingDistributionCompositionIds:
          offer.packingDistributionCompositionIds,
        siteDeliveryPatternId: offer.siteDeliveryPatternId,
        observation: offer.observation,
        minimumQuantity:
          offer.minimumQuantity && offer.minimumQuantity > 0
            ? offer.minimumQuantity
            : null,
        specialPrice:
          offer.specialPrice && offer.specialPrice > 0
            ? offer.specialPrice
            : null,
        volDirectedOfferId: offer.volDirectedOfferId,
        fromMassUpdate: offer.fromMassUpdate
      };
    });
    const customers = action.payload.customers.map((customer, i) => {
      const { customerId, customerGroupId } = customer;
      return {
        index: i + 1,
        customerId: customerId ?? 0,
        customerGroupId: customerGroupId ?? 0
      };
    });
    const arg = {
      id: action.payload.id,
      offers,
      customers
    };
    yield call(DirectedOfferService.create, arg);
    yield put(
      actionsLoading.setLoading({
        status: 'idle'
      })
    );
    yield put(alertRequest('Ofertas salvas com sucesso!', 'success'));
    yield put(actionsLoading.setSubmitForm(true));
  } catch (err: any) {
    console.log('err', err);
    yield put(
      actionsLoading.setLoading({
        status: 'idle'
      })
    );
    let message = '';
    err.response.data.Errors.Messages.forEach((errorMessage: string) => {
      message += `${errorMessage}<br /> `;
    });
    yield put(alertRequest(message, 'error'));
  }
}
export function* updateDirectedVolOffers(
  action: PayloadAction<VolOffer[]>
): any {
  try {
    const directedVolOffers = action.payload.map(offer => {
      return {
        stockId: offer.stockId,
        volOfferId: offer.volOfferId,
        startDate: offer.startDate,
        endDate: offer.endDate,
        siteDeliveryPatternId: offer.siteDeliveryPatternId,
        exclusiveQuantity: offer.exclusiveQuantity,
        priceByPacking: offer.priceByPacking,
        priceByLayer: offer.priceByLayer,
        priceByTrolley: offer.priceByTrolley,
        minimumQuantity:
          offer.minimumQuantity && offer.minimumQuantity > 0
            ? offer.minimumQuantity
            : null,
        specialPrice:
          offer.specialPrice && offer.specialPrice > 0
            ? offer.specialPrice
            : null,
        status: offer.status,
        observation: offer.observation,
        index: offer.index,
        volDirectedOfferId: offer.volDirectedOfferId,
        volOfferTypeId: offer.volOfferTypeId,
        fromMassUpdate: offer.fromMassUpdate,
        packingDistributionCompositionIds:
          offer.packingDistributionCompositionIds
      };
    });
    yield put(
      actionsLoading.setLoading({
        status: 'fetching'
      })
    );
    yield call(DirectedOfferService.updateDirectedVolOffers, directedVolOffers);
    yield put(
      actionsLoading.setLoading({
        status: 'idle'
      })
    );
    yield put(alertRequest('Ofertas salvas com sucesso!', 'success'));
    const waitStatus = yield select(getWaitEditOpen);
    if (waitStatus === 'waiting') {
      yield put(actionsList.setWaitEditOpen('openEdit'));
    }
    if (waitStatus === 'waitingCloseTab') {
      yield put(actionsList.setWaitEditOpen('closeTab'));
    }
    yield put(actionsLoading.setCloseModal(true));
  } catch (err: any) {
    yield put(
      actionsLoading.setLoading({
        status: 'idle'
      })
    );
    let message = '';
    err.response.data.errors.messages.forEach((errorMessage: string) => {
      message += `${errorMessage}<br /> `;
    });
    yield put(alertRequest(message, 'error'));
  }
}
export function* changeStatus(action: PayloadAction<VolOffer>): any {
  try {
    yield put(
      actionsLoading.setLoading({
        status: 'fetching'
      })
    );
    yield call(VolOfferService.changeStatus, action.payload.volOfferId);
    yield put(
      actionsLoading.setLoading({
        status: 'idle'
      })
    );
    yield put(actions.updateVolOffersLine([action.payload]));
    yield put(alertRequest('Oferta atualizada com sucesso!', 'success'));
    const waitStatus = yield select(getWaitEditOpen);
    if (waitStatus === 'waiting') {
      yield put(actionsList.setWaitEditOpen('openEdit'));
    }
    if (waitStatus === 'waitingCloseTab') {
      yield put(actionsList.setWaitEditOpen('closeTab'));
    }
    yield put(actionsLoading.setCloseModal(true));
  } catch (err: any) {
    yield put(
      actionsLoading.setLoading({
        status: 'idle'
      })
    );
    const errorMessage = generateErrorMessage(
      err,
      'Erro ao atualizar ofertas.'
    );
    yield put(alertRequest(errorMessage, 'error'));
  }
}
export function* update(action: PayloadAction<DirectedOffersUpdateAction>) {
  try {
    yield put(
      actionsLoading.setLoading({
        status: 'fetching'
      })
    );
    yield call(DirectedOfferService.update, action.payload);
    yield put(alertRequest('Ofertas atualizadas com sucesso!', 'success'));
    yield put(actionsLoading.setSubmitForm(true));
    yield put(
      actionsLoading.setLoading({
        status: 'idle'
      })
    );
    yield put(actionsLoading.setCloseModal(true));
  } catch (err: any) {
    const errorMessage = generateErrorMessage(
      err,
      'Erro ao atualizar ofertas.'
    );
    yield put(alertRequest(errorMessage, 'error'));
    yield put(
      actionsLoading.setLoading({
        status: 'idle'
      })
    );
  }
}

export function* deleteSubOffer(action: PayloadAction<number>) {
  try {
    yield put(
      actionsLoading.setLoading({
        status: 'fetching'
      })
    );
    yield call(DirectedOfferService.deleteSubOffer, action.payload);
    yield put(alertRequest('Oferta excluída com sucesso', 'success'));
    yield put(
      actionsLoading.setLoading({
        status: 'idle'
      })
    );
    yield put(actionsLoading.setSubmitForm(true));
  } catch (err: any) {
    yield put(alertRequest(err.message, 'error'));
    yield put(
      actionsLoading.setLoading({
        status: 'idle'
      })
    );
  }
}
export default [
  takeLatest(Types.create, create),
  takeLatest(Types.changeStatus, changeStatus),
  takeLatest(Types.update, update),
  takeLatest(Types.updateDirectedVolOffers, updateDirectedVolOffers),
  takeLatest(Types.deleteSubOffer, deleteSubOffer)
];

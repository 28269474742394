import IUseCase from '~/@core/application/IUseCase';
import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';
import FilterData from '~/@core/domain/filters/rfidReports/FilterData';
import NonConformity from '~/@core/domain/model/rfidReports/NonConformity';
import { ReceiptService } from '~/@core/infrastructure/api/services/rfidReports';

export default class GetDones
  implements IUseCase<FilterData, Promise<DefaultPaginated<NonConformity[]>>>
{
  execute(filter: FilterData): Promise<DefaultPaginated<NonConformity[]>> {
    return ReceiptService.fetchDones(filter);
  }
}

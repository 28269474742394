import * as API from '~/domain/api';

export default class Voucher {
  private json: API.Voucher;
  public readonly id: number;
  public readonly description: string;
  public readonly reajustmentAmount: number;
  public readonly reajustmentType: string;
  public readonly quantityUnitMinimun: number;
  public readonly quantityType: string;
  public readonly observation: string;
  public readonly isActive: boolean;
  public readonly customerGroupId: number;
  public readonly customerGroup: string;

  constructor(json: API.Voucher) {
    this.json = json;
    this.id = this.getId();
    this.description = this.getDescription();
    this.reajustmentAmount = this.getReajustmentAmount();
    this.reajustmentType = this.getReajustmentType();
    this.quantityUnitMinimun = this.getQuantityUnitMinimun();
    this.quantityType = this.getQuantityType();
    this.observation = this.getObservation();
    this.customerGroupId = this.getCustomerGroupId();
    this.customerGroup = this.getCustomerGroup();
    this.isActive = this.isActiveValue();
  }

  private getId(): number {
    return this.json.id ?? Number.NaN;
  }

  private getReajustmentAmount(): number {
    return this.json.reajustmentAmount ?? Number.NaN;
  }

  private getCustomerGroupId(): number {
    return this.json.customerGroupId ?? Number.NaN;
  }

  private getCustomerGroup(): string {
    return this.json.customerGroup ?? '';
  }

  private getQuantityUnitMinimun(): number {
    return this.json.quantityUnitMinimun ?? Number.NaN;
  }

  private getQuantityType(): string {
    return this.json.quantityType ?? '';
  }

  private getDescription(): string {
    return this.json.description ?? '';
  }

  private getReajustmentType(): string {
    return this.json.reajustmentType ?? '';
  }

  private getObservation(): string {
    return this.json.observation ?? '';
  }

  public getStatus(): string {
    return this.isActive ? 'ATIVO' : 'INATIVO';
  }

  public isActiveValue(): boolean {
    return this.json.isActive ?? false;
  }
}

import IUseCase from '../../../IUseCase';
import { ProductService } from '~/@core/infrastructure/api/services';
import ProductGroup from '~/@core/domain/model/product/ProductGroup';

export default class GetProductsGroups
  implements IUseCase<number, Promise<ProductGroup[]>>
{
  execute(siteId: number): Promise<ProductGroup[]> {
    return ProductService.fetchProductGroups(siteId);
  }
}

import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ReactHtmlParser from 'react-html-parser';
import { alertClose, alertTimeout } from '~/store/modules/alert/actions';

import { Container, Message } from './styles';

export default function Alert(props: any) {
  const dispatch = useDispatch();

  const { timeout } = props;

  let timeToClose = timeout;

  if (!timeToClose || Number(timeToClose) <= 0) timeToClose = 5000;

  const type = useSelector((store: any) => store.alert.type);
  const message = useSelector((store: any) => store.alert.message);
  const showIcon = useSelector((store: any) => store.alert.showIcon);
  const _timeout = useSelector((store: any) => store.alert.timeout);
  const show = useSelector((store: any) => store.alert.show);

  const alertData = useMemo(
    () => ({
      message,
      type,
      showIcon,
      show,
      timeout: _timeout
    }),
    [_timeout, message, show, showIcon, type]
  );

  useEffect(() => {
    dispatch(alertTimeout(timeToClose));
  }, [dispatch, timeToClose]);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        dispatch(alertClose());
      }, _timeout);
    }
  }, [_timeout, dispatch, show]);

  return (
    <Container
      className={alertData.show ? 'active' : ''}
      onClick={() => dispatch(alertClose())}
    >
      {Array.isArray(alertData.message) &&
        alertData.message.map(msg => (
          <Message
            message={ReactHtmlParser(msg)}
            type={msg.includes('sucesso') ? 'success' : alertData.type}
            showIcon={alertData.showIcon}
          />
        ))}
      {!Array.isArray(alertData.message) && (
        <Message
          message={ReactHtmlParser(alertData.message)}
          type={alertData.type}
          showIcon={alertData.showIcon}
        />
      )}
    </Container>
  );
}

import { Form } from 'antd';
import ExpeditionWrapper from '~/components/organisms/RfidReports/ExpeditionWrapper';

export default function ExpeditionReport() {
  const [filterFormDone] = Form.useForm();
  const [filterFormNonConformity] = Form.useForm();
  const [filterFormNonConformityGeral] = Form.useForm();
  const [filterFormTransit] = Form.useForm();
  const [filterFormVision] = Form.useForm();

  return (
    <ExpeditionWrapper
      filterFormDone={filterFormDone}
      filterFormNonConformity={filterFormNonConformity}
      filterFormNonConformityGeral={filterFormNonConformityGeral}
      filterFormTransit={filterFormTransit}
      filterFormVision={filterFormVision}
    />
  );
}

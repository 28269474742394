import * as API from '~/domain/api';

export default class LinkSite {
  private json: API.LinkSite;

  public readonly id: number;

  public readonly description: string;

  public readonly url: string;

  public readonly isActive: boolean;

  public readonly linkSiteSites: API.LinkSiteSites[];

  constructor(json: API.LinkSite) {
    this.json = json;
    this.id = this.getId();
    this.description = this.getDescription();
    this.url = this.getUrl();
    this.isActive = this.getIsActive();
    this.linkSiteSites = this.getLinkSiteSites();
  }

  private getId(): number {
    return this.json.id ?? Number.NaN;
  }

  private getDescription(): string {
    return this.json.description ?? '';
  }

  private getUrl(): string {
    return this.json.url ?? '';
  }

  private getIsActive(): boolean {
    return this.json.isActive ?? false;
  }

  private getLinkSiteSites(): API.LinkSiteSites[] {
    return this.json.linkSiteSites ?? [];
  }
}

import IUseCase from '~/@core/application/IUseCase';
import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';
import FilterData from '~/@core/domain/filters/rfidReports/FilterData';
import Frequency from '~/@core/domain/model/rfidReports/Frequency';
import { InventoryService } from '~/@core/infrastructure/api/services/rfidReports';

export default class GetFrequencies
  implements IUseCase<FilterData, Promise<DefaultPaginated<Frequency[]>>>
{
  execute(filter: FilterData): Promise<DefaultPaginated<Frequency[]>> {
    return InventoryService.fetchFrequency(filter);
  }
}

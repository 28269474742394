import { forwardRef } from 'react';
import IGfp from '~/@core/domain/model/gfp/IGfp';
import FooterPrintLayout from './FooterPrintLayout/FooterPrintLayout';
import HeaderPrintLayout from './HeaderPrintLayout/HeaderPrintLayout';
import * as S from './styles';
import TablePrintLayout from './TablePrintLayout/TablePrintLayout';

interface IGenerateGFPProps {
  show: boolean;
  setShowGenerateGFP: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRowKeys: React.Key[];
  gfp: IGfp[];
}

const GenerateGFP = forwardRef<HTMLDivElement, IGenerateGFPProps>(
  ({ gfp }, ref) => {
    return (
      <S.ExternalContainer ref={ref} className="print-container">
        {gfp.map((gfpItem, index) => (
          <S.Container key={index}>
            <HeaderPrintLayout gfp={gfpItem} />
            <TablePrintLayout gfp={gfpItem} />
            {gfpItem.status === 'C' && (
              <S.CancelledMessage>GFP-e CANCELADA</S.CancelledMessage>
            )}
            <FooterPrintLayout gfp={gfpItem} />
          </S.Container>
        ))}
      </S.ExternalContainer>
    );
  }
);

export default GenerateGFP;

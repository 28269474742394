import { createSelector } from 'reselect';
import { PropertyThunkState } from '.';

export const getRoot = (state: any) => {
  return state.propertyThunk as PropertyThunkState;
};
export const getProperties = createSelector(
  [getRoot],
  state => state.properties
);

export const getFilters = createSelector([getRoot], state => state.filter);

export const getDownload = createSelector([getRoot], state => state.download);

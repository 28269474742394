import {
  AreaChartOutlined,
  ClusterOutlined,
  DashboardOutlined,
  FormOutlined,
  LineChartOutlined,
  OrderedListOutlined,
  ScheduleOutlined,
  ShopOutlined,
  SolutionOutlined
} from '@ant-design/icons';
import React, { lazy } from 'react';
import { PermissionNames } from '~/constants/permissionNames';
import store from '~/store';
import { actions } from '~/store/modules';
import { deleteTab } from '~/store/modules/tabs/actions';
import GFP from '../organisms/GFP';
import ExpeditionReport from '../pages/RfidReports/ExpeditionReport';
import Inventory from '../pages/RfidReports/Inventory';
import Movement from '../pages/RfidReports/Movement';
import Receipt from '../pages/RfidReports/Receipt';

const Property = lazy(() => import('~/components/pages/Property'));
const ProductionPlanning = lazy(
  () => import('../templates/ProductionPlanning')
);
const Packing = lazy(() => import('../pages/Packing'));
const CompetenceRegistration = lazy(
  () => import('../pages/CompetenceRegistration')
);
const BlackoutDates = lazy(() => import('../pages/BlackoutDates'));
const Dashboard = lazy(() => import('~/components/pages/Dashboard'));
const Banner = lazy(() => import('../pages/Banner'));
const BiDashboard = lazy(() => import('../pages/BIDashboard'));
const VouchersRegistration = lazy(
  () => import('../pages/VouchersRegistration')
);
const VolLkp = lazy(() => import('../pages/VolLkp'));
const SalesReport = lazy(() => import('../pages/SalesReport'));
const SalesReportEcommerce = lazy(
  () => import('../pages/SalesReportEcommerce')
);
const ProducerPage = lazy(() => import('../pages/ProducerPage/index'));
const ProducerPageManagement = lazy(
  () => import('../pages/ProducerManagement/index')
);
const Stock = lazy(() => import('../templates/Stock'));
const Expedition = lazy(() => import('../templates/Expedition'));
const RegisterAnnoucement = lazy(
  () => import('../pages/RegisterAnnouncement/index')
);
const AnnouncementReport = lazy(() => import('../pages/AnnouncementReports'));
const EcommerceManagement = lazy(
  () => import('../templates/EcommerceManagement')
);
const KeyLiberation = lazy(() => import('../templates/KeyLiberation'));
const PLP = lazy(() => import('../templates/PLP'));
const Preferences = lazy(() => import('../templates/Preferences'));
const ShippingCompany = lazy(() => import('../templates/ShippingCompany'));
const AutoService = lazy(() => import('../templates/AutoService'));
const Announcements = lazy(() => import('../pages/Announcements'));
const CommercialManagement = lazy(
  () => import('../pages/CommercialManagement')
);
const Orders = lazy(() => import('../templates/Orders'));
const OrdersGfp = lazy(() => import('../templates/OrdersGfp'));
const OrdersToCollect = lazy(() => import('../pages/OrdersToCollect'));
const TagPrintingList = lazy(() => import('../organisms/TagPrintingList'));
const TechnicalSpecification = lazy(
  () => import('../templates/TechnicalSpecification')
);
const ProductBarCode = lazy(() => import('../organisms/ProductBarCode'));
const ProductionPlanningReplicate = lazy(
  () => import('../pages/ProductionPlanReplicate')
);
const PriceReport = lazy(
  () => import('~/components/pages/PriceReport/PriceReport')
);
const KlokDrawReport = lazy(
  () => import('~/components/pages/KlokDrawReport/KlokDrawReport')
);
const KlokStockReport = lazy(
  () => import('~/components/pages/KlokStockReport')
);
const ManageSiteDecorator = lazy(
  () => import('~/components/pages/ManageSiteDecorator')
);
const ProductRegistrationRequest = lazy(
  () => import('~/components/pages/ProductRegistrationRequest')
);
const TagCart = lazy(() => import('~/components/pages/TagCart'));
const AcceptanceTerms = lazy(
  () => import('~/components/pages/AcceptanceTerms')
);
const Authorization = lazy(() => import('~/components/pages/Authorization'));
const ProductCatalog = lazy(() => import('~/components/pages/ProductCatalog'));
const CustomerCatalog = lazy(
  () => import('~/components/pages/CustomerCatalog')
);
const Agreements = lazy(() => import('~/components/pages/Agreements'));
const WithheldGfp = lazy(
  () => import('~/components/pages/WithheldGfp')
);

export type MenuType = {
  key: string;
  icon?: React.ReactNode;
  title: string;
  permissionName?: string;
  MenuItems?: MenuType[];
  component?: any;
  onCloseAction?: () => void;
  name?: any;
  cantClose?: boolean;
};

export const menus: MenuType[] = [
  {
    key: 'dashboard',
    icon: <DashboardOutlined />,
    title: 'Dashboard',
    name: 'Dashboard',
    permissionName: PermissionNames.MenuDashboard,
    component: () => <Dashboard />
  },
  {
    key: 'registers',
    icon: <FormOutlined />,
    title: 'menu.registers',
    permissionName: PermissionNames.Registers.MenuRegisters,
    MenuItems: [
      {
        key: 'propriedade',
        name: 'Cadastro de Propriedade',
        component: () => <Property />,
        title: 'menu.registerProperty',
        permissionName: PermissionNames.Registers.MenuProperty
      },
      {
        key: 'planejamento-producao',
        name: 'Planejamento de produção',
        component: () => <ProductionPlanning />,
        title: 'menu.productionPlan',
        permissionName: PermissionNames.Registers.MenuProductionPlanning
      },
      {
        key: 'packing',
        name: 'Cadastro de embalagens',
        component: () => <Packing />,
        title: 'menu.registerPacking',
        permissionName: PermissionNames.Registers.MenuPacking
      },
      {
        key: 'productorPreferences',
        name: 'menu.productorPreferences.description',
        component: () => <Preferences />,
        title: 'menu.productorPreferences.register',
        permissionName: PermissionNames.Registers.MenuProductorPreferences
      },
      {
        key: 'stock',
        name: 'Estoque',
        component: () => <Stock />,
        title: 'menu.stock',
        permissionName: PermissionNames.Registers.MenuStock
      },
      {
        key: 'expedition',
        name: 'Cadastro de Expedição',
        component: () => <Expedition />,
        title: 'menu.expedition',
        permissionName: PermissionNames.Registers.MenuExpedition
      },
      {
        key: 'shippingCompany',
        name: 'Transportadora',
        component: () => <ShippingCompany />,
        title: 'menu.registerShippingCompany',
        permissionName: PermissionNames.Registers.MenuShippingCompany
      },
      {
        key: 'decorators',
        name: 'Gerenciar Decoradores',
        component: () => <ManageSiteDecorator />,
        title: 'menu.manageSiteDecorators',
        permissionName: PermissionNames.Registers.MenuSiteDecorators
      },
      {
        key: 'plp',
        name: 'menu.registerPLP',
        component: () => <PLP />,
        title: 'menu.registerPLP',
        permissionName: PermissionNames.Registers.MenuPLP
      }
    ]
  },
  {
    key: 'commercial',
    icon: <ShopOutlined />,
    title: 'Gestão Comercial',
    permissionName: PermissionNames.Commercial.MenuCommercial,
    MenuItems: [
      {
        key: 'autoservice',
        name: 'menu.autosservice',
        component: () => <AutoService />,
        title: 'menu.autosservice',
        permissionName: PermissionNames.Commercial.MenuAutoservice
      },
      {
        key: 'commercial-management',
        name: 'Gestão E-commerce',
        component: () => <EcommerceManagement />,
        title: 'Gestão E-commerce',
        permissionName: PermissionNames.Commercial.MenuCommercialManagement
      },
      {
        key: 'management-ecommerce',
        name: 'Gestão Comercial',
        component: () => <CommercialManagement />,
        title: 'Canais de oferta',
        permissionName: PermissionNames.Commercial.MenuManagementEcommerce
      },
      {
        key: 'backout-dates',
        name: 'Datas indisponíveis E-commerce',
        component: () => <BlackoutDates />,
        title: 'Datas indisponíveis E-commerce',
        permissionName: PermissionNames.Commercial.MenuBackoutDates
      },
      {
        key: 'key-management-ecommerce',
        name: 'Liberação de Chaves E-commerce',
        component: () => <KeyLiberation />,
        title: 'Liberação de Chaves E-commerce',
        permissionName: PermissionNames.Commercial.MenuKeyManagementEcommerce
      }
    ]
  },
  {
    key: 'announcements',
    icon: <OrderedListOutlined />,
    name: 'menu.announcements.announcements',
    title: 'menu.announcements.announcements',
    permissionName: PermissionNames.Announcements.MenuAnnouncements,
    MenuItems: [
      {
        key: 'announcementsList',
        name: 'menu.announcements.announcementsList',
        title: 'menu.announcements.announcementsList',
        permissionName: PermissionNames.Announcements.MenuAnnouncementsList,
        component: () => <Announcements />
      }
    ]
  },
  {
    key: 'quality',
    icon: <ScheduleOutlined />,
    title: 'Gestão de qualidade',
    permissionName: PermissionNames.Quality.MenuQuality,
    MenuItems: [
      {
        key: 'competence',
        name: 'Competência',
        component: () => <CompetenceRegistration />,
        title: 'Cadastro de competência',
        permissionName: PermissionNames.Quality.MenuCompetence
      },
      {
        key: 'technicalSpecification',
        name: 'Especificação Técnica',
        component: () => <TechnicalSpecification />,
        title: 'Especificação Técnica',
        permissionName: PermissionNames.Quality.MenuTechnicalSpecification
      },
      {
        key: 'productBarCode',
        name: 'Impressão Código de Barras Produtor',
        component: () => <ProductBarCode />,
        title: 'Impressão Código de Barras Produtor',
        permissionName: PermissionNames.Quality.MenuProductBarCode
      },
      {
        key: 'productionPlanningReplicate',
        name: 'Replicar Planejamento de Produção',
        component: () => <ProductionPlanningReplicate />,
        title: 'Replicar Planejamento de Produção',
        permissionName: PermissionNames.Quality.MenuProductionPlanningReplicate
      }
    ]
  },
  {
    key: 'gfp',
    icon: <ScheduleOutlined />,
    title: 'GFP',
    permissionName: PermissionNames.Gfp.MenuGFP,
    MenuItems: [
      {
        key: 'gfp2',
        name: 'GFP',
        component: () => <GFP />,
        title: 'GFP',
        permissionName: PermissionNames.Gfp.MenuGFP2
      },
      {
        key: 'acceptanceTerms',
        name: 'Termos de Aceite Eletrônico',
        component: () => <AcceptanceTerms />,
        title: 'Termos de Aceite Eletrônico',
        permissionName: PermissionNames.Gfp.MenuAcceptanceTerms
      },
      {
        key: 'tagCart',
        name: 'Montar Carrinhos',
        component: () => <TagCart />,
        title: 'Montar Carrinhos',
        permissionName: PermissionNames.Gfp.MenuTagCart
      },
      {
        key: 'authorization',
        name: 'Credenciamento',
        component: () => <Authorization />,
        title: 'Credenciamento',
        permissionName: PermissionNames.Gfp.MenuAuthorization
      }
    ]
  },
  {
    key: 'offers',
    icon: <OrderedListOutlined />,
    title: 'Ofertas',
    permissionName: PermissionNames.Offers.MenuOffers,
    MenuItems: [
      {
        key: 'vouchers',
        name: 'Vouchers',
        component: () => <VouchersRegistration />,
        title: 'Cadastro de vouchers',
        permissionName: PermissionNames.Offers.MenuVouchers
      },
      {
        key: 'volLkp',
        name: 'Cadastro de ofertas',
        component: () => <VolLkp />,
        title: 'Cadastro de ofertas',
        permissionName: PermissionNames.Offers.MenuVolLkp,
        cantClose: true,
        onCloseAction: () => {
          const state: any = store.getState();
          const disabledSaveVol = state.core.volOffers.disabledSave;
          const disabledSaveLkp = state.core.lkpOffers.disabledSave;

          if (!disabledSaveVol) {
            store.dispatch(actions.ui.volOfferList.setConfirmSave(true));
            store.dispatch(
              actions.ui.volOfferList.setWaitEditOpen('waitingCloseTab')
            );

            return;
          }
          if (!disabledSaveLkp) {
            store.dispatch(actions.ui.lkpOfferList.setConfirmSaveLkp(true));
            store.dispatch(
              actions.ui.volOfferList.setWaitEditOpen('waitingCloseTab')
            );

            return;
          }
          store.dispatch(deleteTab('volLkp'));
        }
      }
    ]
  },
  {
    key: 'marketing-management',
    icon: <SolutionOutlined />,
    title: 'Gestão de Marketing',
    permissionName: PermissionNames.MarketingManagement.MenuMarketingManagement,
    MenuItems: [
      {
        key: 'banner',
        name: 'Banner',
        component: () => <Banner />,
        title: 'Banner',
        permissionName: PermissionNames.MarketingManagement.MenuBanner
      },
      {
        key: 'registerAnnouncement',
        name: 'Cadastro de Comunicados',
        component: () => <RegisterAnnoucement />,
        title: 'Cadastro de Comunicados',
        permissionName:
          PermissionNames.MarketingManagement.MenuRegisterAnnouncement
      },
      {
        key: 'producerPage',
        name: 'Página do Produtor',
        component: () => <ProducerPage />,
        title: 'Página do Produtor',
        permissionName: PermissionNames.MarketingManagement.MenuProducerPage
      }
    ]
  },
  {
    key: 'producerManagement',
    icon: <AreaChartOutlined />,
    title: 'Gestão do Produtor',
    permissionName: PermissionNames.ProducerManagement.MenuProducerManagement,
    MenuItems: [
      {
        key: 'producerPageManagement',
        name: 'Página do Produtor',
        component: () => <ProducerPageManagement />,
        title: 'Página do Produtor',
        permissionName:
          PermissionNames.ProducerManagement.MenuProducerPageManagement
      },
      {
        key: 'ProductRegistrationRequest',
        name: 'Gerenciar Produtos',
        component: () => <ProductRegistrationRequest />,
        title: 'Gerenciar Produtos',
        permissionName:
          PermissionNames.ProducerManagement.MenuProductRegistrationRequest
      }
    ]
  },
  {
    key: 'reports',
    icon: <AreaChartOutlined />,
    title: 'Relatórios',
    permissionName: PermissionNames.Reports.MenuReports,
    MenuItems: [
      {
        key: 'offer-report',
        name: 'Relatório de vendas',
        component: () => <SalesReport />,
        title: 'Relatório de Vendas',
        permissionName: PermissionNames.Reports.MenuOfferReport
      },
      {
        key: 'offer-report-ecommerce',
        name: 'Relatório de vendas E-commerce',
        component: () => <SalesReportEcommerce />,
        title: 'Relatório de vendas E-commerce',
        permissionName: PermissionNames.Reports.MenuOfferReportEcommerce
      },
      {
        key: 'announcementReports',
        name: 'Relatório de Comunicados',
        component: () => <AnnouncementReport />,
        title: 'Relatório de Comunicados',
        permissionName: PermissionNames.Reports.MenuAnnouncementReports
      },
      {
        key: 'price-report',
        name: 'Relatório de Preços',
        component: () => <PriceReport />,
        title: 'Relatório de Preços',
        permissionName: PermissionNames.Reports.MenuPriceReport
      },
      {
        key: 'klok-draw-report',
        name: 'Relatório de Sorteio KLOK',
        component: () => <KlokDrawReport />,
        title: 'Relatório de Sorteio KLOK',
        permissionName: PermissionNames.Reports.MenuKlokDrawReport
      },
      {
        key: 'klok-stock-report',
        name: 'Relatório de Estoque KLOK',
        component: () => <KlokStockReport />,
        title: 'Relatório de Estoque KLOK',
        permissionName: PermissionNames.Reports.MenuDetailedStock
      },
      {
        key: 'agreement-report',
        name: 'Acordos e Termos Aditivos',
        component: () => <Agreements />,
        title: 'Acordos e Termos Aditivos',
        permissionName: PermissionNames.Reports.MenuAgreement
      },
      {
        key: 'product-catalog',
        name: 'Catalogo de Produtos',
        component: () => <ProductCatalog />,
        title: 'Catalogo de Produtos',
        permissionName: PermissionNames.Reports.MenuProductCatalog
      },
      {
        key: 'customer-catalog',
        name: 'Catálogo de Clientes',
        component: () => <CustomerCatalog />,
        title: 'Catálogo de Clientes',
        permissionName: PermissionNames.Reports.MenuCustomerCatalog
      },
      {
        key: 'withheld-gfp',
        name: 'GFP Retida',
        component: () => <WithheldGfp />,
        title: 'GFP Retida',
        permissionName: PermissionNames.Reports.MenuWithheldGfp
      },
    ]
  },
  {
    key: 'orders',
    icon: <OrderedListOutlined />,
    title: 'Pedidos',
    permissionName: PermissionNames.Orders.MenuOrder,
    MenuItems: [
      {
        key: 'order',
        name: 'Visualizar Pedidos',
        component: () => <Orders />,
        title: 'Visualizar Pedidos',
        permissionName: PermissionNames.Orders.MenuOrder
      },
      {
        key: 'ordergfp',
        name: 'Pedidos x GFP',
        component: () => <OrdersGfp />,
        title: 'Pedidos x GFP',
        permissionName: PermissionNames.Orders.MenuOrdersGfp
      },
      {
        key: 'ordersToCollect',
        name: 'Pedidos para Colher',
        component: () => <OrdersToCollect />,
        title: 'Pedidos para Colher',
        permissionName: PermissionNames.Orders.MenuOrdersToCollect
      },
      {
        key: 'tagPrintingList',
        name: 'Lista de Impressão Etiquetas',
        component: () => <TagPrintingList />,
        title: 'Lista de Impressão Etiquetas',
        permissionName: PermissionNames.Orders.MenuTagPrintingList
      }
    ]
  },
  {
    key: 'indicatorsPanel',
    icon: <LineChartOutlined />,
    title: 'Painel de Indicadores',
    permissionName: PermissionNames.MenuIndicatorsPanel,
    MenuItems: []
  },
  {
    key: 'tiManagement',
    icon: <LineChartOutlined />,
    title: 'Gestão TI',
    permissionName: PermissionNames.TiManagement.MenuTiManagement,
    MenuItems: [
      {
        key: 'BIdashboard',
        name: 'Cadastro de Dashboards',
        component: () => <BiDashboard />,
        title: 'menu.registerDashboard',
        permissionName: PermissionNames.TiManagement.MenuBIdashboard
      }
    ]
  },
  {
    key: 'rfid',
    icon: <ClusterOutlined />,
    title: 'Relatorios RFID',
    // permissionName: PermissionNames.MenuRfidReports,
    permissionName: PermissionNames.MenuIndicatorsPanel,
    MenuItems: [
      {
        key: 'inventory',
        name: 'Inventário',
        icon: <ClusterOutlined />,
        title: 'Inventário',
        permissionName: PermissionNames.MenuIndicatorsPanel,
        component: () => <Inventory />
      },
      {
        key: 'receipt',
        name: 'Recebimento',
        icon: <ClusterOutlined />,
        title: 'Recebimento',
        permissionName: PermissionNames.MenuIndicatorsPanel,
        component: () => <Receipt />
      },
      {
        key: 'expedition',
        name: 'Expedição',
        icon: <ClusterOutlined />,
        title: 'Expedição',
        permissionName: PermissionNames.MenuIndicatorsPanel,
        component: () => <ExpeditionReport />
      },
      {
        key: 'movement',
        name: 'Transferência',
        icon: <ClusterOutlined />,
        title: 'Transferência',
        permissionName: PermissionNames.MenuIndicatorsPanel,
        component: () => <Movement />
      }
    ]
  }
];
export default menus;

// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DefaultPagination } from '~/domain/model';

import * as SalesReportsCalls from './apiCalls';

export interface SalesReportState {
  colors: any;
  productsGrid: any;
  overview: any;
  filterExport: any;
  pagination: DefaultPagination;
}
const initialState: SalesReportState = {
  colors: [],
  productsGrid: [],
  overview: [],
  filterExport: undefined,
  pagination: { PageSize: 10 } as DefaultPagination
};

export const prefix = 'salesReportEcommerce';

const salesReportEcommerceSlice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setPreferencesColor: (state, action: PayloadAction<any>) => {
      state.colors = action.payload;
    },
    setPagination: (state, action: PayloadAction<DefaultPagination>) => {
      state.pagination = action.payload;
    },
    setFilterExport: (state, action: PayloadAction<any>) => {
      state.filterExport = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(
      SalesReportsCalls.fetchPreferenceColor.fulfilled,
      (state, action) => {
        const { data } = action.payload;
        state.colors = data;
      }
    );
    builder.addCase(
      SalesReportsCalls.fetchProductsReport.fulfilled,
      (state, action) => {
        const { data } = action.payload;
        state.productsGrid = data;
      }
    );
    builder.addCase(
      SalesReportsCalls.fetchOverview.fulfilled,
      (state, action) => {
        const { data } = action.payload;
        state.overview = data;
      }
    );
  }
});

export const actions = salesReportEcommerceSlice.actions;
export const reducer = salesReportEcommerceSlice.reducer;

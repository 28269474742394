import * as API from 'domain/api';

export default class SalesReportLkp {
  public readonly json: API.SalesReportLkp;
  public readonly lkpOfferId: number;
  public readonly productId: number;
  public readonly productLongDescription: string;
  public readonly qualityId: number;
  public readonly quality: string;
  public readonly quantitySold?: number;
  public readonly amount: number;
  public readonly lkpReportDetail: API.SalesReportLkpDetail[];

  constructor(json: API.SalesReportLkp) {
    this.json = json;
    this.lkpOfferId = json.lkpOfferId;
    this.productId = json.productId;
    this.productLongDescription = json.productLongDescription;
    this.qualityId = json.qualityId;
    this.quality = json.quality;
    this.quantitySold = json.quantitySold;
    this.amount = json.amount;
    this.lkpReportDetail = json.lkpReportDetail;
  }
}

import { AxiosResponse } from 'axios';
import api from '../api';
import {
  CompetenceRegistrationPostParam,
  CompetenceRegistrationGetParam,
  Create
} from '~/domain/model/CompetenceRegistrationParams';

export const findBlackoutDates = async (
  params: CompetenceRegistrationGetParam
) => {
  const description = params.description
    ? `&Data.Description=${params.description}`
    : '';
  const status = params.status ? `&Data.Status=${params.status}` : '';
  const response = await api.get<void, AxiosResponse<any>>(
    `/ecommerce-unavailable-date?PageNumber=1&PageSize=300&Data.Year=${params.year}${description}${status}`
  );
  return response.data.results;
};

export const updateDates = async (params: number) => {
  const response = await api.put<void, AxiosResponse<any>>(
    `/ecommerce-unavailable-date/status/${params}`
  );
  return response.data.results;
};

export const postDates = async (params: CompetenceRegistrationPostParam) => {
  const response = await api.post<void, AxiosResponse<any>>(
    `/ecommerce-unavailable-date`,
    params
  );
  return response.data.results;
};

export const addPostSpecialDate = async (params: Create) => {
  const response = await api.post<void, AxiosResponse<any>>(
    `/special-date`,
    params
  );
  return response.data;
};

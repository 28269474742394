import produce from 'immer';
import { PropertyLocal, PropertySection } from '~/interface';

interface StateType {
  locals: PropertyLocal[];
  sections: PropertySection[];
  registry: any;
  loading: boolean;
}

const INITIAL_STATE: StateType = {
  locals: [],
  sections: [],
  registry: {},
  loading: false
};

export default function propertyLocal(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case '@propertyLocal/LIST_REQUEST':
    case '@propertyLocal/READ_REQUEST':
    case '@propertyLocal/DELETE_REQUEST':
      return produce(state, draft => {
        draft.loading = true;
      });

    case '@propertyLocal/ADD_REQUEST':
      return produce(state, draft => {
        draft.loading = false;
        draft.locals.push(action.payload.data);
      });

    case '@propertyLocal/LIST_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.locals = action.payload.list;
      });

    case '@propertyLocal/UPDATE_AREA':
      return produce(state, draft => {
        draft.locals = draft.locals.map(local => {
          if (local.id === action.payload.data.id) {
            return {
              ...local,
              area: action.payload.data.area
            };
          }
          return local;
        });
      });

    case '@propertyLocal/UPDATE_AREA_UNITY':
      return produce(state, draft => {
        draft.locals = draft.locals.map(local => {
          if (local.id === action.payload.data.id) {
            return {
              ...local,
              areaUnitId: action.payload.data.areaUnitId
            };
          }
          return local;
        });
      });

    case '@propertyLocal/ADD_SUCCESS':
    case '@propertyLocal/DELETE_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
      });

    case '@propertyLocal/CLEAR_ALL':
      return produce(state, draft => {
        draft.loading = false;
        draft.registry = {};
        draft.locals = [];
        draft.sections = [];
      });

    case '@propertyLocal/LIST_FAILURE':
    case '@propertyLocal/ADD_FAILURE':
    case '@propertyLocal/READ_FAILURE':
    case '@propertyLocal/DELETE_FAILURE':
      return produce(state, draft => {
        draft.loading = false;
      });

    default:
      return state;
  }
}

import { FormInstance, Table, Tooltip } from 'antd';
import Materials from '~/@core/domain/model/rfidReports/Materials';
import Owned from '~/@core/domain/model/rfidReports/Owned';
import OwnedItem from '~/@core/domain/model/rfidReports/OwnedItem';
import { DividerFrame, Frame, StatusCircle } from '~/components/Globals';
import useOwnedStore from '~/zustand/rfidReports/inventory/owned-state';

interface Props {
  form: FormInstance<any>;
}
export default function TableInventoryOwned(props: Props) {
  const { owneds, ownedPagination, handleOwnedPagination } = useOwnedStore();
  const { form } = props;

  return (
    <Frame ghost accordion defaultActiveKey="1">
      <Frame.Panel
        header={
          <DividerFrame orientation="left">
            Ativos em Posse X Saldo
          </DividerFrame>
        }
        key="1"
      >
        <Table
          dataSource={owneds}
          pagination={{
            pageSize: ownedPagination.pageSize,
            current: ownedPagination.current,
            showSizeChanger: true,
            total: ownedPagination.total,
            onChange(page, pageSize) {
              handleOwnedPagination({
                ...ownedPagination,
                current: page,
                pageSize
              });
              form.submit();
            }
          }}
          expandable={{
            expandedRowRender: inventoryOwned => (
              <Table
                dataSource={inventoryOwned.items}
                expandable={{
                  expandedRowRender: inventoryOwnedItem => (
                    <Table dataSource={inventoryOwnedItem.items} bordered>
                      <Table.Column
                        dataIndex="owned"
                        render={(_, materials: Materials) =>
                          materials.owned !== '1' ? (
                            <Tooltip>
                              <StatusCircle color="#FA541C" />
                            </Tooltip>
                          ) : (
                            <Tooltip>
                              <StatusCircle color="#52C41A" />
                            </Tooltip>
                          )
                        }
                        title="Posse"
                      />
                      <Table.Column
                        dataIndex="saldo"
                        render={(_, materials: Materials) =>
                          materials.balance !== '1' ? (
                            <Tooltip>
                              <StatusCircle color="#FA541C" />
                            </Tooltip>
                          ) : (
                            <Tooltip>
                              <StatusCircle color="#52C41A" />
                            </Tooltip>
                          )
                        }
                        title="Saldo"
                      />
                      <Table.Column
                        dataIndex="productCode"
                        title="Serial do Carrinho"
                      />
                      <Table.Column dataIndex="area" title="Area" />
                    </Table>
                  )
                }}
                bordered
              >
                <Table.Column
                  dataIndex="status"
                  render={(_, inventoryOwnedAcessory: Owned) =>
                    inventoryOwnedAcessory.status !== '2' ? (
                      <Tooltip>
                        <StatusCircle color="#FA541C" />
                      </Tooltip>
                    ) : (
                      <Tooltip>
                        <StatusCircle color="#52C41A" />
                      </Tooltip>
                    )
                  }
                  title="Status"
                />
                <Table.Column dataIndex="productCode" title="Código Material" />
                <Table.Column
                  dataIndex="modificationDate"
                  title="Data Inventário"
                />
                <Table.Column dataIndex="type" title="Tipo Material" />
                <Table.Column
                  dataIndex="areas"
                  key="areas"
                  title="Áreas"
                  align="center"
                  render={(_, inventoryOwnedItem: OwnedItem) => {
                    return inventoryOwnedItem.areas.map(item => (
                      <span style={{ marginLeft: '40px' }}>
                        {' '}
                        <tr>
                          <b>{item.name}</b>
                        </tr>
                        <tr>{`${item.quantity} / ${item.expected}`}</tr>
                      </span>
                    ));
                  }}
                />
                <Table.Column dataIndex="total" title="Total" />
                <Table.Column dataIndex="balance" title="Saldo" />
                <Table.Column
                  dataIndex="nonConformity"
                  title="Inconformidade"
                />
              </Table>
            )
          }}
        >
          <Table.Column dataIndex="modificationDate" title="Data Inventário" />

          <Table.Column dataIndex="type" title="Tipo Material" />
          <Table.Column
            dataIndex="numberCart"
            title="Carrinho Resultado/Esperado"
          />
          <Table.Column dataIndex="numberMC" title="MC Resultado/Esperado" />
          <Table.Column dataIndex="nonConformity" title="Informodidade" />
        </Table>
      </Frame.Panel>
    </Frame>
  );
}

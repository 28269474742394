export function productionAreaUnitListRequest() {
  return {
    type: '@productionAreaUnit/LIST_REQUEST'
  };
}

export function productionAreaUnitListSuccess(list: any) {
  return {
    type: '@productionAreaUnit/LIST_SUCCESS',
    payload: { list }
  };
}

export function productionAreaUnitListFailure() {
  return {
    type: '@productionAreaUnit/LIST_FAILURE'
  };
}

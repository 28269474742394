import IUseCase from '~/@core/application/IUseCase';
import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';
import FilterData from '~/@core/domain/filters/rfidReports/FilterData';
import ExpeditionDone from '~/@core/domain/model/rfidReports/ExpeditionDone';
import { ExpeditionReportService } from '~/@core/infrastructure/api/services/rfidReports';

export default class GetVisions
  implements IUseCase<FilterData, Promise<DefaultPaginated<ExpeditionDone[]>>>
{
  execute(filter: FilterData): Promise<DefaultPaginated<ExpeditionDone[]>> {
    return ExpeditionReportService.fetchVisions(filter);
  }
}

import { VoucherState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.core.vouchers as VoucherState;
};

export const getVoucherById = createSelector([getRoot], state => {
  return state.voucherById;
});

export const getVoucherHistoryById = createSelector(
  [getRoot],
  state => state.voucherHistoryById
);

export const getIsLoading = createSelector(
  [getRoot],
  state => state.fetchStatus === 'fetching'
);

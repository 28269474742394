import * as API from '../api';

export default class VolOfferHistoryChanges implements API.Histories {
  private _volOfferHistoryId: number;
  private _startDate: Date;
  private _endDate: Date;
  private _oldAvailableQuantity: number;
  private _oldPriceByPacking: number;
  private _oldPriceByLayer: number;
  private _oldPriceByTrolley: number;
  private _oldMinimumQuantity: number;
  private _oldSpecialPrice: number;
  private _newAvailableQuantity: number;
  private _newPriceByPacking: number;
  private _newPriceByLayer: number;
  private _newPriceByTrolley: number;
  private _newMinimumQuantity: number;
  private _newSpecialPrice: number;
  private _vouchers: string[];
  private _packingCompositions: string[];
  private _observation: string;
  private _siteDeliveryPattern: string;
  private _modifiedBy: string;
  private _creationDate: Date;
  private _availableQuantity: number;

  public get availableQuantity(): number {
    return this._availableQuantity ?? Number.NaN;
  }

  public get modifiedBy(): string {
    return this._modifiedBy ?? '';
  }

  public get creationDate(): Date {
    return this._creationDate;
  }

  public get volOfferHistoryId(): number {
    return this._volOfferHistoryId ?? Number.NaN;
  }

  public get startDate(): Date {
    return this._startDate;
  }

  public get endDate(): Date {
    return this._endDate;
  }

  public get oldAvailableQuantity(): number {
    return this._oldAvailableQuantity ?? Number.NaN;
  }

  public get oldPriceByPacking(): number {
    return this._oldPriceByPacking ?? 0;
  }

  public get oldPriceByLayer(): number {
    return this._oldPriceByLayer ?? 0;
  }

  public get oldPriceByTrolley(): number {
    return this._oldPriceByTrolley ?? 0;
  }

  public get oldMinimumQuantity(): number {
    return this._oldMinimumQuantity ?? 0;
  }

  public get oldSpecialPrice(): number {
    return this._oldSpecialPrice ?? 0;
  }

  public get newAvailableQuantity(): number {
    return this._newAvailableQuantity ?? Number.NaN;
  }

  public get newPriceByPacking(): number {
    return this._newPriceByPacking ?? 0;
  }

  public get newPriceByLayer(): number {
    return this._newPriceByLayer ?? 0;
  }

  public get newPriceByTrolley(): number {
    return this._newPriceByTrolley ?? 0;
  }

  public get newMinimumQuantity(): number {
    return this._newMinimumQuantity ?? 0;
  }

  public get newSpecialPrice(): number {
    return this._newSpecialPrice ?? 0;
  }

  public get vouchers(): string[] {
    return this._vouchers ?? ([] as string[]);
  }

  public get packingCompositions(): string[] {
    return this._packingCompositions ?? ([] as string[]);
  }

  public get observation(): string {
    return this._observation ?? '';
  }

  public get siteDeliveryPattern(): string {
    return this._siteDeliveryPattern ?? '';
  }

  constructor(json: API.Histories) {
    this._volOfferHistoryId = json.volOfferHistoryId;
    this._startDate = json.startDate;
    this._endDate = json.endDate;
    this._oldAvailableQuantity = json.oldAvailableQuantity;
    this._oldPriceByPacking = json.oldPriceByPacking;
    this._oldPriceByLayer = json.oldPriceByLayer;
    this._oldPriceByTrolley = json.oldPriceByTrolley;
    this._oldMinimumQuantity = json.oldMinimumQuantity;
    this._oldSpecialPrice = json.oldSpecialPrice;
    this._newAvailableQuantity = json.newAvailableQuantity;
    this._newPriceByPacking = json.newPriceByPacking;
    this._newPriceByLayer = json.newPriceByLayer;
    this._newPriceByTrolley = json.newPriceByTrolley;
    this._newMinimumQuantity = json.newMinimumQuantity;
    this._newSpecialPrice = json.newSpecialPrice;
    this._vouchers = json.vouchers;
    this._packingCompositions = json.packingCompositions;
    this._observation = json.observation;
    this._siteDeliveryPattern = json.siteDeliveryPattern;
    this._modifiedBy = json.modifiedBy;
    this._creationDate = json.creationDate;
    this._availableQuantity = json.availableQuantity;
  }
}

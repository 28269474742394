import { AxiosError, AxiosResponse } from 'axios';
import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';
import FilterStocks from '~/@core/domain/filters/stock/FilterStock';
import Stocks from '~/@core/domain/model/stock/Stocks';
import api from '../config/index';

export const fetchStocks = (params: FilterStocks) => {
  return new Promise<DefaultPaginated<Stocks[]>>((resolve, reject) => {
    api
      .get<void, AxiosResponse<DefaultPaginated<Stocks[]>>>('/stocks', {
        params
      })
      .then((response: AxiosResponse<DefaultPaginated<Stocks[]>>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import FilterTagPrintingList from '~/@core/domain/filters/tag/FilterTagPrintingList';
import { generateErrorMessage } from '~/helpers/util/error';
import * as tagPrinting from '~/services/producer/TagPrinting';
import { alertRequest } from '../../alert/actions';

export const fetchTagsByProductView = createAsyncThunk(
  `tag-print/products`,
  async (params: FilterTagPrintingList, { dispatch, rejectWithValue }) => {
    try {
      const response = await tagPrinting.findManyByProducts(params);
      return response;
    } catch (err: unknown) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao buscar etiquetas!'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchTagsByCustomerView = createAsyncThunk(
  `tag-print/customer`,
  async (params: FilterTagPrintingList, { dispatch, rejectWithValue }) => {
    try {
      const response = await tagPrinting.findManyByProducts(params);
      return response;
    } catch (err: unknown) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao buscar etiquetas!'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchPrintedList = createAsyncThunk(
  `tag-print/printListManagement`,
  async (
    params: { siteId: number; pageSize: number; pageNumber: number },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await tagPrinting.getPrintedList(params);
      return response;
    } catch (err: unknown) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao buscar etiquetas!'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const updateOrders = createAsyncThunk(
  `tag-print/updateOrders`,
  async (
    items: { id: number; origin: string; observation: string }[],
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await tagPrinting.updateOrders(items);
      dispatch(alertRequest('Pedidos salvos com sucesso!', 'success'));
      return response;
    } catch (err: unknown) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao buscar etiquetas!'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

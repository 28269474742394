import IUseCase from '../../../IUseCase';
import { ProductService } from '~/@core/infrastructure/api/services';
import ProductDetailed from '~/@core/domain/model/product/ProductDetailed';

export default class GetProductsDetailed
  implements IUseCase<number, Promise<ProductDetailed[]>>
{
  execute(siteId: number): Promise<ProductDetailed[]> {
    return ProductService.fetchProductsDetailed(siteId);
  }
}

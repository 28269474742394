import * as Effects from 'redux-saga/effects';
import { CreateInit } from '~/store/modules/_core/vouchers/duck';
import { actions, Types } from './duck';
import { Action } from '~/helpers/util/reducers';
import { APIError } from '~/domain/api/errors';
import * as VoucherService from '~/store/modules/_core/vouchers/service';

const call: any = Effects.call;
const put: any = Effects.put;
const takeLatest: any = Effects.takeLatest;

export function* createSaga(action: Action<CreateInit>) {
  try {
    const {
      description,
      reajustmentAmount,
      reajustmentType,
      quantityUnitMinimun,
      quantityType,
      observation,
      customerGroupId,
      customersIds,
      siteId
    } = action.payload;
    yield call(VoucherService.create, {
      description,
      reajustmentAmount,
      reajustmentType,
      quantityUnitMinimun,
      quantityType,
      observation,
      customerGroupId,
      customersIds,
      siteId
    });
    yield put(actions.createSuccess());
  } catch (error) {
    if (error instanceof APIError) {
      yield put(actions.createFailure({ errorMessage: error.message }));
    } else {
      yield put(
        actions.createFailure({
          errorMessage: 'Descrição de Voucher duplicada.'
        })
      );
    }
  }
}

export default [takeLatest(Types.CREATE_INIT, createSaga)];

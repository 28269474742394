// #FIXME
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  findBlackoutDates,
  updateDates,
  postDates,
  addPostSpecialDate
} from '~/services/producer/CompetenceRegistration';
import {
  CompetenceRegistrationPostParam,
  CompetenceRegistrationGetParam,
  Create
} from '~/domain/model/CompetenceRegistrationParams';
import { actions } from '../../_ui/loading';
import { alertRequest } from '../../alert/actions';

export const fetchBlackoutDates = createAsyncThunk(
  `competenceRegistration/fetchBlackoutDates`,
  async (params: CompetenceRegistrationGetParam, { dispatch }) => {
    dispatch(actions.setSubmitForm(true));

    try {
      const data = await findBlackoutDates(params);
      dispatch(actions.setSubmitForm(false));
      return data;
    } catch (err: any) {
      dispatch(actions.setSubmitForm(false));
      console.log('error');
    }
  }
);

export const updateBlackoutDates = createAsyncThunk(
  `competenceRegistration/updateBlackoutDates`,
  async (params: number, { dispatch }) => {
    dispatch(actions.setSubmitForm(true));

    try {
      await updateDates(params);
      dispatch(actions.setSubmitForm(false));
      return true;
    } catch (err: any) {
      dispatch(actions.setSubmitForm(false));
      console.log('error');
    }
  }
);

export const createBlackoutDates = createAsyncThunk(
  `competenceRegistration/createBlackoutDates`,
  async (params: CompetenceRegistrationPostParam, { dispatch }) => {
    dispatch(actions.setSubmitForm(true));

    try {
      await postDates(params);
      dispatch(actions.setSubmitForm(false));
      dispatch(alertRequest('Data cadastrada com sucesso.', 'success'));
      return true;
    } catch (err: any) {
      dispatch(actions.setSubmitForm(false));
      dispatch(
        alertRequest(
          JSON.stringify(err.response?.data.errors.messages),
          'error'
        )
      );
    }
  }
);

export const createSpecialDate = createAsyncThunk(
  `competenceRegistration/createSpecialDate`,
  async (params: Create, { dispatch }) => {
    dispatch(actions.setSubmitForm(true));

    try {
      await addPostSpecialDate(params);
      dispatch(actions.setSubmitForm(false));
      dispatch(alertRequest('Data cadastrada com sucesso.', 'success'));
      return 'success';
    } catch (err: any) {
      dispatch(actions.setSubmitForm(false));
      dispatch(
        alertRequest(
          JSON.stringify(err.response?.data.errors.messages),
          'error'
        )
      );
    }
  }
);

import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { actions, Types } from './duck';
import * as AnnouncementService from '~/store/modules/_core/announcement/service';
import { Action } from '~/helpers/util/reducers';

export function* watchGetFilesByIdSaga(action: Action<number>) {
  yield fork(getFilesByIdSaga, action.payload);
}

export function* watchPostReadAnnouncementSaga(action: Action<number>) {
  yield fork(postReadAnnouncementSaga, action.payload);
}

export function* reportsAnnouncementBannerSaga() {
  try {
    yield call(AnnouncementService.requestAnnouncementBanner);

    yield put(actions.requestAnnouncementBannerSuccess());
  } catch (error: any) {
    yield put(actions.requestAnnouncementBannerFailure());
  }
}

export function* getFilesByIdSaga(id: number) {
  try {
    yield call(AnnouncementService.getFilesById, id);

    yield put(actions.getFilesByIdSuccess());
  } catch (error: any) {
    yield put(actions.getFilesByIdFailure());
  }
}

export function* postReadAnnouncementSaga(id: number) {
  try {
    yield call(AnnouncementService.postReadAnnouncement, id);

    yield put(actions.postReadAnnouncementSuccess());
  } catch (error: any) {
    yield put(actions.postReadAnnouncementFailure());
  }
}

export default [
  takeLatest(Types.requestAnnouncementBanner, reportsAnnouncementBannerSaga),
  takeLatest(Types.getFilesById, watchGetFilesByIdSaga),
  takeLatest(Types.postReadAnnouncement, watchPostReadAnnouncementSaga)
];

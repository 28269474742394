import { LkpOfferEditState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.ui.lkpOfferEdit as LkpOfferEditState;
};

export const getLkpOffersChanges = createSelector([getRoot], state => {
  return state.lkpOffersChanges;
});

export const getIsLoading = createSelector(
  [getRoot],
  state => state.editStatus === 'pending'
);

export const getEditStatus = createSelector([getRoot], state => {
  return state.editStatus;
});

export const getEditType = createSelector([getRoot], state => {
  return state.editType;
});

export const getErrorMessage = createSelector([getRoot], state => {
  return state.errorMessage;
});

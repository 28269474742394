import moment from 'moment';
import * as API from '~/domain/api';
import { brFormat } from '~/helpers/util';

export default class UnavailableDate {
  public readonly json: API.UnavailableDates;
  public readonly id: number;
  public readonly description: string;
  public readonly unavailableDate: Date;
  public readonly creationDate: Date;

  private getJson(): API.UnavailableDates {
    return this.json;
  }

  private getId(): number {
    return this.json.id ?? Number.NaN;
  }

  private getDescription(): string {
    return this.json.description ?? '';
  }

  private getUnavailableDate(): Date {
    return this.json.unavailableDate ?? new Date();
  }

  private getCreationDate(): Date {
    return this.json.creationDate ?? new Date();
  }

  public showCreationDateFormated(): string {
    const momentDate = moment(this.getCreationDate());
    return brFormat(momentDate);
  }

  public showUnavailableDateFormated(): string {
    const momentDate = moment(this.getUnavailableDate());
    return brFormat(momentDate);
  }

  constructor(json: API.UnavailableDates) {
    this.json = json;
    this.id = this.getId();
    this.description = this.getDescription();
    this.unavailableDate = this.getUnavailableDate();
    this.creationDate = this.getCreationDate();
  }
}

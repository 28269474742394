import { call, put } from 'redux-saga/effects';

import { UploadFile } from 'antd/lib/upload/interface';
import * as API from '~/domain/api';

import { actions } from './duck';
import * as BannerService from '~/services/producer/Banner';
import Banner from '~/domain/model/Banner';

export function* fetchMany(
  description?: string,
  bannerTypeId?: number,
  active?: boolean,
  pageNumber?: number,
  pageSize?: number
) {
  const response: API.ServerResponse = yield call(
    BannerService.fetchMany,
    description,
    bannerTypeId,
    active,
    pageNumber,
    pageSize
  );
  const data: API.DefaultResponse = response.data;
  const { results } = data;
  const banners: API.Banner[] = results as API.Banner[];
  const bannerByID: Map<number, Banner> = new Map(
    banners.map(banner => [banner.id, new Banner(banner)])
  );
  yield put(actions.update({ byId: bannerByID }));
  return response;
}

export function* fetchBannerUrlById(bannerId: number) {
  const base64String: string = yield call(
    BannerService.fetchBannerUrlById,
    bannerId
  );
  return base64String;
}

export function* updateStatus(bannerId: number) {
  yield call(BannerService.updateStatus, bannerId);
}

export function* create(
  description: string,
  bannerTypeId: number,
  bannerImage: UploadFile
) {
  yield call(BannerService.create, description, bannerTypeId, bannerImage);
}

import { AxiosResponse } from 'axios';
import api from '~/services/api';
import { FeatureFlag } from '~/zustand/featureFlag/useFeatureFlagStore';

interface IParams {
  flag: string;
}

export interface IResponse extends AxiosResponse {
  data: boolean;
}

export async function getFeatureFlag(params: IParams): Promise<FeatureFlag> {
  const response: IResponse = await api.get(`/feature-flag/${params.flag}`);

  return {
    name: params.flag,
    isEnabled: response.data
  };
}

import { AxiosResponse } from 'axios';
import * as Effects from 'redux-saga/effects';

import * as API from '~/domain/api';

import { actions, CreateInit } from './duck';
import { Voucher, VoucherHistory } from '~/domain/model';
import * as VoucherService from '~/services/producer/Voucher';

const call: any = Effects.call;
const put: any = Effects.put;

export function* create(createInit: CreateInit) {
  const {
    description,
    reajustmentAmount,
    reajustmentType,
    quantityUnitMinimun,
    quantityType,
    observation,
    customerGroupId,
    customersIds,
    siteId
  } = createInit;
  yield call(VoucherService.create, {
    description,
    reajustmentAmount,
    reajustmentType,
    quantityUnitMinimun,
    quantityType,
    observation,
    customerGroupId,
    customersIds,
    siteId
  });
}

export function* findVouchersByFilters(
  pageNumber?: number,
  pageSize?: number,
  status?: string,
  customerId?: number,
  customerGroupId?: number,
  reajustmentAmount?: number,
  reajustmentType?: string,
  description?: string,
  siteId?: number
) {
  const response: AxiosResponse = yield call(
    VoucherService.fetchVoucher,
    status,
    pageNumber,
    pageSize,
    customerId,
    customerGroupId,
    reajustmentAmount,
    reajustmentType,
    description,
    siteId
  );
  const apiResponse = response.data as API.DefaultResponse;
  const vouchers: API.Voucher[] = apiResponse.results ?? [];
  const voucherById: Map<string, Voucher> = new Map(
    vouchers.map(voucher => [String(voucher.id), new Voucher(voucher)])
  );
  yield put(actions.update({ voucherById }));
  return apiResponse;
}

export function* activeOrInactiveStatus(id: number) {
  yield call(VoucherService.activeOrInactiveStatus, id);
}

export function* getVoucherHistoryById(id: number) {
  const response: AxiosResponse = yield call(
    VoucherService.getVoucherHistoryById,
    id
  );
  const apiResponse = response.data as API.VoucherHistory[];
  const voucherHistories: API.VoucherHistory[] = apiResponse ?? [];
  const voucherHistoryById: Map<string, VoucherHistory> = new Map(
    voucherHistories.map(voucherHistory => [
      String(voucherHistory.id),
      new VoucherHistory(voucherHistory)
    ])
  );
  yield put(actions.update({ voucherHistoryById }));
}

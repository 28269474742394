// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
import produce from 'immer';

interface StateType {
  data: any[];
  registry: any;
  loading: boolean;
  saving: boolean;
}

const INITIAL_STATE: StateType = {
  data: [],
  registry: {},
  loading: false,
  saving: false
};

export default function section(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case '@section/LIST_REQUEST':
    case '@section/READ_REQUEST':
      return produce(state, draft => {
        draft.loading = true;
      });

    case '@section/ADD_REQUEST':
      return produce(state, draft => {
        draft.loading = false;
        draft.data.push(action.payload.data);
      });

    case '@section/DELETE_REQUEST':
      return produce(state, draft => {
        draft.loading = false;
        draft.data.push(action.payload.data);
      });

    case '@section/LIST_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.data = action.payload.list;
      });

    case '@section/ADD_SUCCESS':
    case '@section/DELETE_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
      });

    case '@section/CLEAR_ALL':
      return produce(state, draft => {
        draft.loading = false;
        draft.registry = {};
        draft.data = [];
      });

    case '@section/LIST_FAILURE':
    case '@section/ADD_FAILURE':
    case '@section/READ_FAILURE':
    case '@section/DELETE_FAILURE':
      return produce(state, draft => {
        draft.loading = false;
      });

    default:
      return state;
  }
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationError } from '~/domain/api/errors';

export type status = 'idle' | 'pending' | 'success' | 'failure';

export type SiteLinksEditState = {
  editStatus: status;
  activeOrInactiveStatus: status;
  fetchInitStatus: status;
  errorMessage?: string;
};

export type EditFailure = {
  error?: ApplicationError;
};

export type Error = {
  errorMessage?: string;
};

const prefix = '@siteLinksEdit';

export const initialState: SiteLinksEditState = {
  editStatus: 'idle',
  activeOrInactiveStatus: 'idle',
  fetchInitStatus: 'idle'
};

export type CreateAndEditSiteLink = {
  id?: number;
  description: string;
  url: string;
  sitesIds?: number[];
};

const siteLinksEdit = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: state => {
      state.editStatus = initialState.editStatus;
      state.fetchInitStatus = initialState.fetchInitStatus;
      state.activeOrInactiveStatus = initialState.activeOrInactiveStatus;
      state.errorMessage = '';
    },
    fetchInit: state => {
      state.fetchInitStatus = 'pending';
    },
    fetchInitSuccess: state => {
      state.fetchInitStatus = 'success';
    },
    fetchInitFailure: state => {
      state.fetchInitStatus = 'failure';
    },
    editSiteLink: (state, _action: PayloadAction<CreateAndEditSiteLink>) => {
      state.editStatus = 'pending';
    },
    editSiteLinkSuccess: state => {
      state.editStatus = 'success';
    },
    editSiteLinkFailure: state => {
      state.editStatus = 'failure';
    },
    activeOrInactiveSiteLink: (state, _action: PayloadAction<number>) => {
      state.activeOrInactiveStatus = 'pending';
    },
    activeOrInactiveSiteLinkSuccess: state => {
      state.activeOrInactiveStatus = 'success';
    },
    activeOrInactiveSiteLinkFailure: state => {
      state.activeOrInactiveStatus = 'failure';
    }
  }
});
export const actions = siteLinksEdit.actions;
export const reducer = siteLinksEdit.reducer;

const caseReducers = siteLinksEdit.caseReducers;
export const Types = Object.freeze({
  clear: `${prefix}/${caseReducers.clear.name}`,
  editSiteLink: `${prefix}/${caseReducers.editSiteLink.name}`,
  editSiteLinkSuccess: `${prefix}/${caseReducers.editSiteLinkSuccess.name}`,
  editSiteLinkFailure: `${prefix}/${caseReducers.editSiteLinkFailure.name}`,
  activeOrInactiveSiteLink: `${prefix}/${caseReducers.activeOrInactiveSiteLink.name}`,
  activeOrInactiveSiteLinkSuccess: `${prefix}/${caseReducers.activeOrInactiveSiteLinkSuccess.name}`,
  activeOrInactiveSiteLinkFailure: `${prefix}/${caseReducers.activeOrInactiveSiteLinkFailure.name}`,
  fetchInit: `${prefix}/${caseReducers.fetchInit.name}`,
  fetchInitSuccess: `${prefix}/${caseReducers.fetchInitSuccess.name}`,
  fetchFailureInit: `${prefix}/${caseReducers.fetchInitFailure.name}`
});

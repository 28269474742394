import { UploadFile } from 'antd/lib/upload/interface';
import { AxiosResponse } from 'axios';
import { APIError } from '~/domain/api/errors';
import AnnouncementsFetchParams from '~/domain/model/Announcements/AnnouncementsFetchParams';
import apiProducer from '../api';

export const announcementsFetch = async ({
  description,
  validity,
  role,
  status,
  startDate,
  endDate
}: AnnouncementsFetchParams) => {
  try {
    const params = new URLSearchParams();
    if (description) {
      params.append('data.description', description);
    }
    if (validity) {
      params.append('data.validity', validity);
    }
    if (role !== undefined && (role === 0 || role > 0)) {
      params.append('data.role', String(role));
    }
    if (status) {
      params.append('data.status', status);
    }
    if (startDate && endDate) {
      params.append('data.startDate', startDate);
      params.append('data.endDate', endDate);
    }
    params.append('pageNumber', String(1));
    params.append('pageSize', String(900));
    const response: AxiosResponse = await apiProducer.get('/announcement', {
      params
    });
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const requestAnnouncementList = async (
  description?: string,
  validity?: string,
  role?: number,
  status?: string,
  startDate?: string,
  endDate?: string,
  pageNumber?: number,
  pageSize?: number
) => {
  try {
    const params = new URLSearchParams();
    if (description) {
      params.append('data.description', description);
    }
    if (validity) {
      params.append('data.validity', validity);
    }
    if (role) {
      params.append('data.role', String(role));
    }
    if (status) {
      params.append('data.status', status);
    }
    if (startDate && endDate) {
      params.append('data.startDate', startDate);
      params.append('data.endDate', endDate);
    }
    if (pageNumber) {
      params.append('pageNumber', String(pageNumber));
    }
    if (pageSize) {
      params.append('pageSize', String(pageSize));
    }
    const response: AxiosResponse = await apiProducer.get('/announcement', {
      params
    });
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const getById = async (id: number) => {
  try {
    const response: AxiosResponse = await apiProducer.get(
      `/announcement/${id}`
    );
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const postReadAnnouncement = async (id: number) => {
  try {
    const response: AxiosResponse = await apiProducer.post(
      `/announcement/${id}`
    );
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const requestAnnouncementBanner = async () => {
  try {
    const response: AxiosResponse = await apiProducer.get(
      `/announcement/visualization`
    );
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const getFilesById = async (id: number) => {
  try {
    const response: AxiosResponse = await apiProducer.get(
      `/announcement/file/${id}`
    );
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const activeOrInactiveAnnouncement = async (id: number) => {
  try {
    const response: any = await apiProducer.put(`/announcement/status/${id}`);
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const createOrPut = async (
  environment: number,
  role: number,
  description: string,
  required: boolean,
  startDate: string,
  endDate: string,
  content: string,
  documents?: UploadFile[],
  image?: UploadFile,
  id?: number
) => {
  const formData = new FormData();
  formData.append('Id', id ? id.toString() : '0');
  formData.append('Description', description);
  formData.append('Required', required.toString());
  formData.append('Role', role.toString());
  formData.append('Environment', environment.toString());
  formData.append('StartDate', startDate);
  formData.append('EndDate', endDate);
  if (image && image.originFileObj) {
    formData.append('Image', image.originFileObj);
  }
  if (documents?.length) {
    documents.forEach((doc, i) => {
      if (doc.originFileObj) {
        formData.append(`File${i + 1}`, doc.originFileObj);
      }
    });
  }
  formData.append('Message', content);
  const response: any = await apiProducer.put('/announcement', formData);
  return response;
};

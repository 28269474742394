import { Card, FormInstance } from 'antd';
import FormFilterDataModel from '~/@core/domain/ui/forms/FormFilterDataModel';
import FormFilterTextModel from '~/@core/domain/ui/forms/FormFilterTextModel';
import FormFilterInventoryConsolidated from '~/components/molecules/RfidReports/Inventory/Consolidated/FormFilterInventoryConsolidated';
import TableInventoryConsolidated from '~/components/molecules/RfidReports/Inventory/Consolidated/TableInventoryConsolidated';
import FormFilterInventoryDone from '~/components/molecules/RfidReports/Inventory/Dones/FormFilterInventoryDone';
import TableInventoryDone from '~/components/molecules/RfidReports/Inventory/Dones/TableInventoryDone';
import FormFilterInventoryFrequency from '~/components/molecules/RfidReports/Inventory/Frequency/FormFilterInventoryFrequency';
import TableInventoryFrequency from '~/components/molecules/RfidReports/Inventory/Frequency/TableInventoryFrequency';
import FormFilterInventoryOwned from '~/components/molecules/RfidReports/Inventory/Owned/FormFilterInventoryOwned';
import TableInventoryOwned from '~/components/molecules/RfidReports/Inventory/Owned/TableInventoryOwned';
import { TabContainerForm } from '~/pages/Home/styles';

interface IProps {
  filterFormDone: FormInstance<FormFilterDataModel>;
  filterFormOwned: FormInstance<FormFilterTextModel>;
  filterFormConsolidated: FormInstance<FormFilterTextModel>;
  filterFormFrequency: FormInstance<FormFilterDataModel>;
}

export default function InventoryWrapper(props: IProps) {
  const {
    filterFormDone,
    filterFormOwned,
    filterFormConsolidated,
    filterFormFrequency
  } = props;

  return (
    <Card>
      <TabContainerForm>
        <TabContainerForm.TabPane tab="Realizados" key="1">
          <FormFilterInventoryDone form={filterFormDone} />
          <TableInventoryDone form={filterFormDone} />
        </TabContainerForm.TabPane>

        <TabContainerForm.TabPane tab="Posse X Saldo" key="2">
          <FormFilterInventoryOwned form={filterFormOwned} />
          <TableInventoryOwned form={filterFormOwned} />
        </TabContainerForm.TabPane>

        <TabContainerForm.TabPane tab="Consolidado" key="4">
          <FormFilterInventoryConsolidated form={filterFormConsolidated} />
          <TableInventoryConsolidated form={filterFormConsolidated} />
        </TabContainerForm.TabPane>

        <TabContainerForm.TabPane tab="Frequência/Inconformidade" key="6">
          <FormFilterInventoryFrequency form={filterFormFrequency} />
          <TableInventoryFrequency form={filterFormFrequency} />
        </TabContainerForm.TabPane>
      </TabContainerForm>
    </Card>
  );
}

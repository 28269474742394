import { Col, Form, FormInstance, InputNumber } from 'antd';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomerSelect from '~/components/atoms/Inputs/CustomerSelect';
import ExpeditionSelect from '~/components/atoms/Inputs/ExpeditionSelect';
import QualitiesSelect from '~/components/atoms/Inputs/QualitiesSelect';
import ProductFilters from '~/components/molecules/Inputs/ProductFilters';
import { globalContext } from '~/context/GlobalContext';

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
interface Props {
  form: FormInstance<any>;
}

export default function HideFilters(props: Props) {
  const { form } = props;
  const { t } = useTranslation();
  const { siteIdSelected } = useContext(globalContext);

  // #FIXME
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
  const [orderNumber, setOrderNumber] = useState<number>(Number.NaN);

  const handleChangeOrder = useCallback((orderValue: any) => {
    if (orderValue > 0) setOrderNumber(orderValue);
  }, []);

  const preventMinus = (event: any) => {
    if (event.code === 'Minus') {
      event.preventDefault();
    }
  };

  useEffect(() => {
    setOrderNumber(Number.NaN);
    form.resetFields(['OrderNumber']);
  }, [form, siteIdSelected]);

  return (
    <>
      <ProductFilters
        form={form}
        siteId={siteIdSelected}
        lgCategory={4}
        lgGroup={4}
        lgProduct={8}
        lgRecipient={4}
        lgColor={4}
      />

      <QualitiesSelect form={form} siteId={siteIdSelected} lgQuality={4} />

      <ExpeditionSelect form={form} siteId={siteIdSelected} lgExpedition={4} />

      <CustomerSelect form={form} siteId={siteIdSelected} lgCustomer={12} />

      <Col lg={4}>
        <Form.Item name="OrderNumber" label={t('gfp.filters.orderNumber')}>
          <InputNumber
            controls={false}
            onKeyPress={preventMinus}
            onChange={value => {
              handleChangeOrder(value);
            }}
          />
        </Form.Item>
      </Col>
    </>
  );
}

import ICancelGfp from '~/@core/domain/filters/gfp/FilterCancelGfp';
import { GfpService } from '~/@core/infrastructure/api/services';
import IUseCase from '../../IUseCase';

export default class CancelGfp
  implements IUseCase<ICancelGfp, Promise<number[]>>
{
  execute(filter: ICancelGfp): Promise<number[]> {
    return GfpService.cancelGfp(filter);
  }
}

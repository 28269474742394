/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HarvestLimit from '~/domain/model/ProductPreference/HarvestLimit';

export type FetchStatus = 'idle' | 'fetching' | 'success' | 'failure';

export type ProductPreferencesCreateState = {
  fetchStatus: FetchStatus;
  submitForm: boolean;
};
export type PackingDistributionCompositions = {
  id: number;
  minimumQuantity: string;
  multipleQuantity: string;
};
export type ProductPreferencesCreateAction = {
  productIds: number[];
  siteId: number;
  harvestLimit?: HarvestLimit;
  packingDistributionCompositions: PackingDistributionCompositions[];
  expeditionId: number;
};
export type ProductPreferencesUpdateAction = {
  productId: number;
  harvestLimit: HarvestLimitAction;
  packingDistributionCompositions: PackingDistributionCompositions[];
  expeditionId: number;
  id: number;
};

export type HarvestLimitAction = {
  id: number;
  sundayLimit?: number;
  mondayLimit?: number;
  tuesdayLimit?: number;
  wednesdayLimit?: number;
  thursdayLimit?: number;
  fridayLimit?: number;
  saturdayLimit?: number;
};

const prefix = '@productPreferencesCreate';
const initialState: ProductPreferencesCreateState = {
  fetchStatus: 'idle',
  submitForm: false
};
const productPreferenceCreate = createSlice({
  name: prefix,
  initialState,
  reducers: {
    createPreference: (
      state,
      action: PayloadAction<ProductPreferencesCreateAction>
    ) => {
      state.fetchStatus = 'fetching';
    },
    updatePreference: (
      state,
      action: PayloadAction<ProductPreferencesUpdateAction>
    ) => {
      state.fetchStatus = 'fetching';
    },
    setSubmitForm: (state, action: PayloadAction<boolean>) => {
      state.submitForm = action.payload;
    }
  }
});
export const actions = productPreferenceCreate.actions;
export const reducer = productPreferenceCreate.reducer;
export const Types = Object.freeze({
  createPreference: `${prefix}/${productPreferenceCreate.caseReducers.createPreference.name}`,
  updatePreference: `${prefix}/${productPreferenceCreate.caseReducers.updatePreference.name}`
});

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';

import { actions, selectors } from '~/store/modules';
import { AnnouncementVisualization } from '~/domain/model';
import * as S from './styles';
import Loading from '~/components/Loading';

type ModalAnnouncementProps = {
  modalIsVisible: boolean;
  setModalIsVisible: (visible: boolean) => void;
  announcement?: AnnouncementVisualization;
  uniqueAnnouncement: boolean;
  nextAnnouncement: () => void;
  previusAnnouncement: () => void;
};

export default function ModalAnnouncement({
  modalIsVisible,
  setModalIsVisible,
  nextAnnouncement,
  uniqueAnnouncement,
  previusAnnouncement,
  announcement
}: ModalAnnouncementProps) {
  const dispatch = useDispatch();
  const [image, setImage] = useState('');
  const [searchAll, setSearchAll] = useState(false);
  const [documentFile, setDocument] = useState('');

  const statusPostRead = useSelector(
    selectors.ui.announcementBanner.getStatusPost
  );
  const announcementFiles = useSelector(selectors.core.announcement.getFiles);
  const isLoadingFileById = useSelector(
    selectors.ui.announcementBanner.isLoadingFileById
  );
  const finished = useSelector(selectors.ui.announcementBanner.getFinished);

  const postReadAnnouncement = () => {
    if (announcement) {
      dispatch(
        actions.ui.announcementBanner.postReadAnnouncement(announcement.id)
      );
    }
  };

  const getAllAnnouncementBanner = () => {
    setModalIsVisible(false);
    setImage('');
    setDocument('');
    setSearchAll(false);
    dispatch(actions.ui.announcementBanner.clearStatusPost());
    dispatch(actions.ui.announcementBanner.requestAnnouncementBanner());
  };

  useEffect(() => {
    finished && getAllAnnouncementBanner();
    dispatch(actions.ui.announcementBanner.changeFinished());
  }, [finished]);

  useEffect(() => {
    if (statusPostRead === 'success' && searchAll) {
      getAllAnnouncementBanner();
    }
  }, [statusPostRead, searchAll]);

  useEffect(() => {
    if (announcement) {
      dispatch(actions.ui.announcementBanner.getFilesById(announcement.id));
    }
  }, [announcement]);

  useEffect(() => {
    const imageFind = announcementFiles?.find(
      file =>
        file.extension.includes('png') ||
        file.extension.includes('jpeg') ||
        file.extension.includes('jpg')
    );
    if (imageFind) {
      setImage(imageFind.url);
    }
    const documentFileFind = announcementFiles?.find(
      file =>
        !file.extension.includes('png') &&
        !file.extension.includes('jpeg') &&
        !file.extension.includes('jpg')
    );
    if (documentFileFind) {
      setDocument(documentFileFind.url);
    }
    return () => {
      dispatch(actions.core.announcement.clear());
    };
  }, [announcementFiles]);

  const downloadURI = (uri: string, name: string) => {
    const link = document.createElement('a');
    link.download = name;
    link.target = '_blank';
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <S.ModalAnnouncement
      closable={false}
      centered
      visible={modalIsVisible}
      keyboard={false}
      footer={
        <S.ButtonsActions>
          {!uniqueAnnouncement && (
            <>
              <S.ButtonPrevius
                disabled={isLoadingFileById}
                onClick={() => {
                  postReadAnnouncement();
                  previusAnnouncement();
                  setImage('');
                  setDocument('');
                }}
              >
                Anterior
              </S.ButtonPrevius>
              <S.ButtonNext
                disabled={isLoadingFileById}
                onClick={() => {
                  postReadAnnouncement();
                  nextAnnouncement();
                  setImage('');
                  setDocument('');
                }}
              >
                Próximo
              </S.ButtonNext>
            </>
          )}
          <S.ButtonOk
            disabled={isLoadingFileById}
            onClick={() => {
              setSearchAll(true);
              postReadAnnouncement();
              setModalIsVisible(false);
              setImage('');
              setDocument('');
            }}
          >
            Confirmar leitura
          </S.ButtonOk>
        </S.ButtonsActions>
      }
    >
      <S.ModalAnnouncementHeader>
        <span className="title">{announcement?.description}</span>
        <span className="date">
          {moment(announcement?.creationDate).format('DD/MM/YYYY')}
        </span>
      </S.ModalAnnouncementHeader>
      {documentFile && (
        <S.DocumentsDownload>
          <Button
            icon={<DownloadOutlined />}
            onClick={() => downloadURI(documentFile, 'Documento_comunicado')}
          />
        </S.DocumentsDownload>
      )}
      <S.ModalAnnouncementBody>
        {image && <img loading="lazy" src={image} alt="Imagem do comunicado" />}
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: announcement?.message ?? '' }}
        />
      </S.ModalAnnouncementBody>
      <Loading loading={isLoadingFileById} />
    </S.ModalAnnouncement>
  );
}

import produce from 'immer';
import { ProductCategory } from '~/domain/model';
import { createCreator, createReducer, Reducer } from '~/helpers/util/reducers';

export type Status = 'cleared' | 'updated';

export type ProductCategoryState = {
  productCategoriesByID: Map<string, ProductCategory>;
  status: Status;
};

export type Update = {
  productCategoriesByID: Map<string, ProductCategory>;
};

const prefix = '@productCategory';

export const Types = Object.freeze({
  CLEAR: `${prefix}/CLEAR`,
  UPDATE: `${prefix}/UPDATE`
});

export const initialState: ProductCategoryState = {
  productCategoriesByID: new Map(),
  status: 'cleared'
};

const clearReducer: Reducer<ProductCategoryState, void> = state => {
  return produce(state, draft => {
    draft.productCategoriesByID = initialState.productCategoriesByID;
    draft.status = initialState.status;
  });
};

const updateReducer: Reducer<ProductCategoryState, Update> = (
  state,
  action
) => {
  return produce(state, draft => {
    draft.productCategoriesByID = action.productCategoriesByID as Map<
      string,
      ProductCategory
    >;
  });
};

export const actions = Object.freeze({
  clear: createCreator<void>(Types.CLEAR),
  update: createCreator<Update>(Types.UPDATE)
});

export const reducer = createReducer(initialState, {
  [Types.CLEAR]: clearReducer,
  [Types.UPDATE]: updateReducer
});

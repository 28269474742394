/* eslint-disable @typescript-eslint/no-explicit-any */
import { Col, Form, FormInstance, Input, Row, Space, Switch } from 'antd';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SwitchLabel } from './styles';

interface Props {
  searchFormName: string;
  setSearchValue?: Dispatch<SetStateAction<string>>;
  switchLabel?: string;
  switchFormName: string;
  form: FormInstance<any>;
  isRadioEnabled: boolean;
  isInputDisabled?: boolean;
  handleFunction?: any;
  isGroupByOrders?: boolean;
  setIsGroupByOrders?: Dispatch<SetStateAction<boolean>>;
}

export default function PanelFilters(props: Props) {
  const {
    switchLabel,
    switchFormName,
    searchFormName,
    setSearchValue,
    isRadioEnabled,
    // #FIXME
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
    form,
    isInputDisabled,
    handleFunction,
    isGroupByOrders,
    setIsGroupByOrders
  } = props;

  const { t } = useTranslation();
  const { Search } = Input;
  const showInput = isInputDisabled ? 'none' : 'block';
  const showRadio = isRadioEnabled ? 'block' : 'none';

  const onSearchHere = useCallback(
    (searchValue: string) => {
      if (setSearchValue) {
        setSearchValue(searchValue);
      }
      if (handleFunction) {
        handleFunction();
      }
    },
    [handleFunction, setSearchValue]
  );

  return (
    <Row
      justify="space-evenly"
      style={{ marginLeft: '25px' }}
      onClick={event => {
        event.stopPropagation();
      }}
      onKeyPress={event => {
        if (event.key === 'Enter') event.stopPropagation();
      }}
      wrap
      gutter={{ xl: 10 }}
    >
      <Col style={{ display: showInput }}>
        <Form.Item name={searchFormName}>
          <Search
            placeholder={t('generals.searchHere')}
            style={{ width: 200 }}
            onSearch={value => onSearchHere(value)}
            onPressEnter={e =>
              onSearchHere((e.target as HTMLInputElement).value)
            }
          />
        </Form.Item>
      </Col>
      {setIsGroupByOrders ? (
        <Col style={{ display: showRadio }}>
          <Space size="middle">
            <SwitchLabel>{switchLabel}</SwitchLabel>
            <Form.Item name={switchFormName} noStyle>
              <Switch
                checked={isGroupByOrders}
                onClick={() => {
                  setIsGroupByOrders(!isGroupByOrders);
                }}
              />
            </Form.Item>
          </Space>
        </Col>
      ) : null}
    </Row>
  );
}

import { call, fork, put, takeLatest } from 'redux-saga/effects';

import * as API from '~/domain/api';

import { actions, Types, FetchBannerApproval, ChangeStatus } from './duck';
import * as BannerApprovalService from '~/store/modules/_core/bannerApproval/service';
import * as BannerTypesService from '~/store/modules/_core/bannerTypes/service';
import * as SiteLinksService from '~/store/modules/_core/siteLinks/service';
import { Action } from '~/helpers/util/reducers';
import { alertRequest } from '../../alert/actions';
import { APIError } from '~/domain/api/errors';
import { generateErrorMessage } from '~/helpers/util/error';

export function* watchFetchBannerApprovalSaga(
  action: Action<FetchBannerApproval>
) {
  yield fork(fetchBannerApprovalSaga, action.payload);
}

export function* watchApproveOrDisapproveBannerSaga(
  action: Action<ChangeStatus>
) {
  yield fork(approveOrDisapproveBanner, action.payload);
}

export function* fetchInitSaga(): any {
  try {
    yield call(SiteLinksService.getAllUrls);
    yield call(BannerTypesService.fetchAll);
    yield call(BannerApprovalService.fetchBannerApproval, {
      pageNumber: 1,
      pageSize: 10
    });
    yield put(actions.fetchInitSuccess());
  } catch (err) {
    yield put(actions.fetchInitFailure());
  }
}

export function* fetchBannerApprovalSaga({
  description,
  url,
  bannerTypeId,
  uploadDate,
  status,
  pageNumber,
  pageSize
}: FetchBannerApproval): any {
  try {
    const response: API.ServerResponse = yield call(
      BannerApprovalService.fetchBannerApproval,
      {
        description,
        url,
        bannerTypeId,
        uploadDate,
        status,
        pageNumber,
        pageSize
      }
    );
    const data: API.DefaultResponse = response.data;

    const totalCount = data.totalCount ?? 0;
    yield put(
      actions.fetchBannerApprovalSuccess({
        pageNumber,
        pageSize,
        totalCount
      })
    );
  } catch (err) {
    yield put(actions.fetchBannerApprovalFailure());
  }
}

export function* approveOrDisapproveBanner({
  id,
  status,
  message
}: ChangeStatus) {
  try {
    yield call(BannerApprovalService.approveOrDisapproveBanner, {
      id,
      status,
      message
    });
    yield put(actions.approveOrDisapproveSuccess());
    yield put(actions.approveOrDisapproveSuccess());
    if (status === 'A') {
      yield put(alertRequest('Banner aprovado com sucesso.', 'success'));
    } else {
      yield put(alertRequest('Banner reprovado com sucesso.', 'success'));
    }
  } catch (error) {
    yield put(actions.approveOrDisapproveFailure());
    if (error instanceof APIError) {
      yield put(alertRequest(generateErrorMessage(error.message), 'error'));
    } else {
      if (status === 'A') {
        yield put(
          alertRequest('Não foi possível aprovar este banner.', 'error')
        );
      }
      if (status === 'R') {
        yield put(
          alertRequest('Não foi possível reprovar este banner.', 'error')
        );
      }
    }
  }
}

export default [
  takeLatest(Types.fetchInit, fetchInitSaga),
  takeLatest(
    Types.approveOrDisapproveBanner,
    watchApproveOrDisapproveBannerSaga
  ),
  takeLatest(Types.fetchBannerApproval, watchFetchBannerApprovalSaga)
];

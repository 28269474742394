import styled from 'styled-components';
import { Button, Collapse, Divider, Modal, Table, Tabs, Space } from 'antd';

export const ModalPacking = styled(Modal)`
  border-radius: 10px;
  .ant-modal-header {
    background: #f78e1e;
    padding: 24px 24px 16px;
  }
  .ant-modal-title {
    font-size: 20px;
    color: #fff;
  }
`;
export const TabWrapper = styled.div`
  padding: 12px 16px;
  width: 100%;
  margin-top: 15px;
  padding-left: 0 !important;
`;
export const TabContainerUsers = styled(Tabs)`
  .ant-form-item-label {
    padding-bottom: 2px;
  }
  .ant-tabs-tab {
    border: none;
    box-shadow: none;
  }
  label {
    font: 12px 'Roboto', sans-serif;
    color: #353839;
  }

  .ant-tabs-nav {
    margin-bottom: 4px;

    &::before {
      position: absolute;
      right: 0;
      left: 0;
      top: 100%;
      border-bottom: 1px solid #d9d9d9;
      content: '';
    }
  }

  .tab-default-content {
    margin-left: -10px;
    margin-right: -10px;
  }

  .ant-tabs-content {
    width: calc(100% + 20px);
    display: block;
  }
`;

export const ButtonTop = styled(Button)`
  padding: 4px 9px;
`;
export const CircleOrange = styled.h2`
  color: #f78e1e;
`;
export const Frame = styled(Collapse)`
  font: 16px 'Roboto', sans-serif;
  font-weight: 400;
  .ant-collapse-header {
    display: flex;
  }
`;
export const DividerFrame = styled(Divider)`
  &.ant-divider-horizontal.ant-divider-with-text-left::before {
    width: 2% !important;
    top: 0% !important;
  }
  &.ant-divider-horizontal.ant-divider-with-text-left::after {
    top: 0% !important;
  }
  border-top-color: #d9d9d9 !important;
  margin-top: 0 !important;
`;
export const StatusCircle = styled.div`
  border-radius: 100%;
  background-color: ${props => props.color};
  width: 16px;
  height: 16px;
  margin: auto;
`;
export const PermissionCircle = styled.div`
  border-radius: 100%;
  background-color: ${props => props.color};
  width: 16px;
  height: 16px;
  margin: auto;
`;
export const StockValue = styled.span`
  &:hover {
    cursor: pointer;
    color: #f78e1e;
  }
`;
export const P = styled.p`
  margin: 0px;
`;
export const Scroll = styled.div`
  overflow-y: scroll;
  height: 150px;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 300px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 300px;
  }
`;
export const TableVolOffer = styled(Table);

export const FormFilterWrapper = styled.div`
  padding: 12px 16px;
`;

export const GroupTitleInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const GroupTitleOverview = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const TitleText = styled.h4`
  font: 12px 'Roboto', sans-serif;
  font-weight: 500;
  margin-bottom: 3px;
  text-align: start !important;
`;

export const TitleTextWrap = styled.h4`
  font: 12px 'Roboto', sans-serif;
  font-weight: 500;
  margin-bottom: 3px;
  text-align: start !important;
  max-width: 25ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const DescriptionText = styled.p`
  font-size: 12px;
  font-weight: 400;
  text-align: start !important;
  color: #8c8c8c !important;
`;

export const Subtitles = styled(Space)`
  font-size: 12px;
  margin-top: 20px;
`;

export const Cell = styled(Table.Summary.Cell)`
  background-color: rgba(245, 245, 245, 0.5);
`;

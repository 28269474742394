import { AxiosError, AxiosResponse } from 'axios';
import api from '../../config/index';
import FilterTagByOrder from '~/@core/domain/filters/tag/FilterTagByOrder';
import { TagByOrder } from '~/@core/domain/model/tag';
import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';

export const getTagByOrder = (_params: FilterTagByOrder) => {
  const params = new URLSearchParams();
  Object.entries(_params).forEach(([key, value]) => {
    if (
      key === 'Data.productIds' &&
      value &&
      Array.isArray(value) &&
      value.length
    ) {
      value.forEach((productId: number) => {
        params.append('Data.ProductIds', String(productId));
      });
      return;
    }
    if (value) {
      params.append(key, String(value));
    }
  });

  return new Promise<DefaultPaginated<TagByOrder[]>>((resolve, reject) => {
    api
      .get<void, AxiosResponse<DefaultPaginated<TagByOrder[]>>>('/tag', {
        params
      })
      .then((response: AxiosResponse<DefaultPaginated<TagByOrder[]>>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

import { AxiosResponse } from 'axios';
import * as API from 'domain/api';
import { APIError } from '~/domain/api/errors';
import { VolOffer } from '~/domain/model';

import apiProducer from '../api';

export const create = async (directedOffer: API.DirectedOffer) => {
  try {
    const response: any = await apiProducer.post(
      '/vol-offer/directed',
      directedOffer
    );
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.errors.messages[0]));
    }

    if (dataError.errors.messages.length) {
      throw new APIError(dataError.errors.messages);
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const updateCustomers = async ({
  volOfferId,
  customers
}: {
  volOfferId: number;
  customers: {
    customerId: number;
    customerGroupId: number;
  }[];
}) => {
  try {
    const response: any = await apiProducer.put(
      `/vol-offer/directed/${volOfferId}/customers`,
      [...customers]
    );
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.errors.messages[0]));
    }

    if (dataError.errors.messages.length) {
      throw new APIError(dataError.errors.messages);
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const fetchMany = async (params: any) => {
  try {
    const response: AxiosResponse = await apiProducer.get(
      '/vol-offer/directed',
      {
        params
      }
    );
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const fetchManyPrincipalOffers = async (params: any) => {
  try {
    let url = `/vol-offer?`;
    const entries = Object.entries(params);

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of entries) {
      if (key !== 'pagination' && value !== undefined) {
        url += `Data.${key}=${value}&`;
      }
    }
    const response: AxiosResponse<{ totalCount: number; results: VolOffer[] }> =
      await apiProducer.get(url);
    const results = response.data.results.map(result => new VolOffer(result));
    return results;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const fetchConsumptionHistory = async (params: number) => {
  try {
    const response: AxiosResponse = await apiProducer.get(
      `/vol-offer/consumed-history/${params}`
    );

    return response.data;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

import { AxiosError, AxiosResponse } from 'axios';
import FilterRemoveLocation from '~/@core/domain/filters/RemoveLocation/FilterRemoveLocation';
import RemoveLocation from '~/@core/domain/model/removeLocation/RemoveLocation';
import api from '../config/index';

export const fetchRemoveLocations = (params: FilterRemoveLocation) => {
  return new Promise<RemoveLocation[]>((resolve, reject) => {
    api
      .get<void, AxiosResponse<RemoveLocation[]>>('/order/remove-locations', {
        params
      })
      .then((response: AxiosResponse<RemoveLocation[]>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

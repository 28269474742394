import { CompetencesState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.core.competences as CompetencesState;
};

export const getCompetencesById = createSelector([getRoot], state => {
  return state.competenceByID;
});

export const getIsCleared = createSelector(
  [getRoot],
  state => state.status === 'cleared'
);

import { AxiosError, AxiosResponse } from 'axios';
import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';
import FilterData from '~/@core/domain/filters/rfidReports/FilterData';
import ExpeditionDone from '~/@core/domain/model/rfidReports/ExpeditionDone';
import { apiRfid } from '../../config';

export const fetchVisions = (params: FilterData) => {
  return new Promise<DefaultPaginated<ExpeditionDone[]>>((resolve, reject) => {
    apiRfid
      .get<void, AxiosResponse<DefaultPaginated<ExpeditionDone[]>>>(
        'api/expedition/vision',
        {
          params
        }
      )
      .then((response: AxiosResponse<DefaultPaginated<ExpeditionDone[]>>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const fetchNonConformities = (params: FilterData) => {
  return new Promise<DefaultPaginated<ExpeditionDone[]>>((resolve, reject) => {
    apiRfid
      .get<void, AxiosResponse<DefaultPaginated<ExpeditionDone[]>>>(
        'api/expedition/nonConformity',
        {
          params
        }
      )
      .then((response: AxiosResponse<DefaultPaginated<ExpeditionDone[]>>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const fetchNonConformitiesGeral = (params: FilterData) => {
  return new Promise<DefaultPaginated<ExpeditionDone[]>>((resolve, reject) => {
    apiRfid
      .get<void, AxiosResponse<DefaultPaginated<ExpeditionDone[]>>>(
        'api/expedition/nonConformityGeral',
        {
          params
        }
      )
      .then((response: AxiosResponse<DefaultPaginated<ExpeditionDone[]>>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const fetchDones = (params: FilterData) => {
  return new Promise<DefaultPaginated<ExpeditionDone[]>>((resolve, reject) => {
    apiRfid
      .get<void, AxiosResponse<DefaultPaginated<ExpeditionDone[]>>>(
        'api/expedition/dones',
        {
          params
        }
      )
      .then((response: AxiosResponse<DefaultPaginated<ExpeditionDone[]>>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const fetchTransit = (params: FilterData) => {
  return new Promise<DefaultPaginated<ExpeditionDone[]>>((resolve, reject) => {
    apiRfid
      .get<void, AxiosResponse<DefaultPaginated<ExpeditionDone[]>>>(
        'api/expedition/transit',
        {
          params
        }
      )
      .then((response: AxiosResponse<DefaultPaginated<ExpeditionDone[]>>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

import { call, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import service from '~/services/api';
import * as API from '~/domain/api';
import { actions } from './duck';
import { actions as uiActions } from '../../_ui';
import { VolOffer, VolOfferHistory, VolOfferType } from '~/domain/model';
import { VolOffersListAction } from '../../_ui/volOffersList/duck';
import {
  VolOffersCreateAction,
  VolOffersUpdateAction
} from '../../_ui/volOffersCreate/duck';
import { generateErrorMessage } from '~/helpers/util/error';

export function* findMany(payload: VolOffersListAction): any {
  try {
    let url = `/vol-offer?`;
    const entries = Object.entries(payload);

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of entries) {
      if (key !== 'pagination' && value !== undefined) {
        url += `Data.${key}=${value}&`;
      }
    }

    if (payload.pagination) {
      url += `PageSize=${payload.pagination.pageSize}&PageNumber=${payload.pagination.pageNumber}`;
    }
    const response: AxiosResponse = yield call(service.get, url);
    const apiResponse = response.data as API.DefaultResponse;
    const results = apiResponse.results as API.VolOffer[];

    const volOffers = results.map(
      (value: API.VolOffer, index: number) => new VolOffer({ ...value, index })
    );

    yield put(
      uiActions.volOfferList.setPagination({
        totalCount: apiResponse.totalCount,
        pageNumber: payload.pagination?.pageNumber,
        pageSize: payload.pagination?.pageSize
      })
    );

    const keywordsArray = payload.Keyword?.split(' ');
    const isLetter = /^[a-zA-Z]/.test(payload.Keyword ?? '');
    let newTableData: VolOffer[] = volOffers;

    keywordsArray?.forEach((word: string) => {
      newTableData = newTableData.filter((item: any) =>
        item.productDescription.toLowerCase().includes(word.toLowerCase())
      );
    });

    yield put(
      actions.update({ volOffers: isLetter ? newTableData : volOffers })
    );
  } catch (err) {
    throw new Error(String(err));
  }
}

export function* create(payload: VolOffersCreateAction[]): any {
  const response = yield call(service.post, '/vol-offer', payload);

  if (response?.response?.status >= 400) {
    throw new Error(response);
  }
}

export function* findVolOfferTypes(): any {
  const response = yield call(service.get, '/vol-offer/types');
  if (response?.response?.status >= 400) {
    throw new Error(generateErrorMessage(response.response.data.Errors));
  }
  const results = response.data as API.VolOfferType[];
  const volOfferTypes = results.map(
    volOfferType => new VolOfferType(volOfferType)
  );
  yield put(uiActions.volOfferList.setVolOfferTypes(volOfferTypes));
}

export function* update(payload: VolOffersUpdateAction) {
  yield call(service.put, '/vol-offer', payload);
}

export function* deleteSubOffer(payload: number) {
  yield call(service.delete, `/vol-offer/${payload}`);
}
export function* findHistory(payload: number): any {
  const response = yield call(service.get, `/vol-offer/${payload}/history`);
  if (response?.response?.status >= 400) {
    throw new Error(generateErrorMessage(response.response.data.Errors));
  }
  const historyResult = response.data as API.History;
  const history = new VolOfferHistory(historyResult);
  yield put(uiActions.volOfferList.setHistory(history));
}

import { call, fork, put, takeLatest } from 'redux-saga/effects';

import * as API from '~/domain/api';

import { actions, FetchPage, Remove, Types } from './duck';
import { Action } from '~/helpers/util/reducers';
import * as CompetenceService from '~/store/modules/_core/competences/service';
import { CompetenceRemoveError, UnknownError } from '~/domain/api/errors';
import { alertRequest } from '../../alert/actions';

export function* watchFetchPage(action: Action<FetchPage>) {
  yield fork(fetchPage, action.payload);
}

export function* fetchPage(params: FetchPage): any {
  try {
    const { nextPage, nextPageSize, referenceYear } = params;

    const response: API.DefaultResponse = yield call(
      CompetenceService.findMany,
      nextPage,
      nextPageSize,
      referenceYear
    );
    const totalCount = response.totalCount ?? 0;

    yield put(actions.fetchPageSuccess({ nextPage, nextPageSize, totalCount }));
  } catch (err) {
    yield put(actions.fetchPageFailure());
  }
}

export function* removeSaga(action: Action<Remove>) {
  const { competenceIDs } = action.payload;
  try {
    yield call(CompetenceService.remove, competenceIDs);
    yield put(actions.removeSuccess());
  } catch (error: unknown) {
    if (error instanceof CompetenceRemoveError) {
      /* eslint-disable @typescript-eslint/no-unused-vars */
      const { iDsWithEvents, invalidIDs, message } = error;
      if (error.iDsWithEvents.length > 0) {
        yield put(
          actions.removeFailure({
            status: 'failure-has-events',
            iDsWithEvents
          })
        );
      } else if (error.invalidIDs.length > 0) {
        yield put(
          actions.removeFailure({
            status: 'failure-invalid-ids',
            invalidIDs
          })
        );
      }
      if (error.message) {
        yield put(alertRequest(error.message, 'error'));
      }
    } else if (error instanceof UnknownError) {
      yield put(
        actions.removeFailure({
          status: 'failure'
        })
      );
    }
  }
}

export default [
  takeLatest(Types.FETCH_PAGE, watchFetchPage),
  takeLatest(Types.REMOVE, removeSaga)
];

import * as API from '~/domain/api';

export default class Announcement {
  public readonly json: API.Announcement;
  public readonly id: number;
  public readonly description: string;
  public readonly environment: number;
  public readonly hasAttachments: number;
  public readonly role: number;
  public readonly creationDate: string;
  public readonly startDate: string;
  public readonly endDate: string;
  public readonly message: string;
  public readonly status: boolean;
  public readonly required: boolean;

  constructor(json: API.Announcement) {
    this.json = json;
    this.id = this.getId();
    this.description = this.getDescription();
    this.environment = this.getEnvironment();
    this.creationDate = this.getCreationDate();
    this.hasAttachments = this.getHasAttachments();
    this.startDate = this.getStartDate();
    this.endDate = this.getEndDate();
    this.status = this.getStatus();
    this.role = this.getRole();
    this.message = this.getMessage();
    this.required = this.getRequired();
  }

  private getId(): number {
    return this.json.id ?? Number.NaN;
  }

  private getRole(): number {
    return this.json.role ?? Number.NaN;
  }

  private getDescription(): string {
    return this.json.description ?? '';
  }

  private getMessage(): string {
    return this.json.message ?? '';
  }

  private getEnvironment(): number {
    return this.json.environment ?? '';
  }

  private getCreationDate(): string {
    return this.json.creationDate ?? '';
  }

  private getHasAttachments(): number {
    return this.json.hasAttachments ?? '';
  }

  private getStartDate(): string {
    return this.json.startDate ?? '';
  }

  private getEndDate(): string {
    return this.json.endDate ?? '';
  }

  private getStatus(): boolean {
    return this.json.status ?? false;
  }

  private getRequired(): boolean {
    return this.json.required ?? false;
  }
}

import produce from 'immer';
import { createCreator, createReducer, Reducer } from '~/helpers/util/reducers';

export type FetchStatus = 'idle' | 'fetching' | 'success' | 'failure';

export type CustomersGroupState = {
  fetchStatus: FetchStatus;
};

export type CreateInit = {
  id?: number;
  description?: string;
  customersIds?: number[];
  siteId?: number;
};

// export type Update = {
//   voucherById?: Map<string, Voucher>;
//   voucherHistoryById?: Map<string, VoucherHistory>;
// };

const prefix = '@customersGroup';

export const Types = Object.freeze({
  CLEAR: `${prefix}/CLEAR`,
  UPDATE: `${prefix}/UPDATE`
});

export const initialState: CustomersGroupState = {
  fetchStatus: 'idle'
};

const clearReducer: Reducer<CustomersGroupState, void> = state => {
  return produce(state, draft => {
    draft.fetchStatus = initialState.fetchStatus;
  });
};

// const updateReducer: Reducer<VoucherState, Update> = (state, action) => {
//   return produce(state, draft => {
//     if (action.voucherById) {
//       draft.voucherById = action.voucherById as Map<string, Voucher>;
//     }
//     if (action.voucherHistoryById) {
//       draft.voucherHistoryById = action.voucherHistoryById as Map<
//         string,
//         VoucherHistory
//       >;
//     }
//   });
// };

export const actions = Object.freeze({
  clear: createCreator<void>(Types.CLEAR)
  // update: createCreator<Update>(Types.UPDATE)
});

export const reducer = createReducer(initialState, {
  [Types.CLEAR]: clearReducer
  // [Types.UPDATE]: updateReducer
});

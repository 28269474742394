import produce from 'immer';
import { OfferChannel, HistoryOfferChannel } from '~/domain/model';
import { createCreator, createReducer, Reducer } from '~/helpers/util/reducers';

export type Status = 'cleared' | 'updated';

export type OfferChannelState = {
  offerChannelByID: Map<string, OfferChannel>;
  offerChannelHistoryById: Map<string, HistoryOfferChannel>;
  status: Status;
};

export type Update = {
  offerChannelByID?: Map<string, OfferChannel>;
  offerChannelHistoryById?: Map<string, HistoryOfferChannel>;
};

const prefix = '@offerChannel';

export const Types = Object.freeze({
  CLEAR: `${prefix}/CLEAR`,
  CLEAR_OFFER_CHANNEL_HISTORY: `${prefix}/CLEAR_OFFER_CHANNEL_HISTORY`,
  UPDATE: `${prefix}/UPDATE`
});

export const initialState: OfferChannelState = {
  offerChannelByID: new Map(),
  offerChannelHistoryById: new Map(),
  status: 'cleared'
};

const clearReducer: Reducer<OfferChannelState, void> = state => {
  return produce(state, draft => {
    draft.offerChannelByID = initialState.offerChannelByID;
    draft.offerChannelHistoryById = initialState.offerChannelHistoryById;
    draft.status = initialState.status;
  });
};

const clearOfferChannelHistoryReducer: Reducer<
  OfferChannelState,
  void
> = state => {
  return produce(state, draft => {
    draft.offerChannelHistoryById = initialState.offerChannelHistoryById;
  });
};

const updateReducer: Reducer<OfferChannelState, Update> = (state, action) => {
  return produce(state, draft => {
    if (action.offerChannelByID) {
      draft.offerChannelByID = action.offerChannelByID as Map<
        string,
        OfferChannel
      >;
    }

    if (action.offerChannelHistoryById) {
      draft.offerChannelHistoryById = action.offerChannelHistoryById as Map<
        string,
        HistoryOfferChannel
      >;
    }
  });
};

export const actions = Object.freeze({
  clear: createCreator<void>(Types.CLEAR),
  clearOfferChannelHistory: createCreator<void>(
    Types.CLEAR_OFFER_CHANNEL_HISTORY
  ),
  update: createCreator<Update>(Types.UPDATE)
});

export const reducer = createReducer(initialState, {
  [Types.CLEAR]: clearReducer,
  [Types.CLEAR_OFFER_CHANNEL_HISTORY]: clearOfferChannelHistoryReducer,
  [Types.UPDATE]: updateReducer
});

import { createAsyncThunk } from '@reduxjs/toolkit';
import FindBiDashboardParams from '~/domain/model/BiDashboard/FindBiDashboardParams';
import * as biDashboardService from '~/services/producer/BiDashboard';
import { alertRequest } from '../../alert/actions';
import { CreateBiDashboardParams } from '~/domain/model/BiDashboard/CreateBiDashboardParams';
import { generateErrorMessage } from '~/helpers/util/error';
import { EditByDashboardStatusProps } from '~/domain/model/BiDashboard/BiDashboardEdit';

export const fetchBiDashboard = createAsyncThunk(
  `biDashboards/fetchBiDashboard`,
  async (params: FindBiDashboardParams, { dispatch, rejectWithValue }) => {
    try {
      const response = await biDashboardService.findMany(params);
      return response;
    } catch (err: unknown) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao editar dashboard.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const deleteBiDashboard = createAsyncThunk(
  `biDashboards/deleteBiDashboard`,
  async (biDashboardId: number, { dispatch, rejectWithValue }) => {
    try {
      await biDashboardService.deleteBiDashboard(biDashboardId);
      dispatch(alertRequest('Dashboard removido com sucesso!', 'success'));
      return biDashboardId;
    } catch (err: unknown) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao editar dashboard.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const createBiDashboard = createAsyncThunk(
  `biDashboards/createBiDashboard`,
  async (
    createBiDashboardParams: CreateBiDashboardParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await biDashboardService.createBiDashboard(
        createBiDashboardParams
      );
      dispatch(alertRequest('Dashboard criado com sucesso!', 'success'));
      return response;
    } catch (err: unknown) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao editar dashboard.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const editBiDashboard = createAsyncThunk(
  `biDashboards/editBiDashboard`,
  async (
    editBiDashboardParams: CreateBiDashboardParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await biDashboardService.editBiDashboard(
        editBiDashboardParams
      );
      dispatch(alertRequest('Dashboard editado com sucesso!', 'success'));
      return response;
    } catch (err: unknown) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao editar dashboard.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const editBiDashboardStatus = createAsyncThunk(
  `biDashboards/editBiDashboard`,
  async (
    { id, status }: EditByDashboardStatusProps,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await biDashboardService.editBiDashboardStatus(id);
      dispatch(
        alertRequest(
          `Dashboard ${status ? 'inativado' : 'ativado'} com sucesso!`,
          'success'
        )
      );
      return response;
    } catch (err: unknown) {
      const errorMessage = generateErrorMessage(
        err,
        `Erro ao ${status ? 'inativar' : 'ativar'} dashboard.`
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Status = 'idle' | 'fetching' | 'success' | 'failure';

const prefix = '@loading';
export type LoadingState = {
  status: Status;
  submitForm: boolean;
  closeModal: boolean;
  disableSave: boolean;
};
export type SetLoadingStateAction = {
  status: Status;
};
const initialState: LoadingState = {
  status: 'idle',
  submitForm: false,
  closeModal: false,
  disableSave: false
};
const loading = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<SetLoadingStateAction>) => {
      state.status = action.payload.status;
    },
    setSubmitForm: (state, action: PayloadAction<boolean>) => {
      state.submitForm = action.payload;
    },
    setCloseModal: (state, action: PayloadAction<boolean>) => {
      state.closeModal = action.payload;
    },
    setDisableSave: (state, action: PayloadAction<boolean>) => {
      state.disableSave = action.payload;
    }
  }
});
export const actions = loading.actions;
export const reducer = loading.reducer;

/* eslint-disable @typescript-eslint/no-unused-vars */
const caseReducers = loading.caseReducers;
export const Types = Object.freeze({});

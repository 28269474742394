import styled from 'styled-components';

export const GFPCartAssemble = styled.button`
  position: fixed;
  padding: 12px 24px;
  bottom: 0;
  right: 0;
  margin: 0 24px 24px 0;
  z-index: 999;
  border: 0;
  border-radius: 100px;
  background-color: #f78e1e;
  color: white;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  font-size: 16px;
  cursor: pointer;
`;

// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as M from '~/domain/model';
import { validatePlpProductRegisterMandatoryRules } from '~/helpers/util/validatorPlp';
import * as plpApiCalls from './apiCalls';

export interface PlpState {
  addTableData: M.PlpProductRegister[];
  resumeTableData: M.PlpProductRegister[];
  selectedPlp?: M.Plp;
  plps: M.Plp[];
  historicPagination: M.DefaultPagination;
  pagination: M.DefaultPagination;
  totalHistoric: number;
  totalPlps: number;
  productsRegisters: M.PlpProductRegister[];
  historic?: M.PlpHistoric[];
  closeAddStockModal: boolean;
  addModalVisible: boolean;
  addMassUpdateModalVisible: boolean;
  massUpdateModalVisible: boolean;
  historicModalVisible: boolean;
  selectedAddKeys: number[];
  submitAddFormFilter: boolean;
  submitFormFilter: boolean;
  tableInputErrors: M.TableInputError[];
  loading: boolean;
  disabledSave: boolean;
  validated: boolean;
  submitNow: boolean;
  currentStep: number;
}

const initialState: PlpState = {
  addTableData: [],
  resumeTableData: [],
  plps: [],
  historicPagination: { PageSize: 100 } as M.DefaultPagination,
  pagination: { PageSize: 100 } as M.DefaultPagination,
  totalHistoric: 0,
  totalPlps: 0,
  productsRegisters: [],
  closeAddStockModal: false,
  addModalVisible: false,
  addMassUpdateModalVisible: false,
  massUpdateModalVisible: false,
  historicModalVisible: false,
  selectedAddKeys: [],
  submitAddFormFilter: false,
  submitFormFilter: false,
  tableInputErrors: [],
  loading: false,
  disabledSave: true,
  validated: false,
  submitNow: false,
  currentStep: 0
};

export const prefix = 'plp';

const plpSlice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: () => initialState,
    clearRegisters: state => {
      state.productsRegisters = [];
      state.addTableData = [];
      state.resumeTableData = [];
      state.validated = false;
    },
    setAddTableData: (state, action: PayloadAction<M.PlpProductRegister[]>) => {
      state.addTableData = action.payload;
    },
    setHistoricPagination: (
      state,
      action: PayloadAction<M.DefaultPagination>
    ) => {
      state.historicPagination = action.payload;
    },
    setPagination: (state, action: PayloadAction<M.DefaultPagination>) => {
      state.pagination = action.payload;
    },
    setTotalStocks: (state, action: PayloadAction<number>) => {
      state.totalPlps = action.payload;
    },
    setPropertyValue: (
      state,
      action: PayloadAction<{
        value: any;
        property: string;
        id: number;
      }>
    ) => {
      const { payload } = action;
      const updatedItems = state.plps.map(item => {
        if (payload.id === item.id) {
          return {
            ...item,
            toSend: true,
            [payload.property]: payload.value
          } as M.Plp;
        }
        return { ...item } as M.Plp;
      });
      state.plps = updatedItems;
      state.disabledSave = false;
    },
    setAddTableInputValue: (
      state,
      action: PayloadAction<{
        value: any;
        property: string;
        id: number;
      }>
    ) => {
      const { payload } = action;
      let updatedItem: any = {};
      const updatedTableData: any[] = state.addTableData?.map((item: any) => {
        if (item.id === payload.id) {
          const updatedData = item;
          updatedData[payload.property] = payload.value;
          updatedData.toSend = Boolean(
            updatedData.a1Price || updatedData.a2Price
          );
          updatedItem = updatedData;
          return updatedData;
        }
        return item;
      });
      const updatedProductRegisters = state.productsRegisters.map(item => {
        if (item.id === updatedItem?.id) {
          return updatedItem;
        }
        return item;
      });
      const updatedResumeTableData = state.resumeTableData.map(item => {
        if (item.id === updatedItem?.id) {
          return updatedItem;
        }
        return item;
      });
      state.addTableData = updatedTableData;
      state.resumeTableData = updatedResumeTableData;
      state.productsRegisters = updatedProductRegisters;
      state.validated = false;
    },
    addMassUpdate: (
      state,
      action: PayloadAction<{
        ids: number[];
        a1Price: number;
        a2Price: number;
        bPrice: number;
      }>
    ) => {
      const { payload } = action;
      state.addTableData = state.addTableData.map(item => {
        if (payload.ids.includes(item.id)) {
          return {
            ...item,
            a1Price:
              payload.a1Price ||
              state.addTableData.find(i => i.id === item.id)?.a1Price ||
              0,
            a2Price:
              payload.a2Price ||
              state.addTableData.find(i => i.id === item.id)?.a2Price ||
              0,
            bPrice: payload.bPrice,
            toSend: true
          };
        }
        return item;
      });
      state.resumeTableData = state.resumeTableData.map(item => {
        if (payload.ids.includes(item.id)) {
          return {
            ...item,
            a1Price: payload.a1Price,
            a2Price: payload.a2Price,
            bPrice: payload.bPrice,
            toSend: true
          };
        }
        return item;
      });
      state.productsRegisters = state.productsRegisters.map(item => {
        if (payload.ids.includes(item.id)) {
          return {
            ...item,
            a1Price: payload.a1Price,
            a2Price: payload.a2Price,
            bPrice: payload.bPrice,
            toSend: true
          };
        }
        return item;
      });
      state.validated = false;
      state.addMassUpdateModalVisible = false;
    },
    validatePlpRegisters: state => {
      const errorMessages: M.TableInputError[] = [];
      const resumeTableData: M.PlpProductRegister[] = [];

      let productsRegistersAux: M.PlpProductRegister[] = [];
      let tableData: M.PlpProductRegister[] = [];

      if (state.currentStep === 0) {
        productsRegistersAux = state.productsRegisters;
        tableData = state.addTableData;
        productsRegistersAux.forEach(product => {
          if ((product.a1Price || product.a2Price) && product.toSend) {
            validatePlpProductRegisterMandatoryRules(product)?.forEach(error =>
              errorMessages.push(error)
            );
            resumeTableData.push({
              ...product,
              index: productsRegistersAux.indexOf(product)
            });
          }
        });
      } else {
        tableData = state.resumeTableData;
        tableData.forEach((item, index) => {
          if (item.toSend) {
            validatePlpProductRegisterMandatoryRules(item)?.forEach(error =>
              errorMessages.push(error)
            );
            resumeTableData.push({ ...item, index });
          }
        });
      }

      if (errorMessages.length === 0) {
        state.resumeTableData = resumeTableData;
        state.tableInputErrors = [];
        if (resumeTableData.length) {
          state.validated = true;
          state.submitNow = state.currentStep === 1;
          state.currentStep = 1;
        }
        return;
      }
      state.tableInputErrors = errorMessages;
      state.validated = false;
    },
    setSubmitNow: (state, action: PayloadAction<boolean>) => {
      state.submitNow = action.payload;
    },
    nextStep: state => {
      state.currentStep += 1;
    },
    previousStep: state => {
      state.currentStep -= 1;
    },
    removeTableRow: (state, action: PayloadAction<number>) => {
      state.addTableData = state.addTableData.map(item => {
        if (action.payload === item.id) {
          return {
            ...item,
            a1Price: 0,
            a2Price: 0,
            bPrice: 0,
            toSend: false
          };
        }
        return item;
      });
      state.productsRegisters = state.productsRegisters.map(item => {
        if (action.payload === item.id) {
          return {
            ...item,
            a1Price: 0,
            a2Price: 0,
            bPrice: 0,
            toSend: false
          };
        }
        return item;
      });
      state.resumeTableData = state.resumeTableData?.filter(
        item => item.id !== action.payload
      );
    },
    setProductsRegister: (state, action: PayloadAction<any[]>) => {
      state.productsRegisters = action.payload;
    },
    setSelectedPlp: (state, action: PayloadAction<M.Plp>) => {
      state.selectedPlp = action.payload;
    },
    setCloseModal: (state, action: PayloadAction<boolean>) => {
      state.closeAddStockModal = action.payload;
    },
    setHistoricModalVisible: (state, action: PayloadAction<boolean>) => {
      state.historicModalVisible = action.payload;
    },
    setAddModalVisible: (state, action: PayloadAction<boolean>) => {
      state.addModalVisible = action.payload;
    },
    setAddMassUpdateModalVisible: (state, action: PayloadAction<boolean>) => {
      state.addMassUpdateModalVisible = action.payload;
    },
    setMassUpdateModalVisible: (state, action: PayloadAction<boolean>) => {
      state.massUpdateModalVisible = action.payload;
    },
    setSubmitFormFilter: (state, action: PayloadAction<boolean>) => {
      state.submitFormFilter = action.payload;
    },
    resetStockReducer: state => {
      state.plps = [];
      state.pagination = { PageSize: 100 } as M.DefaultPagination;
    },
    setSelectedAddKey: (state, action: PayloadAction<number>) => {
      const updated = state.selectedAddKeys.filter(
        key => key !== action.payload
      );
      state.selectedAddKeys = [...updated, action.payload];
    },
    removeSelectedAddKey: (state, action: PayloadAction<number>) => {
      state.selectedAddKeys = state.selectedAddKeys.filter(
        key => key !== action.payload
      );
    },
    selectAllKeys: state => {
      state.selectedAddKeys =
        state.addTableData
          ?.filter(item => !item.plpAlreadyExists)
          ?.map(item => item.id) ?? [];
    },
    removeAllSelectedAddKeys: state => {
      state.selectedAddKeys = [];
    }
  },
  extraReducers: builder => {
    builder.addCase(plpApiCalls.fetch.pending, () => {
      return { ...initialState, loading: true };
    });
    builder.addCase(plpApiCalls.fetch.fulfilled, (state, action) => {
      state.plps = action.payload.results;
      state.totalPlps = action.payload.totalCount;
      state.loading = false;
    });
    builder.addCase(plpApiCalls.fetch.rejected, state => {
      state.loading = false;
    });
    builder.addCase(plpApiCalls.update.pending, state => {
      state.loading = true;
    });
    builder.addCase(plpApiCalls.update.fulfilled, () => {
      return {
        ...initialState,
        submitFormFilter: true
      };
    });
    builder.addCase(plpApiCalls.update.rejected, () => {
      return {
        ...initialState,
        submitFormFilter: true
      };
    });
    builder.addCase(plpApiCalls.updateStatus.pending, state => {
      state.loading = true;
    });
    builder.addCase(plpApiCalls.updateStatus.fulfilled, () => {
      return {
        ...initialState,
        submitFormFilter: true
      };
    });
    builder.addCase(plpApiCalls.updateStatus.rejected, () => {
      return {
        ...initialState,
        submitFormFilter: true
      };
    });
    builder.addCase(plpApiCalls.fetchRegisters.pending, state => {
      state.loading = true;
    });
    builder.addCase(plpApiCalls.fetchRegisters.fulfilled, (state, action) => {
      state.loading = false;
      state.productsRegisters = action.payload;
      state.addTableData = action.payload;
    });
    builder.addCase(plpApiCalls.fetchRegisters.rejected, state => {
      state.loading = false;
    });
    builder.addCase(plpApiCalls.createRegisters.pending, state => {
      state.loading = true;
    });
    builder.addCase(plpApiCalls.createRegisters.fulfilled, () => {
      return {
        ...initialState,
        submitFormFilter: true
      };
    });
    builder.addCase(plpApiCalls.createRegisters.rejected, state => {
      state.loading = false;
      state.submitNow = false;
    });
    builder.addCase(plpApiCalls.updateMass.pending, state => {
      state.loading = true;
    });
    builder.addCase(plpApiCalls.updateMass.fulfilled, () => {
      return {
        ...initialState,
        submitFormFilter: true
      };
    });
    builder.addCase(plpApiCalls.updateMass.rejected, state => {
      state.loading = false;
    });
    builder.addCase(plpApiCalls.fetchHistoric.pending, state => {
      state.loading = true;
    });
    builder.addCase(plpApiCalls.fetchHistoric.fulfilled, (state, action) => {
      state.historic = action.payload.results;
      state.totalHistoric = action.payload.totalCount;
      state.loading = false;
    });
    builder.addCase(plpApiCalls.fetchHistoric.rejected, state => {
      state.loading = false;
    });
  }
});

export const actions = plpSlice.actions;
export const reducer = plpSlice.reducer;

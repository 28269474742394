import styled from 'styled-components';

export const Content = styled.div`
  min-height: 580px;
  .my-powerbi-embed {
    width: 100%;
    height: 89vh;
    border: 1px solid #ccc;
  }
`;

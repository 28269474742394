import { createSelector } from '~/helpers/util/selectors';
import { DeliveryPatternListState } from './duck';

export const getRoot = (state: any) => {
  return state.ui.deliveryPatternList as DeliveryPatternListState;
};

export const getIsLoading = createSelector(
  [getRoot],
  state => state.fetchStatus === 'fetching'
);
export const getIsSuccess = createSelector(
  [getRoot],
  state => state.fetchStatus === 'success'
);

export const getSiteId = createSelector([getRoot], state => state.siteId);

export const getSubmitForm = createSelector(
  [getRoot],
  state => state.submitForm
);

import { CompetenceEventsState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.core.competenceEvents as CompetenceEventsState;
};

export const getCompetenceEventById = createSelector(
  [getRoot],
  state => state.competenceEventByID
);

export const getIsLoading = createSelector(
  [getRoot],
  state => state.fetchStatus === 'fetching'
);

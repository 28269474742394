import { AxiosResponse } from 'axios';
import { APIError } from '~/domain/api/errors';
import DeleteImageParams from '~/domain/model/Offers/DeleteImageParams';
import FetchImagesResponse from '~/domain/model/Offers/FetchImagesResponse';
import ReorderImagesParams from '~/domain/model/Offers/ReorderImagesParams';
import UploadImagesParams from '~/domain/model/Offers/UploadImagesParams';
import apiProducer from '../api';

export const changeStatus = async (volOfferId: number) => {
  try {
    const response: any = await apiProducer.put(
      `/vol-offer/status/${volOfferId}`
    );
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.errors.messages[0]));
    }

    if (dataError.errors.messages.length) {
      throw new APIError(dataError.errors.messages);
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const fetchImagesRequest = async (volOfferId: number) => {
  const response = await apiProducer.get<
    number,
    AxiosResponse<FetchImagesResponse>
  >(`/vol-offer/${volOfferId}`);
  return response.data;
};

export const changeFlagUseProductPreferenceRequest = async (
  volOfferId: number
) => {
  const response = await apiProducer.put<void, AxiosResponse<any>>(
    `/vol-offer/flag-use-product-preference/${volOfferId}`
  );
  return response.data;
};

export const deleteImageRequest = async (params: DeleteImageParams) => {
  await apiProducer.delete<any, AxiosResponse<any>>(
    `/vol-offer/${params.volOfferId}/images/${params.fileId}`
  );
};

export const reorderImagesRequest = async (payload: ReorderImagesParams) => {
  const params = {
    volOfferId: payload.volOfferId,
    imagesPosition: payload.newList.map((image, i) => {
      return {
        imageId: image.imageId,
        position: i + 1
      };
    })
  };
  const response = await apiProducer.put<
    number,
    AxiosResponse<FetchImagesResponse>
  >(`/vol-offer/images-position`, params);
  return response.data;
};

export const uploadImagesRequest = async (payload: UploadImagesParams) => {
  const formData = new FormData();
  formData.append('volOfferId', payload.volOfferId.toString());
  payload.files.forEach((uploadFile: any, index: number) => {
    formData.append(`Image0${index + 1}`, uploadFile.originFileObj);
  });
  const response = await apiProducer.post<
    any,
    AxiosResponse<FetchImagesResponse>
  >(`/vol-offer/${payload.volOfferId}/images`, formData);
  return response.data;
};

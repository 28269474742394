import { PackingAdjustQuantityState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.ui.packingAdjustQuantity as PackingAdjustQuantityState;
};

export const getStatusAdjustQuantity = createSelector(
  [getRoot],
  state => state.status
);

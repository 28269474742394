import { call, put } from 'redux-saga/effects';

import { UploadFile } from 'antd/lib/upload/interface';
import * as API from '~/domain/api';

import { BannerApproval as BannerProducerManagement } from '~/domain/model';
import { actions, FetchBannerProducerManagement } from './duck';
import * as BannerProducerManagementService from '~/services/producer/BannerProducerManagement';

export function* fetchBannerProducerManagement(
  params: FetchBannerProducerManagement
) {
  yield put(actions.update({ bannerProducerManagementList: [] }));
  const {
    description,
    url,
    status,
    bannerTypeId,
    uploadDate,
    pageNumber,
    pageSize
  } = params;
  const response: API.ServerResponse = yield call(
    BannerProducerManagementService.fetchBannerProducerManagement,
    description,
    url,
    status,
    bannerTypeId,
    uploadDate,
    pageNumber,
    pageSize
  );
  const data: API.DefaultResponse = response.data;
  const bannerProducerManagementResult: API.BannerApproval[] =
    data.results ?? [];
  let bannerProducerManagementList: BannerProducerManagement[] = [];
  if (bannerProducerManagementResult) {
    bannerProducerManagementList = bannerProducerManagementResult.map(
      (bannerProducerManagement: any) =>
        new BannerProducerManagement(bannerProducerManagement)
    );
  }
  yield put(actions.update({ bannerProducerManagementList }));
  return response;
}

export function* fetchBannerImage(id: number) {
  yield put(actions.update({ bannerImage: undefined }));

  const response: API.ServerResponse = yield call(
    BannerProducerManagementService.fetchBannerImage,
    id
  );
  const data: string = response.data;
  yield put(actions.update({ bannerImage: data }));
  return response;
}

export function* activeOrInactiveBanner(id: number) {
  yield call(BannerProducerManagementService.activeOrInactiveBanner, id);
}

export function* create(
  description: string,
  url: string,
  bannerTypeId: number,
  bannerImage: UploadFile
) {
  yield call(
    BannerProducerManagementService.create,
    description,
    url,
    bannerTypeId,
    bannerImage
  );
}

import { AxiosResponse } from 'axios';
import { APIError } from '~/domain/api/errors';
import apiProducer from '../api';
import * as API from '~/domain/api';

export const upsert = async (offerSettings: API.OfferSettingsParam) => {
  try {
    const response: any = await apiProducer.put(
      '/offer-settings',
      offerSettings
    );
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const fetchBySiteId = async (siteId: number) => {
  try {
    const URL = `/offer-settings/${siteId}`;
    const response: AxiosResponse = await apiProducer.get(URL);

    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const fetchCountingPatternHistoryBySiteId = async (siteId: number) => {
  try {
    const URL = `/offer-settings/counting-pattern/history/${siteId}`;
    const response: AxiosResponse = await apiProducer.get(URL);

    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const fetchCompositionHistoryBySiteId = async (siteId: number) => {
  try {
    const URL = `/offer-settings/composition/history/${siteId}`;
    const response: AxiosResponse = await apiProducer.get(URL);

    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const fetchMinimumAmountCutProductHistoryBySiteId = async (
  siteId: number
) => {
  try {
    const URL = `/offer-settings/minimun-amount-cut-product/history/${siteId}`;
    const response: AxiosResponse = await apiProducer.get(URL);

    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

import { ProductPreferenceState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.core.productPreferences as ProductPreferenceState;
};

export const getProductPreferencesById = createSelector([getRoot], state => {
  return state.productPreferencesByID;
});

export const getIsCleared = createSelector(
  [getRoot],
  state => state.status === 'cleared'
);

export const getProductPreference = createSelector(
  [getRoot],
  state => state.productPreference
);

export const getCompositionsById = createSelector(
  [getRoot],
  state => state.compositionsById
);

/* eslint-disable @typescript-eslint/no-explicit-any */
import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '~/services/api';

import {
  searchProductGroupsFailure,
  searchProductGroupsSuccess,
  searchProductCategoriesSuccess,
  searchProductGroupsSiteSuccess,
  searchProductGroupsSiteFailure
} from './actions';

export function* onSearchGroups({ payload }: any): any {
  try {
    const response = yield call(
      api.get,
      `/production-product-group?categoryId=${payload.categoryId}&siteId=${payload.siteId}`
    );
    const { data } = response;
    yield put(searchProductGroupsSuccess(data));
  } catch (err) {
    yield put(searchProductGroupsFailure());
  }
}

export function* onSearchGroupsSite({ payload }: any): any {
  try {
    const response = yield call(
      api.get,
      `/production-product-group?siteId=${payload.siteId}`
    );
    const { data } = response;
    yield put(searchProductGroupsSiteSuccess(data));
  } catch (err) {
    yield put(searchProductGroupsSiteFailure());
  }
}

export function* onSearchCategories({ payload }: any): any {
  try {
    const response = yield call(api.get, `/product-category/${payload.siteId}`);
    const { data } = response;
    yield put(searchProductCategoriesSuccess(data));
  } catch (err) {
    yield put(searchProductGroupsFailure());
  }
}

export function* onUpdate() {
  //
}

export default all([
  takeLatest('@product/SEARCH_PRODUCT_GROUPS', onSearchGroups),
  takeLatest('@product/SEARCH_PRODUCT_CATEGORIES', onSearchCategories),
  takeLatest('@product/SEARCH_PRODUCT_GROUPS_SITE', onSearchGroupsSite)
]);

import { FormInstance, TablePaginationConfig } from 'antd';
import produce from 'immer';
import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand';
import GetOrders from '~/@core/application/useCases/order/getOrders';
import GfpCarts from '~/@core/domain/model/gfp/GfpCarts';
import Order from '~/@core/domain/model/order/Order';
import Stocks from '~/@core/domain/model/stock/Stocks';
import FormFilterGfpCartsModel from '~/@core/domain/ui/forms/FormFilterGfpCartsModel';
import FormFilterOrderModel, {
  MapFormToFilterOrder
} from '~/@core/domain/ui/forms/FormFilterOrderModel';
import ProductGroupWithCategories from '~/domain/model/PriceReport/ProductGroupWithCategories';
import { IProductSimple } from '~/domain/model/PriceReport/ProductSimple';
import ProductCategory from '~/domain/model/ProductCategory';
import Site from '~/domain/model/site';

export type ITableParams = {
  pagination: TablePaginationConfig;
};

export type ITable = {
  data: GfpCarts[];
  isLoading: boolean;
  isSuccessful: boolean;
  isError: boolean;
  errorMessage: string;
  tableParams: ITableParams;
};

export type ITableOrders = {
  data: Order[];
  isLoading: boolean;
  isSuccessful: boolean;
  isError: boolean;
  errorMessage: string;
  tableParams: ITableParams;
};

export type IActions = {
  setData: (fn: (state: IProductCatalog) => void) => void;
  handleChangeStocks: (value: any, property: string, index: number) => void;
  duplicateStock: (stockKey: number) => void;
  removeStock: (stockId: number) => void;
  // FIXME Removido para atender as necessidades dos clientes sem bugs para corrigir com mais calma
  // handleSearchCarts: () => Order[];
  handleSearchOrders: () => GfpCarts[];
};

export type ISelectors = {
  mappedFilterGroups: () => ProductGroupWithCategories[];
  isMainFiltersSuccessful: () => boolean;
  mappedFilterProduct: () => IProductSimple[];
  mappedSiteIds: () => number[];
  mappedFilters: () => IFilters;
  validProductIds: () => number[];
  validCategoryIds: () => number[];
  validGroupIds: () => number[];
  isDisabledExportButton: () => boolean;
};

export type IFormFilter = {
  siteIds: number[] | null;
  categoryIds?: number[] | null;
  commercialGroupIds?: number[] | null;
  productIds?: number[] | null;
};

export type IFilters = {
  sites: {
    data: Site[];
    isLoading: boolean;
    isSuccessful: boolean;
    isError: boolean;
    errorMessage: string;
  };
  categories: {
    data: ProductCategory[];
    isLoading: boolean;
    isSuccessful: boolean;
    isError: boolean;
    errorMessage: string;
  };
  groups: {
    data: ProductGroupWithCategories[];
    isLoading: boolean;
    isSuccessful: boolean;
    isError: boolean;
    errorMessage: string;
  };
  products: {
    data: IProductSimple[];
    isLoading: boolean;
    isSuccessful: boolean;
    isError: boolean;
    errorMessage: string;
  };
  formFilter: IFormFilter;
};

export type IProductCatalog = {
  table: ITable;
  tableOrders: ITableOrders;
  filters: IFilters;
  formFilterInstance: Partial<FormInstance<IFormFilter>> | null;
  isOpenRequestModal: boolean;
} & IActions &
  ISelectors;

export const initialStateTable: ITable = {
  data: [],
  isLoading: false,
  isSuccessful: false,
  isError: false,
  errorMessage: '',
  tableParams: {
    pagination: {
      current: 1,
      pageSize: 100,
      total: 0,
      showSizeChanger: true
    }
  }
};

export const initialStateTableOrders: ITableOrders = {
  data: [],
  isLoading: false,
  isSuccessful: false,
  isError: false,
  errorMessage: '',
  tableParams: {
    pagination: {
      current: 1,
      pageSize: 100,
      total: 0,
      showSizeChanger: true
    }
  }
};

interface IGfpService {
  tableParams: ITableParams;
  // FIXME Removido para atender as necessidades dos clientes sem bugs para corrigir com mais calma
  // handleSearchCarts: () => Promise<GfpCarts[]>;
  handleSearchOrders: () => Promise<Order[]>;
  stocks: Stocks[];
  setStocks: (stockValues: Stocks[]) => void;
  order: Order[];
  setOrder: (orderValues: Order[]) => void;
  stocksSelected: Stocks[];
  setStocksSelected: (stockSelectedValues: Stocks[]) => void;
  // #FIXME Remover any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleChangeStocks: (value: any, property: string, index: number) => void;
  duplicateStock: (stockKey: number) => void;
  removeStock: (stockKey: number) => void;
  selectedRowKeys: React.Key[];
  setSelectedRowKeys: (keys: React.Key[]) => void;
  selectedGfpIds: React.Key[];
  setSelectedGfpIds: (
    updater: React.Key[] | ((prev: React.Key[]) => React.Key[])
  ) => void;
  filters: FormFilterGfpCartsModel | null;
  filtersOrder: FormFilterOrderModel | null;
  setFilters: (filters: FormFilterGfpCartsModel) => void;
  setFiltersOrder: (filters: FormFilterOrderModel) => void;
  pagination: { pageSize: number; current: number; pageNumber: number };
  table: ITable;
  tableOrders: ITableOrders;
  setPagination: (pagination: {
    pageSize: number;
    current: number;
    pageNumber: number;
  }) => void;
  formFilterInstance: Partial<FormInstance<IFormFilter>> | null;
  setData: (fn: (state: IProductCatalog) => void) => GfpCarts[] | void;
  setDataOrders: (fn: (state: IProductCatalog) => void) => Order[] | void;
}

const useGfpStore = create<IGfpService>((set, get) => ({
  // States
  formFilterInstance: null,
  tableParams: initialStateTable.tableParams,
  tableOrders: initialStateTableOrders,
  table: initialStateTable,
  stocks: [],
  setStocks: stockValues => set({ stocks: stockValues }),
  order: [],
  setOrder: orderValues => set({ order: orderValues }),
  stocksSelected: [],
  setStocksSelected: stockSelectedValues =>
    set({ stocksSelected: stockSelectedValues }),
  selectedRowKeys: [],
  setSelectedRowKeys: keys => set({ selectedRowKeys: keys }),
  selectedGfpIds: [],
  setSelectedGfpIds: updater =>
    set(state => ({
      selectedGfpIds:
        typeof updater === 'function' ? updater(state.selectedGfpIds) : updater
    })),
  filters: null,
  filtersOrder: null,
  setFilters: filters => set({ filters }),
  setFiltersOrder: filters => set({ filtersOrder: filters }),
  pagination: { pageSize: 100, current: 1, pageNumber: 1 },
  setPagination: pagination => set({ pagination }),

  // Setters
  setData: (fn: any) => set(produce(fn)),
  setDataOrders: (fn: any) => set(produce(fn)),
  setFormInstance: (form: any) => {
    set({ formFilterInstance: form });
  },

  // Actions
  handleChangeStocks: (value, property, index) => {
    set(state => {
      const stockMaped = state.stocks.map((stockLoop, i) => {
        if (i === index) {
          return {
            ...stockLoop,
            [property]: value,
            changed: true
          };
        }
        return stockLoop;
      });
      return { stocks: stockMaped };
    });
  },
  duplicateStock: stockKey => {
    const nextNumber = get().stocksSelected.length + 1;

    set(state => {
      const stockToDuplicate = state.stocksSelected.find(
        stock => stock.stockKey === stockKey
      );
      if (stockToDuplicate) {
        const newStock = {
          ...stockToDuplicate,
          stockKey: Number(`${stockToDuplicate.stockId}${nextNumber}`),
          uniqueId: uuidv4(),
          changed: true
        };
        return {
          stocks: [...state.stocks, newStock],
          stocksSelected: [...state.stocksSelected, newStock]
        };
      }
      return state;
    });
  },
  removeStock: stockKey =>
    set(state => {
      const updatedStocks = state.stocks.map(stock => {
        if (stock.stockId === Math.floor(stockKey / 10)) {
          return {
            ...stock,
            observationId1: '',
            observationId2: '',
            quantityPacking: '',
            quantityPerPacking: '',
            packingDescription: '',
            packingCode: '',
            packingId: null
          };
        }
        return stock;
      });
      return {
        stocks: updatedStocks,
        stocksSelected: state.stocksSelected.filter(
          stock => stock.stockKey !== stockKey
        )
      };
    }),
  // FIXME Removido para atender as necessidades dos clientes sem bugs para corrigir com mais calma
  // handleSearchCarts: async (): Promise<GfpCarts[]> => {
  //   const { filters, pagination } = get();
  //   if (!filters) {
  //     console.error('Filters cannot be null');
  //     return [];
  //   }
  //   const filter = MapFormToFilter.toFilter(filters);
  //   set({ table: { ...initialStateTable, isLoading: true } });
  //   try {
  //     // const response = await new GetGfpCarts().execute(filter);
  //     set({
  //       table: {
  //         // data: response.results,
  //         isLoading: false,
  //         isSuccessful: true,
  //         tableParams: {
  //           pagination: {
  //             ...pagination
  //             // total: response.totalCount
  //           }
  //         },
  //         isError: false,
  //         errorMessage: ''
  //       }
  //     });
  //     // return response.results;
  //   } catch (error: any) {
  //     set({
  //       table: {
  //         ...initialStateTable,
  //         isLoading: false,
  //         isSuccessful: false,
  //         isError: true,
  //         errorMessage: error.message
  //       }
  //     });
  //     return [];
  //   }
  // },
  handleSearchOrders: async (): Promise<Order[]> => {
    const { filtersOrder, pagination } = get();
    if (!filtersOrder) {
      console.error('Filters cannot be null');
      return [];
    }
    const filter = MapFormToFilterOrder.toFilter(filtersOrder, pagination);

    set({ tableOrders: { ...initialStateTableOrders, isLoading: true } });
    try {
      const response = await new GetOrders().execute(filter);

      set({
        tableOrders: {
          data: response.results,
          isLoading: false,
          isSuccessful: true,
          tableParams: {
            pagination: {
              ...pagination,
              total: response.totalCount
            }
          },
          isError: false,
          errorMessage: ''
        }
      });
      return response.results;
    } catch (error: any) {
      set({
        tableOrders: {
          ...initialStateTableOrders,
          isLoading: false,
          isSuccessful: false,
          isError: true,
          errorMessage: error.message
        }
      });
      return [];
    }
  }
}));

export default useGfpStore;

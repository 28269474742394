import { Col, Form, FormInstance, Select, SelectProps } from 'antd';
import { AxiosError } from 'axios';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GetExpeditionsBySiteId from '~/@core/application/useCases/inputs/getExpeditionsBySiteId';
import Expedition from '~/@core/domain/model/expedition/Expedition';
import { globalContext } from '~/context/GlobalContext';

interface Props extends SelectProps {
  form: FormInstance<any>;
  siteId: number;
  lgExpedition?: number;
}

export default function ExpeditionSelect({
  form,
  siteId,
  lgExpedition,
  ...props
}: Props) {
  const { handlePromiseUseCase, activeAlert } = useContext(globalContext);

  const { t } = useTranslation();
  const [expeditions, setExpeditions] = useState<Expedition[]>([]);

  useEffect(() => {
    if (siteId) {
      const getExpeditionsBySiteId = new GetExpeditionsBySiteId();

      handlePromiseUseCase<Expedition[], AxiosError>(
        getExpeditionsBySiteId.execute(siteId),
        expedBySite => {
          setExpeditions(expedBySite);
        },
        error => {
          activeAlert({
            message: JSON.stringify(
              error.response?.data
                ? error.response?.data
                : 'Houve um erro inesperado!'
            ),
            type: 'error',
            timeout: 5000
          });
        }
      );
    }
    onChangeSite();
    resetOnClear(['ExpeditionId']);
  }, [siteId]);

  const resetOnClear = useCallback(
    (fields: string[]) => {
      form.resetFields(fields);
    },
    [form]
  );

  const onChangeSite = useCallback(() => {
    setExpeditions([]);
  }, []);

  return (
    <Col lg={lgExpedition ?? 6}>
      <Form.Item name="ExpeditionId" label={t('generals.filters.expedition')}>
        <Select
          placeholder={t('generals.selectPlaceholder')}
          allowClear
          optionFilterProp="children"
          filterOption
          showSearch
          {...props}
        >
          {expeditions?.map((expedition: Expedition) => (
            <Select.Option key={expedition.id} value={expedition.id}>
              {expedition.name ?? expedition.id}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
  );
}

export const changeFocusWithRef = (
  event: React.KeyboardEvent<HTMLInputElement>,
  columnIndex: number,
  rowIndex: number,
  tableRef: React.RefObject<any>
) => {
  const getId = (difference: number) => {
    switch (event?.key) {
      case 'ArrowDown': {
        return `idToFocus-${columnIndex}-${rowIndex + difference}`;
      }
      case 'ArrowUp': {
        return `idToFocus-${columnIndex}-${rowIndex - difference}`;
      }
      case 'ArrowLeft': {
        return `idToFocus-${columnIndex - difference}-${rowIndex}`;
      }
      case 'ArrowRight': {
        return `idToFocus-${columnIndex + difference}-${rowIndex}`;
      }
      default:
        return null;
    }
  };
  let index = 1;
  let shouldContinue = true;
  do {
    const targetElementId = getId(index);
    if (targetElementId === null) {
      shouldContinue = false;
      break;
    }
    if (index === 1) {
      event?.preventDefault();
    }
    const targetElement = tableRef.current.querySelector(
      `input[id="${targetElementId}"]`
    ) as HTMLInputElement;
    if (!targetElement) {
      console.log('Element not found');
      shouldContinue = false;
      break;
    }
    const computedStyle = getComputedStyle(targetElement);
    if (targetElement.disabled || computedStyle.display === 'none') {
      // ATENÇÂO se o parent estiver com display none não funciona, tem que ser o elemento em si.
      console.log(
        `Target element [${targetElementId}] is disabled or hidden => continue;`
      );
      // eslint-disable-next-line no-continue
      shouldContinue = true;
    } else {
      targetElement.focus();
      shouldContinue = false;
    }
    index++;
  } while (shouldContinue);
};

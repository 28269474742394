export const getRefreshToken = (): string => {
  return localStorage.getItem('refreshToken') as string;
};

export const getAccessToken = (): string => {
  return localStorage.getItem('accessToken') as string;
};

export const getTokenType = (): string => {
  return localStorage.getItem('tokenType') as string;
};
export const getExpiresIn = (): string => {
  return localStorage.getItem('expiresIn') as string;
};
export const getScope = (): string => {
  return localStorage.getItem('scope') as string;
};

type Tokens = {
  refreshToken: string;
  accessToken: string;
  tokenType: string;
  expiresIn: string;
  scope: string;
};
export const setTokens = (tokens: Tokens) => {
  localStorage.setItem('refreshToken', tokens.refreshToken);
  localStorage.setItem('accessToken', tokens.accessToken);
  localStorage.setItem('tokenType', tokens.tokenType);
  localStorage.setItem('expiresIn', tokens.expiresIn);
  localStorage.setItem('scope', tokens.scope);
};
export const getTokens = (): Tokens => {
  return {
    refreshToken: getRefreshToken(),
    accessToken: getAccessToken(),
    tokenType: getTokenType(),
    expiresIn: getExpiresIn(),
    scope: getScope()
  };
};
export const removeTokens = () => {
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('tokenType');
  localStorage.removeItem('expiresIn');
  localStorage.removeItem('scope');
  redirectSingleSignOn();
};
export const redirectSingleSignOn = () => {
  window.location.href =
    `${process.env.REACT_APP_SINGLE_SIGNON}logoff` as string;
};

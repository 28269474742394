export function searchCompetences() {
  return {
    type: '@product/SEARCH_COMPETENCES'
  };
}

export function searchCompetenceSuccess(data: any) {
  return {
    type: '@product/SEARCH_COMPETENCES_SUCCESS',
    payload: data
  };
}

export function searchCompetenceFailure() {
  return {
    type: '@product/SEARCH_PRODUCT_GROUPS_FAILURE'
  };
}

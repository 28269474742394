import apiProducer from '../api';
import { endpoints } from '~/constants/endpoints';

export const fetchPropertiesRequest = async (params: any) => {
  const entries = Object.entries(params);
  let url = endpoints.property.base;
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of entries) {
    if (key !== 'pagination' && value !== undefined) {
      url += `Data.${key.replace('Data_', '')}=${value}&`;
    }
  }
  url += 'PageNumber=1&PageSize=5000';
  const response = await apiProducer.get(`${url}`);
  return response.data;
};

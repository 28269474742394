import { AxiosResponse } from 'axios';
import { endpoints } from '~/constants/endpoints';
import * as M from '~/domain/model';
import StockConvertQuality from '~/domain/model/StockConvertQuality';

import Stocks from '~/domain/model/Stock/Stocks';
import api from '../api';
import ResponseApiStock from '~/domain/model/ResponseApiStock';

export const findStocksRegister = async (
  fetchStockParams: M.FetchStockParams
) => {
  const response = await api.get<
    M.FetchStockParams,
    AxiosResponse<M.StockRegister>
  >(endpoints.stock.stockRegister, {
    params: fetchStockParams
  });
  return response.data;
};

export const createStocksRegisterApi = async (
  createStockParams: M.CreateStockParams
) => {
  const response = await api.post(
    endpoints.stock.stockRegister,
    createStockParams
  );
  return response;
};

export const findStocks = async (fetchStockParams: M.FindStockParams) => {
  const response = await api.get<
    M.FetchStockParams,
    AxiosResponse<ResponseApiStock<Stocks[]>>
  >(endpoints.stock.stocks, {
    params: fetchStockParams
  });
  return response.data;
};

export const findStocksDirected = async (
  fetchStockParams: M.FindDirectedOfferStockParams
) => {
  const response = await api.get<
    M.FetchStockParams,
    AxiosResponse<ResponseApiStock<Stocks[]>>
  >(endpoints.stock.stocksDirected, {
    params: fetchStockParams
  });
  return response.data;
};

export const updateStocksApi = async (updateStockParams: M.StocksUpdate) => {
  const response = await api.put(endpoints.stock.stocks, updateStockParams);
  return response;
};

export const findOfferChannel = async (stockId: number) => {
  const response = await api.get<void, AxiosResponse<M.StockOfferChannel>>(
    `${endpoints.stock.offerChannels}${stockId}`
  );
  return response.data;
};
export const updateStockMassApi = async (
  updateStockMassParams: M.StockMassUpdateParams
) => {
  const response = await api.put(endpoints.stock.mass, updateStockMassParams);
  return response;
};
export const replicateStockApi = async (
  stockReplicateParams: M.StockReplicateParams
) => {
  const response = await api.put(
    endpoints.stock.replicate,
    stockReplicateParams
  );
  return response.data;
};

export const convertQualityApi = async (
  stockConvertQualityParams: StockConvertQuality
) => {
  const response = await api.put(
    endpoints.stock.convert,
    stockConvertQualityParams
  );
  return response.data;
};

export const findHistoric = async (stockId: number) => {
  const response = await api.get<void, AxiosResponse<M.StockHistory>>(
    `${endpoints.stock.historic}${stockId}`
  );
  return response.data;
};

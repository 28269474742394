import { AxiosResponse } from 'axios';
import apiProducer from '../api';

import { endpoints } from '~/constants/endpoints';
import { ResponseApi } from '~/domain/model';
import { KeyLiberationFetchParams } from '~/domain/model/KeyLiberation/KeyLiberationFetchParams';
import { KeyLiberationFetchResponse } from '~/domain/model/KeyLiberation/KeyLiberationFetchResponse';
import { KeyLiberationHistoryFetchParams } from '~/domain/model/KeyLiberation/KeyLiberationHistoryFetchParams';
import { KeyLiberationHistory } from '~/domain/model/KeyLiberation/KeyLiberationHistory';
import { KeyLiberationAddParams } from '~/domain/model/KeyLiberation/KeyLiberationAddParams';
import { KeyLiberationAddResponse } from '~/domain/model/KeyLiberation/KeyLiberationAddResponse';
import { KeyLiberationChangeStatusParams } from '~/domain/model/KeyLiberation/KeyLiberationChangeStatusParams';

export const keyLiberationFetchRequest = ({
  customerId,
  liberated,
  OrderBy,
  PageNumber,
  PageSize,
  Sort
}: KeyLiberationFetchParams) => {
  const params = new URLSearchParams();
  if (customerId) {
    params.append('Data.CustomerId', String(customerId));
  }
  if (liberated !== undefined) {
    params.append('Data.Liberated', String(liberated));
  }
  if (OrderBy) {
    params.append('OrderBy', String(OrderBy));
  }
  if (PageNumber) {
    params.append('PageNumber', String(PageNumber));
  }
  if (PageSize) {
    params.append('PageSize', String(PageSize));
  }
  if (Sort) {
    params.append('Sort', String(Sort));
  }
  return apiProducer.get<
    void,
    AxiosResponse<ResponseApi<KeyLiberationFetchResponse[]>>
  >(endpoints.keyLiberation.base, {
    params
  });
};

export const keyLiberationHistoryFetchRequest = ({
  id
}: KeyLiberationHistoryFetchParams) => {
  return apiProducer.get<void, AxiosResponse<KeyLiberationHistory[]>>(
    `${endpoints.keyLiberation.history}/${id}`
  );
};

export const keyLiberationAddRequest = (formData: KeyLiberationAddParams) => {
  return apiProducer.post<
    void,
    AxiosResponse<ResponseApi<KeyLiberationAddResponse[]>>
  >(endpoints.keyLiberation.base, formData);
};

export const keyLiberationChangeStatusRequest = (id: number) => {
  return apiProducer.put<void, AxiosResponse<any>>(
    `${endpoints.keyLiberation.status}/${id}`
  );
};

export const keyLiberationChangeAllStatusRequest = (
  params: KeyLiberationChangeStatusParams
) => {
  return apiProducer.put<void, AxiosResponse<any>>(
    endpoints.keyLiberation.statusAll,
    params
  );
};

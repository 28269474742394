import { Menu } from 'antd';

interface ITableDropdownProps {
  onClick: () => void;
  disabled?: boolean;
}

export default function TableDropdownGfp({
  onClick,
  disabled
}: ITableDropdownProps) {
  return (
    <Menu>
      <Menu.Item onClick={onClick} disabled={disabled}>
        Cancelar GFPs
      </Menu.Item>
    </Menu>
  );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductDetailed, RecipientDetailed } from '~/domain/model';
import * as API from '~/domain/api';

export type FetchStatus = 'idle' | 'fetching' | 'success' | 'failure';

export type ProductState = {
  productsById: Map<string, ProductDetailed>;
  productQualities: API.ProductQualityType[];
  productGroups: API.ProductGroup[];
  recipients: string[];
  detailedRecipients: RecipientDetailed[];
};
export type Update = {
  productsByID: Map<string, ProductDetailed>;
};

const prefix = '@product';

export const initialState: ProductState = {
  productsById: new Map(),
  productQualities: [] as API.ProductQualityType[],
  productGroups: [] as API.ProductGroup[],
  recipients: [] as string[],
  detailedRecipients: [] as RecipientDetailed[]
};

const product = createSlice({
  name: prefix,
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Update>) => {
      state.productsById = action.payload.productsByID;
    },
    updateProductQualities: (
      state,
      action: PayloadAction<API.ProductQualityType[]>
    ) => {
      state.productQualities = action.payload;
    },
    updateProductGroups: (state, action: PayloadAction<API.ProductGroup[]>) => {
      state.productGroups = action.payload;
    },
    updateRecipients: (state, action: PayloadAction<string[]>) => {
      state.recipients = action.payload;
    },
    updateDetailedRecipients: (
      state,
      action: PayloadAction<RecipientDetailed[]>
    ) => {
      state.detailedRecipients = action.payload;
    },
    // This function clear all states used in AdvancedFilters
    clearFilterFields: draft => {
      draft.productGroups = initialState.productGroups;
      draft.productQualities = initialState.productQualities;
      draft.productsById = initialState.productsById;
      draft.recipients = initialState.recipients;
      draft.detailedRecipients = initialState.detailedRecipients;
    }
  }
});
export const actions = product.actions;
export const reducer = product.reducer;

const caseReducers = product.caseReducers;
export const Types = Object.freeze({
  update: `${prefix}/${caseReducers.update.name}`,
  updateProductQualities: `${prefix}/${caseReducers.updateProductQualities.name}`,
  updateProductGroups: `${prefix}/${caseReducers.updateProductGroups.name}`
});

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationError } from '~/domain/api/errors';
import * as API from '~/domain/api';
import { FetchGfps, FetchHistory } from '../../_core/lkpOffers/duck';
import { ProductsPackingSummary } from '~/domain/model';

export type FetchStatus = 'idle' | 'pending' | 'success' | 'failure';
export type CreateStatus = 'idle' | 'pending' | 'success' | 'failure';

export type LkpOfferListState = {
  siteId?: number;
  weekId?: number;
  selectedWeek?: number;
  fetchInitStatus: FetchStatus;
  fetchProductPackingSummaryStatus: FetchStatus;
  fetchFiltersStatus: FetchStatus;
  fetchWorkSheetStatus: FetchStatus;
  fetchLkpOffersStatus: FetchStatus;
  fetchLkpOffersParams: FetchLkpOffers;
  fetchProductGroupsStatus: FetchStatus;
  fetchProductsStatus: FetchStatus;
  fetchHistoryStatus: FetchStatus;
  fetchGfpsStatus: FetchStatus;
  createStatus: CreateStatus;
  errorMessage?: string;
  fetchSaveChanges: boolean;
  fetchWorkSheetInit: boolean;
  pageNumber: number;
  pageSize: number;
  totalCount: number;
  pageNumberGfps: number;
  pageSizeGfps: number;
  totalCountGfps: number;
  lkpOfferHistory: API.LkpOfferHistory[];
  lkpOfferGfps: API.LkpOfferGfps[];
  productPackingSummary: ProductsPackingSummary[];
  reloadOffers: boolean;
  confirmSaveLkp: boolean;
};

export type FetchPage = {
  pageNumber: number;
  pageSize: number;
};
export type SetTotalAction = {
  totalCount: number;
};
export type FetchLkpOffersSuccess = {
  pageNumber?: number;
  pageSize?: number;
  totalCount: number;
};

export type FetchFailure = {
  error?: ApplicationError;
};

export type FetchProductPackingSummary = {
  siteId: number;
  productId: number;
};

export type Error = {
  errorMessage?: string;
};

const prefix = '@lkpOfferList';

export const initialState: LkpOfferListState = {
  fetchInitStatus: 'idle',
  reloadOffers: false,
  selectedWeek: undefined,
  fetchWorkSheetStatus: 'idle',
  fetchProductPackingSummaryStatus: 'idle',
  fetchFiltersStatus: 'idle',
  fetchLkpOffersStatus: 'idle',
  fetchLkpOffersParams: {} as FetchLkpOffers,
  fetchSaveChanges: false,
  fetchWorkSheetInit: false,
  fetchProductGroupsStatus: 'idle',
  fetchProductsStatus: 'idle',
  fetchHistoryStatus: 'idle',
  fetchGfpsStatus: 'idle',
  createStatus: 'idle',
  pageNumber: 1,
  pageSize: 100,
  totalCount: 0,
  pageNumberGfps: 1,
  pageSizeGfps: 100,
  totalCountGfps: 0,
  lkpOfferHistory: [],
  productPackingSummary: [],
  lkpOfferGfps: [],
  confirmSaveLkp: false
};

export type FetchFilters = {
  siteId: number;
};

export type FetchProductGroups = {
  siteId: number;
  categoryId?: number;
};

export type FetchProducts = {
  siteId: number;
  groupId?: number;
};

export type FetchLkpOffers = {
  siteId: number;
  startDate: string;
  endDateStock: string;
  productId?: number;
  categoryId?: number;
  expeditionId?: number;
  groupId?: number;
  productQualityId?: number;
  productRecipient?: string;
  status?: boolean;
  pageNumber?: number;
  pageSize?: number;
  lkpOfferId?: any;
  ColorId?: number;
  keyword?: string;
};

export type FetchWorkSheetLkpOffers = {
  siteId: number;
  weekId: number;
  productId?: number;
  categoryId?: number;
  groupId?: number;
  productQualityId?: number;
  pageNumber?: number;
  pageSize?: number;
};

export type FetchHistorySuccess = {
  lkpOfferHistory: API.LkpOfferHistory[];
};

export type FetchGfpsSuccess = {
  lkpOfferGfps: API.LkpOfferGfps[];
  totalCount: number;
  pageSize: number;
  pageNumber: number;
};

const lkpOfferList = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: state => {
      state.fetchInitStatus = initialState.fetchInitStatus;
      state.fetchLkpOffersStatus = initialState.fetchLkpOffersStatus;
      state.createStatus = initialState.createStatus;
      state.fetchFiltersStatus = initialState.fetchFiltersStatus;
    },
    clearGfps: state => {
      state.fetchGfpsStatus = initialState.fetchGfpsStatus;
      state.lkpOfferGfps = initialState.lkpOfferGfps;
      state.totalCountGfps = 0;
      state.pageNumberGfps = 1;
      state.pageSizeGfps = 100;
    },
    clearHistory: state => {
      state.fetchHistoryStatus = initialState.fetchHistoryStatus;
      state.lkpOfferHistory = initialState.lkpOfferHistory;
    },
    clearWorkSheet: state => {
      state.fetchWorkSheetInit = initialState.fetchWorkSheetInit;
      state.fetchWorkSheetStatus = initialState.fetchWorkSheetStatus;
    },
    fetchWorkSheet: (state, action: PayloadAction<FetchWorkSheetLkpOffers>) => {
      state.fetchWorkSheetStatus = 'pending';
      action.payload.pageNumber = state.pageNumber;
      action.payload.pageSize = state.totalCount;
      action.payload.weekId = state.weekId ?? 0;
    },
    fetchInit: state => {
      state.fetchInitStatus = 'pending';
    },
    fetchSuccessInit: state => {
      state.fetchInitStatus = 'success';
    },
    fetchSaveChanges: (state, action: PayloadAction<boolean>) => {
      state.fetchSaveChanges = action.payload;
    },
    fetchWorkSheetInit: (state, action: PayloadAction<boolean>) => {
      state.fetchWorkSheetInit = action.payload;
    },
    fetchSuccess: (state, action: PayloadAction<FetchLkpOffersSuccess>) => {
      state.fetchInitStatus = 'success';
      state.totalCount = action.payload.totalCount;
      state.pageSize = action.payload.pageSize ?? 100;
      state.pageNumber = action.payload.pageNumber ?? 1;
    },
    fetchFailure: state => {
      state.fetchInitStatus = 'failure';
    },
    setSelectedWeek: (state, action: PayloadAction<number>) => {
      state.selectedWeek = action.payload;
    },
    setPagination: (state, action: PayloadAction<FetchPage>) => {
      state.pageNumber = action.payload.pageNumber;
      state.pageSize = action.payload.pageSize;
    },
    setTotal: (state, action: PayloadAction<SetTotalAction>) => {
      state.totalCount = action.payload.totalCount;
    },
    setPaginationGfps: (state, action: PayloadAction<FetchPage>) => {
      state.pageNumberGfps = action.payload.pageNumber;
      state.pageSizeGfps = action.payload.pageSize;
    },
    setSelectedSiteId: (state, action: PayloadAction<number>) => {
      state.siteId = action.payload;
    },
    setSelectedWeekId: (state, action: PayloadAction<number>) => {
      state.weekId = action.payload;
    },
    fetchFilters: (state, _action: PayloadAction<number>) => {
      state.fetchFiltersStatus = 'pending';
    },
    fetchFiltersSuccess: state => {
      state.fetchFiltersStatus = 'success';
    },
    fetchFiltersFailure: state => {
      state.fetchFiltersStatus = 'failure';
    },
    fetchLkpOffers: (state, action: PayloadAction<FetchLkpOffers>) => {
      state.fetchLkpOffersStatus = 'pending';

      let { pageNumber, pageSize } = action.payload;
      if (!pageNumber) {
        pageNumber = initialState.pageNumber;
        state.pageNumber = pageNumber;
      }
      if (!pageSize) {
        pageSize = initialState.pageSize;
        state.pageSize = pageSize;
      }

      state.fetchLkpOffersParams = action.payload;
    },
    fetchLkpOffersSuccess: (
      state,
      action: PayloadAction<FetchLkpOffersSuccess>
    ) => {
      state.fetchLkpOffersStatus = 'success';
      state.totalCount = action.payload.totalCount;
    },
    fetchLkpOffersFailure: (state, _action: PayloadAction<Error | void>) => {
      state.fetchLkpOffersStatus = 'failure';
    },
    fetchProductGroups: (state, _action: PayloadAction<FetchProductGroups>) => {
      state.fetchProductGroupsStatus = 'pending';
    },
    fetchProductGroupsSuccess: state => {
      state.fetchProductGroupsStatus = 'success';
    },
    fetchProductGroupsFailure: state => {
      state.fetchProductGroupsStatus = 'failure';
    },
    fetchProducts: (state, _action: PayloadAction<FetchProducts>) => {
      state.fetchProductsStatus = 'pending';
    },
    fetchProductsSuccess: state => {
      state.fetchProductsStatus = 'success';
    },
    fetchWorkSheetSuccess: state => {
      state.fetchWorkSheetStatus = 'success';
      state.fetchWorkSheetInit = false;
    },
    fetchProductsFailure: state => {
      state.fetchProductsStatus = 'failure';
    },
    fetchWorkSheetFailure: state => {
      state.fetchWorkSheetStatus = 'failure';
      state.fetchWorkSheetInit = false;
    },
    fetchHistory: (state, _action: PayloadAction<FetchHistory>) => {
      state.fetchHistoryStatus = 'pending';
    },
    fetchProductPackingSummary: (
      state,
      action: PayloadAction<FetchProductPackingSummary>
    ) => {
      action.payload.siteId = state.siteId ?? 0;
      state.fetchProductPackingSummaryStatus = 'pending';
    },
    fetchProductPackingSummarySuccess: (
      state,
      action: PayloadAction<ProductsPackingSummary[]>
    ) => {
      state.fetchProductPackingSummaryStatus = 'success';
      state.productPackingSummary = action.payload;
    },
    fetchProductPackingSummaryFailure: (
      state,
      _action: PayloadAction<Error | void>
    ) => {
      state.fetchProductPackingSummaryStatus = 'failure';
      state.productPackingSummary = [];
    },
    fetchHistorySuccess: (
      state,
      action: PayloadAction<FetchHistorySuccess>
    ) => {
      state.fetchHistoryStatus = 'success';
      state.lkpOfferHistory = action.payload.lkpOfferHistory;
    },
    fetchHistoryFailure: state => {
      state.fetchHistoryStatus = 'failure';
    },
    fetchGfps: (state, action: PayloadAction<FetchGfps>) => {
      state.fetchGfpsStatus = 'pending';
      let { pageNumber, pageSize } = action.payload;
      if (!pageNumber) {
        pageNumber = initialState.pageNumberGfps;
      }
      if (!pageSize) {
        pageSize = initialState.pageSizeGfps;
      }
      state.pageNumberGfps = pageNumber;
      state.pageSizeGfps = pageSize;
    },
    fetchGfpsSuccess: (state, action: PayloadAction<FetchGfpsSuccess>) => {
      state.fetchGfpsStatus = 'success';
      state.lkpOfferGfps = action.payload.lkpOfferGfps;
      state.totalCountGfps = action.payload.totalCount;
      state.pageSizeGfps = action.payload.pageSize ?? 10;
      state.pageNumberGfps = action.payload.pageNumber ?? 1;
    },
    fetchGfpsFailure: state => {
      state.fetchGfpsStatus = 'failure';
    },
    reloadOffers: (state, action: PayloadAction<boolean>) => {
      state.reloadOffers = action.payload;
    },
    setConfirmSaveLkp: (state, action: PayloadAction<boolean>) => {
      state.confirmSaveLkp = action.payload;
    }
  }
});
export const actions = lkpOfferList.actions;
export const reducer = lkpOfferList.reducer;

const caseReducers = lkpOfferList.caseReducers;
export const Types = Object.freeze({
  clear: `${prefix}/${caseReducers.clear.name}`,
  clearGfps: `${prefix}/${caseReducers.clearGfps.name}`,
  clearHistory: `${prefix}/${caseReducers.clearHistory.name}`,
  fetchInit: `${prefix}/${caseReducers.fetchInit.name}`,
  fetchWorkSheet: `${prefix}/${caseReducers.fetchWorkSheet.name}`,
  fetchWorkSheetSuccess: `${prefix}/${caseReducers.fetchWorkSheetSuccess.name}`,
  fetchWorkSheetFailure: `${prefix}/${caseReducers.fetchWorkSheetFailure.name}`,
  fetchWorkSheetInit: `${prefix}/${caseReducers.fetchWorkSheetInit.name}`,
  fetchSaveChanges: `${prefix}/${caseReducers.fetchSaveChanges.name}`,
  fetchSuccess: `${prefix}/${caseReducers.fetchSuccess.name}`,
  fetchSuccessInit: `${prefix}/${caseReducers.fetchSuccessInit.name}`,
  fetchFailure: `${prefix}/${caseReducers.fetchFailure.name}`,
  setPagination: `${prefix}/${caseReducers.setPagination.name}`,
  setSelectedSiteId: `${prefix}/${caseReducers.setSelectedSiteId.name}`,
  setSelectedWeekId: `${prefix}/${caseReducers.setSelectedWeekId.name}`,
  fetchFilters: `${prefix}/${caseReducers.fetchFilters.name}`,
  fetchFiltersSuccess: `${prefix}/${caseReducers.fetchFiltersSuccess.name}`,
  fetchFiltersFailure: `${prefix}/${caseReducers.fetchFiltersFailure.name}`,
  fetchLkpOffers: `${prefix}/${caseReducers.fetchLkpOffers.name}`,
  fetchLkpOffersSuccess: `${prefix}/${caseReducers.fetchLkpOffersSuccess.name}`,
  fetchLkpOffersFailure: `${prefix}/${caseReducers.fetchLkpOffersFailure.name}`,
  fetchProductGroups: `${prefix}/${caseReducers.fetchProductGroups.name}`,
  fetchProductGroupsSuccess: `${prefix}/${caseReducers.fetchProductGroupsSuccess.name}`,
  fetchProductGroupsFailure: `${prefix}/${caseReducers.fetchProductGroupsFailure.name}`,
  fetchProducts: `${prefix}/${caseReducers.fetchProducts.name}`,
  fetchProductsSuccess: `${prefix}/${caseReducers.fetchProductsSuccess.name}`,
  fetchProductsFailure: `${prefix}/${caseReducers.fetchProductsFailure.name}`,
  fetchProductPackingSummary: `${prefix}/${caseReducers.fetchProductPackingSummary.name}`,
  fetchProductPackingSummarySuccess: `${prefix}/${caseReducers.fetchProductPackingSummarySuccess.name}`,
  fetchProductPackingSummaryFailure: `${prefix}/${caseReducers.fetchProductPackingSummaryFailure.name}`,
  fetchHistory: `${prefix}/${caseReducers.fetchHistory.name}`,
  fetchHistorySuccess: `${prefix}/${caseReducers.fetchHistorySuccess.name}`,
  fetchHistoryFailure: `${prefix}/${caseReducers.fetchHistoryFailure.name}`,
  fetchGfps: `${prefix}/${caseReducers.fetchGfps.name}`,
  fetchGfpsSuccess: `${prefix}/${caseReducers.fetchGfpsSuccess.name}`,
  fetchGfpsFailure: `${prefix}/${caseReducers.fetchGfpsFailure.name}`
});

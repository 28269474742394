import { FormInstance, Table, Tooltip } from 'antd';
import Vision from '~/@core/domain/model/rfidReports/Vision';
import { DividerFrame, Frame, StatusCircle } from '~/components/Globals';
import useVisionStore from '~/zustand/rfidReports/receipt/vision-state';

interface Props {
  form: FormInstance<any>;
}
export default function TableReceiptVision(props: Props) {
  const { visions, visionPagination, handleVisionPagination } =
    useVisionStore();
  const { form } = props;

  return (
    <Frame ghost accordion defaultActiveKey="1">
      <label>
        <b>Porcentagem Inconformidade</b>
      </label>
      <br />
      {/* TODO: ADD PIE CHART */}
      {/* <PieChart
        data={[
          { title: 'Inconformidade', value: 100, color: '#F78E1E' },
          { title: 'Correto', value: 0, color: '#BBBBBD' }
        ]}
        lineWidth={40}
        style={{ width: '150px', marginLeft: '50px' }}
      /> */}
      <Frame.Panel
        header={
          <DividerFrame orientation="left">
            Lista de Produtores com recebimento em inconformidade
          </DividerFrame>
        }
        key="1"
      >
        <Table
          dataSource={visions}
          pagination={{
            pageSize: visionPagination.pageSize,
            current: visionPagination.current,
            showSizeChanger: true,
            total: visionPagination.total,
            onChange(page, pageSize) {
              handleVisionPagination({
                ...visionPagination,
                current: page,
                pageSize
              });
              form.submit();
            }
          }}
        >
          <Table.Column
            dataIndex="status"
            render={(_, receiptVision: Vision) => {
              switch (receiptVision.status) {
                case '3':
                  return (
                    <Tooltip>
                      <StatusCircle color="#BBBBBD" />
                    </Tooltip>
                  );
                  break;
                case '1':
                  return (
                    <Tooltip>
                      <StatusCircle color="#52C41A" />
                    </Tooltip>
                  );
                  break;
                default:
                  return (
                    <Tooltip>
                      <StatusCircle color="#FA541C" />
                    </Tooltip>
                  );
                  break;
              }
            }}
            title="Status"
          />
          <Table.Column dataIndex="producerId" title="Produtor" />
          <Table.Column dataIndex="document" title="Romaneio" />
          <Table.Column dataIndex="sendDate" title="Data Retirada" />
          <Table.Column dataIndex="receiptDate" title="Data Recebimento" />
          <Table.Column dataIndex="numberCart" title="Total Carrinhos" />
          <Table.Column dataIndex="numberMC" title="Total MC" />
          <Table.Column dataIndex="nonConformity" title="Inconformidade" />
        </Table>
      </Frame.Panel>
    </Frame>
  );
}

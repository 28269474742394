// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { isNullOrEmpty } from '~/helpers/util';

import api from '~/services/api';
import * as API from '~/domain/api';
import { ProductionProperty } from '~/domain/model';

import {
  propertyListGenericSuccess,
  propertyListGenericFailure,
  propertyListRequest,
  propertyListSuccess,
  propertyListFailure,
  propertyAddSuccess,
  propertyAddFailure,
  propertyReadSuccess,
  propertyReadFailure,
  propertyDeleteSuccess,
  propertyDeleteFailure,
  propertyUpdateSuccess,
  propertyUpdateFailure,
  propertyActiveSuccess,
  propertyActiveFailure,
  propertyListFullSuccess,
  propertyListFullFailure,
  propertyReadRequest
} from './actions';
import { alertRequest } from '../alert/actions';

import store from '~/store';
import { productPropertyAttachmentTypesRequest } from '../productPropertyAttachmentTypes/actions';
import { attachmentFullListRequest } from '../attachment/actions';
import { PropertyModel } from '~/domain/model/PropertyModel';
import { formDataPostAndPuyProperty } from './formDataFunction';

const pagination = {
  pageNumber: 1,
  pageSize: 10,
  orderBy: 'Site.Account',
  orderAsc: 'ascend'
};

export function* onList({ payload }: any): any {
  try {
    const { filters } = payload;

    let params = '';
    let isSearch = false;

    if (!filters || filters.length <= 1) {
      isSearch = true;
      params += `${isNullOrEmpty(params) ? '?' : '&'}Search=%`;
    }

    if (Array.isArray(filters)) {
      filters.forEach(e => {
        if (Object.keys(e).length === 1) {
          if (Object.keys(e)[0] === 'Search') isSearch = true;
          params += `${isNullOrEmpty(params) ? '?' : '&'}${Object.keys(
            e
          )[0].replace('_', '.')}=${e[Object.keys(e)[0]]}`;
        }
      });

      if (!isSearch) params += `${isNullOrEmpty(params) ? '?' : '&'}Search=%`;
    }

    // paginação
    if (Array.isArray(filters)) {
      filters.forEach(e => {
        if (Object.keys(e).length > 1) {
          const iPageNumber = Object.keys(e).findIndex(a => a === 'pageNumber');
          const iPageSize = Object.keys(e).findIndex(a => a === 'pageSize');
          const iOrderBy = Object.keys(e).findIndex(a => a === 'orderBy');
          const iOrderAsc = Object.keys(e).findIndex(a => a === 'orderAsc');

          params += `${isNullOrEmpty(params) ? '?' : '&'}PageNumber=${
            e[Object.keys(e)[iPageNumber]]
          }&PageSize=${e[Object.keys(e)[iPageSize]]}&OrderBy=${
            e[Object.keys(e)[iOrderBy]]
          }&Sort=${e[Object.keys(e)[iOrderAsc]] === 'ascend' ? 'Asc' : 'Desc'}`;
        }
      });
    }

    const response = yield call(api.get, `/production-property${params}`);
    const result = response.data;

    const count =
      !result || result.results?.length <= 0 || !result.totalCount
        ? 0
        : result.totalCount;

    const jsons = result?.results as API.ProductionProperty[];

    const productionProperties = jsons.map(
      json => new ProductionProperty(json)
    );

    yield put(propertyListSuccess(productionProperties, count));
  } catch (e) {
    const err = !Object(e)?.response?.data?.error_description
      ? String(e)
      : Object(e)?.response?.data?.error_description;

    yield put(alertRequest(err, 'error'));
    yield put(propertyListFailure());
  }
}

export function* onListFull({ payload }: any): any {
  try {
    const { filters } = payload;
    let params = '';
    const keys = Object.keys(filters);

    if (keys.length > 0) {
      keys.forEach((key: string, index: number) => {
        params +=
          index > 0
            ? `&Data.${key}=${filters[key]}`
            : `?Data.${key}=${filters[key]}`;
      });
    }
    const response = yield call(api.get, `/production-property/full${params}`);

    const result = response.data;

    const count = result.totalCount;

    yield put(propertyListFullSuccess(result.results, count));
  } catch (err) {
    yield put(propertyListFullFailure());
  }
}

export function* onGeneric({ payload }: any): any {
  try {
    const { keyword } = payload;

    const params = '?PageNumber=1&PageSize=999999999';

    const response = yield call(
      api.get,
      `/production-property/search${params}&keyword=${keyword}`
    );

    const { data } = response;

    yield put(propertyListGenericSuccess(data));
  } catch (err) {
    yield put(alertRequest('Falha ao listar as propriedades', 'error'));
    yield put(propertyListGenericFailure());
  }
}

export function* onCreate({ payload }: any): any {
  try {
    const property: PropertyModel = payload.property;

    const formData = formDataPostAndPuyProperty(property);

    const response = yield call(api.post, `/production-property`, formData);

    if (response?.status >= 400) throw response;

    const data = response?.data;

    if (!data) throw response;
    if (data.Errors || data.errors) throw data;

    try {
      const responseGet = yield call(api.get, `/production-property/${data}`);

      yield put(propertyReadSuccess(responseGet.data));
    } catch (error) {
      yield put(propertyReadFailure());
    }

    yield put(propertyAddSuccess(data));

    yield put(alertRequest('Propriedade adicionada com sucesso!'));

    const str: any = store.getState();
    const { accounts } = str.ui.auth;

    if (accounts.length > 0) {
      const filter: any[] = [{ Data_AccountList: accounts }];
      filter.push(pagination);
      yield put(propertyListRequest(filter));
    } else yield put(propertyListRequest(null));
  } catch (e) {
    const err = Catch(e);
    yield put(alertRequest(err, 'error'));
    yield put(propertyAddFailure());
  }
}

export function* onUpdate({ payload }: any): any {
  try {
    const property: PropertyModel = payload.property;

    const formData = formDataPostAndPuyProperty(property);

    const response = yield call(api.put, `/production-property`, formData);

    const { data } = response;

    yield put(propertyUpdateSuccess(data));

    yield put(alertRequest('Propriedade atualizada com sucesso!'));

    const str: any = store.getState();
    const { accounts } = str.ui.auth;

    if (accounts.length > 0) {
      const filter: any[] = [{ Data_AccountList: accounts }];
      filter.push(pagination);
      yield put(propertyListRequest(filter));
    } else yield put(propertyListRequest(null));
    yield put(productPropertyAttachmentTypesRequest());
    yield put(attachmentFullListRequest(property?.id ?? 0));
    yield put(propertyReadRequest(property?.id ?? 0));
  } catch (e) {
    const err = Catch(e);
    yield put(alertRequest(err, 'error'));
    yield put(propertyUpdateFailure());
  }
}

export function* onRead({ payload }: any): any {
  try {
    const { id } = payload;

    const response = yield call(api.get, `/production-property/${id}`);

    const { data } = response;

    yield put(propertyReadSuccess(data));
  } catch (err) {
    yield put(alertRequest('Falha ao localizar a propriedade', 'error'));
    yield put(propertyReadFailure());
  }
}

export function* onDelete({ payload }: any): any {
  const { id, filters } = payload;

  try {
    yield call(api.delete, `/production-property/${id}`);

    yield put(propertyDeleteSuccess());

    yield put(alertRequest('Propriedade excluída com sucesso!'));

    yield put(propertyListRequest(filters));
  } catch (e) {
    const err = !Object(e)?.response?.data?.Errors?.Messages[0]
      ? String(e)
      : Object(e).response.data.Errors.Messages[0];
    yield put(alertRequest(err, 'error'));
    yield put(propertyDeleteFailure());
  }
}

export function* onActive({ payload }: any): any {
  const { id, active, filters } = payload;

  try {
    yield call(
      api.put,
      `/production-property/${id}/${active ? 'activate' : 'deactivate'}`
    );

    yield put(propertyActiveSuccess());

    yield put(
      alertRequest(
        `Propriedade ${active ? 'ativada' : 'desativada'} com sucesso!`
      )
    );

    yield put(propertyListRequest(filters));
  } catch (e) {
    const err = !Object(e)?.response?.data?.Errors?.Messages[0]
      ? String(e)
      : Object(e).response.data.Errors.Messages[0];
    yield put(alertRequest(err, 'error'));
    yield put(propertyActiveFailure());
  }
}

function Catch(e: any) {
  const obj = Object(e)?.response?.data
    ? Object(e).response.data
    : Object(e)?.data
    ? Object(e).data
    : Object(e);

  const err = obj.Errors?.Messages[0]
    ? obj.Errors.Messages[0]
    : obj.errors?.Area[0]
    ? obj.errors?.Area[0]
    : obj.errors?.area[0]
    ? obj.errors?.area[0]
    : obj.errors?.address?.cityId[0]
    ? obj.errors?.address?.cityId[0]
    : obj.errors?.address?.stateId[0]
    ? obj.errors?.address?.stateId[0]
    : obj?.toString();

  return err?.toString();
}

export default all([
  takeLatest('@property/LIST_REQUEST', onList),
  takeLatest('@property/LIST_REQUEST_FULL', onListFull),
  takeLatest('@property/LIST_GENERIC_REQUEST', onGeneric),
  takeLatest('@property/ADD_REQUEST', onCreate),
  takeLatest('@property/READ_REQUEST', onRead),
  takeLatest('@property/UPDATE_REQUEST', onUpdate),
  takeLatest('@property/DELETE_REQUEST', onDelete),
  takeLatest('@property/ACTIVE_REQUEST', onActive)
]);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as API from '~/domain/api';
import * as M from '~/domain/model';
import { ProductionPlanningHistory } from '~/domain/model/ProductionPlanning/ProductionPlanningHistory';
import { ProductionPlanningImportingHistory } from '~/domain/model/ProductionPlanning/ProductionPlanningImportingHistory';
import { ProductionPlanningImportingLog } from '~/domain/model/ProductionPlanning/ProductionPlanningImportingLog';
import { ProductionPlanningImportingLogDetails } from '~/domain/model/ProductionPlanning/ProductionPlanningImportingLogDetails';
import { ProductionPlanningReplicateResponse } from '~/domain/model/ProductionPlanning/ProductionPlanningReplicateResponse';
import ProductionPlanningTableCell from '~/domain/model/ProductionPlanning/ProductionPlanningTableCell';
import { ProductionPlanningUpdateCell } from '~/domain/model/ProductionPlanning/ProductionPlanningUpdateCell';
import { ProductionPlanningUpdatedWeek } from '~/domain/model/ProductionPlanning/ProductionPlanningUpdatedWeek';
import { ProductionPlanningWeekValues } from '~/domain/model/ProductionPlanning/ProductionPlanningWeekValues';
import { formatProductionPlanningColumnWeeks } from '~/helpers/productionPlanning';
import {
  fetchProductionPlanningReplicate,
  fetchProductionPlanningReplicateHistory,
  productionPlanningFetch,
  productionPlanningHistoryFetch,
  productionPlanningImport,
  productionPlanningImportingHistoryFetch,
  productionPlanningImportingLogDetailsFetch,
  productionPlanningImportingLogFetch,
  productionPlanningReplicateWeek,
  productionPlanningReplicateYear,
  productionPlanningSave
} from './apiCalls';

export interface ProductionPlanningsState {
  submitFormFilter: boolean;
  disableDownload: boolean;
  disableUpload: boolean;
  disableSave: boolean;
  selectedKeys: number[];
  importingHistory?: ProductionPlanningImportingHistory[];
  importingLog?: ProductionPlanningImportingLog | null;
  importingLogDetails: ProductionPlanningImportingLogDetails[];
  loading: boolean;
  loadingImportingLog: boolean;
  productGroupHistories: ProductionPlanningHistory[];
  closeImportModal: boolean;
  closeReplicateWeekModal: boolean;
  closeReplicateYearModal: boolean;
  updatedWeeks: ProductionPlanningUpdatedWeek[];
  currentFocusWeek: number;
  totalColumn: ProductionPlanningWeekValues[];
  totalRow: ProductionPlanningWeekValues[];
  columnWeeks: number[];
  competenceId: number;
  modificationDate: string;
  productionPlanningId: number;
  replicateEnable: boolean;
  siteId: number;
  specialDates: API.SpecialDate[];
  virtualTableDataSource: ProductionPlanningTableCell[][];
  visibleColumns: string[];
  prodPlanReplicate?: ProductionPlanningReplicateResponse[];
  totalCount: number;
  prodPlanReplicateHistory?: ProductionPlanningReplicateResponse[];
  replicateBudgetSuccess: boolean;
  prodPlanRepSiteId: number;
  prodPlanRepCompentenceId: number;
}

const initialState: ProductionPlanningsState = {
  submitFormFilter: false,
  disableDownload: true,
  disableSave: true,
  disableUpload: true,
  selectedKeys: [],
  importingLogDetails: [],
  loading: false,
  loadingImportingLog: false,
  productGroupHistories: [],
  closeImportModal: false,
  closeReplicateWeekModal: false,
  closeReplicateYearModal: false,
  updatedWeeks: [],
  currentFocusWeek: 1,
  totalColumn: [],
  totalRow: [],
  columnWeeks: formatProductionPlanningColumnWeeks(),
  competenceId: Number.NaN,
  modificationDate: '',
  productionPlanningId: Number.NaN,
  replicateEnable: false,
  siteId: Number.NaN,
  specialDates: [],
  virtualTableDataSource: [],
  visibleColumns: ['lastYear', 'budgeted', 'adjusted', 'done'],
  prodPlanReplicate: [],
  totalCount: 0,
  prodPlanReplicateHistory: [],
  replicateBudgetSuccess: false,
  prodPlanRepSiteId: 0,
  prodPlanRepCompentenceId: 0
};

export const prefix = 'productionPlannings';

const productionPlanningsSlice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: state => {
      return { ...initialState, siteId: state.siteId };
    },
    setCloseImportModal: (state, action: PayloadAction<boolean>) => {
      state.closeImportModal = action.payload;
    },
    setCurrentFocusWeek: (state, action: PayloadAction<number>) => {
      state.currentFocusWeek = action.payload;
    },
    decreaseCurrentFocusWeek: state => {
      const prevValue = state.currentFocusWeek;
      state.currentFocusWeek = prevValue > 1 ? prevValue - 1 : prevValue;
    },
    increaseCurrentFocusWeek: state => {
      const prevValue = state.currentFocusWeek;
      state.currentFocusWeek =
        prevValue < state.columnWeeks.length - 1 ? prevValue + 1 : prevValue;
    },
    setSwicthChangeTotalValue: (
      state,
      action: PayloadAction<{ weekNumber: number; checked: boolean }>
    ) => {
      const { payload } = action;
      const products: ProductionPlanningTableCell[][] =
        state.virtualTableDataSource;
      const week: any[] = [];
      products.forEach((product: ProductionPlanningTableCell[]) => {
        product.forEach((weekSelected: ProductionPlanningTableCell, index) => {
          if (index === payload.weekNumber) {
            week.push(weekSelected.week);
          }
        });
      });
      const updatedTotalValue: any | undefined = state.totalColumn?.map(
        (offer: ProductionPlanningWeekValues, index) => {
          if (offer && week.length > 0 && payload.checked) {
            const updatedData = offer;
            updatedData.adjusted -= week[index].adjusted;
            updatedData.budgeted -= week[index].budgeted;
            updatedData.done -= week[index].done;
            updatedData.lastYear -= week[index].lastYear;
            return updatedData;
          }
          if (offer && week.length > 0 && !payload.checked) {
            const updatedData = offer;
            updatedData.adjusted += week[index].adjusted;
            updatedData.budgeted += week[index].budgeted;
            updatedData.done += week[index].done;
            updatedData.lastYear += week[index].lastYear;
            return updatedData;
          }
          return offer;
        }
      );
      state.totalColumn = updatedTotalValue;
    },
    setUpdatedWeeks: (
      state,
      action: PayloadAction<{
        productionPlanningId: number;
        productionPlanningGroupId: number;
        productionProductGroupId: number;
        weekId: number;
        key: 'adjusted' | 'budgeted';
        newValue: number;
      }>
    ) => {
      state.disableSave = false;
      const {
        key,
        newValue,
        productionPlanningId,
        productionPlanningGroupId,
        productionProductGroupId,
        weekId
      } = action.payload;
      const updatedWeeks = [...state.updatedWeeks];
      const existingWeekIndex = updatedWeeks.findIndex(
        week =>
          week.weekId === weekId &&
          week.productionPlanningGroupId === productionPlanningGroupId &&
          week.productionProductGroupId === productionProductGroupId
      );
      if (existingWeekIndex >= 0) {
        const existingWeek = { ...updatedWeeks[existingWeekIndex] };
        if (key === 'adjusted') {
          const oldExistingValue = Number(existingWeek.adjustedQuantity ?? 0);
          const updatedAdjustedValue = newValue ?? oldExistingValue;
          existingWeek.adjustedQuantity = updatedAdjustedValue;
          updatedWeeks[existingWeekIndex] = existingWeek;
          state.updatedWeeks = updatedWeeks;
          return;
        }
        const oldExistingValue = Number(existingWeek.budgetedQuantity ?? 0);
        const updatedAdjustedValue = newValue ?? oldExistingValue;
        existingWeek.budgetedQuantity = updatedAdjustedValue;
        updatedWeeks[existingWeekIndex] = existingWeek;
        state.updatedWeeks = updatedWeeks;
        return;
      }
      if (key === 'adjusted') {
        updatedWeeks.push({
          adjustedQuantity: key === 'adjusted' ? newValue : undefined,
          budgetedQuantity: undefined,
          productionPlanningId,
          productionPlanningGroupId,
          productionProductGroupId,
          weekId
        });
        state.updatedWeeks = updatedWeeks;
        return;
      }
      updatedWeeks.push({
        adjustedQuantity: undefined,
        budgetedQuantity: key === 'budgeted' ? newValue : undefined,
        productionPlanningId,
        productionPlanningGroupId,
        productionProductGroupId,
        weekId
      });
      state.updatedWeeks = updatedWeeks;
    },
    setLoadingImportingLog: (state, action: PayloadAction<boolean>) => {
      state.loadingImportingLog = action.payload;
    },
    setImportingLogResponse: (
      state,
      action: PayloadAction<ProductionPlanningImportingLog>
    ) => {
      state.importingLog = action.payload ? action.payload : null;
      if (action.payload) {
        state.modificationDate = action.payload.creationDate;
      }
      state.loadingImportingLog = false;
    },
    setCloseReplicateWeekModal: (state, action: PayloadAction<boolean>) => {
      state.closeReplicateWeekModal = action.payload;
    },
    setCloseReplicateYearModal: (state, action: PayloadAction<boolean>) => {
      state.closeReplicateYearModal = action.payload;
    },
    setSubmitFormFilter: (state, action: PayloadAction<boolean>) => {
      state.submitFormFilter = action.payload;
    },
    setSelectedKey: (state, action: PayloadAction<number>) => {
      const updated = state.selectedKeys.filter(key => key !== action.payload);
      state.selectedKeys = [...updated, action.payload];
    },
    removeSelectedKey: (state, action: PayloadAction<number>) => {
      state.selectedKeys = state.selectedKeys.filter(
        key => key !== action.payload
      );
    },
    selectAllKeys: state => {
      state.selectedKeys =
        state.virtualTableDataSource?.map(
          row => row[0].productionProductGroupId
        ) ?? [];
    },
    setSiteId: (state, action: PayloadAction<number>) => {
      state.siteId = action.payload;
    },
    removeAllSelectedKeys: state => {
      state.selectedKeys = [];
    },
    updateCell: (
      state,
      action: PayloadAction<ProductionPlanningUpdateCell>
    ) => {
      const { rowIndex, colIndex, adjusted, budgeted } = action.payload;
      const updatedCell = {
        ...state.virtualTableDataSource[rowIndex][colIndex]
      };
      state.virtualTableDataSource[rowIndex][colIndex] = {
        ...updatedCell,
        week: {
          ...updatedCell.week,
          adjusted: Number.isNaN(adjusted)
            ? updatedCell.week.adjusted
            : Number(adjusted),
          budgeted: Number.isNaN(budgeted)
            ? updatedCell.week.budgeted
            : Number(budgeted)
        }
      };
    },
    setVisibleColumns: (state, action: PayloadAction<string[]>) => {
      state.visibleColumns = action.payload;
    },
    clearPropPlanReplicate: state => {
      state.prodPlanReplicate = initialState.prodPlanReplicate;
    },
    setPropPlanReplicate: (
      state,
      action: PayloadAction<ProductionPlanningReplicateResponse[]>
    ) => {
      state.prodPlanReplicate = action.payload;
    },
    setPropPlanReplicateHistory: (
      state,
      action: PayloadAction<ProductionPlanningReplicateResponse[]>
    ) => {
      state.prodPlanReplicateHistory = action.payload;
    },
    setPropPlanReplicateHistoryClear: state => {
      state.prodPlanReplicateHistory = initialState.prodPlanReplicateHistory;
    },
    setReplicateBudgetSuccess: (state, action: PayloadAction<boolean>) => {
      state.replicateBudgetSuccess = action.payload;
    },
    setPropPlanRepSiteId: (state, action: PayloadAction<number>) => {
      state.prodPlanRepSiteId = action.payload;
    },
    setPropPlanRepCompentenceId: (state, action) => {
      state.prodPlanRepCompentenceId = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(productionPlanningFetch.pending, state => {
      return { ...initialState, siteId: state.siteId, loading: true };
    });
    builder.addCase(productionPlanningFetch.fulfilled, (state, action) => {
      state.columnWeeks = action.payload.columnWeeks;
      state.competenceId = action.payload.competenceId;
      state.modificationDate = action.payload.modificationDate;
      state.replicateEnable = action.payload.replicateEnable;
      state.siteId = action.payload.siteId;
      state.specialDates = action.payload.specialDates;
      state.totalColumn = action.payload.totalColumn;
      state.totalRow = action.payload.totalRow;
      state.productionPlanningId = action.payload.id;
      state.virtualTableDataSource = action.payload.virtualTableDataSource;
      state.disableDownload = false;
      state.disableUpload = false;
      state.loading = false;
      state.totalCount = action.payload.totalCount;
    });
    builder.addCase(productionPlanningFetch.rejected, state => {
      state.loading = false;
    });
    builder.addCase(productionPlanningSave.pending, state => {
      state.loading = true;
    });
    builder.addCase(productionPlanningSave.fulfilled, state => {
      state.loading = false;
      state.submitFormFilter = true;
    });
    builder.addCase(productionPlanningSave.rejected, state => {
      state.loading = false;
      state.submitFormFilter = true;
    });
    builder.addCase(productionPlanningImport.pending, state => {
      state.loading = true;
    });
    builder.addCase(productionPlanningImport.fulfilled, state => {
      state.closeImportModal = true;
      state.loading = false;
      state.submitFormFilter = true;
    });
    builder.addCase(productionPlanningImport.rejected, state => {
      state.closeImportModal = true;
      state.loading = false;
      state.submitFormFilter = true;
    });
    builder.addCase(productionPlanningImportingHistoryFetch.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      productionPlanningImportingHistoryFetch.fulfilled,
      (state, action) => {
        state.importingHistory = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(productionPlanningImportingHistoryFetch.rejected, state => {
      state.loading = false;
    });
    builder.addCase(productionPlanningHistoryFetch.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      productionPlanningHistoryFetch.fulfilled,
      (state, action) => {
        state.productGroupHistories = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(productionPlanningHistoryFetch.rejected, state => {
      state.loading = false;
    });
    builder.addCase(productionPlanningImportingLogFetch.pending, state => {
      state.loadingImportingLog = true;
    });
    builder.addCase(
      productionPlanningImportingLogFetch.fulfilled,
      (state, action) => {
        state.importingLog = action.payload ? action.payload : null;
        if (action.payload) {
          state.modificationDate = action.payload.creationDate;
        }
        state.loadingImportingLog = false;
      }
    );
    builder.addCase(productionPlanningImportingLogFetch.rejected, state => {
      state.loadingImportingLog = false;
    });
    builder.addCase(
      productionPlanningImportingLogDetailsFetch.pending,
      state => {
        state.loading = true;
      }
    );
    builder.addCase(
      productionPlanningImportingLogDetailsFetch.fulfilled,
      (state, action) => {
        state.importingLogDetails = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(
      productionPlanningImportingLogDetailsFetch.rejected,
      state => {
        state.loading = false;
      }
    );
    builder.addCase(productionPlanningReplicateWeek.pending, state => {
      state.loading = true;
    });
    builder.addCase(productionPlanningReplicateWeek.fulfilled, state => {
      state.loading = false;
      state.closeReplicateWeekModal = true;
      state.submitFormFilter = true;
    });
    builder.addCase(productionPlanningReplicateWeek.rejected, state => {
      state.loading = false;
      state.closeReplicateWeekModal = true;
    });
    builder.addCase(productionPlanningReplicateYear.pending, state => {
      state.loading = true;
    });
    builder.addCase(productionPlanningReplicateYear.fulfilled, state => {
      state.loading = false;
      state.closeReplicateYearModal = true;
      state.submitFormFilter = true;
    });
    builder.addCase(productionPlanningReplicateYear.rejected, state => {
      state.loading = false;
      state.closeReplicateYearModal = true;
      state.submitFormFilter = true;
    });
    /** ***************************************************************************** */
    builder.addCase(
      fetchProductionPlanningReplicate.fulfilled,
      (
        state,
        action: PayloadAction<
          M.ResponseApi<ProductionPlanningReplicateResponse[]>
        >
      ) => {
        const { results, totalCount } = action.payload;
        state.totalCount = totalCount;
        state.prodPlanReplicate = results;
        state.submitFormFilter = false;
        state.loading = false;
      }
    );
    builder.addCase(fetchProductionPlanningReplicate.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchProductionPlanningReplicate.rejected, state => {
      state.loading = false;
    });
    /** ***************************************************************************** */
    builder.addCase(
      fetchProductionPlanningReplicateHistory.fulfilled,
      (
        state,
        action: PayloadAction<
          M.ResponseApi<ProductionPlanningReplicateResponse[]>
        >
      ) => {
        const { results, totalCount } = action.payload;
        state.totalCount = totalCount;
        state.prodPlanReplicateHistory = results;
        state.submitFormFilter = false;
        state.loading = false;
      }
    );
    builder.addCase(fetchProductionPlanningReplicateHistory.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchProductionPlanningReplicateHistory.rejected, state => {
      state.loading = false;
    });
  }
});

export const productionPlanningActions = productionPlanningsSlice.actions;
export const reducer = productionPlanningsSlice.reducer;

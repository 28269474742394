import { Form } from 'antd';
import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as G from '~/components/Globals';
import AssembleGFP from '~/components/atoms/GFP/AssembleGFP';
import FormFilterListOrders from '~/components/molecules/GFP/FormFilters/FormFilterListOrders';
import FormFilterManagementGFP from '~/components/molecules/GFP/FormFilters/FormFilterManagementGFP';
import TabExtraButtons from '~/components/molecules/GFP/TabExtraButtons';
import TableListOrders from '~/components/molecules/GFP/Tables/TableListOrders';
import TableManagement from '~/components/molecules/GFP/Tables/TableManagement';
import { GFPContext } from '~/context/GFPContext';
import Loading from '../Loading';

export default function ContextGFPWrapper() {
  const { t } = useTranslation();
  const [listOrdersForm] = Form.useForm();
  const [managementGfpForm] = Form.useForm();
  const { isPageLoading, activeKey, setActiveKey } = useContext(GFPContext);

  const initialValues = {
    listOrders: { key: 'defaultKey1' },
    managementGfp: { key: 'defaultKey2' }
  };

  const handleTabChange = useCallback(
    (key: any) => {
      setActiveKey(key);
    },
    [setActiveKey]
  );

  useEffect(() => {
    setActiveKey('addGfp');
  }, [setActiveKey]);

  useEffect(() => {
    if (activeKey === 'addGfp') {
      listOrdersForm.setFieldsValue(initialValues.listOrders);
    } else if (activeKey === 'managementGfp') {
      managementGfpForm.setFieldsValue(initialValues.managementGfp);
    }
  }, [activeKey, listOrdersForm, managementGfpForm]);

  return (
    <>
      <Loading isVisible={isPageLoading} />
      <G.Card>
        <G.TabContainer
          onChange={handleTabChange}
          tabBarExtraContent={<TabExtraButtons />}
          activeKey={activeKey}
        >
          <G.TabContainer.TabPane tab={t('gfp.tabs.title.add')} key="addGfp">
            <FormFilterListOrders form={listOrdersForm} />
            <TableListOrders form={listOrdersForm} />
          </G.TabContainer.TabPane>
          <G.TabContainer.TabPane
            tab={t('gfp.tabs.title.management')}
            key="managementGfp"
          >
            <FormFilterManagementGFP form={managementGfpForm} />
            <TableManagement form={managementGfpForm} />
          </G.TabContainer.TabPane>
        </G.TabContainer>
      </G.Card>
      {activeKey === 'addGfp' ? (
        <AssembleGFP listOrdersForm={listOrdersForm} />
      ) : null}
    </>
  );
}

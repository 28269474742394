import { Moment } from 'moment';
import { usFormat } from '~/helpers/util';
import FilterData from '../../filters/rfidReports/FilterData';
import BasePagination from '../BasePagination';

export default interface FormFilterDataModel {
  dtaFilter: Moment[];
  producerFilter: string;
  areaFilter: string;
}

export class MapFormToFilter {
  static toFilter(
    formData: FormFilterDataModel,
    pagination: BasePagination
  ): FilterData {
    const filter: FilterData = {
      pageSize: pagination.pageSize,
      pageNumber: pagination.pageNumber
    };

    if (formData.dtaFilter.length > 0) {
      filter.startDtaFilter = usFormat(formData.dtaFilter[0]).toString();
      filter.endDtaFilter = usFormat(formData.dtaFilter[1]).toString();
    }
    if (formData.producerFilter !== '') {
      filter.producerFilter = formData.producerFilter;
    }
    if (formData.areaFilter !== '') {
      filter.areaFilter = formData.areaFilter;
    }
    return filter;
  }
}

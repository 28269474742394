import { createSelector } from 'reselect';

import { VolLkpState } from './index';

export const getRoot = ({ volLkp }: { volLkp: VolLkpState }) => {
  return volLkp;
};

export const getPreferenceColors = createSelector(
  [getRoot],
  state => state.colors
);

export const getCloseModal = createSelector(
  [getRoot],
  state => state.closeModal
);

export const getImages = createSelector([getRoot], state => state.images);

export const getLoading = createSelector([getRoot], state => state.loading);

export const getUseProductPreference = createSelector(
  [getRoot],
  state => state.useProductPreference
);

export const getRefetchOffersList = createSelector(
  [getRoot],
  state => state.refetchOffersList
);

export const getKeyword = createSelector([getRoot], state => state.keyword);

export const getSearchByKeyword = createSelector(
  [getRoot],
  state => state.searchByKeyword
);

export const getShowKeysForEdit = createSelector(
  [getRoot],
  state => state.showKeysForEdit
);

export const getClearKeys = createSelector([getRoot], state => state.clearKeys);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BannerApproval as BannerProducerManagement } from '~/domain/model';

export type Status = 'cleared' | 'updated';

export type BannerProducerManagementState = {
  bannerProducerManagementList: BannerProducerManagement[];
  bannerImage?: string;
  status: Status;
};

export type Update = {
  bannerProducerManagementList?: BannerProducerManagement[];
  bannerImage?: string;
};

export type FetchBannerProducerManagement = {
  description?: string;
  url?: string;
  status?: string;
  bannerTypeId?: number;
  uploadDate?: string;
  pageNumber?: number;
  pageSize?: number;
};

const prefix = '@bannerProducerManagement';

export const initialState: BannerProducerManagementState = {
  bannerProducerManagementList: [],
  bannerImage: undefined,
  status: 'cleared'
};

const bannerProducerManagement = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: state => {
      state.bannerProducerManagementList =
        initialState.bannerProducerManagementList;
      state.status = initialState.status;
      state.bannerImage = initialState.bannerImage;
    },
    clearBanner: state => {
      state.bannerImage = undefined;
    },
    update: (state, action: PayloadAction<Update>) => {
      if (action.payload.bannerProducerManagementList) {
        state.bannerProducerManagementList =
          action.payload.bannerProducerManagementList;
      }
      if (action.payload.bannerImage) {
        state.bannerImage = action.payload.bannerImage;
      }
    }
  }
});

export const actions = bannerProducerManagement.actions;
export const reducer = bannerProducerManagement.reducer;

const caseReducers = bannerProducerManagement.caseReducers;
export const Types = Object.freeze({
  clear: `${prefix}/${caseReducers.clear.name}`,
  update: `${prefix}/${caseReducers.update.name}`
});

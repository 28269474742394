import produce from 'immer';
import { ApplicationError } from '~/domain/api/errors';
import { createCreator, createReducer, Reducer } from '~/helpers/util/reducers';

export type RequestStatus = 'idle' | 'running' | 'success' | 'failure';
export type InactiveStatus = 'idle' | 'running' | 'success' | 'failure';

export type CompetenceEventListState = {
  competenceID?: number;
  requestStatus: RequestStatus;
  inactivateStatus: InactiveStatus;
  errorMessage?: string;
  pageNumber: number;
  pageSize: number;
  totalCount: number;
};

export type FetchEvents = {
  competenceID?: number;
  status?: string;
  pageNumber?: number;
  pageSize?: number;
};

export type FetchPage = {
  pageNumber: number;
  pageSize: number;
};

export type FetchEventSuccess = {
  pageNumber?: number;
  pageSize?: number;
  totalCount: number;
};

export type FetchFailure = {
  error?: ApplicationError;
};

export type Inactivate = {
  id: number;
};

export type Error = {
  errorMessage?: string;
};

const prefix = '@competenceList';

export const Types = Object.freeze({
  CLEAR: `${prefix}/CLEAR`,
  FETCH_INIT: `${prefix}/FETCH_INIT`,
  FETCH_SUCCESS: `${prefix}/FETCH_SUCCESS`,
  FETCH_FAILURE: `${prefix}/FETCH_FAILURE`,
  FETCH_EVENTS: `${prefix}/FETCH_EVENTS`,
  FETCH_EVENTS_SUCCESS: `${prefix}/FETCH_EVENTS_SUCCESS`,
  FETCH_EVENTS_FAILURE: `${prefix}/FETCH_EVENTS_FAILURE`,
  INACTIVATE_INIT: `${prefix}/INACTIVATE_INIT`,
  INACTIVATE_SUCCESS: `${prefix}/INACTIVATE_SUCCESS`,
  INACTIVATE_FAILURE: `${prefix}/INACTIVATE_FAILURE`
});

export const initialState: CompetenceEventListState = {
  competenceID: Number.NaN,
  requestStatus: 'idle',
  inactivateStatus: 'idle',
  pageNumber: 1,
  pageSize: 10,
  totalCount: 0
};

const clearReducer: Reducer<CompetenceEventListState, void> = (
  state,
  _action
) => {
  return produce(state, draft => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    draft.competenceID = initialState.competenceID;
    draft.requestStatus = initialState.requestStatus;
    draft.inactivateStatus = initialState.inactivateStatus;
  });
};

const fetchInitReducer: Reducer<CompetenceEventListState, void> = state => {
  return produce(state, draft => {
    draft.requestStatus = 'running';
  });
};

const fetchSuccessReducer: Reducer<CompetenceEventListState, void> = state => {
  return produce(state, draft => {
    draft.requestStatus = 'success';
  });
};

const fetchFailureReducer: Reducer<CompetenceEventListState, void> = state => {
  return produce(state, draft => {
    draft.requestStatus = 'success';
  });
};

const fetchEventsReducer: Reducer<CompetenceEventListState, FetchEvents> = (
  state,
  action
) => {
  return produce(state, draft => {
    draft.requestStatus = 'running';
    draft.competenceID = action.competenceID;
    let { pageNumber, pageSize } = action;
    if (!pageNumber) {
      pageNumber = initialState.pageNumber;
    }
    if (!pageSize) {
      pageSize = initialState.pageSize;
    }
    draft.pageNumber = pageNumber;
    draft.pageSize = pageSize;
  });
};

const fetchEventsSuccessReducer: Reducer<
  CompetenceEventListState,
  FetchEventSuccess
> = (state, action) => {
  return produce(state, draft => {
    const { pageNumber, pageSize, totalCount } = action;
    draft.requestStatus = 'success';
    draft.pageNumber = pageNumber ?? initialState.pageNumber;
    draft.pageSize = pageSize ?? initialState.pageSize;
    draft.totalCount = totalCount;
  });
};

const fetchEventsFailureReducer: Reducer<
  CompetenceEventListState,
  void
> = state => {
  return produce(state, draft => {
    draft.requestStatus = 'failure';
  });
};

const inactivateInitReducer: Reducer<
  CompetenceEventListState,
  Inactivate
> = state => {
  return produce(state, draft => {
    draft.inactivateStatus = 'running';
  });
};

const inactivateSuccessReducer: Reducer<
  CompetenceEventListState,
  void
> = state => {
  return produce(state, draft => {
    draft.inactivateStatus = 'success';
  });
};

const inactivateFailureReducer: Reducer<CompetenceEventListState, Error> = (
  state,
  action
) => {
  return produce(state, draft => {
    draft.inactivateStatus = 'failure';
    draft.errorMessage = action.errorMessage;
  });
};

export const actions = Object.freeze({
  clear: createCreator<void>(Types.CLEAR),
  fetchInit: createCreator<void>(Types.FETCH_INIT),
  fetchSuccess: createCreator<void>(Types.FETCH_SUCCESS),
  fetchFailure: createCreator<void>(Types.FETCH_FAILURE),
  fetchEvents: createCreator<FetchEvents>(Types.FETCH_EVENTS),
  fetchEventsSuccess: createCreator<FetchEventSuccess>(
    Types.FETCH_EVENTS_SUCCESS
  ),
  fetchEventsFailure: createCreator<void>(Types.FETCH_EVENTS_FAILURE),
  inactivateInit: createCreator<Inactivate>(Types.INACTIVATE_INIT),
  inactivateSuccess: createCreator<void>(Types.INACTIVATE_SUCCESS),
  inactivateFailure: createCreator<Error>(Types.INACTIVATE_FAILURE)
});

export const reducer = createReducer(initialState, {
  [Types.CLEAR]: clearReducer,
  [Types.FETCH_INIT]: fetchInitReducer,
  [Types.FETCH_SUCCESS]: fetchSuccessReducer,
  [Types.FETCH_FAILURE]: fetchFailureReducer,
  [Types.FETCH_EVENTS]: fetchEventsReducer,
  [Types.FETCH_EVENTS_SUCCESS]: fetchEventsSuccessReducer,
  [Types.FETCH_EVENTS_FAILURE]: fetchEventsFailureReducer,
  [Types.INACTIVATE_INIT]: inactivateInitReducer,
  [Types.INACTIVATE_SUCCESS]: inactivateSuccessReducer,
  [Types.INACTIVATE_FAILURE]: inactivateFailureReducer
});

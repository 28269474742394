import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';
import FilterGfpCarts from '~/@core/domain/filters/gfp/FilterGfpCarts';
import GfpCarts from '~/@core/domain/model/gfp/GfpCarts';
import { GfpService } from '~/@core/infrastructure/api/services';
import IUseCase from '../../IUseCase';

export default class GetGfpCarts
  implements IUseCase<FilterGfpCarts, Promise<DefaultPaginated<GfpCarts[]>>>
{
  execute(filter: FilterGfpCarts): Promise<DefaultPaginated<GfpCarts[]>> {
    return GfpService.fetchGfpCarts(filter);
  }
}

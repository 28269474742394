import * as API from '~/domain/api';

export default class HarvestLimit {
  public readonly json: API.HarvestLimit;
  public readonly id: number;
  public readonly sundayLimit: number;
  public readonly mondayLimit: number;
  public readonly tuesdayLimit: number;
  public readonly wednesdayLimit: number;
  public readonly thursdayLimit: number;
  public readonly fridayLimit: number;
  public readonly saturdayLimit: number;

  private getId(): number {
    return this.json.id ?? Number.NaN;
  }

  private getSundayLimit(): number {
    return this.json.sundayLimit ?? Number.NaN;
  }

  private getMondayLimit(): number {
    return this.json.mondayLimit ?? Number.NaN;
  }

  private getTuesdayLimit(): number {
    return this.json.tuesdayLimit ?? Number.NaN;
  }

  private getWednesdayLimit(): number {
    return this.json.wednesdayLimit ?? Number.NaN;
  }

  private getThursdayLimit(): number {
    return this.json.thursdayLimit ?? Number.NaN;
  }

  private getFridayLimit(): number {
    return this.json.fridayLimit ?? Number.NaN;
  }

  private getSaturdayLimit(): number {
    return this.json.saturdayLimit ?? Number.NaN;
  }

  constructor(json: API.HarvestLimit) {
    this.json = json;
    this.id = this.getId();
    this.sundayLimit = this.getSundayLimit();
    this.mondayLimit = this.getMondayLimit();
    this.tuesdayLimit = this.getTuesdayLimit();
    this.wednesdayLimit = this.getWednesdayLimit();
    this.thursdayLimit = this.getThursdayLimit();
    this.fridayLimit = this.getFridayLimit();
    this.saturdayLimit = this.getSaturdayLimit();
  }
}

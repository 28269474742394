import produce from 'immer';

export type AlertType =
  | 'success'
  | 'info'
  | 'warning'
  | 'error'
  | string
  | undefined;
interface AlertState {
  type: AlertType;
  message: string;
  showIcon: boolean;
  timeout: number;
  show: boolean;
}

const INITIAL_STATE: AlertState = {
  type: undefined,
  message: '',
  showIcon: true,
  timeout: 5000,
  show: false
};

export default function alert(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case '@alert/REQUEST':
      return produce(state, draft => {
        draft.type = action.payload.type;
        draft.message = action.payload.message;
        draft.showIcon = action.payload.showIcon;
        draft.show = true;
      });

    case '@alert/CLEAR':
      return produce(state, draft => {
        draft.type = undefined;
        draft.message = '';
        draft.showIcon = true;
        draft.timeout = 5000;
        draft.show = false;
      });

    case '@alert/CLOSE':
      return produce(state, draft => {
        draft.show = false;
      });

    case '@alert/SET_TIMEOUT':
      return produce(state, draft => {
        draft.timeout =
          !action.payload.timeout || action.payload.timeout <= 0
            ? 5000
            : action.payload.timeout;
      });

    default:
      return state;
  }
}

/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { FormInstance, Select, Table, Tooltip } from 'antd';
import { AxiosError } from 'axios';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import GetStocks from '~/@core/application/useCases/stock/getStocks';
import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';
import ResolvedStockQuantities from '~/@core/domain/model/stock/ResolvedStockQuantities';
import Stocks from '~/@core/domain/model/stock/Stocks';
import { DividerFrame } from '~/components/Globals';
import PanelFilters from '~/components/atoms/Inputs/PanelFilters';
import { QualityContainer } from '~/components/atoms/QualityContainer';
import { SelectOrderPacking } from '~/components/atoms/SelectOrderPacking';
import { GFPContext } from '~/context/GFPContext';
import { globalContext } from '~/context/GlobalContext';
import { onlyNumber } from '~/helpers/util';
import { changeFocusWithRef } from '~/helpers/util/table';
import useDebounce from '~/helpers/util/useDebounceSearch';
import useGfpStore from '~/zustand/gfp/gfp-service-state';
import { InputTable } from '../../Modals/AssembleGFP/style';
import {
  CheckboxContainer,
  FrameStyled,
  GroupTitleInformation,
  SpanLabel,
  StyledSubText,
  StyledTable,
  StyledText
} from './style';

interface IProps {
  form: FormInstance<any>;
}
export default function TableAddKlokGFP(props: IProps) {
  const { t } = useTranslation();
  const { form } = props;

  const { handlePromiseUseCase, activeAlert, siteIdSelected } =
    useContext(globalContext);

  const {
    stocks,
    setStocks,
    stocksSelected,
    setStocksSelected,
    handleChangeStocks
  } = useGfpStore();

  const {
    qualityObservations,
    outDate,
    setIsPageLoading,
    selectedRowKeysKlok
  } = useContext(GFPContext);

  const [searchValue, setSearchValue] = useState<string>('');
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const focusControlledColumns = useMemo(() => {
    return [
      'quantityPacking',
      'quantityPerPacking',
      'unitPrice',
      'observationText'
    ];
  }, []);

  const tableRef = useRef<any>(null);

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    onlyNumber(e);
    changeFocusWithRef(
      e,
      focusControlledColumns.indexOf('quantityPerPacking'),
      index,
      tableRef
    );
  };

  const columns: any = [
    {
      key: 'productDescription',
      title: 'Descrição do produto',
      dataIndex: 'productDescription',
      render: (value: any, record: Stocks) => {
        return (
          <GroupTitleInformation>
            <Tooltip title={record.productDescription}>
              <StyledText>
                {record.productDescription?.slice(0, 25) +
                  (record.productDescription?.length > 20 ? '...' : '')}
              </StyledText>
            </Tooltip>
            <SpanLabel>{record.productCode}</SpanLabel>
            {checkLineExists(record) ? (
              <StyledSubText>Produto selecionado</StyledSubText>
            ) : (
              ''
            )}
          </GroupTitleInformation>
        );
      }
    },
    {
      key: 'qualityDescription',
      title: 'Qld.',
      dataIndex: 'qualityDescription',
      align: 'center',
      width: 50,
      render: (value: any) => {
        return <QualityContainer quality={value}>{value}</QualityContainer>;
      }
    },
    {
      key: 'packingCode',
      title: 'Emb.',
      width: 80,
      render: (_: any, record: Stocks, index: number) => {
        if (!record.packingCode && record.unitPrice) {
          handleChangeStockSelected(undefined, 'unitPrice', index, record);
          handleChangeStockSelected(undefined, 'totalPrice', index, record);
        }
        return (
          <div>
            <SelectOrderPacking
              disabled={checkLineExists(record)}
              allowClear={false}
              stockId={record.stockId}
              loadOnClick
              productId={record.productId}
              siteId={siteIdSelected}
              value={record.packingId}
              onChange={selectedPacking =>
                handleChangeSelectPacking(selectedPacking, index)
              }
            />
          </div>
        );
      }
    },
    {
      dataIndex: 'quantityPacking',
      key: 'quantityPacking',
      title: 'QE',
      align: 'center',
      width: 50,
      render: (quantityPacking: any, record: Stocks, index: number) => {
        const inputId = `idToFocus-${focusControlledColumns.indexOf(
          'quantityPacking'
        )}-${index}`;
        return (
          <InputTable
            id={inputId}
            disabled={!record.packingCode || checkLineExists(record)}
            onKeyDown={e => handleKeyDown(e, index)}
            value={quantityPacking}
            status={record.isSelected && !record.packingQuantity ? 'error' : ''}
            onChange={e => {
              const value = e.target.value;
              handleChangeStockSelected(
                value,
                'quantityPacking',
                index,
                record
              );
            }}
            style={{ width: '30px', textAlign: 'center' }}
          />
        );
      }
    },
    {
      dataIndex: 'quantityPerPacking',
      key: 'quantityPerPacking',
      title: 'QpE',
      align: 'center',
      width: 50,
      render: (quantityPerPacking: any, record: Stocks, index: number) => {
        const inputId = `idToFocus-${focusControlledColumns.indexOf(
          'quantityPerPacking'
        )}-${index}`;
        return (
          <InputTable
            id={inputId}
            disabled={!record.packingCode || checkLineExists(record)}
            onKeyDown={e => handleKeyDown(e, index)}
            value={quantityPerPacking}
            status={record.isSelected && !record.packingQuantity ? 'error' : ''}
            onChange={e => {
              const value = e.target.value;
              handleChangeStockSelected(
                value,
                'quantityPerPacking',
                index,
                record
              );
            }}
            style={{ width: '30px', textAlign: 'center' }}
          />
        );
      }
    },
    {
      key: 'observationId1',
      title: 'Obs 1.',
      dataIndex: 'observation',
      align: 'center',
      width: 60,
      render: (_: any, record: Stocks, index: number) => {
        return (
          <Select
            disabled={checkLineExists(record)}
            dropdownStyle={{ minWidth: '200px' }}
            filterOption
            optionFilterProp="children"
            placeholder={t('generals.selectPlaceholder')}
            style={{ maxWidth: 60, width: 60 }}
            showSearch
            allowClear
            labelInValue
            optionLabelProp="label"
            onChange={(value: any) => {
              handleChangeStockSelected(
                value?.value ?? '',
                'observationId1',
                index,
                record
              );
            }}
            value={record.observationId1 ? [record.observationId1] : []}
          >
            {qualityObservations &&
              qualityObservations.map(observation => (
                <Select.Option
                  key={observation.id}
                  value={observation.id}
                  label={`${observation.code}`}
                >
                  {observation.code} - {observation.description}
                </Select.Option>
              ))}
          </Select>
        );
      }
    },
    {
      key: 'observationId2',
      title: 'Obs 2.',
      dataIndex: 'observation',
      align: 'center',
      width: 60,
      render: (_: any, record: Stocks, index: number) => {
        return (
          <Select
            disabled={checkLineExists(record)}
            dropdownStyle={{ minWidth: '200px' }}
            filterOption
            optionFilterProp="children"
            placeholder={t('generals.selectPlaceholder')}
            style={{ maxWidth: 60, width: 60 }}
            showSearch
            allowClear
            labelInValue
            optionLabelProp="label"
            onChange={(value: any) => {
              handleChangeStockSelected(
                value?.value ?? '',
                'observationId2',
                index,
                record
              );
            }}
            value={record.observationId2 ? [record.observationId2] : []}
          >
            {qualityObservations &&
              qualityObservations.map(observation => (
                <Select.Option
                  key={observation.id}
                  value={observation.id}
                  label={`${observation.code}`}
                >
                  {observation.code} - {observation.description}
                </Select.Option>
              ))}
          </Select>
        );
      }
    },
    Table.SELECTION_COLUMN
  ].filter(Boolean);

  if (!stocks.length || stocks.every(x => !x.isVolOnly)) {
    columns.splice(3, 0, {
      key: 'remainingQuantity',
      title: () => {
        return (
          <GroupTitleInformation>
            <StyledText>Quantidade</StyledText>
            <StyledText>em Estoque</StyledText>
          </GroupTitleInformation>
        );
      },
      dataIndex: 'remainingQuantity',
      align: 'center',
      width: 100,
      render: (resolvedQuantities: ResolvedStockQuantities, record: Stocks) => {
        return (
          record.resolvedQuantities?.remainingKlokHoard ??
          record.remainingQuantity
        );
      }
    });
  }

  const onSubmitSearch = useCallback(() => {
    if (siteIdSelected && !isSearching) {
      setIsSearching(true);
      setIsPageLoading(true);

      const getStocks = new GetStocks();
      const outDateFilter = form.getFieldValue('outDate');

      const searchValueForm = form.getFieldValue('search');

      const filter = {
        siteId: siteIdSelected,
        FirstWeekDay:
          outDateFilter?.clone().startOf('week').format('YYYY-MM-DD') || '',
        LastWeekDay:
          outDateFilter?.clone().endOf('week').format('YYYY-MM-DD') || '',
        Keyword: searchValueForm ?? '',
        pageSize: 100,
        pageNumber: 1
      };

      handlePromiseUseCase<DefaultPaginated<Stocks[]>, AxiosError>(
        getStocks.execute(filter),
        stocksOut => {
          setStocks(stocksOut.results);
          setIsPageLoading(false);
          setIsSearching(false);
        },
        error => {
          setIsPageLoading(false);
          setIsSearching(false);
          activeAlert({
            message: JSON.stringify(
              error.response?.data
                ? error.response?.data
                : 'Houve um erro inesperado'
            ),
            type: 'error',
            timeout: 5000
          });
        }
      );
    }
  }, [
    activeAlert,
    endDate,
    handlePromiseUseCase,
    searchValue,
    setIsPageLoading,
    setStocks,
    siteIdSelected,
    startDate
  ]);

  const debouncedSearch = useDebounce(onSubmitSearch, 100);

  const handleSearch = useCallback(() => {
    debouncedSearch();
  }, [debouncedSearch]);

  useEffect(() => {
    if (siteIdSelected && searchValue) {
      debouncedSearch();
    }
  }, [searchValue, debouncedSearch, siteIdSelected]);

  const panelFilters = () => (
    <PanelFilters
      searchFormName="search"
      setSearchValue={setSearchValue}
      switchLabel={t('gfp.table.switchLabel')}
      switchFormName="GroupOrders"
      form={form}
      isRadioEnabled
      isInputDisabled={false}
      handleFunction={handleSearch}
      isGroupByOrders
    />
  );

  const handleChangeStockSelected = (
    valueParam: any,
    property: string,
    indexTable: number,
    stock: Stocks
  ) => {
    let value: string | number = 0;
    if (typeof valueParam === 'string') {
      value = valueParam;
    }
    if (typeof valueParam !== 'string' && !Number.isNaN(valueParam)) {
      value = valueParam;
    }

    const stockMaped = stocks.map((stockLoop: Stocks) => {
      if (stockLoop.stockId === stock.stockId) {
        return {
          ...stockLoop,
          [property]: value,
          changed: true
        };
      }
      return stockLoop;
    });

    setStocks(stockMaped);

    const selectedStock = stocksSelected.findIndex(
      x => x.stockId === stock.stockId
    );

    if (selectedStock >= 0) {
      const stockSelectedMapped = stocksSelected.map((stockLoop: Stocks) => {
        if (stockLoop.stockId === stock.stockId) {
          return {
            ...stockLoop,
            [property]: value,
            changed: true
          };
        }

        return stockLoop;
      });

      setStocksSelected(stockSelectedMapped);
    }
  };

  const updateCheckedList = (stock: Stocks) => {
    const updatedSelected = [...stocksSelected];
    const existingIndex = stocksSelected.findIndex(
      item => item.stockId === stock.stockId
    );
  
    if (existingIndex !== -1) {
      updatedSelected.splice(existingIndex, 1);
    } else {
      const sameStockItems = stocksSelected.filter(
        item => item.stockId === stock.stockId
      );
  
      const nextNumber = sameStockItems.length + 1;
      const stockKey = Number(`${stock.stockId}${nextNumber}`);
  
      updatedSelected.push({
        ...stock,
        stockKey
      });
    }
  
    setStocksSelected(updatedSelected);
  };

  const checkLineExists = (record: Stocks) => {
    if (stocksSelected?.length) {
      const found = stocksSelected.find(
        stockSelected => stockSelected.stockId === record.stockId
      );
      return !!found;
    }
    return false;
  };

  const handleChangeSelectPacking = (selectedPacking: any, index: number) => {
    handleChangeStocks(selectedPacking?.qe || '', 'quantityPacking', index);
    handleChangeStocks(selectedPacking?.qpe || '', 'quantityPerPacking', index);
    handleChangeStocks(selectedPacking?.code || '', 'packingCode', index);
    handleChangeStocks(selectedPacking?.id || '', 'packingId', index);
  };

  const rowSelection = {
    columnTitle: '',
    selectedRowKeysKlok,
    with: 30,
    hideSelectAll: true,
    renderCell: (checked: boolean, record: any, index: number, node: any) => {
      const allFieldsFilled =
        record.packingCode &&
        record.quantityPacking &&
        record.quantityPerPacking;
      const qualityIsA2 = record.qualityDescription === 'A2';
      const obsFilled = record.observationId1 || record.observationId2;

      const handleClick = (
        event: React.MouseEvent<HTMLLabelElement, MouseEvent>
      ) => {
        if (!checkLineExists(record)) {
          if (allFieldsFilled && (!qualityIsA2 || (qualityIsA2 && obsFilled))) {
            updateCheckedList(record);
          } else {
            event.preventDefault();
            const missingFields: string[] = [];
            let qualityMessage = '';

            if (!record.packingCode) {
              missingFields.push('Embalagem');
            }
            if (!record.quantityPacking) {
              missingFields.push('Quantidade de Embalagem (QE)');
            }
            if (!record.quantityPerPacking) {
              missingFields.push('Quantidade por Embalagem (QpE)');
            }
            if (record.qualityDescription === 'A2') {
              qualityMessage =
                'É necessário preencher o código de desclassificação';
            }
            if (qualityIsA2 && !obsFilled) {
              qualityMessage =
                'É necessário preencher o código de desclassificação';
            }
            const messages = missingFields.map(
              field => `Campo de ${field} deve ser preenchido <br>`
            );

            if (qualityMessage) {
              messages.push(qualityMessage);
            }

            if (messages.length > 0) {
              activeAlert({
                message: messages.join('\n'),
                type: 'error',
                timeout: 5000
              });
            }
          }
        } else {
          updateCheckedList(record);
        }
      };

      return (
        <CheckboxContainer onClick={handleClick} style={{ cursor: 'pointer' }}>
          {checkLineExists(record) ? <MinusOutlined /> : <PlusOutlined />}
          {node}
        </CheckboxContainer>
      );
    }
  };

  return (
    <FrameStyled defaultActiveKey="table" ghost accordion>
      <FrameStyled.Panel
        header={<DividerFrame orientation="left">Estoque</DividerFrame>}
        key="table"
        extra={panelFilters()}
      >
        <StyledTable
          rowSelection={rowSelection}
          bordered
          columns={columns}
          dataSource={stocks}
          rowKey="stockId"
          pagination={false}
          scroll={{ y: 340, x: true }}
        />
      </FrameStyled.Panel>
    </FrameStyled>
  );
}

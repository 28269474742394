import QualityObservations from '~/@core/domain/model/order/QualityObservations';
import { OrderService } from '~/@core/infrastructure/api/services';
import IUseCase from '../../IUseCase';

export default class GetQualityObservations
  implements IUseCase<void, Promise<QualityObservations[]>>
{
  execute(): Promise<QualityObservations[]> {
    return OrderService.fetchQualityObservations();
  }
}

// eslint-disable-next-line import/no-named-default
import { APIError } from '~/domain/api/errors';
import { FetchSavePackingAdjustQuantity } from '~/store/modules/_ui/packingAdjustQuantity/duck';
import * as M from '~/domain/model';
import apiProducer from '../api';

export const adjustQuantity = async ({
  id,
  quantity
}: FetchSavePackingAdjustQuantity) => {
  try {
    const response: any = await apiProducer.put(
      `/packing/update-quantity?id=${id}&quantity=${quantity}`
    );
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const fetchPackingsBySiteAndProductRequest = async ({
  siteId,
  productId
}: M.FetchPackingBySiteAndProductParams) => {
  const response = await apiProducer.get<M.FetchPackingBySiteAndProduct[]>(
    `/packing/${siteId}/${productId}`,
    {
      headers: {
        'X-Is-Async-Loading': 'true'
      }
    }
  );
  if (response.data.length === 0) {
    throw new Error('Não há embalagens cadastradas para este produto');
  }
  return response;
};

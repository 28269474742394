import { createAsyncThunk } from '@reduxjs/toolkit';
import DeleteImageParams from '~/domain/model/Offers/DeleteImageParams';
import ReorderImagesParams from '~/domain/model/Offers/ReorderImagesParams';
import UploadImagesParams from '~/domain/model/Offers/UploadImagesParams';
import { findPreferenceColor } from '~/services/producer/ProductPreference';
import * as volOfferService from '~/services/producer/VolOffer';
import { alertRequest } from '../../alert/actions';

export const fetchPreferenceColor = createAsyncThunk(
  `volLkp/fetchPreferenceColor`,
  async () => {
    const response = await findPreferenceColor();
    return response;
  }
);

export const fetchImages = createAsyncThunk(
  `volLkp/fetchImages`,
  async (volOfferId: number) => {
    const response = await volOfferService.fetchImagesRequest(volOfferId);
    return response;
  }
);

export const changeFlagUseProductPreference = createAsyncThunk(
  `volLkp/changeFlagUseProductPreference`,
  async (volOfferId: number, { dispatch }) => {
    const response =
      await volOfferService.changeFlagUseProductPreferenceRequest(volOfferId);
    dispatch(fetchImages(volOfferId));
    return response;
  }
);

export const deleteImage = createAsyncThunk(
  `volLkp/deleteImage`,
  async (params: DeleteImageParams, { dispatch, rejectWithValue }) => {
    try {
      await volOfferService.deleteImageRequest(params);
      // #FIXME Remover any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.log(`err:: `, err);
      dispatch(
        alertRequest(JSON.stringify(err.response.data.errors.messages), 'error')
      );
      rejectWithValue({});
    }
    dispatch(fetchImages(params.volOfferId));
  }
);

export const reorderImages = createAsyncThunk(
  `volLkp/reorderImages`,
  // #FIXME
  // eslint-disable-next-line consistent-return
  async (params: ReorderImagesParams, { dispatch, rejectWithValue }) => {
    try {
      const response = await volOfferService.reorderImagesRequest(params);
      return response;
      // #FIXME Remover any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      dispatch(alertRequest(err.message, 'error'));
      rejectWithValue({});
    }
    dispatch(fetchImages(params.volOfferId));
  }
);

export const uploadImages = createAsyncThunk(
  `volLkp/uploadImages`,
  async (params: UploadImagesParams, { dispatch, rejectWithValue }) => {
    try {
      const response = await volOfferService.uploadImagesRequest(params);
      dispatch(alertRequest('Imagem carregada com sucesso!', 'success'));
      dispatch(fetchImages(params.volOfferId));
      return response;
      // #FIXME Remover any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      dispatch(alertRequest(err.message, 'error'));
      return rejectWithValue(err);
    }
  }
);

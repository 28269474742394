import { createSelector } from 'reselect';

import { KeyLiberationState } from '.';

export const getRoot = ({
  keyLiberation
}: {
  keyLiberation: KeyLiberationState;
}) => {
  return keyLiberation;
};

export const getKeyLiberationState = createSelector([getRoot], state => state);

import { createAsyncThunk } from '@reduxjs/toolkit';
import * as productCategoryServices from '~/services/producer/ProductCategory';

export const productCategoryFetch = createAsyncThunk(
  `productCategory/productCategoryFetch`,
  async () => {
    const response = await productCategoryServices.fetchRequest();
    return response;
  }
);

import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '~/services/api';

import {
  productPropertyAttachmentTypesSuccess,
  productPropertyAttachmentTypesFailure
} from './actions';

export function* onList(): any {
  try {
    const response = yield call(api.get, `/product-property-attachment`);

    const { data } = response;

    yield put(productPropertyAttachmentTypesSuccess(data));
  } catch (err) {
    yield put(productPropertyAttachmentTypesFailure());
  }
}

export default all([
  takeLatest('@productPropertyAttachmentTypes/LIST_REQUEST', onList)
]);

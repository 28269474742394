import { AxiosError, AxiosResponse } from 'axios';
import ISelfServiceWorksheet from '~/@core/domain/model/selfService/ISelfServiceWorksheet';
import IUnpublishedOrder from '~/@core/domain/model/selfService/IUnpublishedOrder';
import api from '../config';

export const fetchWorksheetByName = (worksheetName: string) => {
  return new Promise<ISelfServiceWorksheet>((resolve, reject) => {
    api
      .get<void, AxiosResponse<ISelfServiceWorksheet>>(
        `/self-service/worksheet/name/${worksheetName}`
      )
      .then((response: AxiosResponse<ISelfServiceWorksheet>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const fecthUnpublishOrders = (unpublished: string) => {
  return new Promise<IUnpublishedOrder>((resolve, reject) => {
    api
      .get<void, AxiosResponse<IUnpublishedOrder>>(
        `/self-service/unpublished/${unpublished}`
      )
      .then((response: AxiosResponse<IUnpublishedOrder>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

import { PackingDistributionComposition } from '.';
import * as API from '../api';

export default class AvailablePackingDistributionComposition {
  public json: API.AvailablePackingDistributionComposition;
  public id: number;
  public selected: boolean;
  public composition: PackingDistributionComposition;

  public getId(): number {
    return this.json.id ?? Number.NaN;
  }

  public getSelected(): boolean {
    return this.json.selected;
  }

  public getComposition() {
    return this.json.composition
      ? new PackingDistributionComposition(this.json.composition)
      : ({} as PackingDistributionComposition);
  }

  constructor(json: API.AvailablePackingDistributionComposition) {
    this.json = json;
    this.id = this.getId();
    this.selected = this.getSelected();
    this.composition = this.getComposition();
  }
}

import produce from 'immer';
import { ApplicationError } from '~/domain/api/errors';
import { createCreator, createReducer, Reducer } from '~/helpers/util/reducers';

export type RequestStatus = 'idle' | 'running' | 'success' | 'failure';
export type CustomersStatus = 'idle' | 'running' | 'success' | 'failure';

export type CustomersState = {
  requestStatus: RequestStatus;
  customersStatus: RequestStatus;
  customersGroupStatus: RequestStatus;
  customersSegmentStatus: RequestStatus;
  errorMessage?: string;
};

export type FetchCustomers = {
  groupId?: number;
  voucherId?: number;
  name?: string;
  segmentId?: number;
};

export type FetchCustomersFailure = {
  error?: ApplicationError;
};

export type Error = {
  errorMessage?: string;
};

const prefix = '@customers';

export const Types = Object.freeze({
  CLEAR: `${prefix}/CLEAR`,
  FETCH_CUSTOMERS: `${prefix}/FETCH_CUSTOMERS`,
  FETCH_CUSTOMERS_SUCCESS: `${prefix}/FETCH_CUSTOMERS_SUCCESS`,
  FETCH_CUSTOMERS_FAILURE: `${prefix}/FETCH_CUSTOMERS_FAILURE`,
  FETCH_CUSTOMERS_GROUP: `${prefix}/FETCH_CUSTOMERS_GROUP`,
  FETCH_CUSTOMERS_GROUP_SUCCESS: `${prefix}/FETCH_CUSTOMERS_GROUP_SUCCESS`,
  FETCH_CUSTOMERS_GROUP_FAILURE: `${prefix}/FETCH_CUSTOMERS_GROUP_FAILURE`,
  FETCH_CUSTOMERS_SEGMENT: `${prefix}/FETCH_CUSTOMERS_SEGMENT`,
  FETCH_CUSTOMERS_SEGMENT_SUCCESS: `${prefix}/FETCH_CUSTOMERS_SEGMENT_SUCCESS`,
  FETCH_CUSTOMERS_SEGMENT_FAILURE: `${prefix}/FETCH_CUSTOMERS_SEGMENT_FAILURE`,
  FETCH_CUSTOMERS_BY_NAME_AND_SEGMENT: `${prefix}/FETCH_CUSTOMERS_BY_NAME_AND_SEGMENT`,
  FETCH_CUSTOMERS_BY_NAME_AND_SEGMENT_SUCCESS: `${prefix}/FETCH_CUSTOMERS_BY_NAME_AND_SEGMENT_SUCCESS`,
  FETCH_CUSTOMERS_BY_NAME_AND_SEGMENT_FAILURE: `${prefix}/FETCH_CUSTOMERS_BY_NAME_AND_SEGMENT_FAILURE`,
  FETCH_CUSTOMERS_BY_GROUP_ID: `${prefix}/FETCH_CUSTOMERS_BY_GROUP_ID`,
  FETCH_CUSTOMERS_BY_GROUP_ID_SUCCESS: `${prefix}/FETCH_CUSTOMERS_BY_GROUP_ID_SUCCESS`,
  FETCH_CUSTOMERS_BY_GROUP_ID_FAILURE: `${prefix}/FETCH_CUSTOMERS_BY_GROUP_ID_FAILURE`
});

export const initialState: CustomersState = {
  requestStatus: 'idle',
  customersStatus: 'idle',
  customersGroupStatus: 'idle',
  customersSegmentStatus: 'idle'
};

const clearReducer: Reducer<CustomersState, void> = (state, _action) => {
  return produce(state, draft => {
    draft.requestStatus = initialState.requestStatus;
    draft.customersStatus = initialState.customersStatus;
    draft.customersSegmentStatus = initialState.customersSegmentStatus;
  });
};

const fetchCustomersReducer: Reducer<
  CustomersState,
  FetchCustomers
> = state => {
  return produce(state, draft => {
    draft.requestStatus = 'running';
  });
};

const fetchCustomersSuccessReducer: Reducer<CustomersState, void> = state => {
  return produce(state, draft => {
    draft.requestStatus = 'success';
  });
};

const fetchCustomersFailureReducer: Reducer<CustomersState, void> = state => {
  return produce(state, draft => {
    draft.requestStatus = 'failure';
  });
};

const fetchCustomersGroupReducer: Reducer<CustomersState, number> = state => {
  return produce(state, draft => {
    draft.customersGroupStatus = 'running';
  });
};

const fetchCustomersGroupSuccessReducer: Reducer<
  CustomersState,
  void
> = state => {
  return produce(state, draft => {
    draft.customersGroupStatus = 'success';
  });
};

const fetchCustomersGroupFailureReducer: Reducer<
  CustomersState,
  void
> = state => {
  return produce(state, draft => {
    draft.customersGroupStatus = 'failure';
  });
};

const fetchCustomersSegmentReducer: Reducer<CustomersState, void> = state => {
  return produce(state, draft => {
    draft.requestStatus = 'running';
  });
};

const fetchCustomersSegmentSuccessReducer: Reducer<
  CustomersState,
  void
> = state => {
  return produce(state, draft => {
    draft.requestStatus = 'success';
  });
};

const fetchCustomersSegmentFailureReducer: Reducer<
  CustomersState,
  void
> = state => {
  return produce(state, draft => {
    draft.requestStatus = 'failure';
  });
};

const fetchCustomersByNameAndSegmentReducer: Reducer<
  CustomersState,
  FetchCustomers
> = state => {
  return produce(state, draft => {
    draft.requestStatus = 'running';
  });
};

const fetchCustomersByNameAndSegmentSuccessReducer: Reducer<
  CustomersState,
  void
> = state => {
  return produce(state, draft => {
    draft.requestStatus = 'success';
  });
};

const fetchCustomersByNameAndSegmentFailureReducer: Reducer<
  CustomersState,
  void
> = state => {
  return produce(state, draft => {
    draft.requestStatus = 'failure';
  });
};

const fetchCustomersByGroupIdReducer: Reducer<
  CustomersState,
  FetchCustomers
> = state => {
  return produce(state, draft => {
    draft.requestStatus = 'running';
  });
};

const fetchCustomersByGroupIdSuccessReducer: Reducer<
  CustomersState,
  void
> = state => {
  return produce(state, draft => {
    draft.requestStatus = 'success';
  });
};

const fetchCustomersByGroupIdFailureReducer: Reducer<
  CustomersState,
  void
> = state => {
  return produce(state, draft => {
    draft.requestStatus = 'failure';
  });
};

export const actions = Object.freeze({
  clear: createCreator<void>(Types.CLEAR),
  fetchCustomers: createCreator<FetchCustomers>(Types.FETCH_CUSTOMERS),
  fetchCustomersSuccess: createCreator<void>(Types.FETCH_CUSTOMERS_SUCCESS),
  fetchCustomersFailure: createCreator<void>(Types.FETCH_CUSTOMERS_FAILURE),
  fetchCustomersGroup: createCreator<number>(Types.FETCH_CUSTOMERS_GROUP),
  fetchCustomersGroupSuccess: createCreator<void>(
    Types.FETCH_CUSTOMERS_GROUP_SUCCESS
  ),
  fetchCustomersGroupFailure: createCreator<void>(
    Types.FETCH_CUSTOMERS_GROUP_FAILURE
  ),
  fetchCustomersSegment: createCreator<void>(Types.FETCH_CUSTOMERS_SEGMENT),
  fetchCustomersSegmentSuccess: createCreator<void>(
    Types.FETCH_CUSTOMERS_SEGMENT_SUCCESS
  ),
  fetchCustomersSegmentFailure: createCreator<void>(
    Types.FETCH_CUSTOMERS_SEGMENT_FAILURE
  ),
  fetchCustomersByNameAndSegment: createCreator<FetchCustomers>(
    Types.FETCH_CUSTOMERS_BY_NAME_AND_SEGMENT
  ),
  fetchCustomersByNameAndSegmentSuccess: createCreator<void>(
    Types.FETCH_CUSTOMERS_BY_NAME_AND_SEGMENT_SUCCESS
  ),
  fetchCustomersByNameAndSegmentFailure: createCreator<void>(
    Types.FETCH_CUSTOMERS_BY_NAME_AND_SEGMENT_FAILURE
  ),
  fetchCustomersByGroupId: createCreator<FetchCustomers>(
    Types.FETCH_CUSTOMERS_BY_GROUP_ID
  ),
  fetchCustomersByGroupIdSuccess: createCreator<void>(
    Types.FETCH_CUSTOMERS_BY_GROUP_ID_SUCCESS
  ),
  fetchCustomersByGroupIdFailure: createCreator<void>(
    Types.FETCH_CUSTOMERS_BY_GROUP_ID_FAILURE
  )
});

export const reducer = createReducer(initialState, {
  [Types.CLEAR]: clearReducer,
  [Types.FETCH_CUSTOMERS]: fetchCustomersReducer,
  [Types.FETCH_CUSTOMERS_SUCCESS]: fetchCustomersSuccessReducer,
  [Types.FETCH_CUSTOMERS_FAILURE]: fetchCustomersFailureReducer,
  [Types.FETCH_CUSTOMERS_GROUP]: fetchCustomersGroupReducer,
  [Types.FETCH_CUSTOMERS_GROUP_SUCCESS]: fetchCustomersGroupSuccessReducer,
  [Types.FETCH_CUSTOMERS_GROUP_FAILURE]: fetchCustomersGroupFailureReducer,
  [Types.FETCH_CUSTOMERS_SEGMENT]: fetchCustomersSegmentReducer,
  [Types.FETCH_CUSTOMERS_SEGMENT_SUCCESS]: fetchCustomersSegmentSuccessReducer,
  [Types.FETCH_CUSTOMERS_SEGMENT_FAILURE]: fetchCustomersSegmentFailureReducer,
  [Types.FETCH_CUSTOMERS_BY_NAME_AND_SEGMENT]:
    fetchCustomersByNameAndSegmentReducer,
  [Types.FETCH_CUSTOMERS_BY_NAME_AND_SEGMENT_SUCCESS]:
    fetchCustomersByNameAndSegmentSuccessReducer,
  [Types.FETCH_CUSTOMERS_BY_NAME_AND_SEGMENT_FAILURE]:
    fetchCustomersByNameAndSegmentFailureReducer,
  [Types.FETCH_CUSTOMERS_BY_GROUP_ID]: fetchCustomersByGroupIdReducer,
  [Types.FETCH_CUSTOMERS_BY_GROUP_ID_SUCCESS]:
    fetchCustomersByGroupIdSuccessReducer,
  [Types.FETCH_CUSTOMERS_BY_GROUP_ID_FAILURE]:
    fetchCustomersByGroupIdFailureReducer
});

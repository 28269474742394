import produce from 'immer';
import { Competence } from '~/domain/model';
import { createCreator, createReducer, Reducer } from '~/helpers/util/reducers';

export type CreateStatus =
  | 'idle'
  | 'submitting'
  | 'success'
  | 'failure'
  | 'failure-already-registered';

export type CompetenceCreateState = {
  competence: Competence;
  competenceId: string;
  createStatus: CreateStatus;
};

export type ErrorResponse = {
  createStatus: CreateStatus;
};

export type CreateInit = {
  referenceYear: number;
  historicalYear: number;
};

export const initialState: CompetenceCreateState = {
  competence: new Competence({}),
  createStatus: 'idle',
  competenceId: ''
};

const prefix = '@competenceCreate';

export const Types = Object.freeze({
  CLEAR: `${prefix}/CLEAR`,
  CREATE_INIT: `${prefix}/CREATE_INIT`,
  CREATE_SUCCESS: `${prefix}/CREATE_SUCCESS`,
  CREATE_FAILURE: `${prefix}/CREATE_FAILURE`
});

const clearReducer: Reducer<CompetenceCreateState, void> = state => {
  return produce(state, draft => {
    draft.competence = initialState.competence;
    draft.competenceId = initialState.competenceId;
    draft.createStatus = initialState.createStatus;
  });
};

const createInitReducer: Reducer<CompetenceCreateState, void> = state => {
  return produce(state, draft => {
    draft.createStatus = 'submitting';
  });
};

const createSuccessReducer: Reducer<CompetenceCreateState, void> = state => {
  return produce(state, draft => {
    draft.createStatus = 'success';
  });
};

const createFailureReducer: Reducer<CompetenceCreateState, ErrorResponse> = (
  state,
  action
) => {
  return produce(state, draft => {
    draft.createStatus = action.createStatus;
  });
};

export const actions = Object.freeze({
  clear: createCreator<void>(Types.CLEAR),
  createInit: createCreator<CreateInit>(Types.CREATE_INIT),
  createSuccess: createCreator<void>(Types.CREATE_SUCCESS),
  createFailure: createCreator<ErrorResponse>(Types.CREATE_FAILURE)
});

export const reducer = createReducer(initialState, {
  [Types.CLEAR]: clearReducer,
  [Types.CREATE_INIT]: createInitReducer,
  [Types.CREATE_SUCCESS]: createSuccessReducer,
  [Types.CREATE_FAILURE]: createFailureReducer
});

// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import * as API from 'domain/api';
import * as M from '~/domain/model';
import { generateErrorMessage } from '~/helpers/util/error';
import * as CustomerServices from '~/services/producer/Customer';
import * as OrderServices from '~/services/producer/Order';
import { actions as unpublishedActions } from '~/store/modules/unpublished';
import { alertRequest } from '../../alert/actions';

export const createOrders = createAsyncThunk(
  'orders/createOrders',
  async (
    orderFilterParams: M.OrderCreateParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await OrderServices.createOrdersRequest(
        orderFilterParams
      );
      dispatch(alertRequest('Pedido criado com sucesso!', 'success'));
      return response.data;
    } catch (err: any) {
      const errorMessage = generateErrorMessage(err, 'Erro ao criar pedido.');
      dispatch(alertRequest(errorMessage, 'error'));

      return rejectWithValue(errorMessage);
    }
  }
);

export const saveOrders = createAsyncThunk(
  'orders/saveOrders',
  async (
    orderFilterParams: M.OrderCreateParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await OrderServices.saveOrdersRequest(orderFilterParams);
      dispatch(alertRequest('Pedido salvo com sucesso!', 'success'));
      return response.data;
    } catch (err: any) {
      const errorMessage = generateErrorMessage(err, 'Erro ao criar pedido.');
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchOrders = createAsyncThunk(
  'orders/fetchOrders',
  async (
    orderFilterParams: M.OrderFilterParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await OrderServices.findOrdersRequest(orderFilterParams);
      return response.data;
    } catch (err: any) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao carregar pedidos.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchOrdersQualityObservations = createAsyncThunk(
  'orders/fetchOrdersQualityObservations',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response =
        await OrderServices.findOrdersQualityObservationsRequest();
      return response;
    } catch (err: any) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao carregar pedidos.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchOrdersStocks = createAsyncThunk(
  `stocks/fetchOrdersStocks`,
  async (params: M.FindOrderStockParams, { dispatch, rejectWithValue }) => {
    try {
      const response = await OrderServices.fetchOrdersStocksRequest(params);
      if (!response.results?.length) {
        throw new Error(
          'Não existe Estoque cadastrado para o período informado!'
        );
      }
      console.log('fetchOrdersStocks > response:: ', response);
      const tableData: any = response.results.map((data, index) => {
        const packingDistributionCompositionIds: number[] = [];
        data.productPreference?.availablePackingDistributionCompositions?.forEach(
          (packing: API.AvailablePackingDistributionComposition) => {
            if (packing?.selected) {
              packingDistributionCompositionIds.push(packing.id);
            }
          }
        );
        return {
          packingId: undefined,
          packingCode: undefined,
          quantityPerPackage: undefined,
          packingDistributionCompositionIds: [],
          productQualityDescription: data.qualityDescription,
          stockId: data.stockId,
          periodDescription: data.periodDescription,
          productDescription: data.productDescription,
          productCode: data.productCode,
          productId: data.productId,
          productPreference: data.productPreference,
          index,
          key: index,
          useStockOnlyVol: data.isVolOnly,
          remainingQuantity: data.remainingQuantity,
          observationId1: '',
          observationId2: '',
          observationText: '',
          isSelected: false
        };
      });

      console.log('fetchOrdersStocks > tableData:: ', tableData);

      const keywordsArray = params.Keyword?.split(' ');
      const isLetter = /^[a-zA-Z]/.test(params.Keyword ?? '');
      let newTableData: any = tableData;

      keywordsArray?.forEach((word: string) => {
        newTableData = newTableData.filter((item: any) =>
          item.productDescription.toLowerCase().includes(word.toLowerCase())
        );
      });

      return isLetter ? newTableData : tableData;
    } catch (err: any) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao carregar produtos.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const updateOrders = createAsyncThunk(
  'orders/updateOrders',
  async (
    orderUpdateParams: M.OrderUpdateParam,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await OrderServices.updateOrdersApi(orderUpdateParams);
      return response.data;
    } catch (err: any) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao responder pedido.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const cancelOrder = createAsyncThunk(
  'orders/orderCancel',
  async (ids: any[], { rejectWithValue, dispatch }) => {
    try {
      const response = await OrderServices.cancelOrderRequest(ids);
      dispatch(alertRequest('Pedido cancelado com sucesso!', 'success'));
      dispatch(unpublishedActions.setReloadPage(true));
      return response.data;
    } catch (err: any) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao responder pedido.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchCustomers = createAsyncThunk(
  `orders/fetchCustomers`,
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await CustomerServices.findCustomersForVD();
      return response;
    } catch (err: any) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao carregar clientes.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchOrdersOpNatures = createAsyncThunk(
  `orders/fetchOrdersOpNatures`,
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await OrderServices.findOpNatures();
      return response;
    } catch (err: any) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao carregar vendedores.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchOrdersOpNaturesForCustomer = createAsyncThunk(
  `orders/fetchOrdersOpNaturesForCustomer`,
  async (customerId: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await OrderServices.findOpNaturesForCustomer(customerId);
      return response;
    } catch (err: any) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao carregar vendedores.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchOrdersRemoveLocations = createAsyncThunk(
  `orders/fetchOrdersRemoveLocations`,
  async (
    params: { accountcode: string; siteId: number },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await OrderServices.findRemoveLocationsRequest(
        params.accountcode,
        params.siteId
      );
      return response;
    } catch (err: any) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao carregar locais de retirada.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchPublishers = createAsyncThunk(
  `orders/fetchPublishers`,
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await OrderServices.findPublishers();
      return response;
    } catch (err: any) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao carregar vendedores.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const answerOrderIntentions = createAsyncThunk(
  'orders/answerOrderIntentions',
  async (params: M.OrderIntentionAnswer[], { dispatch, rejectWithValue }) => {
    try {
      const response = await OrderServices.answerOrderIntentionsRequest(params);
      dispatch(alertRequest('Pedido foi respondido com sucesso!', 'success'));
      return response.data;
    } catch (err: any) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao responder pedido.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const republishDirectedSale = createAsyncThunk(
  'orders/answerOrderIntentions',
  async (params: any[], { dispatch, rejectWithValue }) => {
    try {
      const response = await OrderServices.republishDirectedSale(params);
      dispatch(alertRequest('Pedido foi republicado com sucesso!', 'success'));
      return response.data;
    } catch (err: any) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao republicar pedido.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

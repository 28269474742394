import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';

import service from '~/services/api';
import * as API from '~/domain/api';

import { actions } from './duck';
import { HistoryPriceAlert, PriceAlert } from '~/domain/model';

export function* findMany(params: { nextPage: number; nextPageSize: number }) {
  let URL = '/preferences-offer-channels/price-alert?';
  const { nextPage, nextPageSize } = params;
  if (nextPage) {
    URL += `PageNumber=${nextPage}`;
  }
  if (nextPageSize) {
    URL += `&PageSize=${nextPageSize}`;
  }
  const response: AxiosResponse = yield call(service.get, URL);
  const apiResponse = response.data as API.DefaultResponse;
  const priceAlerts: API.PriceAlert[] = apiResponse.results ?? [];
  const priceAlertByID = new Map(
    priceAlerts.map(priceAlert => [
      String(priceAlert.id),
      new PriceAlert(priceAlert)
    ])
  );
  yield put(actions.update({ priceAlertByID }));
  return apiResponse;
}

type UpdateOrCreate = {
  id: number;
  categoryId: number;
  percentBelowAverage: number;
  percentAboveAverage: number;
};

export const create = async (data: UpdateOrCreate) => {
  await service.post('/preferences-offer-channels/price-alert', data);
};

export const update = async (data: UpdateOrCreate) => {
  await service.put('/preferences-offer-channels/price-alert', data);
};

export function* history(params: {
  id: number;
  nextPage: number;
  nextPageSize: number;
}) {
  const { id, nextPage, nextPageSize } = params;

  let URL = `/preferences-offer-channels/price-alert/history/${id}?`;

  if (nextPage) {
    URL += `PageNumber=${nextPage}`;
  }
  if (nextPageSize) {
    URL += `&PageSize=${nextPageSize}`;
  }

  const response: AxiosResponse = yield call(service.get, URL);
  const apiResponse = response.data as API.DefaultResponse;
  const historyPriceAlerts: API.HistoryPriceAlert[] = apiResponse.results ?? [];
  const priceAlertHistoryById = new Map(
    historyPriceAlerts.map(historyPriceAlert => [
      String(historyPriceAlert.id),
      new HistoryPriceAlert(historyPriceAlert)
    ])
  );
  yield put(actions.update({ priceAlertHistoryById }));
  return apiResponse;
}

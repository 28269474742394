/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Select, SelectProps } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as M from '~/domain/model';
import { generateErrorMessage } from '~/helpers/util/error';
import { fetchPackingsBySiteAndProductRequest } from '~/services/producer/Packing';
import { alertRequest } from '~/store/modules/alert/actions';
import { useOrdersState } from '~/store/modules/orders/selectors';

interface Props extends SelectProps {
  productId: number;
  defaultPacking?: Partial<M.FetchPackingBySiteAndProduct>;
  siteId?: number;
  loadOnClick?: boolean;
  stockId?: number;
  onChange?: (
    selectedPacking?: Partial<M.FetchPackingBySiteAndProduct>
  ) => void;
}
export const SelectOrderPacking = ({
  productId,
  defaultPacking,
  siteId,
  onChange,
  loadOnClick = false,
  stockId,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [packings, setPackings] = useState<
    Partial<M.FetchPackingBySiteAndProduct>[]
  >(defaultPacking ? [defaultPacking] : []);

  const { currentStep, resumeTableData } = useOrdersState();

  const fetchPackings = useCallback(async () => {
    if (!productId || !siteId || isDataFetched) return;
    setLoading(true);
    fetchPackingsBySiteAndProductRequest({
      siteId,
      productId
    })
      .then(res => {
        setPackings(res.data);
      })
      .catch(err => {
        const errorMessage = generateErrorMessage(
          err,
          'Erro ao carregar embalagens.'
        );
        dispatch(alertRequest(errorMessage, 'error'));
      })
      .finally(() => {
        setLoading(false);
        setIsDataFetched(true);
      });
  }, [dispatch, isDataFetched, productId, siteId]);

  useEffect(() => {
    setIsDataFetched(false);
  }, [productId, siteId]);

  useEffect(() => {
    if (loadOnClick) return;
    fetchPackings();
  }, []);

  useEffect(() => {
    if (stockId) {
      resumeTableData.forEach((data: any) => {
        if (stockId === data.stockId) {
          fetchPackings();
        }
      });
    }
  }, [currentStep]);

  return (
    <div
      onClick={() => {
        if (loadOnClick) {
          fetchPackings();
        }
      }}
    >
      <Select
        allowClear
        dropdownStyle={{ minWidth: '300px' }}
        loading={loading}
        filterOption
        optionFilterProp="children"
        placeholder={t('generals.selectPlaceholder')}
        className={!productId || !siteId ? 'error' : undefined}
        style={{ maxWidth: 75, width: 75 }}
        showSearch
        {...props}
        disabled={props.disabled}
        onChange={v => {
          if (onChange) {
            onChange(packings?.find(i => i.id === v));
          }
        }}
      >
        {packings?.map((item: Partial<M.FetchPackingBySiteAndProduct>) => (
          <Select.Option key={item.id} value={item.id}>
            {item.code} - {item.description}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

import IUseCase from '~/@core/application/IUseCase';
import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';
import FilterData from '~/@core/domain/filters/rfidReports/FilterData';
import Vision from '~/@core/domain/model/rfidReports/Vision';
import { ReceiptService } from '~/@core/infrastructure/api/services/rfidReports';

export default class GetVisions
  implements IUseCase<FilterData, Promise<DefaultPaginated<Vision[]>>>
{
  execute(filter: FilterData): Promise<DefaultPaginated<Vision[]>> {
    return ReceiptService.fetchVisions(filter);
  }
}

import * as Effects from 'redux-saga/effects';

import { CreateInit } from './duck';
import * as CustomersGroupService from '~/services/producer/CustomersGroup';

const call: any = Effects.call;

export function* create(createInit: CreateInit) {
  const { id, description, customersIds, siteId } = createInit;
  yield call(CustomersGroupService.create, {
    id,
    description,
    customersIds,
    siteId
  });
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LkpOffer } from '~/domain/model';

export type Status = 'cleared' | 'updated';

export type LkpOffersState = {
  byId: LkpOffer[] | any;
  listToEdit: EditParams[];
  status: Status;
  disabledSave: boolean;
};

export type Update = {
  byId: LkpOffer[];
};

export type ParamsPrepareToEdit = {
  siteId: number;
  weekId: number;
};

export type FetchLkpOffers = {
  siteId: number;
  startDate: any;
  endDateStock: any;
  productId?: number;
  categoryId?: number;
  expeditionId?: number;
  groupId?: number;
  productQualityId?: number;
  productRecipient?: string;
  pageNumber?: number;
  pageSize?: number;
  lkpOfferId: any;
  weekStartDate?: any;
  weekEndDate?: any;
  status?: boolean;
  ColorId?: number;
  keyword?: string;
};

export type EditParams = {
  siteId?: number;
  weekId?: number;
  lkpOfferId: number;
  isActive?: any;
  productQualityId?: number;
  lkpPercentage: number;
  productId?: number;
  initialDate?: string;
  finalDate?: string;
  unitPrice?: number;
  remarks?: string;
  avgPriceSold?: number;
  percentAboveAverage?: number;
  percentBelowAverage?: number;
};

export type FetchHistory = {
  lkpOfferId: number;
};

export type StatusOffer = {
  lkpOfferIds: number[];
  isActive: boolean;
};

export type FetchGfps = {
  siteId?: number;
  weekId?: number;
  productId?: number;
  productQualityId?: number;
  auctionDate?: string;
  outtingDate?: string;
  pageSize?: number;
  pageNumber?: number;
  startDateStock?: string;
  endDateStock?: string;
};

export type SetPropertyAction = {
  value: any;
  property: string;
  index: number;
};

export type SetPropertiesDatesAction = {
  initialDate: string;
  finalDate: string;
  index: number;
};

const prefix = '@lkpOffers';

export const initialState: LkpOffersState = {
  byId: [],
  listToEdit: [],
  status: 'cleared',
  disabledSave: true
};

const lkpOffers = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: state => {
      state.byId = initialState.byId;
      state.status = initialState.status;
    },
    clearEdit: state => {
      state.listToEdit = initialState.listToEdit;
      (state.byId as LkpOffer[]).forEach(offer => (offer.toSend = false));
    },
    ErrorEdit: state => {
      state.listToEdit = initialState.listToEdit;
    },
    update: (state, action: PayloadAction<Update>) => {
      state.byId = action.payload.byId;
    },
    changeStatus: (_state, _action: PayloadAction<StatusOffer>) => {},
    prepareToEdit: (state, action: PayloadAction<ParamsPrepareToEdit>) => {
      const itensEdited: LkpOffer[] = [];
      const listForSendToEdit: EditParams[] = [];
      const arrayOffers = [...state.byId] as LkpOffer[];

      arrayOffers.forEach(offer => {
        if (offer.toSend === true) {
          itensEdited.push(offer);
        }
      });

      if (itensEdited && itensEdited.length) {
        itensEdited.forEach(item => {
          listForSendToEdit.push({
            siteId: action.payload.siteId,
            weekId: action.payload.weekId,
            isActive: item.isActive,
            productQualityId: item.productQualityId,
            lkpPercentage: item.percentage,
            productId: item.productId,
            initialDate: item.initialDate,
            finalDate: item.finalDate,
            unitPrice: item.price,
            remarks: item.remarks,
            avgPriceSold: item.avgPriceSold,
            percentAboveAverage: item.percentAboveAverage,
            percentBelowAverage: item.percentBelowAverage,
            lkpOfferId: item.lkpOfferId
          });
        });
        state.listToEdit = listForSendToEdit;
      }
    },
    updatePropertyValue: (state, action: PayloadAction<SetPropertyAction>) => {
      const { payload } = action;
      state.byId[payload.index][payload.property] = payload.value;
      state.byId[payload.index].toSend = true;
      state.disabledSave = false;
    },
    enableSave: (state, _action: PayloadAction<boolean>) => {
      state.disabledSave = false;
    },
    disableSave: draft => {
      draft.disabledSave = initialState.disabledSave;
    },
    updatePropertyDates: (
      state,
      action: PayloadAction<SetPropertiesDatesAction>
    ) => {
      const { payload } = action;
      state.byId[payload.index] = {
        ...state.byId[payload.index],
        initialDate: payload.initialDate,
        finalDate: payload.finalDate
      };
      state.byId[payload.index].toSend = true;
    },
    updateById: (state, action: PayloadAction<LkpOffer[]>) => {
      const responseData = action.payload;
      state.byId = state.byId.map((lkpOffer: LkpOffer, index: number) => {
        const lkpInResponse = responseData.find(
          param => param.lkpOfferId === lkpOffer.id
        );
        if (lkpInResponse) {
          lkpInResponse.key = index;
          return { ...lkpInResponse };
        }
        lkpOffer.key = index;
        return { ...lkpOffer };
      });
    },
    updateWithStatus: (state, action: PayloadAction<StatusOffer>) => {
      const responseData = action.payload;
      state.byId = state.byId.map((lkpOffer: LkpOffer) => {
        const editedOffer = responseData.lkpOfferIds.find(
          offerId => offerId === lkpOffer.id
        );
        if (editedOffer) {
          lkpOffer.isActive = responseData.isActive;
        }
        return { ...lkpOffer };
      });
    }
  }
});
export const actions = lkpOffers.actions;
export const reducer = lkpOffers.reducer;

const caseReducers = lkpOffers.caseReducers;
export const Types = Object.freeze({
  clear: `${prefix}/${caseReducers.clear.name}`,
  clearEdit: `${prefix}/${caseReducers.clearEdit.name}`,
  update: `${prefix}/${caseReducers.update.name}`,
  prepareToEdit: `${prefix}/${caseReducers.prepareToEdit.name}`,
  updatePropertyValue: `${prefix}/${caseReducers.updatePropertyValue.name}`,
  updatePropertyDates: `${prefix}/${caseReducers.updatePropertyDates.name}`
});

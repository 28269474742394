import produce from 'immer';

const INITIAL_STATE = {
  data: {},
  states: [],
  cities: [],
  loading: false
};

export default function localization(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@localization/SEARCH_REQUEST':
    case '@localization/STATE_REQUEST':
    case '@localization/CITY_REQUEST':
      return produce(state, draft => {
        draft.loading = true;
      });

    case '@localization/SEARCH_FAILURE':
    case '@localization/STATE_FAILURE':
    case '@localization/CITY_FAILURE':
      return produce(state, draft => {
        draft.loading = false;
      });

    case '@localization/SEARCH_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.data = action.payload.data;
        draft.cities = action.payload.cities;
        draft.cities.push({ id: -1, stateId: 0, name: 'NAO ENCONTRADA' });
      });

    case '@localization/STATE_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.states = action.payload.data;
      });

    case '@localization/CITY_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.cities = action.payload.data;
      });

    default:
      return state;
  }
}

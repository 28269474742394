import styled from 'styled-components';
import { darken, lighten } from 'polished';
import { Layout, Menu } from 'antd';

export const Logo = styled.div`
  padding: 0 60px 0 60px;
  margin-top: -10px;
  margin-bottom: -30px;
  text-align: center;

  &.collapsed {
    margin-bottom: 20px;
    padding: 10px 0 0 0;

    img {
      margin-bottom: -20px;
      width: 50px;
      height: 50px;
    }
  }

  img {
    height: 100px;
    width: 100px;
    object-fit: contain;
  }
`;

export const Guia = styled.li`
  padding: 7px 0 5px 0;
  font: 13px 'Roboto', sans-serif;
  color: #fff;
`;

export const Link = styled.a`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font: 13px 'Roboto', sans-serif;
  color: #fff !important;
  font-weight: 400;
  margin-bottom: 7px;

  &:hover {
    color: #fff !important;
  }

  span {
    font: 13px 'Roboto', sans-serif;
    color: #fff !important;
    font-weight: 400;
  }

  svg {
    color: #fff;
    margin-right: 10px;
    font-size: 13px;
  }
`;

export const closeButtonStyle = {
  marginLeft: 12,
  display: 'flex',
  alignItems: 'center'
};

export const Sider = styled(Layout.Sider)`
  background-color: #f78e1e;
  height: auto;

  .ant-layout-sider-trigger {
    background-color: ${darken(0.05, '#F78E1E')};
    display: ${props => (props.className === 'isMobile' ? 'none' : 'block')};
  }

  .ant-menu-inline-collapsed::before {
    display: unset;
  }
  & > .ant-layout-sider-children {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
`;

export const MenuContainer = styled(Menu)`
  background-color: #f78e1e;
`;

export const MenuItem = styled(Menu.Item)`
  background-color: #f78e1e;
  padding: 5px 10px 0 10px;
  font: 13px 'Roboto', sans-serif;
  color: #fff;
  font-weight: 400;

  &:hover {
    background-color: ${lighten(0.1, '#F78E1E')};
    color: #fff;
  }
`;

export const MenuGroup = styled(Menu.ItemGroup)`
  background-color: #f78e1e;

  .ant-menu-item-group-title {
    display: ${props => (props.className === 'collapsed' ? 'none' : 'block')};
    font: 13px 'Roboto', sans-serif !important;
    color: #fbfcfe;
    font-weight: 500;
    text-transform: uppercase;
  }
`;

export const SubMenu = styled(Menu.SubMenu)`
  background-color: #f78e1e;
  font: 13px 'Roboto', sans-serif !important;
  color: #fff;

  &:hover {
    color: #fff;
  }

  .ant-menu-submenu-arrow {
    color: #fff;

    &:hover {
      color: #fff;
    }
  }
`;

export const ProfileContent = styled.div`
  width: 100%;
  max-width: 256px;
  display: flex;
  flex-direction: column;
  justify-content: center
  align-items: center;
  bottom: 117px;
  z-index: 1;


  &.collapsed {
    max-width: 80px;
  }

  &.isMobile {
    bottom: 48px;
  }

  svg {
    font-size: 16px;
    margin-right: 12px;
    color: #fff;
  }
  div {
    width: 100%;
    padding-bottom: 5px;
    padding: 0 0px 0 0px;
  }
`;

export const ProfileName = styled.span`
  text-align: left;
  font: 14px 'Roboto', sans-serif;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
`;

export const MessageExpiration = styled.p`
  width: 100%;
  max-width: 256px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  bottom: 32px;
  z-index: 1;
  height: 48px;
  padding: 0 24px 0 24px;

  color: #fff;
`;

import CreateGfpItems from '../../model/gfp/CreateGfpItems';
import Order from '../../model/order/Order';

export class MapOrderSelectedToGfpItems {
  static toGfpItems(orderSelected: Order[]): CreateGfpItems[] {
    const items: CreateGfpItems[] = [];

    for (let i = 0; i < orderSelected.length; i++) {
      const order = orderSelected[i];
      items.push({
        saleTransactionId: order.orderId,
        packingId: order.packingId as number,
        quantityPacking: order.quantityPacking,
        unitQuantity: order.quantityPerPacking,
        obs1: order.observationId1,
        obs2: order.observationId2,
        observation: order.observation,
        gfpObservation: order.gfpObservation || ''
      });
    }
    return items;
  }
}

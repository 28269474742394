import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import {
  CancelPurchaseManagement,
  FetchEcommerceManagement
} from '~/domain/model';
import { handleDefaultErrorApi } from '~/helpers/util';
import {
  cancelPurchaseManagement,
  findHistoryPurchaseManagement,
  findListPurchaseManagement,
  findProducts,
  onExport
} from '~/services/producer/PurchaseManagement';

export const fetchProducts = createAsyncThunk(
  'ecommerceManagement/fetchProducts',
  async () => {
    const response = await findProducts();
    return response.data;
  }
);

export const fetchList = createAsyncThunk(
  'ecommerceManagement/fetchList',
  async (fetchListParams: FetchEcommerceManagement, { rejectWithValue }) => {
    try {
      const response = await findListPurchaseManagement(fetchListParams);
      return response.data;
    } catch (error) {
      handleDefaultErrorApi(error as AxiosError);
      return rejectWithValue([]);
    }
  }
);

export const cancelPurchase = createAsyncThunk(
  'ecommerceManagement/cancelPurchase',
  async (cancelParams: CancelPurchaseManagement, { rejectWithValue }) => {
    try {
      const response = await cancelPurchaseManagement(cancelParams);
      return response.data;
    } catch (error) {
      handleDefaultErrorApi(error as AxiosError);

      return rejectWithValue([]);
    }
  }
);

export const fetchHistoryPurchase = createAsyncThunk(
  'ecommerceManagement/fetchHistoryPurchase',
  async (transactionId: number, { rejectWithValue }) => {
    try {
      const response = await findHistoryPurchaseManagement(transactionId);
      return response.data;
    } catch (error) {
      handleDefaultErrorApi(error as AxiosError);
      return rejectWithValue([]);
    }
  }
);

export const exportPurchases = createAsyncThunk(
  'ecommerceManagement/exportPurchases',
  async (fetchListParams: FetchEcommerceManagement, { rejectWithValue }) => {
    try {
      const response = await onExport(fetchListParams);
      const data: string = response?.data?.data;
      const binaryString = window.atob(data);
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      const fileData = bytes;

      const blob = new Blob([fileData], { type: 'application/octet-stream' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.style.display = 'none';
      const fileName = 'gestao_ecommerce.xlsx';
      link.download = fileName;
      link.click();
      return true;
    } catch (error) {
      handleDefaultErrorApi(error as AxiosError);
      return rejectWithValue([]);
    }
  }
);

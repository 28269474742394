import { Form } from 'antd';
import ReceiptWrapper from '~/components/organisms/RfidReports/ReceiptWrapper';

export default function Receipt() {
  const [filterFormDone] = Form.useForm();
  const [filterFormNonConformity] = Form.useForm();
  const [filterFormTransit] = Form.useForm();
  const [filterFormVision] = Form.useForm();

  return (
    <ReceiptWrapper
      filterFormDone={filterFormDone}
      filterFormNonConformity={filterFormNonConformity}
      filterFormTransit={filterFormTransit}
      filterFormVision={filterFormVision}
    />
  );
}

import { AxiosResponse } from 'axios';
import apiProducer from '../api';

import { endpoints } from '~/constants/endpoints';
import { APIError } from '~/domain/api/errors';
import * as M from '~/domain/model';
import RecipientDetailed from '~/domain/model/Product/RecipientDetailed';

type FindMany = {
  siteId: number;
  groupId?: number;
};

export const findMany = async ({ siteId, groupId }: FindMany) => {
  try {
    const params = new URLSearchParams();
    if (siteId) {
      params.append('siteId', String(siteId));
    }
    if (groupId) {
      params.append('groupId', String(groupId));
    }
    const response: AxiosResponse = await apiProducer.get('/product', {
      params
    });
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const findProductsSimple = async (siteId: number) => {
  try {
    const response: AxiosResponse = await apiProducer.get(
      `/product/site/${siteId}/simple`
    );
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};
export const findProductsCategories = (siteId: number) => {
  return apiProducer.get(`${endpoints.product.categories}${siteId}`);
};
export const findProductionProductsGroups = (siteId: number) => {
  return apiProducer.get(
    `${endpoints.productionProductGroup}?siteId=${siteId}`
  );
};

export const findProductsBySite = async (
  siteId?: number
): Promise<M.ProductBySite[]> => {
  if (!siteId) {
    const response: AxiosResponse<any[]> = await apiProducer.get(
      `/product/products`,
      {
        headers: {
          'X-Is-Async-Loading': 'true'
        }
      }
    );
    return (
      response.data?.map(i => {
        return {
          productCode: i.code,
          productId: i.id,
          productName: i.fullName,
          siteList: []
        };
      }) ?? []
    );
  }
  const response: AxiosResponse<M.ProductBySite[]> = await apiProducer.get(
    `/product/products-by-site/${siteId}`,
    {
      headers: {
        'X-Is-Async-Loading': 'true'
      }
    }
  );
  return response.data ?? [];
};

export const findProductsDetailed = (siteId: number) => {
  return apiProducer.get(`/product/site/${siteId}/detailed`);
};

export const findProductsBySearch = async (
  keyword: string
): Promise<M.ProductBySite[]> => {
  const response: AxiosResponse<M.ProductBySite[]> = await apiProducer.get(
    `/product/products-by-search/${keyword}`
  );
  return response.data ?? [];
};

export const findProductsGroups = (siteId: number) => {
  return apiProducer.get(`/product-group/site/${siteId}`);
};

export const findRecipients = (siteId: number) => {
  const URL = endpoints.product.recipients;
  return apiProducer.get(URL, { params: { siteId } });
};
export const findRecipientsDetailed = (siteId: number) => {
  const URL = endpoints.product.recipientsDetailed;
  return apiProducer.get<void, AxiosResponse<RecipientDetailed[]>>(URL, {
    params: { siteId }
  });
};

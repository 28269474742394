import {
  Packing,
  PackingDistribution,
  PackingDistributionComposition,
  HistoryPackingDistribution,
  HistoryPackingDistributionComposition,
  Extract
} from '~/domain/model';

// eslint-disable-next-line no-shadow
export enum ActionTypes {
  listPackingsRequest = `@packing/LIST_PACKING_REQUEST`,
  listPackingsSuccess = `@packing/LIST_PACKING_SUCCESS`,
  listPackingsFailure = `@packing/LIST_PACKING_FAILURE`,
  getExtractsRequest = `@packing/GET_EXTRACTS_REQUEST`,
  getExtractsSuccess = `@packing/GET_EXTRACTS_SUCCESS`,
  getExtractsFailure = `@packing/GET_EXTRACTS_FAILURE`,
  getByIdPackingDistributionRequest = `@packing/GET_BY_ID_PACKING_DISTRIBUTION_REQUEST`,
  getByIdPackingDistributionSuccess = `@packing/GET_BY_ID_PACKING_DISTRIBUTION_SUCCESS`,
  getByIdPackingDistributionFailure = `@packing/GET_BY_ID_PACKING_DISTRIBUTION_FAILURE`,
  getPackingDistributionRequest = `@packing/GET_PACKING_DISTRIBUTION_REQUEST`,
  getPackingDistributionSuccess = `@packing/GET_PACKING_DISTRIBUTION_SUCCESS`,
  getPackingDistributionFailure = `@packing/GET_PACKING_DISTRIBUTION_FAILURE`,
  clearGetAllPackingDistribution = `@packing/CLEAR_GET_ALL_PACKING_DISTRIBUTION`,
  getAllPackingDistribution = `@packing/GET_ALL_PACKING_DISTRIBUTION`,
  getAllPackingDistributionSuccess = `@packing/GET_ALL_PACKING_DISTRIBUTION_SUCCESS`,
  getAllPackingDistributionFailure = `@packing/GET_ALL_PACKING_DISTRIBUTION_FAILURE`,
  getHistoryPackingDistributionRequest = `@packing/GET_HISTORIC_PACKING_DISTRIBUTION_REQUEST`,
  getHistoryPackingDistributionSuccess = `@packing/GET_HISTORIC_PACKING_DISTRIBUTION_SUCCESS`,
  getHistoryPackingDistributionFailure = `@packing/GET_HISTORIC_PACKING_DISTRIBUTION_FAILURE`,
  getHistoryPackingDistributionCompositionRequest = `@packing/GET_HISTORIC_PACKING_DISTRIBUTION_COMPOSITION_REQUEST`,
  getHistoryPackingDistributionCompositionSuccess = `@packing/GET_HISTORIC_PACKING_DISTRIBUTION_COMPOSITION_SUCCESS`,
  getHistoryPackingDistributionCompositionFailure = `@packing/GET_HISTORIC_PACKING_DISTRIBUTION_COMPOSITION_FAILURE`,
  createPacking = `@packing/CREATE_PACKING`,
  registerPackingModel = `@packing/REGISTER_PACKING_MODEL`,
  setDataRegisterPackingModel = `@packing/SET_DATA_REGISTER_PACKING_MODEL`,
  deletePackingModel = `@packing/DELETE_PACKING_MODEL`,
  savePackingDataRequest = `@packing/SAVE_PACKING_DATA_REQUEST`,
  savePackingDataSuccess = `@packing/SAVE_PACKING_DATA_SUCCESS`,
  savePackingDataFailure = `@packing/SAVE_PACKING_DATA_FAILURE`,
  toggleCreateModal = `@packing/TOGGLE_CREATE_MODAL`,
  loadingCreateModal = `@packing/LOADING_CREATE_MODAL`,
  toggleRegisterModelModal = `@packing/TOGGLE_REGISTER_MODEL_MODAL`,
  loadingRegisterModelModal = `@packing/LOADING_REGISTER_MODEL_MODAL`,
  toggleHistoryModal = `@packing/LOADING_TOGGLE_HISTORIC_MODAL`,
  toggleHistoryModelModal = `@packing/LOADING_TOGGLE_HISTORIC_MODEL_MODAL`,
  setPagePagination = `@packing/SET_PAGE_PAGINATION`,
  setPageSizePagination = `@packing/SET_PAGE_SIZE_PAGINATION`,
  resetPackingDistribution = `@packing/RESET_PACKING_DISTRIBUTION`,
  resetPackingState = `@packing/RESET_PACKING_STATE`,
  resetPackingExtractState = `@packing/RESET_PACKING_EXTRACT_STATE`,
  setSubmitForm = `@packing/SET_SUBMIT_FORM`,
  removeModel = `@packing/REMOVE_MODEL`,
  removeModelSuccess = `@packing/REMOVE_MODEL_SUCCESS`,
  removeModelFailure = `@packing/REMOVE_MODEL_FAILURE`
}

export type FiltersPackingState = {
  siteId: number | null;
};

type Modal = {
  isVisible: boolean;
  loading: boolean;
};

type CreateModal = Modal;

type CreateModelModal = Modal;

type HistoryModal = Modal & {
  packingDistributionId: number | null;
  historyPackingDistribution?: {
    id?: number;
    packingCode?: string;
    packingDescription?: string;
    packingQuantity?: number;
    histories: HistoryPackingDistribution[];
  };
};

type HistoryModelModal = Modal & {
  packingDistributionCompositionId: number | null;
  historyPackingDistributionComposition?: {
    id?: number;
    packingCode?: string;
    packingDescription?: string;
    packingQuantity?: number;
    histories: HistoryPackingDistributionComposition[];
  };
};

type Pagination = {
  current: number;
  pageSize: number;
  total: number;
};

export interface IPackingState {
  packings: Packing[];
  allPackingDistribution: Packing[];
  packingDistribution: PackingDistribution[];
  packingDistributionToSave: PackingDistribution | null;
  packingDistributionIsChanged: boolean;
  packingIdSelected: number | null;
  packingModelToEdit: PackingDistributionComposition | null;
  extracts: Extract[];
  loading: boolean;
  loadingSave: boolean;
  createModal: CreateModal;
  createModelModal: CreateModelModal;
  historyModal: HistoryModal;
  historyModelModal: HistoryModelModal;
  filters: FiltersPackingState;
  pagination: Pagination;
  paginationExtract: Pagination;
  submitForm: boolean;
}

interface IPayload {
  [k: string]: any;
}

export interface IActionPacking {
  type: string;
  payload?: IPayload;
}

export interface CreatePacking {
  siteId: number;
  packingId: number;
  quantity: number;
}

export type RegisterPackingModel = Pick<
  PackingDistributionComposition,
  | 'id'
  | 'layersByTrolley'
  | 'modelDescription'
  | 'packagingByLayer'
  | 'unitsPerPackage'
  | 'isEdit'
>;

export type GetPackingDistributionRequest = {
  siteId: number;
  packingId?: number;
  rollingMaterial?: 'true' | 'false';
  pageNumber?: number;
  pageSize?: number;
};

export type GetAllPackingDistribution = {
  siteId: number;
};

export type GetExtractsRequest = {
  siteId: number;
  packingId?: number;
  startPeriod?: string;
  endPeriod?: string;
  pageNumber?: number;
  pageSize?: number;
};
export type RemoveModel = {
  modelId: number;
};

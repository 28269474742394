import { call, put } from 'redux-saga/effects';

import * as API from '~/domain/api';

import { actions } from './duck';

import * as ProductQualityService from '~/services/producer/ProductQuality';
import { ProductQuality } from '~/domain/model';

export function* findMany() {
  const response: API.ServerResponse = yield call(
    ProductQualityService.findMany
  );

  const apiResponse = response.data;
  const productQualities: API.ProductQualityType[] = apiResponse || [];
  const productQualitiesById = new Map(
    productQualities.map(productQuality => [
      String(productQuality.id),
      new ProductQuality(productQuality)
    ])
  );
  yield put(actions.update({ productQualitiesById }));
  return apiResponse;
}

import { call, put, takeLatest } from 'redux-saga/effects';
import { CreateInit, Types, actions } from './duck';
import { Action } from '~/helpers/util/reducers';
import * as CompetenceService from '~/store/modules/_core/competences/service';

export function* createSaga(action: Action<CreateInit>) {
  try {
    const { historicalYear, referenceYear } = action.payload;
    yield call(CompetenceService.create, referenceYear, historicalYear);
    yield put(actions.createSuccess());
  } catch (error) {
    yield put(
      actions.createFailure({ createStatus: 'failure-already-registered' })
    );
  }
}

export default [takeLatest(Types.CREATE_INIT, createSaga)];

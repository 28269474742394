import { AxiosResponse } from 'axios';
import * as Effects from 'redux-saga/effects';

import * as API from '~/domain/api';

import { actions } from './duck';
import { Customer, CustomerGroup, CustomerSegment } from '~/domain/model';
import * as CustomerService from '~/services/producer/Customer';

const call = Effects.call;
const put = Effects.put;

export function* findCustomers() {
  const response: AxiosResponse = yield call(CustomerService.fetchCustomers);
  const apiResponse = response.data as API.Customer[];
  const customersList: API.Customer[] = apiResponse ?? [];
  const customers: Map<string, Customer> = new Map(
    customersList.map(customer => [String(customer.id), new Customer(customer)])
  );
  yield put(actions.update({ customers }));
  return apiResponse;
}

export function* findCustomersByParams(
  groupId?: number,
  voucherId?: number,
  name?: string,
  segmentId?: number
) {
  const response: AxiosResponse = yield call(
    CustomerService.fetchCustomersByParam,
    groupId,
    voucherId,
    name,
    segmentId
  );
  const apiResponse = response.data as API.Customer[];
  const customers: API.Customer[] = apiResponse ?? [];
  const customersByParams: Map<string, Customer> = new Map(
    customers.map(customer => [String(customer.id), new Customer(customer)])
  );
  yield put(actions.update({ customersByParams }));
  return apiResponse;
}

export function* findCustomersByNameAndSegment(
  name?: string,
  segmentId?: number
) {
  const response: AxiosResponse = yield call(
    CustomerService.fetchCustomersByNameAndSegment,
    name,
    segmentId
  );
  const apiResponse = response.data as API.Customer[];
  const customers: API.Customer[] = apiResponse ?? [];
  const customersByNameAndSegment: Map<string, Customer> = new Map(
    customers.map(customer => [String(customer.id), new Customer(customer)])
  );
  yield put(actions.update({ customersByNameAndSegment }));
  return apiResponse;
}

export function* findCustomersByGroupId(groupId?: number) {
  const response: AxiosResponse = yield call(
    CustomerService.fetchCustomersByGroupId,
    groupId
  );
  const apiResponse = response.data as API.Customer[];
  const customers: API.Customer[] = apiResponse ?? [];
  const customersByGroupId: Map<string, Customer> = new Map(
    customers.map(customer => [String(customer.id), new Customer(customer)])
  );
  yield put(actions.update({ customersByGroupId }));
  return apiResponse;
}

export function* findCustomersSegment() {
  const response: AxiosResponse = yield call(
    CustomerService.fetchCustomersSegment
  );
  const apiResponse = response.data as API.CustomerSegment[];
  const customersSegmentList: API.CustomerSegment[] = apiResponse ?? [];
  const customersSegment: Map<string, CustomerSegment> = new Map(
    customersSegmentList.map(customerSegment => [
      String(customerSegment.id),
      new CustomerSegment(customerSegment)
    ])
  );
  yield put(actions.update({ customersSegment }));
  return apiResponse;
}

export function* findCustomerGroups(siteId?: number) {
  const response: AxiosResponse = yield call(
    CustomerService.fetchCustomerGroups,
    siteId
  );
  const apiResponse = response.data as API.CustomerGroup[];
  const groups: API.CustomerGroup[] = apiResponse ?? [];
  const customerGroups: Map<string, CustomerGroup> = new Map(
    groups.map(customerGroup => [
      String(customerGroup.id),
      new CustomerGroup(customerGroup)
    ])
  );
  yield put(actions.update({ customerGroups }));
  return apiResponse;
}

export function propertyExportRequest(filters?: any) {
  return {
    type: '@propertyExport/EXPORT_REQUEST',
    payload: { filters }
  };
}

export function propertyExportSuccess() {
  return {
    type: '@propertyExport/EXPORT_SUCCESS'
  };
}

export function propertyExportFailure() {
  return {
    type: '@propertyExport/EXPORT_FAILURE'
  };
}

import { create } from 'zustand';
import ExpeditionDone from '~/@core/domain/model/rfidReports/ExpeditionDone';
import TablePagination from '~/@core/domain/ui/TablePagination';

interface NonConformityState {
  nonConformities: ExpeditionDone[];
  nonConformityPagination: TablePagination;
  handleNonConformityPagination: (newPagination: TablePagination) => void;
  addNonConformities: (nonConformitiesValues: ExpeditionDone[]) => void;
}

const useNonConformityStore = create<NonConformityState>()(set => ({
  nonConformities: [],
  nonConformityPagination: {
    pageSize: 10,
    current: 1,
    total: 1
  },
  handleNonConformityPagination: newPagination =>
    set({ nonConformityPagination: newPagination }),
  addNonConformities: nonConformityValues =>
    set({ nonConformities: nonConformityValues })
}));

export default useNonConformityStore;

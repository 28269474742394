import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '~/services/api';
import { isNullOrEmpty } from '~/helpers/util';

import { alertRequest } from '../alert/actions';
import { propertyExportSuccess, propertyExportFailure } from './actions';

export function* onExport({ payload }: any): any {
  try {
    const { filters } = payload;

    let params = '';

    if (!filters || filters.length <= 1)
      params += `${isNullOrEmpty(params) ? '?' : '&'}Search=%`;

    if (Array.isArray(filters)) {
      filters.push({ Data_EfetuarDownload: true });

      let isSearch = false;

      filters.forEach(e => {
        if (Object.keys(e).length === 1) {
          if (Object.keys(e)[0] === 'Search') isSearch = true;
          params += `${isNullOrEmpty(params) ? '?' : '&'}${Object.keys(
            e
          )[0].replace('_', '.')}=${e[Object.keys(e)[0]]}`;
        }
      });

      if (!isSearch) params += `${isNullOrEmpty(params) ? '?' : '&'}Search=%`;
    }

    // paginação
    if (Array.isArray(filters)) {
      filters.forEach(e => {
        if (Object.keys(e).length > 1) {
          const iPageNumber = Object.keys(e).findIndex(a => a === 'pageNumber');
          const iPageSize = Object.keys(e).findIndex(a => a === 'pageSize');
          const iOrderBy = Object.keys(e).findIndex(a => a === 'orderBy');
          const iOrderAsc = Object.keys(e).findIndex(a => a === 'orderAsc');

          params += `${isNullOrEmpty(params) ? '?' : '&'}PageNumber=${
            e[Object.keys(e)[iPageNumber]]
          }&PageSize=${e[Object.keys(e)[iPageSize]]}&OrderBy=${
            e[Object.keys(e)[iOrderBy]]
          }&Sort=${e[Object.keys(e)[iOrderAsc]] === 'ascend' ? 'Asc' : 'Desc'}`;
        }
      });
    }

    const response = yield call(
      api.get,
      `/production-property/download${params}`
    );

    const binaryString = window.atob(response?.data?.data);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    const fileData = bytes;

    const blob = new Blob([fileData], { type: 'application/octet-stream' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.style.display = 'none';
    const fileName = 'production-property.xlsx';
    link.download = fileName;
    link.click();

    yield put(propertyExportSuccess());
  } catch (err) {
    yield put(alertRequest(String(err), 'error'));
    yield put(propertyExportFailure());
  }
}

export default all([takeLatest('@propertyExport/EXPORT_REQUEST', onExport)]);

import produce from 'immer';
import { SpecialDate } from '~/domain/model';
import { createCreator, createReducer, Reducer } from '~/helpers/util/reducers';

export type Status = 'cleared' | 'updated';

export type SpecialDateState = {
  specialDateById: Map<string, SpecialDate>;
  status: Status;
};

export type CreateInit = {
  date: string;
  description: string;
  dateStartAlert: string;
  dateEndAlert: string;
};

export type EditInit = {
  id: number;
  date: string;
  description: string;
  dateStartAlert: string;
  dateEndAlert: string;
};

export type DeleteInit = {
  id: number;
};

export type DeleteManyInit = {
  ids: number[];
};

export type Update = {
  specialDateById: Map<string, SpecialDate>;
};

const prefix = '@specialDate';

export const Types = Object.freeze({
  CLEAR: `${prefix}/CLEAR`,
  UPDATE: `${prefix}/UPDATE`
});

export const initialState: SpecialDateState = {
  specialDateById: new Map(),
  status: 'cleared'
};

const clearReducer: Reducer<SpecialDateState, void> = state => {
  return produce(state, draft => {
    draft.specialDateById = initialState.specialDateById;
    draft.status = initialState.status;
  });
};

const updateReducer: Reducer<SpecialDateState, Update> = (state, action) => {
  return produce(state, draft => {
    draft.specialDateById = action.specialDateById as Map<string, SpecialDate>;
  });
};

export const actions = Object.freeze({
  clear: createCreator<void>(Types.CLEAR),
  update: createCreator<Update>(Types.UPDATE)
});

export const reducer = createReducer(initialState, {
  [Types.CLEAR]: clearReducer,
  [Types.UPDATE]: updateReducer
});

import { all, call, put, takeLatest, select } from 'redux-saga/effects';

import api from '~/services/api';

import { searchWeekIdFailure, searchWeekIdSuccess } from './actions';
import { actions } from '../_ui';

export function* onSearchWeekId(): any {
  try {
    yield put(
      actions.loading.setLoading({
        status: 'fetching'
      })
    );
    const start = yield select((state: any) => state.dates.startWeek);
    const end = yield select((state: any) => state.dates.endWeek);
    const response = yield call(
      api.get,
      `/weeks?startDate=${start}&finalDate=${end}`
    );
    yield put(
      actions.loading.setLoading({
        status: 'idle'
      })
    );
    const { data } = response;
    if (response.data.length > 0) {
      yield put(searchWeekIdSuccess(data));
    } else {
      yield put(searchWeekIdFailure());
    }
  } catch (err) {
    yield put(
      actions.loading.setLoading({
        status: 'idle'
      })
    );
    yield put(searchWeekIdFailure());
  }
}

export default all([takeLatest('@dates/CHANGE_WEEK_ID', onSearchWeekId)]);

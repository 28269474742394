import * as API from '~/domain/api';

export default class DeliveryPatternDetail {
  private json: API.SiteDeliveryPatternDetail;
  public readonly registerId: number;
  public readonly orderStartDayNumber: number;
  public readonly orderStartTime: string;
  public readonly orderStartDayName: string;
  public readonly orderEndDayNumber: number;
  public readonly orderEndTime: string;
  public readonly orderEndDayName: string;
  public readonly deliveryDayNumber: number;
  public readonly deliveryTime: string;
  public readonly deliveryDayName: string;

  public getRegisterId(): number {
    return this.json.registerId ?? Number.NaN;
  }

  public getOrderStartDayNumber(): number {
    return this.json.orderStartDayNumber ?? Number.NaN;
  }

  public getOrderStartTime(): string {
    return this.json.orderStartTime ?? '';
  }

  public getOrderStartDayName(): string {
    return this.json.orderStartDayName ?? '';
  }

  public getOrderEndDayNumber(): number {
    return this.json.orderEndDayNumber ?? Number.NaN;
  }

  public getOrderEndTime(): string {
    return this.json.orderEndTime ?? '';
  }

  public getOrderEndDayName(): string {
    return this.json.orderEndDayName ?? '';
  }

  public getDeliveryDayNumber(): number {
    return this.json.deliveryDayNumber ?? Number.NaN;
  }

  public getDeliveryTime(): string {
    return this.json.deliveryTime ?? '';
  }

  public getDeliveryDayName(): string {
    return this.json.deliveryDayName ?? '';
  }

  constructor(json: API.SiteDeliveryPatternDetail) {
    this.json = json;
    this.registerId = this.getRegisterId();
    this.orderStartDayNumber = this.getOrderStartDayNumber();
    this.orderStartTime = this.getOrderStartTime();
    this.orderStartDayName = this.getOrderStartDayName();
    this.orderEndDayName = this.getOrderEndDayName();
    this.orderEndDayNumber = this.getOrderEndDayNumber();
    this.orderEndTime = this.getOrderEndTime();
    this.deliveryDayNumber = this.getDeliveryDayNumber();
    this.deliveryTime = this.getDeliveryTime();
    this.deliveryDayName = this.getDeliveryDayName();
  }
}

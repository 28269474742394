import { call, put } from 'redux-saga/effects';

import * as API from '~/domain/api';

import { LinkSite } from '~/domain/model';
import { actions, CreateAndEditSiteLink, FetchSiteLinks } from './duck';
import * as SiteLinkService from '~/services/producer/SiteLink';

export function* fetchSiteLinks(params: FetchSiteLinks) {
  yield put(actions.update({ siteLinks: [] }));
  const { description, url, pageNumber, pageSize } = params;
  const response: API.ServerResponse = yield call(
    SiteLinkService.fetchSiteLinks,
    description,
    url,
    pageNumber,
    pageSize
  );
  const data: API.DefaultResponse = response.data;
  const siteLinksResult: API.LinkSite[] = data.results ?? [];
  let siteLinks: LinkSite[] = [];
  if (siteLinksResult) {
    siteLinks = siteLinksResult.map((siteLink: any) => new LinkSite(siteLink));
  }
  yield put(actions.update({ siteLinks }));
  return response;
}

export function* editSiteLink(params: CreateAndEditSiteLink) {
  yield call(SiteLinkService.editSiteLink, params);
}

export function* getAllUrls() {
  yield put(actions.update({ urlsList: [] }));
  const response: API.ServerResponse = yield call(SiteLinkService.getAllUrls);
  const urlsList: string[] = response.data ?? [];
  yield put(actions.update({ urlsList }));
  return response;
}

export function* activeOrInactiveSiteLink(id: number) {
  yield call(SiteLinkService.activeOrInactiveSiteLink, id);
}

import { AxiosError, AxiosResponse } from 'axios';
import Customers from '~/@core/domain/model/customer/Customers';
import api from '../config';

export const fetchCustomers = () => {
  return new Promise<Customers[]>((resolve, reject) => {
    api
      .get<void, AxiosResponse<Customers[]>>('/customers/customers-vd')
      .then((response: AxiosResponse<Customers[]>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const fetchCustomerByCode = (code: string) => {
  return new Promise<Customers>((resolve, reject) => {
    api
      .get<string, AxiosResponse<Customers>>(
        `/customers/account-code?code=${code}`
      )
      .then((response: AxiosResponse<Customers>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

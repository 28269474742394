import { create } from 'zustand';
import Area from '~/@core/domain/model/rfidReports/Area';
import TablePagination from '~/@core/domain/ui/TablePagination';

interface AreaState {
  areas: Area[];
  areaPagination: TablePagination;
  handleAreaPagination: (newPagination: TablePagination) => void;
  addAreas: (areasValues: Area[]) => void;
}

const useAreaStore = create<AreaState>()(set => ({
  areas: [],
  areaPagination: {
    pageSize: 10,
    current: 1,
    total: 1
  },
  handleAreaPagination: newPagination => set({ areaPagination: newPagination }),
  addAreas: areasValues => set({ areas: areasValues })
}));

export default useAreaStore;

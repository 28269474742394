import produce from 'immer';
import { PriceAlert } from '~/domain/model';
import { createCreator, createReducer, Reducer } from '~/helpers/util/reducers';

export type RequestStatus = 'idle' | 'running' | 'success' | 'failure';

export type PriceAlertCreateState = {
  priceAlert: PriceAlert;
  requestStatus: RequestStatus;
  createStatus: RequestStatus;
  showPriceAlertCreate: boolean;
};

export type ErrorResponse = {
  requestStatus: RequestStatus;
};

type ShowPriceAlertState = boolean;

export type CreateInit = {
  id: number;
  categoryId: number;
  percentBelowAverage: number;
  percentAboveAverage: number;
};

export const initialState: PriceAlertCreateState = {
  priceAlert: new PriceAlert({}),
  requestStatus: 'idle',
  createStatus: 'idle',
  showPriceAlertCreate: false
};

const prefix = '@priceAlertCreate';

export const Types = Object.freeze({
  FETCH_INIT: `${prefix}/FETCH_INIT`,
  FETCH_SUCCESS: `${prefix}/FETCH_SUCCESS`,
  FETCH_FAILURE: `${prefix}/FETCH_FAILURE`,
  CLEAR: `${prefix}/CLEAR`,
  CREATE_INIT: `${prefix}/CREATE_INIT`,
  CREATE_SUCCESS: `${prefix}/CREATE_SUCCESS`,
  CREATE_FAILURE: `${prefix}/CREATE_FAILURE`,
  SHOW_PRICE_ALERT_CREATE: `${prefix}/SHOW_PRICE_ALERT_CREATE`
});

const fetchInitReducer: Reducer<PriceAlertCreateState, void> = state => {
  return produce(state, draft => {
    draft.requestStatus = 'running';
  });
};

const fetchSuccessReducer: Reducer<PriceAlertCreateState, void> = state => {
  return produce(state, draft => {
    draft.requestStatus = 'success';
  });
};

const fetchFailureReducer: Reducer<PriceAlertCreateState, void> = state => {
  return produce(state, draft => {
    draft.requestStatus = 'failure';
  });
};

const clearReducer: Reducer<PriceAlertCreateState, void> = state => {
  return produce(state, draft => {
    draft.priceAlert = new PriceAlert({});
    draft.requestStatus = 'idle';
    draft.createStatus = 'idle';
    draft.showPriceAlertCreate = false;
  });
};

const createInitReducer: Reducer<PriceAlertCreateState, void> = state => {
  return produce(state, draft => {
    draft.createStatus = 'running';
  });
};

const createSuccessReducer: Reducer<PriceAlertCreateState, void> = state => {
  return produce(state, draft => {
    draft.createStatus = 'success';
  });
};

const createFailureReducer: Reducer<PriceAlertCreateState, ErrorResponse> = (
  state,
  action
) => {
  return produce(state, draft => {
    draft.createStatus = action.requestStatus;
  });
};

const showPriceAlertCreateReducer: Reducer<
  PriceAlertCreateState,
  ShowPriceAlertState
> = (state, action) => {
  return produce(state, draft => {
    draft.showPriceAlertCreate = action;
  });
};

export const actions = Object.freeze({
  fetchInit: createCreator<void>(Types.FETCH_INIT),
  fetchSuccess: createCreator<void>(Types.FETCH_SUCCESS),
  fetchFailure: createCreator<void>(Types.FETCH_FAILURE),
  clear: createCreator<void>(Types.CLEAR),
  createInit: createCreator<CreateInit>(Types.CREATE_INIT),
  createSuccess: createCreator<void>(Types.CREATE_SUCCESS),
  createFailure: createCreator<ErrorResponse>(Types.CREATE_FAILURE),
  showPriceAlertCreate: createCreator<ShowPriceAlertState>(
    Types.SHOW_PRICE_ALERT_CREATE
  )
});

export const reducer = createReducer(initialState, {
  [Types.FETCH_INIT]: fetchInitReducer,
  [Types.FETCH_SUCCESS]: fetchSuccessReducer,
  [Types.FETCH_FAILURE]: fetchFailureReducer,
  [Types.CLEAR]: clearReducer,
  [Types.CREATE_INIT]: createInitReducer,
  [Types.CREATE_SUCCESS]: createSuccessReducer,
  [Types.CREATE_FAILURE]: createFailureReducer,
  [Types.SHOW_PRICE_ALERT_CREATE]: showPriceAlertCreateReducer
});

import { call, put } from 'redux-saga/effects';

import * as API from '~/domain/api';

import { actions } from './duck';
import * as BannerTypeService from '~/services/producer/BannerType';

export function* fetchAll() {
  const response: API.ServerResponse = yield call(BannerTypeService.fetchAll);
  const bannerTypes: API.BannerType[] = response.data;
  const bannerTypeByID: Map<number, API.BannerType> = new Map(
    bannerTypes.map(bannerType => [bannerType.id, bannerType])
  );
  yield put(actions.update({ byId: bannerTypeByID }));
  return response;
}

import { createSelector } from '~/helpers/util/selectors';
import { PriceAlertCreateState } from './duck';

export const getRoot = (state: any) => {
  return state.ui.priceAlertCreate as PriceAlertCreateState;
};

export const getCreateStatus = createSelector([getRoot], state => {
  return state.createStatus;
});

export const getRequestStatus = createSelector([getRoot], state => {
  return state.requestStatus;
});

export const getIsLoading = createSelector(
  [getCreateStatus, getRequestStatus],
  state => {
    return state === 'running';
  }
);

export const getShowPriceAlertCreate = createSelector([getRoot], state => {
  return state.showPriceAlertCreate;
});

import { ProductionProductGroupState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.core.productionProductGroups as ProductionProductGroupState;
};

export const getProductionProductGroupById = createSelector(
  [getRoot],
  state => {
    return state.productionProductGroupById;
  }
);

export const getProductionProductGroups = createSelector([getRoot], state =>
  Array.from(state.productionProductGroupById.values())
);

export const getIsCleared = createSelector(
  [getRoot],
  state => state.status === 'cleared'
);

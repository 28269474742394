import { UploadFile } from 'antd/lib/upload/interface';
import { AxiosResponse } from 'axios';
import { APIError } from '~/domain/api/errors';

import apiProducer from '../api';

export const create = async (
  description: string,
  bannerTypeId: number,
  bannerImage: UploadFile
) => {
  try {
    const formData = new FormData();
    formData.append('Description', description);
    formData.append('BannerTypeId', bannerTypeId.toString());
    formData.append('Banner', bannerImage.originFileObj!);

    const response: any = await apiProducer.post('/banner', formData);
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    } else if (error?.response?.status === 422) {
      throw new APIError(error?.response?.data[0]);
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const fetchMany = async (
  description?: string,
  bannerTypeId?: number,
  active?: boolean,
  pageNumber?: number,
  pageSize?: number
) => {
  try {
    const params = new URLSearchParams();
    if (description) {
      params.append('Data.Description', description);
    }
    if (bannerTypeId) {
      params.append('Data.BannerTypeId', String(bannerTypeId));
    }
    if (typeof active === 'boolean') {
      params.append('Data.IsActive', String(active));
    }
    if (pageNumber) {
      params.append('PageNumber', String(pageNumber));
    }
    if (pageSize) {
      params.append('PageSize', String(pageSize));
    }
    const response: AxiosResponse = await apiProducer.get('/banner', {
      params
    });
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const fetchBannerUrlById = async (bannerId: number) => {
  try {
    const response: AxiosResponse = await apiProducer.get(
      `/banner/image/${bannerId}`
    );
    return response.data;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const updateStatus = async (bannerId: number) => {
  try {
    const response: any = await apiProducer.put(`/banner/status/${bannerId}`);
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

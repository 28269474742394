import apiProducer from '../api';

import { APIError } from '~/domain/api/errors';

export const fetchAll = async () => {
  try {
    const response: any = await apiProducer.get('/banner/types');
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

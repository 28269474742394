import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

// #FIXME Remover any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = (reducers: any) => {
  const persistedReducer = persistReducer(
    {
      key: 'veiling',
      storage,
      whitelist: ['_ui/auth']
    },
    reducers
  );

  return persistedReducer;
};

export default reducer;

// eslint-disable-next-line no-shadow
export enum StockModalTabsEnum {
  Register = '1',
  Resume = '2'
}
// eslint-disable-next-line no-shadow
export enum QualityColorEnum {
  A1 = 'A1',
  A2 = 'A2'
}

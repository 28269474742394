import produce from 'immer';
import { PriceAlert } from '~/domain/model';
import { createCreator, createReducer, Reducer } from '~/helpers/util/reducers';

export type FetchStatus = 'idle' | 'fetching' | 'success' | 'failure';
export type UpdateStatus = 'idle' | 'running' | 'success' | 'failure';

export type PriceAlertListState = {
  fetchStatus: FetchStatus;
  pageNumber: number;
  pageSize: number;
  totalCount: number;
  fetchHistoryStatus: FetchStatus;
  pageNumberHistory: number;
  pageSizeHistory: number;
  totalCountHistory: number;
  showPriceAlertEditModal: boolean;
  showHistoryModal: boolean;
  priceAlertToEditOrHistory: PriceAlert;
  priceAlertUpdateStatus: UpdateStatus;
  historyStatus: FetchStatus;
};

export type FetchPage = {
  nextPage: number;
  nextPageSize: number;
};

export type FetchPageSuccess = {
  nextPage: number;
  nextPageSize: number;
  totalCount: number;
};

type ShowPriceAlertState = boolean;

type PriceAlertToEdit = {
  priceAlert: PriceAlert;
};

export type ErrorResponse = {
  requestStatus: UpdateStatus | FetchStatus;
};

export type UpdateInit = {
  id: number;
  categoryId: number;
  percentBelowAverage: number;
  percentAboveAverage: number;
};

export type HistoryInit = {
  id: number;
  nextPage: number;
  nextPageSize: number;
};

export type HistorySuccess = {
  nextPage: number;
  nextPageSize: number;
  totalCount: number;
};

const prefix = '@priceAlertList';

export const Types = Object.freeze({
  CLEAR: `${prefix}/CLEAR`,
  FETCH_PAGE: `${prefix}/FETCH_PAGE`,
  FETCH_PAGE_SUCCESS: `${prefix}/FETCH_PAGE_SUCCESS`,
  FETCH_PAGE_FAILURE: `${prefix}/FETCH_PAGE_FAILURE`,
  SHOW_PRICE_ALERT_EDIT_MODAL: `${prefix}/SHOW_PRICE_ALERT_EDIT_MODAL`,
  SHOW_HISTORY_MODAL: `${prefix}/SHOW_HISTORY_MODAL`,
  SET_PRICE_ALERT_TO_EDIT_OR_HISTORY: `${prefix}/SET_PRICE_ALERT_TO_EDIT_OR_HISTORY`,
  UPDATE_INIT: `${prefix}/UPDATE_INIT`,
  UPDATE_SUCCESS: `${prefix}/UPDATE_SUCCESS`,
  UPDATE_FAILURE: `${prefix}/UPDATE_FAILURE`,
  HISTORY_INIT: `${prefix}/HISTORY_INIT`,
  HISTORY_SUCCESS: `${prefix}/HISTORY_SUCCESS`,
  HISTORY_FAILURE: `${prefix}/HISTORY_FAILURE`
});

export const initialState: PriceAlertListState = {
  fetchStatus: 'idle',
  pageNumber: 1,
  pageSize: 10,
  totalCount: 0,
  fetchHistoryStatus: 'idle',
  pageNumberHistory: 1,
  pageSizeHistory: 10,
  totalCountHistory: 0,
  showPriceAlertEditModal: false,
  showHistoryModal: false,
  priceAlertToEditOrHistory: new PriceAlert({}),
  priceAlertUpdateStatus: 'idle',
  historyStatus: 'idle'
};

const clearReducer: Reducer<PriceAlertListState, void> = state => {
  return produce(state, draft => {
    draft.fetchStatus = 'idle';
    draft.pageNumber = 1;
    draft.pageSize = 10;
    draft.totalCount = 0;
    draft.fetchHistoryStatus = 'idle';
    draft.pageNumberHistory = 1;
    draft.pageSizeHistory = 10;
    draft.totalCountHistory = 0;
    draft.showPriceAlertEditModal = false;
    draft.showHistoryModal = false;
    draft.priceAlertToEditOrHistory = new PriceAlert({});
    draft.priceAlertUpdateStatus = 'idle';
    draft.historyStatus = 'idle';
  });
};

const fetchPageReducer: Reducer<PriceAlertListState, void> = state => {
  return produce(state, draft => {
    draft.fetchStatus = 'fetching';
  });
};

const fetchPageSuccessReducer: Reducer<
  PriceAlertListState,
  FetchPageSuccess
> = (state, action) => {
  return produce(state, draft => {
    const { nextPage, nextPageSize, totalCount } = action;
    draft.fetchStatus = 'success';
    draft.pageNumber = nextPage;
    draft.pageSize = nextPageSize;
    draft.totalCount = totalCount;
  });
};

const fetchPageFailureReducer: Reducer<PriceAlertListState, void> = state => {
  return produce(state, draft => {
    draft.fetchStatus = 'failure';
  });
};

const showPriceAlertEditModalReducer: Reducer<
  PriceAlertListState,
  ShowPriceAlertState
> = (state, stateModal) => {
  return produce(state, draft => {
    draft.showPriceAlertEditModal = stateModal;
  });
};

const showHistoryModalReducer: Reducer<
  PriceAlertListState,
  ShowPriceAlertState
> = (state, stateModal) => {
  return produce(state, draft => {
    draft.showHistoryModal = stateModal;
  });
};

const setPriceAlertToEditOrHistoryReducer: Reducer<
  PriceAlertListState,
  PriceAlertToEdit
> = (state, action) => {
  return produce(state, draft => {
    draft.priceAlertToEditOrHistory = action.priceAlert;
  });
};

const updateInitReducer: Reducer<PriceAlertListState, void> = state => {
  return produce(state, draft => {
    draft.priceAlertUpdateStatus = 'running';
  });
};

const updateSuccessReducer: Reducer<PriceAlertListState, void> = state => {
  return produce(state, draft => {
    draft.priceAlertUpdateStatus = 'success';
  });
};

const updateFailureReducer: Reducer<PriceAlertListState, ErrorResponse> = (
  state,
  action
) => {
  return produce(state, draft => {
    draft.priceAlertUpdateStatus = action.requestStatus as UpdateStatus;
  });
};

const historyInitReducer: Reducer<PriceAlertListState, void> = state => {
  return produce(state, draft => {
    draft.historyStatus = 'fetching';
  });
};

const historySuccessReducer: Reducer<PriceAlertListState, HistorySuccess> = (
  state,
  action
) => {
  return produce(state, draft => {
    const { nextPage, nextPageSize, totalCount } = action;
    draft.historyStatus = 'success';
    draft.pageNumberHistory = nextPage;
    draft.pageSizeHistory = nextPageSize;
    draft.totalCountHistory = totalCount;
  });
};

const historyFailureReducer: Reducer<PriceAlertListState, ErrorResponse> = (
  state,
  action
) => {
  return produce(state, draft => {
    draft.historyStatus = action.requestStatus as FetchStatus;
  });
};

export const actions = Object.freeze({
  clear: createCreator<void>(Types.CLEAR),
  fetchPage: createCreator<FetchPage>(Types.FETCH_PAGE),
  fetchPageSuccess: createCreator<FetchPageSuccess>(Types.FETCH_PAGE_SUCCESS),
  fetchPageFailure: createCreator<void>(Types.FETCH_PAGE_FAILURE),
  showPriceAlertEditModal: createCreator<ShowPriceAlertState>(
    Types.SHOW_PRICE_ALERT_EDIT_MODAL
  ),
  showHistoryModal: createCreator<ShowPriceAlertState>(
    Types.SHOW_HISTORY_MODAL
  ),
  setPriceAlertToEditOrHistory: createCreator<PriceAlertToEdit>(
    Types.SET_PRICE_ALERT_TO_EDIT_OR_HISTORY
  ),
  updateInit: createCreator<UpdateInit>(Types.UPDATE_INIT),
  updateSuccess: createCreator<void>(Types.UPDATE_SUCCESS),
  updateFailure: createCreator<ErrorResponse>(Types.UPDATE_FAILURE),
  historyInit: createCreator<HistoryInit>(Types.HISTORY_INIT),
  historySuccess: createCreator<HistorySuccess>(Types.HISTORY_SUCCESS),
  historyFailure: createCreator<ErrorResponse>(Types.HISTORY_FAILURE)
});

export const reducer = createReducer(initialState, {
  [Types.CLEAR]: clearReducer,
  [Types.FETCH_PAGE]: fetchPageReducer,
  [Types.FETCH_PAGE_SUCCESS]: fetchPageSuccessReducer,
  [Types.FETCH_PAGE_FAILURE]: fetchPageFailureReducer,
  [Types.SHOW_PRICE_ALERT_EDIT_MODAL]: showPriceAlertEditModalReducer,
  [Types.SHOW_HISTORY_MODAL]: showHistoryModalReducer,
  [Types.SET_PRICE_ALERT_TO_EDIT_OR_HISTORY]:
    setPriceAlertToEditOrHistoryReducer,
  [Types.UPDATE_INIT]: updateInitReducer,
  [Types.UPDATE_SUCCESS]: updateSuccessReducer,
  [Types.UPDATE_FAILURE]: updateFailureReducer,
  [Types.HISTORY_INIT]: historyInitReducer,
  [Types.HISTORY_SUCCESS]: historySuccessReducer,
  [Types.HISTORY_FAILURE]: historyFailureReducer
});

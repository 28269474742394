import * as API from '~/domain/api';

export default class VoucherHistory {
  private json: API.VoucherHistory;
  public readonly id: number;
  public readonly voucherId: number;
  public readonly voucherDescription: string;
  public readonly modificationBy: string;
  public readonly eventDate: string;
  public readonly isActive: boolean;

  constructor(json: API.VoucherHistory) {
    this.json = json;
    this.id = this.getId();
    this.voucherId = this.getVoucherId();
    this.voucherDescription = this.getVoucherDescription();
    this.modificationBy = this.getModificationBy();
    this.eventDate = this.getEventDate();
    this.isActive = this.isActiveValue();
  }

  private getId(): number {
    return this.json.id ?? Number.NaN;
  }

  private getVoucherId(): number {
    return this.json.voucherId ?? Number.NaN;
  }

  private getVoucherDescription(): string {
    return this.json.voucherDescription ?? '';
  }

  private getModificationBy(): string {
    return this.json.modificationBy ?? '';
  }

  private getEventDate(): string {
    return this.json.eventDate ?? '';
  }

  public getStatus(): string {
    return this.isActive ? 'ATIVO' : 'INATIVO';
  }

  public isActiveValue(): boolean {
    return this.json.isActive ?? false;
  }
}

import { createSelector } from 'reselect';
import { UnpublishedState } from '.';

// #FIXME Remover any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRoot = (state: any) => {
  return state.unpublished as UnpublishedState;
};

export const getUnpublishedPagination = createSelector(
  [getRoot],
  state => state.unpublishedPagination
);

export const getUnpublished = createSelector(
  [getRoot],
  state => state.unpublished
);

export const getSelectListIds = createSelector(
  [getRoot],
  state => state.selectListIds
);

export const getSelectList = createSelector(
  [getRoot],
  state => state.selectList
);

export const getCurrentStep = createSelector(
  [getRoot],
  state => state.currentStep
);

export const getShowEditUnpublishedModal = createSelector(
  [getRoot],
  state => state.showEditUnpublishedModal
);

export const getUnpublishedEdit = createSelector(
  [getRoot],
  state => state.unpublishedEdit
);

export const getUnpublishedItems = createSelector(
  [getRoot],
  state => state.unpublishedItems
);

export const getPagination = createSelector([getRoot], state => {
  return state.pagination;
});

export const getUnpublishedSinglePublished = createSelector(
  [getRoot],
  state => state.unpublishedSinglePublished
);

export const getReloadPage = createSelector(
  [getRoot],
  state => state.reloadPage
);

export const getUpublishedUpdateList = createSelector(
  [getRoot],
  state => state.unpublishedUpadeList
);

export const getDeliveryDate = createSelector(
  [getRoot],
  state => state.deliveryDate
);

export const getCommercialDate = createSelector(
  [getRoot],
  state => state.commercialDate
);

export const getDeliveryDateHold = createSelector(
  [getRoot],
  state => state.deliveryDateHold
);

export const getCommercialDateHold = createSelector(
  [getRoot],
  state => state.commercialDateHold
);

export const getCustomerId = createSelector(
  [getRoot],
  state => state.customerId
);

export const getOpNatureIdSelected = createSelector(
  [getRoot],
  state => state.opNatureIdSelected
);

export const getTotalPriceOffers = createSelector(
  [getRoot],
  state => state.totalPriceOffers
);

import { BannerApprovalListState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.ui.bannerApprovalList as BannerApprovalListState;
};

export const getIsLoading = createSelector(
  [getRoot],
  state =>
    state.fetchInitStatus === 'pending' ||
    state.fetchBannerApprovalStatus === 'pending' ||
    state.approveOrDisapproveBannerStatus === 'pending'
);

export const getFetchStatus = createSelector([getRoot], state => {
  return state.fetchInitStatus;
});

export const getApproveOrDisapproveBannerStatus = createSelector(
  [getRoot],
  state => {
    return state.approveOrDisapproveBannerStatus;
  }
);

export const getFetchBannerApprovalStatus = createSelector([getRoot], state => {
  return state.fetchBannerApprovalStatus;
});

export const getPageNumber = createSelector([getRoot], state => {
  return state.pageNumber;
});

export const getPageSize = createSelector([getRoot], state => state.pageSize);

export const getTotalCount = createSelector(
  [getRoot],
  state => state.totalCount
);

export const getErrorMessage = createSelector([getRoot], state => {
  return state.errorMessage;
});

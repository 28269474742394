import { VoucherListState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.ui.voucherList as VoucherListState;
};

export const getIsLoading = createSelector(
  [getRoot],
  state =>
    state.requestStatus === 'running' ||
    state.activeOrInactiveStatus === 'running'
);

export const getIsLoadingHistory = createSelector(
  [getRoot],
  state => state.getVoucherHistoryStatus === 'running'
);

export const getIsFetchStatusIdle = createSelector(
  [getRoot],
  state => state.requestStatus === 'idle'
);

export const getPageNumber = createSelector([getRoot], state => {
  return state.pageNumber;
});

export const getPageSize = createSelector([getRoot], state => state.pageSize);

export const getTotalCount = createSelector(
  [getRoot],
  state => state.totalCount
);

export const getActiveOrInactiveStatus = createSelector([getRoot], state => {
  return state.activeOrInactiveStatus;
});

export const getErrorMessage = createSelector([getRoot], state => {
  return state.errorMessage;
});

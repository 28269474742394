import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ResponseApi } from '~/domain/model';
import FindShippingCompaniesParams from '~/domain/model/FindShippingCompaniesParams';
import * as shippingCompanyService from '~/services/producer/ShippingCompany';
import ShippingCompany from '~/domain/model/ShippingCompany/ShippingCompany';
import { alertRequest } from '../../alert/actions';
import { FavoriteShippingCompany } from '~/domain/model/ShippingCompany/FavoriteShippingCompany';
import { CreateShippingCompanyParams } from '~/domain/model/ShippingCompany/CreateShippingCompanyParams';

export const fetchShippingCompanies = createAsyncThunk(
  `shippingCompanies/fetchShippingCompanies`,
  async (params: FindShippingCompaniesParams) => {
    const shippingCompaniesData: ResponseApi<ShippingCompany[]> =
      await shippingCompanyService.findMany(params);
    return shippingCompaniesData;
  }
);

export const fetchShippingCompaniesHistory = createAsyncThunk(
  `shippingCompanies/fetchShippingCompaniesHistory`,
  async (shippingCompanyId: number) => {
    const data = await shippingCompanyService.findHistory(shippingCompanyId);
    return data;
  }
);

export const favoriteShippingCompany = createAsyncThunk(
  `shippingCompanies/favoriteShippingCompany`,
  async (params: FavoriteShippingCompany, { dispatch, rejectWithValue }) => {
    try {
      const value = await shippingCompanyService.favoriteShippingCompanyRequest(
        params
      );
      dispatch(alertRequest('Transportadora editada com sucesso!', 'success'));
      return { value, id: params.shippingCompanyId };
    } catch (err: unknown) {
      const error = err as AxiosError;
      dispatch(
        alertRequest(
          JSON.stringify(error.response?.data.errors.messages),
          'error'
        )
      );
      return rejectWithValue([]);
    }
  }
);

export const deleteShippingCompany = createAsyncThunk(
  `shippingCompanies/deleteShippingCompany`,
  async (shippingCompanyId: number, { dispatch, rejectWithValue }) => {
    try {
      await shippingCompanyService.deleteShippingCompany(shippingCompanyId);
      dispatch(alertRequest('Transportadora removida com sucesso!', 'success'));
      return shippingCompanyId;
    } catch (err: unknown) {
      const error = err as AxiosError;
      dispatch(
        alertRequest(
          JSON.stringify(error.response?.data.errors.messages),
          'error'
        )
      );
      return rejectWithValue([]);
    }
  }
);

export const createShippingCompany = createAsyncThunk(
  `shippingCompanies/createShippingCompany`,
  async (
    createShippingCompanyParams: CreateShippingCompanyParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await shippingCompanyService.createShippingCompany(
        createShippingCompanyParams
      );
      dispatch(alertRequest('Transportadora criada com sucesso!', 'success'));
      return response as ShippingCompany;
    } catch (err: unknown) {
      const error = err as AxiosError;
      dispatch(
        alertRequest(
          JSON.stringify(error.response?.data.errors.messages),
          'error'
        )
      );
      return rejectWithValue([]);
    }
  }
);

export const editShippingCompany = createAsyncThunk(
  `shippingCompanies/editShippingCompany`,
  async (
    editShippingCompanyParams: CreateShippingCompanyParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await shippingCompanyService.editShippingCompany(
        editShippingCompanyParams
      );
      dispatch(alertRequest('Transportadora editada com sucesso!', 'success'));
      return response as ShippingCompany;
    } catch (err: unknown) {
      const error = err as AxiosError;
      dispatch(
        alertRequest(
          JSON.stringify(error.response?.data.errors.messages),
          'error'
        )
      );
      return rejectWithValue([]);
    }
  }
);

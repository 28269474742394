import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '~/services/api';

import {
  attachmentFullListSuccess,
  attachmentFullListFailure
} from './actions';

export function* onList({ payload }: any): any {
  try {
    const { id } = payload;

    const response = yield call(
      api.get,
      `/production-property/${id}/attachments`
    );

    const { data } = response;

    yield put(attachmentFullListSuccess(data));
  } catch (err) {
    yield put(attachmentFullListFailure());
  }
}

export default all([takeLatest('@attachment/FULL_LIST_REQUEST', onList)]);

import * as API from '~/domain/api';

export default class TransactionStatus {
  public json: API.TransactionStatus;
  public code: string;
  public description: string;

  constructor(json: API.TransactionStatus) {
    this.json = json;
    this.code = this.json.code;
    this.description = this.json.description;
  }
}

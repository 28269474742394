import { UploadFile } from 'antd/lib/upload/interface';
import produce from 'immer';
import { ApplicationError } from '~/domain/api/errors';
import { createCreator, createReducer, Reducer } from '~/helpers/util/reducers';

export type CreateStatus = 'idle' | 'pending' | 'success' | 'failure';
export type FetchBannerUrlStatus = 'idle' | 'pending' | 'success' | 'failure';

export type BannerCreateState = {
  createStatus: CreateStatus;
  fetchBannerUrlStatus: FetchBannerUrlStatus;
  errorMessage?: string;
  viewBannerId?: number;
  bannerImage: string;
};

export type FetchFailure = {
  error?: ApplicationError;
};

export type Create = {
  description: string;
  bannerTypeId: number;
  bannerImage: UploadFile<any>;
};

export type FetchBannerUrl = {
  bannerId: number;
};

export type FetchBannerUrlSuccess = {
  bannerImage: string;
};

export type Error = {
  errorMessage?: string;
};

const prefix = '@bannerCreate';

export const Types = Object.freeze({
  CLEAR: `${prefix}/CLEAR`,
  CREATE_INIT: `${prefix}/CREATE_INIT`,
  CREATE_SUCCESS: `${prefix}/CREATE_SUCCESS`,
  CREATE_FAILURE: `${prefix}/CREATE_FAILURE`,
  FETCH_BANNER_URL: `${prefix}/FETCH_BANNER_URL`,
  FETCH_BANNER_URL_SUCCESS: `${prefix}/FETCH_BANNER_URL_SUCCESS`,
  FETCH_BANNER_URL_FAILURE: `${prefix}/FETCH_BANNER_URL_FAILURE`
});

export const initialState: BannerCreateState = {
  createStatus: 'idle',
  fetchBannerUrlStatus: 'idle',
  viewBannerId: undefined,
  bannerImage: ''
};

const clearReducer: Reducer<BannerCreateState, void> = (state, _action) => {
  return produce(state, draft => {
    draft.fetchBannerUrlStatus = initialState.fetchBannerUrlStatus;
    draft.createStatus = initialState.createStatus;
    draft.viewBannerId = initialState.viewBannerId;
    draft.bannerImage = initialState.bannerImage;
  });
};

const createInitReducer: Reducer<BannerCreateState, Create> = state => {
  return produce(state, draft => {
    draft.createStatus = 'pending';
  });
};

const createSuccessReducer: Reducer<BannerCreateState, void> = state => {
  return produce(state, draft => {
    draft.createStatus = 'success';
  });
};

const createFailureReducer: Reducer<BannerCreateState, Error> = (
  state,
  action
) => {
  return produce(state, draft => {
    draft.createStatus = 'failure';
    draft.errorMessage = action.errorMessage;
  });
};

const fetchBannerUrlReducer: Reducer<BannerCreateState, FetchBannerUrl> = (
  state,
  action
) => {
  return produce(state, draft => {
    draft.fetchBannerUrlStatus = 'pending';
    const { bannerId } = action;
    draft.viewBannerId = bannerId;
  });
};

const fetchBannerUrlSuccessReducer: Reducer<
  BannerCreateState,
  FetchBannerUrlSuccess
> = (state, action) => {
  return produce(state, draft => {
    const { bannerImage } = action;
    draft.fetchBannerUrlStatus = 'success';
    draft.bannerImage = bannerImage;
  });
};

const fetchBannerUrlFailureReducer: Reducer<
  BannerCreateState,
  void
> = state => {
  return produce(state, draft => {
    draft.fetchBannerUrlStatus = 'failure';
  });
};

export const actions = Object.freeze({
  clear: createCreator<void>(Types.CLEAR),
  createInit: createCreator<Create>(Types.CREATE_INIT),
  createSuccess: createCreator<void>(Types.CREATE_SUCCESS),
  createFailure: createCreator<Error>(Types.CREATE_FAILURE),
  fetchBannerUrl: createCreator<FetchBannerUrl>(Types.FETCH_BANNER_URL),
  fetchBannerUrlSuccess: createCreator<FetchBannerUrlSuccess>(
    Types.FETCH_BANNER_URL_SUCCESS
  ),
  fetchBannerUrlFailure: createCreator<void>(Types.FETCH_BANNER_URL_FAILURE)
});

export const reducer = createReducer(initialState, {
  [Types.CLEAR]: clearReducer,
  [Types.CREATE_INIT]: createInitReducer,
  [Types.CREATE_SUCCESS]: createSuccessReducer,
  [Types.CREATE_FAILURE]: createFailureReducer,
  [Types.FETCH_BANNER_URL]: fetchBannerUrlReducer,
  [Types.FETCH_BANNER_URL_SUCCESS]: fetchBannerUrlSuccessReducer,
  [Types.FETCH_BANNER_URL_FAILURE]: fetchBannerUrlFailureReducer
});

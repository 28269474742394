export const endpoints = {
  buyIntention: '/buy-intention',
  log: '/log-user',
  plp: {
    plp: '/plp',
    export: '/plp/download',
    historic: '/plp/history',
    mass: '/plp/mass-update'
  },
  stock: {
    stocks: '/stocks',
    stocksDirected: '/stocks/directed',
    stockRegister: '/stock-register',
    offerChannels: '/stocks/offer-channels/',
    mass: '/stocks/mass-update',
    replicate: '/stocks/replication',
    convert: '/stocks/convert',
    historic: '/stock-history/'
  },
  expedition: {
    delete: '/expedition/delete/',
    expedition: '/expedition',
    history: '/expedition/history/'
  },
  expeditionLocales: '/expedition-locale',
  harvestingOrder: {
    base: '/orderToCollect'
  },
  keyLiberation: {
    base: '/key-liberation',
    history: '/key-liberation/history',
    status: '/key-liberation/status',
    statusAll: '/key-liberation/status/all'
  },
  product: {
    recipients: '/product/recipients',
    recipientsDetailed: '/product/recipients/detailed',
    categories: '/product-category/',
    group: '/product-group/site/'
  },
  productionPlanning: {
    base: '/production-planning',
    export: '/production-planning/export',
    history: '/production-planning/history',
    import: '/production-planning/import',
    importingLog: '/production-planning/importing-log',
    importingLogDetails: '/production-planning/importing-log-details',
    importingHistory: '/production-planning/importing-history',
    replicateWeek: '/production-planning/replicate-week',
    replicateYear: '/production-planning/replicate-year',
    replicateBudget: '/production-planning/replicate-budget',
    replicate: '/production-planning/replicate-production-planning',
    replicateHistory:
      '/production-planning/replicate-history-production-planning'
  },
  productionProductGroup: '/production-product-group',
  shippingCompany: {
    shippingCompany: '/shipping-company',
    delete: '/shipping-company/delete/',
    history: '/shipping-company/history/',
    favorite: '/shipping-company/favorite'
  },
  bidashboard: {
    bidashboard: '/bi-dashboard',
    status: '/bi-dashboard/status',
    delete: '/bi-dashboard/delete/'
  },
  specialDates: {
    weekNumber: '/special-date/weekNumber/'
  },
  productPreference: {
    base: '/product-preference/',
    composition: '/product-preference/packing-composition/site/',
    history: '/product-preference/history/',
    color: '/product/colors'
  },
  siteDeliveryPattern: {
    base: '/site-delivery-pattern/'
  },
  technicalSpecification: {
    base: '/technical-specification'
  },
  purchaseManagement: {
    base: '/purchase-management/',
    download: '/purchase-management/download?',
    cartStatus: '/purchase-management/cart-status',
    transactionStatus: '/purchase-management/transaction-status',
    history: '/purchase-management/history/',
    products: '/product/products'
  },
  orderManagement: {
    base: '/order-management?',
    buySuggestion: '/order-management/buy-suggestion?',
    exportExcel: '/order-management/export?',
    occurrence: '/order-management/occurrence',
    occurrenceMessage: '/order-management/occurrence-message',
    pending: '/order-management/pending',
    publishers: '/order-management/publishers',
    republish: '/order-management/republish',
    republishCancelled: '/order-management/republish-cancelled',
    massRepublish: '/order-management/republish-mass'
  },
  orders: {
    base: '/order/',
    cancel: '/direct-sale-draft/cancel/',
    directSale: '/order/direct-sale',
    draft: '/order/draft',
    opNature: '/order/op-nature',
    opNatureForCustomer: '/order/op-nature-vd',
    publisher: '/order/publishers',
    qualityObservation: '/order/quality-observation',
    removeLocations: '/order/remove-locations'
  },
  customers: {
    base: '/customers',
    baseForVD: '/customers/customers-vd'
  },
  salesReportEcommerce: {
    grid: '/sales-report-ecommerce?',
    overview: '/sales-report-ecommerce/overview?',
    export: 'sales-report-ecommerce/export?'
  },
  selfService: {
    import: '/self-service/import',
    importRevalidate: '/self-service/revalidate',
    process: '/self-service/process',
    flagsDefinition: 'self-service/definition/flags/',
    updateFlagsDefinition: 'self-service/definition/conditions-packaging',
    witheListsearch: '/self-service/rating/whitelist',
    blackListSearch: '/self-service/rating/blacklist/',
    postClassification: '/self-service/rating',
    updatePriority: '/self-service/rating/blacklist/',
    updateRating: '/self-service/rating/',
    deleteProducer: '/self-service/rating/remove/',
    requestSearchList: '/self-service/worksheet?',
    postRequest: '/self-service/worksheet',
    cancelDetais: '/self-service/worksheet/cancel/',
    searchDetailsRequest: '/self-service/worksheet/',
    searchDetailsLine: '/self-service/worksheet/lines/',
    cancelDetailsLine: '/self-service/worksheetLine/cancel/',
    finishOrder: '/self-service/worksheet/purchase-order/',
    searchOffers: '/self-service/worksheet/offers/',
    cancelOffers: '/self-service/worksheetOffer/cancel/',
    addDetailsOffers: '/self-service/worksheet/line-offer',
    searchProducts: '/api/Offer?page=1&totalPage=3000&orderBy=AZ',
    filterProducts: '/api/offer/filters?customerId=',
    addProduct: '/self-service/worksheet/new-product',
    searchHistory: '/self-service/history/',
    delete: '/self-service/remove-line/'
  },
  unpublished: {
    base: '/direct-sale-draft',
    publish: 'order/draft/mass-publish',
    delete: '/direct-sale-draft/delete'
  },
  property: {
    base: '/production-property?'
  },
  tagPrinting: {
    base: '/tag-print'
  }
};

import { AnnouncementListState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.ui.announcementList as AnnouncementListState;
};

export const getIsLoading = createSelector(
  [getRoot],
  state =>
    state.requestAnnouncementListStatus === 'pending' ||
    state.activeOrInactiveStatus === 'pending'
);

export const getPageNumber = createSelector(
  [getRoot],
  state => state.pageNumber
);

export const getActiveOrInactiveStatus = createSelector(
  [getRoot],
  state => state.activeOrInactiveStatus
);

export const getPageSize = createSelector([getRoot], state => state.pageSize);

export const getTotalCount = createSelector(
  [getRoot],
  state => state.totalCount
);

export const getErrorMessage = createSelector(
  [getRoot],
  state => state.errorMessage
);

import { QuestionCircleOutlined } from '@ant-design/icons';
import { ButtonProps } from 'antd';
import IconButton from './IconButton';

type Props = ButtonProps;

export default function HelpButton(props: Props) {
  return (
    <IconButton {...props} tooltip="Ajuda" icon={<QuestionCircleOutlined />} />
  );
}

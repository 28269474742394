import { call, put } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import * as API from '~/domain/api';

import {
  actions,
  EditParams,
  FetchGfps,
  FetchHistory,
  FetchLkpOffers,
  StatusOffer
} from './duck';
import * as LkpOfferService from '~/services/producer/LkpOffer';
import { LkpOffer } from '~/domain/model';
import { LkpOfferParams } from '~/domain/api';
import {
  FetchProductPackingSummary,
  FetchWorkSheetLkpOffers
} from '../../_ui/lkpOfferList/duck';
import { actions as actionsList } from '../../_ui/lkpOfferList';
import { alertRequest } from '../../alert/actions';

export function* fetchMany(params: FetchLkpOffers) {
  yield put(actions.update({ byId: [] }));
  const {
    siteId,
    startDate,
    endDateStock,
    productId,
    categoryId,
    expeditionId,
    groupId,
    productQualityId,
    pageNumber,
    productRecipient,
    pageSize,
    status,
    ColorId,
    keyword
  } = params;

  const response: API.ServerResponse = yield call(
    LkpOfferService.fetchMany,
    siteId,
    startDate,
    endDateStock,
    productId,
    categoryId,
    expeditionId,
    groupId,
    productQualityId,
    productRecipient,
    pageNumber,
    pageSize,
    status,
    ColorId,
    keyword
  );
  const data: API.DefaultResponse = response.data;
  const lkpOffers: API.LkpOffer[] = data.results ?? [];
  let lkpOfferByID: LkpOffer[] = [];
  if (lkpOffers) {
    lkpOfferByID = lkpOffers.map(
      (lkpOffer: API.LkpOffer) => new LkpOffer(lkpOffer)
    );
    lkpOfferByID.forEach((offer, index) => {
      offer.key = index;
    });
  }
  yield put(
    actionsList.setTotal({
      totalCount: data.totalCount ?? Number.NaN
    })
  );

  const keywordsArray = keyword?.split(' ');
  const isLetter = /^[a-zA-Z]/.test(keyword ?? '');
  let newTableData: LkpOffer[] = lkpOfferByID;

  keywordsArray?.forEach((word: string) => {
    newTableData = newTableData.filter((item: any) =>
      item.productDescription.toLowerCase().includes(word.toLowerCase())
    );
  });

  yield put(actions.update({ byId: isLetter ? newTableData : lkpOfferByID }));
  return response;
}

export function* fetchHistory(params: FetchHistory) {
  const { lkpOfferId } = params;
  const response: API.ServerResponse = yield call(
    LkpOfferService.fetchHistory,
    lkpOfferId
  );
  const json: API.LkpOfferHistory[] = response.data;
  return json;
}

export function* fetchProductPackingSummary(
  params: FetchProductPackingSummary
) {
  const { siteId, productId } = params;
  const response: API.ServerResponse = yield call(
    LkpOfferService.fetchProductPackingSummary,
    siteId,
    productId
  );
  const json: API.ProductsPackingSummary[] = response.data;
  return json;
}

export function* fetchWorkSheet(params: FetchWorkSheetLkpOffers) {
  const {
    siteId,
    weekId,
    productId,
    categoryId,
    groupId,
    productQualityId,
    pageNumber,
    pageSize
  } = params;
  const response: API.ServerResponse = yield call(
    LkpOfferService.fetchWorksheet,
    siteId,
    weekId,
    productId,
    categoryId,
    groupId,
    productQualityId,
    pageNumber,
    pageSize
  );
  const json: API.LkpOfferHistory[] = response.data;
  const binaryString = window.atob(response?.data?.data);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  const fileData = bytes;

  const blob = new Blob([fileData], { type: 'application/octet-stream' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.style.display = 'none';
  const fileName = 'ofertas-lkp.xlsx';
  link.download = fileName;
  link.click();

  return json;
}

export function* fetchGfps(params: FetchGfps) {
  const {
    siteId,
    weekId,
    productId,
    productQualityId,
    auctionDate,
    outtingDate,
    pageSize,
    pageNumber
  } = params;
  const response: API.ServerResponse = yield call(
    LkpOfferService.fetchGfps,
    siteId,
    weekId,
    productId,
    productQualityId,
    auctionDate,
    outtingDate,
    pageSize,
    pageNumber
  );
  const json: API.ResponseLkpOfferGfps = response.data;
  return json;
}

export function* changeStatus(data: StatusOffer) {
  yield call(LkpOfferService.changeStatus, data);
  yield put(actions.changeStatus(data));
  yield put(actions.updateWithStatus(data));
}

export function* edit(params: EditParams[]) {
  const lkpOfferParams: LkpOfferParams[] = [];
  params.forEach(lkpOfferParam => {
    const {
      lkpOfferId,
      productId,
      initialDate,
      finalDate,
      lkpPercentage,
      unitPrice,
      remarks,
      isActive
    } = lkpOfferParam;
    lkpOfferParams.push({
      lkpOfferId,
      productId,
      lkpPercentage,
      initialDate,
      finalDate,
      unitPrice,
      remarks,
      isActive
    });
  });

  const response: AxiosResponse = yield call(
    LkpOfferService.update,
    lkpOfferParams
  );
  const data = response.data as API.LkpOffer[];
  let adjustMessage = '';

  const lkpOfferByID = data.map((lkpOffer: API.LkpOffer) => {
    if (lkpOffer.messageLkpPercentageWasAdjusted.length > 0) {
      adjustMessage += `${lkpOffer.messageLkpPercentageWasAdjusted}<br/>`;
    }
    return new LkpOffer(lkpOffer);
  });
  if (adjustMessage.length > 0) {
    yield put(alertRequest(adjustMessage, 'success'));
  } else {
    yield put(alertRequest('Atualizado com sucesso', 'success'));
  }

  yield put(actions.updateById(lkpOfferByID));
}

import { BannerCreateState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.ui.bannerCreate as BannerCreateState;
};

export const getCreateStatus = createSelector([getRoot], state => {
  return state.createStatus;
});

export const getFetchBannerUrlStatus = createSelector([getRoot], state => {
  return state.fetchBannerUrlStatus;
});

export const getErrorMessage = createSelector([getRoot], state => {
  return state.errorMessage;
});

export const getViewBannerId = createSelector([getRoot], state => {
  return state.viewBannerId;
});

export const getBannerImage = createSelector([getRoot], state => {
  return state.bannerImage;
});

export const getIsLoading = createSelector(
  [getRoot],
  state =>
    state.createStatus === 'pending' || state.fetchBannerUrlStatus === 'pending'
);

import * as API from '~/domain/api';

export default class Site {
  public readonly json: API.Site;
  public readonly id: number;
  public readonly producerId: number;
  public readonly account?: string;
  public readonly corporateName?: string;
  public readonly tradingName?: string;

  constructor(json: API.Site) {
    this.json = json;
    this.id = this.getId();
    this.producerId = this.getProducerId();
    this.account = this.getAccount();
    this.corporateName = this.getCorporateName();
    this.tradingName = this.getTradingName();
  }

  private getId(): number {
    return this.json.id ?? Number.NaN;
  }

  private getProducerId(): number {
    return this.json.producerId ?? Number.NaN;
  }

  private getAccount(): string {
    return this.json.account ?? '';
  }

  private getCorporateName(): string {
    return this.json.corporateName ?? '';
  }

  private getTradingName(): string {
    return this.json.tradingName ?? '';
  }

  public getFormatedSite(): string {
    return `${this.account} - ${this.corporateName} (${this.tradingName})`;
  }
}

import { Form } from 'antd';
import MovementWrapper from '~/components/organisms/RfidReports/MovementWrapper';

export default function Movement() {
  const [filterForm] = Form.useForm();

  return (
    <MovementWrapper filterForm={filterForm} filterFormData={filterForm} />
  );
}

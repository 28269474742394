import { createSelector } from 'reselect';

import { StocksState } from '.';

// #FIXME
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRoot = (state: any) => {
  return state.stocks as StocksState;
};
export const getStocks = createSelector([getRoot], state => state.stocks);
export const getStocksPagination = createSelector(
  [getRoot],
  state => state.stocksPagination
);
export const getTotalStocks = createSelector(
  [getRoot],
  state => state.totalstocks
);
export const getProductsRegister = createSelector(
  [getRoot],
  state => state.productsRegister
);
export const getStockOfferChannel = createSelector(
  [getRoot],
  state => state.stockOfferChannel
);
export const getStockHistoric = createSelector(
  [getRoot],
  state => state.stockHistory
);
export const getStockLoading = createSelector(
  [getRoot],
  state => state.loading
);
export const getStockUpdateErrors = createSelector(
  [getRoot],
  state => state.stockUpdateErrors
);
export const getCloseAddStockModal = createSelector(
  [getRoot],
  state => state.closeAddStockModal
);
export const getSubmitFormFilter = createSelector(
  [getRoot],
  state => state.submitFormFilter
);
export const getSubmitFormFilterForEditTable = createSelector(
  [getRoot],
  state => state.submitFormFilterForEditTable
);
export const getShowDistributeSalesChannelsModal = createSelector(
  [getRoot],
  state => state.showDistributeSalesChannelsModal
);
export const getSelectedStockId = createSelector(
  [getRoot],
  state => state.selectedStockId
);
export const getPlannedGroups = createSelector(
  [getRoot],
  state => state.plannedGroups
);
export const getAllowsKlokHoard = createSelector(
  [getRoot],
  state => state.siteAllowsKlokHoard
);
export const getAllowsVolHoard = createSelector(
  [getRoot],
  state => state.siteAllowsVolHoard
);
export const getWeekCreatedDate = createSelector(
  [getRoot],
  state => state.weekCreatedDate
);
export const getSiteIdAux = createSelector([getRoot], state => state.siteIdAux);
export const getFirstWeekDay = createSelector(
  [getRoot],
  state => state.FirstWeekDay
);
export const getLastWeekDay = createSelector(
  [getRoot],
  state => state.LastWeekDay
);
export const getKeyword = createSelector([getRoot], state => state.keyword);
export const getSearchByKeyword = createSelector(
  [getRoot],
  state => state.searchByKeyword
);

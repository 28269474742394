// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '~/services/api';

import { searchStockSuccesss, searchStockFailure } from './actions';

export function* onCreate(): any {
  try {
    yield call(api.post, '/stock');
    // yield put(addStockSuccess());
  } catch (err) {
    // yield put(addStockFailure());
  }
}

export function* onSearch({ payload }: any): any {
  try {
    yield call(api.get, '/stock', payload.data);
    yield put(searchStockSuccesss());
  } catch (err) {
    yield put(searchStockFailure());
  }
}

export function* onUpdate() {
  //
}

export default all([takeLatest('@productionPlan/SEARCH_STOCK', onSearch)]);

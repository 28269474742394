import { Col, Form, FormInstance, Select } from 'antd';
import { AxiosError } from 'axios';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GetProductsCategories from '~/@core/application/useCases/inputs/product/getProductsCategories';
import GetProductsDetailed from '~/@core/application/useCases/inputs/product/getProductsDetailed';
import GetProductsGroups from '~/@core/application/useCases/inputs/product/getProductsGroups';
import GetProductsPreferenceColor from '~/@core/application/useCases/inputs/product/getProductsPreferenceColor';
import GetRecipientsDetailed from '~/@core/application/useCases/inputs/product/getRecipientsDetailed';
import ProductCategory from '~/@core/domain/model/product/ProductCategory';
import ProductDetailed from '~/@core/domain/model/product/ProductDetailed';
import ProductGroup from '~/@core/domain/model/product/ProductGroup';
import ProductPreferenceColor from '~/@core/domain/model/product/ProductPreferenceColor';
import RecipientDetailed from '~/@core/domain/model/product/RecipientDetailed';
import { globalContext } from '~/context/GlobalContext';
import { OrderTabEnum } from '~/domain/enum/orders.enum';
import { convertInt } from '~/helpers/util';

interface Props {
  form: FormInstance<any>;
  siteId: number;
  lgCategory?: number;
  lgGroup?: number;
  lgProduct?: number;
  lgRecipient?: number;
  lgColor?: number;
  productLabel?: string;
  selsectTab?: OrderTabEnum;
  selectMultipleProducts?: boolean;
  hideColor?: boolean;
}
export default function ProductFilters(props: Props) {
  const {
    form,
    siteId,
    lgCategory,
    lgGroup,
    lgProduct,
    lgRecipient,
    lgColor,
    productLabel,
    selectMultipleProducts,
    hideColor
  } = props;

  const {
    preferenceColors,
    setPreferenceColors,
    handlePromiseUseCase,
    activeAlert
  } = useContext(globalContext);

  const [productsCategories, setProductsCategories] = useState<
    ProductCategory[]
  >([]);
  const [productionProductGroups, setProductionProductGroups] = useState<
    ProductGroup[]
  >([]);
  const [products, setProducts] = useState<ProductDetailed[]>([]);
  const [recipients, setRecipients] = useState<RecipientDetailed[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<number>(Number.NaN);
  const [selectedGroup, setSelectedGroup] = useState<number>(Number.NaN);
  const [selectedProducts, setSelectedProducts] = useState<number[]>([]);

  const resetOnClear = useCallback(
    (fields: string[]) => {
      form.resetFields(fields);
    },
    [form]
  );

  const onChangeSite = useCallback(() => {
    setSelectedCategory(Number.NaN);
    setProductsCategories([]);
    setProductionProductGroups([]);
    setProducts([]);
    setRecipients([]);
    resetOnClear(['ProductId', 'ProductIds', 'ProductRecipient']);
    setSelectedGroup(Number.NaN);
    setSelectedProducts([]);
    setPreferenceColors([]);
  }, []);

  const clearOtherFielOnCategoryChange = useCallback(() => {
    resetOnClear([
      'ColorId',
      'GroupId',
      'ProductId',
      'ProductIds',
      'ProductRecipient'
    ]);
    setSelectedGroup(Number.NaN);
    setSelectedProducts([]);
  }, [resetOnClear]);

  useEffect(() => {
    if (siteId) {
      const getProductsPreferenceColor = new GetProductsPreferenceColor();
      const getProductsCategories = new GetProductsCategories();
      const getProductsGroups = new GetProductsGroups();
      const getProductsDetailed = new GetProductsDetailed();
      const getRecipientsDetailed = new GetRecipientsDetailed();

      handlePromiseUseCase<ProductPreferenceColor[], AxiosError>(
        getProductsPreferenceColor.execute(),
        prefColor => {
          setPreferenceColors(prefColor);
        },
        error => {
          activeAlert({
            message: JSON.stringify(
              error.response?.data
                ? error.response?.data
                : 'Houve um erro inesperado!'
            ),
            type: 'error',
            timeout: 5000
          });
        }
      );

      handlePromiseUseCase<ProductCategory[], AxiosError>(
        getProductsCategories.execute(siteId),
        prodCategories => {
          setProductsCategories(prodCategories);
        },
        error => {
          activeAlert({
            message: JSON.stringify(
              error.response?.data
                ? error.response?.data
                : 'Houve um erro inesperado!'
            ),
            type: 'error',
            timeout: 5000
          });
        }
      );

      handlePromiseUseCase<ProductGroup[], AxiosError>(
        getProductsGroups.execute(siteId),
        prodGroup => {
          setProductionProductGroups(prodGroup);
        },
        error => {
          activeAlert({
            message: JSON.stringify(
              error.response?.data
                ? error.response?.data
                : 'Houve um erro inesperado!'
            ),
            type: 'error',
            timeout: 5000
          });
        }
      );

      handlePromiseUseCase<ProductDetailed[], AxiosError>(
        getProductsDetailed.execute(siteId),
        prodDetailed => {
          setProducts(prodDetailed);
        },
        error => {
          activeAlert({
            message: JSON.stringify(
              error.response?.data
                ? error.response?.data
                : 'Houve um erro inesperado!'
            ),
            type: 'error',
            timeout: 5000
          });
        }
      );

      handlePromiseUseCase<RecipientDetailed[], AxiosError>(
        getRecipientsDetailed.execute(siteId),
        recDetailed => {
          setRecipients(recDetailed);
        },
        error => {
          activeAlert({
            message: JSON.stringify(
              error.response?.data
                ? error.response?.data
                : 'Houve um erro inesperado!'
            ),
            type: 'error',
            timeout: 5000
          });
        }
      );
    }
    clearOtherFielOnCategoryChange();
    onChangeSite();
  }, [siteId]);

  const productGroupsFiltered = useMemo(() => {
    return productionProductGroups.filter(productGroup => {
      if (!selectedCategory) {
        return true;
      }
      return productGroup.categoryId === selectedCategory;
    });
  }, [productionProductGroups, selectedCategory]);

  const productsFiltered = useMemo(() => {
    return products.filter(product => {
      if (Number.isNaN(selectedCategory) && Number.isNaN(selectedGroup)) {
        return true;
      }

      if (!Number.isNaN(selectedGroup)) {
        return product.groupId === selectedGroup;
      }
      return productGroupsFiltered.find(group => group.id === product.groupId);
    });
  }, [products, selectedCategory, selectedGroup, productGroupsFiltered]);

  const productInRecipient = useCallback(
    (productId: number, recipient: RecipientDetailed) => {
      if (!recipient || !recipient.productIds) {
        return false;
      }
      return recipient.productIds.includes(productId);
    },
    []
  );

  const recipientsFiltered = useMemo(() => {
    return recipients.filter(recipient => {
      if (selectedProducts.length > 0) {
        return selectedProducts.some(productId =>
          productInRecipient(productId, recipient)
        );
      }
      return productsFiltered.find(product =>
        productInRecipient(product.id, recipient)
      );
    });
  }, [selectedProducts, recipients, productInRecipient, productsFiltered]);

  const preferenceColorsFiltered = useMemo(() => {
    if (!productsFiltered.length) {
      return preferenceColors;
    }
    if (!selectedProducts.length) {
      const preferenceColorsFilteredByProductsFiltered =
        preferenceColors.filter((color: any) => {
          return productsFiltered.some(product => product.colorId === color.id);
        });
      if (preferenceColorsFilteredByProductsFiltered.length) {
        return preferenceColorsFilteredByProductsFiltered;
      }
    }
    const preferenceColorsFilteredBySelectedProducts = preferenceColors.filter(
      (color: any) => {
        return productsFiltered
          .filter(p => selectedProducts.some(sP => sP === p.id))
          .some(product => product.colorId === color.id);
      }
    );
    if (preferenceColorsFilteredBySelectedProducts.length) {
      return preferenceColorsFilteredBySelectedProducts;
    }
    return preferenceColors;
  }, [preferenceColors, productsFiltered, selectedProducts]);

  const { t } = useTranslation();

  return (
    <>
      <Col lg={lgCategory ?? 6}>
        <Form.Item name="CategoryId" label={t('generals.category')}>
          <Select
            allowClear
            onClear={() => {
              clearOtherFielOnCategoryChange();
              setSelectedCategory(Number.NaN);
            }}
            placeholder={t('generals.selectPlaceholder')}
            onChange={(value: string) => {
              clearOtherFielOnCategoryChange();
              setSelectedCategory(convertInt(value));
            }}
            optionFilterProp="children"
            filterOption
            showSearch
          >
            {productsCategories.map(productCategory => (
              <Select.Option
                key={productCategory.id}
                value={productCategory.id}
              >
                {productCategory.description}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col lg={lgGroup ?? 6}>
        <Form.Item name="GroupId" label={t('generals.group')}>
          <Select
            allowClear
            onClear={() => {
              resetOnClear([
                'ColorId',
                'ProductId',
                'ProductIds',
                'ProductRecipient'
              ]);
              setSelectedGroup(Number.NaN);
              setSelectedProducts([]);
            }}
            placeholder={t('generals.selectPlaceholder')}
            onChange={(value: string) => {
              setSelectedGroup(parseInt(value, 10));
              resetOnClear([
                'ColorId',
                'ProductId',
                'ProductIds',
                'ProductRecipient'
              ]);
              setSelectedProducts([]);
            }}
            optionFilterProp="children"
            filterOption
            showSearch
          >
            {productGroupsFiltered.map(productGroup => (
              <Select.Option key={productGroup.id} value={productGroup.id}>
                {productGroup.description}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      {selectMultipleProducts ? (
        <Col lg={lgProduct ?? 6}>
          <Form.Item name="ProductIds" label={t('generals.products')}>
            <Select
              mode="multiple"
              placeholder={t('generals.selectPlaceholder')}
              allowClear
              onChange={value => {
                resetOnClear(['ColorId', 'ProductRecipient']);
                setSelectedProducts(value);
              }}
              onClear={() => {
                resetOnClear(['ColorId', 'ProductRecipient', 'GroupId']);
                setSelectedProducts([]);
                setSelectedGroup(Number.NaN);
              }}
              optionFilterProp="children"
              filterOption
              showSearch
              maxTagCount="responsive"
              maxTagPlaceholder={omittedValues => {
                return (
                  <div
                    className="ant-select-selection-overflow-item ant-select-selection-overflow-item-rest"
                    style={{ opacity: 1, order: -1, padding: 0 }}
                  >
                    <span
                      className="ant-select-selection-item"
                      style={{ margin: 0, padding: 0, border: '#f5f5f5' }}
                      title={omittedValues.reduce((acc, curr) => {
                        return `${acc}\n${curr.label
                          ?.toString()
                          .replaceAll(', ', '')
                          .replaceAll(',', '')}`;
                      }, '')}
                    >
                      <span className="ant-select-selection-item-content">
                        +{omittedValues.length}...
                      </span>
                    </span>
                  </div>
                );
              }}
            >
              {productsFiltered.map(product => (
                <Select.Option
                  key={product.id}
                  value={product.id}
                  label={product.fullName}
                >
                  {product.fullName} - {product.sapCode ?? product.code}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      ) : (
        <Col lg={lgProduct ?? 6}>
          <Form.Item
            name="ProductId"
            label={productLabel ?? t('generals.product')}
          >
            <Select
              placeholder={t('generals.selectPlaceholder')}
              allowClear
              onChange={(value: string) => {
                resetOnClear(['ColorId', 'ProductRecipient']);
                setSelectedProducts([convertInt(value)]);
              }}
              onClear={() => {
                resetOnClear(['ColorId', 'ProductRecipient', 'GroupId']);
                setSelectedProducts([]);
                setSelectedGroup(Number.NaN);
              }}
              optionFilterProp="children"
              filterOption
              showSearch
            >
              {productsFiltered.map(product => (
                <Select.Option key={product.id} value={product.id}>
                  {product.fullName} - {product.sapCode ?? product.code}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      )}
      <Col lg={lgRecipient ?? 6}>
        <Form.Item name="ProductRecipient" label={t('generals.size')}>
          <Select
            placeholder={t('generals.selectPlaceholder')}
            allowClear
            onChange={() => {
              resetOnClear(['ColorId']);
            }}
            onClear={() => {
              resetOnClear(['ColorId']);
            }}
            showSearch
            optionFilterProp="children"
            filterOption
          >
            {recipientsFiltered?.map((recipient: RecipientDetailed) => (
              <Select.Option
                key={recipient.description}
                value={recipient.description}
              >
                {recipient.description}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

      {!hideColor ? (
        <Col lg={lgColor ?? 6}>
          <Form.Item name="ColorId" label="Cor">
            <Select
              placeholder={t('generals.selectPlaceholder')}
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption
            >
              {preferenceColorsFiltered?.map(
                (colorsData: ProductPreferenceColor) => {
                  return (
                    <Select.Option key={colorsData.id} value={colorsData.id}>
                      {colorsData.description}
                    </Select.Option>
                  );
                }
              )}
            </Select>
          </Form.Item>
        </Col>
      ) : null}
    </>
  );
}

// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
export function propertyListGenericRequest(keyword: string) {
  return {
    type: '@property/LIST_GENERIC_REQUEST',
    payload: { keyword }
  };
}

export function propertyListGenericSuccess(list: any) {
  return {
    type: '@property/LIST_GENERIC_SUCCESS',
    payload: { list }
  };
}

export function propertyListGenericFailure() {
  return {
    type: '@property/LIST_GENERIC_FAILURE'
  };
}

export function propertyListRequest(filters?: any) {
  return {
    type: '@property/LIST_REQUEST',
    payload: { filters }
  };
}

export function propertyListRequestFull(filters?: any) {
  return {
    type: '@property/LIST_REQUEST_FULL',
    payload: { filters }
  };
}

export function propertyListSuccess(list: any, count: boolean) {
  return {
    type: '@property/LIST_SUCCESS',
    payload: { list, count }
  };
}

export function propertyListFailure() {
  return {
    type: '@property/LIST_FAILURE'
  };
}
export function propertyListFullSuccess(list: any, count: boolean) {
  return {
    type: '@property/LIST_FULL_SUCCESS',
    payload: { list, count }
  };
}

export function propertyListFullFailure() {
  return {
    type: '@property/LIST_FULL_FAILURE'
  };
}

export function propertyAddRequest(property: any) {
  return {
    type: '@property/ADD_REQUEST',
    payload: { property }
  };
}

export function propertyAddSuccess(id: number) {
  return {
    type: '@property/ADD_SUCCESS',
    payload: { id }
  };
}

export function propertyAddFailure() {
  return {
    type: '@property/ADD_FAILURE'
  };
}

export function propertyReadRequest(id: number) {
  return {
    type: '@property/READ_REQUEST',
    payload: { id }
  };
}

export function propertyReadSuccess(data: any) {
  return {
    type: '@property/READ_SUCCESS',
    payload: { data }
  };
}

export function propertyReadFailure() {
  return {
    type: '@property/READ_FAILURE'
  };
}

export function propertyUpdateRequest(property: any) {
  return {
    type: '@property/UPDATE_REQUEST',
    payload: { property }
  };
}

export function propertyUpdateSuccess(id: number) {
  return {
    type: '@property/UPDATE_SUCCESS',
    payload: { id }
  };
}

export function propertyUpdateFailure() {
  return {
    type: '@property/UPDATE_FAILURE'
  };
}

export function propertyDeleteRequest(id: number, filters?: any) {
  return {
    type: '@property/DELETE_REQUEST',
    payload: { id, filters }
  };
}

export function propertyDeleteSuccess() {
  return {
    type: '@property/DELETE_SUCCESS'
  };
}

export function propertyDeleteFailure() {
  return {
    type: '@property/DELETE_FAILURE'
  };
}

export function propertyActiveRequest(
  id: number,
  active: boolean,
  filters?: any
) {
  return {
    type: '@property/ACTIVE_REQUEST',
    payload: { id, active, filters }
  };
}

export function propertyActiveSuccess() {
  return {
    type: '@property/ACTIVE_SUCCESS'
  };
}

export function propertyActiveFailure() {
  return {
    type: '@property/ACTIVE_FAILURE'
  };
}

export function propertyClearRequest() {
  return {
    type: '@property/CLEAR_REQUEST'
  };
}

export function propertyFilterReload(filter: any) {
  return {
    type: '@property/FILTER_RELOAD',
    payload: { filter }
  };
}

export function propertyClearRegristryCompleted() {
  return {
    type: '@property/CLEAR_REGISTRY_COMPLETED'
  };
}

import { create } from 'zustand';
import NonConformity from '~/@core/domain/model/rfidReports/NonConformity';
import TablePagination from '~/@core/domain/ui/TablePagination';

interface DoneState {
  dones: NonConformity[];
  donePagination: TablePagination;
  handleDonePagination: (newPagination: TablePagination) => void;
  addDones: (donesValues: NonConformity[]) => void;
}

const useDoneStore = create<DoneState>()(set => ({
  systems: [],
  dones: [],
  donePagination: {
    pageSize: 10,
    current: 1,
    total: 1
  },
  handleDonePagination: newPagination => set({ donePagination: newPagination }),
  addDones: donesValues => set({ dones: donesValues })
}));

export default useDoneStore;

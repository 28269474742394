import { PropertyModel } from '~/domain/model/PropertyModel';

export const formDataPostAndPuyProperty = (
  property: PropertyModel
): FormData => {
  const formData = new FormData();

  if (property.id?.toString()) {
    formData.append('Id', property.id?.toString());
  }
  if (property.siteId?.toString()) {
    formData.append('SiteId', property.siteId?.toString());
  }
  if (property.description) {
    formData.append('Description', property.description);
  }
  if (property.sequencialCode?.toString()) {
    formData.append('SequencialCode', property.sequencialCode?.toString());
  }
  if (property.isTax?.toString()) {
    formData.append('IsTax', property.isTax?.toString());
  }
  if (property.area?.toString()) {
    formData.append('Area', property.area?.toString());
  }
  if (property.areaUnitId?.toString()) {
    formData.append('AreaUnitId', property.areaUnitId?.toString());
  }
  if (property.isRent?.toString()) {
    formData.append('IsRent', property.isRent?.toString());
  }
  if (property.rentStartPeriod) {
    formData.append('RentStartPeriod', property.rentStartPeriod);
  }
  if (property.rentEndPeriod) {
    formData.append('RentEndPeriod', property.rentEndPeriod);
  }
  if (property.latitude?.toString()) {
    formData.append('Latitude', property.latitude?.toString());
  }
  if (property.longitude?.toString()) {
    formData.append('Longitude', property.longitude?.toString());
  }
  if (property.address?.id?.toString()) {
    formData.append('Address.Id', property.address?.id?.toString());
  }
  if (property.address?.stateId?.toString()) {
    formData.append('Address.StateId', property.address?.stateId?.toString());
  }
  if (property.address?.cityId?.toString()) {
    formData.append('Address.CityId', property.address?.cityId?.toString());
  }
  if (property.address?.zipcode) {
    formData.append('Address.Zipcode', property.address?.zipcode);
  }
  if (property.address?.neighborhood) {
    formData.append('Address.Neighborhood', property.address?.neighborhood);
  }
  if (property.address?.street) {
    formData.append('Address.Street', property.address?.street);
  }
  if (property.address?.addressNumber) {
    formData.append('Address.AddressNumber', property.address?.addressNumber);
  }
  if (property.address?.addressComplement) {
    formData.append(
      'Address.AddressComplement',
      property.address?.addressComplement
    );
  }

  property.locals?.forEach((value, index) => {
    if (value?.id?.toString()) {
      formData.append(`Locals[${index}].Id`, value?.id?.toString());
    }
    if (value?.localTypeId?.toString()) {
      formData.append(
        `Locals[${index}].LocalTypeId`,
        value?.localTypeId?.toString()
      );
    }
    if (value?.area?.toString()) {
      formData.append(`Locals[${index}].Area`, value?.area?.toString());
    }
    if (value?.areaUnitId?.toString()) {
      formData.append(
        `Locals[${index}].AreaUnitId`,
        value?.areaUnitId?.toString()
      );
    }
    if (value?.description) {
      formData.append(`Locals[${index}].Description`, value?.description);
    }
    if (value?.isDeleted?.toString()) {
      formData.append(
        `Locals[${index}].IsDeleted`,
        value?.isDeleted?.toString()
      );
    }
    value.sections?.forEach((valueSection, indexSection) => {
      if (valueSection?.id?.toString()) {
        formData.append(
          `Locals[${index}].Sections[${indexSection}].Id`,
          valueSection?.id?.toString()
        );
      }
      if (valueSection?.description) {
        formData.append(
          `Locals[${index}].Sections[${indexSection}].Description`,
          valueSection?.description
        );
      }
      if (valueSection?.area?.toString()) {
        formData.append(
          `Locals[${index}].Sections[${indexSection}].Area`,
          valueSection?.area?.toString()
        );
      }
      if (valueSection?.areaUnitId?.toString()) {
        formData.append(
          `Locals[${index}].Sections[${indexSection}].AreaUnitId`,
          valueSection?.areaUnitId?.toString()
        );
      }
      if (valueSection?.isDeleted?.toString()) {
        formData.append(
          `Locals[${index}].Sections[${indexSection}].IsDeleted`,
          valueSection?.isDeleted?.toString()
        );
      }
    });
  });

  property.attachments?.forEach((value, index) => {
    if (value?.id?.toString()) {
      formData.append(`Attachments[${index}].Id`, value?.id?.toString());
    }
    if (value?.propertyId?.toString()) {
      formData.append(
        `Attachments[${index}].PropertyId`,
        value?.propertyId?.toString()
      );
    }
    if (value?.propertyAttachmentTypeId?.toString()) {
      formData.append(
        `Attachments[${index}].PropertyAttachmentTypeId`,
        value?.propertyAttachmentTypeId?.toString()
      );
    }
    if (value?.name) {
      formData.append(
        `Attachments[${index}].Observation`,
        value?.name?.toString()
      );
    }
    if (value?.fileUrl) {
      formData.append(
        `Attachments[${index}].FileUrl`,
        value?.fileUrl?.toString()
      );
    }
    if (value?.file) {
      formData.append(`Attachments[${index}].File`, value?.file);
    }
  });

  return formData;
};

import { call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { PayloadAction } from '@reduxjs/toolkit';
import { actions, StatusOffer, Types } from './duck';
import * as LkpOfferService from '~/store/modules/_core/lkpOffers/service';
import { Action } from '~/helpers/util/reducers';
import { APIError } from '~/domain/api/errors';
import { EditParams } from '../../_core/lkpOffers/duck';
import { getWaitEditOpen } from '../volOffersList/selectors';
import { actions as actionsVol } from '../volOffersList';
import { alertRequest } from '../../alert/actions';
import { actions as actionsLoading } from '../loading';
import { generateErrorMessage } from '~/helpers/util/error';

export function* watchEditSaga(action: Action<EditParams[]>) {
  yield fork(editSaga, action.payload);
}

// #FIXME
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* editSaga(params: EditParams[]): any {
  try {
    yield call(LkpOfferService.edit, params);
    yield put(actions.editSuccess());
    const waitStatus = yield select(getWaitEditOpen);
    if (waitStatus === 'waiting') {
      yield put(actionsVol.setWaitEditOpen('openEdit'));
    }
    if (waitStatus === 'waitingCloseTab') {
      yield put(actionsVol.setWaitEditOpen('closeTab'));
    }
    yield put(actionsLoading.setSubmitForm(true));
    // #FIXME
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error instanceof APIError) {
      yield put(actions.editFailure({ errorMessage: error.message }));
    } else {
      yield put(actions.editFailure({}));
    }
  }
}

export function* changeStatus(params: PayloadAction<StatusOffer>) {
  try {
    yield call(LkpOfferService.changeStatus, params.payload);
    yield put(actions.editSuccess());
    yield put(actionsLoading.setSubmitForm(true));
  } catch (error) {
    const errorMessage = generateErrorMessage(error, 'Erro ao ativar ofertas.');
    yield put(alertRequest(errorMessage, 'error'));
    if (error instanceof APIError) {
      // yield put(alertRequest(error.message, 'error'));
    } else {
      yield put(actions.editFailure({}));
    }
  }
}

export default [
  takeLatest(Types.editInit, watchEditSaga),
  takeLatest(Types.changeStatus, changeStatus)
];

import { SearchOutlined } from '@ant-design/icons';
import { Button } from 'antd';

interface ButtonSaveProps {
  onClick: () => any;
  disabled?: boolean;
  isLoading?: boolean;
}
export default function ButtonSearch(props: ButtonSaveProps) {
  const { onClick, disabled, isLoading } = props;
  return (
    <Button
      type="primary"
      disabled={disabled}
      onClick={onClick}
      loading={isLoading}
    >
      <SearchOutlined />
    </Button>
  );
}

import { call, put, takeLatest } from 'redux-saga/effects';
import { Types, actions as authActions } from './duck';
import * as AuthService from '~/services/producer/Auth';
import * as API from '~/domain/api';
import { AuthPermission } from '~/domain/model';
import { redirectSingleSignOn } from '~/helpers/util/storage';
import history from '~/services/history';
import { isNullOrEmpty } from '~/helpers/util';

export function* fetchPermissions() {
  try {
    const { response } = yield call(AuthService.getPermissions);
    if (response) {
      const data: API.AuthPermissionRequest = response.data;
      const permissions = data.permissions.map(
        permission => new AuthPermission(permission)
      );
      let accounts = '';
      data.accountCodes.forEach((ac: string) => {
        // remove zeros a esquerda
        let acc = '';
        let isNoZero = false;
        for (let i = 0; i < ac.length; i++) {
          if (ac.substring(i, i + 1) !== '0') isNoZero = true;
          if (isNoZero) acc += `${ac.substring(i, i + 1)}`;
        }
        accounts += `${isNullOrEmpty(accounts) ? '' : ','}${acc}`;
      });

      yield put(authActions.setPermissions({ permissions, accounts }));
      history.push('/');
    } else {
      redirectSingleSignOn();
    }
  } catch {
    redirectSingleSignOn();
  }
}

export default [takeLatest(Types.fetchPermissions, fetchPermissions)];

import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useState
} from 'react';
import ProductPreferenceColor from '~/@core/domain/model/product/ProductPreferenceColor';
import ShippingCompany from '~/@core/domain/model/site/ShippingCompany';
import Site from '~/@core/domain/model/site/Site';
import AlertModel from '~/@core/domain/ui/AlertModel';
import Alert from '~/components/AlertGlobal';

export interface GlobalContextAttributes {
  siteIdSelected: number;
  setSiteIdSelected: Dispatch<SetStateAction<number>>;
  preferenceColors: ProductPreferenceColor[];
  setPreferenceColors: Dispatch<SetStateAction<ProductPreferenceColor[]>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  showAlert: boolean;
  setShowAlert: Dispatch<SetStateAction<boolean>>;
  handlePromiseUseCase: <O, E>(
    promise: Promise<O>,
    resolve: (output: O) => void,
    reject: (error: E) => void
  ) => void;
  activeAlert: (alertValues: AlertModel) => void;
  sites: Site[];
  setSites: Dispatch<SetStateAction<Site[]>>;
  selectedTransporter: ShippingCompany | null;
  setSelectedTransporter: Dispatch<SetStateAction<ShippingCompany | null>>;
}

export const globalContext = createContext<GlobalContextAttributes>({
  siteIdSelected: Number.NaN,
  setSiteIdSelected: () => {},
  preferenceColors: [],
  setPreferenceColors: () => {},
  loading: false,
  setLoading: () => {},
  handlePromiseUseCase: () => {},
  showAlert: false,
  setShowAlert: () => {},
  activeAlert: (_alertValues: AlertModel) => {},
  sites: [],
  setSites: () => {},
  selectedTransporter: null,
  setSelectedTransporter: () => {}
});

export const GlobalProvider = ({ children }: any) => {
  const [siteIdSelected, setSiteIdSelected] = useState(Number.NaN);
  const [preferenceColors, setPreferenceColors] = useState<
    ProductPreferenceColor[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertData, setAlertData] = useState<AlertModel>({
    message: '',
    timeout: 0,
    type: 'info'
  });
  const [selectedTransporter, setSelectedTransporter] =
    useState<ShippingCompany | null>(null);

  const activeAlert = useCallback(
    (alertValues: AlertModel) => {
      setAlertData(alertValues);
      setShowAlert(true);
    },
    [setAlertData, setShowAlert]
  );
  const handlePromiseUseCase = useCallback(
    <O, E>(
      promise: Promise<O>,
      resolve: (output: O) => void,
      reject: (error: E) => void
    ) => {
      setLoading(true);
      promise
        .then((output: O) => {
          setLoading(false);
          resolve(output);
        })
        .catch((error: E) => {
          setLoading(false);
          reject(error);
        });
    },
    []
  );
  const [sites, setSites] = useState<Site[]>([]);

  return (
    <globalContext.Provider
      value={{
        siteIdSelected,
        setSiteIdSelected,
        preferenceColors,
        setPreferenceColors,
        loading,
        setLoading,
        handlePromiseUseCase,
        showAlert,
        setShowAlert,
        activeAlert,
        sites,
        setSites,
        selectedTransporter,
        setSelectedTransporter
      }}
    >
      {children}
      <Alert
        message={alertData.message}
        type={alertData.type}
        timeout={alertData.timeout || 5000}
        show={showAlert}
        setShow={setShowAlert}
      />
    </globalContext.Provider>
  );
};

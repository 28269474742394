import produce from 'immer';
import { Week } from '~/domain/model';
import { createCreator, createReducer, Reducer } from '~/helpers/util/reducers';

export type WeeksState = {
  byId: Map<string, Week>;
};

export type Update = {
  byId: Map<string, Week>;
};

const prefix = '@weeks';

export const Types = Object.freeze({
  CLEAR: `${prefix}/CLEAR`,
  UPDATE: `${prefix}/UPDATE`
});

export const initialState: WeeksState = {
  byId: new Map()
};

const clearReducer: Reducer<WeeksState, void> = state => {
  return produce(state, draft => {
    draft.byId = initialState.byId;
  });
};

const updateReducer: Reducer<WeeksState, Update> = (state, action) => {
  return produce(state, draft => {
    draft.byId = action.byId as Map<string, Week>;
  });
};

export const actions = Object.freeze({
  clear: createCreator<void>(Types.CLEAR),
  update: createCreator<Update>(Types.UPDATE)
});

export const reducer = createReducer(initialState, {
  [Types.CLEAR]: clearReducer,
  [Types.UPDATE]: updateReducer
});

// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import api from '~/services/api';

import {
  addSuccess,
  addFailure,
  searchSuccess,
  searchFailure
} from './actions';

export function* onCreate({ payload }: any): any {
  try {
    yield call(api.post, '/site-create', payload);

    yield put(addSuccess());
  } catch (err) {
    yield put(addFailure());
  }
}

export function* onSearch(): any {
  try {
    const tabs = yield select((store: any) => store.tabs.tabs);
    const selectedTabIndex = yield select(
      (store: any) => store.tabs.selectedTab
    );
    const selectedTab = tabs[selectedTabIndex];

    const response: any = yield call(
      api.get,
      `/site/${selectedTab.permissionName}`
    );
    const { data } = response;

    yield put(searchSuccess(data));
  } catch (err) {
    yield put(searchFailure());
  }
}

export function* onSearchNotFilter(): any {
  try {
    const response: any = yield call(api.get, `/site`);
    const { data } = response;

    yield put(searchSuccess(data));
  } catch (err) {
    yield put(searchFailure());
  }
}

export function* onUpdate() {
  //
}

export function* onStart() {}

export default all([
  takeLatest('@site/ADD_REQUEST', onCreate),
  takeLatest('@site/EDIT_REQUEST', onUpdate),
  takeLatest('@site/SEARCH_SITE', onSearch),
  takeLatest('@site/SEARCH_SITE_NOT_FILTER', onSearchNotFilter)
]);

import { EcommerceManagementState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

// #FIXME Remover any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRoot = (state: any) => {
  return state.ui.ecommerceManagementList as EcommerceManagementState;
};

export const isLoading = createSelector([getRoot], state => {
  return (
    state.fetchInitStatus === 'pending' ||
    state.fetchProductsStatus === 'pending' ||
    state.fetchListStatus === 'pending' ||
    state.exportStaus === 'pending'
  );
});

export const isLoadingHistory = createSelector([getRoot], state => {
  return state.fetchHistoryPurchaseManagementStatus === 'pending';
});

export const historyStatus = createSelector([getRoot], state => {
  return state.fetchHistoryPurchaseManagementStatus;
});

export const errorMessage = createSelector([getRoot], state => {
  return state.errorMessage;
});

export const isLoadingCancel = createSelector([getRoot], state => {
  return state.cancelPurchaseManagementStatus === 'pending';
});

export const cancelStatus = createSelector([getRoot], state => {
  return state.cancelPurchaseManagementStatus;
});

export const getPageNumber = createSelector([getRoot], state => {
  return state.pageNumber;
});

export const getPageSize = createSelector([getRoot], state => {
  return state.pageSize;
});

export const getTotalCount = createSelector([getRoot], state => {
  return state.totalCount;
});

import produce from 'immer';
import { ApplicationError } from '~/domain/api/errors';
import { createCreator, createReducer, Reducer } from '~/helpers/util/reducers';
import { Upsert } from '../../_core/offerSettings/duck';

import * as API from '~/domain/api';

export type FetchStatus = 'idle' | 'pending' | 'success' | 'failure';
export type UpsertStatus = 'idle' | 'pending' | 'success' | 'failure';

export type OfferSettingsState = {
  siteCountingPatternId?: number;
  countingPatternID?: number;
  siteCompositionId?: number;
  categoryCodes?: number[];
  minimunAmountId?: number;
  quantity?: number;
  abled?: boolean;
  fetchStatus: FetchStatus;
  upsertStatus: UpsertStatus;
  fetchOfferSettingsStatus: FetchStatus;
  fetchCountingPatternHistoryStatus: FetchStatus;
  fetchCompositionHistoryStatus: FetchStatus;
  fetchMinimumAmountHistoryStatus: FetchStatus;
  countingPatternHistory: API.CountingPatternHistory[];
  compositionHistory: API.CompositionHistory[];
  minimumAmountCutProductHistory: API.MinimumAmountCutProductHistory[];
  errorMessage?: string;
  pageNumber: number;
  pageSize: number;
  totalCount: number;
};

export type FetchOfferSettings = {
  siteId: number;
};

export type FetchHistory = {
  siteId: number;
};

export type FetchPage = {
  pageNumber: number;
  pageSize: number;
};

export type FetchCountingPatternHistorySuccess = {
  countingPatternHistory: API.CountingPatternHistory[];
};

export type FetchCompositionHistorySuccess = {
  compositionHistory: API.CompositionHistory[];
};

export type FetchMinimumAmountCutProductHistorySuccess = {
  minimumAmountCutProductHistory: API.MinimumAmountCutProductHistory[];
};

export type FetchHistorySuccess = {
  pageNumber?: number;
  pageSize?: number;
  totalCount: number;
};

export type FetchFailure = {
  error?: ApplicationError;
};

export type Error = {
  errorMessage?: string;
};

const prefix = '@offerSettings';

export const Types = Object.freeze({
  CLEAR: `${prefix}/CLEAR`,
  FETCH_INIT: `${prefix}/FETCH_INIT`,
  FETCH_SUCCESS: `${prefix}/FETCH_SUCCESS`,
  FETCH_FAILURE: `${prefix}/FETCH_FAILURE`,
  FETCH_OFFER_SETTINGS: `${prefix}/FETCH_OFFER_SETTINGS`,
  FETCH_OFFER_SETTINGS_SUCCESS: `${prefix}/FETCH_OFFER_SETTINGS_SUCCESS`,
  FETCH_OFFER_SETTINGS_FAILURE: `${prefix}/FETCH_OFFER_SETTINGS_FAILURE`,
  UPSERT_INIT: `${prefix}/UPSERT_INIT`,
  UPSERT_SUCCESS: `${prefix}/ UPSERT_SUCCESS`,
  UPSERT_FAILURE: `${prefix}/ UPSERT_FAILURE`,
  FETCH_COUNTING_PATTERN_HISTORY: `${prefix}/FETCH_COUNTING_PATTERN_HISTORY`,
  FETCH_COUNTING_PATTERN_HISTORY_SUCCESS: `${prefix}/FETCH_COUNTING_PATTERN_HISTORY_SUCCESS`,
  FETCH_COUNTING_PATTERN_HISTORY_FAILURE: `${prefix}/FETCH_COUNTING_PATTERN_HISTORY_FAILURE`,
  FETCH_COMPOSITION_HISTORY: `${prefix}/FETCH_COMPOSITION_HISTORY`,
  FETCH_COMPOSITION_HISTORY_SUCCESS: `${prefix}/FETCH_COMPOSITION_HISTORY_SUCCESS`,
  FETCH_COMPOSITION_HISTORY_FAILURE: `${prefix}/FETCH_COMPOSITION_HISTORY_FAILURE`,
  FETCH_MINIMUM_AMOUNT_CUT_PRODUCT_HISTORY: `${prefix}/FETCH_MINIMUM_AMOUNT_CUT_PRODUCT_HISTORY`,
  FETCH_MINIMUM_AMOUNT_CUT_PRODUCT_HISTORY_SUCCESS: `${prefix}/FETCH_MINIMUM_AMOUNT_CUT_PRODUCT_HISTORY_SUCCESS`,
  FETCH_MINIMUM_AMOUNT_CUT_PRODUCT_HISTORY_FAILURE: `${prefix}/FETCH_MINIMUM_AMOUNT_CUT_PRODUCT_HISTORY_FAILURE`
});

export const initialState: OfferSettingsState = {
  siteCountingPatternId: Number.NaN,
  countingPatternID: Number.NaN,
  siteCompositionId: Number.NaN,
  categoryCodes: [],
  minimunAmountId: Number.NaN,
  quantity: Number.NaN,
  abled: false,
  fetchStatus: 'idle',
  upsertStatus: 'idle',
  fetchOfferSettingsStatus: 'idle',
  fetchCountingPatternHistoryStatus: 'idle',
  fetchCompositionHistoryStatus: 'idle',
  fetchMinimumAmountHistoryStatus: 'idle',
  countingPatternHistory: [],
  compositionHistory: [],
  minimumAmountCutProductHistory: [],
  errorMessage: '',
  pageNumber: 1,
  pageSize: 10,
  totalCount: 0
};

const clearReducer: Reducer<OfferSettingsState, void> = (state, _action) => {
  return produce(state, draft => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.assign(draft, { ...initialState });
  });
};

const fetchInitReducer: Reducer<OfferSettingsState, void> = state => {
  return produce(state, draft => {
    draft.fetchStatus = 'pending';
  });
};

const fetchSuccessReducer: Reducer<OfferSettingsState, void> = state => {
  return produce(state, draft => {
    draft.fetchStatus = 'success';
  });
};

const fetchFailureReducer: Reducer<OfferSettingsState, void> = state => {
  return produce(state, draft => {
    draft.fetchStatus = 'failure';
  });
};

const fetchOfferSettingsReducer: Reducer<OfferSettingsState, void> = state => {
  return produce(state, draft => {
    draft.fetchOfferSettingsStatus = 'pending';
  });
};

const fetchOfferSettingsSuccessReducer: Reducer<
  OfferSettingsState,
  void
> = state => {
  return produce(state, draft => {
    draft.fetchOfferSettingsStatus = 'success';
  });
};

const fetchOfferSettingsFailureReducer: Reducer<
  OfferSettingsState,
  void
> = state => {
  return produce(state, draft => {
    draft.fetchOfferSettingsStatus = 'failure';
  });
};

const upsertInitReducer: Reducer<OfferSettingsState, void> = state => {
  return produce(state, draft => {
    draft.upsertStatus = 'pending';
  });
};

const upsertSuccessReducer: Reducer<OfferSettingsState, void> = state => {
  return produce(state, draft => {
    draft.upsertStatus = 'success';
  });
};

const upsertFailureReducer: Reducer<OfferSettingsState, Error> = (
  state,
  action
) => {
  return produce(state, draft => {
    draft.upsertStatus = 'failure';
    draft.errorMessage = action?.errorMessage;
  });
};

const fetchCountingPatternHistoryReducer: Reducer<
  OfferSettingsState,
  FetchHistory
> = state => {
  return produce(state, draft => {
    draft.fetchCountingPatternHistoryStatus = 'pending';
  });
};

const fetchCountingPatternHistorySuccessReducer: Reducer<
  OfferSettingsState,
  FetchCountingPatternHistorySuccess
> = (state, action) => {
  return produce(state, draft => {
    const { countingPatternHistory } = action;
    draft.fetchCountingPatternHistoryStatus = 'success';
    draft.countingPatternHistory = countingPatternHistory;
  });
};

const fetchCountingPatternHistoryFailureReducer: Reducer<
  OfferSettingsState,
  void
> = state => {
  return produce(state, draft => {
    draft.fetchCountingPatternHistoryStatus = 'failure';
  });
};

const fetchCompositionHistoryReducer: Reducer<
  OfferSettingsState,
  FetchHistory
> = state => {
  return produce(state, draft => {
    draft.fetchCompositionHistoryStatus = 'pending';
  });
};

const fetchCompositionHistorySuccessReducer: Reducer<
  OfferSettingsState,
  FetchCompositionHistorySuccess
> = (state, action) => {
  return produce(state, draft => {
    const { compositionHistory } = action;
    draft.fetchCompositionHistoryStatus = 'success';
    draft.compositionHistory = compositionHistory;
  });
};

const fetchCompositionHistoryFailureReducer: Reducer<
  OfferSettingsState,
  void
> = state => {
  return produce(state, draft => {
    draft.fetchCompositionHistoryStatus = 'failure';
  });
};

const fetchMinimumAmountHistoryReducer: Reducer<
  OfferSettingsState,
  FetchHistory
> = state => {
  return produce(state, draft => {
    draft.fetchMinimumAmountHistoryStatus = 'pending';
  });
};

const fetchMinimumAmountHistorySuccessReducer: Reducer<
  OfferSettingsState,
  FetchMinimumAmountCutProductHistorySuccess
> = (state, action) => {
  return produce(state, draft => {
    const { minimumAmountCutProductHistory } = action;
    draft.fetchMinimumAmountHistoryStatus = 'success';
    draft.minimumAmountCutProductHistory = minimumAmountCutProductHistory;
  });
};

const fetchMinimumAmountHistoryFailureReducer: Reducer<
  OfferSettingsState,
  void
> = state => {
  return produce(state, draft => {
    draft.fetchMinimumAmountHistoryStatus = 'failure';
  });
};

export const actions = Object.freeze({
  clear: createCreator<void>(Types.CLEAR),
  fetchInit: createCreator<void>(Types.FETCH_INIT),
  fetchSuccess: createCreator<void>(Types.FETCH_SUCCESS),
  fetchFailure: createCreator<void>(Types.FETCH_FAILURE),
  fetchOfferSettings: createCreator<FetchOfferSettings>(
    Types.FETCH_OFFER_SETTINGS
  ),
  fetchOfferSettingsSuccess: createCreator<void>(
    Types.FETCH_OFFER_SETTINGS_SUCCESS
  ),
  fetchOfferSettingsFailure: createCreator<void>(
    Types.FETCH_OFFER_SETTINGS_FAILURE
  ),
  upsertInit: createCreator<Upsert>(Types.UPSERT_INIT),
  upsertSuccess: createCreator<void>(Types.UPSERT_SUCCESS),
  upsertFailure: createCreator<Error>(Types.UPSERT_FAILURE),
  fetchCountingPatternHistory: createCreator<FetchHistory>(
    Types.FETCH_COUNTING_PATTERN_HISTORY
  ),
  fetchCountingPatternHistorySuccess:
    createCreator<FetchCountingPatternHistorySuccess>(
      Types.FETCH_COUNTING_PATTERN_HISTORY_SUCCESS
    ),
  fetchCountingPatternHistoryFailure: createCreator<void>(
    Types.FETCH_COUNTING_PATTERN_HISTORY_FAILURE
  ),
  fetchCompositionHistory: createCreator<FetchHistory>(
    Types.FETCH_COMPOSITION_HISTORY
  ),
  fetchCompositionHistorySuccess: createCreator<FetchCompositionHistorySuccess>(
    Types.FETCH_COMPOSITION_HISTORY_SUCCESS
  ),
  fetchCompositionHistoryFailure: createCreator<void>(
    Types.FETCH_COMPOSITION_HISTORY_FAILURE
  ),
  fetchMinimumAmountCutProductHistory: createCreator<FetchHistory>(
    Types.FETCH_MINIMUM_AMOUNT_CUT_PRODUCT_HISTORY
  ),
  fetchMinimumAmountCutProductHistorySuccess:
    createCreator<FetchMinimumAmountCutProductHistorySuccess>(
      Types.FETCH_MINIMUM_AMOUNT_CUT_PRODUCT_HISTORY_SUCCESS
    ),
  fetchMinimumAmountCutProductHistoryFailure: createCreator<void>(
    Types.FETCH_MINIMUM_AMOUNT_CUT_PRODUCT_HISTORY_FAILURE
  )
});

export const reducer = createReducer(initialState, {
  [Types.CLEAR]: clearReducer,
  [Types.FETCH_INIT]: fetchInitReducer,
  [Types.FETCH_SUCCESS]: fetchSuccessReducer,
  [Types.FETCH_FAILURE]: fetchFailureReducer,
  [Types.FETCH_OFFER_SETTINGS]: fetchOfferSettingsReducer,
  [Types.FETCH_OFFER_SETTINGS_SUCCESS]: fetchOfferSettingsSuccessReducer,
  [Types.FETCH_OFFER_SETTINGS_FAILURE]: fetchOfferSettingsFailureReducer,
  [Types.UPSERT_INIT]: upsertInitReducer,
  [Types.UPSERT_SUCCESS]: upsertSuccessReducer,
  [Types.UPSERT_FAILURE]: upsertFailureReducer,
  [Types.FETCH_COUNTING_PATTERN_HISTORY]: fetchCountingPatternHistoryReducer,
  [Types.FETCH_COUNTING_PATTERN_HISTORY_SUCCESS]:
    fetchCountingPatternHistorySuccessReducer,
  [Types.FETCH_COUNTING_PATTERN_HISTORY_FAILURE]:
    fetchCountingPatternHistoryFailureReducer,
  [Types.FETCH_COMPOSITION_HISTORY]: fetchCompositionHistoryReducer,
  [Types.FETCH_COMPOSITION_HISTORY_SUCCESS]:
    fetchCompositionHistorySuccessReducer,
  [Types.FETCH_COMPOSITION_HISTORY_FAILURE]:
    fetchCompositionHistoryFailureReducer,
  [Types.FETCH_MINIMUM_AMOUNT_CUT_PRODUCT_HISTORY]:
    fetchMinimumAmountHistoryReducer,
  [Types.FETCH_MINIMUM_AMOUNT_CUT_PRODUCT_HISTORY_SUCCESS]:
    fetchMinimumAmountHistorySuccessReducer,
  [Types.FETCH_MINIMUM_AMOUNT_CUT_PRODUCT_HISTORY_FAILURE]:
    fetchMinimumAmountHistoryFailureReducer
});

import produce from 'immer';
import { ProductQuality } from '~/domain/model';
import { createCreator, createReducer, Reducer } from '~/helpers/util/reducers';

export type Status = 'cleared' | 'updated';

export type ProductQualityState = {
  productQualitiesById: Map<string, ProductQuality>;
  status: Status;
};

export type Update = {
  productQualitiesById: Map<string, ProductQuality>;
};

const prefix = '@productQuality';

export const Types = Object.freeze({
  CLEAR: `${prefix}/CLEAR`,
  UPDATE: `${prefix}/UPDATE`
});

export const initialState: ProductQualityState = {
  productQualitiesById: new Map(),
  status: 'cleared'
};

const clearReducer: Reducer<ProductQualityState, void> = state => {
  return produce(state, draft => {
    draft.productQualitiesById = initialState.productQualitiesById;
    draft.status = initialState.status;
  });
};

const updateReducer: Reducer<ProductQualityState, Update> = (state, action) => {
  return produce(state, draft => {
    draft.productQualitiesById = action.productQualitiesById as Map<
      string,
      ProductQuality
    >;
  });
};

export const actions = Object.freeze({
  clear: createCreator<void>(Types.CLEAR),
  update: createCreator<Update>(Types.UPDATE)
});

export const reducer = createReducer(initialState, {
  [Types.CLEAR]: clearReducer,
  [Types.UPDATE]: updateReducer
});

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type StatusRequest = 'idle' | 'pending' | 'success' | 'failure';

export type ReportsAnnouncementState = {
  activeKey: string;
  requestReportsAnnouncementStatus: StatusRequest;
  exportStatus: StatusRequest;
  errorMessage?: string;
  pageNumber: number;
  pageSize: number;
  totalCount: number;
};

export type ParamRequestReportsAnnouncement = {
  description?: string;
  startDate?: string;
  endDate?: string;
  role?: string;
  siteId?: number;
  pageNumber?: number;
  pageSize?: number;
};

export type ParamChangePagination = {
  pageNumber: number;
  pageSize: number;
};

export type ParamRequestReportsAnnouncementSuccess = {
  pageNumber?: number;
  pageSize?: number;
  totalCount: number;
};

export type Error = {
  errorMessage?: string;
};

const prefix = '@reportsAnnouncement';

export const initialState: ReportsAnnouncementState = {
  activeKey: '1',
  requestReportsAnnouncementStatus: 'idle',
  exportStatus: 'idle',
  pageNumber: 1,
  pageSize: 20,
  totalCount: 0
};

const reportsAnnouncement = createSlice({
  name: prefix,
  initialState,
  reducers: {
    requestReportsAnnouncement: (
      state,
      action: PayloadAction<ParamRequestReportsAnnouncement>
    ) => {
      state.requestReportsAnnouncementStatus = 'pending';
      let { pageNumber, pageSize } = action.payload;
      if (!pageNumber) {
        pageNumber = initialState.pageNumber;
        state.pageNumber = pageNumber;
      }
      if (!pageSize) {
        pageSize = initialState.pageSize;
        state.pageSize = pageSize;
      }
    },
    requestReportsAnnouncementSuccess: (
      state,
      action: PayloadAction<ParamRequestReportsAnnouncementSuccess>
    ) => {
      state.requestReportsAnnouncementStatus = 'success';
      state.totalCount = action.payload.totalCount;
      state.pageSize = action.payload.pageSize ?? 10;
      state.pageNumber = action.payload.pageNumber ?? 1;
    },
    requestReportsAnnouncementFailure: (
      state,
      action: PayloadAction<Error>
    ) => {
      state.requestReportsAnnouncementStatus = 'failure';
      if (action.payload.errorMessage) {
        state.errorMessage = action.payload.errorMessage;
      }
    },
    setActiveKey: (state, action: PayloadAction<string>) => {
      state.activeKey = action.payload;
    },
    setPagination: (state, action: PayloadAction<ParamChangePagination>) => {
      const { pageNumber, pageSize } = action.payload;
      state.pageNumber = pageNumber ?? 1;
      state.pageSize = pageSize ?? 10;
    }
  }
});

export const actions = reportsAnnouncement.actions;
export const reducer = reportsAnnouncement.reducer;

const caseReducers = reportsAnnouncement.caseReducers;

export const Types = Object.freeze({
  requestReportsAnnouncement: `${prefix}/${caseReducers.requestReportsAnnouncement.name}`,
  requestReportsAnnouncementSuccess: `${prefix}/${caseReducers.requestReportsAnnouncementSuccess.name}`,
  requestReportsAnnouncementFailure: `${prefix}/${caseReducers.requestReportsAnnouncementFailure.name}`
});

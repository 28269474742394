import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationError } from '~/domain/api/errors';

export type FetchStatus = 'idle' | 'pending' | 'success' | 'failure';

export type BannerProducerManagementListState = {
  fetchInitStatus: FetchStatus;
  fetchBannerProducerManagementStatus: FetchStatus;
  fetchBannerImageStatus: FetchStatus;
  activeOrInactiveStatus: FetchStatus;
  errorMessage?: string;
  pageNumber: number;
  pageSize: number;
  totalCount: number;
};

export type FetchPage = {
  pageNumber: number;
  pageSize: number;
};

export type FetchBannerProducerManagementSuccess = {
  pageNumber?: number;
  pageSize?: number;
  totalCount: number;
};

export type ChangeStatus = {
  id: number;
  isActive: boolean;
};

export type FetchFailure = {
  error?: ApplicationError;
};

export type Error = {
  errorMessage?: string;
};

const prefix = '@bannerProducerManagementList';

export const initialState: BannerProducerManagementListState = {
  fetchInitStatus: 'idle',
  activeOrInactiveStatus: 'idle',
  fetchBannerImageStatus: 'idle',
  fetchBannerProducerManagementStatus: 'idle',
  pageNumber: 1,
  pageSize: 10,
  totalCount: 0
};

export type FetchBannerProducerManagement = {
  description?: string;
  url?: string;
  status?: string;
  bannerTypeId?: number;
  uploadDate?: string;
  pageNumber?: number;
  pageSize?: number;
};
const bannerProducerManagementList = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: state => {
      state.fetchInitStatus = initialState.fetchInitStatus;
      state.fetchBannerImageStatus = initialState.fetchBannerImageStatus;
      state.fetchBannerProducerManagementStatus =
        initialState.fetchBannerProducerManagementStatus;
      state.pageNumber = 1;
      state.pageSize = 10;
      state.totalCount = 0;
      state.errorMessage = '';
    },
    clearStatusBannerImage: state => {
      state.fetchBannerImageStatus = initialState.fetchBannerImageStatus;
    },
    fetchInit: state => {
      state.fetchInitStatus = 'pending';
    },
    fetchInitSuccess: state => {
      state.fetchInitStatus = 'success';
    },
    fetchInitFailure: state => {
      state.fetchInitStatus = 'failure';
    },
    fetchBannerImage: (state, _action: PayloadAction<number>) => {
      state.fetchBannerImageStatus = 'pending';
    },
    fetchBannerImageSuccess: state => {
      state.fetchBannerImageStatus = 'success';
    },
    fetchBannerImageFailure: state => {
      state.fetchBannerImageStatus = 'failure';
    },
    activeOrInactiveBanner: (state, _action: PayloadAction<ChangeStatus>) => {
      state.activeOrInactiveStatus = 'pending';
    },
    activeOrInactiveBannerSuccess: state => {
      state.activeOrInactiveStatus = 'success';
    },
    activeOrInactiveBannerFailure: state => {
      state.activeOrInactiveStatus = 'failure';
    },
    setPagination: (state, action: PayloadAction<FetchPage>) => {
      state.pageNumber = action.payload.pageNumber;
      state.pageSize = action.payload.pageSize;
    },
    fetchBannerProducerManagement: (
      state,
      action: PayloadAction<FetchBannerProducerManagement>
    ) => {
      state.fetchBannerProducerManagementStatus = 'pending';
      let { pageNumber, pageSize } = action.payload;
      if (!pageNumber) {
        pageNumber = initialState.pageNumber;
        state.pageNumber = pageNumber;
      }
      if (!pageSize) {
        pageSize = initialState.pageSize;
        state.pageSize = pageSize;
      }
    },
    fetchBannerProducerManagementSuccess: (
      state,
      action: PayloadAction<FetchBannerProducerManagementSuccess>
    ) => {
      state.fetchBannerProducerManagementStatus = 'success';
      state.totalCount = action.payload.totalCount;
      state.pageSize = action.payload.pageSize ?? 10;
      state.pageNumber = action.payload.pageNumber ?? 1;
    },
    fetchBannerProducerManagementFailure: (
      state,
      _action: PayloadAction<Error | void>
    ) => {
      state.fetchBannerProducerManagementStatus = 'failure';
    }
  }
});
export const actions = bannerProducerManagementList.actions;
export const reducer = bannerProducerManagementList.reducer;

const caseReducers = bannerProducerManagementList.caseReducers;
export const Types = Object.freeze({
  clear: `${prefix}/${caseReducers.clear.name}`,
  clearStatusBannerImage: `${prefix}/${caseReducers.clearStatusBannerImage.name}`,
  fetchInit: `${prefix}/${caseReducers.fetchInit.name}`,
  fetchInitSuccess: `${prefix}/${caseReducers.fetchInitSuccess.name}`,
  fetchFailureInit: `${prefix}/${caseReducers.fetchInitFailure.name}`,
  fetchBannerImage: `${prefix}/${caseReducers.fetchBannerImage.name}`,
  fetchBannerImageSuccess: `${prefix}/${caseReducers.fetchBannerImageSuccess.name}`,
  fetchBannerImageFailure: `${prefix}/${caseReducers.fetchBannerImageFailure.name}`,
  setPagination: `${prefix}/${caseReducers.setPagination.name}`,
  activeOrInactiveBanner: `${prefix}/${caseReducers.activeOrInactiveBanner.name}`,
  activeOrInactiveBannerSuccess: `${prefix}/${caseReducers.activeOrInactiveBannerSuccess.name}`,
  activeOrInactiveBannerFailure: `${prefix}/${caseReducers.activeOrInactiveBannerFailure.name}`,
  fetchBannerProducerManagement: `${prefix}/${caseReducers.fetchBannerProducerManagement.name}`,
  fetchBannerProducerManagementSuccess: `${prefix}/${caseReducers.fetchBannerProducerManagementSuccess.name}`,
  fetchBannerProducerManagementFailure: `${prefix}/${caseReducers.fetchBannerProducerManagementFailure.name}`
});

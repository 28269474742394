import * as API from '~/domain/api';

export default class AuthPermission implements API.AuthPermission {
  private _permissionName: string;
  private _accounts: any[];

  constructor(json: API.AuthPermission) {
    this._accounts = json.accounts;
    this._permissionName = json.permissionName;
  }

  /**
   * Getter permissionName
   * @return {string}
   */
  public get permissionName(): string {
    return this._permissionName;
  }

  /**
   * Setter permissionName
   * @param {string} value
   */
  public set permissionName(value: string) {
    this._permissionName = value;
  }

  /**
   * Getter accounts
   * @return {any[]}
   */
  public get accounts(): any[] {
    return this._accounts.length > 0 ? this._accounts : [];
  }

  /**
   * Setter accounts
   * @param {any[]} value
   */
  public set accounts(value: any[]) {
    this._accounts = value;
  }
}

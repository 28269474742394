import { call, fork, put, takeLatest } from 'redux-saga/effects';

import * as API from '~/domain/api';
import * as ReportsAnnouncementService from '~/store/modules/_core/reportsAnnouncement/service';

import { actions, ParamRequestReportsAnnouncement, Types } from './duck';
import { Action } from '~/helpers/util/reducers';
import { APIError } from '~/domain/api/errors';

export function* watchReportsAnnouncementSaga(
  action: Action<ParamRequestReportsAnnouncement>
) {
  yield fork(requestReportsAnnouncementSaga, action.payload);
}

export function* requestReportsAnnouncementSaga(
  params: ParamRequestReportsAnnouncement
) {
  try {
    const response: API.ServerResponse = yield call(
      ReportsAnnouncementService.requestReportsAnnouncement,
      params
    );
    const data: API.DefaultResponse = response.data;

    const totalCount = data.totalCount ?? 0;
    yield put(
      actions.requestReportsAnnouncementSuccess({
        pageNumber: params.pageNumber,
        pageSize: params.pageSize,
        totalCount
      })
    );
  } catch (error: any) {
    if (error instanceof APIError) {
      yield put(
        actions.requestReportsAnnouncementFailure({
          errorMessage: error.message
        })
      );
    } else {
      yield put(
        actions.requestReportsAnnouncementFailure({
          errorMessage: error?.response?.data[0]
        })
      );
    }
  }
}

export default [
  takeLatest(Types.requestReportsAnnouncement, watchReportsAnnouncementSaga)
];

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// eslint-disable-next-line import/no-extraneous-dependencies
import { initReactI18next } from 'react-i18next';
import { pt, en } from './locales';

const options = {
  interpolation: {
    escapeValue: false // not needed for react!!
  },

  debug: true,

  lng: 'pt-BR',

  resources: {
    pt: {
      common: pt['pt-BR']
    },
    en: {
      common: en['en-US']
    }
  },

  fallbackLng: 'en',

  ns: ['common'],

  defaultNS: 'common',

  react: {
    wait: false,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default'
  }
};

i18n.use(LanguageDetector).use(initReactI18next).init(options);

export default i18n;

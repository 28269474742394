import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type FetchStatus = 'idle' | 'pending' | 'success' | 'failure';

export type SalesReportState = {
  weekId?: number;
  errorMessage?: string;
  fetchInitStatus: FetchStatus;
  fetchSalesReportVolListStatus: FetchStatus;
  fetchWorkSheetVolStatus: FetchStatus;
  fetchWorkSheetLkpStatus: FetchStatus;
  fetchSalesReportLkpListStatus: FetchStatus;
  fetchProductsStatus: FetchStatus;
  totalCountVolList?: number;
  pageNumberVolList?: number;
  pageSizeVolList?: number;
  totalCountLkpList?: number;
  pageNumberLkpList?: number;
  pageSizeLkpList?: number;
  producerNameVol?: string;
  productNameVol?: string;
  weekInfoVol?: number;
  producerNameLkp?: string;
  productNameLkp?: string;
  weekInfoLkp?: number;
};

export const initialState: SalesReportState = {
  fetchInitStatus: 'idle',
  fetchProductsStatus: 'idle',
  fetchSalesReportVolListStatus: 'idle',
  fetchWorkSheetVolStatus: 'idle',
  totalCountVolList: 0,
  pageNumberVolList: 1,
  pageSizeVolList: 10,
  fetchSalesReportLkpListStatus: 'idle',
  fetchWorkSheetLkpStatus: 'idle',
  totalCountLkpList: 0,
  pageNumberLkpList: 1,
  pageSizeLkpList: 10,
  producerNameVol: '-',
  productNameVol: '-',
  weekInfoVol: 0,
  producerNameLkp: '-',
  productNameLkp: '-',
  weekInfoLkp: 0
};

export type Error = {
  errorMessage?: string;
};

export type FetchWorkSheet = {
  id: number;
};

export type PaginationParams = {
  pageNumber?: number;
  pageSize?: number;
};

export type FetchProducts = {
  siteId: number;
};

export type FetchSalesReportSuccess = {
  pageNumber?: number;
  pageSize?: number;
  totalCount: number;
};

export type FetchFilterInformations = {
  producerName?: string;
  productName?: string;
  weekInfo?: number;
};

export type FetchSalesReport = {
  siteId: number;
  startDate: string;
  endDate: string;
  productId?: number;
  ColorId?: number;
  pageNumber?: number;
  pageSize?: number;
};

const prefix = '@salesReport';

const salesReport = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: state => {
      state.fetchInitStatus = initialState.fetchInitStatus;
      state.fetchProductsStatus = initialState.fetchProductsStatus;
    },
    clearLkp: state => {
      state.fetchSalesReportLkpListStatus =
        initialState.fetchSalesReportLkpListStatus;
      state.fetchWorkSheetLkpStatus = initialState.fetchWorkSheetLkpStatus;
      state.totalCountLkpList = initialState.totalCountLkpList;
      state.pageNumberLkpList = initialState.pageNumberLkpList;
      state.pageSizeLkpList = initialState.pageSizeLkpList;
    },
    clearVol: state => {
      state.fetchSalesReportVolListStatus =
        initialState.fetchSalesReportVolListStatus;
      state.totalCountVolList = initialState.totalCountVolList;
      state.pageNumberVolList = initialState.pageNumberVolList;
      state.pageSizeVolList = initialState.pageSizeVolList;
      state.fetchWorkSheetVolStatus = initialState.fetchWorkSheetVolStatus;
    },
    setSelectedWeekId: (state, action: PayloadAction<number>) => {
      state.weekId = action.payload;
    },
    setFiltersInformationsVol: (
      state,
      action: PayloadAction<FetchFilterInformations>
    ) => {
      state.producerNameVol = action.payload.producerName;
      state.productNameVol = action.payload.productName;
      state.weekInfoVol = action.payload.weekInfo;
    },
    setFiltersInformationsLkp: (
      state,
      action: PayloadAction<FetchFilterInformations>
    ) => {
      state.producerNameLkp = action.payload.producerName;
      state.productNameLkp = action.payload.productName;
      state.weekInfoLkp = action.payload.weekInfo;
    },
    setPaginationVol: (state, action: PayloadAction<PaginationParams>) => {
      const { pageNumber, pageSize } = action.payload;
      state.pageNumberVolList = pageNumber ?? 1;
      state.pageSizeVolList = pageSize ?? 10;
    },
    setPaginationLkp: (state, action: PayloadAction<PaginationParams>) => {
      const { pageNumber, pageSize } = action.payload;
      state.pageNumberLkpList = pageNumber ?? 1;
      state.pageSizeLkpList = pageSize ?? 10;
    },
    fetchSalesReportVolList: (
      state,
      action: PayloadAction<FetchSalesReport>
    ) => {
      state.fetchSalesReportVolListStatus = 'pending';
      action.payload.pageNumber = state.pageNumberVolList;
      action.payload.pageSize = state.pageSizeVolList;
    },
    fetchSalesReportVolListSuccess: (state, action: PayloadAction<number>) => {
      state.totalCountVolList = action.payload;
      state.fetchSalesReportVolListStatus = 'success';
    },
    fetchWorkSheetVol: (state, _action: PayloadAction<FetchWorkSheet>) => {
      state.fetchWorkSheetVolStatus = 'pending';
    },
    fetchWorkSheetVolSuccess: state => {
      state.fetchWorkSheetVolStatus = 'success';
    },
    fetchWorkSheetVolFailure: state => {
      state.fetchWorkSheetVolStatus = 'failure';
    },
    fetchWorkSheetLkp: (state, _action: PayloadAction<FetchWorkSheet>) => {
      state.fetchWorkSheetLkpStatus = 'pending';
    },
    fetchWorkSheetLkpSuccess: state => {
      state.fetchWorkSheetLkpStatus = 'success';
    },
    fetchWorkSheetLkpFailure: state => {
      state.fetchWorkSheetLkpStatus = 'failure';
    },
    fetchSalesReportVolListFailure: state => {
      state.fetchSalesReportVolListStatus = 'failure';
    },
    fetchSalesReportLkpList: (
      state,
      action: PayloadAction<FetchSalesReport>
    ) => {
      state.fetchSalesReportLkpListStatus = 'pending';
      action.payload.pageNumber = state.pageNumberLkpList;
      action.payload.pageSize = state.pageSizeLkpList;
    },
    fetchSalesReportLkpListSuccess: (state, action: PayloadAction<number>) => {
      state.totalCountLkpList = action.payload;
      state.fetchSalesReportLkpListStatus = 'success';
    },
    fetchSalesReportLkpListFailure: state => {
      state.fetchSalesReportLkpListStatus = 'failure';
    },
    fetchInit: state => {
      state.fetchInitStatus = 'pending';
    },
    fetchSuccessInit: state => {
      state.fetchInitStatus = 'success';
    },
    fetchFailure: state => {
      state.fetchInitStatus = 'failure';
    },
    fetchProducts: (state, _action: PayloadAction<FetchProducts>) => {
      state.fetchProductsStatus = 'pending';
    },
    fetchProductsSuccess: state => {
      state.fetchProductsStatus = 'success';
    },
    fetchProductsFailure: state => {
      state.fetchProductsStatus = 'failure';
    }
  }
});

export const actions = salesReport.actions;
export const reducer = salesReport.reducer;

const caseReducers = salesReport.caseReducers;
export const Types = Object.freeze({
  clear: `${prefix}/${caseReducers.clear.name}`,
  fetchInit: `${prefix}/${caseReducers.fetchInit.name}`,
  fetchSuccessInit: `${prefix}/${caseReducers.fetchSuccessInit.name}`,
  fetchFailure: `${prefix}/${caseReducers.fetchFailure.name}`,
  fetchProducts: `${prefix}/${caseReducers.fetchProducts.name}`,
  fetchProductsSuccess: `${prefix}/${caseReducers.fetchProductsSuccess.name}`,
  fetchProductsFailure: `${prefix}/${caseReducers.fetchProductsFailure.name}`,
  fetchSalesReportVolList: `${prefix}/${caseReducers.fetchSalesReportVolList.name}`,
  fetchSalesReportVolListSuccess: `${prefix}/${caseReducers.fetchSalesReportVolListSuccess.name}`,
  fetchSalesReportVolListFailure: `${prefix}/${caseReducers.fetchSalesReportVolListFailure.name}`,
  fetchSalesReportLkpList: `${prefix}/${caseReducers.fetchSalesReportLkpList.name}`,
  fetchSalesReportLkpListSuccess: `${prefix}/${caseReducers.fetchSalesReportLkpListSuccess.name}`,
  fetchSalesReportLkpListFailure: `${prefix}/${caseReducers.fetchSalesReportLkpListFailure.name}`,
  fetchWorkSheetVol: `${prefix}/${caseReducers.fetchWorkSheetVol.name}`,
  fetchWorkSheetVolSuccess: `${prefix}/${caseReducers.fetchWorkSheetVol.name}`,
  fetchWorkSheetVolFailure: `${prefix}/${caseReducers.fetchWorkSheetVol.name}`,
  fetchWorkSheetLkp: `${prefix}/${caseReducers.fetchWorkSheetLkp.name}`,
  fetchWorkSheetLkpSuccess: `${prefix}/${caseReducers.fetchWorkSheetLkp.name}`,
  fetchWorkSheetLkpFailure: `${prefix}/${caseReducers.fetchWorkSheetLkp.name}`
});

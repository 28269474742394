// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
const INITIAL_STATE = {
  tabs: [],
  selectedTab: 0
};

export default function tabs(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case '@tabs/ADD_TAB': {
      const activeTab = state.tabs.findIndex(
        (value: any) => value.key === action.payload.key
      );
      const newState: any = {
        ...state,
        tabs: [...state.tabs, action.payload],
        selectedTab: null
      };
      newState.selectedTab = newState.tabs.length - 1;
      if (activeTab >= 0) {
        newState.tabs = [...state.tabs];
        newState.selectedTab = activeTab;
      }

      return newState;
    }

    case '@tabs/DELETE_TAB': {
      const newTabs = state.tabs.filter(
        (value: any) => value.key !== action.payload
      );
      let currentTab = state.selectedTab;

      if (newTabs.length >= 1) {
        // seleciona a ultima tab
        currentTab =
          // #FIXME
          // eslint-disable-next-line no-nested-ternary
          currentTab - 1 <= 0
            ? 0
            : currentTab - 1 >= newTabs.length
            ? (currentTab = newTabs.length - 1)
            : currentTab - 1;
      }

      const selectTab = { ...state, tabs: newTabs, selectedTab: currentTab };
      return selectTab;
    }

    case '@tabs/CHANGE_TAB': {
      const newSelectTab = {
        ...state,
        tabs: [...state.tabs],
        selectedTab: action.payload
      };
      return newSelectTab;
    }

    case '@tabs/CLEAR_TAB':
    case '@auth/SIGNIN_FAILURE':
      return { ...state, tabs: [], selectedTab: 0 };

    default:
      return state;
  }
}

import { AxiosResponse } from 'axios';
import FilterCreateGfp from '~/@core/domain/filters/gfp/FilterCreateGfp';
import { GfpService } from '~/@core/infrastructure/api/services';
import IUseCase from '../../IUseCase';

export default class CreateGfp
  implements IUseCase<FilterCreateGfp, Promise<AxiosResponse<void>>>
{
  execute(params: FilterCreateGfp): Promise<AxiosResponse<void>> {
    return GfpService.createGfp(params);
  }
}

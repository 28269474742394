import { createSelector } from 'reselect';

import { AutoserviceDefinitionsState } from './index';

export const getRoot = (state: any) => {
  return state.autoserviceDefinitions as AutoserviceDefinitionsState;
};

export const getDefinitions = createSelector(
  [getRoot],
  state => state.definitions
);

export const getReloadPageData = createSelector(
  [getRoot],
  state => state.reloadPageData
);

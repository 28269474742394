/* eslint-disable no-shadow */
export enum OrderManagementTabEnum {
  order = 'tab-order',
  unpublished = 'tab-unpublished',
  settings = 'tab-settings',
  pendingOrdersReview = 'tab-pendingOrdersReview'
}

export enum OrderManagementStatusEnum {
  CONFIRMED = 'C',
  PARTIAL = 'P',
  CONFIRMED_WITH_CHANGES = 'F',
  VERIFYING_OFFERS = 'O',
  ORDER_OFFERS = 'Q',
  WAITING_ORDER_GENERATION = 'A',
  WAITING_PRODUCER = 'W',
  WAITING_INTERMEDIATOR = 'T',
  REFUSED = 'R',
  CANCELED = 'I',
  ERRO = 'X',
  EXPIRED = 'E',
  VALID_LINE = 'V',
  INVALID_LINE = 'U'
}

export enum OrderManagementStatusIdEnum {
  SUCCESS = 1,
  ERROR = 2
}

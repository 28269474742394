import { AxiosError, AxiosResponse } from 'axios';
import api from '../../config/index';
import { PrintRule } from '~/@core/domain/model/tag';

export const getDefaultPrintRule = (siteId: number) => {
  return new Promise<PrintRule>((resolve, reject) => {
    api
      .get<void, AxiosResponse<PrintRule>>(
        `/tag/printing-default-rule/${siteId}`
      )
      .then((response: AxiosResponse<PrintRule>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

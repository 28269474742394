import { SalesReportState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.core.salesReport as SalesReportState;
};

export const getSalesReportVolList = createSelector([getRoot], state => {
  return state.salesReportVolList;
});

export const getSalesReportLkpList = createSelector([getRoot], state => {
  return state.salesReportLkpList;
});

export const getLkpIsCleared = createSelector(
  [getRoot],
  state => state.statusSalesReportLkpList === 'cleared'
);

export const getVolIsCleared = createSelector(
  [getRoot],
  state => state.statusSalesReportVolList === 'cleared'
);

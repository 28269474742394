import styled from 'styled-components';
import { darken, lighten } from 'polished';
import { Form, Button, Collapse, Tabs, Modal, Checkbox, Table } from 'antd';
import ProTable from '@ant-design/pro-table';

const colorPrimaryBackground = '#F78E1E';
const colorPrimaryBorder = '#F78E1E';
const colorPrimaryInside = '#fff';

const colorDefaultBackground = '#fff';
const colorDefaultBorder = '#D9D9D9';
const colorDefaultInside = '#262626';

const colorGhostBackground = '#fff';
const colorGhostBorder = '#F78E1E';
const colorGhostInside = '#F78E1E';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  min-height: 100%;
`;

export const Content = styled.div`
  display: flex;
  overflow: hidden;
  flex: 1;
  flex-direction: column;
  padding-bottom: 24px;
`;

export const PageBody = styled.div`
  flex: 1;
  overflow: hidden;
  background-color: #fff;
  padding-bottom: 28px;

  &.card-border {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 16px;
  }
`;

export const Card = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 12px;

  &.card-padding {
    padding: 16px;
  }
`;

export const TabsContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  background-color: #fbfcfe;
  margin: 0;
  padding: 12px 24px 0px 24px;
  border-bottom: 1px solid #d9d9d9;
`;

export const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  height: 34px;
  margin: 0;
  margin-left: 12px;
  padding: 12px;
  padding-bottom: 8px;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0 11px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1);

  &:first-child {
    margin-left: 0;
  }

  &.active {
    border-bottom: 2px solid #f78e1e;
  }

  a {
    text-align: center;
    font: 14px 'Roboto', sans-serif;
    color: #8c8c8c;
  }

  &.active,
  &.active a {
    color: #f78e1e;
  }
`;

export const Title = styled.span`
  padding: 8px;
  padding-left: 0;
  font: 16px 'Roboto', sans-serif;
  font-weight: 400;
`;

export const FormButton = styled(Button)`
  width: 32px;
  height: 32px;
  padding: 6px;
  margin-left: 8px;

  &:first-child {
    margin-left: 0;
  }
`;

export const FormContainer = styled(Form)`
  width: 100%;
`;

export const FormItem = styled(Form.Item)`
  .ant-form-item-label {
    padding-bottom: 0;
    margin-bottom: -4px;
  }

  label {
    font: 12px 'Roboto', sans-serif;
    color: #353839;
  }
`;

export const Frame = styled(Collapse)`
  font: 16px 'Roboto', sans-serif;
  font-weight: 400;
`;

export const TabGuideStyle = {
  backgroundColor: '#FBFCFE',
  marginLeft: 8
};

export const TabContainer = styled(Tabs)`
  .ant-form-item-label {
    padding-bottom: 2px;
  }

  .ant-tabs-tab {
    background-color: #fff;

    span[role='img'] {
      margin-right: 0 !important;
    }

    svg {
      color: #bfbfbf;
      font-size: 12px;
    }
  }

  label {
    font: 12px 'Roboto', sans-serif;
    color: #353839;
  }
  &.tab-padding {
    margin-top: 8px;
  }
`;

export const TabContainerForm = styled(Tabs)`
  .ant-form-item-label {
    padding-bottom: 2px;
  }
  .ant-tabs-tab {
    border: none;
    box-shadow: none;
  }
  label {
    font: 12px 'Roboto', sans-serif;
    color: #353839;
  }

  .ant-tabs-nav {
    margin-bottom: 4px;

    &::before {
      position: absolute;
      right: 0;
      left: 0;
      top: 100%;
      border-bottom: 1px solid #d9d9d9;
      content: '';
    }
  }

  .tab-default-content {
    margin-left: -10px;
    margin-right: -10px;
  }

  .ant-tabs-content {
    width: calc(100% + 20px);
  }
`;

export const TableDefault = styled(Table)`
  background: #d9d9d9;
  margin: 0;
  padding: 0;

  .ant-pagination-total-text {
    display: none !important;
  }

  .ant-table-content {
    overflow: hidden;
    overflow-x: auto;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background: #f5f5f5;
    }

    &::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
    }
  }

  table {
    margin-bottom: 1px;
  }

  th,
  td {
    font: 12px 'Roboto', sans-serif !important;
    white-space: nowrap;
    text-align: left !important;
    padding: 12px !important;
    color: #353839 !important;

    span {
      text-align: left !important;
    }

    &::before {
      top: 0 !important;
      height: 100% !important;
    }
  }

  th {
    background: #f5f5f5;
    font-weight: bold !important;

    span {
      text-align: left !important;
    }
  }

  td {
    background: #fff;
  }
`;

export const TableDefaultPro = styled(ProTable)`
  background: #d9d9d9;
  margin: 0 -15px;
  padding: 0;

  .ant-pagination-total-text {
    display: none !important;
  }

  .ant-table-content {
    overflow: hidden;
    overflow-x: auto;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background: #f5f5f5;
    }

    &::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
    }
  }

  table {
    border: none;
    margin-bottom: 1px;
  }

  th,
  td {
    white-space: nowrap;
    text-align: left !important;
    padding: 12px !important;
    color: #353839 !important;
    font: 12px 'Roboto', sans-serif !important;

    span {
      text-align: left !important;
    }

    &::before {
      top: 0 !important;
      height: 100% !important;
    }
  }

  th {
    background: #f5f5f5;
    font-weight: bold !important;
  }

  td {
    background: #fff;
  }

  .ant-pro-table-list-toolbar-container {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
  }
`;

export const ButtonInput = styled(Button)`
  width: 32px;
  height: 32px;
  padding: 3px;
  margin-left: 8px;
  background-color: ${props =>
    props.ghost
      ? colorGhostBackground
      : props.type === 'primary'
      ? colorPrimaryBackground
      : colorDefaultBackground};
  border-color: ${props =>
    props.ghost
      ? colorGhostBorder
      : props.type === 'primary'
      ? colorPrimaryBorder
      : colorDefaultBorder};

  svg {
    color: ${props =>
      props.ghost
        ? colorGhostInside
        : props.type === 'primary'
        ? colorPrimaryInside
        : colorDefaultInside};
    font-size: 12px;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: ${props =>
      props.ghost
        ? lighten(0.05, colorGhostBackground)
        : props.type === 'primary'
        ? lighten(0.05, colorPrimaryBackground)
        : darken(0.05, colorDefaultBackground)};
    border-color: ${props =>
      props.ghost
        ? colorGhostBorder
        : props.type === 'primary'
        ? colorPrimaryBorder
        : colorDefaultBorder};
  }

  &:first-child {
    margin-left: 0;
  }

  &.modal-button {
    width: auto;
    margin-top: 18px;
    padding: 5px 32px 5px 32px;
    font: 14px 'Roboto', sans-serif !important;
    font-style: normal;
    font-weight: normal;
    line-height: 22px;
  }
`;

export const CheckDefault = styled(Checkbox)``;

export const ModalDefault = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-modal-body {
    padding: 33px;
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-table-customized {
    margin-left: 38px;

    th,
    td {
      white-space: nowrap;
      text-align: left !important;
      padding: 17px !important;
      color: #353839 !important;
      font: 14px 'Roboto', sans-serif !important;

      span {
        text-align: left !important;
      }

      &::before {
        height: 100% !important;
      }
    }

    th {
      background: #f5f5f5;
      font-weight: normal !important;
      padding: 17px 14px 17px 14px !important;
    }

    td {
      background: #fff;
    }
  }
`;

export const TabsStyle = {
  padding: '12px 24px 0px 24px'
};

export const TabPaneStyle = {
  height: 34,
  margin: 0,
  padding: 12,
  paddingBottom: 8,
  borderRadius: '8px 8px 0px 0px',
  boxShadow: '0 11px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1)'
};

export const RedCircle = styled.div`
  border-radius: 100%;
  background-color: #fa541c;
  width: 16px;
  height: 16px;
`;

export const BlueCircle = styled.div`
  border-radius: 100%;
  background-color: #13c2c2;
  width: 16px;
  height: 16px;
`;

export const GreenCircle = styled.div`
  border-radius: 100%;
  background-color: #52c41a;
  width: 16px;
  height: 16px;
`;

export const SubTable = styled.span`
  font-size: 12px;
`;

export const TabWrapper = styled.div`
  padding: 12px 16px;
  width: 100%;
  margin-top: 15px;
`;

import { Col, Form, FormInstance, InputNumber, Select } from 'antd';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomerSelect from '~/components/atoms/Inputs/CustomerSelect';
import ExpeditionSelect from '~/components/atoms/Inputs/ExpeditionSelect';
import QualitiesSelect from '~/components/atoms/Inputs/QualitiesSelect';
import ProductFilters from '~/components/molecules/Inputs/ProductFilters';
import { globalContext } from '~/context/GlobalContext';

interface Props {
  form: FormInstance<any>;
}

export default function HideFilters(props: Props) {
  const { form } = props;
  // #FIXME
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
  const { t } = useTranslation();
  const { siteIdSelected } = useContext(globalContext);

  // #FIXME
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
  const [orderNumber, setOrderNumber] = useState<number>(Number.NaN);

  const handleChangeOrder = useCallback((orderValue: any) => {
    if (orderValue > 0) setOrderNumber(orderValue);
  }, []);

  const preventMinus = (event: any) => {
    if (event.code === 'Minus') {
      event.preventDefault();
    }
  };

  useEffect(() => {
    setOrderNumber(Number.NaN);
    form.resetFields(['OrderNumber']);
  }, [form, siteIdSelected]);

  return (
    <>
      <ProductFilters
        form={form}
        siteId={siteIdSelected}
        lgCategory={4}
        lgGroup={4}
        lgProduct={8}
        lgRecipient={4}
        lgColor={4}
      />

      <QualitiesSelect form={form} siteId={siteIdSelected} lgQuality={3} />

      <ExpeditionSelect form={form} siteId={siteIdSelected} lgExpedition={3} />

      <Col lg={3}>
        <Form.Item name="division" label="Tipo:">
          <Select allowClear>
            <Select.Option key="K" value="K">
              KLOK
            </Select.Option>
            <Select.Option key="I" value="I">
              Intermediação
            </Select.Option>
          </Select>
        </Form.Item>
      </Col>

      <Col lg={3}>
        <Form.Item name="status" label="Status:">
          <Select allowClear>
            <Select.Option key="A" value="A">
              Aberta
            </Select.Option>
            <Select.Option key="F" value="F">
              Fechada
            </Select.Option>
            <Select.Option key="C" value="C">
              Cancelada
            </Select.Option>
            <Select.Option key="R" value="R">
              Recepcionada
            </Select.Option>
          </Select>
        </Form.Item>
      </Col>

      <CustomerSelect form={form} siteId={siteIdSelected} lgCustomer={5} />

      <Col lg={3}>
        <Form.Item name="gfpNumber" label="N° GFP:">
          <InputNumber
            controls={false}
            onKeyPress={preventMinus}
            onChange={value => {
              handleChangeOrder(value);
            }}
          />
        </Form.Item>
      </Col>

      <Col lg={3}>
        <Form.Item name="printStatus" label="Status Impressão:">
          <Select allowClear>
            <Select.Option key="1" value="1">
              Impresso
            </Select.Option>
            <Select.Option key="2" value="2">
              Não Impresso
            </Select.Option>
          </Select>
        </Form.Item>
      </Col>
    </>
  );
}

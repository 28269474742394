import produce from 'immer';
import { createCreator, createReducer, Reducer } from '~/helpers/util/reducers';

export type FetchStatus = 'idle' | 'fetching' | 'success' | 'failure';
export type RemoveStatus =
  | 'idle'
  | 'removing'
  | 'success'
  | 'failure'
  | 'failure-has-events'
  | 'failure-invalid-ids';

export type CompetenceListState = {
  fetchStatus: FetchStatus;
  removeStatus: RemoveStatus;
  pageNumber: number;
  pageSize: number;
  totalCount: number;
  invalidIDs?: number[];
  iDsWithEvents?: number[];
};

export type Remove = {
  competenceIDs: number[];
};

export type FetchPage = {
  nextPage: number;
  referenceYear?: number | null;
  nextPageSize: number;
};

export type FetchPageSuccess = {
  nextPage: number;
  nextPageSize: number;
  totalCount: number;
};

export type RemoveFailure = {
  status: RemoveStatus;
  invalidIDs?: number[];
  iDsWithEvents?: number[];
};

const prefix = '@competenceList';

export const Types = Object.freeze({
  CLEAR: `${prefix}/CLEAR`,
  FETCH_PAGE: `${prefix}/FETCH_PAGE`,
  FETCH_PAGE_SUCCESS: `${prefix}/FETCH_PAGE_SUCCESS`,
  FETCH_PAGE_FAILURE: `${prefix}/FETCH_PAGE_FAILURE`,
  UPDATE: `${prefix}/UPDATE`,
  CLEAR_REMOVE: `${prefix}/CLEAR_REMOVE`,
  REMOVE: `${prefix}/REMOVE`,
  REMOVE_SUCCESS: `${prefix}/REMOVE_SUCCESS`,
  REMOVE_FAILURE: `${prefix}/REMOVE_FAILURE`
});

export const initialState: CompetenceListState = {
  fetchStatus: 'idle',
  removeStatus: 'idle',
  pageNumber: 1,
  pageSize: 10,
  totalCount: 0,
  invalidIDs: [],
  iDsWithEvents: []
};

const clearReducer: Reducer<CompetenceListState, void> = (state, _action) => {
  return produce(state, _draft => {
    _draft = initialState;
  });
};

const fetchPageReducer: Reducer<CompetenceListState, void> = state => {
  return produce(state, draft => {
    draft.fetchStatus = 'fetching';
  });
};

const fetchPageSuccessReducer: Reducer<
  CompetenceListState,
  FetchPageSuccess
> = (state, action) => {
  return produce(state, draft => {
    const { nextPage, nextPageSize, totalCount } = action;
    draft.fetchStatus = 'success';
    draft.pageNumber = nextPage;
    draft.pageSize = nextPageSize;
    draft.totalCount = totalCount;
  });
};

const fetchPageFailureReducer: Reducer<CompetenceListState, void> = state => {
  return produce(state, draft => {
    draft.fetchStatus = 'failure';
  });
};

const clearRemoveReducer: Reducer<CompetenceListState, void> = state => {
  return produce(state, draft => {
    draft.removeStatus = 'idle';
  });
};

const removeReducer: Reducer<CompetenceListState, void> = state => {
  return produce(state, draft => {
    draft.removeStatus = 'removing';
  });
};

const removeSuccessReducer: Reducer<CompetenceListState, void> = state => {
  return produce(state, draft => {
    draft.removeStatus = 'success';
  });
};

const removeFailureReducer: Reducer<CompetenceListState, RemoveFailure> = (
  state,
  action
) => {
  return produce(state, draft => {
    draft.removeStatus = action.status;
    draft.invalidIDs = action.invalidIDs;
    draft.iDsWithEvents = action.iDsWithEvents;
  });
};

export const actions = Object.freeze({
  clear: createCreator<void>(Types.CLEAR),
  fetchPage: createCreator<FetchPage>(Types.FETCH_PAGE),
  fetchPageSuccess: createCreator<FetchPageSuccess>(Types.FETCH_PAGE_SUCCESS),
  fetchPageFailure: createCreator<void>(Types.FETCH_PAGE_FAILURE),
  clearRemove: createCreator<void>(Types.CLEAR_REMOVE),
  remove: createCreator<Remove>(Types.REMOVE),
  removeSuccess: createCreator<void>(Types.REMOVE_SUCCESS),
  removeFailure: createCreator<RemoveFailure>(Types.REMOVE_FAILURE)
});

export const reducer = createReducer(initialState, {
  [Types.CLEAR]: clearReducer,
  [Types.FETCH_PAGE]: fetchPageReducer,
  [Types.FETCH_PAGE_SUCCESS]: fetchPageSuccessReducer,
  [Types.FETCH_PAGE_FAILURE]: fetchPageFailureReducer,
  [Types.CLEAR_REMOVE]: clearRemoveReducer,
  [Types.REMOVE]: removeReducer,
  [Types.REMOVE_SUCCESS]: removeSuccessReducer,
  [Types.REMOVE_FAILURE]: removeFailureReducer
});

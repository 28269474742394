import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VolOffer } from '~/domain/model';

export type Status = 'cleared' | 'updated';

export type VolOffersState = {
  volOffers: VolOffer[] | any;
  status: Status;
  disabledSave: boolean;
};

export type Update = {
  volOffers: VolOffer[];
};

export type SetPropertyAction = {
  value: any;
  property: string;
  index: number;
};
export type SetVoucher = {
  index: number;
  voucherId: number;
  checked: boolean;
};
export type SetVouchers = {
  index: number;
  voucherIds: number[];
  checked: boolean;
};
export type SetComposition = {
  index: number;
  compositionId: number;
  checked: boolean;
};

const prefix = '@volOffers';

export const initialState: VolOffersState = {
  volOffers: [] as VolOffer[],
  status: 'cleared',
  disabledSave: true
};

const volOffers = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: state => {
      state.volOffers = initialState.volOffers;
      state.status = initialState.status;
      state.disabledSave = true;
    },
    update: (state, action: PayloadAction<Update>) => {
      state.volOffers = action.payload.volOffers;
      state.disabledSave = true;
    },
    setPropertyValue: (state, action: PayloadAction<SetPropertyAction>) => {
      const { payload } = action;
      state.volOffers[payload.index][payload.property] = payload.value;
      state.volOffers[payload.index].toSend = true;
      state.disabledSave = false;
    },
    setVoucher: (state, action: PayloadAction<SetVoucher>) => {
      const { payload } = action;
      if (!payload.checked) {
        return {
          ...state,
          disabledSave: false,
          volOffers: state.volOffers.map(
            (volOffer: VolOffer, index: number) => {
              if (index === payload.index) {
                const voucherIds = [...volOffer.voucherIds];
                voucherIds.forEach(
                  (voucherId: number, indexVoucher: number) => {
                    if (voucherId === payload.voucherId) {
                      voucherIds.splice(indexVoucher, 1);
                    }
                  }
                );
                return { ...volOffer, voucherIds, toSend: true };
              }
              return volOffer;
            }
          )
        };
      }
      return {
        ...state,
        disabledSave: false,

        volOffers: state.volOffers.map((volOffer: VolOffer, index: number) => {
          if (index === payload.index) {
            const voucherIds = [...volOffer.voucherIds];
            voucherIds.push(payload.voucherId);
            return { ...volOffer, voucherIds, toSend: true };
          }
          return volOffer;
        })
      };
    },
    setVouchers: (state, action: PayloadAction<SetVouchers>) => {
      const { payload } = action;
      return {
        ...state,
        disabledSave: false,
        volOffers: state.volOffers.map((volOffer: VolOffer, index: number) => {
          if (index === payload.index) {
            return {
              ...volOffer,
              voucherIds: payload.voucherIds,
              toSend: true
            };
          }
          return volOffer;
        })
      };
    },
    clearVouchers: (state, action: PayloadAction<number>) => {
      const offerIndex = action.payload;
      return {
        ...state,
        volOffers: state.volOffers.map((volOffer: VolOffer) => {
          if (offerIndex === volOffer.key) {
            return { ...volOffer, voucherIds: [] };
          }
          return { ...volOffer };
        })
      };
    },
    setToEdit: (state, action: PayloadAction<number>) => {
      const offerIndex = action.payload;
      state.volOffers[offerIndex].toSend = true;
      return state;
    },
    setCompositions: (state, action: PayloadAction<SetComposition>) => {
      const { payload } = action;
      if (!payload.checked) {
        return {
          ...state,
          disabledSave: false,
          volOffers: state.volOffers.map(
            (volOffer: VolOffer, index: number) => {
              if (index === payload.index) {
                const packingDistributionCompositionIds = [
                  ...volOffer.packingDistributionCompositionIds
                ];
                const packings = packingDistributionCompositionIds.filter(
                  (compositionId: number, _indexComposition: number) => {
                    if (compositionId === payload.compositionId) {
                      return false;
                    }
                    return true;
                  }
                );
                return {
                  ...volOffer,
                  packingDistributionCompositionIds: packings,
                  toSend: true
                };
              }
              return volOffer;
            }
          )
        };
      }
      return {
        ...state,
        disabledSave: false,
        volOffers: state.volOffers.map((volOffer: VolOffer, index: number) => {
          if (index === payload.index) {
            const packingDistributionCompositionIds = [
              ...volOffer.packingDistributionCompositionIds
            ];
            packingDistributionCompositionIds.push(payload.compositionId);
            return {
              ...volOffer,
              packingDistributionCompositionIds,
              toSend: true
            };
          }
          return volOffer;
        })
      };
    }
  }
});
export const actions = volOffers.actions;
export const reducer = volOffers.reducer;

const caseReducers = volOffers.caseReducers;
export const Types = Object.freeze({
  clear: `${prefix}/${caseReducers.clear.name}`,
  update: `${prefix}/${caseReducers.update.name}`
});

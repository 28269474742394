import { ShoppingCartOutlined } from '@ant-design/icons';
import { Badge, FormInstance } from 'antd';
import { useContext } from 'react';
import ModalAssembleGFP from '~/components/molecules/GFP/Modals/AssembleGFP';
import { GFPContext } from '~/context/GFPContext';
import { GFPCartAssemble } from './styles';

interface Props {
  listOrdersForm: FormInstance;
}

export default function AssembleGFP(props: Props) {
  const form = props;
  const { showAddGFPModal, setShowAddGFPModal, itemsCount } =
    useContext(GFPContext);

  return (
    <>
      <GFPCartAssemble onClick={() => setShowAddGFPModal(!showAddGFPModal)}>
        <Badge count={itemsCount} offset={[20, -5]}>
          <p style={{ color: 'white', margin: 0 }}>
            <ShoppingCartOutlined /> Montar GFPs
          </p>
        </Badge>
      </GFPCartAssemble>
      <ModalAssembleGFP
        isModalVisible={showAddGFPModal}
        setIsModalVisible={setShowAddGFPModal}
        listOrdersForm={form.listOrdersForm}
      />
    </>
  );
}

import IUseCase from '../../../IUseCase';
import { ProductService } from '~/@core/infrastructure/api/services';
import ProductCategory from '~/@core/domain/model/product/ProductCategory';

export default class GetProductsCategories
  implements IUseCase<number, Promise<ProductCategory[]>>
{
  execute(siteId: number): Promise<ProductCategory[]> {
    return ProductService.fetchProductCategories(siteId);
  }
}

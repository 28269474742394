import { takeLatest, call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  DeliveryPatternEnable,
  Types,
  actions,
  FetchDeliveryPatterns
} from './duck';
import * as DeliveryPatternService from '../../_core/deliveryPattern/service';
import { alertRequest } from '../../alert/actions';
import { actions as actionsCore } from '../../_core/deliveryPattern';

export function* fetchBySiteId(action: PayloadAction<FetchDeliveryPatterns>) {
  try {
    yield put(actionsCore.clear());
    yield call(DeliveryPatternService.findManyBySiteId, action.payload);
    yield put(actions.fetchSuccess());
  } catch (error) {
    yield put(actions.fetchFailure());
  }
}
export function* deletePattern(action: PayloadAction<DeliveryPatternEnable>) {
  try {
    yield call(DeliveryPatternService.deletePattern, action.payload);
    if (action.payload.enabled) {
      yield put(
        alertRequest('Esquema de entrega foi Ativado com sucesso!', 'success')
      );
    } else {
      yield put(
        alertRequest('Esquema de entrega Inativado com sucesso!', 'success')
      );
    }
    yield put(actions.fetchSuccess());
    yield put(
      actions.fetchBySiteId({
        siteId: action.payload.siteId,
        enabled: !action.payload.enabled
      })
    );
  } catch (error: any) {
    const message = JSON.stringify(error.response.data.Errors.Messages);
    yield put(alertRequest(message, 'error'));
    yield put(actions.fetchFailure());
  }
}
export function* favoritePattern(action: PayloadAction<number>): any {
  try {
    yield call(DeliveryPatternService.favoritePattern, action.payload);
    yield put(alertRequest('Alterado com sucesso!', 'success'));
    yield put(actions.fetchSuccess());
    yield put(actions.setSubmitForm(true));
  } catch (error: any) {
    yield put(actions.fetchFailure());
    const message = JSON.stringify(error.response.data.errors.messages);
    yield put(alertRequest(message, 'error'));
  }
}

export default [
  takeLatest(Types.FETCH_BY_SITE_ID, fetchBySiteId),
  takeLatest(Types.DELETE, deletePattern),
  takeLatest(Types.FAVORITE, favoritePattern)
];

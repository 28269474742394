import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Customer } from '~/domain/model';
import { fetchMeList } from './apiCalls';

export interface MeState {
  meList: Customer[];
}

const initialState: MeState = {
  meList: []
};

export const prefix = 'me';

const meSlice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setMeList: (state, action: PayloadAction<Customer[]>) => {
      state.meList = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(
      fetchMeList.fulfilled,
      (state, action: PayloadAction<Customer[]>) => {
        state.meList = action.payload;
      }
    );
  }
});

export const actions = meSlice.actions;
export const reducer = meSlice.reducer;

import { call, fork, put, takeLatest } from 'redux-saga/effects';

import { actions, FetchHistory, FetchOfferSettings, Types } from './duck';
import * as OfferSettingsService from '~/store/modules/_core/offerSettings/service';
import * as SiteService from '~/store/modules/_core/sites/service';
import * as ProductCategoryService from '~/store/modules/_core/productCategories/service';
import * as API from '~/domain/api';
import { Action } from '~/helpers/util/reducers';
import { Upsert } from '../../_core/offerSettings/duck';

export function* fetchInitSaga(): any {
  try {
    yield call(SiteService.findMany);
    yield call(ProductCategoryService.findOfferSettingsCategories);
    yield put(actions.fetchSuccess());
  } catch (err) {
    yield put(actions.fetchFailure());
  }
}

export function* watchFetchOfferSettingsSaga(
  action: Action<FetchOfferSettings>
) {
  yield fork(fetchOfferSettingsSaga, action.payload.siteId);
}

export function* fetchOfferSettingsSaga(siteId: number): any {
  try {
    yield call(OfferSettingsService.fetchBySiteId, siteId);
    yield put(actions.fetchOfferSettingsSuccess());
  } catch (err) {
    yield put(actions.fetchOfferSettingsFailure());
  }
}

export function* watchUpsertSaga(action: Action<Upsert>) {
  yield fork(upsertSaga, action.payload);
}

export function* upsertSaga(param: Upsert): any {
  try {
    yield call(OfferSettingsService.upsert, param);
    yield put(actions.upsertSuccess());
  } catch (err: any) {
    yield put(actions.upsertFailure({ errorMessage: err.message }));
  }
}

export function* watchFetchCountingPatternHistory(
  action: Action<FetchHistory>
) {
  yield fork(fetchCountingPatternHistoryBySiteId, action.payload.siteId);
}

export function* fetchCountingPatternHistoryBySiteId(siteId: number): any {
  try {
    const countingPatternHistory: API.CountingPatternHistory[] = yield call(
      OfferSettingsService.fetchCountingPatternHistoryBySiteId,
      siteId
    );
    yield put(
      actions.fetchCountingPatternHistorySuccess({ countingPatternHistory })
    );
  } catch (err) {
    yield put(actions.fetchCountingPatternHistoryFailure());
  }
}

export function* watchFetchCompositionHistory(action: Action<FetchHistory>) {
  yield fork(fetchCompositionHistoryBySiteId, action.payload.siteId);
}

export function* fetchCompositionHistoryBySiteId(siteId: number): any {
  try {
    const compositionHistory: API.CompositionHistory[] = yield call(
      OfferSettingsService.fetchCompositionHistoryBySiteId,
      siteId
    );
    yield put(actions.fetchCompositionHistorySuccess({ compositionHistory }));
  } catch (err) {
    yield put(actions.fetchCompositionHistoryFailure());
  }
}

export function* watchFetchMinimumAmountCutProductHistory(
  action: Action<FetchHistory>
) {
  yield fork(
    fetchMinimumAmountCutProductHistoryBySiteId,
    action.payload.siteId
  );
}

export function* fetchMinimumAmountCutProductHistoryBySiteId(
  siteId: number
): any {
  try {
    const minimumAmountCutProductHistory: API.MinimumAmountCutProductHistory[] =
      yield call(
        OfferSettingsService.fetchMinimumAmountCutProductHistoryBySiteId,
        siteId
      );
    yield put(
      actions.fetchMinimumAmountCutProductHistorySuccess({
        minimumAmountCutProductHistory
      })
    );
  } catch (err) {
    yield put(actions.fetchMinimumAmountCutProductHistoryFailure());
  }
}

export default [
  takeLatest(Types.FETCH_INIT, fetchInitSaga),
  takeLatest(Types.FETCH_OFFER_SETTINGS, watchFetchOfferSettingsSaga),
  takeLatest(Types.UPSERT_INIT, watchUpsertSaga),
  takeLatest(
    Types.FETCH_COUNTING_PATTERN_HISTORY,
    watchFetchCountingPatternHistory
  ),
  takeLatest(Types.FETCH_COMPOSITION_HISTORY, watchFetchCompositionHistory),
  takeLatest(
    Types.FETCH_MINIMUM_AMOUNT_CUT_PRODUCT_HISTORY,
    watchFetchMinimumAmountCutProductHistory
  )
];

import { AxiosError, AxiosResponse } from 'axios';
import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';
import FilterOrder from '~/@core/domain/filters/order/FilterOrder';
import IFilterOrderNumber from '~/@core/domain/filters/order/IFilterOrderNumber';
import NatureOperation from '~/@core/domain/model/order/NatureOperation';
import Order from '~/@core/domain/model/order/Order';
import OrderNumber from '~/@core/domain/model/order/OrderNumber';
import QualityObservations from '~/@core/domain/model/order/QualityObservations';
import api, { apiGfp } from '../config/index';

export const fetchOrders = (params: FilterOrder) => {
  return new Promise<DefaultPaginated<Order[]>>((resolve, reject) => {
    apiGfp
      .get<void, AxiosResponse<DefaultPaginated<Order[]>>>('/gfp/orders', {
        params
      })
      .then((response: AxiosResponse<DefaultPaginated<Order[]>>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const fetchQualityObservations = () => {
  return new Promise<QualityObservations[]>((resolve, reject) => {
    api
      .get<void, AxiosResponse<QualityObservations[]>>(
        '/order/quality-observation'
      )
      .then((response: AxiosResponse<QualityObservations[]>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const fetchOrdersNumber = (params: IFilterOrderNumber) => {
  return new Promise<OrderNumber[]>((resolve, reject) => {
    api
      .get<void, AxiosResponse<OrderNumber[]>>('/order/order-number', {
        params
      })
      .then((response: AxiosResponse<OrderNumber[]>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const fetchNatureOperations = () => {
  return new Promise<NatureOperation[]>((resolve, reject) => {
    api
      .get<void, AxiosResponse<NatureOperation[]>>('/order/op-nature')
      .then((response: AxiosResponse<NatureOperation[]>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import * as SalesReportServices from '~/services/producer/SalesReportEcommerce';
import { FetchXMLdownload } from '~/domain/model/SalesReportEcommerce';
import { handleDefaultErrorApi } from '~/helpers/util';

export const fetchPreferenceColor = createAsyncThunk(
  `salesReportEcommerce/fetchPreferenceColor`,
  async () => {
    const response = await SalesReportServices.findPreferenceColor();
    return response;
  }
);

export const fetchProductsReport = createAsyncThunk(
  `salesReportEcommerce/fetchProductsReport`,
  async (params: any) => {
    const data = await SalesReportServices.findProductsReport(params);
    return data;
  }
);

export const fetchOverview = createAsyncThunk(
  `salesReportEcommerce/fetchOverview`,
  async (params: any) => {
    const data = await SalesReportServices.findOverview(params);
    return data;
  }
);

export const exportPurchases = createAsyncThunk(
  'salesReportEcommerce/exportPurchases',
  async (fetchListParams: FetchXMLdownload, { rejectWithValue }) => {
    try {
      const response = await SalesReportServices.onExport(fetchListParams);
      const data: string = response?.data?.data;
      const binaryString = window.atob(data);
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      const fileData = bytes;

      const blob = new Blob([fileData], { type: 'application/octet-stream' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.style.display = 'none';
      const fileName = 'relatorio_de_vendas.xlsx';
      link.download = fileName;
      link.click();
      return true;
    } catch (error) {
      handleDefaultErrorApi(error as AxiosError);
      return rejectWithValue([]);
    }
  }
);

import { Space } from 'antd';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddButton from '~/components/Buttons/AddButton';
import BackButton from '~/components/Buttons/BackButton';
import HelpButton from '~/components/Buttons/HelpButton';
import { GFPContext } from '~/context/GFPContext';
import ModalAddKlokGFP from './Modals/AddKlokGFP';
import ModalHelpGFP from './Modals/HelpGFP';

export default function TabExtraButtons() {
  const { t } = useTranslation();
  const [showHelp, setShowHelp] = useState<boolean>(false);
  const [showAddKlok, setShowAddKlok] = useState<boolean>(false);

  const { activeKey, setActiveKey } = useContext(GFPContext);

  return (
    <Space style={{ marginBottom: 8 }}>
      {activeKey === 'addGfp' ? (
        <AddButton
          tooltip="Adicionar GFP"
          type="default"
          onClick={() => setShowAddKlok(!showAddKlok)}
        >
          {t('gfp.modal.addGfp.title')}
        </AddButton>
      ) : (
        <BackButton onClick={() => setActiveKey('addGfp')} />
      )}

      <HelpButton
        type="default"
        onClick={() => setShowHelp(true)}
        title="Ajuda"
      />
      <ModalHelpGFP isModalVisible={showHelp} setIsModalVisible={setShowHelp} />
      <ModalAddKlokGFP
        isModalVisible={showAddKlok}
        setIsModalVisible={setShowAddKlok}
      />
    </Space>
  );
}

import styled from 'styled-components';

interface Props {
  sizeGroup: string;
  timeToGroup: string;
}

export const Announcement = styled.div`
  position: fixed;
  background-color: white;
  bottom: 0;
  background-color: #fff;
  height: 34px;
  z-index: 99;
  width: 100%;
  display: flex;
  overflow-x: hidden;
  flex-direction: row;
`;

export const LastAnnouncement = styled.div`
  background-color: #faad14;
  color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  z-index: 1;
  min-width: 200px;
`;

export const AnnouncementGroup = styled.div.attrs((props: Props) => ({
  sizeGroup: props.sizeGroup,
  timeToGroup: props.timeToGroup
}))`
  overflow-x: hidden;
  animation: title ${props => props.timeToGroup} infinite linear;
  min-width: ${props => props.sizeGroup};
  display: flex;
  align-items: center;
  @keyframes title {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  &:hover {
    animation-play-state: paused;
  }
`;
export const AnnouncementList = styled.div`
  overflow-x: hidden;
  display: flex;
  > * {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 50px;
  }
`;

export const AnnouncementTitle = styled.span`
  min-width: fit-content;
  cursor: pointer;
  color: #f78e1e;

  &:hover {
    text-decoration: underline;
  }
`;

import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '~/services/api';

import { partnershipListSuccess, partnershipListFailure } from './actions';

export function* onList({ payload }: any): any {
  try {
    const { siteId } = payload;

    const response = yield call(api.get, `/partnership/${siteId}`);

    const { data } = response;

    yield put(partnershipListSuccess(data));
  } catch (err) {
    yield put(partnershipListFailure());
  }
}

export default all([takeLatest('@partnership/LIST_REQUEST', onList)]);

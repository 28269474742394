import { AnnouncementCreateState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.ui.announcementCreate as AnnouncementCreateState;
};

export const getCreateStatus = createSelector([getRoot], state => {
  return state.createStatus;
});

export const getErrorMessage = createSelector([getRoot], state => {
  return state.errorMessage;
});

export const getIsLoading = createSelector(
  [getRoot],
  state =>
    state.createStatus === 'pending' ||
    state.getByIdStatus === 'pending' ||
    state.getFilesByIdStatus === 'pending'
);

import produce from 'immer';
import { createCreator, createReducer, Reducer } from '~/helpers/util/reducers';

import { DeleteInit, DeleteManyInit } from '../../_core/specialDates/duck';

export type FetchStatus = 'idle' | 'fetching' | 'success' | 'failure';
export type DeleteStatus = 'idle' | 'deleting' | 'success' | 'failure';

export type SpecialDateListState = {
  fetchStatus: FetchStatus;
  deleteStatus: DeleteStatus;
  deleteManyStatus: DeleteStatus;
  pageNumber: number;
  pageSize: number;
  totalCount: number;
  errorMessage?: string;
};

export type FetchPage = {
  startDate?: string;
  endDate?: string;
  date?: string;
  description?: string;
  startAlertWeekNumber?: number;
  endAlertWeekNumber?: number;
  year?: string;
  weekNumber?: number;
  nextPage: number;
  nextPageSize: number;
};

export type FetchPageSuccess = {
  nextPage: number;
  nextPageSize: number;
  totalCount: number;
};

export type DeleteError = {
  errorMessage?: string;
};

const prefix = '@specialDateList';

export const Types = Object.freeze({
  CLEAR: `${prefix}/CLEAR`,
  FETCH_PAGE: `${prefix}/FETCH_PAGE`,
  FETCH_PAGE_SUCCESS: `${prefix}/FETCH_PAGE_SUCCESS`,
  FETCH_PAGE_FAILURE: `${prefix}/FETCH_PAGE_FAILURE`,
  DELETE_INIT: `${prefix}/DELETE_INIT`,
  DELETE_SUCCESS: `${prefix}/DELETE_SUCCESS`,
  DELETE_FAILURE: `${prefix}/DELETE_FAILURE`,
  DELETE_MANY_INIT: `${prefix}/DELETE_MANY_INIT`,
  DELETE_MANY_SUCCESS: `${prefix}/DELETE_MANY_SUCCESS`,
  DELETE_MANY_FAILURE: `${prefix}/DELETE_MANY_FAILURE`,
  UPDATE: `${prefix}/UPDATE`
});

export const initialState: SpecialDateListState = {
  fetchStatus: 'idle',
  deleteStatus: 'idle',
  deleteManyStatus: 'idle',
  pageNumber: 1,
  pageSize: 10,
  totalCount: 0
};

const clearReducer: Reducer<SpecialDateListState, void> = (state, _action) => {
  return produce(state, draft => {
    draft.fetchStatus = 'idle';
    draft.deleteStatus = 'idle';
    draft.deleteManyStatus = 'idle';
    draft.pageNumber = 1;
    draft.pageSize = 10;
    draft.totalCount = 0;
  });
};

const fetchPageReducer: Reducer<SpecialDateListState, void> = state => {
  return produce(state, draft => {
    draft.fetchStatus = 'fetching';
  });
};

const fetchPageSuccessReducer: Reducer<
  SpecialDateListState,
  FetchPageSuccess
> = (state, action) => {
  return produce(state, draft => {
    const { nextPage, nextPageSize, totalCount } = action;
    draft.fetchStatus = 'success';
    draft.pageNumber = nextPage;
    draft.pageSize = nextPageSize;
    draft.totalCount = totalCount;
  });
};

const fetchPageFailureReducer: Reducer<SpecialDateListState, void> = state => {
  return produce(state, draft => {
    draft.fetchStatus = 'failure';
  });
};

const deleteInitReducer: Reducer<SpecialDateListState, void> = state => {
  return produce(state, draft => {
    draft.deleteStatus = 'deleting';
  });
};

const deleteSuccessReducer: Reducer<
  SpecialDateListState,
  FetchPageSuccess
> = state => {
  return produce(state, draft => {
    draft.deleteStatus = 'success';
  });
};

const deleteFailureReducer: Reducer<SpecialDateListState, DeleteError> = (
  state,
  action
) => {
  return produce(state, draft => {
    draft.deleteStatus = 'failure';
    draft.errorMessage = action.errorMessage;
  });
};

const deleteManyInitReducer: Reducer<SpecialDateListState, void> = state => {
  return produce(state, draft => {
    draft.deleteManyStatus = 'deleting';
  });
};

const deleteManySuccessReducer: Reducer<
  SpecialDateListState,
  FetchPageSuccess
> = state => {
  return produce(state, draft => {
    draft.deleteManyStatus = 'success';
  });
};

const deleteManyFailureReducer: Reducer<SpecialDateListState, DeleteError> = (
  state,
  action
) => {
  return produce(state, draft => {
    draft.deleteManyStatus = 'failure';
    draft.errorMessage = action.errorMessage;
  });
};

export const actions = Object.freeze({
  clear: createCreator<void>(Types.CLEAR),
  fetchPage: createCreator<FetchPage>(Types.FETCH_PAGE),
  fetchPageSuccess: createCreator<FetchPageSuccess>(Types.FETCH_PAGE_SUCCESS),
  fetchPageFailure: createCreator<void>(Types.FETCH_PAGE_FAILURE),
  deleteInit: createCreator<DeleteInit>(Types.DELETE_INIT),
  deleteSuccess: createCreator<void>(Types.DELETE_SUCCESS),
  deleteFailure: createCreator<DeleteError>(Types.DELETE_FAILURE),
  deleteManyInit: createCreator<DeleteManyInit>(Types.DELETE_MANY_INIT),
  deleteManySuccess: createCreator<void>(Types.DELETE_MANY_SUCCESS),
  deleteManyFailure: createCreator<DeleteError>(Types.DELETE_MANY_FAILURE)
});

export const reducer = createReducer(initialState, {
  [Types.CLEAR]: clearReducer,
  [Types.FETCH_PAGE]: fetchPageReducer,
  [Types.FETCH_PAGE_SUCCESS]: fetchPageSuccessReducer,
  [Types.FETCH_PAGE_FAILURE]: fetchPageFailureReducer,
  [Types.DELETE_INIT]: deleteInitReducer,
  [Types.DELETE_SUCCESS]: deleteSuccessReducer,
  [Types.DELETE_FAILURE]: deleteFailureReducer,
  [Types.DELETE_MANY_INIT]: deleteManyInitReducer,
  [Types.DELETE_MANY_SUCCESS]: deleteManySuccessReducer,
  [Types.DELETE_MANY_FAILURE]: deleteManyFailureReducer
});

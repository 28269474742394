import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';

import ModalAnnouncement from '../../components/Modals/Announcement';
import { actions, selectors } from '~/store/modules';
import { AnnouncementVisualization } from '~/domain/model';
import * as S from './styles';

export default function Announcement() {
  const dispatch = useDispatch();
  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);
  const [openRequiredAnnouncement, setOpenRequiredAnnouncement] =
    useState(false);
  const [showAnnouncement, setShowAnnouncement] = useState<
    AnnouncementVisualization | undefined
  >(undefined);
  const announcementBanner = useSelector(
    selectors.core.announcement.getAnnouncementBanner
  );

  useEffect(() => {
    dispatch(actions.ui.announcementBanner.requestAnnouncementBanner());
  }, []);

  useEffect(() => {
    if (
      announcementBanner &&
      announcementBanner.length &&
      announcementBanner.find(announcement => announcement.required)
    ) {
      setShowAnnouncement(
        announcementBanner.find(announcement => announcement.required)
      );
      setOpenRequiredAnnouncement(true);
      setModalIsVisible(true);
    }
  }, [announcementBanner]);

  const nextAnnouncement = useCallback(() => {
    let index: number = Number.NaN;

    if (announcementBanner?.length && showAnnouncement) {
      index = announcementBanner?.indexOf(showAnnouncement);
    }

    let announcementToSet: AnnouncementVisualization | null = null;

    if (index !== announcementBanner?.length - 1) {
      for (let i = index + 1; i <= announcementBanner?.length - 1; i++) {
        if (announcementBanner[i].required === openRequiredAnnouncement) {
          announcementToSet = announcementBanner[i];
          setShowAnnouncement(announcementBanner[i]);
          break;
        }
      }
    }

    if (!announcementToSet) {
      for (let i = 0; i <= announcementBanner?.length - 1; i++) {
        if (announcementBanner[i].required === openRequiredAnnouncement) {
          setShowAnnouncement(announcementBanner[i]);
          break;
        }
      }
    }
  }, [showAnnouncement, announcementBanner, openRequiredAnnouncement]);

  const previusAnnouncement = useCallback(() => {
    let index: number = Number.NaN;

    if (announcementBanner?.length && showAnnouncement) {
      index = announcementBanner?.indexOf(showAnnouncement);
    }

    let announcementToSet: AnnouncementVisualization | null = null;

    if (index > 0) {
      for (let i = index - 1; i >= 0; i--) {
        if (announcementBanner[i].required === openRequiredAnnouncement) {
          announcementToSet = announcementBanner[i];
          setShowAnnouncement(announcementBanner[i]);
          break;
        }
      }
    }

    if (!announcementToSet) {
      for (let i = announcementBanner.length - 1; i >= 0; i--) {
        if (announcementBanner[i].required === openRequiredAnnouncement) {
          setShowAnnouncement(announcementBanner[i]);
          break;
        }
      }
    }
  }, [showAnnouncement, announcementBanner, openRequiredAnnouncement]);

  return (
    <S.Announcement>
      <S.LastAnnouncement>Último Comunicado</S.LastAnnouncement>
      <S.AnnouncementGroup
        sizeGroup={
          (announcementBanner?.length ?? 2) * 13 < 100
            ? '100%'
            : `${(announcementBanner?.length ?? 2) * 13}%`
        }
        timeToGroup={
          (announcementBanner?.length ?? 2) * 2 < 10
            ? '15s'
            : `${(announcementBanner?.length ?? 2) * 2}s`
        }
      >
        <S.AnnouncementList>
          {announcementBanner && announcementBanner.length
            ? announcementBanner.map(announcement => (
                <S.AnnouncementTitle
                  onClick={() => {
                    setShowAnnouncement(announcement);
                    setOpenRequiredAnnouncement(announcement.required);
                    setModalIsVisible(true);
                  }}
                >
                  {moment(announcement.creationDate).format('DD/MM')} -{' '}
                  {announcement.description}
                </S.AnnouncementTitle>
              ))
            : null}
        </S.AnnouncementList>
      </S.AnnouncementGroup>
      <ModalAnnouncement
        uniqueAnnouncement={
          announcementBanner?.filter(
            announcement => announcement.required === openRequiredAnnouncement
          )?.length === 1
        }
        modalIsVisible={modalIsVisible}
        announcement={showAnnouncement}
        setModalIsVisible={setModalIsVisible}
        nextAnnouncement={nextAnnouncement}
        previusAnnouncement={previusAnnouncement}
      />
    </S.Announcement>
  );
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type CreateStatus =
  | 'idle'
  | 'submitting'
  | 'success'
  | 'failure'
  | 'failure-already-registered';

export type DeliveryPatternCreateState = {
  createStatus: CreateStatus;
  closeModal: boolean;
};

export type DeliveryPatternCreateAction = {
  id?: number;
  patternDescription: string;
  siteId: number;
  enabledStartDate: Date;
  enabledEndDate: Date;
  siteDeliveryPatternDetails: DeliveryPatternDetail[];
};
export type DeliveryPatternDetail = {
  id?: number;
  orderEndDayNumber?: number;
  orderEndTime?: string;
  deliveryDayNumber?: number;
  deliveryTime?: string;
};

const initialState: DeliveryPatternCreateState = {
  createStatus: 'idle',
  closeModal: false
};

const prefix = '@deliveryPatternCreate';

const deliveryPattern = createSlice({
  name: prefix,
  initialState,
  reducers: {
    create(state, action: PayloadAction<DeliveryPatternCreateAction>) {},
    setCloseModal(state, action: PayloadAction<boolean>) {
      state.closeModal = action.payload;
    }
  }
});

export const actions = deliveryPattern.actions;
export const reducer = deliveryPattern.reducer;

const caseReducers = deliveryPattern.caseReducers;
export const Types = Object.freeze({
  CREATE: `${prefix}/${caseReducers.create.name}`
});

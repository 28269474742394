import { createAsyncThunk } from '@reduxjs/toolkit';

import * as AutoserviceModel from '~/domain/model/AutoserviceDefinitionsParam';
import * as AutoserviceServices from '~/services/producer/AutoserviceDefinitions';

export const fetchDefinitions = createAsyncThunk(
  `autoserviceDefinitions/fetchDefinitions`,
  async (params: number) => {
    const data = await AutoserviceServices.findDefinitions(params);
    return data;
  }
);

export const updateDefinitions = createAsyncThunk(
  `autoserviceDefinitions/updateDefinitions`,
  async (params: AutoserviceModel.AutoserviceDefinitionsParam) => {
    try {
      await AutoserviceServices.updateDefinitionsRequest(params);
      return true;
    } catch (err: any) {
      console.log('error');
    }
  }
);

import { combineReducers } from 'redux';
import { reducer as competenceRegistration } from './CompetenceRegistration';
import { reducer as core } from './_core';
import { reducer as ui } from './_ui';
import alert from './alert/reducer';
import { reducer as announcementsList } from './announcementsList';
import attachment from './attachment/reducer';
import { reducer as autoService } from './autoService';
import { reducer as autoserviceClassification } from './autoserviceClassification';
import { reducer as autoserviceDefinitions } from './autoserviceDefinitions';
import { reducer as autoserviceRequests } from './autoserviceRequests';
import { reducer as biDashboards } from './bidashboards';
import competency from './competency/reducer';
import dates from './dates/reducer';
import { reducer as directedOffers } from './directedOffers';
import { reducer as ecommerceManagement } from './ecommerceManagement';
import { reducer as expeditions } from './expeditions';
import { reducer as harvestingOrders } from './harvestingOrders';
import { reducer as keyLiberation } from './keyLiberation';
import localization from './localization/reducer';
import { reducer as me } from './me';
import { reducer as orders } from './orders';
import packing from './packing/reducer';
import partnership from './partnership/reducer';
import { reducer as plp } from './plp';
import product from './product/reducer';
import { reducer as productCategories } from './productCategories';
import { reducer as productPreferences } from './productPreferences';
import productPropertyAttachmentTypes from './productPropertyAttachmentTypes/reducer';
import productionAreaUnit from './productionAreaUnit/reducer';
import productionLocalType from './productionLocalType/reducer';
import productionPlan from './productionPlan/reducer';
import { reducer as productionPlanning } from './productionPlanning';
import property from './property/reducer';
import propertyExport from './propertyExport/reducer';
import propertyLocal from './propertyLocal/reducer';
import { reducer as propertyThunk } from './propertyThunk';
import { reducer as salesReportEcommerce } from './salesReportEcommerce';
import section from './section/reducer';
import { reducer as shippingCompanies } from './shippingCompanies';
import site from './site/reducer';
import siteSimpleList from './siteSimpleList/reducer';
import specialDate from './specialDate/reducer';
import { reducer as stocks } from './stocks';
import tabs from './tabs/reducer';
import { reducer as tagPrinting } from './tagPrinting';
import { reducer as technicalSpecification } from './technicalSpecification';
import { reducer as unvailableDates } from './unavailableDates/index';
import { reducer as unpublished } from './unpublished';
import { reducer as volLkp } from './volLkp';
import { reducer as volOffers } from './volOffers';

export default combineReducers({
  announcementsList,
  core,
  ui,
  alert,
  competency,
  competenceRegistration,
  directedOffers,
  expeditions,
  keyLiberation,
  tabs,
  property,
  site,
  siteSimpleList,
  section,
  propertyLocal,
  localization,
  productionPlan,
  packing,
  autoService,
  autoserviceClassification,
  autoserviceRequests,
  autoserviceDefinitions,
  me,
  productionAreaUnit,
  productionLocalType,
  product,
  partnership,
  specialDate,
  stocks,
  productPropertyAttachmentTypes,
  attachment,
  dates,
  propertyExport,
  unvailableDates,
  productPreferences,
  ecommerceManagement,
  orders,
  plp,
  salesReportEcommerce,
  productCategories,
  productionPlanning,
  technicalSpecification,
  shippingCompanies,
  biDashboards,
  volLkp,
  unpublished,
  volOffers,
  propertyThunk,
  harvestingOrders,
  tagPrinting
});

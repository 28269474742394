import Order from '~/@core/domain/model/order/Order';
import FilterCreateGfp from '../../filters/gfp/FilterCreateGfp';
import { MapOrderSelectedToGfpItems } from './MappingGfpItems';

export default interface FormFilterCreateGfpModel {
  emissionDate: string;
  outDate: string;
  commercialDate: string;
  shippingCompanyId: number;
  partitionQuantity: number;
  pickupLocation?: string;
  siteId: number;
  multiple?: number;
  isKlok?: boolean;
  gfpItems: Order[];
  observation?: string;
}

export class MapFormToFilter {
  static toFilter(
    formData: FormFilterCreateGfpModel,
    siteIdSelected: number
  ): FilterCreateGfp {
    const filter: FilterCreateGfp = {
      emissionDate: formData.emissionDate,
      outDate: formData.outDate,
      commercialDate: formData.gfpItems[0].commercialDate,
      transporterId: formData.shippingCompanyId,
      partitionQuantity: formData.partitionQuantity,
      pickupLocation: formData.pickupLocation,
      siteId: siteIdSelected,
      multiple: (() => {
        if (formData.multiple !== undefined) return formData.multiple;
        if (formData.gfpItems.length > 0) return formData.gfpItems[0].multi;
        return undefined;
      })(),
      isKlok: false,
      observation: formData.observation,
      items: MapOrderSelectedToGfpItems.toGfpItems(formData.gfpItems)
    };

    return filter;
  }
}

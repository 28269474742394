import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';
import FilterStocks from '~/@core/domain/filters/stock/FilterStock';
import Stocks from '~/@core/domain/model/stock/Stocks';
import { StockService } from '~/@core/infrastructure/api/services';
import IUseCase from '../../IUseCase';

export default class GetStocks
  implements IUseCase<FilterStocks, Promise<DefaultPaginated<Stocks[]>>>
{
  execute(filter: FilterStocks): Promise<DefaultPaginated<Stocks[]>> {
    return StockService.fetchStocks(filter);
  }
}

import { createSelector } from 'reselect';

import { ExpeditionsState } from '.';

export const getRoot = (state: any) => {
  return state.expeditions as ExpeditionsState;
};
export const getExpeditionsPagination = createSelector(
  [getRoot],
  state => state.expeditionsPagination
);

export const getTotalExpeditions = createSelector(
  [getRoot],
  state => state.totalexpedition
);
export const getExpeditions = createSelector(
  [getRoot],
  state => state.expeditions
);
export const getExpeditionsBySiteId = createSelector(
  [getRoot],
  state => state.expeditionsBySiteId
);
export const getExpeditionHistory = createSelector(
  [getRoot],
  state => state.expeditionHistory
);
export const getCloseExpeditionHistoryModal = createSelector(
  [getRoot],
  state => state.closeExpeditionHistoryModal
);
export const getCloseEditExpeditionModal = createSelector(
  [getRoot],
  state => state.closeEditExpeditionModal
);

export const getCloseAddExpeditionModal = createSelector(
  [getRoot],
  state => state.closeAddExpeditionModal
);
export const getSubmitFormFilter = createSelector(
  [getRoot],
  state => state.submitFormFilter
);

import { AxiosResponse, AxiosError } from 'axios';
import api from '../config';
import Expedition from '~/@core/domain/model/expedition/Expedition';

export const fetchExpeditionBySiteId = (siteId: number) => {
  return new Promise<Expedition[]>((resolve, reject) => {
    api
      .get<void, AxiosResponse<Expedition[]>>(`/expedition/${siteId}`)
      .then((response: AxiosResponse<Expedition[]>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { actions } from '~/store/modules/unavailableDates';
import api from '~/services/api';
import {
  Pagination,
  Types,
  UnavailableDateCreate,
  UnavailableDateUpdate
} from './duck';
import { Action } from '~/helpers/util/reducer';
import * as API from '~/domain/api';
import { UnavailableDate } from '~/domain/model';
import { getPagination } from './selectors';
import { generateErrorMessage } from '~/helpers/util/error';
import { actions as actionsLoading } from '../_ui/loading';

export function* onSearch(action: Action<number>): any {
  try {
    yield put(actions.resetDates());
    const pagination: Pagination = yield select(getPagination);

    const response: AxiosResponse = yield call(
      api.get,
      `/unavailable-date?Data.SiteId=${action.payload}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
    const unavailableResponse: API.UnavailableDateGet = response.data;
    const unavailableDates: UnavailableDate[] =
      unavailableResponse.unavailableDates.map(
        (unavailableDate: API.UnavailableDates) =>
          new UnavailableDate(unavailableDate)
      );
    if (!response.status || response.status >= 400) {
      throw Error();
    }
    const newPagination: Pagination = {
      ...pagination,
      totalRows: unavailableResponse.totalCount
    };

    yield put(actions.setPagination(newPagination));
    yield put(actions.searchSuccess(unavailableDates));
  } catch (err) {
    yield put(actions.searchFailure(`Erro ao buscar datas! ${err}`));
  }
}
export function* onCreate(action: Action<UnavailableDateCreate>): any {
  try {
    yield put(actionsLoading.setLoading({ status: 'fetching' }));
    const response: any = yield call(
      api.post,
      `/unavailable-date`,
      action.payload
    );
    yield put(actionsLoading.setLoading({ status: 'idle' }));

    if (!response.status || response.status >= 400) {
      const errorMessage = generateErrorMessage(response.response.data.errors);
      throw Error(errorMessage);
    }
    yield put(actions.createSuccess('Data cadastrada com sucesso!'));
  } catch (err: any) {
    yield put(actionsLoading.setLoading({ status: 'idle' }));

    const message = JSON.stringify(err.response.data.errors.messages);
    yield put(actions.createFailure(message));
  }
}

export function* onUpdate(action: Action<UnavailableDateUpdate>): any {
  try {
    yield put(actionsLoading.setLoading({ status: 'fetching' }));

    const response: any = yield call(
      api.put,
      `/unavailable-date`,
      action.payload
    );
    yield put(actionsLoading.setLoading({ status: 'idle' }));

    if (!response.status || response.status >= 400) {
      throw Error(response?.response?.data);
    }
    yield put(actions.updateSuccess('Data editada com sucesso!'));
  } catch (err: any) {
    yield put(actionsLoading.setLoading({ status: 'idle' }));
    const message = JSON.stringify(err.response.data.errors.messages);
    yield put(actions.updateFailure(message));
  }
}
export function* onDelete(action: Action<number>) {
  try {
    const response: AxiosResponse = yield call(
      api.delete,
      `/unavailable-date?id=${action.payload}`
    );
    if (!response.status || response.status >= 400) {
      throw Error();
    }
    const newPagination: Pagination = {
      pageNumber: 1,
      pageSize: 10
    };

    yield put(actions.setPagination(newPagination));
    yield put(actions.deleteSuccess('Data excluida com sucesso!'));
  } catch (err) {
    yield put(actions.deleteFailure('Erro ao excluir data!'));
  }
}

export default all([
  takeLatest(Types.SEARCH, onSearch),
  takeLatest(Types.CREATE, onCreate),
  takeLatest(Types.UPDATE, onUpdate),
  takeLatest(Types.DELETE, onDelete)
]);

import { createAsyncThunk } from '@reduxjs/toolkit';
import * as PropertyServices from '~/services/producer/Property';
import { alertRequest } from '../../alert/actions';
import { generateErrorMessage } from '~/helpers/util/error';

export const getPropertiesForAll = createAsyncThunk(
  'propertyThunk/getProperties',
  async (propertyFilterParams: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await PropertyServices.fetchPropertiesRequest(
        propertyFilterParams
      );
      return response.results;
    } catch (err: any) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao buscar propriedades.'
      );
      dispatch(alertRequest(errorMessage, 'error'));

      return rejectWithValue(errorMessage);
    }
  }
);

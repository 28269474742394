import { UploadFile } from 'antd/lib/upload/interface';
import produce from 'immer';
import { ApplicationError } from '~/domain/api/errors';
import { createCreator, createReducer, Reducer } from '~/helpers/util/reducers';

export type Status = 'idle' | 'pending' | 'success' | 'failure';

export type BannerProducerManagementCreateState = {
  createStatus: Status;
  fetchInitStatus: Status;
  errorMessage?: string;
  bannerImage: string;
};

export type FetchFailure = {
  error?: ApplicationError;
};

export type Create = {
  description: string;
  url: string;
  bannerTypeId: number;
  bannerImage: UploadFile<any>;
};

export type FetchBannerUrl = {
  bannerId: number;
};

export type FetchBannerUrlSuccess = {
  bannerImage: string;
};

export type Error = {
  errorMessage?: string;
};

const prefix = '@bannerProducerManagementCreate';

export const Types = Object.freeze({
  CLEAR: `${prefix}/CLEAR`,
  CREATE_INIT: `${prefix}/CREATE_INIT`,
  CREATE_SUCCESS: `${prefix}/CREATE_SUCCESS`,
  CREATE_FAILURE: `${prefix}/CREATE_FAILURE`,
  FETCH_INIT: `${prefix}/FETCH_INIT`,
  FETCH_INIT_SUCCESS: `${prefix}/FETCH_INIT_SUCCESS`,
  FETCH_INIT_FAILURE: `${prefix}/FETCH_INIT_FAILURE`
});

export const initialState: BannerProducerManagementCreateState = {
  createStatus: 'idle',
  fetchInitStatus: 'idle',
  bannerImage: ''
};

const clearReducer: Reducer<BannerProducerManagementCreateState, void> = (
  state,
  _action
) => {
  return produce(state, draft => {
    draft.createStatus = initialState.createStatus;
    draft.bannerImage = initialState.bannerImage;
  });
};

const fetchInit: Reducer<BannerProducerManagementCreateState, void> = state => {
  return produce(state, draft => {
    draft.fetchInitStatus = 'pending';
  });
};

const fetchInitSuccess: Reducer<
  BannerProducerManagementCreateState,
  void
> = state => {
  return produce(state, draft => {
    draft.fetchInitStatus = 'success';
  });
};

const fetchInitFailure: Reducer<
  BannerProducerManagementCreateState,
  void
> = state => {
  return produce(state, draft => {
    draft.fetchInitStatus = 'failure';
  });
};

const createInitReducer: Reducer<
  BannerProducerManagementCreateState,
  Create
> = state => {
  return produce(state, draft => {
    draft.createStatus = 'pending';
  });
};

const createSuccessReducer: Reducer<
  BannerProducerManagementCreateState,
  void
> = state => {
  return produce(state, draft => {
    draft.createStatus = 'success';
  });
};

const createFailureReducer: Reducer<
  BannerProducerManagementCreateState,
  Error
> = (state, action) => {
  return produce(state, draft => {
    draft.createStatus = 'failure';
    draft.errorMessage = action.errorMessage;
  });
};

export const actions = Object.freeze({
  clear: createCreator<void>(Types.CLEAR),
  createInit: createCreator<Create>(Types.CREATE_INIT),
  createSuccess: createCreator<void>(Types.CREATE_SUCCESS),
  createFailure: createCreator<Error>(Types.CREATE_FAILURE),
  fetchInit: createCreator<void>(Types.FETCH_INIT),
  fetchInitSuccess: createCreator<void>(Types.FETCH_INIT_SUCCESS),
  fetchInitFailure: createCreator<void>(Types.FETCH_INIT_FAILURE)
});

export const reducer = createReducer(initialState, {
  [Types.CLEAR]: clearReducer,
  [Types.CREATE_INIT]: createInitReducer,
  [Types.CREATE_SUCCESS]: createSuccessReducer,
  [Types.CREATE_FAILURE]: createFailureReducer,
  [Types.FETCH_INIT]: fetchInit,
  [Types.FETCH_INIT_SUCCESS]: fetchInitSuccess,
  [Types.FETCH_INIT_FAILURE]: fetchInitFailure
});

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { OrdersState } from '.';

export const getRoot = (state: any) => {
  return state.orders as OrdersState;
};
export const getOrders = createSelector([getRoot], state => state.orders);
export const getTotalOrders = createSelector(
  [getRoot],
  state => state.totalOrders
);

export const getOrdersPagination = createSelector(
  [getRoot],
  state => state.ordersPagination
);

export const getLoading = createSelector([getRoot], state => state.loading);

export const getExtraLoading = createSelector(
  [getRoot],
  state => state.extraLoading
);

export const getCloseAwnser = createSelector(
  [getRoot],
  state => state.closeAwnser
);

export const getCustomers = createSelector([getRoot], state => state.customers);

export const getPublishers = createSelector(
  [getRoot],
  state => state.publishers
);

export const getSiteId = createSelector([getRoot], state => state.siteId);

export const useOrdersState = () => {
  return useSelector(createSelector([getRoot], state => state));
};

export const getDeliveryDateHold = createSelector(
  [getRoot],
  state => state.deliveryDateHold
);

export const getCommercialDateHold = createSelector(
  [getRoot],
  state => state.commercialDateHold
);

export const getReloadDatas = createSelector(
  [getRoot],
  state => state.reloadDatas
);

export const getStocksForItems = createSelector(
  [getRoot],
  state => state.stocksForItems
);

export const getInterIDsWainting = createSelector(
  [getRoot],
  state => state.interIDsWaiting
);

export const getInterIdSelected = createSelector(
  [getRoot],
  state => state.interIdSelected
);

export const getTableData = createSelector([getRoot], state => state.tableData);

export const getRemoveLocation = createSelector(
  [getRoot],
  state => state.removeLocation
);

export const getMenyKeys = createSelector([getRoot], state => state.manyKeys);

export const getUUID = createSelector([getRoot], state => state.uuid);

// eslint-disable-next-line no-shadow
export enum RemoveLocation {
  SITIO = 1,
  CVH = 2
}

// eslint-disable-next-line no-shadow
export enum RemoveLocationMap {
  SITIO = 'SITIO',
  CVH = 'CVH'
}

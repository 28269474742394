import styled, { css } from 'styled-components';

import { QualityColorEnum } from '~/domain/enum/stockModalTabs.enum';

const containerMap = {
  [QualityColorEnum.A1]: css`
    background-color: #198754;
    color: #fff !important;
  `,
  [QualityColorEnum.A2]: css`
    background-color: #ffc107;
    color: #000;
    font-weight: bold;
  `
};

export const QualityContainer = styled.span<{
  quality: QualityColorEnum;
}>`
  padding: 2px;
  border-radius: 5px;
  ${props => containerMap[props.quality]};
`;

import React, { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import {
  CollapseModal,
  ModalHelp,
  ModalLeft,
  ModalLogo,
  SubTitle,
  Title
} from './style';
import logo from '~/assets/images/logo.png';
import { selectors } from '~/store/modules';

interface ModalHelpPropertyType {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
}

export default function ModalHelpGFP({
  isModalVisible,
  setIsModalVisible
}: ModalHelpPropertyType) {
  function onClose() {
    setIsModalVisible(false);
  }
  const profile = useSelector(selectors.ui.auth.getProfile);

  return (
    <ModalHelp
      title="Ajuda"
      className="hideHeader"
      width={1200}
      visible={isModalVisible}
      onCancel={onClose}
      footer={() => {}}
    >
      <Row>
        <ModalLeft xs={24} lg={10}>
          <ModalLogo src={logo} />
        </ModalLeft>
        <Col
          xs={24}
          lg={14}
          style={{
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 30,
            paddingBottom: 60
          }}
        >
          <Row justify="end">
            <CloseOutlined onClick={() => onClose()} />
          </Row>
          <Title>Dúvidas frequentes</Title>
          <SubTitle>Olá, {profile.veiling_claim_type_name} !</SubTitle>

          <CollapseModal>
            <CollapseModal.Panel
              style={{ backgroundColor: '#fff' }}
              header="Cadastro de planejamento de produção"
              key="1"
            >
              <div>
                <p>
                  <b>CADASTRAR</b>
                </p>

                <p>
                  Será necessário primeiramente filtrar informações como chave
                  produtor caso tenha mais de uma chave, a semana de produção,
                  categoria e grupo do produto. Clique no botão filtrar
                  sinalizado por uma lupa.
                </p>
                <p>
                  Todas as informações serão listadas na grade logo abaixo
                  contendo informações separadas em dois níveis, primeiro tendo
                  informações do grupo do produto, contendo a quantidade
                  produzida do ano anterior (caso tenha) e um campo para inserir
                  o valor a ser produzido para o grupo na semana escolhida.
                </p>
                <p>
                  No primeiro nível da grade temos ações de possibilitar a
                  seleção dos grupos a serem planejados na semana escolhida.
                </p>
                <p>
                  No primeiro nível da grade temos ações de informar um ajuste a
                  ser aplicado de +/- em comparação ao ano anterior produzido
                  para o novo ano, ação de replicar para semanas futuras o
                  planejado já formatado e a possibilidade de importar o ano
                  anterior planejado (caso tenha) para o próximo ano de
                  produção.
                </p>
                <p>
                  No segundo nível, temos informações do produto que consta para
                  o grupo listado. Grade composta na exibição do código e
                  descrição do produto, propriedade de produção, local de
                  produção, seção de produção e o valor produzido no ano
                  anterior (caso tenha).
                </p>
                <p>
                  No segundo nível, temos ações que possibilitam a replicação da
                  linha de produto, a remoção do item replicado.
                </p>
                <p>
                  No segundo nível, possibilita inserir as informações dos
                  locais de produção de cada produto. As colunas Propriedade,
                  Local e Seção da grade são editáveis, podendo selecionar os
                  locais definidos para a sua produção de dentro da propriedade.
                </p>
                <p>
                  Após todos os campos obrigatórios serem preenchidos e que o
                  registro seja inserido ou alterado, deve-se clicar no botão
                  salvar sinalizado por um disquete no topo da tela, assim todas
                  as informações serão guardadas dentro do portal.
                </p>
              </div>
            </CollapseModal.Panel>
            <CollapseModal.Panel
              style={{ backgroundColor: '#fff' }}
              header="Consultar/editar planejamento de produção"
              key="2"
            >
              <div>
                <p>
                  <b>CONSULTAR/EDITAR</b>
                </p>
                <p>
                  Será necessário primeiramente filtrar informações como chave
                  produtor caso tenha mais de uma chave, a semana de produção,
                  categoria e grupo do produto. Clique no botão filtrar
                  sinalizado por uma lupa
                </p>
                <p>
                  Todas as informações serão listadas na grade logo abaixo
                  contendo informações separadas em dois níveis, primeiro tendo
                  informações do grupo do produto, contendo a quantidade
                  produzida do ano anterior (caso tenha) e a quantidade
                  produzida para o grupo na semana escolhida.
                </p>
                <p>
                  No primeiro nível da grade temos ações de possibilitar a
                  seleção dos grupos a serem planejados na semana escolhida.
                </p>
                <p>
                  No primeiro nível da grade temos ações de informar um ajuste a
                  ser aplicado de +/- em comparação ao ano anterior produzido
                  para o novo ano, ação de replicar para semanas futuras o
                  planejado já formatado e a possibilidade de importar o ano
                  anterior planejado (caso tenha) para o próximo ano de
                  produção.
                </p>
                <p>
                  No segundo nível, temos informações do produto que consta para
                  o grupo listado. Grade composta na exibição do código e
                  descrição do produto, propriedade de produção, local de
                  produção, seção de produção e o valor produzido no ano
                  anterior (caso tenha).
                </p>

                <p>
                  No segundo nível, possibilita inserir as informações dos
                  locais de produção de cada produto. As colunas Propriedade,
                  Local e Seção da grade são editáveis, podendo selecionar os
                  locais definidos para a sua produção de dentro da propriedade.
                </p>
                <p>
                  Após todos os campos obrigatórios serem preenchidos e que o
                  registro seja inserido ou alterado, deve-se clicar no botão
                  salvar sinalizado por um disquete no topo da tela, assim todas
                  as informações serão guardadas dentro do portal.
                </p>
              </div>
            </CollapseModal.Panel>
            <CollapseModal.Panel
              style={{ backgroundColor: '#fff' }}
              header="Histórico"
              key="3"
            >
              <p>
                Nesta tela, o objetivo é possibilitar a visualização de forma
                gráfica a comparação das quantidades produzidas em comparação
                entre anos.
              </p>

              <p>
                No campo chave insira uma chave. No quadro filtre o grupo de
                produtos e os anos a serem comparados.
              </p>

              <p>
                O resultado será listado abaixo em formato de gráfico de linha
                tend como base os meses e quantidades realizadas por ano.
              </p>

              <p>
                Informações poderão ser exportadas para Excel e para arquivos de
                imagens. Basta clicar no ícone de download logo em cima do
                gráfico.
              </p>
            </CollapseModal.Panel>
          </CollapseModal>
        </Col>
      </Row>
    </ModalHelp>
  );
}

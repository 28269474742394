// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line import/no-named-default
import { AxiosResponse } from 'axios';
import * as API from '~/domain/api';
import { APIError } from '~/domain/api/errors';
import apiProducer from '../api';

export const create = async (voucher: API.Voucher) => {
  try {
    const {
      description,
      reajustmentAmount,
      reajustmentType,
      quantityUnitMinimun,
      quantityType,
      observation,
      customerGroupId,
      customersIds,
      siteId
    } = voucher;

    const data: any = {
      id: 0,
      description,
      reajustmentAmount,
      reajustmentType,
      quantityUnitMinimun,
      quantityType,
      observation,
      customerGroupId,
      customersIds,
      siteId
    };
    const response: any = await apiProducer.post('/vouchers', data);

    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;

    throw new APIError(String(dataError.errors.messages[0]));
  }
};

export const fetchVoucher = async (
  status?: string,
  pageNumber?: number,
  pageSize?: number,
  customerId?: number,
  customerGroupId?: number,
  reajustmentAmount?: number,
  reajustmentType?: string,
  description?: string,
  siteId?: number
) => {
  try {
    let URL = '/vouchers';

    if (!pageNumber) {
      pageNumber = 1;
    }

    if (!pageSize) {
      pageSize = 100;
    }

    let validateIsFirstParam = true;

    if (pageNumber) {
      URL += `${getConcatenatedCharacterQueryParams(
        validateIsFirstParam
      )}pageNumber=${pageNumber}`;
      validateIsFirstParam = false;
    }
    if (pageSize) {
      URL += `${getConcatenatedCharacterQueryParams(
        validateIsFirstParam
      )}pageSize=${pageSize}`;
      validateIsFirstParam = false;
    }
    if (siteId) {
      URL += `${getConcatenatedCharacterQueryParams(
        validateIsFirstParam
      )}data.siteId=${siteId}`;
      validateIsFirstParam = false;
    }
    if (status) {
      URL += `${getConcatenatedCharacterQueryParams(
        validateIsFirstParam
      )}data.isActive=${status}`;
      validateIsFirstParam = false;
    }
    if (customerId) {
      URL += `${getConcatenatedCharacterQueryParams(
        validateIsFirstParam
      )}data.customerId=${customerId}`;
      validateIsFirstParam = false;
    }
    if (customerGroupId) {
      URL += `${getConcatenatedCharacterQueryParams(
        validateIsFirstParam
      )}data.groupId=${customerGroupId}`;
      validateIsFirstParam = false;
    }
    if (reajustmentAmount) {
      URL += `${getConcatenatedCharacterQueryParams(
        validateIsFirstParam
      )}data.reajustmentAmount=${reajustmentAmount}`;
      validateIsFirstParam = false;
    }
    if (reajustmentType) {
      URL += `${getConcatenatedCharacterQueryParams(
        validateIsFirstParam
      )}data.reajustmentType=${reajustmentType}`;
      validateIsFirstParam = false;
    }
    if (description) {
      URL += `${getConcatenatedCharacterQueryParams(
        validateIsFirstParam
      )}data.description=${description}`;
      validateIsFirstParam = false;
    }

    const response: AxiosResponse = await apiProducer.get(URL);
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

function getConcatenatedCharacterQueryParams(isFirstParam: boolean): string {
  if (isFirstParam) {
    return '?';
  }
  return '&';
}

export const getVoucherHistoryById = async (id: number) => {
  try {
    const URL = `/vouchers/history/${id}`;
    const response: AxiosResponse = await apiProducer.get(URL);
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const activeOrInactiveStatus = async (id: number) => {
  try {
    const response: any = await apiProducer.put(`/vouchers/status/${id}`);
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

import { Form, FormInstance, Select, SelectProps } from 'antd';
import { AxiosError } from 'axios';
import { isEmpty, isNil } from 'lodash';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import GetSites from '~/@core/application/useCases/inputs/getSites';
import Site from '~/@core/domain/model/site/Site';
import { globalContext } from '~/context/GlobalContext';
import { getSelectedTabPermission } from '~/store/modules/tabs/selectors';

export const ensureArray = <T,>(data: T | T[]): T[] => {
  if (isNil(data)) return [];

  return Array.isArray(data) ? data : [data];
};

interface ComponentProps extends SelectProps {
  form?: FormInstance<any>;
  isCustomSiteSelected?: boolean;
  setCustomSiteSelected?: Dispatch<SetStateAction<any>>;
}

export default function SitesSelect(props: ComponentProps) {
  const { form, isCustomSiteSelected, setCustomSiteSelected } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const initialSiteId = ensureArray(form?.getFieldValue('siteId'));

  const [sitesSelected, setSitesSelected] = useState<any>(initialSiteId);
  const selectedTabPermission = useSelector(getSelectedTabPermission);
  const { t } = useTranslation();

  const { handlePromiseUseCase, activeAlert, setSiteIdSelected, setSites } =
    useContext(globalContext);

  useEffect(() => {
    const getSites = new GetSites();
    setIsDisabled(true);
    setIsLoading(true);

    handlePromiseUseCase<Site[], AxiosError>(
      getSites.execute(selectedTabPermission),
      sitesOut => {
        setSitesSelected(sitesOut);
        if (!isEmpty(initialSiteId)) {
          setSiteIdSelected(initialSiteId[0]);
        } else {
          setSiteIdSelected(sitesOut[0].id);
        }

        setSites(sitesOut);
        if (isCustomSiteSelected && setCustomSiteSelected) {
          setCustomSiteSelected(sitesOut);
        }
        if (form && isEmpty(initialSiteId)) {
          form.setFieldValue('siteId', sitesOut[0].id);
        }
        setIsLoading(false);
        setIsDisabled(false);
      },
      error => {
        activeAlert({
          message: JSON.stringify(
            error.response?.data
              ? error.response?.data
              : 'Houve um erro inesperado!'
          ),
          type: 'error',
          timeout: 5000
        });
        setIsDisabled(true);
        setIsLoading(false);
      }
    );
  }, []);

  return (
    <Form.Item
      name="siteId"
      label={t('generals.filters.siteInput')}
      rules={[{ required: true, message: 'Selecione a chave.' }]}
    >
      <Select
        showSearch
        allowClear
        placeholder={t('generals.selectPlaceholder')}
        optionFilterProp="children"
        filterOption
        loading={isLoading}
        disabled={isDisabled}
        {...props}
      >
        {sitesSelected?.map((value: any) => (
          <Select.Option key={value.id} value={value.id}>
            {value.account}-{value.corporateName} ({value.tradingName})
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

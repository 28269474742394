import produce from 'immer';

interface StateType {
  loading: boolean;
}

const INITIAL_STATE: StateType = {
  loading: false
};

export default function propertyExport(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case '@propertyExport/EXPORT_REQUEST':
      return produce(state, draft => {
        draft.loading = true;
      });

    case '@propertyExport/EXPORT_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
      });

    case '@propertyExport/EXPORT_FAILURE':
      return produce(state, draft => {
        draft.loading = false;
      });

    default:
      return state;
  }
}

import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { Container, Content } from './styles';

import image from '~/assets/images/loading.gif';
import { selectors } from '~/store/modules';
import { getIsLoading } from '~/store/modules/_ui/loading/selectors';
import { getShippingCompanyLoading } from '~/store/modules/shippingCompanies/selectors';
import { getStockLoading } from '~/store/modules/stocks/selectors';
import { getProductCategoriesState } from '~/store/modules/productCategories/selectors';
import { getTechnicalSpecificationState } from '~/store/modules/technicalSpecification/selectors';

interface LoadingProps {
  loading?: boolean;
}

export default function Loading({ loading }: LoadingProps) {
  const isLoading = useSelector(getIsLoading);
  const isLoadingEcommerceManagementList = useSelector(
    selectors.ui.ecommerceManagementList.isLoading
  );
  const isLoadingOrders = useSelector(selectors.orders.getLoading);
  const isLoadingProductCategories = useSelector(
    getProductCategoriesState
  ).loading;
  const isLoadingProductionPlanning = useSelector(
    selectors.productionPlanning.getLoading
  );
  const isLoadingTechnicalSpecification = useSelector(
    getTechnicalSpecificationState
  ).loading;
  const isLoadingVolOfferList = useSelector(
    selectors.ui.volOfferList.getLoading
  );
  const isLoadingPlp = useSelector(selectors.plp.getLoading);
  const isLoadingShippingCompany = useSelector(getShippingCompanyLoading);
  const isLoadingStock = useSelector(getStockLoading);
  const isGeneratingOrders = useSelector(
    selectors.autoService.getGeneratingOrders
  );

  const isVisible = useMemo(() => {
    return (
      loading ||
      isLoading ||
      isLoadingEcommerceManagementList ||
      isLoadingOrders ||
      isLoadingPlp ||
      isLoadingProductCategories ||
      isLoadingProductionPlanning ||
      isLoadingTechnicalSpecification ||
      isLoadingShippingCompany ||
      isLoadingStock ||
      isLoadingVolOfferList ||
      isGeneratingOrders
    );
  }, [
    loading,
    isLoading,
    isLoadingEcommerceManagementList,
    isLoadingOrders,
    isLoadingPlp,
    isLoadingProductCategories,
    isLoadingProductionPlanning,
    isLoadingTechnicalSpecification,
    isLoadingShippingCompany,
    isLoadingStock,
    isLoadingVolOfferList,
    isGeneratingOrders
  ]);

  return (
    <Container style={{ display: isVisible ? 'flex' : 'none' }}>
      <Content style={{ display: isVisible ? 'flex' : 'none' }} />
      <img src={image} alt="" />
    </Container>
  );
}

import { AxiosResponse } from 'axios';
import * as Effects from 'redux-saga/effects';

import * as API from '~/domain/api';

import { actions, CreateInit } from './duck';
import { CompetenceEvent } from '~/domain/model';
import * as CompetenceEventService from '~/services/producer/CompetenceEvent';

const call: any = Effects.call;
const put: any = Effects.put;

export function* create(createInit: CreateInit) {
  const {
    competenceId,
    startDate,
    endDate,
    firstDayAvailable,
    lastDayAvailable,
    justification,
    siteId,
    competenceEventType
  } = createInit;
  yield call(
    CompetenceEventService.create,
    {
      competenceId,
      startDate,
      endDate,
      firstDayAvailable,
      lastDayAvailable,
      justification,
      competenceEventType
    },
    siteId
  );
}

export function* findCompetenceEventsByCompetenceID(
  competenceID?: number,
  status?: string,
  pageNumber?: number,
  pageSize?: number
) {
  const response: AxiosResponse = yield call(
    CompetenceEventService.fetchCompetenceEventsByCompetenceID,
    competenceID,
    status,
    pageNumber,
    pageSize
  );
  const apiResponse = response.data as API.DefaultResponse;
  const competenceEvents: API.CompetenceEvent[] = apiResponse.results ?? [];
  const competenceEventByID: Map<string, CompetenceEvent> = new Map(
    competenceEvents.map(competenceEvent => [
      String(competenceEvent.id),
      new CompetenceEvent(competenceEvent)
    ])
  );
  yield put(actions.update({ competenceEventByID }));
  return apiResponse;
}
export function* inactivate(id: number) {
  yield call(CompetenceEventService.inactivate, id);
}

export interface Action<P> {
  readonly type: string;
  readonly payload: P;
}

export const createCreator =
  <P>(type: string) =>
  (payload: P) => {
    const action: Action<P> = { type, payload };
    return action;
  };

export interface Reducer<S, P> extends Function {
  (state: S, action: P): S;
}

export const createReducer = <S>(
  initialState: S,
  reducerByType: Record<string, Reducer<S, any>>
) => {
  return (state: S = initialState, action: Action<any>) => {
    if (Object.prototype.hasOwnProperty.call(reducerByType, action.type))
      return reducerByType[action.type](state, action.payload);
    return state;
  };
};

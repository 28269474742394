import { createSelector } from 'reselect';
import { TechnicalSpecificationsState } from '.';

const getRoot = ({
  technicalSpecification
}: {
  technicalSpecification: TechnicalSpecificationsState;
}) => {
  return technicalSpecification;
};

export const getTechnicalSpecificationState = createSelector(
  [getRoot],
  state => state
);

export const getSubmitFormFilter = createSelector(
  [getRoot],
  state => state.submitFormFilter
);

import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';

import service from '~/services/api';
import * as API from '~/domain/api';

import { actions } from './duck';
import { ProductCategory } from '~/domain/model';

export function* findMany() {
  const URL = `/product-category/`;
  const response: AxiosResponse = yield call(service.get, URL);
  const apiResponse: API.ProductCategory[] =
    response.data as API.ProductCategory[];
  const productCategoriesByID = new Map(
    apiResponse.map((value: API.ProductCategory) => [
      String(value.id),
      new ProductCategory(value)
    ])
  );
  yield put(actions.update({ productCategoriesByID }));
  return apiResponse;
}

export function* findOfferSettingsCategories() {
  const URL = `/product-category/offer-Settings`;
  const response: AxiosResponse = yield call(service.get, URL);
  const apiResponse: API.ProductCategory[] =
    response.data as API.ProductCategory[];
  const productCategoriesByID = new Map(
    apiResponse.map((value: API.ProductCategory) => [
      String(value.id),
      new ProductCategory(value)
    ])
  );
  yield put(actions.update({ productCategoriesByID }));
  return apiResponse;
}

export function* findManyById(siteId: number) {
  const URL = `/product-category/${siteId}`;
  const response: AxiosResponse = yield call(service.get, URL);
  const apiResponse = response.data as API.ProductCategory[];
  const productCategoriesByID = new Map(
    apiResponse.map((value: API.ProductCategory) => [
      String(value.id),
      new ProductCategory(value)
    ])
  );
  yield put(actions.update({ productCategoriesByID }));
  return apiResponse;
}

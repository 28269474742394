import { call, put } from 'redux-saga/effects';

import * as API from '~/domain/api';

import { actions, Upsert } from './duck';
import { OfferSettings } from '~/domain/model';
import * as OfferSettingsService from '~/services/producer/OfferSettings';

export function* fetchBySiteId(siteId: number) {
  const response: API.ServerResponse = yield call(
    OfferSettingsService.fetchBySiteId,
    siteId
  );
  const json: API.OfferSettings = response.data;
  const offerSettings = new OfferSettings(json);
  yield put(actions.merge({ siteId, offerSettings }));
  return response;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function* upsert(params: Upsert) {
  yield call(OfferSettingsService.upsert, params.offerSettings);
}

export function* fetchCountingPatternHistoryBySiteId(siteId: number) {
  const response: API.ServerResponse = yield call(
    OfferSettingsService.fetchCountingPatternHistoryBySiteId,
    siteId
  );
  const json: API.CountingPatternHistory[] = response.data;
  return json;
}

export function* fetchCompositionHistoryBySiteId(siteId: number) {
  const response: API.ServerResponse = yield call(
    OfferSettingsService.fetchCompositionHistoryBySiteId,
    siteId
  );
  const json: API.CompositionHistory[] = response.data;
  return json;
}

export function* fetchMinimumAmountCutProductHistoryBySiteId(siteId: number) {
  const response: API.ServerResponse = yield call(
    OfferSettingsService.fetchMinimumAmountCutProductHistoryBySiteId,
    siteId
  );
  const json: API.MinimumAmountCutProductHistory[] = response.data;
  return json;
}

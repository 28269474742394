import { AxiosResponse } from 'axios';

import AutoserviceRequestsListParam from '../../domain/model/AutoserviceRequestsListParam';
import api, { apiStatus } from '../api';
import { endpoints } from '~/constants/endpoints';
import ecommerceApi from '../ecommerceApi';

export const findAutoserviceRequestsList = async (
  params: AutoserviceRequestsListParam
) => {
  const entries = Object.entries(params);
  let url = endpoints.selfService.requestSearchList;
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of entries) {
    if (key !== 'pagination' && value !== undefined) {
      url += `Data.${key}=${value}&`;
    }
  }
  url += 'PageNumber=1&PageSize=3000';
  const response = await api.get<void, AxiosResponse<any>>(`${url}`);
  return response.data;
};

export const findAutoserviceRequestsListStatus = async (
  params: AutoserviceRequestsListParam
) => {
  const entries = Object.entries(params);
  let url = endpoints.selfService.requestSearchList;
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of entries) {
    if (key !== 'pagination' && value !== undefined) {
      url += `Data.${key}=${value}&`;
    }
  }
  url += 'PageNumber=1&PageSize=3000';
  const response = await apiStatus.get<void, AxiosResponse<any>>(`${url}`);
  return response.data;
};

export const postAutoserviceDetailsRequest = async (payload: any) => {
  const formData = new FormData();
  formData.append('CustomerId', payload.customerId.toString());
  formData.append('Description', payload.description);
  if (payload.observation !== null && payload.observation !== undefined) {
    formData.append('Observation', payload.observation);
  }
  if (payload.xped !== undefined && payload.xped !== '') {
    formData.append('Xped', payload.xped);
  }
  formData.append('BillingDate', payload.billingDate);
  formData.append('DeliveryDate', payload.deliveryDate);
  formData.append('DeliveryTime', payload.hour);
  formData.append('File', payload.document.originFileObj);
  const response = await api.post(endpoints.selfService.postRequest, formData);
  return response;
};

export const cancelAutoserviceDetailsRequest = async (params: number) => {
  const response = await api.put<void, AxiosResponse<any>>(
    `${endpoints.selfService.cancelDetais}${params}`
  );
  return response;
};

export const findAutoserviceDetailsRequest = async (worksheetId: number) => {
  const response = await api.get<void, AxiosResponse<any>>(
    `${endpoints.selfService.searchDetailsRequest}${worksheetId}`
  );
  return response.data;
};

export const findAutoserviceDetailsRequestLines = async (
  worksheetId: number
) => {
  const response = await api.get<void, AxiosResponse<any>>(
    `${endpoints.selfService.searchDetailsLine}${worksheetId}?PageNumber=1&PageSize=300`
  );
  return response.data;
};

export const cancelAutoserviceDetailsLine = async (worksheetLineId: number) => {
  const response = await api.put<void, AxiosResponse<any>>(
    `${endpoints.selfService.cancelDetailsLine}${worksheetLineId}`
  );
  return response;
};

export const finishAutoserviceDetails = async (worksheetId: number) => {
  const response = await api.put<void, AxiosResponse<any>>(
    `${endpoints.selfService.finishOrder}${worksheetId}`
  );
  return response;
};

export const findAutoserviceDetailsOffers = async (worksheetLineId: number) => {
  const response = await api.get<void, AxiosResponse<any>>(
    `${endpoints.selfService.searchOffers}${worksheetLineId}`
  );
  return response.data ? response.data : undefined;
};

export const cancelAutoserviceDetailsOffer = async (
  worksheetOfferId: number
) => {
  const response = await api.put<void, AxiosResponse<any>>(
    `${endpoints.selfService.cancelOffers}${worksheetOfferId}`
  );
  return response;
};

export const postAutoserviceDetailsOffers = async (payload: any) => {
  const response = await api.post(
    endpoints.selfService.addDetailsOffers,
    payload
  );
  return response;
};

export const findAProductsEcommerce = async (params: string) => {
  const response = await ecommerceApi.get<void, AxiosResponse<any>>(
    `${endpoints.selfService.searchProducts}${params}`
  );
  return response.data.offers;
};

export const findFiltersProductsEcommerce = async (params: number) => {
  const response = await ecommerceApi.get<void, AxiosResponse<any>>(
    `${endpoints.selfService.filterProducts}${params}`
  );
  return response.data;
};

export const postNewProduct = async (params: any) => {
  const response = await api.post<void, AxiosResponse<any>>(
    endpoints.selfService.addProduct,
    params
  );
  return response.data;
};

export const findHistory = async (params: number) => {
  const response = await api.get<void, AxiosResponse<any>>(
    `${endpoints.selfService.searchHistory}${params}`
  );
  return response.data;
};

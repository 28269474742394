// #FIXME
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function searchStock(data: any) {
  return {
    type: '@productionPlan/SEARCH_STOCK',
    payload: { data }
  };
}

export function searchStockSuccesss() {
  return {
    type: '@productionPlan/SEARCH_STOCK_SUCCESS',
    payload: {}
  };
}

export function searchStockFailure() {
  return {
    type: '@productionPlan/SEARCH_STOCK_FAILURE'
  };
}

import { createSelector } from '~/helpers/util/selectors';
import { DirectedOffersState } from './duck';

export const getRoot = (state: any) => {
  return state.core.directedOffers as DirectedOffersState;
};

export const getDirectedOffers = createSelector([getRoot], state => {
  return state.directedOffers;
});

export const getDirectedOffersList = createSelector([getRoot], state => {
  return state.directedOffersList;
});

export const getIsCleared = createSelector(
  [getRoot],
  state => state.status === 'cleared'
);

export const getDisableSaveDirectedOffers = createSelector(
  [getRoot],
  state => state.disabledSave
);

import { ProductionLocal, ProductionPropertyAttachment } from '.';
import * as API from '../api';

export default class ProductionProperty {
  private readonly json: API.ProductionProperty;
  private readonly propertyId: number;
  private readonly propertyDescription?: string | null;
  private readonly sequencialCode?: number | null;
  private readonly account?: number | null;
  private readonly tradingName?: string | null;
  private readonly corporateName?: string | null;
  private readonly isTax?: boolean | null;
  private readonly area?: number | null;
  private readonly areaUnitId?: number | null;
  private readonly cityId?: number | null;
  private readonly siteId?: number | null;
  private readonly cityName?: string | null;
  private readonly neighborhood?: string | null;
  private readonly stateId?: number | null;
  private readonly stateAcronym?: string | null;
  private readonly isRent?: boolean | null;
  private readonly rentStartPeriod?: string | null;
  private readonly rentEndPeriod?: string | null;
  private readonly latitude?: number | null;
  private readonly longitude?: number | null;
  private readonly isActive?: boolean | null;
  private readonly locals?: ProductionLocal[];
  private readonly attachments?: ProductionPropertyAttachment[];

  constructor(json: API.ProductionProperty) {
    this.json = json;
    this.propertyId = this.getPropertyId();
    this.propertyDescription = this.getPropertyDescription();
    this.sequencialCode = this.getSequencialCode();
    this.account = this.getAccount();
    this.tradingName = this.getTradingName();
    this.corporateName = this.getCorporateName();
    this.isTax = this.getIsTax();
    this.area = this.getArea();
    this.areaUnitId = this.getAreaUnitId();
    this.cityId = this.getCityId();
    this.siteId = this.getSiteId();
    this.cityName = this.getCityName();
    this.stateId = this.getStateId();
    this.stateAcronym = this.getStateAcronym();
    this.isRent = this.getIsRent();
    this.rentStartPeriod = this.getRentStartPeriod();
    this.rentEndPeriod = this.getRentEndPeriod();
    this.latitude = this.getLatitude();
    this.longitude = this.getLongitude();
    this.isActive = this.getIsActive();
    this.neighborhood = this.getNeighborhood();
  }

  toJSON() {
    return this.json;
  }

  private getPropertyId() {
    return this.json.propertyId ?? Number.NaN;
  }

  private getPropertyDescription() {
    return this.json.propertyDescription ?? '';
  }

  private getSequencialCode() {
    return this.json.sequencialCode ?? Number.NaN;
  }

  private getAccount() {
    return this.json.account ?? Number.NaN;
  }

  private getTradingName() {
    return this.json.tradingName ?? '';
  }

  private getCorporateName() {
    return this.json.corporateName ?? '';
  }

  private getIsTax() {
    return this.json.isTax ?? false;
  }

  private getArea() {
    return this.json.area ?? Number.NaN;
  }

  private getAreaUnitId() {
    return this.json.areaUnitId ?? Number.NaN;
  }

  private getCityId() {
    return this.json.cityId ?? Number.NaN;
  }

  private getSiteId() {
    return this.json.siteId ?? Number.NaN;
  }

  private getCityName() {
    return this.json.cityName ?? '';
  }

  private getNeighborhood() {
    return this.json.neighborhood ?? '';
  }

  private getStateId() {
    return this.json.stateId ?? Number.NaN;
  }

  private getStateAcronym() {
    return this.json.stateAcronym ?? '';
  }

  private getIsRent() {
    return this.json.isRent ?? false;
  }

  private getRentStartPeriod() {
    return this.json.rentStartPeriod ?? null;
  }

  private getRentEndPeriod() {
    return this.json.rentEndPeriod ?? null;
  }

  private getLatitude() {
    return this.json.latitude ?? Number.NaN;
  }

  private getLongitude() {
    return this.json.longitude ?? Number.NaN;
  }

  private getIsActive() {
    return this.json.isActive ?? false;
  }
}

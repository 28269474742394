import { createSelector } from 'reselect';

// #FIXME
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRoot = (state: any) => {
  return state.tabs;
};
export const getSelectedTabPermission = createSelector([getRoot], state => {
  const selectedTabIndex = state.selectedTab;
  const selectedTab = state.tabs[selectedTabIndex];
  return selectedTab?.permissionName ?? '';
});

import * as API from '~/domain/api';

export default class OfferChannel {
  public readonly json: API.OfferChannel;
  public readonly id: number;
  public readonly categoryId: number;
  public readonly percentLkp: number;
  public readonly percentVol: number;
  public readonly categoryDescription: string;

  constructor(json: API.OfferChannel) {
    this.json = json;
    this.id = this.getId();
    this.categoryId = this.getCategoryId();
    this.percentLkp = this.getPercentLkp();
    this.percentVol = this.getPercentVol();
    this.categoryDescription = this.getCategoryDescription();
  }

  private getId(): number {
    return this.json.id ?? Number.NaN;
  }

  private getCategoryId(): number {
    return this.json.categoryId ?? Number.NaN;
  }

  private getPercentLkp(): number {
    return this.json.percentLkp ?? Number.NaN;
  }

  private getPercentVol(): number {
    return this.json.percentVol ?? Number.NaN;
  }

  private getCategoryDescription(): string {
    return this.json.categoryDescription ?? '';
  }
}

import { createSelector } from 'reselect';
import { PlpState } from '.';

export const getRoot = (state: any) => {
  return state.plp as PlpState;
};

export const getAddMassUpdateModalVisible = createSelector(
  [getRoot],
  state => state.addMassUpdateModalVisible
);

export const getAddModalVisible = createSelector(
  [getRoot],
  state => state.addModalVisible
);

export const getAddTableData = createSelector(
  [getRoot],
  state => state.addTableData
);

export const getCloseAddStockModal = createSelector(
  [getRoot],
  state => state.closeAddStockModal
);

export const getCurrentStep = createSelector(
  [getRoot],
  state => state.currentStep
);

export const getDisabledSave = createSelector(
  [getRoot],
  state => state.disabledSave
);

export const getHistoric = createSelector([getRoot], state => state.historic);

export const getHistoricModalVisible = createSelector(
  [getRoot],
  state => state.historicModalVisible
);

export const getHistoricPagination = createSelector(
  [getRoot],
  state => state.historicPagination
);

export const getLoading = createSelector([getRoot], state => state.loading);

export const getMassUpdateModalVisible = createSelector(
  [getRoot],
  state => state.massUpdateModalVisible
);

export const getPagination = createSelector(
  [getRoot],
  state => state.pagination
);

export const getPlps = createSelector([getRoot], state => state.plps);

export const getProductRegisters = createSelector(
  [getRoot],
  state => state.productsRegisters
);

export const getResumeTableData = createSelector(
  [getRoot],
  state => state.resumeTableData
);

export const getSelectedAddKeys = createSelector(
  [getRoot],
  state => state.selectedAddKeys
);

export const getSelectedPlp = createSelector(
  [getRoot],
  state => state.selectedPlp
);

export const getSubmitAddFormFilter = createSelector(
  [getRoot],
  state => state.submitAddFormFilter
);

export const getSubmitFormFilter = createSelector(
  [getRoot],
  state => state.submitFormFilter
);

export const getSubmitNow = createSelector([getRoot], state => state.submitNow);

export const getTableInputErrors = createSelector(
  [getRoot],
  state => state.tableInputErrors
);

export const getTotalHistoric = createSelector(
  [getRoot],
  state => state.totalHistoric
);

export const getTotalPlps = createSelector([getRoot], state => state.totalPlps);

export const getValidated = createSelector([getRoot], state => state.validated);

import { BorderOutlined, CloseSquareOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import moment from 'moment';
import Barcode from 'react-barcode';
import InputMask from 'react-input-mask';
import IGfp from '~/@core/domain/model/gfp/IGfp';
import {
  Address,
  BarcodeContainer,
  BorderStyled,
  Column,
  ColumnItem,
  ContainerFlex,
  DateContainer,
  DateText,
  HorizontalDivider,
  Identifier,
  IdentifierDate,
  MarginSpan,
  NumberBoxContainer,
  SubIdentifier,
  Text,
  TextBold,
  TitleGFPe,
  VerticalDividerLeft,
  VerticalDividerRight
} from '../styles';

interface IHeaderPrintLayoutProps {
  gfp: IGfp;
}

export default function HeaderPrintLayout({ gfp }: IHeaderPrintLayoutProps) {
  return (
    <>
      <Column height="10.45%">
        <ColumnItem width="30%">
          <BorderStyled padding="0.10rem">
            <BarcodeContainer>
              <Identifier as="h2">
                CHAVE DE ACESSO <TextBold />
              </Identifier>
              <Barcode
                value={gfp.accessKey}
                width={4}
                height={100}
                marginLeft={-430}
                marginBottom={30}
                fontSize={40}
              />
              <NumberBoxContainer>
                <HorizontalDivider style={{ top: '-3px' }} />
              </NumberBoxContainer>
              <SubIdentifier style={{ fontSize: '7px', margin: '0 0 0 0' }}>
                <TextBold style={{ margin: '3px 3px 0 0' }}>PROTOCOLO</TextBold>{' '}
                {gfp.protocol} <TextBold> DATA / HORA</TextBold>{' '}
                {moment(gfp.issueDate).format('DD/MM/YYYY HH:mm:ss')}
              </SubIdentifier>
            </BarcodeContainer>
          </BorderStyled>
        </ColumnItem>
        <ColumnItem width="24.4%">
          <BorderStyled padding="0.10rem">
            {gfp.producer.address.includes('SP') ? (
              <TitleGFPe>GFP-e</TitleGFPe>
            ) : (
              <TitleGFPe>GFP-Digital</TitleGFPe>
            )}
            <Text style={{ fontSize: '7px' }}>
              GUIA DE FORNECIMENTO DE PRODUTO ELETRÔNICA
            </Text>
            <SubIdentifier>
              SÉRIE:{' '}
              <TextBold as={MarginSpan} style={{ fontSize: '100%' }}>
                {gfp.series}
              </TextBold>
              N°: <TextBold style={{ fontSize: '100%' }}>{gfp.number}</TextBold>
            </SubIdentifier>
          </BorderStyled>
        </ColumnItem>
        <ColumnItem width="23%">
          <BorderStyled padding="2px 10px">
            <Identifier as="h1" style={{ marginBottom: '8px' }}>
              EMITENTE
            </Identifier>
            <Address>ROD. SP 107 KM 27 - S/Nº - ZONA RURAL </Address>
            <Address>STO. ANTÔNIO DE POSSE - SP - CEP 13833-585</Address>
            <Address>CNPJ: 03.630.560/0001-18</Address>
            <Address>IE: 630.011.957.118</Address>
          </BorderStyled>
        </ColumnItem>
        <ColumnItem width="22.6%">
          <BorderStyled padding="0.1rem">
            <BarcodeContainer>
              <Identifier as="h2">CONTROLE DE RETORNO</Identifier>
              <Barcode
                value={gfp.barcode}
                format="CODE128"
                width={3}
                height={60}
                marginLeft={-90}
              />
            </BarcodeContainer>
          </BorderStyled>
        </ColumnItem>
      </Column>

      <Column height="6%">
        <ColumnItem width="14.86%">
          <BorderStyled padding="0.10rem">
            <BarcodeContainer>
              <Identifier as="h2">
                <TextBold>LOCAL RETIRADA CLIENTE</TextBold>
              </Identifier>
              <ContainerFlex>
                <Col>
                  {gfp.pickupLocation === 'CVH' ? (
                    <CloseSquareOutlined
                      style={{ marginLeft: '10px', fontSize: '18px' }}
                    />
                  ) : (
                    <BorderOutlined style={{ fontSize: '18px' }} />
                  )}
                </Col>
                <Col
                  style={{
                    marginRight: '15px',
                    marginLeft: 'px'
                  }}
                >
                  <IdentifierDate as="h2" style={{ fontSize: '8px' }}>
                    CVH
                  </IdentifierDate>
                </Col>
                <Col>
                  {gfp.pickupLocation === 'SITIO' ? (
                    <CloseSquareOutlined style={{ fontSize: '18px' }} />
                  ) : (
                    <BorderOutlined style={{ fontSize: '18px' }} />
                  )}
                </Col>
                <Col
                  style={{
                    marginLeft: '3px'
                  }}
                >
                  <IdentifierDate as="h2" style={{ fontSize: '8px' }}>
                    SÍTIO
                  </IdentifierDate>
                </Col>
              </ContainerFlex>
            </BarcodeContainer>
          </BorderStyled>
        </ColumnItem>

        <ColumnItem width="30.97%">
          <BorderStyled padding="0.10rem">
            <Identifier as="h2">DIVISÃO COMERCIAL</Identifier>
            <ContainerFlex>
              <Col style={{ marginLeft: '15px' }}>
                {gfp.division === 'K' ? (
                  <CloseSquareOutlined style={{ fontSize: '18px' }} />
                ) : (
                  <BorderOutlined style={{ fontSize: '18px' }} />
                )}
              </Col>
              <Col style={{ marginLeft: '3px' }}>
                <IdentifierDate as="h2" style={{ fontSize: '8px' }}>
                  KLOK
                </IdentifierDate>
              </Col>
              <Col style={{ marginLeft: '18px' }}>
                {gfp.division === 'I' ? (
                  <CloseSquareOutlined style={{ fontSize: '18px' }} />
                ) : (
                  <BorderOutlined style={{ fontSize: '18px' }} />
                )}
              </Col>
              <Col style={{ marginLeft: '3px' }}>
                <IdentifierDate as="h2" style={{ fontSize: '8px' }}>
                  INTERMEDIAÇÃO
                </IdentifierDate>
              </Col>
              <Col style={{ marginLeft: '18px' }}>
                {gfp.division === 'E' ? (
                  <CloseSquareOutlined style={{ fontSize: '18px' }} />
                ) : (
                  <BorderOutlined style={{ fontSize: '18px' }} />
                )}
              </Col>
              <Col style={{ marginLeft: '3px' }}>
                <IdentifierDate as="h2" style={{ fontSize: '7px' }}>
                  EXPORTAÇÃO
                </IdentifierDate>
              </Col>
            </ContainerFlex>
          </BorderStyled>
        </ColumnItem>

        <ColumnItem width="54.17%">
          <BorderStyled padding="0.08rem">
            <Row justify="space-between">
              <Col span={24}>
                <IdentifierDate as="h2">DATAS</IdentifierDate>
              </Col>
              <Col>
                <DateContainer>
                  <IdentifierDate as="h2">EMISSÃO</IdentifierDate>
                  <DateText style={{ marginBottom: '70px' }}>
                    {moment(gfp.issueDate).format('DD/MM/YYYY')}
                  </DateText>
                </DateContainer>
                <VerticalDividerLeft />
              </Col>
              <Col>
                <DateContainer>
                  <IdentifierDate as="h2">SAÍDA</IdentifierDate>
                  <DateText>
                    {moment(gfp.departureDate).format('DD/MM/YYYY')}
                  </DateText>
                </DateContainer>
                <VerticalDividerRight />
              </Col>
              <Col>
                <DateContainer>
                  <IdentifierDate as="h2">COMERCIAL</IdentifierDate>
                  <DateText>
                    {moment(
                      gfp.division === 'I'
                        ? gfp.startTradeDate
                        : gfp.departureDate
                    ).format('DD/MM/YYYY')}{' '}
                    <span>até</span>{' '}
                    {moment(gfp.tradeDate).format('DD/MM/YYYY')}
                  </DateText>
                </DateContainer>
              </Col>
            </Row>
          </BorderStyled>
        </ColumnItem>
      </Column>

      <Column height="6%">
        <ColumnItem width="10.08%">
          <BorderStyled padding="0.10rem">
            <BarcodeContainer>
              <Identifier as="h2">
                CÓD. CLIENTE <TextBold />
              </Identifier>
              <TextBold
                style={{
                  fontSize: '12px',
                  textAlign: 'center',
                  marginBottom: '8px'
                }}
              >
                {gfp.customer?.code !== null ? gfp.customer?.code : ''}
              </TextBold>
            </BarcodeContainer>
          </BorderStyled>
        </ColumnItem>
        <ColumnItem width="67.96%">
          <BorderStyled padding="0.10rem">
            <Identifier as="h2">CLIENTE</Identifier>
            <TextBold style={{ fontSize: '12px' }}>
              {gfp.customer?.name !== null ? gfp.customer?.name : ''}
            </TextBold>
          </BorderStyled>
        </ColumnItem>
        <ColumnItem width="13.5%">
          <BorderStyled padding="0.10rem">
            <Identifier as="h1">ENDEREÇO DO CLIENTE</Identifier>
            <TextBold style={{ fontSize: '12px', textAlign: 'center' }} />
          </BorderStyled>
        </ColumnItem>
        <ColumnItem width="13.5%">
          <BorderStyled padding="0.10rem">
            <BarcodeContainer>
              <Identifier as="h2">PEDIDO</Identifier>
              <TextBold
                style={{
                  fontSize: '12px',
                  textAlign: 'center',
                  marginBottom: '8px'
                }}
              >
                {gfp.orderNumber}
              </TextBold>
            </BarcodeContainer>
          </BorderStyled>
        </ColumnItem>
      </Column>

      <Column height="6%">
        <ColumnItem width="19.46%">
          <BorderStyled padding="0.10rem">
            <BarcodeContainer>
              <Identifier as="h2">CNPJ DO PRODUTOR</Identifier>
              <TextBold
                style={{
                  fontSize: '12px',
                  textAlign: 'center',
                  marginBottom: '8px'
                }}
              >
                {gfp.producer.cnpj}
              </TextBold>
            </BarcodeContainer>
          </BorderStyled>
        </ColumnItem>
        <ColumnItem width="9.02%">
          <BorderStyled padding="0.10rem">
            <BarcodeContainer>
              <Identifier as="h2">
                CÓD. PROD <TextBold />
              </Identifier>
              <TextBold
                style={{
                  fontSize: '12px',
                  textAlign: 'center',
                  marginBottom: '8px'
                }}
              >
                {gfp.producer.code}
              </TextBold>
            </BarcodeContainer>
          </BorderStyled>
        </ColumnItem>
        <ColumnItem width="53.45%">
          <BorderStyled padding="0.10rem">
            <BarcodeContainer>
              <Identifier as="h2">
                PRODUTOR <TextBold />
              </Identifier>
              <TextBold style={{ fontSize: '12px', marginBottom: '8px' }}>
                {gfp.producer.name}
              </TextBold>
            </BarcodeContainer>
          </BorderStyled>
        </ColumnItem>
        <ColumnItem width="18.07%">
          <BorderStyled padding="0.10rem">
            <BarcodeContainer>
              <Identifier as="h2">
                IE <TextBold />
              </Identifier>
              <TextBold
                style={{
                  fontSize: '12px',
                  textAlign: 'center',
                  marginBottom: '8px'
                }}
              >
                <InputMask
                  mask="999.999.999.999"
                  value={gfp.producer.ie}
                  style={{ width: '100%', border: 'none', textAlign: 'center' }}
                />
              </TextBold>
            </BarcodeContainer>
          </BorderStyled>
        </ColumnItem>
      </Column>

      <Column height="6%">
        <ColumnItem width="8.31%">
          <BorderStyled padding="0.10rem">
            <BarcodeContainer>
              <Identifier as="h2" style={{ textAlign: 'center' }}>
                MULTI-BOLETAS
              </Identifier>
              <TextBold
                style={{
                  fontSize: '12px',
                  textAlign: 'center',
                  marginBottom: '8px'
                }}
              >
                {gfp.multiPosition} de {gfp.multiTotal}
              </TextBold>
            </BarcodeContainer>
          </BorderStyled>
        </ColumnItem>
        <ColumnItem width="4.60%">
          <BorderStyled padding="0.10rem">
            <BarcodeContainer>
              <Identifier as="h2" style={{ textAlign: 'center' }}>
                CARRINHO
              </Identifier>
              <TextBold
                style={{
                  fontSize: '12px',
                  textAlign: 'center',
                  marginBottom: '8px'
                }}
              >
                {gfp.trolleyQuantity}
              </TextBold>
            </BarcodeContainer>
          </BorderStyled>
        </ColumnItem>
        <ColumnItem width="4.60%">
          <BorderStyled padding="0.10rem">
            <BarcodeContainer>
              <Identifier as="h2" style={{ textAlign: 'center' }}>
                DIVISÓRIA
              </Identifier>
              <TextBold
                style={{
                  fontSize: '12px',
                  textAlign: 'center',
                  marginBottom: '8px'
                }}
              >
                {gfp.partitionQuantity}
              </TextBold>
            </BarcodeContainer>
          </BorderStyled>
        </ColumnItem>
        <ColumnItem width="4.60%">
          <BorderStyled padding="0.10rem">
            <BarcodeContainer>
              <Identifier as="h2" style={{ textAlign: 'center' }}>
                EXTENSOR{' '}
              </Identifier>
              <TextBold
                style={{
                  fontSize: '12px',
                  textAlign: 'center',
                  marginBottom: '8px'
                }}
              >
                0
              </TextBold>
            </BarcodeContainer>
          </BorderStyled>
        </ColumnItem>
        <ColumnItem width="4.60%">
          <BorderStyled padding="0.10rem">
            <BarcodeContainer>
              <Identifier as="h2" style={{ textAlign: 'center' }}>
                SUPORTE PEQUENO{' '}
              </Identifier>
              <TextBold
                style={{
                  fontSize: '12px',
                  textAlign: 'center',
                  marginBottom: '8px'
                }}
              >
                0
              </TextBold>
            </BarcodeContainer>
          </BorderStyled>
        </ColumnItem>
        <ColumnItem width="4.60%">
          <BorderStyled padding="0.10rem">
            <BarcodeContainer>
              <Identifier as="h2" style={{ textAlign: 'center' }}>
                SUPORTE GRANDE{' '}
              </Identifier>
              <TextBold
                style={{
                  fontSize: '12px',
                  textAlign: 'center',
                  marginBottom: '8px'
                }}
              >
                0
              </TextBold>
            </BarcodeContainer>
          </BorderStyled>
        </ColumnItem>
        <ColumnItem width="68.69%">
          <BorderStyled padding="0.10rem" style={{ fontSize: '12px' }}>
            <Identifier as="h2">
              ENDEREÇO DO PRODUTOR <TextBold />
            </Identifier>
            <TextBold style={{ fontSize: '12px', marginBottom: '8px' }}>
              {gfp.producer.address.length > 100
                ? `${gfp.producer.address.substring(0, 85)}`
                : gfp.producer.address}
            </TextBold>
          </BorderStyled>
        </ColumnItem>
      </Column>
    </>
  );
}

import { FormInstance, Select, SelectProps } from 'antd';
import { AxiosError } from 'axios';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GetShippingCompany from '~/@core/application/useCases/inputs/getShippingCompany';
import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';
import ShippingCompany from '~/@core/domain/model/site/ShippingCompany';
import { globalContext } from '~/context/GlobalContext';

interface Props {
  form: FormInstance<any>;
  siteId: number;
  formFieldName: string;
  style?: React.CSSProperties;
}

export default function ShippingCompanySelect(props: Props & SelectProps) {
  const { form, siteId, formFieldName, style } = props;
  const {
    handlePromiseUseCase,
    activeAlert,
    selectedTransporter,
    setSelectedTransporter
  } = useContext(globalContext);

  const { t } = useTranslation();
  const [shippingCompanies, setShippingCompanies] = useState<ShippingCompany[]>(
    []
  );

  const onChangeSite = useCallback(() => {
    setShippingCompanies([]);
  }, []);

  useEffect(() => {
    if (siteId) {
      const getShippingCompanies = new GetShippingCompany();

      handlePromiseUseCase<DefaultPaginated<ShippingCompany[]>, AxiosError>(
        getShippingCompanies.execute(siteId),
        shippOut => {
          setShippingCompanies(shippOut.results);

          if (!selectedTransporter) {
            const favoriteTransporter = shippOut.results.find(
              ship => ship.favorite
            );

            if (favoriteTransporter) {
              form.setFieldValue(formFieldName, favoriteTransporter.id);
              setSelectedTransporter(favoriteTransporter);
            } else if (shippOut.results.length > 0) {
              form.setFieldValue(formFieldName, shippOut.results[0].id);
              setSelectedTransporter(shippOut.results[0]);
            } else {
              setSelectedTransporter(null);
            }
          }
        },
        error => {
          activeAlert({
            message: JSON.stringify(
              error.response?.data
                ? error.response?.data
                : 'Houve um erro inesperado!'
            ),
            type: 'error',
            timeout: 5000
          });
        }
      );

      return () => {
        onChangeSite();
        setSelectedTransporter(null);
        form.setFieldValue(formFieldName, null);
      };
    }
  }, [siteId, setSelectedTransporter]);

  const handleTransporterChange = (value: number) => {
    const transporter = shippingCompanies.find(ship => ship.id === value);
    setSelectedTransporter(transporter ?? null);
    form.setFieldValue(formFieldName, value);
  };

  const selectedTransporterId = selectedTransporter?.id ?? null;

  return (
    <Select
      placeholder={t('generals.selectPlaceholder')}
      allowClear
      optionFilterProp="children"
      filterOption
      showSearch
      onChange={handleTransporterChange}
      value={selectedTransporterId}
      style={style}
    >
      {shippingCompanies?.map((shippingCompany: ShippingCompany) => (
        <Select.Option key={shippingCompany.id} value={shippingCompany.id}>
          {shippingCompany.name}
        </Select.Option>
      ))}
    </Select>
  );
}

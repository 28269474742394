import * as API from '~/domain/api';

export default class CartStatus {
  public json: API.CartStatus;
  public code: string;
  public description: string;

  constructor(json: API.CartStatus) {
    this.json = json;
    this.code = this.json.code;
    this.description = this.json.description;
  }
}

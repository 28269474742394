/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type FetchStatus =
  | 'idle'
  | 'fetching'
  | 'submitting'
  | 'success'
  | 'failure'
  | 'failure-already-registered';

export type DeliveryPatternListState = {
  fetchStatus: FetchStatus;
  siteId: number;
  submitForm: boolean;
};
export type DeliveryPatternEnable = {
  deliveryPatternId: number;
  siteId: number | undefined;
  enabled: boolean;
};
export type FetchDeliveryPatterns = {
  siteId: number | undefined;
  enabled?: boolean;
};
const initialState: DeliveryPatternListState = {
  fetchStatus: 'idle',
  siteId: Number.NaN,
  submitForm: false
};

const prefix = '@deliveryPatternList';

const deliveryPattern = createSlice({
  name: prefix,
  initialState,
  reducers: {
    fetchBySiteId(state, action: PayloadAction<FetchDeliveryPatterns>) {
      state.fetchStatus = 'fetching';
    },
    deletePattern(state, action: PayloadAction<DeliveryPatternEnable>) {
      state.fetchStatus = 'fetching';
    },
    favoritePattern(state, action: PayloadAction<number>) {
      state.fetchStatus = 'fetching';
    },
    fetchSuccess(state) {
      state.fetchStatus = 'success';
    },
    fetchFailure(state) {
      state.fetchStatus = 'failure';
    },
    setSiteId(state, action: PayloadAction<number>) {
      state.siteId = action.payload;
    },
    setSubmitForm(state, action: PayloadAction<boolean>) {
      state.submitForm = action.payload;
    },
    clear(state) {
      state.siteId = Number.NaN;
    }
  }
});

export const actions = deliveryPattern.actions;
export const reducer = deliveryPattern.reducer;

const caseReducers = deliveryPattern.caseReducers;
export const Types = Object.freeze({
  FETCH_BY_SITE_ID: `${prefix}/${caseReducers.fetchBySiteId.name}`,
  DELETE: `${prefix}/${caseReducers.deletePattern.name}`,
  FAVORITE: `${prefix}/${caseReducers.favoritePattern.name}`
});

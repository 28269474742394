import { createSelector } from 'reselect';
import { DirectedOffersListState } from './duck';

// #FIXME Remover any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRoot = (state: any) => {
  return state.ui.directedOfferList as DirectedOffersListState;
};
export const getPagination = createSelector(
  [getRoot],
  state => state.pagination
);

export const getHistory = createSelector([getRoot], state => state.history);

export const getWaitEditOpen = createSelector(
  [getRoot],
  state => state.waitEditOpen
);

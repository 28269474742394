import * as API from '~/domain/api';

export default class PriceAlert {
  public readonly json: API.PriceAlert;
  public readonly id: number;
  public readonly categoryId: number;
  public readonly percentAboveAverage: number;
  public readonly percentBelowAverage: number;
  public readonly categoryDescription: string;

  constructor(json: API.PriceAlert) {
    this.json = json;
    this.id = this.getId();
    this.categoryId = this.getCategoryId();
    this.percentAboveAverage = this.getPercentAboveAverage();
    this.percentBelowAverage = this.getPercentBelowAverage();
    this.categoryDescription = this.getCategoryDescription();
  }

  private getId(): number {
    return this.json.id ?? Number.NaN;
  }

  private getCategoryId(): number {
    return this.json.categoryId ?? Number.NaN;
  }

  private getPercentAboveAverage(): number {
    return this.json.percentAboveAverage ?? Number.NaN;
  }

  private getPercentBelowAverage(): number {
    return this.json.percentBelowAverage ?? Number.NaN;
  }

  private getCategoryDescription(): string {
    return this.json.categoryDescription ?? '';
  }
}

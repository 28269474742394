import pt from './default.json';
import en from './default.en.json';
import menuPt from '~/components/Menu/locales/default.json';
import menuEn from '~/components/Menu/locales/default.en.json';
import productorPreferencePt from '~/components/pages/ProducerPreferences/locales/default.json';
import productorPreferenceEn from '~/components/pages/ProducerPreferences/locales/default.en.json';
import volLkpPt from '~/components/pages/VolLkp/locales/default.json';
import volLkpEn from '~/components/pages/VolLkp/locales/default.en.json';
import otherPlatformsPt from '~/components/pages/OtherPlatforms/locales/default.json';

pt['pt-BR'].menu = menuPt['pt-BR'];
en['en-US'].menu = menuEn['en-US'];


pt['pt-BR'].productorPreferences = productorPreferencePt['pt-BR'];
en['en-US'].productorPreferences = productorPreferenceEn['en-US'];

pt['pt-BR'].volLkp = volLkpPt['pt-BR'];
en['en-US'].volLkp = volLkpEn['en-US'];

pt['pt-BR'].otherPlatforms = otherPlatformsPt['pt-BR'];

export { pt, en };

import * as API from '~/domain/api';

export default class CompetenceEvent {
  private json: API.CompetenceEvent;
  public readonly id: number;
  public readonly competenceId: number;
  public readonly startDate: string;
  public readonly endDate: string;
  public readonly status: string;
  public readonly disabledAt: string;
  public readonly startWeekId: number;
  public readonly endWeekId: number;
  public readonly firstDayAvailable: string;
  public readonly lastDayAvailable: string;
  public readonly justification: string;
  public readonly limiters: string;
  public readonly competenceEventType: number;
  public readonly sites?: API.EventSite[];
  public readonly startWeekNumber: number;
  public readonly endWeekNumber: number;

  constructor(json: API.CompetenceEvent) {
    this.json = json;
    this.id = this.getId();
    this.competenceId = this.getCompetenceId();
    this.startDate = this.getStartDate();
    this.endDate = this.getEndDate();
    this.status = this.getStatus();
    this.disabledAt = this.getDisabledAt();
    this.startWeekId = this.getStartWeekId();
    this.endWeekId = this.getEndWeekId();
    this.firstDayAvailable = this.getFirstDayAvailable();
    this.lastDayAvailable = this.getLastDayAvailable();
    this.justification = this.getJustification();
    this.limiters = this.getLimiters();
    this.competenceEventType = this.getCompetenceEventType();
    this.sites = this.getSites();
    this.startWeekNumber = json.startWeekNumber ?? 0;
    this.endWeekNumber = json.endWeekNumber ?? 0;
  }

  private getId(): number {
    return this.json.id ?? Number.NaN;
  }

  private getCompetenceId(): number {
    return this.json.competenceId ?? Number.NaN;
  }

  private getStartDate(): string {
    return this.json.startDate ?? '';
  }

  private getEndDate(): string {
    return this.json.endDate ?? '';
  }

  private getStatus(): string {
    return this.json.status ?? 'INATIVADO';
  }

  private getDisabledAt(): string {
    return this.json.disabledAt ?? '';
  }

  private getStartWeekId(): number {
    return this.json.startWeekId ?? Number.NaN;
  }

  private getEndWeekId(): number {
    return this.json.endWeekId ?? Number.NaN;
  }

  private getFirstDayAvailable(): string {
    return this.json.firstDayAvailable ?? '';
  }

  private getLastDayAvailable(): string {
    return this.json.lastDayAvailable ?? '';
  }

  private getJustification(): string {
    return this.json.justification ?? '';
  }

  private getLimiters(): string {
    return this.json.limiters ?? '';
  }

  public isActive(): boolean {
    return this.status === 'ATIVO';
  }

  public getAvailableWeek(): string {
    return `${this.firstDayAvailable} => ${this.lastDayAvailable}`;
  }

  public getCompetenceEventType(): number {
    return this.json.competenceEventType ?? Number.NaN;
  }

  public getSites(): API.EventSite[] {
    return this.json.sites ?? [];
  }
}

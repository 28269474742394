import {
  Col,
  Input,
  InputNumber,
  InputNumberProps,
  InputProps,
  Row,
  Tabs
} from 'antd';
import styled from 'styled-components';

export const TabNumber = styled.span<{ selected: boolean }>`
  background: ${props => (props.selected ? '#f78e1e' : '#fff')};
  border-radius: 100%;
  border-color: ${props => (props.selected ? '#f78e1e' : '#BFBFBF')};
  border: 1px solid;
  color: ${props => (props.selected ? '#fff' : '#BFBFBF')};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin-right: 8px;
  width: 32px;
  height: 32px;
`;
export const ColTab = styled(Col)`
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const TabsRow = styled(Row)`
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 3px;
`;
export const FiltersTableRow = styled(Row)`
  margin-top: 10px;
`;
export const InputTable = styled(Input)<InputProps>`
  width: 90px !important;
`;
export const InputTableNumber = styled(InputNumber)<InputNumberProps>`
  width: 100px !important;
`;
export const TabContainerAddKlokGFP = styled(Tabs)`
  .ant-form-item-label {
    padding-bottom: 2px;
  }
  .ant-tabs-tab {
    border: none;
    box-shadow: none;
    padding: 6px 140px 6px !important;
  }

  label {
    font: 12px 'Roboto', sans-serif;
    color: #353839;
  }

  .ant-tabs-nav {
    margin-bottom: 4px;

    &::before {
      position: absolute;
      right: 0;
      left: 0;
      top: 100%;
      border-bottom: 1px solid #d9d9d9;
      content: '';
    }
  }

  .tab-default-content {
    margin-left: -10px;
    margin-right: -10px;
  }
`;

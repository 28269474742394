import * as API from 'domain/api';

export default class SalesReportVol {
  public readonly json: API.SalesReportVol;

  public readonly volOfferId: number;
  public readonly productId: number;
  public readonly productLongDescription: string;
  public readonly qualityId: number;
  public readonly quality: string;
  public readonly quantityStock?: number;
  public readonly quantityConsumed: number;
  public readonly amount: number;
  public readonly volReportDetail: API.SalesReportVolDetail[];

  constructor(json: API.SalesReportVol) {
    this.json = json;
    this.volOfferId = json.volOfferId ?? Number.NaN;
    this.productId = json.productId ?? Number.NaN;
    this.productLongDescription = json.productLongDescription ?? '';
    this.qualityId = json.qualityId ?? Number.NaN;
    this.quality = json.quality ?? '';
    this.quantityStock = json.quantityStock ?? Number.NaN;
    this.quantityConsumed = json.quantityConsumed ?? Number.NaN;
    this.amount = json.amount ?? Number.NaN;
    this.volReportDetail = json.volReportDetail ?? [];
  }
}

import * as API from '../api';

export default class VolOfferType implements API.VolOfferType {
  private _id: number;
  private _abbrev: string;
  private _name: string;

  public get id(): number {
    return this._id ?? Number.NaN;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get abbrev(): string {
    return this._abbrev ?? '';
  }

  public set abbrev(value: string) {
    this._abbrev = value;
  }

  public get name(): string {
    return this._name ?? '';
  }

  public set name(value: string) {
    this._name = value;
  }

  constructor(json: API.VolOfferType) {
    this._id = json.id;
    this._abbrev = json.abbrev;
    this._name = json.name;
  }
}

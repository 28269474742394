import { ProductPreferencesListState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.ui.productpreferencesList as ProductPreferencesListState;
};

export const getIsProductPreferenceLoading = createSelector(
  [getRoot],
  state => state.fetchStatus === 'fetching'
);
export const getFetchMessage = createSelector(
  [getRoot],
  state => state.fetchMessage
);
export const getFetchStatus = createSelector(
  [getRoot],
  state => state.fetchStatus
);
export const getPagination = createSelector(
  [getRoot],
  state => state.pagination
);

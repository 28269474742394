import { PlusOutlined } from '@ant-design/icons';
import { Collapse, Table } from 'antd';
import styled from 'styled-components';

export const CheckboxContainer = styled.label`
  display: inline-block;
  padding: 8px;
  cursor: pointer;
  .ant-checkbox {
    padding: 50px;
    display: none;
  }
`;

export const StyledTable = styled(Table)`
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: transparent;
  }
`;

export const GroupTitleInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const SpanLabel = styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  color: #8c8c8c;
`;

export const StyledText = styled.p`
  padding: 0;
  margin: 0;
`;

export const StyledSubText = styled.p`
  color: #f78e1e;
  padding: 5px 0 0 0;
  margin: 0;
`;

export const FrameStyled = styled(Collapse)`
  font: 16px 'Roboto', sans-serif;
  font-weight: 400;
  .ant-collapse-header {
    display: flex;
    flex-wrap: wrap !important;
  }
`;

export const PlusOutlinedStyled = styled(PlusOutlined)`
  cursor: pointer;
`;

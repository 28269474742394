import { SpecialDateListState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.ui.specialDateList as SpecialDateListState;
};

export const getFetchStatus = createSelector(
  [getRoot],
  state => state.fetchStatus
);

export const getDeleteStatus = createSelector(
  [getRoot],
  state => state.deleteStatus
);

export const getDeleteManyStatus = createSelector(
  [getRoot],
  state => state.deleteManyStatus
);

export const getErrorMessage = createSelector([getRoot], state => {
  return state.errorMessage;
});

export const getIsLoading = createSelector(
  [getFetchStatus, getDeleteStatus, getDeleteManyStatus],
  (fetchStatus, deleteStatus, deleteManyStatus) =>
    fetchStatus === 'fetching' ||
    deleteStatus === 'deleting' ||
    deleteManyStatus === 'deleting'
);

export const getIsSuccess = createSelector(
  [getFetchStatus, getDeleteStatus, getDeleteManyStatus],
  (fetchStatus, deleteStatus, deleteManyStatus) =>
    fetchStatus === 'success' ||
    deleteStatus === 'success' ||
    deleteManyStatus === 'success'
);

export const getDeleteStatusIsSuccess = createSelector(
  [getDeleteStatus, getDeleteManyStatus],
  (deleteStatus, deleteManyStatus) =>
    deleteStatus === 'success' || deleteManyStatus === 'success'
);

export const getDeleteStatusIsFailure = createSelector(
  [getDeleteStatus, getDeleteManyStatus],
  (deleteStatus, deleteManyStatus) =>
    deleteStatus === 'failure' || deleteManyStatus === 'failure'
);

export const getPageNumber = createSelector([getRoot], state => {
  return state.pageNumber;
});

export const getPageSize = createSelector([getRoot], state => state.pageSize);

export const getTotalCount = createSelector(
  [getRoot],
  state => state.totalCount
);

import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  Types,
  DirectedOfferReloadAction,
  DirectedOffersListAction
} from './duck';
import * as DirectedOfferService from '../../_core/directedOffers/service';
import * as DeliveryPatternService from '../../_core/deliveryPattern/service';
import * as VoucherService from '../../_core/vouchers/service';
import { getPagination } from './selectors';
import * as ProductService from '~/store/modules/_core/product/service';
import * as ProductCategoriesService from '~/store/modules/_core/productCategories/service';
import * as ProductGroupService from '~/store/modules/_core/productionProductGroups/service';
import { alertRequest } from '../../alert/actions';
import { actions as actionsLoading } from '../loading';

export function* onReloadAllData(
  action: PayloadAction<DirectedOfferReloadAction>
): any {
  try {
    const { directedOfferFilters } = action.payload;
    yield call(fetchFilterData, {
      payload: directedOfferFilters.SiteId,
      type: Types.fetchFilterData
    });
    yield call(fetch, {
      payload: directedOfferFilters,
      type: Types.fetch
    });
  } catch (error: any) {
    yield put(alertRequest(error.message, 'error'));
  }
}

export function* fetchFilterData(action: PayloadAction<number>): any {
  try {
    yield call(DeliveryPatternService.findManyBySiteId, {
      siteId: action.payload,
      enabled: true
    });
    yield call(ProductCategoriesService.findManyById, action.payload);
    yield call(ProductService.findQualities);
    yield call(ProductService.findMany, action.payload);
    yield call(ProductGroupService.findMany, { siteId: action.payload });
    yield call(ProductService.findGroups, action.payload);
    yield call(ProductService.findRecipients, action.payload);
  } catch (error: any) {
    yield put(alertRequest(error.message, 'error'));
  }
}

export function* fetch(action: PayloadAction<DirectedOffersListAction>): any {
  try {
    const pagination = yield select(getPagination);
    yield call(DeliveryPatternService.findManyBySiteId, {
      siteId: action.payload.SiteId,
      enabled: true
    });
    yield call(
      VoucherService.findVouchersByFilters,
      undefined,
      undefined,
      'true',
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      action.payload.SiteId
    );
    yield call(DirectedOfferService.findMany, {
      ...action.payload,
      pagination
    });
  } catch (error: any) {
    yield put(alertRequest(error.message, 'error'));
  }
}
export function* active(action: PayloadAction<number>) {
  try {
    yield call(DirectedOfferService.active, action.payload);

    yield put(actionsLoading.setSubmitForm(true));
  } catch (error: any) {
    yield put(alertRequest(error.message, 'error'));
  }
}
export function* fetchHistory(action: PayloadAction<number>) {
  try {
    yield call(DirectedOfferService.findHistory, action.payload);
  } catch (error: any) {
    yield put(alertRequest(error.message, 'error'));
  }
}
export default [
  takeLatest(Types.fetch, fetch),
  takeLatest(Types.fetchHistory, fetchHistory),
  takeLatest(Types.fetchFilterData, fetchFilterData),
  takeLatest(Types.active, active),
  takeLatest(Types.reloadAllData, onReloadAllData)
];

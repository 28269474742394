import { UploadFile } from 'antd/lib/upload/interface';
import produce from 'immer';
import { ApplicationError } from '~/domain/api/errors';
import { createCreator, createReducer, Reducer } from '~/helpers/util/reducers';

export type Status = 'idle' | 'pending' | 'success' | 'failure';

export type AnnouncementCreateState = {
  createStatus: Status;
  getByIdStatus: Status;
  getFilesByIdStatus: Status;
  errorMessage?: string;
};

export type FetchFailure = {
  error?: ApplicationError;
};

export type Create = {
  environment: number;
  role: number;
  description: string;
  required: boolean;
  startDate: string;
  endDate: string;
  content: string;
  image?: UploadFile;
  documents?: UploadFile[];
  id?: number;
};

export type Error = {
  errorMessage?: string;
};

const prefix = '@announcementCreate';

export const Types = Object.freeze({
  CLEAR: `${prefix}/CLEAR`,
  CREATE_INIT: `${prefix}/CREATE_INIT`,
  CREATE_SUCCESS: `${prefix}/CREATE_SUCCESS`,
  CREATE_FAILURE: `${prefix}/CREATE_FAILURE`,
  GET_BY_ID: `${prefix}/GET_BY_ID`,
  GET_BY_ID_SUCCESS: `${prefix}/GET_BY_ID_SUCCESS`,
  GET_BY_ID_FAILURE: `${prefix}/GET_BY_ID_FAILURE`,
  GET_FILES_BY_ID: `${prefix}/GET_FILES_BY_ID`,
  GET_FILES_BY_ID_SUCCESS: `${prefix}/GET_FILES_BY_ID_SUCCESS`,
  GET_FILES_BY_ID_FAILURE: `${prefix}/GET_FILES_BY_ID_FAILURE`
});

export const initialState: AnnouncementCreateState = {
  createStatus: 'idle',
  getByIdStatus: 'idle',
  getFilesByIdStatus: 'idle'
};

const clearReducer: Reducer<AnnouncementCreateState, void> = (
  state,
  _action
) => {
  return produce(state, draft => {
    draft.createStatus = initialState.createStatus;
    draft.errorMessage = initialState.errorMessage;
  });
};

const createInitReducer: Reducer<AnnouncementCreateState, Create> = state => {
  return produce(state, draft => {
    draft.createStatus = 'pending';
  });
};

const createSuccessReducer: Reducer<AnnouncementCreateState, void> = state => {
  return produce(state, draft => {
    draft.createStatus = 'success';
  });
};

const createFailureReducer: Reducer<AnnouncementCreateState, Error> = (
  state,
  action
) => {
  return produce(state, draft => {
    draft.createStatus = 'failure';
    draft.errorMessage = action.errorMessage;
  });
};

const getByIdReducer: Reducer<AnnouncementCreateState, number> = state => {
  return produce(state, draft => {
    draft.getByIdStatus = 'pending';
  });
};

const getByIdSuccessReducer: Reducer<AnnouncementCreateState, void> = state => {
  return produce(state, draft => {
    draft.getByIdStatus = 'success';
  });
};

const getByIdFailureReducer: Reducer<AnnouncementCreateState, Error> = (
  state,
  action
) => {
  return produce(state, draft => {
    draft.getByIdStatus = 'failure';
    draft.errorMessage = action.errorMessage;
  });
};

const getFilesByIdReducer: Reducer<AnnouncementCreateState, number> = state => {
  return produce(state, draft => {
    draft.getFilesByIdStatus = 'pending';
  });
};

const getFilesByIdSuccessReducer: Reducer<
  AnnouncementCreateState,
  void
> = state => {
  return produce(state, draft => {
    draft.getFilesByIdStatus = 'success';
  });
};

const getFilesByIdFailureReducer: Reducer<AnnouncementCreateState, Error> = (
  state,
  action
) => {
  return produce(state, draft => {
    draft.getFilesByIdStatus = 'failure';
    draft.errorMessage = action.errorMessage;
  });
};

export const actions = Object.freeze({
  clear: createCreator<void>(Types.CLEAR),
  createInit: createCreator<Create>(Types.CREATE_INIT),
  createSuccess: createCreator<void>(Types.CREATE_SUCCESS),
  createFailure: createCreator<Error>(Types.CREATE_FAILURE),
  getById: createCreator<number>(Types.GET_BY_ID),
  getByIdSuccess: createCreator<void>(Types.GET_BY_ID_SUCCESS),
  getByIdFailure: createCreator<Error>(Types.GET_BY_ID_FAILURE),
  getFilesById: createCreator<number>(Types.GET_FILES_BY_ID),
  getFilesByIdSuccess: createCreator<void>(Types.GET_FILES_BY_ID_SUCCESS),
  getFilesByIdFailure: createCreator<Error>(Types.GET_FILES_BY_ID_FAILURE)
});

export const reducer = createReducer(initialState, {
  [Types.CLEAR]: clearReducer,
  [Types.CREATE_INIT]: createInitReducer,
  [Types.CREATE_SUCCESS]: createSuccessReducer,
  [Types.CREATE_FAILURE]: createFailureReducer,
  [Types.GET_BY_ID]: getByIdReducer,
  [Types.GET_BY_ID_SUCCESS]: getByIdSuccessReducer,
  [Types.GET_BY_ID_FAILURE]: getByIdFailureReducer,
  [Types.GET_FILES_BY_ID]: getFilesByIdReducer,
  [Types.GET_FILES_BY_ID_SUCCESS]: getFilesByIdSuccessReducer,
  [Types.GET_FILES_BY_ID_FAILURE]: getFilesByIdFailureReducer
});

import produce from 'immer';
import { Voucher } from '~/domain/model';
import { createCreator, createReducer, Reducer } from '~/helpers/util/reducers';
import { CreateInit } from '../../_core/vouchers/duck';

export type FetchStatus = 'idle' | 'fetching' | 'success' | 'failure';

export type CreateStatus = 'idle' | 'creating' | 'success' | 'failure';

export type VoucherCreateState = {
  voucher: Voucher;
  fetchStatus: FetchStatus;
  createStatus: CreateStatus;
  errorMessage?: string;
};

export type Error = {
  errorMessage?: string;
};

export const initialState: VoucherCreateState = {
  voucher: new Voucher({}),
  fetchStatus: 'idle',
  createStatus: 'idle'
};

const prefix = '@voucherCreate';

export const Types = Object.freeze({
  CLEAR: `${prefix}/CLEAR`,
  FETCH_INIT: `${prefix}/FETCH_INIT`,
  FETCH_INIT_SUCCESS: `${prefix}/FETCH_INIT_SUCCESS`,
  FETCH_INIT_FAILURE: `${prefix}/FETCH_INIT_FAILURE`,
  CREATE_INIT: `${prefix}/CREATE_INIT`,
  CREATE_SUCCESS: `${prefix}/CREATE_SUCCESS`,
  CREATE_FAILURE: `${prefix}/CREATE_FAILURE`
});

const clearReducer: Reducer<VoucherCreateState, void> = state => {
  return produce(state, draft => {
    draft.voucher = initialState.voucher;
    draft.fetchStatus = initialState.fetchStatus;
    draft.createStatus = initialState.createStatus;
  });
};

const fetchInitReducer: Reducer<VoucherCreateState, void> = state => {
  return produce(state, draft => {
    draft.fetchStatus = 'fetching';
  });
};

const fetchInitSuccessReducer: Reducer<VoucherCreateState, void> = state => {
  return produce(state, draft => {
    draft.fetchStatus = 'success';
  });
};

const fetchInitFailureReducer: Reducer<VoucherCreateState, void> = state => {
  return produce(state, draft => {
    draft.fetchStatus = 'failure';
  });
};

const createInitReducer: Reducer<VoucherCreateState, void> = state => {
  return produce(state, draft => {
    draft.createStatus = 'creating';
  });
};

const createSuccessReducer: Reducer<VoucherCreateState, void> = state => {
  return produce(state, draft => {
    draft.createStatus = 'success';
  });
};

const createFailureReducer: Reducer<VoucherCreateState, Error> = (
  state,
  action
) => {
  return produce(state, draft => {
    draft.createStatus = 'failure';
    draft.errorMessage = action.errorMessage;
  });
};

export const actions = Object.freeze({
  clear: createCreator<void>(Types.CLEAR),
  fetchInit: createCreator<void>(Types.FETCH_INIT),
  fetchInitSuccess: createCreator<void>(Types.FETCH_INIT_SUCCESS),
  fetchInitFailure: createCreator<void>(Types.FETCH_INIT_FAILURE),
  createInit: createCreator<CreateInit>(Types.CREATE_INIT),
  createSuccess: createCreator<void>(Types.CREATE_SUCCESS),
  createFailure: createCreator<Error>(Types.CREATE_FAILURE)
});

export const reducer = createReducer(initialState, {
  [Types.CLEAR]: clearReducer,
  [Types.FETCH_INIT]: fetchInitReducer,
  [Types.FETCH_INIT_SUCCESS]: fetchInitSuccessReducer,
  [Types.FETCH_INIT_FAILURE]: fetchInitFailureReducer,
  [Types.CREATE_INIT]: createInitReducer,
  [Types.CREATE_SUCCESS]: createSuccessReducer,
  [Types.CREATE_FAILURE]: createFailureReducer
});

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationError } from '~/domain/api/errors';

export type FetchStatus = 'idle' | 'pending' | 'success' | 'failure';

export type BannerApprovalListState = {
  fetchInitStatus: FetchStatus;
  fetchBannerApprovalStatus: FetchStatus;
  approveOrDisapproveBannerStatus: FetchStatus;
  errorMessage?: string;
  pageNumber: number;
  pageSize: number;
  totalCount: number;
};

export type FetchPage = {
  pageNumber: number;
  pageSize: number;
};

export type FetchBannerApprovalSuccess = {
  pageNumber?: number;
  pageSize?: number;
  totalCount: number;
};

export type FetchFailure = {
  error?: ApplicationError;
};

export type Error = {
  errorMessage?: string;
};

const prefix = '@bannerApprovalList';

export const initialState: BannerApprovalListState = {
  fetchInitStatus: 'idle',
  approveOrDisapproveBannerStatus: 'idle',
  fetchBannerApprovalStatus: 'idle',
  pageNumber: 1,
  pageSize: 10,
  totalCount: 0
};

export type ChangeStatus = {
  id: number;
  message?: string;
  status: string;
};

export type FetchBannerApproval = {
  description?: string;
  url?: string;
  status?: string;
  bannerTypeId?: number;
  uploadDate?: string;
  pageNumber?: number;
  pageSize?: number;
};
const bannerApprovalList = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: state => {
      state.fetchInitStatus = initialState.fetchInitStatus;
      state.fetchBannerApprovalStatus = initialState.fetchBannerApprovalStatus;
      state.pageNumber = 1;
      state.pageSize = 10;
      state.totalCount = 0;
      state.errorMessage = '';
    },
    fetchInit: state => {
      state.fetchInitStatus = 'pending';
    },
    fetchInitSuccess: state => {
      state.fetchInitStatus = 'success';
    },
    fetchInitFailure: state => {
      state.fetchInitStatus = 'failure';
    },
    setPagination: (state, action: PayloadAction<FetchPage>) => {
      state.pageNumber = action.payload.pageNumber;
      state.pageSize = action.payload.pageSize;
    },
    fetchBannerApproval: (
      state,
      action: PayloadAction<FetchBannerApproval>
    ) => {
      state.fetchBannerApprovalStatus = 'pending';
      let { pageNumber, pageSize } = action.payload;
      if (!pageNumber) {
        pageNumber = initialState.pageNumber;
        state.pageNumber = pageNumber;
      }
      if (!pageSize) {
        pageSize = initialState.pageSize;
        state.pageSize = pageSize;
      }
    },
    fetchBannerApprovalSuccess: (
      state,
      action: PayloadAction<FetchBannerApprovalSuccess>
    ) => {
      state.fetchBannerApprovalStatus = 'success';
      state.totalCount = action.payload.totalCount;
      state.pageSize = action.payload.pageSize ?? 10;
      state.pageNumber = action.payload.pageNumber ?? 1;
    },
    fetchBannerApprovalFailure: (
      state,
      _action: PayloadAction<Error | void>
    ) => {
      state.fetchBannerApprovalStatus = 'failure';
    },
    approveOrDisapproveBanner: (
      state,
      _action: PayloadAction<ChangeStatus>
    ) => {
      state.approveOrDisapproveBannerStatus = 'pending';
    },
    approveOrDisapproveSuccess: state => {
      state.approveOrDisapproveBannerStatus = 'success';
    },
    approveOrDisapproveFailure: state => {
      state.approveOrDisapproveBannerStatus = 'failure';
    }
  }
});
export const actions = bannerApprovalList.actions;
export const reducer = bannerApprovalList.reducer;

const caseReducers = bannerApprovalList.caseReducers;
export const Types = Object.freeze({
  clear: `${prefix}/${caseReducers.clear.name}`,
  fetchInit: `${prefix}/${caseReducers.fetchInit.name}`,
  fetchInitSuccess: `${prefix}/${caseReducers.fetchInitSuccess.name}`,
  fetchFailureInit: `${prefix}/${caseReducers.fetchInitFailure.name}`,
  setPagination: `${prefix}/${caseReducers.setPagination.name}`,
  fetchBannerApproval: `${prefix}/${caseReducers.fetchBannerApproval.name}`,
  fetchBannerApprovalSuccess: `${prefix}/${caseReducers.fetchBannerApprovalSuccess.name}`,
  fetchBannerApprovalFailure: `${prefix}/${caseReducers.fetchBannerApprovalFailure.name}`,
  approveOrDisapproveBanner: `${prefix}/${caseReducers.approveOrDisapproveBanner.name}`,
  approveOrDisapproveSuccess: `${prefix}/${caseReducers.approveOrDisapproveSuccess.name}`,
  approveOrDisapproveFailure: `${prefix}/${caseReducers.approveOrDisapproveFailure.name}`
});

import { call, put, takeLatest } from 'redux-saga/effects';
import { CreateInit } from '~/store/modules/_core/specialDates/duck';
import { actions, Types } from './duck';
import { Action } from '~/helpers/util/reducers';
import { APIError } from '~/domain/api/errors';
import * as SpecialDateService from '~/store/modules/_core/specialDates/service';

export function* createSaga(action: Action<CreateInit>) {
  try {
    const data = action.payload;

    yield call(SpecialDateService.create, data);
    yield put(actions.createSuccess());
  } catch (error) {
    console.log('error teste', error);
    if (error instanceof APIError) {
      yield put(actions.createFailure({ errorMessage: error.message }));
    } else {
      yield put(actions.createFailure({}));
    }
  }
}

export default [takeLatest(Types.CREATE_INIT, createSaga)];

export function searchProductGroups(categoryId: number, siteId: number) {
  return {
    type: '@product/SEARCH_PRODUCT_GROUPS',
    payload: { categoryId, siteId }
  };
}

export function searchProductGroupsSite(siteId: number) {
  return {
    type: '@product/SEARCH_PRODUCT_GROUPS_SITE',
    payload: { siteId }
  };
}

export function searchProductGroupsSiteSuccess(groups: any) {
  return {
    type: '@product/SEARCH_PRODUCT_GROUPS_SITE_SUCCESS',
    payload: { groups }
  };
}

export function searchProductGroupsSuccess(data: any) {
  return {
    type: '@product/SEARCH_PRODUCT_GROUPS_SUCCESS',
    payload: data
  };
}

export function searchProductGroupsFailure() {
  return {
    type: '@product/SEARCH_PRODUCT_GROUPS_FAILURE'
  };
}
export function searchProductGroupsSiteFailure() {
  return {
    type: '@product/SEARCH_PRODUCT_GROUPS_SITE_FAILURE'
  };
}

export function searchProductCategories(siteId: number) {
  return {
    type: '@product/SEARCH_PRODUCT_CATEGORIES',
    payload: { siteId }
  };
}
export function searchProductCategoriesSuccess(data: any) {
  return {
    type: '@product/SEARCH_PRODUCT_CATEGORIES_SUCCESS',
    payload: data
  };
}
export function searchProductCategoriesFailure() {
  return {
    type: '@product/SEARCH_PRODUCT_CATEGORIES_FAILURE'
  };
}
export function removeProductsGroups() {
  return {
    type: '@product/REMOVE_PRODUCT_GROUPS'
  };
}
export function removeCategories() {
  return {
    type: '@product/REMOVE_CATEGORIES'
  };
}

import * as API from '~/domain/api';

export default class Customer {
  private json: API.Customer;
  public readonly id: number;
  public readonly name: string;
  public readonly accountCode: string;

  constructor(json: API.Customer) {
    this.json = json;
    this.id = this.getId();
    this.name = this.getName();
    this.accountCode = this.getAccountCode();
  }

  private getId(): number {
    return this.json.id ?? Number.NaN;
  }

  private getName(): string {
    return this.json.name ?? '';
  }

  private getAccountCode(): string {
    return this.json.accountCode ?? '';
  }
}

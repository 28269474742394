import { AxiosError, AxiosResponse } from 'axios';
import FilterPackings from '~/@core/domain/filters/packing/FilterPacking';
import Packing from '~/@core/domain/model/packing/Packing';
import api from '../config/index';

export const fetchPackings = (filter: FilterPackings) => {
  return new Promise<Packing[]>((resolve, reject) => {
    api
      .get<void, AxiosResponse<Packing[]>>(
        `/packing/${filter.siteId}/${filter.productId}`
      )
      .then((response: AxiosResponse<Packing[]>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

import { AxiosResponse } from 'axios';
import { AutoserviceDefinitionsParam } from '~/domain/model/AutoserviceDefinitionsParam';
import { endpoints } from '~/constants/endpoints';
import api from '../api';

export const findDefinitions = async (params: number) => {
  const response = await api.get<void, AxiosResponse<any>>(
    `${endpoints.selfService.flagsDefinition}${params}`
  );
  return response.data;
};

export const updateDefinitionsRequest = async (
  params: AutoserviceDefinitionsParam
) => {
  const response = await api.put<void, AxiosResponse<any>>(
    endpoints.selfService.updateFlagsDefinition,
    params
  );
  return response.data;
};

import * as API from '~/domain/api';

export default class AnnouncementVisualization {
  public readonly json: API.AnnouncementVisualization;

  public readonly id: number = Number.NaN;

  public readonly description: string = '';

  public readonly message: string = '';

  public readonly required: boolean = false;

  public readonly creationDate: string = '';

  constructor(json: API.AnnouncementVisualization) {
    this.json = json;
    this.id = this.getId();
    this.description = this.getDescription();
    this.message = this.getMessage();
    this.required = this.getRequired();
    this.creationDate = this.getCreationDate();
  }

  private getId(): number {
    return this.json.id ?? Number.NaN;
  }

  private getDescription(): string {
    return this.json.description ?? '';
  }

  private getCreationDate(): string {
    return this.json.creationDate ?? '';
  }

  private getMessage(): string {
    return this.json.message ?? '';
  }

  private getRequired(): boolean {
    return this.json.required ?? false;
  }
}

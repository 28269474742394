import { formatDefault } from '~/helpers/util';

export function searchSpecialDates(start: Date, end: Date) {
  const formatStart = formatDefault(start);
  const formatEnd = formatDefault(end);
  return {
    type: '@specialDates/SEARCH_SPECIAL_DATES',
    payload: { formatStart, formatEnd }
  };
}

export function searchSpecialDatesSuccess(data: any) {
  return {
    type: '@specialDates/SEARCH_SPECIAL_DATES_SUCCESS',
    payload: data
  };
}

export function searchSpecialDatesFailure() {
  return {
    type: '@specialDates/SEARCH_SPECIAL_DATES_FAILURE'
  };
}

export function removeSpecialDates() {
  return {
    type: '@specialDates/REMOVE_SPECIAL_DATES'
  };
}
export function setSpecialDateModal(specialDateModal: boolean) {
  return {
    type: '@specialDates/SET_SPECIAL_DATE_MODAL',
    payload: { specialDateModal }
  };
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EditParams } from '../../_core/lkpOffers/duck';

export type EditStatus = 'idle' | 'pending' | 'success' | 'failure';

export type LkpOfferEditState = {
  editStatus: EditStatus;
  editType: string;
  errorMessage?: string;
  lkpOffersChanges?: EditParams[];
};

export type StatusOffer = {
  lkpOfferIds: number[];
  isActive: boolean;
};

export type Error = {
  errorMessage?: string;
};

const prefix = '@lkpOfferEdit';

export const initialState: LkpOfferEditState = {
  editStatus: 'idle',
  editType: 'modal',
  errorMessage: undefined,
  lkpOffersChanges: []
};

const lkpOfferEdit = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: state => {
      state.editStatus = initialState.editStatus;
      state.editType = 'modal';
      state.errorMessage = initialState.errorMessage;
    },
    setLkpOfferChanges: (state, action: PayloadAction<EditParams[]>) => {
      state.lkpOffersChanges = action.payload;
    },
    editInit: (state, _action: PayloadAction<EditParams[]>) => {
      state.editStatus = 'pending';
    },
    editType: (state, action: PayloadAction<string>) => {
      state.editType = action.payload;
    },
    editSuccess: state => {
      state.editStatus = 'success';
    },
    editFailure: (state, action: PayloadAction<Error>) => {
      state.editStatus = 'failure';
      state.errorMessage = action.payload.errorMessage;
    },

    // #FIXME
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    changeStatus: (state, action: PayloadAction<StatusOffer>) => {}
  }
});
export const actions = lkpOfferEdit.actions;
export const reducer = lkpOfferEdit.reducer;

const caseReducers = lkpOfferEdit.caseReducers;
export const Types = Object.freeze({
  clear: `${prefix}/${caseReducers.clear.name}`,
  setLkpOfferChanges: `${prefix}/${caseReducers.setLkpOfferChanges.name}`,
  editInit: `${prefix}/${caseReducers.editInit.name}`,
  editType: `${prefix}/${caseReducers.editType.name}`,
  editSuccess: `${prefix}/${caseReducers.editSuccess.name}`,
  editFailure: `${prefix}/${caseReducers.editFailure.name}`,
  changeStatus: `${prefix}/${caseReducers.changeStatus.name}`
});

import { AxiosError, AxiosResponse } from 'axios';
import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';

import FilterData from '~/@core/domain/filters/rfidReports/FilterData';
import FilterText from '~/@core/domain/filters/rfidReports/FilterText';
import Balance from '~/@core/domain/model/rfidReports/Balance';
import Consolidated from '~/@core/domain/model/rfidReports/Consolidated';
import Done from '~/@core/domain/model/rfidReports/Done';
import Frequency from '~/@core/domain/model/rfidReports/Frequency';
import NonConformity from '~/@core/domain/model/rfidReports/NonConformity';
import Owned from '~/@core/domain/model/rfidReports/Owned';
import Producer from '~/@core/domain/model/rfidReports/Producer';
import api, { apiRfid } from '../../config';

export const fetchProducers = (permissionName: string) => {
  return new Promise<Producer[]>((resolve, reject) => {
    api
      .get<void, AxiosResponse<Producer[]>>(`/site/${permissionName}`)
      .then((response: AxiosResponse<Producer[]>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const fetchConsolidateds = (params: FilterText) => {
  return new Promise<DefaultPaginated<Consolidated[]>>((resolve, reject) => {
    apiRfid
      .get<void, AxiosResponse<DefaultPaginated<Consolidated[]>>>(
        'api/inventory/consolidateds',
        {
          params
        }
      )
      .then((response: AxiosResponse<DefaultPaginated<Consolidated[]>>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const UpdateConsolidateds = (params: Consolidated) => {
  return new Promise<AxiosResponse<void>>((resolve, reject) => {
    apiRfid
      .post<void, AxiosResponse<void>>('api/inventory/consolidateds', params)
      .then((response: AxiosResponse<void>) => {
        resolve(response);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const fetchOwned = (params: FilterText) => {
  return new Promise<DefaultPaginated<Owned[]>>((resolve, reject) => {
    apiRfid
      .get<void, AxiosResponse<DefaultPaginated<Owned[]>>>(
        'api/inventory/owned',
        {
          params
        }
      )
      .then((response: AxiosResponse<DefaultPaginated<Owned[]>>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const fetchNonConformities = () => {
  return new Promise<DefaultPaginated<NonConformity[]>>((resolve, reject) => {
    apiRfid
      .get<void, AxiosResponse<DefaultPaginated<NonConformity[]>>>(
        'api/inventory/nonConformity'
      )
      .then((response: AxiosResponse<DefaultPaginated<NonConformity[]>>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const fetchBalances = () => {
  return new Promise<DefaultPaginated<Balance[]>>((resolve, reject) => {
    apiRfid
      .get<void, AxiosResponse<DefaultPaginated<Balance[]>>>(
        'api/inventory/balance'
      )
      .then((response: AxiosResponse<DefaultPaginated<Balance[]>>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const fetchDones = (params: FilterData) => {
  return new Promise<DefaultPaginated<Done[]>>((resolve, reject) => {
    apiRfid
      .get<void, AxiosResponse<DefaultPaginated<Done[]>>>(
        'api/inventory/dones',
        {
          params
        }
      )
      .then((response: AxiosResponse<DefaultPaginated<Done[]>>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const fetchFrequency = (params: FilterData) => {
  return new Promise<DefaultPaginated<Frequency[]>>((resolve, reject) => {
    apiRfid
      .get<void, AxiosResponse<DefaultPaginated<Frequency[]>>>(
        'api/inventory/frequency',
        {
          params
        }
      )
      .then((response: AxiosResponse<DefaultPaginated<Frequency[]>>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

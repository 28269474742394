/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Moment } from 'moment';
import { VolOffer } from '~/domain/api';
import { DirectedOffers } from '~/domain/model';

export type Status = 'idle' | 'fetching' | 'success' | 'failure';

export type DirectedOffersCreateState = {
  status: Status;
};

export type DirectedOffersUpdateAction = {
  status: string;
  startDate?: Moment | string | null;
  endDate?: Moment | string | null;
  volOfferIds: number[];
  siteDeliveryPatternId: number;
  observation: string;
  priceByPacking?: number;
  priceByLayer?: number;
  priceByTrolley?: number;
  exclusiveQuantity?: number;
  minimumQuantity?: number;
  specialPrice?: number;
};

const prefix = '@directedOffersCreate';

export const initialState: DirectedOffersCreateState = {
  status: 'idle'
};

const directedOffersCreate = createSlice({
  name: prefix,
  initialState,
  reducers: {
    create: (
      state: DirectedOffersCreateState,
      _action: PayloadAction<DirectedOffers>
    ) => {
      state.status = 'fetching';
    },
    changeStatus: (
      _state: DirectedOffersCreateState,
      _action: PayloadAction<VolOffer>
    ) => {},
    updateDirectedVolOffers: (
      state: DirectedOffersCreateState,
      _action: PayloadAction<any[]>
    ) => {
      state.status = 'fetching';
    },
    update: (
      _state: DirectedOffersCreateState,
      _action: PayloadAction<DirectedOffersUpdateAction>
    ) => {},
    deleteSubOffer: (
      _state: DirectedOffersCreateState,
      _action: PayloadAction<number>
    ) => {}
  }
});
export const actions = directedOffersCreate.actions;
export const reducer = directedOffersCreate.reducer;

const caseReducers = directedOffersCreate.caseReducers;
export const Types = Object.freeze({
  create: `${prefix}/${caseReducers.create.name}`,
  changeStatus: `${prefix}/${caseReducers.changeStatus.name}`,
  updateDirectedVolOffers: `${prefix}/${caseReducers.updateDirectedVolOffers.name}`,
  update: `${prefix}/${caseReducers.update.name}`,
  deleteSubOffer: `${prefix}/${caseReducers.deleteSubOffer.name}`
});

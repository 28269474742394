// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';

import * as API from 'domain/api';
import { APIError } from '~/domain/api/errors';

import apiProducer from '../api';

export const fetchSiteLinks = async (
  description?: string,
  url?: string,
  pageNumber?: number,
  pageSize?: number
) => {
  try {
    const params = new URLSearchParams();
    if (description) {
      params.append('data.description', description);
    }
    if (url) {
      params.append('data.url', url);
    }
    if (pageNumber) {
      params.append('pageNumber', String(pageNumber));
    }
    if (pageSize) {
      params.append('pageSize', String(pageSize));
    }
    const response: AxiosResponse = await apiProducer.get('/link-site', {
      params
    });
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const editSiteLink = async (
  paramsSiteLink: API.CreateAndEditSiteLink
) => {
  try {
    const response: any = await apiProducer.put('/link-site', paramsSiteLink);
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 422, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const activeOrInactiveSiteLink = async (id: number) => {
  try {
    const response: any = await apiProducer.put(`/link-site/status/${id}`);
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const getAllUrls = async () => {
  try {
    const response: AxiosResponse = await apiProducer.get('/link-site/urls');
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

import * as API from '~/domain/api';

export default class BannerApproval {
  private json: API.BannerApproval;

  public readonly id: number;

  public readonly description: string;

  public readonly url: string;

  public readonly status: string;

  public readonly justification: string;

  public readonly isActive: boolean;

  public readonly bannerType: string;

  public readonly linkSiteUrl: string;

  public readonly createdBy: string;

  public readonly date: string;

  public readonly uploadDate: string;

  constructor(json: API.BannerApproval) {
    this.json = json;
    this.id = this.getId();
    this.description = this.getDescription();
    this.justification = this.getJustification();
    this.url = this.getUrl();
    this.status = this.getStatus();
    this.isActive = this.getIsActive();
    this.bannerType = this.getBannerType();
    this.linkSiteUrl = this.getLinkSiteUrl();
    this.createdBy = this.getCreatedBy();
    this.date = this.getDate();
    this.uploadDate = this.getUploadDate();
  }

  private getId(): number {
    return this.json.id ?? Number.NaN;
  }

  private getDescription(): string {
    return this.json.description ?? '';
  }

  private getJustification(): string {
    return this.json.justification ?? '';
  }

  private getLinkSiteUrl(): string {
    return this.json.linkSiteUrl ?? '';
  }

  private getCreatedBy(): string {
    return this.json.createdBy ?? '';
  }

  private getUrl(): string {
    return this.json.url ?? '';
  }

  private getStatus(): string {
    return this.json.status ?? '';
  }

  private getIsActive(): boolean {
    return this.json.isActive ?? false;
  }

  private getBannerType(): string {
    return this.json.bannerType ?? '';
  }

  private getDate(): string {
    return this.json.date ?? '';
  }

  private getUploadDate(): string {
    return this.json.uploadDate ?? '';
  }
}

import { create } from 'zustand';
import Consolidated from '~/@core/domain/model/rfidReports/Consolidated';
import TablePagination from '~/@core/domain/ui/TablePagination';

interface ConsolidatedState {
  consolidateds: Consolidated[];
  consolidatedPagination: TablePagination;
  handleConsolidatedPagination: (newPagination: TablePagination) => void;
  addConsolidateds: (consolidatedsValues: Consolidated[]) => void;
}

const useConsolidatedStore = create<ConsolidatedState>()(set => ({
  consolidateds: [],
  consolidatedPagination: {
    pageSize: 10,
    current: 1,
    total: 1
  },
  handleConsolidatedPagination: newPagination =>
    set({ consolidatedPagination: newPagination }),
  addConsolidateds: consolidatedsValues =>
    set({ consolidateds: consolidatedsValues })
}));

export default useConsolidatedStore;

import * as API from '~/domain/api';

export default class Banner {
  public readonly json: API.Banner;
  public readonly id: number;
  public readonly description?: string;
  public readonly bannerTypeId?: number;
  public readonly bannerType?: string;
  public readonly isActive?: boolean;
  public readonly imagePath?: string;
  public readonly createdBy?: string;
  public readonly uploadDate?: string;

  constructor(json: API.Banner) {
    this.json = json;
    this.id = json.id;
    this.description = json.description ?? '';
    this.bannerTypeId = json.bannerTypeId ?? Number.NaN;
    this.bannerType = json.bannerType ?? '';
    this.isActive = json.isActive ?? false;
    this.imagePath = json.imagePath ?? '';
    this.createdBy = json.createdBy ?? '';
    this.uploadDate = json.uploadDate ?? '';
  }
}

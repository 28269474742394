import IUseCase from '~/@core/application/IUseCase';
import FilterData from '~/@core/domain/filters/rfidReports/FilterData';
import Producer from '~/@core/domain/model/rfidReports/Producer';
import { InventoryService } from '~/@core/infrastructure/api/services/rfidReports';

export default class GetProducers
  implements IUseCase<FilterData, Promise<Producer[]>>
{
  execute(): Promise<Producer[]> {
    return InventoryService.fetchProducers('');
  }
}

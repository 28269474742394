import { ProductsState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.core.products as ProductsState;
};

export const getById = createSelector([getRoot], state => state.byId);

export const getProductsSimple = createSelector(
  [getRoot],
  state => state.productsSimple
);

export const getProducts = createSelector([getRoot], state =>
  Array.from(state.byId.values())
);

import styled from 'styled-components';
import { darken, lighten } from 'polished';
import { Button as CustomButton } from 'antd';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 8px;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 16px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: auto;
  margin-left: 15px;
`;

export const Button = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin-left: 7px;
  width: 32px;
  height: 32px;
  background: #fff;
  color: #595959;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  font-size: 11px;
  transition: background 0.2s;
  cursor: pointer;

  &.primary {
    background: #f78e1e;
    color: #fff;
    border: 1px solid #f78e1e;

    svg {
      font-size: 11px;
      color: #fff;
    }

    &:hover {
      background: ${lighten(0.05, '#F78E1E')};
    }
  }

  &:hover {
    background: ${darken(0.05, '#fff')};
  }

  &:first-child {
    margin-left: 0;
  }

  svg {
    font-size: 14px;
  }
`;

export const ButtonTop = styled(CustomButton)`
  padding: 4px 9px;
`;

export const Filter = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 0;
  padding: 0;
  height: 0;
  border-bottom: none;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  transition: all 1s ease;

  &.active {
    overflow: unset;
    height: auto;
    margin-bottom: 16px;
    border-bottom: 1px solid #d9d9d9;
  }
`;

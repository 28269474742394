import { createSelector } from '~/helpers/util/selectors';
import { CustomerGroupCreateState } from './duck';

export const getRoot = (state: any) => {
  return state.ui.customersGroupCreate as CustomerGroupCreateState;
};

export const getCreateStatus = createSelector([getRoot], state => {
  return state.createStatus;
});

export const getErrorMessage = createSelector([getRoot], state => {
  return state.errorMessage;
});

export const getIsLoading = createSelector([getCreateStatus], createStatus => {
  return createStatus === 'creating';
});

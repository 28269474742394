import * as API from '../api';

export default class PackingDistributionComposition {
  private readonly json: API.PackingDistributionComposition;
  public readonly id: number;
  public readonly layersByTrolley: number;
  public readonly modelDescription: string;
  public readonly packagingByLayer: number;
  public readonly unitsPerPackage: number;
  public readonly packingDescription: string;
  public readonly isEdit: boolean;
  public readonly packingCode: number;
  public readonly packingId: number;
  public readonly minimumQuantity: number;
  public readonly multipleQuantity: number;

  constructor(json: API.PackingDistributionComposition) {
    this.json = json;
    this.id = this.getId();
    this.layersByTrolley = this.getLayersByTrolley();
    this.modelDescription = this.getModelDescription();
    this.packagingByLayer = this.getPackagingByLayer();
    this.unitsPerPackage = this.getUnitsPerPackage();
    this.packingDescription = this.getPackingDescription();
    this.isEdit = this.getIsEdit();
    this.packingCode = this.getPackingCode();
    this.packingId = this.getPackingId();
    this.minimumQuantity = this.getMinimumQuantity();
    this.multipleQuantity = this.getMultipleQuantity();
  }

  private getId() {
    return this.json.id || Number.NaN;
  }

  private getPackingCode() {
    return this.json.packingCode || Number.NaN;
  }

  private getPackingId() {
    return this.json.packingId || Number.NaN;
  }

  private getIsEdit() {
    return this.isEdit || false;
  }

  private getLayersByTrolley() {
    return this.json.layersByTrolley || Number.NaN;
  }

  private getModelDescription() {
    return this.json.modelDescription || '';
  }

  private getPackagingByLayer() {
    return this.json.packagingByLayer || Number.NaN;
  }

  private getUnitsPerPackage() {
    return this.json.unitsPerPackage || Number.NaN;
  }

  private getMinimumQuantity() {
    return this.json.minimumQuantity || Number.NaN;
  }

  private getMultipleQuantity() {
    return this.json.multipleQuantity || Number.NaN;
  }

  private getPackingDescription() {
    return this.json.packingDescription || '';
  }
}

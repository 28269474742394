import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import thunk from 'redux-thunk';
import createStore from './createStore';
import persistReducers from './persistReducers';

import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';

import type { ISiteState } from './modules/site/types';
import type { IPackingState } from './modules/packing/types';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, thunk];

export interface IState {
  site: ISiteState;
  packing: IPackingState;
}

const store = createStore(persistReducers(rootReducer), middlewares);

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { persistor };

export default store;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LinkSite } from '~/domain/model';

export type Status = 'cleared' | 'updated';

export type SiteLinksState = {
  siteLinks: LinkSite[];
  urlsList: string[];
  status: Status;
};

export type Update = {
  siteLinks?: LinkSite[];
  urlsList?: string[];
};

export type FetchSiteLinks = {
  description?: string;
  url?: string;
  pageNumber?: number;
  pageSize?: number;
};

export type CreateAndEditSiteLink = {
  id?: number;
  description: string;
  url: string;
  sitesIds?: number[];
};

const prefix = '@siteLinks';

export const initialState: SiteLinksState = {
  siteLinks: [],
  urlsList: [],
  status: 'cleared'
};

const siteLinks = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: state => {
      state.siteLinks = initialState.siteLinks;
      state.status = initialState.status;
    },
    update: (state, action: PayloadAction<Update>) => {
      if (action.payload.siteLinks) {
        state.siteLinks = action.payload.siteLinks;
      }
      if (action.payload.urlsList) {
        state.urlsList = action.payload.urlsList;
      }
    }
  }
});

export const actions = siteLinks.actions;
export const reducer = siteLinks.reducer;

const caseReducers = siteLinks.caseReducers;
export const Types = Object.freeze({
  clear: `${prefix}/${caseReducers.clear.name}`,
  update: `${prefix}/${caseReducers.update.name}`
});

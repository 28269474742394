import { combineReducers } from 'redux';

import * as competences from './competences';
import * as competenceEvents from './competenceEvents';
import * as vouchers from './vouchers';
import * as ecommerceManagement from './ecommerceManagement';
import * as siteLinks from './siteLinks';
import * as bannerApproval from './bannerApproval';
import * as reportsAnnouncement from './reportsAnnouncement';
import * as bannerProducerManagement from './bannerProducerManagement';
import * as customers from './customers';
import * as customersGroup from './customersGroup';
import * as announcement from './announcement';
import * as specialDates from './specialDates';
import * as sites from './sites';
import * as salesReport from './salesReport';
import * as productCategories from './productCategories';
import * as offerChannels from './offerChannels';
import * as productPreferences from './productPreferences';
import * as product from './product';
import * as deliveryPattern from './deliveryPattern';
import * as priceAlerts from './priceAlerts';
import * as productionPlanningEventWeeks from './productionPlanningEventWeeks';
import * as weeks from './weeks';
import * as productionProductGroups from './productionProductGroups';
import * as products from './products';
import * as countingPatterns from './countingPatterns';
import * as offerSettings from './offerSettings';
import * as bannerTypes from './bannerTypes';
import * as banners from './banners';
import * as volOffers from './volOffers';
import * as directedOffers from './directedOffers';
import * as lkpOffers from './lkpOffers';
import * as productQualities from './productQualities';

export const actions = Object.freeze({
  productCategories: productCategories.actions,
  ecommerceManagement: ecommerceManagement.actions,
  siteLinks: siteLinks.actions,
  reportsAnnouncement: reportsAnnouncement.actions,
  bannerApproval: bannerApproval.actions,
  bannerProducerManagement: bannerProducerManagement.actions,
  productPreferences: productPreferences.actions,
  product: product.actions,
  announcement: announcement.actions,
  deliveryPattern: deliveryPattern.actions,
  competences: competences.actions,
  salesReport: salesReport.actions,
  vouchers: vouchers.actions,
  competenceEvents: competenceEvents.actions,
  specialDates: specialDates.actions,
  sites: sites.actions,
  offerChannels: offerChannels.actions,
  productionPlanningEventWeeks: productionPlanningEventWeeks.actions,
  weeks: weeks.actions,
  customers: customers.actions,
  customersGroup: customersGroup.actions,
  products: products.actions,
  productionProductGroups: productionProductGroups.actions,
  priceAlerts: priceAlerts.actions,
  countingPatterns: countingPatterns.actions,
  offerSettings: offerSettings.actions,
  volOffers: volOffers.actions,
  directedOffers: directedOffers.actions,
  bannerTypes: bannerTypes.actions,
  banners: banners.actions,
  lkpOffers: lkpOffers.actions,
  productQualities: productQualities.actions
});

export const reducer = combineReducers({
  competences: competences.reducer,
  specialDates: specialDates.reducer,
  siteLinks: siteLinks.reducer,
  reportsAnnouncement: reportsAnnouncement.reducer,
  bannerApproval: bannerApproval.reducer,
  bannerProducerManagement: bannerProducerManagement.reducer,
  ecommerceManagement: ecommerceManagement.reducer,
  salesReport: salesReport.reducer,
  competenceEvents: competenceEvents.reducer,
  vouchers: vouchers.reducer,
  customers: customers.reducer,
  customersGroup: customersGroup.reducer,
  sites: sites.reducer,
  announcement: announcement.reducer,
  offerChannels: offerChannels.reducer,
  productPreferences: productPreferences.reducer,
  product: product.reducer,
  deliveryPattern: deliveryPattern.reducer,
  productCategories: productCategories.reducer,
  productionPlanningEventWeeks: productionPlanningEventWeeks.reducer,
  weeks: weeks.reducer,
  products: products.reducer,
  productionProductGroups: productionProductGroups.reducer,
  priceAlerts: priceAlerts.reducer,
  countingPatterns: countingPatterns.reducer,
  offerSettings: offerSettings.reducer,
  volOffers: volOffers.reducer,
  directedOffers: directedOffers.reducer,
  bannerTypes: bannerTypes.reducer,
  banners: banners.reducer,
  lkpOffers: lkpOffers.reducer,
  productQualities: productQualities.reducer
});

export const selectors = Object.freeze({
  competences: competences.selectors,
  reportsAnnouncement: reportsAnnouncement.selectors,
  specialDates: specialDates.selectors,
  bannerApproval: bannerApproval.selectors,
  bannerProducerManagement: bannerProducerManagement.selectors,
  competenceEvents: competenceEvents.selectors,
  vouchers: vouchers.selectors,
  salesReport: salesReport.selectors,
  announcement: announcement.selectors,
  customers: customers.selectors,
  customersGroup: customersGroup.selectors,
  sites: sites.selectors,
  offerChannels: offerChannels.selectors,
  ecommerceManagement: ecommerceManagement.selectors,
  siteLinks: siteLinks.selectors,
  productPreferences: productPreferences.selectors,
  priceAlerts: priceAlerts.selectors,
  product: product.selectors,
  deliveryPattern: deliveryPattern.selectors,
  productionPlanningEventWeeks: productionPlanningEventWeeks.selectors,
  weeks: weeks.selectors,
  products: products.selectors,
  productionProductGroups: productionProductGroups.selectors,
  productCategories: productCategories.selectors,
  countingPatterns: countingPatterns.selectors,
  offerSettings: offerSettings.selectors,
  volOffers: volOffers.selectors,
  directedOffers: directedOffers.selectors,
  bannerTypes: bannerTypes.selectors,
  banners: banners.selectors,
  lkpOffers: lkpOffers.selectors,
  productQualities: productQualities.selectors
});

import { formtDateBr } from '~/helpers/util';

export function setWeekId() {
  return {
    type: '@dates/CHANGE_WEEK_ID'
  };
}

export function searchWeekIdSuccess(weeks: any) {
  return {
    type: '@dates/SEARCH_WEEK_ID_SUCCESS',
    payload: weeks
  };
}

export function searchWeekIdFailure() {
  return {
    type: '@dates/SEARCH_WEEK_ID_FAILURE'
  };
}
export function setCompetencyId(id: any) {
  return {
    type: '@dates/SET_COMPETENCY_ID',
    payload: id
  };
}
export function setSelectedDates(startDate: Date, endDate: Date) {
  const startFormat = formtDateBr(startDate);
  const endFormat = formtDateBr(endDate);
  return {
    type: '@dates/CHANGE_DATES',
    payload: { startFormat, endFormat }
  };
}

import * as API from '../api';

export default class Product {
  private readonly json: API.Product;
  public readonly id: number;
  public readonly code: string;
  public readonly description: string;

  constructor(json: API.Product) {
    this.json = json;
    this.id = this.getId();
    this.code = this.getCode();
    this.description = this.getDescription();
  }

  public toJSON() {
    return this.json;
  }

  private getId() {
    return this.json.id || 0;
  }

  private getCode() {
    return this.json.code || '';
  }

  private getDescription() {
    return this.json.description || '';
  }
}

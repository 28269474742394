import { call, fork, put, takeLatest } from 'redux-saga/effects';

import { actions, CreateAndEditSiteLink, Types } from './duck';
import { Action } from '~/helpers/util/reducers';
import * as SiteLinksService from '~/store/modules/_core/siteLinks/service';
import * as SiteService from '~/store/modules/_core/sites/service';
import { APIError } from '~/domain/api/errors';
import { alertRequest } from '../../alert/actions';
import { generateErrorMessage } from '~/helpers/util/error';

export function* watchEditSaga(action: Action<CreateAndEditSiteLink>) {
  yield fork(editSaga, action.payload);
}

export function* watchActiveOrInactiveSaga(action: Action<number>) {
  yield fork(activeOrInactiveSaga, action.payload);
}

export function* editSaga(params: CreateAndEditSiteLink) {
  try {
    yield call(SiteLinksService.editSiteLink, params);
    yield put(actions.editSiteLinkSuccess());
    if (params.id) {
      yield put(
        alertRequest('Vínculo de sítios alterado com sucesso.', 'success')
      );
    } else {
      yield put(
        alertRequest('Vínculo de sítios criado com sucesso.', 'success')
      );
    }
  } catch (error) {
    if (error instanceof APIError) {
      yield put(actions.editSiteLinkFailure());
      yield put(alertRequest(generateErrorMessage(error.message), 'error'));
    } else {
      yield put(actions.editSiteLinkFailure());
    }
  }
}

export function* activeOrInactiveSaga(id: number) {
  try {
    yield call(SiteLinksService.activeOrInactiveSiteLink, id);
    yield put(actions.activeOrInactiveSiteLinkSuccess());
    yield put(alertRequest('Status alterado com sucesso.', 'success'));
  } catch (error) {
    if (error instanceof APIError) {
      yield put(alertRequest(generateErrorMessage(error.message), 'error'));
    } else {
      yield put(alertRequest('Não foi possível alterar o status.', 'error'));
      yield put(actions.activeOrInactiveSiteLinkFailure());
    }
  }
}

export function* fetchInitSaga(): any {
  try {
    yield call(SiteService.findMany);
    yield put(actions.fetchInitSuccess());
  } catch (err) {
    yield put(actions.fetchInitFailure());
  }
}

export default [
  takeLatest(Types.editSiteLink, watchEditSaga),
  takeLatest(Types.activeOrInactiveSiteLink, watchActiveOrInactiveSaga),
  takeLatest(Types.fetchInit, fetchInitSaga)
];

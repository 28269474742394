/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { LeftOutlined } from '@ant-design/icons';
import IGfp from '~/@core/domain/model/gfp/IGfp';
import { GfpPrintEnum } from '~/domain/enum/gfpPrint.enum';
import {
  BarcodeContainer,
  BorderStyled,
  Column,
  ColumnItem,
  ContainerFlex,
  Identifier,
  SubIdentifier,
  Text,
  TextBold,
  VerticalDividerFooterLeft,
  VerticalDividerFooterRight
} from '../styles';

interface IFooterPrintLayoutProps {
  gfp: IGfp;
}

export default function FooterPrintLayout({ gfp }: IFooterPrintLayoutProps) {
  const formattedValue = gfp.totalValue.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  return (
    <>
      <Column height="6%">
        <ColumnItem width="17.52%">
          <BorderStyled
            padding="0.10rem"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center'
            }}
          >
            <Identifier
              as="h2"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '9px',
                textAlign: 'center'
              }}
            >
              NATUREZA DA <br />
              OPERAÇÃO
            </Identifier>
          </BorderStyled>
        </ColumnItem>
        <ColumnItem width="37.34%">
          <BorderStyled
            padding="0.10rem"
            style={{
              display: 'flex',
              justifyContent: 'star',
              alignItems: 'center'
            }}
          >
            <TextBold style={{ fontSize: '14px' }}>
              {gfp.operationNature}
            </TextBold>
          </BorderStyled>
        </ColumnItem>
        <ColumnItem
          width="24.07%"
          style={{
            textAlign: 'center',
            fontWeight: 'bold'
          }}
        >
          <BorderStyled padding="0.10rem">
            <BarcodeContainer>
              <Identifier as="h2">DESTINATÁRIO DAS VIAS:</Identifier>
              <Text style={{ fontSize: '9px' }}>
                1ª via Cooperativa Destinatária/Transporte
              </Text>
            </BarcodeContainer>
          </BorderStyled>
        </ColumnItem>
        <ColumnItem
          width="21.07%"
          style={{
            height: '202%'
          }}
        >
          <BorderStyled height="100%">
            <ContainerFlex
              style={{
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <div style={{ position: 'absolute', top: '78%', right: '12%' }}>
                <Text style={{ fontSize: '18px' }}>{formattedValue}</Text>
              </div>

              <LeftOutlined
                style={{
                  position: 'absolute',
                  top: '70.6%',
                  right: '8%',
                  fontSize: '70px',
                  maxWidth: '50px',
                  maxHeight: '60px',
                  textAlign: 'center',
                  stroke: 'white',
                  strokeWidth: '62',
                  marginBottom: '20px',
                  marginTop: '8px'
                }}
              />

              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  marginBottom: '7%'
                }}
              >
                <Identifier
                  as="h2"
                  style={{
                    fontSize: '15px',
                    position: 'absolute',
                    right: '3%',
                    top: '75%'
                  }}
                >
                  VALOR <br />
                  TOTAL
                </Identifier>
              </div>
            </ContainerFlex>
          </BorderStyled>
        </ColumnItem>
      </Column>
      <ColumnItem width="78.93%" height="6%">
        <BorderStyled padding="0.10rem">
          <BarcodeContainer>
            <Identifier as="h2">OBSERVAÇÃO</Identifier>
            <Text
              style={{
                fontSize: '14px',
                fontWeight: 'normal',
                display: 'flex',
                justifyContent: 'start',
                marginBottom: '10px'
              }}
            >
              {gfp.observation && gfp.observation.length > 93
                ? `${gfp.observation.substring(0, 93)}`
                : gfp.observation}
            </Text>
          </BarcodeContainer>
        </BorderStyled>
      </ColumnItem>

      <Column height="11%">
        <ColumnItem
          width="78.93%"
          style={{
            fontWeight: 'bold'
          }}
        >
          <BorderStyled padding="0.10rem">
            <BarcodeContainer style={{ lineHeight: '2px', padding: '1px' }}>
              <Identifier
                as="h2"
                style={{
                  fontSize: '8px',
                  display: 'flex',
                  position: 'relative',
                  width: '100%'
                }}
              >
                <span
                  style={{
                    position: 'absolute',
                    left: '0%',
                    width: '15%',
                    fontSize: '6px'
                  }}
                >
                  <TextBold>TRANSPORTADOR</TextBold>
                </span>
                <span
                  style={{
                    position: 'absolute',
                    left: '10%',
                    width: '35%'
                  }}
                >
                  {gfp.transport.transportName}
                </span>
                <span
                  style={{
                    position: 'absolute',
                    left: '46%',
                    width: '15%',
                    fontSize: '6px'
                  }}
                >
                  <TextBold>FRETE P/ CONTA</TextBold>
                </span>
                <span
                  style={{
                    position: 'absolute',
                    left: '60%',
                    width: '10%',
                    fontSize: '6px'
                  }}
                >
                  <TextBold>P. VEÍCULO</TextBold>
                </span>
                <span
                  style={{ position: 'absolute', left: '67%', width: '10%' }}
                >
                  {gfp.transport.vehiclePlate}
                </span>
                <span
                  style={{
                    position: 'absolute',
                    left: '73%',
                    width: '5%',
                    fontSize: '6px'
                  }}
                >
                  <TextBold>UF</TextBold>
                </span>
                <span
                  style={{ position: 'absolute', left: '76%', width: '5%' }}
                >
                  {gfp.transport.state}
                </span>
                <span
                  style={{
                    position: 'absolute',
                    left: '82%',
                    width: '10%',
                    fontSize: '6px'
                  }}
                >
                  <TextBold>CPF/CNPJ</TextBold>
                </span>
                <span
                  style={{ position: 'absolute', left: '90%', width: '10%' }}
                >
                  {gfp.transport.cnpjCpf}
                </span>
              </Identifier>
              <Identifier style={{ fontSize: '8px' }}>
                <TextBold style={{ marginLeft: '46%', fontSize: '8px' }}>
                  1 - EMITENTE
                </TextBold>
              </Identifier>
              <Identifier
                as="h2"
                style={{
                  fontSize: '8px',
                  display: 'flex',
                  width: '100%'
                }}
              >
                <span
                  style={{
                    position: 'absolute',
                    left: '2.8%',
                    width: '6%',
                    fontSize: '6px'
                  }}
                >
                  <TextBold>ENDEREÇO</TextBold>
                </span>
                <span
                  style={{ position: 'absolute', left: '10%', width: '38%' }}
                >
                  {gfp.transport.address}
                </span>
                <span
                  style={{
                    position: 'absolute',
                    left: '37%',
                    width: '15%',
                    fontSize: '8px'
                  }}
                >
                  <TextBold>2 - DESTINATÁRIO</TextBold>
                </span>
                <div>
                  <VerticalDividerFooterRight />
                  <p
                    style={{
                      position: 'absolute',
                      left: '46.7%',
                      bottom: '10.8%',
                      fontSize: '10px'
                    }}
                  >
                    {gfp.transport.freightByAccount === GfpPrintEnum.E
                      ? 'E'
                      : 'D'}
                  </p>
                  <VerticalDividerFooterLeft />
                </div>
              </Identifier>
              <Identifier as="h2" style={{ fontSize: '8px' }}>
                <TextBold
                  style={{
                    marginLeft: '45.8%',
                    marginRight: '10px',
                    fontSize: '6px'
                  }}
                >
                  MUN.
                </TextBold>
                {gfp.transport.city}
                <TextBold
                  style={{
                    marginLeft: '10%',
                    marginRight: '15px',
                    fontSize: '6px'
                  }}
                >
                  UF
                </TextBold>
                {gfp.transport.state}
                <TextBold
                  style={{
                    marginLeft: '5%',
                    marginRight: '10px',
                    fontSize: '6px'
                  }}
                >
                  I. EST.
                </TextBold>
                {gfp.transport.ie}
              </Identifier>
              <Identifier as="h2" style={{ fontSize: '8px' }}>
                <TextBold style={{ marginRight: '4.5%', fontSize: '6px' }}>
                  QUANTIDADE
                </TextBold>
                {gfp.transport.quantity}
                <TextBold
                  style={{
                    marginLeft: '8%',
                    marginRight: '10px',
                    fontSize: '7px'
                  }}
                >
                  ESPÉCIE
                </TextBold>
                {gfp.transport.species}
                <TextBold
                  style={{
                    marginLeft: '10%',
                    marginRight: '10px',
                    fontSize: '6px'
                  }}
                >
                  MARCA
                </TextBold>
                {gfp.transport.brand}
                <TextBold
                  style={{
                    marginLeft: '11.3%',
                    marginRight: '10px',
                    fontSize: '6px'
                  }}
                >
                  NÚMERO
                </TextBold>
                {gfp.transport.number}
                <TextBold
                  style={{
                    marginLeft: '13%',
                    marginRight: '10px',
                    fontSize: '6px'
                  }}
                >
                  P.BRUTO
                </TextBold>
                {gfp.transport.grossWeight}
                <TextBold
                  style={{
                    marginLeft: '7%',
                    marginRight: '10px',
                    fontSize: '6px'
                  }}
                >
                  P.LÍQUIDO
                </TextBold>
                {gfp.transport.netWeight}
              </Identifier>
            </BarcodeContainer>
          </BorderStyled>
        </ColumnItem>
        <ColumnItem
          width="21.07%"
          style={{
            textAlign: 'center',
            fontWeight: 'bold'
          }}
        >
          <BorderStyled padding="0.10rem">
            <BarcodeContainer
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Identifier as="h2" style={{ fontSize: '8px' }}>
                {gfp.producer.address.includes('SP') ? (
                  <TextBold>
                    REGIME ESPECIAL PROCESSO ELETRÔNICO 8008/2016
                  </TextBold>
                ) : (
                  ''
                )}
              </Identifier>
            </BarcodeContainer>
          </BorderStyled>
        </ColumnItem>
      </Column>

      <Column height="3%">
        <ColumnItem width="100%">
          <BorderStyled padding="0.10rem">
            <BarcodeContainer>
              <SubIdentifier style={{ fontSize: '10px' }}>
                <TextBold>PORTAL DE CONSULTA AUTENTICIDADE:</TextBold>{' '}
                <a
                  href="https://cvhnet.veilingnet.com.br/consultaGFPe"
                  style={{ color: '#000' }}
                >
                  <span key={gfp.authenticityCheckPortal}>
                    {gfp.authenticityCheckPortal}
                  </span>
                </a>
                <TextBold style={{ marginLeft: '240px' }}>
                  IMPRESSO POR:
                </TextBold>{' '}
                Veiling Online
              </SubIdentifier>
            </BarcodeContainer>
          </BorderStyled>
        </ColumnItem>
      </Column>
    </>
  );
}

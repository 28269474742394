import { Card, FormInstance } from 'antd';
import FormFilterDataModel from '~/@core/domain/ui/forms/FormFilterDataModel';
import FormFilterReceiptDones from '~/components/molecules/RfidReports/Receipt/Dones/FormFilterReceiptDones';
import TableReceiptDones from '~/components/molecules/RfidReports/Receipt/Dones/TableReceiptDones';
import FormFilterReceiptNonConformity from '~/components/molecules/RfidReports/Receipt/NonConformity/FormFilterReceiptNonConformity';
import TableReceiptNonConformity from '~/components/molecules/RfidReports/Receipt/NonConformity/TableReceiptNonConformity';
import FormFilterReceiptTransit from '~/components/molecules/RfidReports/Receipt/Transit/FormFilterReceiptTransit';
import TableReceiptTransit from '~/components/molecules/RfidReports/Receipt/Transit/TableReceiptTransit';
import FormFilterReceiptVision from '~/components/molecules/RfidReports/Receipt/Vision/FormFilterReceiptVision';
import TableReceiptVision from '~/components/molecules/RfidReports/Receipt/Vision/TableReceiptVision';
import { TabContainerForm } from '~/pages/Home/styles';

interface IProps {
  filterFormDone: FormInstance<FormFilterDataModel>;
  filterFormNonConformity: FormInstance<FormFilterDataModel>;
  filterFormTransit: FormInstance<FormFilterDataModel>;
  filterFormVision: FormInstance<FormFilterDataModel>;
}
export default function ReceiptWrapper(props: IProps) {
  const {
    filterFormDone,
    filterFormNonConformity,
    filterFormTransit,
    filterFormVision
  } = props;

  return (
    <Card>
      <TabContainerForm>
        <TabContainerForm.TabPane tab="Realizados" key="1">
          <FormFilterReceiptDones formDone={filterFormDone} />
          <TableReceiptDones form={filterFormDone} />
        </TabContainerForm.TabPane>

        <TabContainerForm.TabPane tab="Inconformidade" key="2">
          <FormFilterReceiptNonConformity form={filterFormNonConformity} />
          <TableReceiptNonConformity form={filterFormNonConformity} />
        </TabContainerForm.TabPane>

        <TabContainerForm.TabPane tab="Trânsito" key="4">
          <FormFilterReceiptTransit form={filterFormTransit} />
          <TableReceiptTransit form={filterFormTransit} />
        </TabContainerForm.TabPane>

        <TabContainerForm.TabPane tab="Visão Inconformidade" key="5">
          <FormFilterReceiptVision form={filterFormVision} />
          <TableReceiptVision form={filterFormVision} />
        </TabContainerForm.TabPane>
      </TabContainerForm>
    </Card>
  );
}

import { RangePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';
import { useEffect, useState } from 'react';
import * as S from './styles';

export default function DeliveryDateSelect(props: RangePickerProps) {
  const [datesDelivery, setDatesDelivery] = useState<any[] | null>([]);
  const [selectedDeliveryStartDate, setSelectedDeliveryStartDate] = useState<
    moment.Moment | undefined
  >(undefined);
  const [selectedDeliveryEndDate, setSelectedDeliveryEndDate] = useState<
    moment.Moment | undefined
  >(undefined);

  useEffect(() => {
    if (datesDelivery) {
      setSelectedDeliveryStartDate(datesDelivery[0]);
      setSelectedDeliveryEndDate(datesDelivery[1]);
    }
  }, [datesDelivery]);

  const disabledDateDelivery = (current: any) => {
    if (!datesDelivery || datesDelivery.length === 0) {
      return false;
    }
    const tooLate =
      datesDelivery[0] && current.diff(datesDelivery[0], 'days') > 30;
    const past =
      datesDelivery[0] && moment(datesDelivery[0]).add(-1, 'days') >= current;
    const tooEarly =
      datesDelivery[1] && datesDelivery[1].diff(current, 'days') > 30;
    return tooEarly || tooLate || past;
  };

  return (
    <S.DeliveryDateDiv>
      <S.DeliveryDateRangePicker
        disabledDate={disabledDateDelivery}
        onCalendarChange={val => setDatesDelivery(val)}
        value={
          selectedDeliveryStartDate && selectedDeliveryEndDate
            ? [selectedDeliveryStartDate, selectedDeliveryEndDate]
            : undefined
        }
        format="DD/MM/YYYY"
        size="middle"
        {...props}
      />
    </S.DeliveryDateDiv>
  );
}

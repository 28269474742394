import { createSelector } from 'reselect';
import { VolOffersListState } from './duck';

export const getRoot = (state: any) => {
  return state.ui.volOfferList as VolOffersListState;
};
export const getPagination = createSelector(
  [getRoot],
  state => state.pagination
);

export const getHistory = createSelector([getRoot], state => state.history);

export const getVolOfferTypes = createSelector(
  [getRoot],
  state => state.volOfferTypes
);

export const getConfirmSave = createSelector(
  [getRoot],
  state => state.confirmSave
);

export const getLoading = createSelector([getRoot], state => state.loading);

export const getWaitEditOpen = createSelector(
  [getRoot],
  state => state.waitEditOpen
);

import { AnnouncementBannerState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.ui.announcementBanner as AnnouncementBannerState;
};

export const getStatusAnnouncementBanner = createSelector(
  [getRoot],
  state => state.requestAnnouncementBannerStatus
);

export const isLoadingFileById = createSelector(
  [getRoot],
  state => state.requestGetFilesByIdStatus === 'pending'
);

export const getStatusPost = createSelector(
  [getRoot],
  state => state.postReadAnnouncementStatus
);

export const getFinished = createSelector([getRoot], state => state.finished);

import PropTypes from 'prop-types';
import { Route, useLocation } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import store from '~/store';

import DefaultLayout from '~/pages/_layouts/default';
import Loading from '~/components/Loading';
import { getTokens } from '~/helpers/util/storage';

interface RouteType {
  component: any;
  tabName?: string;
  path: string;
  exact?: boolean;
  isPrivate?: boolean;
  logout?: boolean;
}

export default function RouteWrapper({
  component: Component,
  path,
  exact,
  isPrivate,
  // #FIXME
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  logout,
  ...rest
}: RouteType) {
  // #FIXME
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
  const dispatch = useDispatch();
  const location = useLocation();

  // #FIXME
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars, @typescript-eslint/no-explicit-any
  const state: any = store.getState();
  const tokens = getTokens();

  if (isPrivate && !tokens.accessToken) {
    window.location.href =
      `${process.env.REACT_APP_SINGLE_SIGNON_REDIRECT}` as string;
  }

  const getLayout = (props: any) => {
    if (location.pathname === '/oauth/') {
      return (
        <>
          <Component {...props} />
          <Loading loading />
        </>
      );
    }
    return <DefaultLayout />;
  };
  return (
    <Route
      path={path}
      exact={exact}
      {...rest}
      render={props => getLayout(props)}
    />
  );
}

RouteWrapper.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
};

import { useEffect } from 'react';

import { getValidadeToken } from '~/helpers/util/validatorToken';

export default function useValidateToken() {
  useEffect(() => {
    const handleBodyClick = (event: MouseEvent) => {
      getValidadeToken({ mouseEvent: event, imputEvent: null });
    };

    const handleBodyInput = (event: Event) => {
      getValidadeToken({ mouseEvent: null, imputEvent: event });
    };

    document.body.addEventListener('click', handleBodyClick);
    document.body.addEventListener('input', handleBodyInput);

    return () => {
      document.body.removeEventListener('click', handleBodyClick);
      document.body.removeEventListener('input', handleBodyInput);
    };
  }, []);
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import {
  CreateExpeditionParams,
  Expedition,
  FindExpeditionsParams
} from '~/domain/model';
import * as expeditionService from '~/services/producer/Expedition';
import { alertRequest } from '../../alert/actions';

export const fetchExpeditions = createAsyncThunk(
  `expeditions/fetchExpeditions`,
  async (params: FindExpeditionsParams) => {
    const expeditionsData: any = await expeditionService.findMany(params);
    return expeditionsData.results;
  }
);

export const fetchExpeditionsBySiteId = createAsyncThunk(
  `expeditions/fetchExpeditionsBySiteId`,
  async (siteId: number) => {
    const expeditionsData: any =
      await expeditionService.findExpeditionsBySiteId(siteId);
    return expeditionsData;
  }
);

export const fetchExpeditionsHistory = createAsyncThunk(
  `expeditions/fetchExpeditionsHistory`,
  async (expeditionId: number) => {
    const data = await expeditionService.findHistory(expeditionId);
    return data;
  }
);

export const deleteExpedition = createAsyncThunk(
  `expeditions/deleteExpedition`,
  async (expeditionId: number, { dispatch, rejectWithValue }) => {
    try {
      await expeditionService.deleteExpedition(expeditionId);
      dispatch(alertRequest('Expedição Excluida com sucesso!', 'success'));
      return expeditionId;
    } catch (err: unknown) {
      const error = err as AxiosError;
      dispatch(
        alertRequest(
          JSON.stringify(error.response?.data.errors.messages),
          'error'
        )
      );
      return rejectWithValue([]);
    }
  }
);

export const editExpeditions = createAsyncThunk(
  `expeditions/editExpeditions`,
  async (
    createExpeditionParams: CreateExpeditionParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      await expeditionService.createExpedition(createExpeditionParams);
      dispatch(alertRequest('Expedição editada com sucesso!', 'success'));
      return createExpeditionParams as Expedition;
    } catch (err: unknown) {
      const error = err as AxiosError;
      dispatch(
        alertRequest(
          JSON.stringify(error.response?.data.errors.messages),
          'error'
        )
      );
      return rejectWithValue([]);
    }
  }
);

export const createExpeditions = createAsyncThunk(
  `expeditions/createExpedition`,
  async (
    createExpeditionParams: CreateExpeditionParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await expeditionService.createExpedition(
        createExpeditionParams
      );
      dispatch(alertRequest('Expedição criada com sucesso!', 'success'));
      return response as Expedition;
    } catch (err: unknown) {
      const error = err as AxiosError;
      dispatch(
        alertRequest(
          JSON.stringify(error.response?.data.errors.messages),
          'error'
        )
      );
      return rejectWithValue([]);
    }
  }
);

import { ReportsAnnouncementState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.ui.reportsAnnouncement as ReportsAnnouncementState;
};

export const getActiveKey = createSelector([getRoot], state => state.activeKey);

export const getStatusReportsAnnouncement = createSelector(
  [getRoot],
  state => state.requestReportsAnnouncementStatus
);

export const getIsLoading = createSelector(
  [getRoot],
  state =>
    state.requestReportsAnnouncementStatus === 'pending' ||
    state.exportStatus === 'pending'
);

export const getPageNumber = createSelector(
  [getRoot],
  state => state.pageNumber
);

export const getPageSize = createSelector([getRoot], state => state.pageSize);

export const getTotalCount = createSelector(
  [getRoot],
  state => state.totalCount
);

export const getExportStatus = createSelector(
  [getRoot],
  state => state.exportStatus
);

export const getErrorMessage = createSelector(
  [getRoot],
  state => state.errorMessage
);

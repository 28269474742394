import produce from 'immer';
import { OfferChannel } from '~/domain/model';
import { createCreator, createReducer, Reducer } from '~/helpers/util/reducers';

export type RequestStatus = 'idle' | 'running' | 'success' | 'failure';

export type OfferChannelCreateState = {
  offerChannel: OfferChannel;
  requestStatus: RequestStatus;
  createStatus: RequestStatus;
  showOfferChannelCreate: boolean;
};

export type ErrorResponse = {
  requestStatus: RequestStatus;
};

type ShowOfferChannelState = boolean;

export type CreateInit = {
  id: number;
  categoryId: number;
  percentLkp: number;
  percentVol: number;
};

export const initialState: OfferChannelCreateState = {
  offerChannel: new OfferChannel({}),
  requestStatus: 'idle',
  createStatus: 'idle',
  showOfferChannelCreate: false
};

const prefix = '@offerChannelCreate';

export const Types = Object.freeze({
  FETCH_INIT: `${prefix}/FETCH_INIT`,
  FETCH_SUCCESS: `${prefix}/FETCH_SUCCESS`,
  FETCH_FAILURE: `${prefix}/FETCH_FAILURE`,
  CLEAR: `${prefix}/CLEAR`,
  CREATE_INIT: `${prefix}/CREATE_INIT`,
  CREATE_SUCCESS: `${prefix}/CREATE_SUCCESS`,
  CREATE_FAILURE: `${prefix}/CREATE_FAILURE`,
  SHOW_OFFER_CHANNEL_CREATE: `${prefix}/SHOW_OFFER_CHANNEL_CREATE`
});

const fetchInitReducer: Reducer<OfferChannelCreateState, void> = state => {
  return produce(state, draft => {
    draft.requestStatus = 'running';
  });
};

const fetchSuccessReducer: Reducer<OfferChannelCreateState, void> = state => {
  return produce(state, draft => {
    draft.requestStatus = 'success';
  });
};

const fetchFailureReducer: Reducer<OfferChannelCreateState, void> = state => {
  return produce(state, draft => {
    draft.requestStatus = 'failure';
  });
};

const clearReducer: Reducer<OfferChannelCreateState, void> = state => {
  return produce(state, draft => {
    draft.offerChannel = new OfferChannel({});
    draft.requestStatus = 'idle';
    draft.createStatus = 'idle';
    draft.showOfferChannelCreate = false;
  });
};

const createInitReducer: Reducer<OfferChannelCreateState, void> = state => {
  return produce(state, draft => {
    draft.createStatus = 'running';
  });
};

const createSuccessReducer: Reducer<OfferChannelCreateState, void> = state => {
  return produce(state, draft => {
    draft.createStatus = 'success';
  });
};

const createFailureReducer: Reducer<OfferChannelCreateState, ErrorResponse> = (
  state,
  action
) => {
  return produce(state, draft => {
    draft.createStatus = action.requestStatus;
  });
};

const showOfferChannelCreateReducer: Reducer<
  OfferChannelCreateState,
  ShowOfferChannelState
> = (state, action) => {
  return produce(state, draft => {
    draft.showOfferChannelCreate = action;
  });
};

export const actions = Object.freeze({
  fetchInit: createCreator<void>(Types.FETCH_INIT),
  fetchSuccess: createCreator<void>(Types.FETCH_SUCCESS),
  fetchFailure: createCreator<void>(Types.FETCH_FAILURE),
  clear: createCreator<void>(Types.CLEAR),
  createInit: createCreator<CreateInit>(Types.CREATE_INIT),
  createSuccess: createCreator<void>(Types.CREATE_SUCCESS),
  createFailure: createCreator<ErrorResponse>(Types.CREATE_FAILURE),
  showOfferChannelCreate: createCreator<ShowOfferChannelState>(
    Types.SHOW_OFFER_CHANNEL_CREATE
  )
});

export const reducer = createReducer(initialState, {
  [Types.FETCH_INIT]: fetchInitReducer,
  [Types.FETCH_SUCCESS]: fetchSuccessReducer,
  [Types.FETCH_FAILURE]: fetchFailureReducer,
  [Types.CLEAR]: clearReducer,
  [Types.CREATE_INIT]: createInitReducer,
  [Types.CREATE_SUCCESS]: createSuccessReducer,
  [Types.CREATE_FAILURE]: createFailureReducer,
  [Types.SHOW_OFFER_CHANNEL_CREATE]: showOfferChannelCreateReducer
});

import { AxiosError, AxiosResponse } from 'axios';
import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';
import ShippingCompany from '~/@core/domain/model/site/ShippingCompany';
import api from '../config';

export const fetchShippingCompany = (siteId: number) => {
  return new Promise<DefaultPaginated<ShippingCompany[]>>((resolve, reject) => {
    api
      .get<void, AxiosResponse<DefaultPaginated<ShippingCompany[]>>>(
        `/shipping-company?Data.SiteId=${siteId}`
      )
      .then((response: AxiosResponse<DefaultPaginated<ShippingCompany[]>>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

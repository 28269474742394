import produce from 'immer';

const INITIAL_STATE = {
  sites: [],
  loading: false
};

export default function siteSimpleList(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case '@siteSimpleList/SEARCH_REQUEST':
      return produce(state, draft => {
        draft.loading = true;
      });

    case '@siteSimpleList/SEARCH_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.sites = action.payload.data;
      });

    case '@siteSimpleList/SEARCH_FAILURE':
      return produce(state, draft => {
        draft.loading = false;
      });

    default:
      return state;
  }
}

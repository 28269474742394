import { call, put } from 'redux-saga/effects';
import { actions, ParamRequestReportsAnnouncement } from './duck';
import * as API from '~/domain/api';
import * as ReportsAnnouncementService from '~/services/producer/ReportsAnnouncement';
import { ReportsAnnouncement } from '~/domain/model';

export function* requestReportsAnnouncement({
  description,
  startDate,
  endDate,
  role,
  siteId,
  pageNumber,
  pageSize
}: ParamRequestReportsAnnouncement) {
  yield put(actions.update({ reportsAnnouncement: [] }));
  const response: API.ServerResponse = yield call(
    ReportsAnnouncementService.requestReportsAnnouncement,
    description,
    startDate,
    endDate,
    role,
    siteId,
    pageNumber,
    pageSize
  );
  const data: API.DefaultResponse = response.data;
  const reportsAnnouncementResult: API.Announcement[] = data.results ?? [];
  let reportsAnnouncement: ReportsAnnouncement[] = [];
  if (reportsAnnouncementResult) {
    reportsAnnouncement = reportsAnnouncementResult.map(
      (reports: API.ReportsAnnouncement) => new ReportsAnnouncement(reports)
    );
  }
  yield put(actions.update({ reportsAnnouncement }));
  return response;
}

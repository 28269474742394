import moment, { Moment } from 'moment';
import { VolOffer } from '~/domain/model';

export const formatDate = (date: string, format = 'DD/MM/YYYY'): string => {
  return moment(date).format(format);
};

export const formatDateAndTime = (date: string): string => {
  const format = 'DD/MM/YYYY - HH:mm';
  return moment(date).format(format);
};

export const formatDateAndTimePortuguese = (date: string): string => {
  return `${moment(date).format('L')} às ${moment(date).format('LTS')}`;
};

export const setTimeToZero = (date: Date) => {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

export const dateIsDisabled = (
  currentDate: Moment,
  selectedWeek: [number, number]
) => {
  const weekNumber = parseInt(currentDate?.format('w'), 10);
  return weekNumber <= selectedWeek[0] && weekNumber >= selectedWeek[1];
};

export const dateIsPastDateOrUndefined = (
  date: Moment | string | Date | undefined | null
) => {
  if (!date) return true;
  const today = moment();
  const inputDate = moment(date);
  return inputDate.isBefore(today, 'day');
};

export const dateIsBetween = (volOffer: VolOffer, currentDate: Moment) => {
  const startDate = moment(volOffer.weekStartDate);
  const endDate = moment(volOffer.weekEndDate);
  if (
    currentDate.isSameOrAfter(startDate) &&
    currentDate.isSameOrBefore(endDate)
  ) {
    return false;
  }
  return true;
};

export const weekIsNotSame = (currentDate: Moment, startDate: Moment) => {
  const currentWeek = currentDate.week();
  const startDateWeek = moment(startDate).week();
  return currentWeek !== startDateWeek;
};

export const disablePreviousDates = (value: Moment) => {
  const initalDay = moment().add(-1, 'day');
  return value.isBefore(initalDay);
};

export const disableBillingDates = (value: Moment, deliveryDate: Moment) => {
  if (deliveryDate) {
    if (value.isBefore(deliveryDate, 'day')) {
      return true;
    }
    const saturday = Boolean(deliveryDate?.weekday() === 6);
    if (saturday) {
      const lastDay = moment(deliveryDate).add(2, 'days');
      return value.isAfter(lastDay, 'day');
    }
    const lastDay = moment(deliveryDate).add(1, 'days');
    return value.isAfter(lastDay, 'day');
  }
  return true;
};

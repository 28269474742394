export const PermissionNames = {
  MenuDashboard: 'Menu.Dashboard',
  Registers: {
    MenuRegisters: 'Menu.Registers',
    MenuProperty: 'Menu.Registers.Property',
    MenuProductionPlanning: 'Menu.Registers.ProductionPlanning',
    MenuPacking: 'Menu.Registers.Packaging',
    MenuProductorPreferences: 'Menu.Registers.ProducerPreferences',
    MenuStock: 'Menu.Registers.Stock',
    MenuExpedition: 'Menu.Registers.Expedition',
    MenuShippingCompany: 'Menu.Registers.ShippingCompany',
    MenuSiteDecorators: 'Menu.Registers.ManageDecorators',
    MenuPLP: 'Menu.Registers.PLP'
  },
  Commercial: {
    MenuCommercial: 'Menu.CommercialManager',
    MenuAutoservice: 'Menu.CommercialManager.SelfService',
    MenuCommercialManagement: 'Menu.CommercialManager.EcommerceMonitoring',
    MenuManagementEcommerce: 'Menu.CommercialManager.PreferencesOfferChannels',
    MenuBackoutDates: 'Menu.CommercialManager.UnavailableDatesEcommerce',
    MenuKeyManagementEcommerce: 'Menu.CommercialManager.KeyLiberation'
  },
  Announcements: {
    MenuAnnouncements: 'Menu.Announcements',
    MenuAnnouncementsList: 'Menu.Announcements.AnnouncementsList'
  },
  Quality: {
    MenuQuality: 'Menu.QualityManager',
    MenuCompetence: 'Menu.QualityManager.Competence',
    MenuTechnicalSpecification: 'Menu.QualityManager.TechnicalSpecification',
    MenuProductBarCode: 'Menu.QualityManager.ProductBarCode',
    MenuProductionPlanningReplicate:
      'Menu.QualityManager.ProductionPlanningReplicate'
  },
  Gfp: {
    MenuGFP: 'Menu.GFP',
    MenuGFP2: 'Menu.GFP.GFP',
    MenuTagCart: 'Menu.GFP.TagCart',
    MenuOrders: 'Menu.GFP.Orders',
    MenuAcceptanceTerms: 'Menu.GFP.AcceptanceTerms',
    MenuAuthorization: 'Menu.GFP.Authorization'
  },
  Offers: {
    MenuOffers: 'Menu.Offers',
    MenuVouchers: 'Menu.Offers.Voucher',
    MenuVolLkp: 'Menu.Offers.RegisterOffer'
  },
  MarketingManagement: {
    MenuMarketingManagement: 'Menu.MarketingManager',
    MenuBanner: 'Menu.MarketingManager.Banner',
    MenuRegisterAnnouncement: 'Menu.MarketingManager.Announcements',
    MenuProducerPage: 'Menu.MarketingManager.ProducerPage'
  },
  ProducerManagement: {
    MenuProducerManagement: 'Menu.ProducerManager',
    MenuProducerPageManagement: 'Menu.ProducerManager.ProducerPage',
    MenuProductRegistrationRequest:
      'Menu.ProducerManager.ManageProductRegistration'
  },
  Reports: {
    MenuReports: 'Menu.Reports',
    MenuOfferReport: 'Menu.Reports.SalesReport',
    MenuOfferReportEcommerce: 'Menu.Reports.SalesReportEcommerce',
    MenuAnnouncementReports: 'Menu.Reports.Announcement',
    MenuPriceReport: 'Menu.Reports.Price',
    MenuKlokDrawReport: 'Menu.Reports.KlokDraw',
    MenuDetailedStock: 'Menu.Reports.DetailedStock',
    MenuAgreement: 'Menu.Reports.Agreement',
    MenuProductCatalog: 'Menu.Reports.ProductCatalog',
    MenuCustomerCatalog: 'Menu.Reports.CustomerCatalog',
    MenuWithheldGfp: 'Menu.Reports.WithheldGfp'
  },
  Orders: {
    MenuOrders: 'Menu.Orders',
    MenuOrder: 'Menu.Orders.Visualize',
    MenuOrdersToCollect: 'Menu.Orders.OrderToCollect',
    MenuTagPrintingList: 'Menu.Orders.TagPrintList',
    MenuOrdersGfp: 'Menu.Orders.OrdersGfp'
  },
  MenuIndicatorsPanel: 'Menu.IndicatorsPanel',
  TiManagement: {
    MenuTiManagement: 'Menu.ITManager',
    MenuBIdashboard: 'Menu.ITManager.BiDashboard'
  },
  RfidReports: {
    MenuRfidReports: 'Menu.RfidReports'
  }
};

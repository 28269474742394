import { PlusOutlined } from '@ant-design/icons';
import { ButtonProps } from 'antd';
import IconButton from './IconButton';

type Props = ButtonProps & {
  tooltip?: string;
};

export default function AddButton(props: Props) {
  return (
    <IconButton
      {...props}
      tooltip={props.tooltip || 'Adicionar'}
      icon={<PlusOutlined />}
    />
  );
}

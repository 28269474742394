import { create } from 'zustand';
import Producer from '~/@core/domain/model/rfidReports/Producer';
import TablePagination from '~/@core/domain/ui/TablePagination';

interface ProducerState {
  producers: Producer[];
  producerPagination: TablePagination;
  handleProducerPagination: (newPagination: TablePagination) => void;
  addProducers: (producersValues: Producer[]) => void;
}

const useProducerStore = create<ProducerState>()(set => ({
  producers: [],
  producerPagination: {
    pageSize: 10,
    current: 1,
    total: 1
  },
  handleProducerPagination: newPagination =>
    set({ producerPagination: newPagination }),
  addProducers: producersValues => set({ producers: producersValues })
}));

export default useProducerStore;

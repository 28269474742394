import IUseCase from '../../../IUseCase';
import { ProductService } from '~/@core/infrastructure/api/services';
import ProductQuality from '~/@core/domain/model/product/ProductQuality';

export default class GetProductQualities
  implements IUseCase<void, Promise<ProductQuality[]>>
{
  execute(): Promise<ProductQuality[]> {
    return ProductService.fetchProductQualities();
  }
}

import produce from 'immer';
import { OfferChannel } from '~/domain/model';
import { createCreator, createReducer, Reducer } from '~/helpers/util/reducers';

export type FetchStatus = 'idle' | 'fetching' | 'success' | 'failure';
export type UpdateStatus = 'idle' | 'running' | 'success' | 'failure';

export type OfferChannelListState = {
  fetchStatus: FetchStatus;
  pageNumber: number;
  pageSize: number;
  totalCount: number;
  fetchHistoryStatus: FetchStatus;
  pageNumberHistory: number;
  pageSizeHistory: number;
  totalCountHistory: number;
  showOfferChannelEditModal: boolean;
  showHistoryModal: boolean;
  offerChannelToEditOrHistory: OfferChannel;
  offerChannelUpdateStatus: UpdateStatus;
  historyStatus: FetchStatus;
};

export type FetchPage = {
  nextPage: number;
  nextPageSize: number;
};

export type FetchPageSuccess = {
  nextPage: number;
  nextPageSize: number;
  totalCount: number;
};

type ShowOfferChannelState = boolean;

type OfferChannelToEdit = {
  offerChannel: OfferChannel;
};

export type ErrorResponse = {
  requestStatus: UpdateStatus | FetchStatus;
};

export type UpdateInit = {
  id: number;
  categoryId: number;
  percentLkp: number;
  percentVol: number;
};

export type HistoryInit = {
  id: number;
  nextPage: number;
  nextPageSize: number;
};

export type HistorySuccess = {
  nextPage: number;
  nextPageSize: number;
  totalCount: number;
};

const prefix = '@offerChannelList';

export const Types = Object.freeze({
  CLEAR: `${prefix}/CLEAR`,
  FETCH_PAGE: `${prefix}/FETCH_PAGE`,
  FETCH_PAGE_SUCCESS: `${prefix}/FETCH_PAGE_SUCCESS`,
  FETCH_PAGE_FAILURE: `${prefix}/FETCH_PAGE_FAILURE`,
  SHOW_OFFER_CHANNEL_EDIT_MODAL: `${prefix}/SHOW_OFFER_CHANNEL_EDIT_MODAL`,
  SHOW_HISTORY_MODAL: `${prefix}/SHOW_HISTORY_MODAL`,
  SET_OFFER_CHANNEL_TO_EDIT_OR_HISTORY: `${prefix}/SET_OFFER_CHANNEL_TO_EDIT_OR_HISTORY`,
  UPDATE_INIT: `${prefix}/UPDATE_INIT`,
  UPDATE_SUCCESS: `${prefix}/UPDATE_SUCCESS`,
  UPDATE_FAILURE: `${prefix}/UPDATE_FAILURE`,
  HISTORY_INIT: `${prefix}/HISTORY_INIT`,
  HISTORY_SUCCESS: `${prefix}/HISTORY_SUCCESS`,
  HISTORY_FAILURE: `${prefix}/HISTORY_FAILURE`
});

export const initialState: OfferChannelListState = {
  fetchStatus: 'idle',
  pageNumber: 1,
  pageSize: 10,
  totalCount: 0,
  fetchHistoryStatus: 'idle',
  pageNumberHistory: 1,
  pageSizeHistory: 10,
  totalCountHistory: 0,
  showOfferChannelEditModal: false,
  showHistoryModal: false,
  offerChannelToEditOrHistory: new OfferChannel({}),
  offerChannelUpdateStatus: 'idle',
  historyStatus: 'idle'
};

const clearReducer: Reducer<OfferChannelListState, void> = state => {
  return produce(state, draft => {
    draft.fetchStatus = 'idle';
    draft.pageNumber = 1;
    draft.pageSize = 10;
    draft.totalCount = 0;
    draft.fetchHistoryStatus = 'idle';
    draft.pageNumberHistory = 1;
    draft.pageSizeHistory = 10;
    draft.totalCountHistory = 0;
    draft.showOfferChannelEditModal = false;
    draft.showHistoryModal = false;
    draft.offerChannelToEditOrHistory = new OfferChannel({});
    draft.offerChannelUpdateStatus = 'idle';
    draft.historyStatus = 'idle';
  });
};

const fetchPageReducer: Reducer<OfferChannelListState, void> = state => {
  return produce(state, draft => {
    draft.fetchStatus = 'fetching';
  });
};

const fetchPageSuccessReducer: Reducer<
  OfferChannelListState,
  FetchPageSuccess
> = (state, action) => {
  return produce(state, draft => {
    const { nextPage, nextPageSize, totalCount } = action;
    draft.fetchStatus = 'success';
    draft.pageNumber = nextPage;
    draft.pageSize = nextPageSize;
    draft.totalCount = totalCount;
  });
};

const fetchPageFailureReducer: Reducer<OfferChannelListState, void> = state => {
  return produce(state, draft => {
    draft.fetchStatus = 'failure';
  });
};

const showOfferChannelEditModalReducer: Reducer<
  OfferChannelListState,
  ShowOfferChannelState
> = (state, stateModal) => {
  return produce(state, draft => {
    draft.showOfferChannelEditModal = stateModal;
  });
};

const showHistoryModalReducer: Reducer<
  OfferChannelListState,
  ShowOfferChannelState
> = (state, stateModal) => {
  return produce(state, draft => {
    draft.showHistoryModal = stateModal;
  });
};

const setOfferChannelToEditOrHistoryReducer: Reducer<
  OfferChannelListState,
  OfferChannelToEdit
> = (state, action) => {
  return produce(state, draft => {
    draft.offerChannelToEditOrHistory = action.offerChannel;
  });
};

const updateInitReducer: Reducer<OfferChannelListState, void> = state => {
  return produce(state, draft => {
    draft.offerChannelUpdateStatus = 'running';
  });
};

const updateSuccessReducer: Reducer<OfferChannelListState, void> = state => {
  return produce(state, draft => {
    draft.offerChannelUpdateStatus = 'success';
  });
};

const updateFailureReducer: Reducer<OfferChannelListState, ErrorResponse> = (
  state,
  action
) => {
  return produce(state, draft => {
    draft.offerChannelUpdateStatus = action.requestStatus as UpdateStatus;
  });
};

const historyInitReducer: Reducer<OfferChannelListState, void> = state => {
  return produce(state, draft => {
    draft.historyStatus = 'fetching';
  });
};

const historySuccessReducer: Reducer<OfferChannelListState, HistorySuccess> = (
  state,
  action
) => {
  return produce(state, draft => {
    const { nextPage, nextPageSize, totalCount } = action;
    draft.historyStatus = 'success';
    draft.pageNumberHistory = nextPage;
    draft.pageSizeHistory = nextPageSize;
    draft.totalCountHistory = totalCount;
  });
};

const historyFailureReducer: Reducer<OfferChannelListState, ErrorResponse> = (
  state,
  action
) => {
  return produce(state, draft => {
    draft.historyStatus = action.requestStatus as FetchStatus;
  });
};

export const actions = Object.freeze({
  clear: createCreator<void>(Types.CLEAR),
  fetchPage: createCreator<FetchPage>(Types.FETCH_PAGE),
  fetchPageSuccess: createCreator<FetchPageSuccess>(Types.FETCH_PAGE_SUCCESS),
  fetchPageFailure: createCreator<void>(Types.FETCH_PAGE_FAILURE),
  showOfferChannelEditModal: createCreator<ShowOfferChannelState>(
    Types.SHOW_OFFER_CHANNEL_EDIT_MODAL
  ),
  showHistoryModal: createCreator<ShowOfferChannelState>(
    Types.SHOW_HISTORY_MODAL
  ),
  setOfferChannelToEditOrHistory: createCreator<OfferChannelToEdit>(
    Types.SET_OFFER_CHANNEL_TO_EDIT_OR_HISTORY
  ),
  updateInit: createCreator<UpdateInit>(Types.UPDATE_INIT),
  updateSuccess: createCreator<void>(Types.UPDATE_SUCCESS),
  updateFailure: createCreator<ErrorResponse>(Types.UPDATE_FAILURE),
  historyInit: createCreator<HistoryInit>(Types.HISTORY_INIT),
  historySuccess: createCreator<HistorySuccess>(Types.HISTORY_SUCCESS),
  historyFailure: createCreator<ErrorResponse>(Types.HISTORY_FAILURE)
});

export const reducer = createReducer(initialState, {
  [Types.CLEAR]: clearReducer,
  [Types.FETCH_PAGE]: fetchPageReducer,
  [Types.FETCH_PAGE_SUCCESS]: fetchPageSuccessReducer,
  [Types.FETCH_PAGE_FAILURE]: fetchPageFailureReducer,
  [Types.SHOW_OFFER_CHANNEL_EDIT_MODAL]: showOfferChannelEditModalReducer,
  [Types.SHOW_HISTORY_MODAL]: showHistoryModalReducer,
  [Types.SET_OFFER_CHANNEL_TO_EDIT_OR_HISTORY]:
    setOfferChannelToEditOrHistoryReducer,
  [Types.UPDATE_INIT]: updateInitReducer,
  [Types.UPDATE_SUCCESS]: updateSuccessReducer,
  [Types.UPDATE_FAILURE]: updateFailureReducer,
  [Types.HISTORY_INIT]: historyInitReducer,
  [Types.HISTORY_SUCCESS]: historySuccessReducer,
  [Types.HISTORY_FAILURE]: historyFailureReducer
});

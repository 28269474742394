export function localizationSearchRequest(cep) {
  return {
    type: '@localization/SEARCH_REQUEST',
    payload: { cep }
  };
}

export function localizationSearchSuccess(data, cities) {
  return {
    type: '@localization/SEARCH_SUCCESS',
    payload: { data, cities }
  };
}

export function localizationSearchFailure() {
  return {
    type: '@localization/SEARCH_FAILURE'
  };
}

export function localizationStateRequest() {
  return {
    type: '@localization/STATE_REQUEST'
  };
}

export function localizationStateSuccess(data) {
  return {
    type: '@localization/STATE_SUCCESS',
    payload: { data }
  };
}

export function localizationStateFailure() {
  return {
    type: '@localization/STATE_FAILURE'
  };
}

export function localizationCityRequest(stateId) {
  return {
    type: '@localization/CITY_REQUEST',
    payload: { stateId }
  };
}

export function localizationCitySuccess(data) {
  return {
    type: '@localization/CITY_SUCCESS',
    payload: { data }
  };
}

export function localizationCityFailure() {
  return {
    type: '@localization/CITY_FAILURE'
  };
}

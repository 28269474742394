import { FormInstance, Table } from 'antd';
import { Frame, DividerFrame } from '~/components/Globals';
import useDoneStore from '~/zustand/rfidReports/expedition/done-state';

interface Props {
  form: FormInstance<any>;
}
export default function TableExpeditionDones(props: Props) {
  const { dones, donePagination, handleDonePagination } = useDoneStore();
  const { form } = props;

  return (
    <Frame ghost accordion defaultActiveKey="1">
      <Frame.Panel
        header={
          <DividerFrame orientation="left">Expedições Realizadas</DividerFrame>
        }
        key="1"
      >
        <Table
          dataSource={dones}
          pagination={{
            pageSize: donePagination.pageSize,
            current: donePagination.current,
            showSizeChanger: true,
            total: donePagination.total,
            onChange(page, pageSize) {
              handleDonePagination({
                ...donePagination,
                current: page,
                pageSize
              });
              form.submit();
            }
          }}
          expandable={{
            expandedRowRender: expeditionDones => (
              <Table
                dataSource={expeditionDones.carts}
                expandable={{
                  expandedRowRender: expeditionDonesItems => (
                    <Table
                      dataSource={expeditionDonesItems.acessories}
                      bordered
                    >
                      <Table.Column
                        dataIndex="productCode"
                        title="Código Material"
                      />
                      <Table.Column dataIndex="type" title="Tipo Material" />

                      <Table.Column
                        dataIndex="results"
                        title="Resultado/Esperado"
                      />
                    </Table>
                  )
                }}
                bordered
              >
                <Table.Column
                  dataIndex="numberMC"
                  title="MC Resultado/Esperado"
                />
                <Table.Column dataIndex="codeGFP" title="GFP" />
                <Table.Column dataIndex="sequency" title="Sequência" />
              </Table>
            )
          }}
        >
          <Table.Column dataIndex="expeditionDate" title="Data Expedição" />
          <Table.Column dataIndex="receiptDate" title="Data Recebimento" />
          <Table.Column dataIndex="area" title="Área" />
          <Table.Column dataIndex="productCode" title="Serial do Carrinho" />
          <Table.Column dataIndex="numberGFP" title="Total GFP" />
          <Table.Column dataIndex="numberMC" title="MC" />
        </Table>
      </Frame.Panel>
    </Frame>
  );
}

import { call, fork, put, takeLatest } from 'redux-saga/effects';

import * as API from '~/domain/api';

import {
  actions,
  Types,
  FetchLkpOffers,
  FetchWorkSheetLkpOffers,
  FetchProductPackingSummary
} from './duck';
import * as LkpOfferService from '~/store/modules/_core/lkpOffers/service';
import * as SiteService from '~/store/modules/_core/sites/service';
import * as ProductService from '~/store/modules/_core/product/service';
import * as ProductCategoryService from '~/store/modules/_core/productCategories/service';
import * as ProductQualityService from '~/store/modules/_core/productQualities/service';
import { Action } from '~/helpers/util/reducers';
import { FetchGfps, FetchHistory } from '../../_core/lkpOffers/duck';
import { ProductsPackingSummary } from '~/domain/model';

export function* watchFetchLkpOffersSaga(action: Action<FetchLkpOffers>) {
  yield fork(fetchLkpOffersSaga, action.payload);
}

export function* watchFetchFilters(action: Action<number>) {
  yield fork(fetchFiltersSaga, action.payload);
}

export function* watchFetchWorkSheet(action: Action<FetchWorkSheetLkpOffers>) {
  yield fork(fetchWorkSheetSaga, action.payload);
}

export function* watchFetchHistory(action: Action<FetchHistory>) {
  yield fork(fetchHistory, action.payload);
}

export function* watchFetchProductPackingSummary(
  action: Action<FetchProductPackingSummary>
) {
  yield fork(fetchProductPackingSummary, action.payload);
}

export function* watchFetchGfps(action: Action<FetchGfps>) {
  yield fork(fetchGfps, action.payload);
}

export function* fetchInitSaga(): any {
  try {
    yield call(SiteService.findMany);
    yield call(ProductQualityService.findMany);
    yield put(actions.fetchSuccessInit());
  } catch (err) {
    yield put(actions.fetchFailure());
  }
}

export function* fetchFiltersSaga(siteId: number): any {
  try {
    yield call(ProductCategoryService.findManyById, siteId);
    yield call(ProductService.findMany, siteId);
    yield call(ProductService.findGroups, siteId);
    yield put(actions.fetchFiltersSuccess());
  } catch (err) {
    yield put(actions.fetchFiltersFailure());
  }
}

export function* fetchLkpOffersSaga({
  siteId,
  startDate,
  endDateStock,
  productId,
  categoryId,
  expeditionId,
  groupId,
  productQualityId,
  pageNumber,
  productRecipient,
  pageSize,
  lkpOfferId,
  status,
  ColorId,
  keyword
}: FetchLkpOffers): any {
  try {
    const response: API.ServerResponse = yield call(LkpOfferService.fetchMany, {
      siteId,
      startDate,
      endDateStock,
      productId,
      categoryId,
      expeditionId,
      groupId,
      productQualityId,
      productRecipient,
      pageNumber,
      pageSize,
      lkpOfferId,
      status,
      ColorId,
      keyword
    });
    const data: API.DefaultResponse = response.data;

    const totalCount = data.totalCount ?? 0;

    yield put(
      actions.fetchLkpOffersSuccess({
        pageNumber,
        pageSize,
        totalCount
      })
    );
  } catch (err) {
    yield put(actions.fetchLkpOffersFailure());
  }
}

export function* fetchWorkSheetSaga(params: FetchWorkSheetLkpOffers): any {
  try {
    yield call(LkpOfferService.fetchWorkSheet, params);
    yield put(actions.fetchWorkSheetSuccess());
  } catch (err) {
    yield put(actions.fetchWorkSheetFailure());
  }
}

export function* fetchHistory(params: FetchHistory): any {
  try {
    const lkpOfferHistory: API.LkpOfferHistory[] = yield call(
      LkpOfferService.fetchHistory,
      params
    );
    yield put(
      actions.fetchHistorySuccess({
        lkpOfferHistory
      })
    );
  } catch (err) {
    yield put(actions.fetchHistoryFailure());
  }
}

export function* fetchProductPackingSummary(
  params: FetchProductPackingSummary
): any {
  try {
    const productPackingSummaryResponse: API.ProductsPackingSummary[] =
      yield call(LkpOfferService.fetchProductPackingSummary, params);
    const productPackingSummary = productPackingSummaryResponse.map(
      packing => new ProductsPackingSummary(packing)
    );
    yield put(actions.fetchProductPackingSummarySuccess(productPackingSummary));
  } catch (err) {
    yield put(actions.fetchHistoryFailure());
  }
}

export function* fetchGfps(params: FetchGfps): any {
  try {
    const responseResult: API.ResponseLkpOfferGfps = yield call(
      LkpOfferService.fetchGfps,
      params
    );
    yield put(
      actions.fetchGfpsSuccess({
        lkpOfferGfps: responseResult.results,
        totalCount: responseResult.totalCount,
        pageNumber: params.pageNumber ?? 1,
        pageSize: params.pageSize ?? 10
      })
    );
  } catch (err) {
    yield put(actions.fetchGfpsFailure());
  }
}

export default [
  takeLatest(Types.fetchInit, fetchInitSaga),
  takeLatest(Types.fetchFilters, watchFetchFilters),
  takeLatest(Types.fetchWorkSheet, watchFetchWorkSheet),
  takeLatest(Types.fetchLkpOffers, watchFetchLkpOffersSaga),
  takeLatest(Types.fetchHistory, watchFetchHistory),
  takeLatest(Types.fetchProductPackingSummary, watchFetchProductPackingSummary),
  takeLatest(Types.fetchGfps, watchFetchGfps)
];

import { AxiosResponse } from 'axios';
import { endpoints } from '~/constants/endpoints';
import { APIError } from '~/domain/api/errors';
import {
  CreateExpeditionParams,
  ExpeditionHistory,
  FindExpeditionsParams
} from '~/domain/model';
import api from '../api';

export type FindMany = {
  siteId: number;
  pageNumber?: number;
  pageSize?: number;
};

export const findMany = async ({
  PageNumber = 1,
  PageSize = 100,
  ...params
}: FindExpeditionsParams) => {
  try {
    let url = endpoints.expedition.expedition;
    const entries = Object.entries({ ...params });

    url += `?PageSize=${PageSize}&PageNumber=${PageNumber}`;
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of entries) {
      url += `&Data.${key}=${value}`;
    }

    const response: AxiosResponse = await api.get(url);

    return response.data;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const findExpeditionsBySiteId = async (siteId: number) => {
  const response = await api.get(`/expedition/${siteId}`);
  return response.data;
};

export const createExpedition = async (
  createExpeditionParams: CreateExpeditionParams
) => {
  const response = await api.put(
    endpoints.expedition.expedition,
    createExpeditionParams
  );
  return response.data;
};

export const findHistory = async (expeditionId: number) => {
  const response = await api.get<void, AxiosResponse<ExpeditionHistory[]>>(
    `${endpoints.expedition.history}${expeditionId}`
  );
  return response.data;
};

export const deleteExpedition = async (expeditionId: number) => {
  const response = await api.put<void, AxiosResponse<boolean>>(
    `${endpoints.expedition.delete}${expeditionId}`
  );
  return !!response.data;
};

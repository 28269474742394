import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Types, VolOffersCreateAction, VolOffersUpdateAction } from './duck';
import * as VolOfferService from '../../_core/volOffers/service';
import { alertRequest } from '../../alert/actions';
import { actions as actionsLoading } from '../loading';
import { actions as actionsList } from '../volOffersList';
import { getWaitEditOpen } from '../volOffersList/selectors';
import { generateErrorMessage } from '~/helpers/util/error';

export function* create(action: PayloadAction<VolOffersCreateAction[]>): any {
  try {
    yield put(
      actionsLoading.setLoading({
        status: 'fetching'
      })
    );
    yield call(VolOfferService.create, action.payload);
    yield put(
      actionsLoading.setLoading({
        status: 'idle'
      })
    );
    yield put(alertRequest('Ofertas salvas com sucesso!', 'success'));
    const waitStatus = yield select(getWaitEditOpen);
    if (waitStatus === 'waiting') {
      yield put(actionsList.setWaitEditOpen('openEdit'));
    }
    if (waitStatus === 'waitingCloseTab') {
      yield put(actionsList.setWaitEditOpen('closeTab'));
    }
    yield put(actionsLoading.setCloseModal(true));
    yield put(actionsLoading.setSubmitForm(true));
  } catch (err: any) {
    yield put(
      actionsLoading.setLoading({
        status: 'idle'
      })
    );
    const errorMessage = generateErrorMessage(
      err,
      'Erro ao atualizar ofertas.'
    );
    yield put(alertRequest(errorMessage, 'error'));
  }
}
export function* update(action: PayloadAction<VolOffersUpdateAction>) {
  try {
    yield put(
      actionsLoading.setLoading({
        status: 'fetching'
      })
    );
    yield call(VolOfferService.update, action.payload);
    yield put(
      actionsLoading.setLoading({
        status: 'idle'
      })
    );
    yield put(actionsLoading.setCloseModal(true));
    yield put(actionsLoading.setSubmitForm(true));
    yield put(alertRequest('Ofertas atualizadas com sucesso!', 'success'));
  } catch (err: any) {
    const errorMessage = generateErrorMessage(
      err,
      'Erro ao atualizar ofertas.'
    );
    yield put(alertRequest(errorMessage, 'error'));
    yield put(
      actionsLoading.setLoading({
        status: 'idle'
      })
    );
  }
}
export function* deleteSubOffer(action: PayloadAction<number>) {
  try {
    yield put(
      actionsLoading.setLoading({
        status: 'fetching'
      })
    );
    yield call(VolOfferService.deleteSubOffer, action.payload);
    yield put(
      actionsLoading.setLoading({
        status: 'idle'
      })
    );
    yield put(actionsLoading.setSubmitForm(true));
    yield put(alertRequest('Sub-oferta removida com sucesso!', 'success'));
  } catch (err: any) {
    yield put(alertRequest(err.message, 'error'));
    yield put(
      actionsLoading.setLoading({
        status: 'idle'
      })
    );
  }
}
export default [
  takeLatest(Types.create, create),
  takeLatest(Types.update, update),
  takeLatest(Types.deleteSubOffer, deleteSubOffer)
];

export function alertRequest(
  message: string | string[],
  type: any = 'success',
  showIcon = true
) {
  return {
    type: '@alert/REQUEST',
    payload: { message, type, showIcon }
  };
}

export function alertClear() {
  return {
    type: '@alert/CLEAR'
  };
}

export function alertClose() {
  return {
    type: '@alert/CLOSE'
  };
}

export function alertTimeout(timeout: number) {
  return {
    type: '@alert/SET_TIMEOUT',
    payload: { timeout }
  };
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TechnicalSpecification } from '~/domain/model/TechnicalSpecification/TechnicalSpecification';
import {
  technicalSpecificationDelete,
  technicalSpecificationFetch,
  technicalSpecificationImport
} from './apiCalls';
import { DefaultPagination } from '~/domain/model';

export interface TechnicalSpecificationsState {
  selectedRecord?: TechnicalSpecification;
  technicalSpecifications: TechnicalSpecification[];
  technicalSpecificationsTotal: number;
  submitFormFilter: boolean;
  pagination: DefaultPagination;
  showEditModal: boolean;
  showImportModal: boolean;
  loading: boolean;
  disableDownload: boolean;
  disableUpload: boolean;
}

const initialState: TechnicalSpecificationsState = {
  technicalSpecifications: [],
  technicalSpecificationsTotal: 0,
  submitFormFilter: false,
  pagination: { PageSize: 20, PageNumber: 1 },
  showEditModal: false,
  showImportModal: false,
  loading: false,
  disableDownload: false,
  disableUpload: false
};

export const prefix = 'technicalSpecifications';

const technicalSpecificationsSlice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: () => initialState,
    setPagination: (state, action: PayloadAction<DefaultPagination>) => {
      state.pagination = action.payload;
      state.submitFormFilter = true;
    },
    setSelectedRecord: (
      state,
      action: PayloadAction<TechnicalSpecification>
    ) => {
      state.selectedRecord = action.payload;
    },
    setShowEditModal: (state, action: PayloadAction<boolean>) => {
      state.showEditModal = action.payload;
    },
    setShowImportModal: (state, action: PayloadAction<boolean>) => {
      state.showImportModal = action.payload;
    },
    setSubmitFormFilter: (state, action: PayloadAction<boolean>) => {
      state.submitFormFilter = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(technicalSpecificationFetch.pending, state => {
      state.technicalSpecifications = initialState.technicalSpecifications;
      state.loading = true;
    });
    builder.addCase(technicalSpecificationFetch.fulfilled, (state, action) => {
      state.technicalSpecifications = action.payload.results;
      state.technicalSpecificationsTotal = action.payload.totalCount;
      state.loading = false;
    });
    builder.addCase(technicalSpecificationFetch.rejected, state => {
      state.loading = false;
    });
    builder.addCase(technicalSpecificationImport.pending, state => {
      state.loading = true;
    });
    builder.addCase(technicalSpecificationImport.fulfilled, state => {
      state.submitFormFilter = true;
      state.showEditModal = false;
      state.showImportModal = false;
      state.loading = false;
    });
    builder.addCase(technicalSpecificationDelete.pending, state => {
      state.loading = true;
    });
    builder.addCase(technicalSpecificationDelete.fulfilled, state => {
      state.submitFormFilter = true;
      state.loading = false;
    });
    builder.addCase(technicalSpecificationImport.rejected, state => {
      state.loading = false;
    });
  }
});

export const actions = technicalSpecificationsSlice.actions;
export const reducer = technicalSpecificationsSlice.reducer;

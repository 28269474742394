import produce from 'immer';

import {
  Packing,
  Extract,
  HistoryPackingDistribution,
  HistoryPackingDistributionComposition,
  PackingDistribution,
  PackingDistributionComposition
} from '~/domain/model';

import {
  IPackingState,
  IActionPacking,
  ActionTypes,
  CreatePacking,
  GetPackingDistributionRequest,
  GetExtractsRequest,
  GetAllPackingDistribution
} from './types';

const INITIAL_STATE: IPackingState = {
  packings: [],
  packingIdSelected: null,
  allPackingDistribution: [],
  packingDistribution: [],
  packingDistributionToSave: null,
  packingDistributionIsChanged: false,
  packingModelToEdit: null,
  extracts: [],
  loading: false,
  loadingSave: false,
  createModal: {
    isVisible: false,
    loading: false
  },
  createModelModal: {
    isVisible: false,
    loading: false
  },
  historyModal: {
    loading: false,
    isVisible: false,
    packingDistributionId: null
  },
  historyModelModal: {
    loading: false,
    isVisible: false,
    packingDistributionCompositionId: null
  },
  filters: {
    siteId: null
  },
  pagination: {
    current: 1,
    pageSize: 10,
    total: 0
  },
  paginationExtract: {
    current: 1,
    pageSize: 10,
    total: 0
  },
  submitForm: false
};

const packaging = (state = INITIAL_STATE, action: IActionPacking) => {
  return produce(state, draft => {
    switch (action.type) {
      case ActionTypes.createPacking: {
        const payloadPacking = action.payload?.packing as CreatePacking;

        const packing = draft.packings.find(
          item => item.id === payloadPacking.packingId
        );

        if (!packing) {
          draft.createModal.isVisible = false;
          draft.createModal.loading = false;

          return draft;
        }

        const packingDistribution = {
          id: 0,
          siteId: payloadPacking.siteId,
          packingId: payloadPacking.packingId,
          packingCode: packing.code,
          packingDescription: packing.description,

          quantity: payloadPacking.quantity,
          packingDistributionComposition: []
        };

        draft.packingDistributionToSave = packingDistribution;
        draft.packingDistributionIsChanged = true;

        return draft;
      }

      case ActionTypes.registerPackingModel: {
        const payloadPackingModel = action.payload
          ?.packingModel as PackingDistributionComposition;

        if (draft.packingIdSelected && !payloadPackingModel.isEdit) {
          const packing = draft.packings.find(
            item => item.id === draft.packingIdSelected
          );

          if (packing) {
            draft.packingDistribution = draft.packingDistribution.map(item => {
              if (item.packingCode === packing.code) {
                const packingModel = {
                  id: Number(payloadPackingModel.id),
                  layersByTrolley: Number(payloadPackingModel.layersByTrolley),
                  modelDescription: payloadPackingModel.modelDescription,
                  packagingByLayer: Number(
                    payloadPackingModel.packagingByLayer
                  ),
                  unitsPerPackage: Number(payloadPackingModel.unitsPerPackage)
                };

                const { packingDistributionComposition, ...rest } = item;

                const newPackingDistributionComposition =
                  packingDistributionComposition || [];

                newPackingDistributionComposition.push(packingModel);

                const newPackingDistribution = {
                  ...rest,
                  packingDistributionComposition:
                    newPackingDistributionComposition
                };

                draft.packingDistributionToSave = newPackingDistribution;

                return newPackingDistribution;
              }

              return item;
            });
          }
        } else {
          const newPackingDistribution = draft.packingDistribution.map(item => {
            const findPackingDistributionComposition =
              item.packingDistributionComposition.find(
                itemPdc => itemPdc.id === payloadPackingModel.id
              );

            if (findPackingDistributionComposition) {
              const { packingDistributionComposition, ...rest } = item;
              const newPackingDistributionComposition =
                packingDistributionComposition.map(itemPdc => {
                  if (itemPdc.id === payloadPackingModel.id) {
                    return payloadPackingModel;
                  }

                  return itemPdc;
                });

              const newStatePackingDistribution = {
                ...rest,
                packingDistributionComposition:
                  newPackingDistributionComposition
              };

              draft.packingDistributionToSave = newStatePackingDistribution;

              return newStatePackingDistribution;
            }

            return item;
          });

          draft.packingDistribution = newPackingDistribution;
        }

        draft.packingDistributionIsChanged = true;
        draft.createModelModal.isVisible = false;
        draft.createModelModal.loading = false;
        draft.packingIdSelected = null;

        return draft;
      }

      case ActionTypes.setDataRegisterPackingModel: {
        const payloadPackingModel = action.payload?.packingModel;

        draft.packingModelToEdit = payloadPackingModel;

        return draft;
      }

      case ActionTypes.deletePackingModel: {
        const packingModelId = action.payload?.packingModelId;

        const newPackingDistribution = draft.packingDistribution.map(item => {
          const findPackingDistributionComposition =
            item.packingDistributionComposition.find(
              itemPdc => itemPdc.id === packingModelId
            );

          if (findPackingDistributionComposition) {
            const { packingDistributionComposition, ...rest } = item;

            const newPackingDistributionComposition =
              packingDistributionComposition.filter(
                newItem => newItem.id !== packingModelId
              );

            const newStatePackingDistribution = {
              ...rest,
              packingDistributionComposition: newPackingDistributionComposition
            };

            draft.packingDistributionToSave = newStatePackingDistribution;

            return newStatePackingDistribution;
          }

          return item;
        });

        draft.packingDistribution = newPackingDistribution;
        draft.packingDistributionIsChanged = true;

        return draft;
      }

      case ActionTypes.savePackingDataRequest: {
        draft.loadingSave = true;
        return draft;
      }

      case ActionTypes.savePackingDataFailure: {
        draft.loadingSave = false;
        draft.createModal.loading = false;
        return draft;
      }

      case ActionTypes.getByIdPackingDistributionSuccess: {
        const packingDistribution = action.payload
          ?.packingDistribution as PackingDistribution;

        draft.packingDistribution = draft.packingDistribution.map(item => {
          if (item.packingCode === packingDistribution.packingCode) {
            return {
              ...item,
              ...packingDistribution
            };
          }

          return item;
        });

        draft.packingDistributionIsChanged = false;
        draft.packingDistributionToSave = null;
        draft.loadingSave = false;

        return draft;
      }

      case ActionTypes.savePackingDataSuccess: {
        draft.createModal.isVisible = false;
        draft.createModal.loading = false;
        draft.packingDistribution = [];
        draft.submitForm = true;
        return draft;
      }

      case ActionTypes.listPackingsRequest: {
        draft.loading = true;

        return draft;
      }

      case ActionTypes.listPackingsSuccess: {
        const packings = action.payload?.packings as Packing[];
        draft.packings = packings;
        draft.loading = false;

        return draft;
      }

      case ActionTypes.listPackingsFailure: {
        draft.loading = false;

        return draft;
      }

      case ActionTypes.getPackingDistributionRequest: {
        const { siteId, pageNumber } = action.payload
          ?.filters as GetPackingDistributionRequest;
        draft.loading = true;
        draft.pagination.current = pageNumber ?? 1;
        draft.filters.siteId = siteId;
        draft.packingDistribution = [];

        return draft;
      }

      case ActionTypes.getPackingDistributionSuccess: {
        const packingDistribution = action.payload
          ?.packingDistribution as PackingDistribution[];
        const totalPackingDistribution = action.payload
          ?.totalPackingDistribution as number;

        draft.packingDistribution = packingDistribution;
        draft.pagination.total = totalPackingDistribution;

        draft.loading = false;

        return draft;
      }

      case ActionTypes.getPackingDistributionFailure: {
        draft.loading = false;

        return draft;
      }

      case ActionTypes.getAllPackingDistribution: {
        const { siteId } = action.payload?.filters as GetAllPackingDistribution;
        draft.loading = true;
        draft.filters.siteId = siteId;
        draft.allPackingDistribution = [];

        return draft;
      }

      case ActionTypes.clearGetAllPackingDistribution: {
        draft.allPackingDistribution = [];
        return draft;
      }

      case ActionTypes.getAllPackingDistributionSuccess: {
        const allPackingDistribution = action.payload
          ?.allPackingDistribution as Packing[];

        draft.allPackingDistribution = allPackingDistribution;

        draft.loading = false;

        return draft;
      }

      case ActionTypes.getAllPackingDistributionFailure: {
        draft.loading = false;

        return draft;
      }

      case ActionTypes.getExtractsRequest: {
        const { siteId } = action.payload?.filters as GetExtractsRequest;
        draft.loading = true;
        draft.filters.siteId = siteId;

        return draft;
      }

      case ActionTypes.getExtractsSuccess: {
        const extracts = action.payload?.extracts as Extract[];
        const totalExtracts = action.payload?.totalExtracts as number;

        draft.extracts = extracts;
        draft.paginationExtract.total = totalExtracts;

        draft.loading = false;

        return draft;
      }

      case ActionTypes.getExtractsFailure: {
        draft.loading = false;

        return draft;
      }

      case ActionTypes.getHistoryPackingDistributionRequest: {
        draft.historyModal.loading = true;

        const findPackingDistribution = draft.packingDistribution.find(
          item => item.id === draft.historyModal.packingDistributionId
        );

        if (findPackingDistribution) {
          draft.historyModal.historyPackingDistribution = {
            id: findPackingDistribution.id,
            packingCode: findPackingDistribution.packingCode,
            packingDescription: findPackingDistribution.packingDescription,
            packingQuantity: findPackingDistribution.quantity,
            histories: []
          };
        }

        return draft;
      }

      case ActionTypes.getHistoryPackingDistributionSuccess: {
        const historyPackingDistribution = action.payload
          ?.historyPackingDistribution as HistoryPackingDistribution[];

        draft.historyModal.historyPackingDistribution = {
          ...draft.historyModal.historyPackingDistribution,
          histories: historyPackingDistribution
        };

        draft.historyModal.loading = false;

        return draft;
      }

      case ActionTypes.getHistoryPackingDistributionFailure: {
        draft.historyModal.loading = false;

        return draft;
      }

      case ActionTypes.getHistoryPackingDistributionCompositionRequest: {
        draft.historyModelModal.loading = true;

        const findPackingDistribution = draft.packingDistribution.find(item => {
          const findPackingDistributionComposition =
            item.packingDistributionComposition.find(
              itemPdc =>
                itemPdc.id ===
                draft.historyModelModal.packingDistributionCompositionId
            );

          if (findPackingDistributionComposition) {
            return item;
          }
        });

        if (findPackingDistribution) {
          draft.historyModelModal.historyPackingDistributionComposition = {
            id: findPackingDistribution.id,
            packingCode: findPackingDistribution.packingCode,
            packingDescription: findPackingDistribution.packingDescription,
            packingQuantity: findPackingDistribution.quantity,
            histories: []
          };
        }

        return draft;
      }

      case ActionTypes.getHistoryPackingDistributionCompositionSuccess: {
        const historyPackingDistributionComposition = action.payload
          ?.historyPackingDistributionComposition as HistoryPackingDistributionComposition[];

        draft.historyModelModal.historyPackingDistributionComposition = {
          ...draft.historyModelModal.historyPackingDistributionComposition,
          histories: historyPackingDistributionComposition
        };

        draft.historyModelModal.loading = false;

        return draft;
      }

      case ActionTypes.getHistoryPackingDistributionCompositionFailure: {
        draft.historyModelModal.loading = false;

        return draft;
      }

      case ActionTypes.toggleCreateModal: {
        draft.createModal.isVisible = action.payload?.state as boolean;

        return draft;
      }

      case ActionTypes.loadingCreateModal: {
        draft.createModal.loading = action.payload?.state as boolean;

        return draft;
      }

      case ActionTypes.toggleRegisterModelModal: {
        const packingId = action.payload?.packingId as number;
        const isVisible = action.payload?.state as boolean;

        if (packingId && isVisible) {
          draft.packingIdSelected = packingId;
        }

        if (!isVisible) {
          draft.packingIdSelected = null;
        }

        draft.createModelModal.isVisible = isVisible;

        return draft;
      }

      case ActionTypes.loadingRegisterModelModal: {
        draft.createModelModal.loading = action.payload?.state as boolean;

        return draft;
      }

      case ActionTypes.toggleHistoryModal: {
        const packingDistributionId = action.payload
          ?.packingDistributionId as number;
        const isVisible = action.payload?.state as boolean;

        if (packingDistributionId) {
          draft.historyModal.packingDistributionId = packingDistributionId;
          delete draft.historyModal.historyPackingDistribution;
        }

        draft.historyModal.isVisible = isVisible;

        if (!isVisible) {
          draft.historyModal.packingDistributionId = null;
        }

        return draft;
      }

      case ActionTypes.toggleHistoryModelModal: {
        const packingDistributionCompositionId = action.payload
          ?.packingDistributionCompositionId as number;
        const isVisible = action.payload?.state as boolean;

        if (packingDistributionCompositionId) {
          draft.historyModelModal.packingDistributionCompositionId =
            packingDistributionCompositionId;
          delete draft.historyModelModal.historyPackingDistributionComposition;
        }

        draft.historyModelModal.isVisible = isVisible;

        if (!isVisible) {
          draft.historyModelModal.packingDistributionCompositionId = null;
        }

        return draft;
      }

      case ActionTypes.setPagePagination: {
        const page = action.payload?.page as number;
        draft.pagination.current = page;

        return draft;
      }

      case ActionTypes.setPageSizePagination: {
        const pageSize = action.payload?.pageSize as number;
        draft.pagination.pageSize = pageSize;

        return draft;
      }

      case ActionTypes.resetPackingDistribution: {
        draft.packingDistribution = [];
        draft.filters.siteId = null;
        draft.packingDistributionIsChanged = false;
        draft.packingDistributionToSave = null;

        return draft;
      }

      case ActionTypes.resetPackingState: {
        draft.loading = false;
        draft.packingDistribution = [];
        draft.packings = [];
        draft.extracts = [];
        draft.filters = {
          siteId: null
        };
        draft.packingDistributionIsChanged = false;

        return draft;
      }
      case ActionTypes.resetPackingExtractState: {
        draft.extracts = [];

        return draft;
      }

      case ActionTypes.setSubmitForm: {
        draft.submitForm = action.payload?.submitForm as boolean;
        return draft;
      }
      case ActionTypes.removeModel: {
        draft.loading = true;
        return draft;
      }
      case ActionTypes.removeModelSuccess: {
        draft.loading = false;
        return draft;
      }
      case ActionTypes.removeModelFailure: {
        draft.loading = false;
        return draft;
      }
      default: {
        return draft;
      }
    }
  });
};

export default packaging;

import { createAsyncThunk } from '@reduxjs/toolkit';
import * as M from '~/domain/model';
import { alertRequest } from '../../alert/actions';
import * as plpService from '~/services/producer/Plp';
import { generateErrorMessage } from '~/helpers/util/error';

export const fetch = createAsyncThunk(
  `plp/fetch`,
  async (params: M.PlpFetchParams, { dispatch, rejectWithValue }) => {
    console.log(`plp/fetch`);
    try {
      return await plpService.fetchRequest(params);
    } catch (err: any) {
      const errorMessage = generateErrorMessage(err, 'Erro ao buscar PLPs.');
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const createRegisters = createAsyncThunk(
  `plp/createRegisters`,
  async (params: M.PlpProductRegister[], { dispatch, rejectWithValue }) => {
    try {
      const response = await plpService.createRegistersRequest(params);
      dispatch(alertRequest('PLP criado com sucesso!', 'success'));
      return response;
    } catch (err: unknown) {
      const errorMessage = generateErrorMessage(err, 'Erro ao criar PLP.');
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const update = createAsyncThunk(
  `plp/update`,
  async (params: M.Plp[], { dispatch, rejectWithValue }) => {
    try {
      const response = await plpService.updateRequest(params);
      dispatch(alertRequest('PLP atualizado com sucesso!', 'success'));
      return response;
    } catch (err: unknown) {
      const errorMessage = generateErrorMessage(err, 'Erro ao atualizar PLP.');
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const updateStatus = createAsyncThunk(
  `plp/updateStatus`,
  async (params: M.Plp[], { dispatch, rejectWithValue }) => {
    try {
      const response = await plpService.updateRequest(params);
      dispatch(
        alertRequest(
          `${
            params[0].isActive
              ? 'PLP ativado com sucesso'
              : 'PLP inativado com sucesso'
          }`,
          'success'
        )
      );
      return response;
    } catch (err: unknown) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao atualizar status do PLP.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchRegisters = createAsyncThunk(
  `plp/fetchRegisters`,
  async (params: M.PlpFetchRegistersParams, { dispatch, rejectWithValue }) => {
    try {
      return await plpService.fetchRegistersRequest(params);
    } catch (err: unknown) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao buscar registros.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const updateMass = createAsyncThunk(
  `plp/updateMass`,
  async (params: M.PlpMassUpdateParams, { dispatch, rejectWithValue }) => {
    try {
      const response = await plpService.updateMassRequest(params);
      dispatch(alertRequest('Plps atualizados com sucesso!', 'success'));
      return response;
    } catch (err: unknown) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao buscar registros.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchHistoric = createAsyncThunk(
  `plp/fetchHistoric`,
  async (params: M.PlpHistoricFetchParams, { dispatch, rejectWithValue }) => {
    try {
      const response = await plpService.fetchHistoricRequest(params);
      return response;
    } catch (err: unknown) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao buscar histórico.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const exportPlps = createAsyncThunk(
  'plp/exportPlps',
  async (params: M.PlpExportParams, { dispatch, rejectWithValue }) => {
    try {
      const response = await plpService.exportPlps(params);
      console.log(`response:: `, response);
      const data: string = response?.data?.data;
      console.log(`data:: `, data);
      const binaryString = window.atob(data);
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      const fileData = bytes;

      const blob = new Blob([fileData], { type: 'application/octet-stream' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.style.display = 'none';
      const fileName = 'PLPs.xlsx';
      link.download = fileName;
      link.click();
      return true;
    } catch (err) {
      const errorMessage = generateErrorMessage(err, 'Erro ao exportar PLPs.');
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

import { PriceAlertState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.core.priceAlerts as PriceAlertState;
};

export const getPriceAlertById = createSelector([getRoot], state => {
  return state.priceAlertByID;
});

export const getPriceAlertHistoryById = createSelector([getRoot], state => {
  return state.priceAlertHistoryById;
});

export const getIsCleared = createSelector(
  [getRoot],
  state => state.status === 'cleared'
);

import produce from 'immer';
import { Customer, CustomerGroup, CustomerSegment } from '~/domain/model';
import { createCreator, createReducer, Reducer } from '~/helpers/util/reducers';

export type Status = 'cleared' | 'updated';

export type CustomerState = {
  customers: Map<string, Customer>;
  customersByParams: Map<string, Customer>;
  customersByNameAndSegment: Map<string, Customer>;
  customersByGroupId: Map<string, Customer>;
  customersSegment: Map<string, CustomerSegment>;
  customerGroups: Map<string, CustomerGroup>;
  status: Status;
};

export type Update = {
  customers?: Map<string, Customer>;
  customersByParams?: Map<string, Customer>;
  customersByNameAndSegment?: Map<string, Customer>;
  customersByGroupId?: Map<string, Customer>;
  customersSegment?: Map<string, CustomerSegment>;
  customerGroups?: Map<string, CustomerGroup>;
};

const prefix = '@customer';

export const Types = Object.freeze({
  CLEAR: `${prefix}/CLEAR`,
  CLEAR_CUSTOMERS_BY_NAME_AND_SEGMENT: `${prefix}/CLEAR_CUSTOMERS_BY_NAME_AND_SEGMENT`,
  UPDATE: `${prefix}/UPDATE`
});

export const initialState: CustomerState = {
  customers: new Map(),
  customersByParams: new Map(),
  customersByNameAndSegment: new Map(),
  customersByGroupId: new Map(),
  customersSegment: new Map(),
  customerGroups: new Map(),
  status: 'cleared'
};

const clearReducer: Reducer<CustomerState, void> = state => {
  return produce(state, draft => {
    draft.customers = initialState.customers;
    draft.customersByParams = initialState.customersByParams;
    draft.customersByNameAndSegment = initialState.customersByNameAndSegment;
    draft.customersByGroupId = initialState.customersByGroupId;
    draft.customerGroups = initialState.customerGroups;
    draft.status = initialState.status;
  });
};

const clearCustomersByNameAndSegmentReducer: Reducer<
  CustomerState,
  void
> = state => {
  return produce(state, draft => {
    draft.customersByNameAndSegment = initialState.customersByNameAndSegment;
  });
};

const updateReducer: Reducer<CustomerState, Update> = (state, action) => {
  return produce(state, draft => {
    if (action.customersByParams) {
      draft.customersByParams = action.customersByParams as Map<
        string,
        Customer
      >;
    }
    if (action.customersByNameAndSegment) {
      draft.customersByNameAndSegment = action.customersByNameAndSegment as Map<
        string,
        Customer
      >;
    }
    if (action.customersByGroupId) {
      draft.customersByGroupId = action.customersByGroupId as Map<
        string,
        Customer
      >;
    }
    if (action.customersSegment) {
      draft.customersSegment = action.customersSegment as Map<
        string,
        CustomerSegment
      >;
    }
    if (action.customers) {
      draft.customers = action.customers as Map<string, Customer>;
    }
    if (action.customerGroups) {
      draft.customerGroups = action.customerGroups as Map<
        string,
        CustomerGroup
      >;
    }
  });
};

export const actions = Object.freeze({
  clear: createCreator<void>(Types.CLEAR),
  clearCustomersByNameAndSegment: createCreator<void>(
    Types.CLEAR_CUSTOMERS_BY_NAME_AND_SEGMENT
  ),
  update: createCreator<Update>(Types.UPDATE)
});

export const reducer = createReducer(initialState, {
  [Types.CLEAR]: clearReducer,
  [Types.CLEAR_CUSTOMERS_BY_NAME_AND_SEGMENT]:
    clearCustomersByNameAndSegmentReducer,
  [Types.UPDATE]: updateReducer
});

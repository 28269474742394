import { call, put } from 'redux-saga/effects';

import * as API from '~/domain/api';

import { BannerApproval } from '~/domain/model';
import { actions, FetchBannerApproval, ChangeStatus } from './duck';
import * as BannerApprovalService from '~/services/producer/BannerApproval';

export function* fetchBannerApproval(params: FetchBannerApproval) {
  yield put(actions.update({ bannerApprovalList: [] }));
  const {
    description,
    url,
    status,
    bannerTypeId,
    uploadDate,
    pageNumber,
    pageSize
  } = params;
  const response: API.ServerResponse = yield call(
    BannerApprovalService.fetchBannerApproval,
    description,
    url,
    status,
    bannerTypeId,
    uploadDate,
    pageNumber,
    pageSize
  );
  const data: API.DefaultResponse = response.data;
  const bannerApprovalResult: API.BannerApproval[] = data.results ?? [];
  let bannerApprovalList: BannerApproval[] = [];
  if (bannerApprovalResult) {
    bannerApprovalList = bannerApprovalResult.map(
      (bannerApproval: any) => new BannerApproval(bannerApproval)
    );
  }
  yield put(actions.update({ bannerApprovalList }));
  return response;
}

export function* approveOrDisapproveBanner({
  id,
  status,
  message
}: ChangeStatus) {
  yield call(
    BannerApprovalService.approveOrDisapproveBanner,
    id,
    status,
    message
  );
}

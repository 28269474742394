import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { actions, Create, Types } from './duck';
import { Action } from '~/helpers/util/reducers';
import { APIError } from '~/domain/api/errors';
import * as AnnouncementService from '~/store/modules/_core/announcement/service';
import { actions as actionsLoading } from '../loading';

export function* watchCreateSaga(action: Action<Create>) {
  yield fork(createSaga, action.payload);
}

export function* watchGetByIdSaga(action: Action<number>) {
  yield fork(getByIdSaga, action.payload);
}

export function* watchGetFilesByIdSaga(action: Action<number>) {
  yield fork(getFilesByIdSaga, action.payload);
}

export function* createSaga(param: Create) {
  try {
    yield put(
      actionsLoading.setLoading({
        status: 'fetching'
      })
    );
    const {
      environment,
      role,
      description,
      required,
      startDate,
      endDate,
      content,
      image,
      documents,
      id
    } = param;
    yield call(
      AnnouncementService.create,
      environment,
      role !== 1 && role !== 2 ? 0 : role,
      description,
      required,
      startDate,
      endDate,
      content,
      documents,
      image,
      id
    );
    yield put(actions.createSuccess());
    yield put(
      actionsLoading.setLoading({
        status: 'idle'
      })
    );
  } catch (error: any) {
    yield put(
      actionsLoading.setLoading({
        status: 'idle'
      })
    );
    if (error instanceof APIError) {
      yield put(actions.createFailure({ errorMessage: error.message }));
    } else {
      yield put(
        actions.createFailure({
          errorMessage: error?.response?.data.errors.messages[0]
        })
      );
    }
  }
}

export function* getByIdSaga(id: number) {
  try {
    yield call(AnnouncementService.getById, id);

    yield put(actions.getByIdSuccess());
  } catch (error: any) {
    if (error instanceof APIError) {
      yield put(actions.getByIdFailure({ errorMessage: error.message }));
    } else {
      yield put(
        actions.getByIdFailure({
          errorMessage: error?.response?.data[0]
        })
      );
    }
  }
}

export function* getFilesByIdSaga(id: number) {
  try {
    yield call(AnnouncementService.getFilesById, id);

    yield put(actions.getFilesByIdSuccess());
  } catch (error: any) {
    if (error instanceof APIError) {
      yield put(actions.getFilesByIdFailure({ errorMessage: error.message }));
    } else {
      yield put(
        actions.getFilesByIdFailure({
          errorMessage: error?.response?.data[0]
        })
      );
    }
  }
}

export default [
  takeLatest(Types.CREATE_INIT, watchCreateSaga),
  takeLatest(Types.GET_BY_ID, watchGetByIdSaga),
  takeLatest(Types.GET_FILES_BY_ID, watchGetFilesByIdSaga)
];

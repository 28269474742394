import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';

import service from '~/services/api';
import * as API from '~/domain/api';

import { actions } from './duck';
import { HistoryOfferChannel, OfferChannel } from '~/domain/model';

export function* findMany(params: { nextPage: number; nextPageSize: number }) {
  let URL = '/preferences-offer-channels/sales?';
  const { nextPage, nextPageSize } = params;
  if (nextPage) {
    URL += `PageNumber=${nextPage}`;
  }
  if (nextPageSize) {
    URL += `&PageSize=${nextPageSize}`;
  }
  const response: AxiosResponse = yield call(service.get, URL);
  const apiResponse = response.data as API.DefaultResponse;
  const offerChannels: API.OfferChannel[] = apiResponse.results ?? [];
  const offerChannelByID = new Map(
    offerChannels.map(offerChannel => [
      String(offerChannel.id),
      new OfferChannel(offerChannel)
    ])
  );
  yield put(actions.update({ offerChannelByID }));
  return apiResponse;
}

type UpdateOrCreate = {
  id: number;
  categoryId: number;
  percentLkp: number;
  percentVol: number;
};

export const create = async (data: UpdateOrCreate) => {
  await service.post('/preferences-offer-channels/sales', data);
};

export const update = async (data: UpdateOrCreate) => {
  await service.put('/preferences-offer-channels/sales', data);
};

export function* history(params: {
  id: number;
  nextPage: number;
  nextPageSize: number;
}) {
  const { id, nextPage, nextPageSize } = params;

  let URL = `/preferences-offer-channels/sales/history/${id}?`;

  if (nextPage) {
    URL += `PageNumber=${nextPage}`;
  }
  if (nextPageSize) {
    URL += `&PageSize=${nextPageSize}`;
  }

  const response: AxiosResponse = yield call(service.get, URL);
  const apiResponse = response.data as API.DefaultResponse;
  const historyOfferChannels: API.HistoryOfferChannel[] =
    apiResponse.results ?? [];
  const offerChannelHistoryById = new Map(
    historyOfferChannels.map(historyOfferChannel => [
      String(historyOfferChannel.id),
      new HistoryOfferChannel(historyOfferChannel)
    ])
  );
  yield put(actions.update({ offerChannelHistoryById }));
  return apiResponse;
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Announcement, File, AnnouncementVisualization } from '~/domain/model';

export type AnnouncementState = {
  announcementList: Announcement[];
  announcementBanner: AnnouncementVisualization[];
  announcementForEdit: Announcement | undefined;
  files: File[];
};

export type Update = {
  announcementList?: Announcement[];
  announcementBanner?: AnnouncementVisualization[];
  announcementForEdit?: Announcement;
  files?: File[];
};

export type ParamRequestAnnouncementList = {
  description?: string;
  validity?: string;
  role?: number;
  status?: string;
  startDate?: string;
  endDate?: string;
  pageNumber?: number;
  pageSize?: number;
};

const prefix = '@announcement';

export const initialState: AnnouncementState = {
  announcementList: [],
  announcementBanner: [],
  announcementForEdit: undefined,
  files: []
};

const announcement = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: state => {
      state.announcementList = initialState.announcementList;
    },
    clearAnnouncementCreate: state => {
      state.announcementForEdit = undefined;
      state.files = [];
    },
    update: (state, action: PayloadAction<Update>) => {
      if (action.payload.announcementList) {
        state.announcementList = action.payload.announcementList;
      }
      if (action.payload.announcementBanner) {
        state.announcementBanner = action.payload.announcementBanner;
      }
      if (action.payload.announcementForEdit) {
        state.announcementForEdit = action.payload.announcementForEdit;
      }
      if (action.payload.files) {
        state.files = action.payload.files;
      }
    }
  }
});

export const actions = announcement.actions;
export const reducer = announcement.reducer;

const caseReducers = announcement.caseReducers;
export const Types = Object.freeze({
  clear: `${prefix}/${caseReducers.clear.name}`,
  update: `${prefix}/${caseReducers.update.name}`
});

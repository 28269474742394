import { VolOfferHistoryChanges } from '.';
import * as API from '../api';

export default class VolOfferHistory implements API.History {
  private _volOfferId: number;
  private _currentStock: number;
  private _productCode: string;
  private _productDescription: string;
  private _histories: API.Histories[];

  public get volOfferId(): number {
    return this._volOfferId ?? Number.NaN;
  }

  public set volOfferId(value: number) {
    this._volOfferId = value;
  }

  public get currentStock(): number {
    return this._currentStock ?? Number.NaN;
  }

  public set currentStock(value: number) {
    this._currentStock = value;
  }

  public get productCode(): string {
    return this._productCode ?? '';
  }

  public set productCode(value: string) {
    this._productCode = value;
  }

  public get productDescription(): string {
    return this._productDescription ?? '';
  }

  public set productDescription(value: string) {
    this._productDescription = value;
  }

  public get histories(): API.Histories[] {
    return this._histories ?? ([] as API.Histories[]);
  }

  public set histories(value: API.Histories[]) {
    this._histories = value.map(history => new VolOfferHistoryChanges(history));
  }

  constructor(json: API.History) {
    this._volOfferId = json.volOfferId;
    this._currentStock = json.currentStock;
    this._productCode = json.productCode;
    this._productDescription = json.productDescription;
    this._histories = json.histories.map(
      history => new VolOfferHistoryChanges(history)
    );
  }
}

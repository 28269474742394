import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type FetchStatus = 'idle' | 'pending' | 'success' | 'failure';

export type PackingAdjustQuantityState = {
  status: FetchStatus;
};

export type FetchSavePackingAdjustQuantity = {
  id?: number;
  quantity?: number;
};

const prefix = '@packingAdjustQuantity';

export const initialState: PackingAdjustQuantityState = {
  status: 'idle'
};

const packingAdjustQuantity = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: state => {
      state.status = initialState.status;
    },
    fetchAdjustQuantity: (
      state,
      _action: PayloadAction<FetchSavePackingAdjustQuantity>
    ) => {
      state.status = 'pending';
    },
    adjustQuantitySuccess: state => {
      state.status = 'success';
    },
    adjustQuantityFailure: state => {
      state.status = 'failure';
    }
  }
});

export const actions = packingAdjustQuantity.actions;
export const reducer = packingAdjustQuantity.reducer;

const caseReducers = packingAdjustQuantity.caseReducers;
export const Types = Object.freeze({
  clear: `${prefix}/${caseReducers.clear.name}`,
  fetchAdjustQuantity: `${prefix}/${caseReducers.fetchAdjustQuantity.name}`
});

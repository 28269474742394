import { AxiosResponse } from 'axios';
import api, { apiStatus } from '../api';

import { endpoints } from '~/constants/endpoints';

export const findPreferenceColor = () => {
  return api.get<void, AxiosResponse<any>>(
    `${endpoints.productPreference.color}`
  );
};

export const findProductsReport = (payload: any) => {
  const entries = Object.entries(payload);
  let url = endpoints.salesReportEcommerce.grid;
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of entries) {
    if (key !== 'pagination' && value !== undefined) {
      url += `Data.${key}=${value}&`;
    }
  }
  url += 'PageNumber=1&PageSize=3000';

  return api.get<void, AxiosResponse<any>>(`${url}`);
};

export const findOverview = (payload: any) => {
  const entries = Object.entries(payload);
  let url = endpoints.salesReportEcommerce.overview;
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of entries) {
    if (key !== 'pagination' && value !== undefined) {
      url += `Data.${key}=${value}&`;
    }
  }
  url += 'PageNumber=1&PageSize=3000';

  return apiStatus.get<void, AxiosResponse<any>>(`${url}`);
};

export const onExport = async (params: any) => {
  const entries = Object.entries(params);
  let url = endpoints.salesReportEcommerce.export;
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of entries) {
    if (key !== 'pagination' && value !== undefined) {
      url += `Data.${key}=${value}&`;
    }
  }
  return api.get(url);
};

import { Form } from 'antd';
import InventoryWrapper from '~/components/organisms/RfidReports/InventoryWrapper';

export default function Inventory() {
  const [filterFormDone] = Form.useForm();
  const [filterFormOwned] = Form.useForm();
  const [filterFormConsolidated] = Form.useForm();
  const [filterFormFrequency] = Form.useForm();

  return (
    <InventoryWrapper
      filterFormDone={filterFormDone}
      filterFormOwned={filterFormOwned}
      filterFormConsolidated={filterFormConsolidated}
      filterFormFrequency={filterFormFrequency}
    />
  );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductCategory } from '~/domain/api';
import { productCategoryFetch } from './apiCalls';

export interface ProductCategoriesState {
  productCategories: ProductCategory[];
  loading: boolean;
}

const initialState: ProductCategoriesState = {
  productCategories: [],
  loading: false
};

export const prefix = 'productCategories';

const productCategoriesSlice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(productCategoryFetch.pending, state => {
      state.productCategories = initialState.productCategories;
      state.loading = true;
    });
    builder.addCase(
      productCategoryFetch.fulfilled,
      (state, action: PayloadAction<ProductCategory[]>) => {
        state.productCategories = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(productCategoryFetch.rejected, state => {
      state.loading = false;
    });
  }
});

export const actions = productCategoriesSlice.actions;
export const reducer = productCategoriesSlice.reducer;

import * as API from '~/domain/api';
import { HarvestLimit, AvailablePackingDistributionComposition } from '.';

export default class ProductPreference {
  public readonly json: API.ProductPreferences;
  public readonly id: number;
  public readonly productCode: string;
  public readonly productDescription: string;
  public readonly productLongDescription: string;
  public readonly productId: number;
  public readonly expeditionBySiteId: number;
  public readonly harvestLimit: HarvestLimit;
  public readonly productPreferenceId: number;
  public readonly packingDistributionCompositions: API.PackingDistributionCompositions[];
  public readonly packingDistributionCompositionIds: number[];
  public readonly minimumQuantity: number;
  public readonly multipleQuantity: number;
  public readonly composition: string[];
  public readonly availablePackingDistributionCompositions: AvailablePackingDistributionComposition[];

  private getId(): number {
    return this.json.id ?? Number.NaN;
  }

  private getProductCode(): string {
    return this.json.productCode ?? '';
  }

  private getProductDescription(): string {
    return this.json.productDescription ?? '';
  }

  private getProductLongDescription(): string {
    return this.json.productLongDescription ?? '';
  }

  private getProductId(): number {
    return this.json.productId ?? Number.NaN;
  }

  private getExpeditionBySiteId(): number {
    return this.json.expeditionBySiteId ?? Number.NaN;
  }

  private getHarvestLimit(): HarvestLimit {
    return this.json.harvestLimit
      ? new HarvestLimit(this.json.harvestLimit)
      : ({} as HarvestLimit);
  }

  private getProductPreferenceId(): number {
    return this.json.productPreferenceId ?? Number.NaN;
  }

  private getPackingDistributionCompositions(): API.PackingDistributionCompositions[] {
    return this.json.packingDistributionCompositions &&
      this.json.packingDistributionCompositions.length > 0
      ? this.json.packingDistributionCompositions
      : ([] as API.PackingDistributionCompositions[]);
  }

  private getComposition(): string[] {
    return this.json.composition;
  }

  private getAvailablePackingDistributionCompositions() {
    return this.json.availablePackingDistributionCompositions?.length > 0
      ? this.json.availablePackingDistributionCompositions?.map(
          available => new AvailablePackingDistributionComposition(available)
        )
      : ([] as AvailablePackingDistributionComposition[]);
  }

  private getPackingDistributionCompositionIds(): number[] {
    return this.json.packingDistributionCompositionIds ?? [];
  }

  private getMinimumQuantity(): number {
    return this.json.minimumQuantity ?? Number.NaN;
  }

  private getMultipleQuantity(): number {
    return this.json.multipleQuantity ?? Number.NaN;
  }

  constructor(json: API.ProductPreferences) {
    this.json = json;
    this.id = this.getId();
    this.productCode = this.getProductCode();
    this.productDescription = this.getProductDescription();
    this.productLongDescription = this.getProductLongDescription();
    this.productId = this.getProductId();
    this.expeditionBySiteId = this.getExpeditionBySiteId();
    this.harvestLimit = this.getHarvestLimit();
    this.productPreferenceId = this.getProductPreferenceId();
    this.packingDistributionCompositions =
      this.getPackingDistributionCompositions();
    this.composition = this.getComposition();
    this.availablePackingDistributionCompositions =
      this.getAvailablePackingDistributionCompositions();
    this.packingDistributionCompositionIds =
      this.getPackingDistributionCompositionIds();
    this.minimumQuantity = this.getMinimumQuantity();
    this.multipleQuantity = this.getMultipleQuantity();
  }
}

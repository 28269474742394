/* eslint-disable no-shadow */
import produce from 'immer';

const INITIAL_STATE = {
  products: [],
  groups: [],
  categories: [],
  failure: false,
  success: false
};

export default function product(state = INITIAL_STATE, action: any) {
  return produce(state, draft => {
    switch (action.type) {
      case '@product/SEARCH_PRODUCT_GROUPS_SUCCESS': {
        return produce(state, draft => {
          draft.groups = action.payload;
        });
      }

      case '@product/SEARCH_PRODUCT_CATEGORIES_SUCCESS': {
        return produce(state, draft => {
          draft.categories = action.payload;
        });
      }
      case '@product/SEARCH_PRODUCT_GROUPS_SITE_SUCCESS': {
        draft.groups = action.payload.groups;
        break;
      }
      case '@product/REMOVE_PRODUCT_GROUPS': {
        draft.groups = [];
        break;
      }
      case '@product/REMOVE_CATEGORIES': {
        draft.categories = [];
        break;
      }
      default:
    }
  });
}

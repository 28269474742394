import * as API from '../api';

export default class Packing {
  private readonly json: API.Packing;
  public readonly id: number;
  public readonly code: string;
  public readonly description: string;
  public readonly abbrev: string;
  public readonly type: API.PackingType;
  public readonly packagingless: boolean;
  public readonly showToProducer: boolean;
  public readonly additionalCost: string;

  constructor(json: API.Packing) {
    this.json = json;
    this.id = this.getId();
    this.code = this.getCode();
    this.description = this.getDescription();
    this.abbrev = this.getAbbrev();
    this.type = this.getType();
    this.packagingless = this.getPackagingless();
    this.showToProducer = this.getShowToProducer();
    this.additionalCost = this.getAdditionalCost();
  }

  public toJSON() {
    return this.json;
  }

  private getId() {
    return this.json.id || Number.NaN;
  }

  private getCode() {
    return this.json.code || '';
  }

  private getDescription() {
    return this.json.description || '';
  }

  private getAbbrev() {
    return this.json.abbrev || '';
  }

  private getType() {
    return this.json.type || 'DESCARTAVEL';
  }

  private getPackagingless() {
    return typeof this.json.packagingless !== 'undefined'
      ? this.json.packagingless
      : false;
  }

  private getShowToProducer() {
    return this.json.showToProducer !== undefined
      ? this.json.showToProducer
      : false;
  }

  private getAdditionalCost() {
    return this.json.additionalCost || '';
  }
}

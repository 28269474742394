import { CustomerState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.core.customers as CustomerState;
};

export const getCustomersByParams = createSelector(
  [getRoot],
  state => state.customersByParams
);

export const getCustomersByGroupId = createSelector(
  [getRoot],
  state => state.customersByGroupId
);

export const getCustomersByNameAndSegment = createSelector(
  [getRoot],
  state => state.customersByNameAndSegment
);

export const getCustomersSegment = createSelector(
  [getRoot],
  state => state.customersSegment
);

export const getCustomers = createSelector([getRoot], state => state.customers);

export const getCustomerGroups = createSelector(
  [getRoot],
  state => state.customerGroups
);

export const getIsLoading = createSelector(
  [getRoot],
  state => state.status === 'cleared'
);

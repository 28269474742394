// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Order } from '~/@core/domain/model/orderManagement';
import {
  OrderManagementStatusEnum,
  OrderManagementStatusIdEnum,
  OrderManagementTabEnum
} from '~/domain/enum/orderManagement.enum';
import * as M from '~/domain/model';
import { validateOrder, validateOrderTest } from '~/helpers/autosservice';
import * as apiCalls from './apiCalls';

export interface AutoServiceState {
  activeKey: OrderManagementTabEnum;
  disableTableTopDropdown: boolean;
  disableUpload: boolean;
  fileOrders: any[];
  hasImportResponse: boolean;
  hasValidateResponse: boolean;
  loading: boolean;
  generatingOrders: boolean;
  buyIntentionCurrentStep: number;
  selectedRecord?: Order;
  orders: Order[];
  ordersTotal: number;
  pendingOrders: any[];
  pendingOrdersPagination: M.DefaultPagination;
  pendingOrdersSelectedKeys: number[];
  pendingOrdersTotal: number;
  submitFormFilter: boolean;
  submitProcessOrders: boolean;
  pagination: M.DefaultPagination;
  publishers: M.OrderManagement.Publisher[];
  publishedById?: number;
  showAwnserModal: boolean;
  showDetailsModal: boolean;
  showEditModal: boolean;
  showExportExcelModal: boolean;
  showExportPDFModal: boolean;
  showImportModal: boolean;
  showImportModalItems: boolean;
  showBuyIntentionModal: boolean;
  showJustifyModal: boolean;
  showObservationModal: boolean;
  showOnlyDivergentOrders: boolean;
  showBuySuggestionModal: boolean;
  showCancelOrderModal: boolean;
  showRepublishModal: boolean;
}

const initialState: AutoServiceState = {
  activeKey: OrderManagementTabEnum.order,
  fileOrders: [],
  hasImportResponse: false,
  hasValidateResponse: false,
  loading: false,
  generatingOrders: false,
  buyIntentionCurrentStep: 0,
  orders: [],
  ordersTotal: 0,
  pendingOrders: [],
  pendingOrdersPagination: { PageSize: 10, PageNumber: 1 },
  pendingOrdersSelectedKeys: [],
  pendingOrdersTotal: 0,
  submitFormFilter: false,
  submitProcessOrders: false,
  pagination: { PageSize: 100, PageNumber: 1 },
  publishers: [],
  showAwnserModal: false,
  showDetailsModal: false,
  showEditModal: false,
  showExportExcelModal: false,
  showExportPDFModal: false,
  showImportModal: false,
  showImportModalItems: false,
  showBuyIntentionModal: false,
  showJustifyModal: false,
  showObservationModal: false,
  showOnlyDivergentOrders: false,
  showBuySuggestionModal: false,
  showCancelOrderModal: false,
  disableTableTopDropdown: true,
  disableUpload: false,
  showRepublishModal: false
};

export const prefix = 'autoService';

const autoServiceSlice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: state => ({
      ...initialState,
      publishers: state.publishers,
      publishedById: state.publishedById
    }),
    setActiveKey: (state, action: PayloadAction<OrderManagementTabEnum>) => {
      state.activeKey = action.payload;
    },
    closeImportModal: state => {
      state.fileOrders = [];
      state.hasImportResponse = false;
      state.hasValidateResponse = false;
      state.showImportModal = false;
      state.showImportModalItems = false;
    },
    setDisableTableTopDropdown: state => {
      if (!state.pendingOrdersSelectedKeys.length) {
        state.disableTableTopDropdown = true;
        return;
      }
      const ordersToValidate = state.pendingOrders.filter(i =>
        state.pendingOrdersSelectedKeys.includes(i.id)
      );
      const hasInvalidOrders = ordersToValidate.some(
        i => !validateOrderTest(i)
      );
      state.disableTableTopDropdown =
        hasInvalidOrders || !ordersToValidate.length;
    },
    setFileOrders: (state, action: PayloadAction<any[]>) => {
      state.fileOrders = action.payload;
    },
    newOrderNextStep: state => {
      state.buyIntentionCurrentStep += 1;
    },
    newOrderPreviousStep: state => {
      state.buyIntentionCurrentStep =
        state.buyIntentionCurrentStep === 1
          ? 0
          : state.buyIntentionCurrentStep - 1;
    },
    removeFileOrder: (state, action: PayloadAction<number>) => {
      const filteredFileOrders: any[] = state.fileOrders.filter(
        (item: any) => item.id !== action.payload
      );
      state.fileOrders = filteredFileOrders;
    },
    setAddTablePropertyValue: (state, action: PayloadAction<any>) => {
      const updatedOrders: any[] = state.fileOrders?.map((item: any) => {
        if (item.id === action.payload.id) {
          return {
            ...action.payload,
            changed: true
          };
        }
        return item;
      });
      state.fileOrders = updatedOrders;
    },

    setPendingOrdersReviewTablePropertyValue: (
      state,
      action: PayloadAction<{
        value: any;
        property: string;
        record: any;
      }>
    ) => {
      const { payload } = action;
      const updatedOrders: any[] = state.pendingOrders?.map((item: any) => {
        if (item.id === payload.record.id) {
          const updatedItem = item;
          updatedItem[payload.property] = payload.value;
          updatedItem.changed = true;
          return updatedItem;
        }
        return item;
      });
      state.pendingOrders = updatedOrders;
    },
    setPendingOrdersReviewRecord: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      const updatedOrders: any[] = [...state.pendingOrders].map((item: any) => {
        if (item.id === payload.id) {
          return {
            ...payload,
            changed: true
          };
        }
        return item;
      });
      state.pendingOrders = updatedOrders;
    },
    setPagination: (state, action: PayloadAction<M.DefaultPagination>) => {
      state.pagination = action.payload;
      state.submitFormFilter = true;
    },
    setPublishedById: (state, action: PayloadAction<number | undefined>) => {
      state.publishedById = action.payload;
    },
    setSelectedRecord: (state, action: PayloadAction<any>) => {
      state.selectedRecord = action.payload;
    },
    setShowAwnserModal: (state, action: PayloadAction<boolean>) => {
      state.showAwnserModal = action.payload;
    },
    setShowEditModal: (state, action: PayloadAction<boolean>) => {
      state.showEditModal = action.payload;
    },
    setShowExportExcelModal: (state, action: PayloadAction<boolean>) => {
      state.showExportExcelModal = action.payload;
    },
    setShowExportPDFModal: (state, action: PayloadAction<boolean>) => {
      state.showExportPDFModal = action.payload;
    },
    setShowDetailsModal: (state, action: PayloadAction<boolean>) => {
      state.showDetailsModal = action.payload;
    },
    setShowImportModal: (state, action: PayloadAction<boolean>) => {
      state.showImportModal = action.payload;
    },
    setShowImportModalItems: (state, action: PayloadAction<boolean>) => {
      state.showImportModalItems = action.payload;
    },
    setShowBuyIntentionModal: (state, action: PayloadAction<boolean>) => {
      state.showBuyIntentionModal = action.payload;
      if (!action.payload) state.buyIntentionCurrentStep = 0;
    },
    setShowJustifyModal: (state, action: PayloadAction<boolean>) => {
      state.showJustifyModal = action.payload;
    },
    setShowObservationModal: (state, action: PayloadAction<boolean>) => {
      state.showObservationModal = action.payload;
    },
    setShowOnlyDivergentOrders: (state, action: PayloadAction<boolean>) => {
      state.showOnlyDivergentOrders = action.payload;
    },
    setShowBuySuggestionModal: (state, action: PayloadAction<boolean>) => {
      state.showBuySuggestionModal = action.payload;
    },
    setShowCancelOrderModal: (state, action: PayloadAction<boolean>) => {
      state.showCancelOrderModal = action.payload;
    },
    setShowRepublishModal: (state, action: PayloadAction<boolean>) => {
      state.showRepublishModal = action.payload;
    },
    setSubmitFormFilter: (state, action: PayloadAction<boolean>) => {
      state.submitFormFilter = action.payload;
    },
    setTablePropertyValue: (
      state,
      action: PayloadAction<{
        value: any;
        property: string;
        record: any;
      }>
    ) => {
      const { payload } = action;
      const updatedOrders: any[] = state.orders?.map((item: any) => {
        if (item.id === payload.record.id) {
          const updatedItem = item;
          updatedItem[payload.property] = payload.value;
          if (payload.property === 'isExpired' && payload.value === true) {
            updatedItem.statusId = OrderManagementStatusEnum.EXPIRED;
          }
          updatedItem.changed = true;
          return updatedItem;
        }
        return item;
      });
      state.orders = updatedOrders;
    },
    setPendingOrdersSelectedKeys: (state, action: PayloadAction<number[]>) => {
      state.pendingOrdersSelectedKeys = action.payload;
    },
    setPendingOrdersPagination: (
      state,
      action: PayloadAction<M.DefaultPagination>
    ) => {
      state.pendingOrdersPagination = action.payload;
      state.submitFormFilter = true;
    },
    setSelectedKey: (state, action: PayloadAction<number>) => {
      const selectedKeys = [...state.pendingOrdersSelectedKeys].filter(
        key => key !== action.payload
      );
      const updatedKeys = [...selectedKeys, action.payload];
      state.pendingOrdersSelectedKeys = [...updatedKeys];
    },
    removeSelectedKey: (state, action: PayloadAction<number>) => {
      state.pendingOrdersSelectedKeys = [
        ...state.pendingOrdersSelectedKeys
      ].filter(key => key !== action.payload);
    },
    selectAllKeys: state => {
      state.pendingOrdersSelectedKeys = [...state.pendingOrders]
        .filter(i => validateOrder(i))
        .map(pendingOrder => pendingOrder.id);
    },
    removeAllSelectedKeys: state => {
      state.pendingOrdersSelectedKeys = [];
    }
  },
  extraReducers: builder => {
    builder.addCase(apiCalls.fetchOrders.pending, state => {
      return {
        ...initialState,
        publishers: state.publishers,
        publishedById: state.publishedById,
        loading: true,
        pagination: state.pagination,
        activeKey: OrderManagementTabEnum.order
      };
    });
    builder.addCase(apiCalls.fetchOrders.fulfilled, (state, action) => {
      state.orders = action.payload.results;
      state.ordersTotal = action.payload.totalCount;
      state.loading = false;
    });
    builder.addCase(apiCalls.fetchOrders.rejected, state => {
      state.loading = false;
    });
    builder.addCase(apiCalls.fetchPendingOrdersReview.pending, state => {
      return {
        ...initialState,
        publishers: state.publishers,
        publishedById: state.publishedById,
        loading: true,
        activeKey: state.activeKey,
        pagination: state.pagination
      };
    });
    builder.addCase(
      apiCalls.fetchPendingOrdersReview.fulfilled,
      (state, action) => {
        state.pendingOrders = action.payload.results;
        state.pendingOrdersTotal = action.payload.totalCount;
        state.loading = false;
      }
    );
    builder.addCase(apiCalls.fetchPendingOrdersReview.rejected, state => {
      state.loading = false;
    });
    builder.addCase(apiCalls.fetchOrderPublishers.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      apiCalls.fetchOrderPublishers.fulfilled,
      (state, action) => {
        state.loading = false;
        state.publishers = action.payload;
      }
    );
    builder.addCase(apiCalls.fetchOrderPublishers.rejected, state => {
      state.loading = false;
    });
    builder.addCase(apiCalls.importOrders.pending, state => {
      state.generatingOrders = true;
    });
    builder.addCase(apiCalls.importOrders.fulfilled, (state, action) => {
      state.hasImportResponse = true;
      state.generatingOrders = false;
      state.hasValidateResponse = true;
      state.fileOrders = [...action.payload];
    });
    builder.addCase(apiCalls.importOrders.rejected, state => {
      state.generatingOrders = false;
    });
    builder.addCase(apiCalls.revalidateOrders.pending, state => {
      state.generatingOrders = true;
    });
    builder.addCase(
      apiCalls.revalidateOrders.fulfilled,
      (state, action: any) => {
        console.log('Action Payloadzinho', action.payload);
        state.hasImportResponse = true;
        state.hasValidateResponse = true;
        state.generatingOrders = false;
        state.fileOrders = [...action.payload];
        state.submitProcessOrders = !action.payload.some(
          (i: any) => i.statusId === OrderManagementStatusIdEnum.ERROR
        );
      }
    );
    builder.addCase(apiCalls.revalidateOrders.rejected, state => {
      state.generatingOrders = false;
    });
    builder.addCase(apiCalls.processOrders.pending, state => {
      state.loading = true;
      state.submitProcessOrders = false;
    });
    builder.addCase(apiCalls.processOrders.fulfilled, state => {
      state.fileOrders = [];
      state.generatingOrders = false;
      state.hasImportResponse = false;
      state.hasValidateResponse = false;
      state.loading = false;
      state.showImportModal = false;
      state.showImportModalItems = false;
      state.submitFormFilter = true;
    });
    builder.addCase(apiCalls.processOrders.rejected, state => {
      state.loading = false;
    });
    builder.addCase(apiCalls.reproccessPendingOrdersReview.pending, state => {
      state.loading = true;
    });
    builder.addCase(apiCalls.reproccessPendingOrdersReview.fulfilled, state => {
      state.loading = false;
      state.submitFormFilter = true;
    });
    builder.addCase(apiCalls.reproccessPendingOrdersReview.rejected, state => {
      state.loading = false;
    });
    builder.addCase(apiCalls.editOrder.pending, state => {
      state.loading = true;
    });
    builder.addCase(apiCalls.editOrder.fulfilled, state => {
      state.loading = false;
      state.showEditModal = false;
      state.submitFormFilter = true;
    });
    builder.addCase(apiCalls.editOrder.rejected, state => {
      state.loading = false;
    });
    builder.addCase(apiCalls.republishOrder.pending, state => {
      state.loading = true;
    });
    builder.addCase(apiCalls.republishOrder.fulfilled, state => {
      state.loading = false;
      state.showRepublishModal = false;
      state.submitFormFilter = true;
    });
    builder.addCase(apiCalls.republishOrder.rejected, state => {
      state.loading = false;
    });
    builder.addCase(apiCalls.republishCancelled.pending, state => {
      state.loading = true;
    });
    builder.addCase(apiCalls.republishCancelled.fulfilled, state => {
      state.loading = false;
      state.showRepublishModal = false;
      state.submitFormFilter = true;
    });
    builder.addCase(apiCalls.republishCancelled.rejected, state => {
      state.loading = false;
    });
    builder.addCase(apiCalls.MassOrderRepublish.pending, state => {
      state.loading = true;
    });
    builder.addCase(apiCalls.MassOrderRepublish.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(apiCalls.MassOrderRepublish.rejected, state => {
      state.loading = false;
    });
  }
});

export const actions = autoServiceSlice.actions;
export const reducer = autoServiceSlice.reducer;

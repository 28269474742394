// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
export function addProductionPlan(productionPlan: any, id: any) {
  // productionPlan.productionPlanningGroups = productionPlan.productionPlanningGroups.filter((group: any) => group.toSend)
  productionPlan.productionPlanningGroups =
    productionPlan.productionPlanningGroups
      .map((group: any) => {
        const newGroup: any = {
          productionPlanningGroupId: group.productionPlanningGroupId,
          productionProductGroupId: group.productionProductGroupId,
          quantity: group.quantity,
          competenceEventId: group.competenceEventId
        };

        if (!group.toSend) {
          return;
        }

        newGroup.productionPlanningGroupProducts =
          group.productionPlanningGroupProducts.filter(
            (product: any) => product.toSend
          );
        newGroup.productionPlanningGroupProducts =
          newGroup.productionPlanningGroupProducts.map((product: any) => {
            const newProduct: any = {
              productId: product.productId
            };
            if (product.productionSectionId > 0) {
              newProduct.productionSectionId = product.productionSectionId;
            }
            if (product.productionPlanningGroupProductId > 0) {
              newProduct.productionPlanningGroupProductId =
                product.productionPlanningGroupProductId;
            }
            return newProduct;
          });
        return newGroup;
      })
      .filter((productionPlanFilter: any) => productionPlanFilter || false);
  productionPlan.id = id || 0;

  return {
    type: '@productionPlan/ADD_PRODUCTION_PLAN',
    payload: productionPlan
  };
}
export function setInvalidProdPlan(invalid: boolean) {
  return {
    type: '@productionPlan/SET_INVALID_PRODUCTION_PLAN',
    payload: { invalid }
  };
}
export function searchProductionPlan(data: any) {
  return {
    type: '@productionPlan/SEARCH_PRODUCTION_PLAN_SECTION',
    payload: { data }
  };
}

export function addProductionPlanSuccess() {
  return {
    type: '@productionPlan/ADD_PRODUCTION_PLAN_SUCCESS',
    payload: {}
  };
}

export function addProductionPlanFailure() {
  return {
    type: '@productionPlan/ADD_PRODUCTION_PLAN_FAILURE'
  };
}
export function searchProductionPlanMessage(alertMessage: string) {
  return {
    type: '@productionPlan/SEARCH_PRODUCTION_PLAN_MESSAGE',
    payload: { alertMessage }
  };
}

export function searchProductionPlanSuccess(productionPlans: any) {
  return {
    type: '@productionPlan/SEARCH_PRODUCTION_PLAN_SUCCESS',
    payload: productionPlans
  };
}

export function searchProductionPlanFailure(error: any) {
  return {
    type: '@productionPlan/SEARCH_PRODUCTION_PLAN_FAILURE',
    payload: { error }
  };
}
export function changeTotalPlan(value: string, index: any) {
  return {
    type: '@productionPlan/CHANGE_TOTAL_PLAN',
    payload: { value, index }
  };
}
export function replicateProductionGroup(group: any) {
  return {
    type: '@productionPlan/REPLICATE_GROUP',
    payload: group
  };
}
export function removeProductionGroup(group: any, index: number) {
  return {
    type: '@productionPlan/REMOVE_GROUP',
    payload: { group, index }
  };
}

export function setValidSave(validSave: boolean) {
  return {
    type: '@productionPlan/SET_VALID_SAVE',
    payload: validSave
  };
}

export function setSelectLocals(
  selected: any,
  productionPlannningGroupProduct: any,
  index: any,
  indexGroup: any,
  propertySelected: any
) {
  return {
    type: '@productionPlan/SET_SELECT_LOCALS',
    payload: {
      selected,
      productionPlannningGroupProduct,
      index,
      indexGroup,
      propertySelected
    }
  };
}

export function setSections(selected: any, index: any, groupKey: any) {
  return {
    type: '@productionPlan/SET_SECTIONS',
    payload: { selected, index, groupKey }
  };
}
export function setProductToCreate(
  selected: any,
  productionPlanningGroupProduct: any,
  groupKey: any,
  productGroupId: any,
  indexProduct: number
) {
  const groupProduct: any = {
    productionSectionId: selected,
    productId: productionPlanningGroupProduct.productId
  };
  if (
    productionPlanningGroupProduct.productionPlanningGroupProductId &&
    productionPlanningGroupProduct.productionPlanningGroupProductId > 0
  ) {
    groupProduct.productionPlanningGroupProductId =
      productionPlanningGroupProduct.productionPlanningGroupProductId;
  }

  return {
    type: '@productionPlan/SET_PRODUCT_TO_CREATE',
    payload: { groupProduct, groupKey, productGroupId, indexProduct, selected }
  };
}
export function changeLoading(loading: boolean) {
  return {
    type: '@productionPlan/CHANGE_LOADING',
    payload: { loading }
  };
}
export function changeSearch(searchEnabled: boolean) {
  return {
    type: '@productionPlan/CHANGE_SEARCH',
    payload: { searchEnabled }
  };
}
export function searchLocalsSuccess(data: any) {
  return {
    type: '@productionPlan/SET_SELECT_LOCALS_SUCCESS',
    payload: data
  };
}
export function setHistory(
  startYear: any,
  endYear: any,
  productionProductGroupId: number,
  groupType: string,
  dataType: string
) {
  return {
    type: '@productionPlan/SET_HISTORY',
    payload: {
      startYear,
      endYear,
      productionProductGroupId,
      groupType,
      dataType
    }
  };
}
export function setHistorySuccess(history: any) {
  return {
    type: '@productionPlan/SET_HISTORY_SUCCESS',
    payload: { history }
  };
}

export function setHistoryFailure() {
  return {
    type: '@productionPlan/SET_HISTORY_FAILURE'
  };
}

export function replicateLastYear(
  percent: number,
  productionPlanningGroupsIds: any[]
) {
  return {
    type: '@productionPlan/REPLICATE_LAST_YEAR',
    payload: { percent, productionPlanningGroupsIds }
  };
}

export function replicateLastYearSuccess() {
  return {
    type: '@productionPlan/REPLICATE_LAST_YEAR_SUCCESS'
  };
}
export function replicateLastYearFailure(failureReplicateMessage: any) {
  return {
    type: '@productionPlan/REPLICATE_LAST_YEAR_FAILURE',
    payload: { failureReplicateMessage }
  };
}

export function replicateWeek(replicateWeekAction: any) {
  return {
    type: '@productionPlan/REPLICATE_WEEK',
    payload: { replicateWeekAction }
  };
}

export function replicateWeekSuccess() {
  return {
    type: '@productionPlan/REPLICATE_WEEK_SUCCESS'
  };
}

export function replicateWeekFailure(alertMessage: any) {
  return {
    type: '@productionPlan/REPLICATE_WEEK_FAILURE',
    payload: { alertMessage }
  };
}
export function setProperty(
  propertyId: number,
  groupKey: number,
  productIndex: number
) {
  return {
    type: '@productionPlan/SET_PROPERTY',
    payload: { propertyId, groupKey, productIndex }
  };
}
export function setLocal(
  localId: number | null,
  groupKey: number,
  productIndex: number
) {
  return {
    type: '@productionPlan/SET_LOCAL',
    payload: { localId, groupKey, productIndex }
  };
}
export function setSection(
  productionSectionId: number | null,
  groupKey: number,
  productIndex: number
) {
  return {
    type: '@productionPlan/SET_SECTION',
    payload: { productionSectionId, groupKey, productIndex }
  };
}
export function duplicateProduct(groupKey: number, productIndex: number) {
  return {
    type: '@productionPlan/DUPLICATE_PRODUCT',
    payload: { groupKey, productIndex }
  };
}

export function removeDuplicatedProduct(
  groupKey: number,
  productIndex: number
) {
  return {
    type: '@productionPlan/REMOVE_DUPLICATED_PRODUCT',
    payload: { groupKey, productIndex }
  };
}

export function deleteProducts(products: any) {
  const ids: any = [];
  const keys = Object.keys(products);
  keys.forEach((key: string) => {
    products[key].map((product: any) => {
      if (product.productionPlanningGroupProductId > 0) {
        ids.push(product.productionPlanningGroupProductId);
      }
    });
  });
  return {
    type: '@productionPlan/DELETE_PRODUCTS',
    payload: { ids }
  };
}
export function deleteProductsSuccess() {
  return {
    type: '@productionPlan/DELETE_PRODUCTS_SUCCESS'
  };
}
export function deleteProductsFailure() {
  return {
    type: '@productionPlan/DELETE_PRODUCTS_FAILURE'
  };
}
export function removePlan() {
  return {
    type: '@productionPlan/REMOVE_PLAN'
  };
}
export function setHistoryDates(historyDates: any[]) {
  return {
    type: '@productionPlan/SET_HISTORY_DATES',
    payload: { historyDates }
  };
}
export function setSuccess(success: boolean) {
  return {
    type: '@productionPlan/SET_SUCCESS',
    payload: { success }
  };
}
export function setSuccessDelete(successDelete: boolean) {
  return {
    type: '@productionPlan/SET_SUCCESS_DELETE',
    payload: { successDelete }
  };
}
export function setSaving(saving: boolean) {
  return {
    type: '@productionPlan/SET_SAVING',
    payload: { saving }
  };
}

export function setSuccessReplicate(successReplicate: boolean) {
  return {
    type: '@productionPlan/SET_SUCCESS_REPLICATE',
    payload: { successReplicate }
  };
}
export function setSuccessReplicateWeek(successReplicate: boolean) {
  return {
    type: '@productionPlan/SET_SUCCESS_REPLICATE_WEEK',
    payload: { successReplicate }
  };
}
export function setFailureReplicate(failure: boolean) {
  return {
    type: '@productionPlan/SET_FAILURE_REPLICATE',
    payload: { failure }
  };
}
export function setFailureReplicateWeek(failureReplicateWeek: boolean) {
  return {
    type: '@productionPlan/SET_FAILURE_REPLICATE_WEEK',
    payload: { failureReplicateWeek }
  };
}

export function setFailure(failure: boolean) {
  return {
    type: '@productionPlan/SET_FAILURE',
    payload: { failure }
  };
}

export function setSelectedRowsProduct(selectedProducts: any) {
  return {
    type: '@productionPlan/SET_SELECTD_ROWS_PRODUCT',
    payload: { selectedProducts }
  };
}
export function setPageNumber(pageNumber: number) {
  return {
    type: '@productionPlan/SET_PAGE_NUMBER',
    payload: { pageNumber }
  };
}
export function setPageSize(pageSize: number) {
  return {
    type: '@productionPlan/SET_PAGE_SIZE',
    payload: { pageSize }
  };
}
export function setValidWeekSave(validWeekSave: boolean) {
  return {
    type: '@productionPlan/SET_VALID_WEEK_SAVE',
    payload: { validWeekSave }
  };
}
export function setExpandRowsKeys(expandRowsKeys: number[]) {
  return {
    type: '@productionPlan/SET_EXPAND_ROWS_KEYS',
    payload: { expandRowsKeys }
  };
}
export function resetPagination() {
  return {
    type: '@productionPlan/RESET_PAGINATION'
  };
}

export function setDisableCalendar(disableCalendar: boolean) {
  return {
    type: '@productionPlan/SET_DISABLE_CALENDAR',
    payload: { disableCalendar }
  };
}
export function searchPlanWeeks() {
  return {
    type: '@productionPlan/SEARCH_PLAN_WEEKS'
  };
}
export function searchPlanWeeksSuccess(planWeeks: any) {
  return {
    type: '@productionPlan/SEARCH_PLAN_WEEKS_SUCCESS',
    payload: { planWeeks }
  };
}
export function searchPlanWeeksFailure() {
  return {
    type: '@productionPlan/SEARCH_PLAN_WEEKS_FAILURE'
  };
}
export function setCalendarMessage(calendarMessage: string) {
  return {
    type: '@productionPlan/SET_CALENDAR_MESSAGE',
    payload: { calendarMessage }
  };
}

import { AxiosResponse } from 'axios';
import { apiSession } from '~/services/api';

export const getPermissions = async () => {
  return apiSession
    .get('permission/frontend?allowedSystemId=1')
    .then(response => {
      return { response };
    })
    .catch(error => {
      return { error };
    });
};

export const sendRefreshToken = async (refreshToken: string, scope: string) => {
  try {
    const queryString = `refresh_token=${refreshToken}&client_id=veiling-online&client_secret=9be425c1-cac1-46ba-a89b-2b564f9ad474&scope=${scope}&grant_type=refresh_token`;
    const response: AxiosResponse = await apiSession.post(
      '/connect/token',
      queryString,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
    if (response?.status === 200) {
      return response;
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

import { AxiosError, AxiosResponse } from 'axios';
import api from '../../config/index';
import {
  EditDefaultPrintRuleParams,
  PrintRule
} from '~/@core/domain/model/tag';

export const editDefaultPrintRule = (input: EditDefaultPrintRuleParams) => {
  return new Promise<void>((resolve, reject) => {
    api
      .put<PrintRule, AxiosResponse>('/tag/printing-default-rule', input)
      .then((_response: AxiosResponse) => {
        resolve();
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

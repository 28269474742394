import { PrinterOutlined } from '@ant-design/icons';
import { Button, ButtonProps } from 'antd';

interface Props extends ButtonProps {
  text?: string;
  disabled?: boolean;
  onClick?: () => void;
}
export default function ButtonPrinter({
  text,
  disabled,
  onClick,
  ...props
}: Props) {
  return (
    <Button type="default" disabled={disabled} {...props} onClick={onClick}>
      <PrinterOutlined /> {text}
    </Button>
  );
}

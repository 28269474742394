import { call, fork, put, takeLatest } from 'redux-saga/effects';

import * as API from '~/domain/api';

import {
  actions,
  FetchEvents,
  Types,
  ActiveOrInactive,
  GetVoucherHistory
} from './duck';
import * as VoucherService from '~/store/modules/_core/vouchers/service';
import * as CustomerService from '~/store/modules/_core/customers/service';
import { Action } from '~/helpers/util/reducers';
import { APIError } from '~/domain/api/errors';

export function* watchFetchEventsSaga(action: Action<FetchEvents>) {
  yield fork(fetchEventsSaga, action.payload);
}

export function* watchActiveOrInactiveStatus(action: Action<ActiveOrInactive>) {
  yield fork(activeOrInactiveStatusSaga, action.payload);
}

export function* watchGetVoucherHistoryStatus(
  action: Action<GetVoucherHistory>
) {
  yield fork(getVoucherHistoryStatusSaga, action.payload);
}

export function* fetchInitSaga(): any {
  try {
    //  yield call(CustomerService.findCustomerGroups);
    yield call(CustomerService.findCustomers);
    yield call(VoucherService.findVouchersByFilters);
    yield put(actions.fetchSuccess());
  } catch (err) {
    yield put(actions.fetchFailure());
  }
}

export function* fetchEventsSaga(params: FetchEvents): any {
  const {
    reajustmentAmount,
    reajustmentType,
    description,
    customerId,
    customerGroupId,
    status,
    pageNumber,
    pageSize,
    siteId
  } = params;
  try {
    const response: API.DefaultResponse = yield call(
      VoucherService.findVouchersByFilters,
      pageNumber,
      pageSize,
      status,
      customerId,
      customerGroupId,
      reajustmentAmount,
      reajustmentType,
      description,
      siteId
    );
    const totalCount = response.totalCount ?? 0;

    yield put(
      actions.fetchEventsSuccess({
        pageNumber,
        pageSize,
        totalCount
      })
    );
  } catch (err) {
    yield put(actions.fetchEventsFailure());
  }
}

export function* activeOrInactiveStatusSaga(param: ActiveOrInactive) {
  try {
    yield call(VoucherService.activeOrInactiveStatus, param.id);
    yield put(actions.activeOrInactiveStatusSuccess());
  } catch (error) {
    console.warn('error', error);
    if (error instanceof APIError) {
      yield put(
        actions.activeOrInactiveStatusFailure({ errorMessage: error.message })
      );
    } else {
      yield put(actions.activeOrInactiveStatusFailure({}));
    }
  }
}

export function* getVoucherHistoryStatusSaga(param: GetVoucherHistory) {
  try {
    yield call(VoucherService.getVoucherHistoryById, param.id);
    yield put(actions.getVoucherHistoryStatusSuccess());
  } catch (error) {
    console.warn('error', error);
    if (error instanceof APIError) {
      yield put(
        actions.getVoucherHistoryStatusFailure({ errorMessage: error.message })
      );
    } else {
      yield put(actions.getVoucherHistoryStatusFailure({}));
    }
  }
}

export default [
  takeLatest(Types.FETCH_INIT, fetchInitSaga),
  takeLatest(Types.FETCH_EVENTS, watchFetchEventsSaga),
  takeLatest(Types.ACTIVEORINACTIVE_INIT, watchActiveOrInactiveStatus),
  takeLatest(Types.GETVOUCHERHISTORY_INIT, watchGetVoucherHistoryStatus)
];

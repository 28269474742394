import CreateGfpItems from '../../model/gfp/CreateGfpItems';
import Stock from '../../model/stock/Stocks';

export class MapStockSelectedToGfpItems {
  static toGfpItems(stocks: Stock[]): CreateGfpItems[] {
    const items: CreateGfpItems[] = [];

    for (let i = 0; i < stocks.length; i++) {
      const stock = stocks[i];
      items.push({
        stockId: stock.stockId,
        packingId: stock.packingId as number,
        quantityPacking: Number(stock.quantityPacking),
        unitQuantity: Number(stock.quantityPerPacking),
        obs1: Number(stock.observationId1),
        obs2: Number(stock.observationId2),
        observation: stock.observation
      });
    }
    return items;
  }
}

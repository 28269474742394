import { ReportsAnnouncementState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.core.reportsAnnouncement as ReportsAnnouncementState;
};

export const getReportsAnnouncement = createSelector([getRoot], state => {
  return state.reportsAnnouncement;
});

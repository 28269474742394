import FilterPackings from '~/@core/domain/filters/packing/FilterPacking';
import Packing from '~/@core/domain/model/packing/Packing';
import { PackingService } from '~/@core/infrastructure/api/services';
import IUseCase from '../../../IUseCase';

export default class GetProductPacking
  implements IUseCase<FilterPackings, Promise<Packing[]>>
{
  execute(filter: FilterPackings): Promise<Packing[]> {
    return PackingService.fetchPackings(filter);
  }
}

import { ProductQualityState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.core.productQualities as ProductQualityState;
};

export const getProductQualitiesById = createSelector([getRoot], state => {
  return state.productQualitiesById;
});

export const getProductQualities = createSelector([getRoot], state =>
  Array.from(state.productQualitiesById.values())
);

export const getIsCleared = createSelector(
  [getRoot],
  state => state.status === 'cleared'
);

import { AxiosResponse } from 'axios';
import producerApi from '../api';
import { endpoints } from '~/constants/endpoints';
import { FetchPreferencesParams, ResponseApi } from '~/domain/model';
import ProductPreference from '~/domain/model/ProductPreference/ProductPreference';
import PackingComposition from '~/domain/model/ProductPreference/PackingComposition';
import CreatePreferenceParams from '~/domain/model/ProductPreference/CreatePreferenceParams';
import PreferenceHistoric from '~/domain/model/ProductPreference/PreferenceHistoric';
import { ProductPreferenceDeleteImageParams } from '~/domain/model/ProductPreference/ProductPreferenceDeleteImageParams';
import { ProductPreferenceUpload } from '~/domain/model/ProductPreference/ProductPreferenceUpload';
import { ProductPreferenceUpdateImageListOrder } from '~/domain/model/ProductPreference/ProductPreferenceUpdateImageListOrder';
import * as API from '~/domain/api';

export const findProdutPreferences = (
  fetchPreferencesParams: FetchPreferencesParams
) => {
  return producerApi.get<
    FetchPreferencesParams,
    AxiosResponse<ResponseApi<ProductPreference[]>>
  >(endpoints.productPreference.base, {
    params: fetchPreferencesParams
  });
};

export const findPackingCompositions = (siteId: number) => {
  return producerApi.get<void, AxiosResponse<PackingComposition[]>>(
    `${endpoints.productPreference.composition}${siteId}`
  );
};

export const createPreferences = (
  createPreferencesParams: CreatePreferenceParams[]
) => {
  return producerApi.post<
    CreatePreferenceParams[],
    AxiosResponse<ProductPreference[]>
  >(`${endpoints.productPreference.base}`, createPreferencesParams);
};

export const findPreferenceHistory = (preferenceId: number) => {
  return producerApi.get<void, AxiosResponse<PreferenceHistoric[]>>(
    `${endpoints.productPreference.history}${preferenceId}`
  );
};

export const findPreferenceColor = () => {
  return producerApi.get<void, AxiosResponse<any>>(
    `${endpoints.productPreference.color}`
  );
};
export const findImages = (preferenceId: number) => {
  return producerApi.get<
    void,
    AxiosResponse<{ preferenceId: number; images: API.Image[] }>
  >(`${endpoints.productPreference.base}image/${preferenceId}`);
};

export const upload = (payload: ProductPreferenceUpload) => {
  const formData = new FormData();

  payload.files.forEach((uploadFile: any, index: number) => {
    formData.append(`Image0${index + 1}`, uploadFile.originFileObj);
  });
  return producerApi.post<void, AxiosResponse<any>>(
    `${endpoints.productPreference.base}images/${payload.preferenceId}`,
    formData
  );
};

export const deleteImage = (payload: ProductPreferenceDeleteImageParams) => {
  return producerApi.delete<void, AxiosResponse<any>>(
    `${endpoints.productPreference.base}image/${payload.preferenceId}/${payload.fileId}`
  );
};

export const updateImageList = (
  payload: ProductPreferenceUpdateImageListOrder
) => {
  const params = {
    productPreferenceId: payload.productPreferenceId,
    imagesPosition: payload.newList.map((image, i) => {
      return {
        imageId: image.imageId,
        position: i + 1
      };
    })
  };
  return producerApi.put<void, AxiosResponse>(
    `${endpoints.productPreference.base}images-position`,
    params
  );
};

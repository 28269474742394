import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';

import service from '~/services/api';
import * as API from '~/domain/api';

import { actions } from './duck';

import SpecialDate from '~/domain/model/SpecialDate';

type Find = {
  startDate?: string;
  endDate?: string;
  date?: string;
  description?: string;
  startAlertWeekNumber?: number;
  endAlertWeekNumber?: number;
  year?: string;
  weekNumber?: number;
  nextPage: number;
  nextPageSize: number;
};

type Create = {
  date: string;
  description: string;
  dateStartAlert: string;
  dateEndAlert: string;
};

export function* find({
  startDate,
  endDate,
  date,
  description,
  startAlertWeekNumber,
  endAlertWeekNumber,
  year,
  weekNumber,
  nextPage,
  nextPageSize
}: Find) {
  let URL = `/special-date?`;

  if (nextPage) {
    URL += `PageNumber=${nextPage}&`;
  }
  if (nextPageSize) {
    URL += `PageSize=${nextPageSize}&`;
  }

  if (startDate && endDate && !date) {
    URL += `Data.StartDate=${startDate}&Data.EndDate=${endDate}&`;
  }

  if (date) {
    URL += `Data.StartDate=${date}&Data.EndDate=${date}&`;
  }

  if (description) {
    URL += `Data.Description=${description}&`;
  }
  if (year) {
    URL += `Data.Year=${year}&`;
  }
  if (weekNumber) {
    URL += `Data.WeekNumber=${weekNumber}&`;
  }
  if (startAlertWeekNumber && endAlertWeekNumber && year) {
    URL += `Data.Year=${year}&Data.StartAlertWeekNumber=${startAlertWeekNumber}&Data.EndAlertWeekNumber=${endAlertWeekNumber}`;
  }

  const response: AxiosResponse = yield call(service.get, URL);
  const apiResponse = response.data as API.DefaultResponse;
  const specialDates: API.SpecialDate[] = apiResponse.results || [];
  const specialDateById = new Map(
    specialDates.map(specialDate => [
      String(specialDate.date),
      new SpecialDate(specialDate)
    ])
  );
  yield put(actions.update({ specialDateById }));
  return apiResponse;
}

export const create = async (data: Create) => {
  await service.post('/special-date', data);
};

export const edit = async (data: Create) => {
  await service.put('/special-date', data);
};

export const destroy = async (id: number) => {
  await service.delete(`/special-date/${id}`);
};

export const destroyMany = async (ids: number[]) => {
  await service.delete('/special-date', { data: { ids } });
};

import { call, put, takeLatest } from 'redux-saga/effects';
import { EditInit } from '~/store/modules/_core/specialDates/duck';
import { actions, Types } from './duck';
import { Action } from '~/helpers/util/reducers';
import { APIError } from '~/domain/api/errors';
import * as SpecialDateService from '~/store/modules/_core/specialDates/service';

export function* editSaga(action: Action<EditInit>) {
  try {
    const data = action.payload;

    yield call(SpecialDateService.edit, data);
    yield put(actions.editSuccess());
  } catch (error) {
    if (error instanceof APIError) {
      yield put(actions.editFailure({ errorMessage: error.message }));
    } else {
      yield put(actions.editFailure({}));
    }
  }
}

export default [takeLatest(Types.EDIT_INIT, editSaga)];

import { call, fork, put, takeLatest } from 'redux-saga/effects';

import * as API from '~/domain/api';

import { actions, FetchEvents, Types, Inactivate } from './duck';
import * as CompetenceEventService from '~/store/modules/_core/competenceEvents/service';
import * as CompetenceService from '~/store/modules/_core/competences/service';
import { Action } from '~/helpers/util/reducers';
import { APIError } from '~/domain/api/errors';

export function* watchFetchEventsSaga(action: Action<FetchEvents>) {
  yield fork(fetchEventsSaga, action.payload);
}

export function* watchInactivateSaga(action: Action<Inactivate>) {
  yield fork(inactivateSaga, action.payload);
}

export function* fetchInitSaga(): any {
  try {
    yield call(CompetenceService.findMany);
    yield put(actions.fetchSuccess());
  } catch (err) {
    yield put(actions.fetchFailure());
  }
}

export function* fetchEventsSaga(params: FetchEvents): any {
  const { competenceID, status, pageNumber, pageSize } = params;
  try {
    const response: API.DefaultResponse = yield call(
      CompetenceEventService.findCompetenceEventsByCompetenceID,
      competenceID,
      status,
      pageNumber,
      pageSize
    );
    const totalCount = response.totalCount ?? 0;

    yield put(
      actions.fetchEventsSuccess({
        pageNumber,
        pageSize,
        totalCount
      })
    );
  } catch (err) {
    yield put(actions.fetchEventsFailure());
  }
}

export function* inactivateSaga(param: Inactivate) {
  try {
    yield call(CompetenceEventService.inactivate, param.id);
    yield put(actions.inactivateSuccess());
  } catch (error) {
    console.warn('error', error);
    if (error instanceof APIError) {
      yield put(actions.inactivateFailure({ errorMessage: error.message }));
    } else {
      yield put(actions.inactivateFailure({}));
    }
  }
}

export default [
  takeLatest(Types.FETCH_INIT, fetchInitSaga),
  takeLatest(Types.FETCH_EVENTS, watchFetchEventsSaga),
  takeLatest(Types.INACTIVATE_INIT, watchInactivateSaga)
];

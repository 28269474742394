import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as API from '~/domain/api';
import { Packing } from '~/domain/model';

import api from '~/services/api';

import { alertRequest } from '~/store/modules/alert/actions';

import {
  listPackingsSuccess,
  listPackingsFailure,
  getPackingDistributionRequest,
  getPackingDistributionSuccess,
  getPackingDistributionFailure,
  getHistoryPackingDistributionRequest,
  getHistoryPackingDistributionSuccess,
  getHistoryPackingDistributionFailure,
  savePackingDataRequest,
  savePackingDataFailure,
  savePackingDataSuccess,
  getHistoryPackingDistributionCompositionRequest,
  getHistoryPackingDistributionCompositionFailure,
  getHistoryPackingDistributionCompositionSuccess,
  getExtractsRequest,
  getExtractsSuccess,
  getExtractsFailure,
  getByIdPackingDistributionRequest,
  getByIdPackingDistributionSuccess,
  getByIdPackingDistributionFailure,
  removeModelSuccess,
  removeModelFailure,
  getAllPackingDistribution,
  getAllPackingDistributionSuccess,
  getAllPackingDistributionFailure,
  setSubmitForm
} from './actions';

import { ActionTypes, RemoveModel } from './types';

type onGetPackingDistributionRequest = ReturnType<
  typeof getPackingDistributionRequest
>;

type onGetAllPackingDistribution = ReturnType<typeof getAllPackingDistribution>;

type onGetExtractsRequest = ReturnType<typeof getExtractsRequest>;

type onGetHistoryPackingDistributionRequest = ReturnType<
  typeof getHistoryPackingDistributionRequest
>;

type onGetHistoryPackingDistributionCompositionRequest = ReturnType<
  typeof getHistoryPackingDistributionCompositionRequest
>;

type savePackingDataRequest = ReturnType<typeof savePackingDataRequest>;

type getByIdPackingDistributionRequest = ReturnType<
  typeof getByIdPackingDistributionRequest
>;

export function* onPackingListRequest() {
  try {
    const { data } = yield call(api.get, '/packing');
    const result = data as API.Packing[];

    const packings = result.map(packing => new Packing(packing));

    yield put(listPackingsSuccess(packings));
  } catch (err) {
    yield put(listPackingsFailure());
  }
}

export function* onGetPackingDistributionRequest({
  payload
}: onGetPackingDistributionRequest) {
  try {
    const { filters } = payload;
    let query = `?Data.SiteId=${filters.siteId}`;

    if (filters.packingId) {
      query += `&Data.PackingId=${filters.packingId}`;
    }

    if (filters.rollingMaterial) {
      query += `&Data.RollingMaterial=${filters.rollingMaterial}`;
    }

    if (filters.pageNumber) {
      query += `&PageNumber=${filters.pageNumber}`;
    }
    if (filters.pageSize) {
      query += `&PageSize=${filters.pageSize}`;
    }
    const { data } = yield call(
      api.get,
      `/packing/packing-distribution${query}`
    );

    yield put(getPackingDistributionSuccess(data.results, data.totalCount));
  } catch (error) {
    yield put(
      alertRequest('Erro ao buscar os dados, tente novamente!', 'error', true)
    );
    yield put(getPackingDistributionFailure());
  }
}

export function* onGetAllPackingDistribution({
  payload
}: onGetAllPackingDistribution) {
  try {
    const { filters } = payload;

    const { data } = yield call(api.get, `/packing/site/${filters.siteId}`);

    yield put(getAllPackingDistributionSuccess(data));
  } catch (error) {
    yield put(getAllPackingDistributionFailure());
  }
}

export function* onGetExtractsRequest({ payload }: onGetExtractsRequest) {
  try {
    const { filters } = payload;
    let query = `?Data.SiteId=${filters.siteId}`;

    if (filters.pageNumber) {
      query += `&pageNumber=${filters.pageNumber}`;
    }

    if (filters.pageSize) {
      query += `&pageSize=${filters.pageSize}`;
    }

    if (filters.packingId) {
      query += `&Data.PackingId=${filters.packingId}`;
    }

    if (filters.startPeriod && filters.endPeriod) {
      query += `&Data.StartPeriod=${filters.startPeriod}&Data.EndPeriod=${filters.endPeriod}`;
    }

    const { data } = yield call(
      api.get,
      `/packing/extract-packing-distribution${query}`
    );

    yield put(getExtractsSuccess(data.results, data.totalCount));
  } catch (error) {
    yield put(
      alertRequest('Erro ao buscar os dados, tente novamente!', 'error', true)
    );
    yield put(getExtractsFailure());
  }
}

export function* onGetHistoryPackingDistributionRequest({
  payload
}: onGetHistoryPackingDistributionRequest) {
  try {
    const { data } = yield call(
      api.get,
      `/packing/history-packing-distribution/${payload.id}`
    );
    yield put(getHistoryPackingDistributionSuccess(data));
  } catch (error) {
    yield put(
      alertRequest('Erro ao buscar os dados, tente novamente!', 'error', true)
    );
    yield put(getHistoryPackingDistributionFailure());
  }
}

export function* onGetHistoryPackingDistributionCompositionRequest({
  payload
}: onGetHistoryPackingDistributionCompositionRequest) {
  try {
    const { data } = yield call(
      api.get,
      `/packing/history-packing-distribution-composition/${payload.id}`
    );

    yield put(getHistoryPackingDistributionCompositionSuccess(data));
  } catch (error) {
    yield put(
      alertRequest('Erro ao buscar os dados, tente novamente!', 'error', true)
    );
    yield put(getHistoryPackingDistributionCompositionFailure());
  }
}

export function* onSavePackingDistributionRequest({
  payload
}: savePackingDataRequest) {
  try {
    let urlToSend = api.put;

    if (payload.packingDistribution.id === 0) {
      urlToSend = api.post;
    }

    const { data } = yield call(
      urlToSend,
      `/packing`,
      payload.packingDistribution
    );

    const packingDistributionId: number =
      data || payload.packingDistribution.id;

    yield put(savePackingDataSuccess());
    yield put(getByIdPackingDistributionRequest(packingDistributionId));
    yield put(alertRequest('Alterações salvas com sucesso', 'success', true));
  } catch (error: any) {
    yield put(savePackingDataFailure());
    yield put(alertRequest(JSON.stringify(error.response.data), 'error', true));
  }
}

export function* onGetByIdPackingDistributionRequest({
  payload
}: getByIdPackingDistributionRequest) {
  try {
    const { data } = yield call(
      api.get,
      `/packing/packing-distribution/${payload.packingDistributionId}`
    );

    yield put(getByIdPackingDistributionSuccess(data));
  } catch (error) {
    yield put(getByIdPackingDistributionFailure());
  }
}

export function* onRemoveModel(action: PayloadAction<RemoveModel>) {
  try {
    yield call(
      api.delete,
      `/packing?packingDistributionCompositionId=${action.payload.modelId}`
    );
    yield put(removeModelSuccess());
    yield put(setSubmitForm(true));
  } catch (error: any) {
    const message = JSON.stringify(error.response.data.errors.messages);
    yield put(alertRequest(message, 'error'));
    yield put(removeModelFailure());
  }
}
export default all([
  takeLatest(ActionTypes.listPackingsRequest, onPackingListRequest),
  takeLatest(
    ActionTypes.getPackingDistributionRequest,
    onGetPackingDistributionRequest
  ),
  takeLatest(
    ActionTypes.getAllPackingDistribution,
    onGetAllPackingDistribution
  ),
  takeLatest(
    ActionTypes.savePackingDataRequest,
    onSavePackingDistributionRequest
  ),
  takeLatest(
    ActionTypes.getByIdPackingDistributionRequest,
    onGetByIdPackingDistributionRequest
  ),
  takeLatest(
    ActionTypes.getHistoryPackingDistributionRequest,
    onGetHistoryPackingDistributionRequest
  ),
  takeLatest(
    ActionTypes.getHistoryPackingDistributionCompositionRequest,
    onGetHistoryPackingDistributionCompositionRequest
  ),
  takeLatest(ActionTypes.getExtractsRequest, onGetExtractsRequest),
  takeLatest(ActionTypes.removeModel, onRemoveModel)
]);

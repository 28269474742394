import { call, fork, put, takeLatest } from 'redux-saga/effects';

import * as API from '~/domain/api';

import { actions, FetchPage, Types } from './duck';
import { Action } from '~/helpers/util/reducers';
import { APIError } from '~/domain/api/errors';

import { DeleteInit, DeleteManyInit } from '../../_core/specialDates/duck';
import * as SpecialDateService from '~/store/modules/_core/specialDates/service';

export function* watchFetchPage(action: Action<FetchPage>) {
  yield fork(fetchPage, action.payload);
}

export function* fetchPage(params: FetchPage): any {
  try {
    const { nextPage, nextPageSize } = params;
    const response: API.DefaultResponse = yield call(
      SpecialDateService.find,
      params
    );

    const totalCount = response.totalCount ?? 0;

    yield put(actions.fetchPageSuccess({ nextPage, nextPageSize, totalCount }));
  } catch (err) {
    yield put(actions.fetchPageFailure());
  }
}

export function* deleteSaga(action: Action<DeleteInit>) {
  try {
    const { id } = action.payload;

    yield call(SpecialDateService.destroy, id);
    yield put(actions.deleteSuccess());
  } catch (error) {
    if (error instanceof APIError) {
      yield put(actions.deleteFailure({ errorMessage: error.message }));
    } else {
      yield put(actions.deleteFailure({}));
    }
  }
}

export function* deleteManySaga(action: Action<DeleteManyInit>) {
  try {
    const { ids } = action.payload;

    yield call(SpecialDateService.destroyMany, ids);
    yield put(actions.deleteManySuccess());
  } catch (error) {
    if (error instanceof APIError) {
      yield put(actions.deleteManyFailure({ errorMessage: error.message }));
    } else {
      yield put(actions.deleteManyFailure({}));
    }
  }
}

export default [
  takeLatest(Types.FETCH_PAGE, watchFetchPage),
  takeLatest(Types.DELETE_INIT, deleteSaga),
  takeLatest(Types.DELETE_MANY_INIT, deleteManySaga)
];

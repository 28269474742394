/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type FetchStatus = 'idle' | 'fetching' | 'success' | 'failure';
export type Pagination = {
  pageNumber?: number | undefined;
  pageSize?: number | undefined;
  totalCount?: number | undefined;
};
export type ProductPreferencesListState = {
  fetchStatus: FetchStatus;
  fetchMessage: string;
  pagination: Pagination;
};
export type FetchPreferencesAction = {
  SiteId: number;
  ProductId: number;
  ExpeditionBySiteId: number;
  CategoryId: number;
  PackingCompositionId: number;
  ProductRecipientDescription: string;
};
export type SetPaginationAction = {
  pagination: Pagination;
};

const prefix = '@productPreferencesList';

export const initialState: ProductPreferencesListState = {
  fetchStatus: 'idle',
  fetchMessage: '',
  pagination: {
    pageNumber: 1,
    pageSize: 10,
    totalCount: 0
  }
};

const productPreferenceList = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: state => {
      state.pagination = {
        pageNumber: 1,
        pageSize: 10,
        totalCount: 0
      };
      state.fetchStatus = 'idle';
      state.fetchMessage = '';
    },
    fetchFormData: (state, action: PayloadAction<number>) => {
      state.fetchStatus = 'fetching';
    },
    fetchProductPreferences: (
      state,
      action: PayloadAction<FetchPreferencesAction>
    ) => {
      state.fetchStatus = 'fetching';
    },
    fetchById: (state, action: PayloadAction<number>) => {
      state.fetchStatus = 'fetching';
    },
    fetchSuccess: state => {
      state.fetchStatus = 'success';
      state.fetchMessage = 'Requisição atendida com sucesso!';
    },
    fetchFailure: state => {
      state.fetchStatus = 'failure';
      state.fetchMessage = 'Houve um erro! Tente novamente mais tarde';
    },
    setPagination: (state, action: PayloadAction<SetPaginationAction>) => {
      state.pagination = action.payload.pagination;
    },
    setLoading: state => {
      state.fetchStatus = 'fetching';
    },
    setIdle: state => {
      state.fetchStatus = 'idle';
    }
  }
});
export const actions = productPreferenceList.actions;
export const reducer = productPreferenceList.reducer;

const caseReducers = productPreferenceList.caseReducers;
export const Types = Object.freeze({
  clear: `${prefix}/${caseReducers.clear.name}`,
  fetchFormData: `${prefix}/${caseReducers.fetchFormData.name}`,
  fetchProductPreferences: `${prefix}/${caseReducers.fetchProductPreferences.name}`,
  fetchById: `${prefix}/${caseReducers.fetchById.name}`,
  fetchSuccess: `${prefix}/${caseReducers.fetchSuccess.name}`,
  fetchFailure: `${prefix}/${caseReducers.fetchFailure.name}`
});

// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as PropertyCalls from './apiCalls';

export const prefix = 'propertyThunk';

export interface PropertyThunkState {
  properties: any[];
  filter: any;
  download: boolean;
}

const initialState: PropertyThunkState = {
  properties: [],
  filter: {
    Data_Search: undefined,
    Data_PropertySequencialCode: undefined,
    Data_Account: undefined,
    Data_CorporateName: undefined,
    Data_TradingName: undefined,
    Data_PropertyDescription: undefined,
    Data_StateId: undefined,
    Data_CityId: undefined,
    Data_IsTax: undefined,
    Data_IsActive: undefined,
    Data_IsRent: undefined,
    Data_RentStartPeriod: undefined,
    Data_RentEndPeriod: undefined,
    pageNumber: undefined
  },
  download: false
};
const propertyThunkSlice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: () => initialState,
    setProperties: (state, action: PayloadAction<any>) => {
      state.properties = action.payload;
    },
    setFilter: (state, action: PayloadAction<any>) => {
      state.filter = action.payload;
    },
    setDownload: (state, action: PayloadAction<boolean>) => {
      state.download = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(
      PropertyCalls.getPropertiesForAll.fulfilled,
      (state, action) => {
        state.properties = action.payload;
        state.download = true;
      }
    );
  }
});

export const actions = propertyThunkSlice.actions;
export const reducer = propertyThunkSlice.reducer;

import { Alert } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  visibility: hidden;
  opacity: 0;
  width: 80%;
  max-width: 300px;
  margin: 0;
  top: 20px;
  left: 100%;
  margin-left: -323px;
  cursor: pointer;
  transition: all 0.7s;
  z-index: 99999;

  &.active {
    visibility: visible;
    opacity: 1;
  }
`;

export const Message = styled(Alert)`
  & + & {
    margin-top: 1rem;
  }
`;

import { AxiosRequestConfig, AxiosResponse } from 'axios';
import moment from 'moment';
import jwtDecode from 'jwt-decode';
import {
  getAccessToken,
  getTokenType,
  getTokens,
  removeTokens
} from '~/helpers/util/storage';

import { refreshEnum } from '~/domain/enum/refresh.enum';

const getTokenToHeader = (): string => {
  return `${getTokenType()} ${getAccessToken()}`;
};

// #FIXME
// eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
const changeLoading = (status: any) => {};

const onRefreshToken = async () => {
  /* const response = await AuthService.sendRefreshToken(
    getRefreshToken(),
    getScope()
  );
  if (response.status === 401) {
    removeTokens();
  }

  const refreshData: API.RefreshData = response.data;
  setTokens({
    refreshToken: refreshData.refresh_token,
    accessToken: refreshData.access_token,
    scope: refreshData.scope,
    expiresIn: refreshData.expires_in,
    tokenType: refreshData.token_type
  }); */
};

export default {
  onResponse: async (responseParam: AxiosResponse<any>) => {
    changeLoading('idle');
    const tokens = getTokens();
    const { exp }: any = jwtDecode(tokens.accessToken);
    const expireDate = moment(exp * 1000);

    const now = moment().format('DD/MM/YYYY HH:mm:ss');

    const timeLeftInMs = moment(expireDate, 'DD/MM/YYYY HH:mm:ss').diff(
      moment(now, 'DD/MM/YYYY HH:mm:ss')
    );
    const timeLeftInMin = Math.floor(timeLeftInMs / 60000);

    if (timeLeftInMin <= refreshEnum.TIME_TO_REFRESH) {
      onRefreshToken();
    }

    return responseParam;
  },
  onError: (error: any) => {
    changeLoading('idle');
    if (error?.response?.status === 401) {
      removeTokens();
    }

    return Promise.reject(error);
  },
  onRequest: (config: AxiosRequestConfig) => {
    config.headers.Authorization = getTokenToHeader();
    return config;
  },
  onRequestStatus: (config: AxiosRequestConfig) => {
    config.headers.Authorization = getTokenToHeader();
    return config;
  },
  onResponseApiSession: async (responseParam: AxiosResponse<any>) => {
    changeLoading('idle');
    return responseParam;
  }
};

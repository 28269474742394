import { Moment } from 'moment';
import { usFormat } from '~/helpers/util';
import FilterOrder from '../../filters/order/FilterOrder';
import BasePagination from '../BasePagination';

export default interface FormFilterOrderModel {
  siteId: number;
  DeliveryDate: Moment[];
  CategoryId?: number;
  GroupId?: number;
  ProductId?: number;
  ColorId?: number;
  ProductRecipient?: string;
  QualityId?: number;
  OrderNumber?: number;
  ExpeditionId?: number;
  CustomerId?: number;
}

export class MapFormToFilterOrder {
  static toFilter(
    formData: FormFilterOrderModel,
    pagination: BasePagination
  ): FilterOrder {
    const startDeliveryDate =
      formData.DeliveryDate && formData.DeliveryDate[0]
        ? usFormat(formData.DeliveryDate[0]).toString()
        : undefined;
    const endDeliveryDate =
      formData.DeliveryDate && formData.DeliveryDate[1]
        ? usFormat(formData.DeliveryDate[1]).toString()
        : undefined;

    const filter: FilterOrder = {
      'Data.siteId': formData.siteId,
      'Data.startDeliveryDate': startDeliveryDate,
      'Data.endDeliveryDate': endDeliveryDate,
      'Data.categoryId': formData.CategoryId,
      'Data.groupId': formData.GroupId,
      'Data.productId': formData.ProductId,
      'Data.colorId': formData.ColorId,
      'Data.productRecipient': formData.ProductRecipient,
      'Data.QualityId': formData.QualityId,
      'Data.orderNumber': formData.OrderNumber,
      'Data.expeditionId': formData.ExpeditionId,
      'Data.customerId': formData.CustomerId,
      pageSize: pagination.pageSize,
      pageNumber: pagination.pageNumber
    };
    return filter;
  }
}

import { call, put, takeLatest } from 'redux-saga/effects';

import * as OfferChannelService from '~/store/modules/_core/offerChannels/service';
import * as ProductCategoryService from '~/store/modules/_core/productCategories/service';

import { Action } from '~/helpers/util/reducers';

import { Types, actions, CreateInit } from './duck';

export function* fetchInit() {
  try {
    yield call(ProductCategoryService.findMany);
    yield put(actions.fetchSuccess());
  } catch (error) {
    yield put(actions.fetchFailure());
  }
}

export function* createSaga(action: Action<CreateInit>) {
  try {
    const data = action.payload;

    yield call(OfferChannelService.create, data);
    yield put(actions.createSuccess());
  } catch (error) {
    yield put(actions.createFailure({ requestStatus: 'failure' }));
  }
}

export default [
  takeLatest(Types.CREATE_INIT, createSaga),
  takeLatest(Types.FETCH_INIT, fetchInit)
];

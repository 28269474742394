import { createSelector } from 'reselect';
import { ProductionPlanningsState } from '.';

export const getRoot = ({
  productionPlanning
}: {
  productionPlanning: ProductionPlanningsState;
}) => {
  return productionPlanning;
};

export const getCloseImportModal = createSelector(
  [getRoot],
  state => state.closeImportModal
);

export const getCloseReplicateWeekModal = createSelector(
  [getRoot],
  state => state.closeReplicateWeekModal
);

export const getCloseReplicateYearModal = createSelector(
  [getRoot],
  state => state.closeReplicateYearModal
);

export const getCurrentFocusWeek = createSelector(
  [getRoot],
  state => state.currentFocusWeek
);

export const getDisableDownload = createSelector(
  [getRoot],
  state => state.disableDownload
);

export const getDisableSave = createSelector(
  [getRoot],
  state => state.disableSave
);

export const getDisableUpload = createSelector(
  [getRoot],
  state => state.disableUpload
);

export const getLoading = createSelector([getRoot], state => state.loading);

export const getId = createSelector(
  [getRoot],
  state => state.productionPlanningId
);

export const getImportingLog = createSelector(
  [getRoot],
  state => state.importingLog
);

export const getImportingLogDetails = createSelector(
  [getRoot],
  state => state.importingLogDetails
);

export const getImportingHistory = createSelector(
  [getRoot],
  state => state.importingHistory
);

export const geLoadingImportingLog = createSelector(
  [getRoot],
  state => state.loadingImportingLog
);

export const getModificationDate = createSelector(
  [getRoot],
  state => state.modificationDate
);

export const getProductGroupHistories = createSelector(
  [getRoot],
  state => state.productGroupHistories
);

export const getSelectedKeys = createSelector(
  [getRoot],
  state => state.selectedKeys
);

export const getSiteId = createSelector([getRoot], state => state.siteId);

export const getSpecialDates = createSelector(
  [getRoot],
  state => state.specialDates
);

export const getSubmitFormFilter = createSelector(
  [getRoot],
  state => state.submitFormFilter
);

export const getTotalColumn = createSelector(
  [getRoot],
  state => state.totalColumn
);

export const getTotalRow = createSelector([getRoot], state => state.totalRow);

export const getUpdatedWeeks = createSelector(
  [getRoot],
  state => state.updatedWeeks
);

export const getVirtualTableDataSource = createSelector(
  [getRoot],
  state => state.virtualTableDataSource
);

export const getVisibleColumns = createSelector(
  [getRoot],
  state => state.visibleColumns
);

export const getProductionPlanningReplicate = createSelector(
  [getRoot],
  state => state.prodPlanReplicate
);

export const getTotalCount = createSelector(
  [getRoot],
  state => state.totalCount
);

export const getProductionPlanningReplicateHistory = createSelector(
  [getRoot],
  state => state.prodPlanReplicateHistory
);

export const getReplicateBudgetSuccess = createSelector(
  [getRoot],
  state => state.replicateBudgetSuccess
);

export const getPropPlanRepSiteId = createSelector(
  [getRoot],
  state => state.prodPlanRepSiteId
);

export const getPropPlanRepCompentenceId = createSelector([getRoot], state => {
  return state.prodPlanRepCompentenceId;
});

import * as dotenv from 'dotenv';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import Interceptors from './interceptors';

dotenv.config();

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

api.interceptors.response.use(
  (response: AxiosResponse<any>) => Interceptors.onResponse(response),
  (error: any) => Interceptors.onError(error)
);

export const apiStatus = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

export const apiEcommerce = axios.create({
  baseURL: process.env.REACT_APP_API_URL_ECOMMERCE
});

apiEcommerce.interceptors.response.use(
  (response: AxiosResponse<any>) => Interceptors.onResponse(response),
  (error: any) => Interceptors.onError(error)
);

export const apiGeo = axios.create({
  baseURL: process.env.REACT_APP_API_GEO_URL
});

apiGeo.interceptors.response.use(
  (response: AxiosResponse<any>) => response,
  (error: any) => Interceptors.onError(error)
);

export const apiSession = axios.create({
  baseURL: process.env.REACT_APP_API_SESSION_URL
});

apiSession.interceptors.response.use(
  (response: AxiosResponse<any>) => Interceptors.onResponseApiSession(response),
  (error: any) => Interceptors.onError(error)
);

export const apiReport = axios.create({
  baseURL: process.env.REACT_APP_API_URL_REPORT
});

apiReport.interceptors.response.use(
  (response: AxiosResponse<any>) => Interceptors.onResponse(response),
  (error: any) => Interceptors.onError(error)
);

api.interceptors.request.use((config: AxiosRequestConfig) =>
  Interceptors.onRequest(config)
);
apiStatus.interceptors.request.use((config: AxiosRequestConfig) =>
  Interceptors.onRequestStatus(config)
);
apiGeo.interceptors.request.use((config: AxiosRequestConfig) =>
  Interceptors.onRequest(config)
);
apiSession.interceptors.request.use((config: AxiosRequestConfig) =>
  Interceptors.onRequest(config)
);
apiReport.interceptors.request.use((config: AxiosRequestConfig) =>
  Interceptors.onRequest(config)
);

export default api;

import {
  Button,
  Collapse,
  Divider,
  Modal,
  ModalProps,
  Table,
  Tabs
} from 'antd';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

export const Title = styled.span`
  padding-left: 0;
  font: 16px Roboto, sans-serif;
  font-weight: bold;
`;

export const Card = styled.div`
  border: 1px solid #d9d9d9;
  padding: 20px;
  border-radius: 12px;
  &:hover {
    cursor: pointer;
  }
  &.card-padding {
    padding: ${isMobile ? '4px' : '16px'};
  }
`;

export const CardSystem = styled.div`
  border: 1px solid #d9d9d9;
  width: 80%;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 12px;
  &:hover {
    cursor: pointer;
  }
  &.card-padding {
    padding: ${isMobile ? '4px' : '16px'};
  }
  &img {
    margin-bottom: 40px;
  }
`;

export const ModalPacking = styled(Modal)`
  border-radius: 10px;
  .ant-modal-header {
    background: #f78e1e;
    padding: 24px 24px 16px;
  }
  .ant-modal-title {
    font-size: 20px;
    color: #fff;
  }
`;

export const TabWrapper = styled.div`
  padding: 12px 16px;
  width: 100%;
  margin-top: 15px;
`;

export const TabContainerMenus = styled(Tabs)`
  .ant-form-item-label {
    padding-bottom: 2px;
  }

  .ant-tabs-tab {
    border: none;
    box-shadow: none;
  }

  label {
    font: 12px Roboto, sans-serif;
    color: #353839;
  }

  .ant-tabs-nav {
    margin-bottom: 4px;

    &::before {
      position: absolute;
      right: 0;
      left: 0;
      top: 100%;
      border-bottom: 1px solid #d9d9d9;
      content: '';
    }
  }

  .tab-default-content {
    margin-left: -10px;
    margin-right: -10px;
  }

  .ant-tabs-content {
    width: calc(100% + 20px);
  }
`;

export const ButtonTop = styled(Button)`
  padding: 4px 9px;
`;

export const CircleOrange = styled.h2`
  color: #f78e1e;
`;

export const Frame = styled(Collapse)`
  font: 16px Roboto, sans-serif;
  font-weight: 400;

  .ant-collapse-header {
    display: flex;
  }
`;

export const DividerFrame = styled(Divider)`
  &.ant-divider-horizontal.ant-divider-with-text-left::before {
    width: 2% !important;
    top: 0% !important;
  }

  &.ant-divider-horizontal.ant-divider-with-text-left::after {
    top: 0% !important;
  }

  border-top-color: #d9d9d9 !important;
  margin: 0 !important;
`;

export const StatusCircle = styled.div`
  border-radius: 100%;
  background-color: ${props => props.color};
  width: 16px;
  height: 16px;
  margin: auto;
`;

export const PermissionCircle = styled.div`
  border-radius: 100%;
  background-color: ${props => props.color};
  width: 16px;
  height: 16px;
  margin: auto;
`;

export const PointerValue = styled.span`
  &:hover {
    cursor: pointer;
    color: #f78e1e;
  }
`;

export const TabContainer = styled(Tabs)`
  .ant-form-item-label {
    padding-bottom: 2px;
  }

  .ant-tabs-tab {
    border: none;
    box-shadow: none;
    padding: 6px 12px 6px !important;
  }

  label {
    font: 12px Roboto, sans-serif;
  }

  .ant-tabs-nav {
    margin-bottom: 4px;
    color: #c7c4c4;

    &::before {
      position: absolute;
      right: 0;
      left: 0;
      top: 100%;
      border-bottom: 1px solid #d9d9d9;
      content: '';
    }
  }

  .tab-default-content {
    margin-left: -10px;
    margin-right: -10px;
  }
`;

export const ModalDefault = styled(Modal)<ModalProps>`
  .ant-modal-close,
  .anticon-close {
    color: #fff !important;
  }

  .ant-modal-body {
    padding: 0 !important;
  }

  .ant-modal-footer {
    display: none;
  }
`;

export const ModalHeader = styled.div`
  background-color: #f78e1e;
  padding: 24px;

  > span {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
  }

  .ant-col {
    > span {
      color: #fff;
      font-size: 20px;
      font-weight: 500;
    }

    button {
      font-size: 14px;
      max-width: 98px;
      background-color: #f78e1e;
      border-color: #fff;
      color: #fff;

      &:hover {
        background-color: #fff;
        color: #f78e1e;
      }
    }
  }
`;

export const ModalBody = styled.div`
  padding: 0px 24px;
`;

export const ModalFooter = styled.div`
  padding: 16px 24px;
  border-top: 1px solid #d9d9d9;

  .record {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    > {
      font-style: normal;
      line-height: 100%;
      margin-bottom: 4px;

      &.title {
        font-weight: 700;
        font-size: 16px;
      }

      &.description {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
`;

export const GroupTitleInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
`;

export const TitleText = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 3px;
  text-align: start !important;

  &.is-result {
    font-weight: 400;
  }
`;

export const DescriptionText = styled.p`
  font-size: 12px;
  font-weight: 400;
  text-align: start !important;
  margin-bottom: 0px;
`;

export const Circle = styled.div`
  border-radius: 100%;
  background-color: ${props => props.color};
  width: 16px;
  height: 16px;
  margin: auto;
  color: #fff;
  text-align: center;
  font-size: 10px;
`;

export const SmallTable = styled(Table)`
  .ant-table-cell {
    padding: 4px !important;
  }
  .ant-select-selection-item {
    margin-top: -4px;
  }
  .row-error > .ant-table-cell {
    background-color: #fff1f0;
  }
`;

export const SpanError = styled.p`
  color: #de480d;
  font-size: 10px;
  display: block;
  text-align: center;
  margin: 5px 0 0 0;
  color: #8c8c8c;
`;

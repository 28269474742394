import FilterText from '../../filters/rfidReports/FilterText';
import BasePagination from '../BasePagination';

export default interface FormFilterTextModel {
  txtFilter: string;
}

export class MapFormToFilter {
  static toFilter(
    formData: FormFilterTextModel,
    pagination: BasePagination
  ): FilterText {
    const filter: FilterText = {
      pageSize: pagination.pageSize,
      pageNumber: pagination.pageNumber
    };
    if (formData.txtFilter !== '') {
      filter.txtFilter = formData.txtFilter;
    }
    return filter;
  }
}

import { ExclamationCircleOutlined } from '@ant-design/icons';
import ReactHtmlParser from 'react-html-parser';
import { GenericModalProps } from '../GenericModal';
import { EndTitle, Icon, Modal, Question, TextWrapper, Title } from './styles';

export interface ConfirmModalProps extends Omit<GenericModalProps, 'title'> {
  confirmIcon?: React.ReactNode;
  confirmTitle: string;
  endBodyMessage?: string;
  question: string;
  visible?: boolean;
  onOk?: () => void;
  onCancel?: () => void;
  okText?: string;
  cancelText?: string;
  width?: number;
}

export const ConfirmModal = ({
  confirmIcon,
  confirmTitle,
  question,
  endBodyMessage,
  ...props
}: ConfirmModalProps) => {
  return (
    <Modal {...props} closable={false} zIndex={1500}>
      <Icon>
        {confirmIcon || (
          <ExclamationCircleOutlined style={{ color: 'red', fontSize: 25 }} />
        )}
      </Icon>
      <TextWrapper>
        <Title>{confirmTitle}</Title>
        <Question>{ReactHtmlParser(question)}</Question>
        <EndTitle>{endBodyMessage || ''}</EndTitle>
      </TextWrapper>
    </Modal>
  );
};

import { create } from 'zustand';
import Owned from '~/@core/domain/model/rfidReports/Owned';
import TablePagination from '~/@core/domain/ui/TablePagination';

interface OwnedState {
  owneds: Owned[];
  ownedPagination: TablePagination;
  handleOwnedPagination: (newPagination: TablePagination) => void;
  addOwneds: (ownedsValues: Owned[]) => void;
}

const useOwnedStore = create<OwnedState>()(set => ({
  owneds: [],
  ownedPagination: {
    pageSize: 10,
    current: 1,
    total: 1
  },
  handleOwnedPagination: newPagination =>
    set({ ownedPagination: newPagination }),
  addOwneds: ownedValues => set({ owneds: ownedValues })
}));

export default useOwnedStore;

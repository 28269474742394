/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as API from '~/domain/api';

export type Status = 'idle' | 'fetching' | 'success' | 'failure';

export type VolOffersListState = {
  loading: boolean;
  status: Status;
  pagination: Pagination;
  history: API.History;
  volOfferTypes: API.VolOfferType[];
  confirmSave: boolean;
  waitEditOpen:
    | 'idle'
    | 'waiting'
    | 'waitingCloseTab'
    | 'openEdit'
    | 'closeTab';
};
export type VolOffersListAction = {
  SiteId: number;
  WeekId?: number;
  pagination?: Pagination;
  CategoryId?: number;
  GroupId?: number;
  ProductId?: number;
  ProductQualityId?: number;
  VolOfferTypeId?: number;
  SiteDeliveryPatternId?: number;
  StartDateStock?: any;
  EndDateStock?: any;
  Status?: boolean;
  ProductRecipient?: string;
  ColorId?: number;
  isVol?: boolean;
  Keyword?: string;
};

const prefix = '@volOffersList';
export type Pagination = {
  pageNumber?: number | undefined;
  pageSize?: number | undefined;
  totalCount?: number | undefined;
};
export const initialState: VolOffersListState = {
  loading: false,
  status: 'idle',
  pagination: {
    pageNumber: 1,
    pageSize: 100,
    totalCount: 0
  },
  history: {} as API.History,
  volOfferTypes: [] as API.VolOfferType[],
  confirmSave: false,
  waitEditOpen: 'idle'
};

const volOffersList = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: (state: VolOffersListState) => {
      state.volOfferTypes = [] as API.VolOfferType[];
    },
    fetch: (
      state: VolOffersListState,
      action: PayloadAction<VolOffersListAction>
    ) => {},
    fetchFilterData: (
      state: VolOffersListState,
      action: PayloadAction<VolOffersListAction>
    ) => {},
    fetchHistory: (
      state: VolOffersListState,
      action: PayloadAction<number>
    ) => {},
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPagination: (state, action: PayloadAction<Pagination>) => {
      state.pagination = action.payload;
    },
    setHistory: (state, action: PayloadAction<API.History>) => {
      state.history = action.payload;
    },
    setVolOfferTypes: (state, action: PayloadAction<API.VolOfferType[]>) => {
      state.volOfferTypes = action.payload;
    },
    setConfirmSave: (state, action: PayloadAction<boolean>) => {
      state.confirmSave = action.payload;
    },
    setWaitEditOpen: (
      state,
      action: PayloadAction<
        'idle' | 'waiting' | 'waitingCloseTab' | 'openEdit' | 'closeTab'
      >
    ) => {
      state.waitEditOpen = action.payload;
    }
  }
});
export const actions = volOffersList.actions;
export const reducer = volOffersList.reducer;

const caseReducers = volOffersList.caseReducers;
export const Types = Object.freeze({
  fetch: `${prefix}/${caseReducers.fetch.name}`,
  fetchFilterData: `${prefix}/${caseReducers.fetchFilterData.name}`,
  fetchHistory: `${prefix}/${caseReducers.fetchHistory.name}`
});

export function partnershipListRequest(siteId?: number) {
  return {
    type: '@partnership/LIST_REQUEST',
    payload: { siteId }
  };
}

export function partnershipListSuccess(list: any) {
  return {
    type: '@partnership/LIST_SUCCESS',
    payload: { list }
  };
}

export function partnershipListFailure() {
  return {
    type: '@partnership/LIST_FAILURE'
  };
}

import { createSelector } from 'reselect';

import { AutoserviceClassificationState } from './index';

export const getRoot = (state: any) => {
  return state.autoserviceClassification as AutoserviceClassificationState;
};

export const getWhiteList = createSelector(
  [getRoot],
  state => state.producersWhiteList
);

export const getBlackList = createSelector(
  [getRoot],
  state => state.producersBlackList
);

export const getReloadPageData = createSelector(
  [getRoot],
  state => state.reloadPageData
);

export const getPagination = createSelector([getRoot], state => {
  return state.pagination;
});

import { createSelector } from 'reselect';
import { AutoServiceState } from '.';

const getRoot = ({ autoService }: { autoService: AutoServiceState }) => {
  return autoService;
};

export const getActiveKey = createSelector([getRoot], state => state.activeKey);

export const getDisableTableTopDropdown = createSelector(
  [getRoot],
  state => state.disableTableTopDropdown
);

export const getDisableUpload = createSelector(
  [getRoot],
  state => state.disableUpload
);

export const getFileOrders = createSelector(
  [getRoot],
  state => state.fileOrders
);

export const getGeneratingOrders = createSelector(
  [getRoot],
  state => state.generatingOrders
);

export const getHasImportResponse = createSelector(
  [getRoot],
  state => state.hasImportResponse
);

export const getHasValidateResponse = createSelector(
  [getRoot],
  state => state.hasValidateResponse
);

export const getBuyIntentionCurrentStep = createSelector(
  [getRoot],
  state => state.buyIntentionCurrentStep
);

export const getOrders = createSelector([getRoot], state => state.orders);

export const getOrdersTotal = createSelector(
  [getRoot],
  state => state.ordersTotal
);

export const getPendingOrders = createSelector(
  [getRoot],
  state => state.pendingOrders
);

export const getPendinOrdersSelectedKeys = createSelector(
  [getRoot],
  state => state.pendingOrdersSelectedKeys
);

export const getPendingOrdersTotal = createSelector(
  [getRoot],
  state => state.pendingOrdersTotal
);

export const getPagination = createSelector(
  [getRoot],
  state => state.pagination
);

export const getPublishers = createSelector(
  [getRoot],
  state => state.publishers
);

export const getPublishedById = createSelector(
  [getRoot],
  state => state.publishedById
);

export const getSelectedRecord = createSelector(
  [getRoot],
  state => state.selectedRecord
);

export const getShowAwnserModal = createSelector(
  [getRoot],
  state => state.showAwnserModal
);

export const getShowDetailsModal = createSelector(
  [getRoot],
  state => state.showDetailsModal
);

export const getShowEditModal = createSelector(
  [getRoot],
  state => state.showEditModal
);

export const getShowExportExcelModal = createSelector(
  [getRoot],
  state => state.showExportExcelModal
);

export const getShowExportPDFModal = createSelector(
  [getRoot],
  state => state.showExportPDFModal
);

export const getShowImportModal = createSelector(
  [getRoot],
  state => state.showImportModal
);

export const getShowImportModalItems = createSelector(
  [getRoot],
  state => state.showImportModalItems
);

export const getShowBuyIntentionModal = createSelector(
  [getRoot],
  state => state.showBuyIntentionModal
);

export const getShowJustifyModal = createSelector(
  [getRoot],
  state => state.showJustifyModal
);

export const getShowObservationModal = createSelector(
  [getRoot],
  state => state.showObservationModal
);

export const getShowOnlyDivergentOrders = createSelector(
  [getRoot],
  state => state.showOnlyDivergentOrders
);

export const getShowBuySuggestionModal = createSelector(
  [getRoot],
  state => state.showBuySuggestionModal
);

export const getShowCancelOrderModal = createSelector(
  [getRoot],
  state => state.showCancelOrderModal
);

export const getShowRepublishModal = createSelector(
  [getRoot],
  state => state.showRepublishModal
);

export const getSubmitFormFilter = createSelector(
  [getRoot],
  state => state.submitFormFilter
);

export const getSubmitProcessOrders = createSelector(
  [getRoot],
  state => state.submitProcessOrders
);

import produce from 'immer';
import { create } from 'zustand';
import { getFeatureFlag } from '~/services/producer/FeatureFlags/FeatureFlags';

export type FeatureFlag = {
  name: string;
  isEnabled: boolean;
};

export type FeatureFlagState = {
  flags: FeatureFlag[];
  isLoading: boolean;
  isError: boolean;
  isSuccessful: boolean;
};

export type FeatureFlagActions = {
  fetchFeatureFlags: () => Promise<void>;
  refreshFeatureFlags: () => Promise<void>;
  refreshSingleFeatureFlag: (flagName: FeatureFlags) => Promise<void>;
  setState: (newState: Partial<FeatureFlagState>) => void;
  isFeatureFlagEnabled: (flagName: FeatureFlags) => boolean;
};

export type FeatureFlagStore = FeatureFlagState & FeatureFlagActions;

const initialFeatureFlagState: FeatureFlagState = {
  flags: [],
  isLoading: false,
  isError: false,
  isSuccessful: false
};

export enum FeatureFlags {
  PriceVariation = 'PriceVariation',
  VerifyOffers = 'VerifyOffers',
  PriceVariationDecreasePrice = 'PriceVariationDecreasePrice'
}

const predefinedFlags = Object.values(FeatureFlags);

export const useFeatureFlagStore = create<FeatureFlagStore>()((set, get) => ({
  ...initialFeatureFlagState,

  setState: (newState: Partial<FeatureFlagState>) => {
    set(
      produce((state: FeatureFlagStore) => {
        Object.assign(state, newState);
      })
    );
  },

  fetchFeatureFlags: async () => {
    const { setState } = get();

    setState({ isLoading: true });

    try {
      const flags = await Promise.all(
        predefinedFlags.map(flag => getFeatureFlag({ flag }))
      );

      setState({
        flags,
        isError: false,
        isLoading: false,
        isSuccessful: true
      });
    } catch (error) {
      setState({
        flags: [],
        isError: true,
        isLoading: false,
        isSuccessful: false
      });
    }
  },

  refreshFeatureFlags: async () => {
    const { fetchFeatureFlags } = get();
    await fetchFeatureFlags();
  },

  refreshSingleFeatureFlag: async (flagName: FeatureFlags) => {
    const { setState, flags } = get();

    try {
      const updatedFlag = await getFeatureFlag({ flag: flagName });
      const updatedFlags = flags.map(flag =>
        flag.name === flagName ? updatedFlag : flag
      );

      setState({
        flags: updatedFlags,
        isError: false,
        isLoading: false,
        isSuccessful: true
      });
    } catch (error) {
      setState({
        isError: true,
        isLoading: false,
        isSuccessful: false
      });
    }
  },

  isFeatureFlagEnabled: (flagName): boolean => {
    const { flags } = get();
    const flag = flags?.find(it => it.name === flagName);
    return flag?.isEnabled ?? false;
  }
}));

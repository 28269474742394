import { call, put } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import service from '~/services/api';
import * as API from '~/domain/api';

import { actions } from './duck';
import {
  ProductDetailed,
  ProductQuality,
  RecipientDetailed
} from '~/domain/model';
import ProductGroup from '~/domain/model/ProductGroup';

export function* findMany(payload: number) {
  const URL = `/product/site/${payload}/detailed`;
  const response: AxiosResponse = yield call(service.get, URL);
  const results = response.data as API.ProductDetailed[];
  const productsByID = new Map(
    results.map((value: API.ProductDetailed) => [
      String(value.id),
      new ProductDetailed(value)
    ])
  );
  yield put(actions.update({ productsByID }));
}
export function* findQualities() {
  const URL = `/product/quality`;
  const response: AxiosResponse = yield call(service.get, URL);
  const results = response.data as API.ProductQualityType[];
  const productQualities = results.map(
    productQuality => new ProductQuality(productQuality)
  );
  yield put(actions.updateProductQualities(productQualities));
}
export function* findGroups(payload: number) {
  const response: AxiosResponse = yield call(
    service.get,
    `/product-group/site/${payload}`
  );

  const results = response.data as API.ProductGroup[];
  const productGroups = results.map(
    productGroup => new ProductGroup(productGroup)
  );
  yield put(actions.updateProductGroups(productGroups));
}
export function* findRecipients(payload: number): any {
  const URL = `/product/recipients?siteId=${payload}`;
  const response: any = yield call(service.get, URL);
  if (response?.response?.status >= 400) {
    throw new Error(JSON.stringify(response.response.data));
  } else {
    const results = response.data as string[];
    yield put(actions.updateRecipients(results));
  }
}
export function* findDetailedRecipients(payload: number): any {
  const URL = `/product/recipients/detailed?siteId=${payload}`;
  const response: any = yield call(service.get, URL);
  if (response?.response?.status >= 400) {
    throw new Error(JSON.stringify(response.response.data));
  } else {
    const results = response.data as RecipientDetailed[];
    yield put(actions.updateDetailedRecipients(results));
  }
}

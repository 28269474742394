import moment from 'moment';
import { OrderManagementStatusEnum } from '~/domain/enum/orderManagement.enum';
import { dateIsPastDateOrUndefined } from './util/date';

export const validateOrder = (order: any) => {
  if (
    !order?.billingDate ||
    !order?.deliveryDate ||
    !order?.qualityId ||
    !order?.packingId ||
    !order?.packingQuantity ||
    !order?.price ||
    !order?.productId ||
    !order?.unitQuantity
  ) {
    return false;
  }
  const currentDate = moment().startOf('day');
  const deliveryDate = moment(order?.deliveryDate).startOf('day');
  const billingDate = moment(order?.billingDate).startOf('day');
  if (deliveryDate.isBefore(currentDate) || billingDate.isBefore(currentDate)) {
    return false;
  }
  return true;
};
export const validateOrderTest = (order: any) => {
  if (
    !order?.billingDate ||
    !order?.deliveryDate ||
    !order?.qualityId ||
    !order?.packingId ||
    !order?.packingQuantity ||
    !order?.price ||
    !order?.productId ||
    !order?.unitQuantity
  ) {
    console.log('validateOrderTest > some invalid field', order);
    return false;
  }
  const deliveryDate = moment(order?.deliveryDate).startOf('day');
  const billingDate = moment(order?.billingDate).startOf('day');
  if (
    dateIsPastDateOrUndefined(deliveryDate) ||
    dateIsPastDateOrUndefined(billingDate)
  ) {
    console.log('validateOrderTest > date invalid', order);
    return false;
  }
  console.log('validateOrderTest > valid', order);
  return true;
};

// Define a função de validação de tipos e obrigatoriedade
type FileFieldType = 'TEXTO' | 'NUMÉRICO' | 'MOEDA' | 'DATA';
export const FILE_LAYOUT: {
  name: string;
  key: string;
  type: FileFieldType;
  required: boolean;
}[] = [
  {
    name: 'CLIENTE',
    key: 'customerCode',
    type: 'TEXTO',
    required: true
  },
  { name: 'TP', key: 'tp', type: 'TEXTO', required: false },
  { name: 'CFOP', key: 'cfop', type: 'NUMÉRICO', required: true },
  { name: 'PRODUTO', key: 'productCode', type: 'TEXTO', required: true },
  { name: 'QLDE', key: 'qualityCode', type: 'TEXTO', required: true },
  {
    name: 'TIPO EMBAL.',
    key: 'packingCode',
    type: 'TEXTO',
    required: false
  },
  {
    name: 'QTDE EMBAL',
    key: 'packingQuantity',
    type: 'NUMÉRICO',
    required: true
  },
  {
    name: 'QTDE UNID',
    key: 'unitQuantity',
    type: 'NUMÉRICO',
    required: true
  },
  { name: 'PRECO', key: 'price', type: 'MOEDA', required: false },
  { name: 'PRAZO', key: 'term', type: 'NUMÉRICO', required: false },
  {
    name: 'DATA ENTREGA',
    key: 'deliveryDate',
    type: 'DATA',
    required: true
  },
  {
    name: 'DATA COMERCIAL',
    key: 'billingDate',
    type: 'DATA',
    required: true
  },
  { name: 'LOCAL', key: 'local', type: 'TEXTO', required: false },
  { name: 'PRODUTOR', key: 'siteCode', type: 'TEXTO', required: false },
  {
    name: 'OBSERVAÇÃO',
    key: 'observation',
    type: 'TEXTO',
    required: false
  },
  {
    name: 'DOCUMENTO',
    key: 'document',
    type: 'NUMÉRICO',
    required: false
  },
  { name: 'PEDIDO', key: 'order', type: 'NUMÉRICO', required: false }
];

export const validateField = ({
  value,
  type,
  required
}: {
  value: any;
  type: FileFieldType;
  required: boolean;
}) => {
  if (required && !value) {
    return false;
  }
  if (!required && !value) {
    return true;
  }

  switch (type) {
    case 'NUMÉRICO':
      return !Number.isNaN(parseFloat(value.replace(',', '.')));
    case 'MOEDA':
      return !Number.isNaN(
        parseFloat(value.replace(',', '.').replace('R$', '').trim())
      );
    case 'DATA':
      return moment(value, 'DD/MM/YYYY', true).isValid();
    default:
      return true; // TEXTO e outros tipos são considerados válidos
  }
};

export const formatFieldValue = ({
  fieldValue,
  type
}: {
  fieldValue: any;
  type: FileFieldType;
}) => {
  switch (type) {
    case 'NUMÉRICO':
      return Number(String(fieldValue.replace('.', '').replace(',', '.')));
    case 'MOEDA':
      return String(fieldValue);
    case 'DATA':
      return String(fieldValue);
    default:
      return fieldValue;
  }
};

export const getOrderManagementStatusTitle = (
  statusId: OrderManagementStatusEnum
) => {
  console.log(' ')
  console.log(' ')
  switch (statusId) {
    case OrderManagementStatusEnum.CONFIRMED:
      return 'autoService.table.confirmed';
    case OrderManagementStatusEnum.PARTIAL:
      return 'autoService.table.partial';
    case OrderManagementStatusEnum.CONFIRMED_WITH_CHANGES:
      return 'autoService.table.alteredConfirm';
    case OrderManagementStatusEnum.VERIFYING_OFFERS:
      return 'autoService.table.verifyingOffers';
    case OrderManagementStatusEnum.ORDER_OFFERS:
      return 'autoService.table.orderOffer';
    case OrderManagementStatusEnum.WAITING_ORDER_GENERATION:
      return 'autoService.table.waitingGeneration';
    case OrderManagementStatusEnum.WAITING_PRODUCER:
      return 'autoService.table.waitingProducer';
    case OrderManagementStatusEnum.WAITING_INTERMEDIATOR:
      return 'autoService.table.waitingIntermediator';
    case OrderManagementStatusEnum.REFUSED:
      return 'autoService.table.recused';
    case OrderManagementStatusEnum.CANCELED:
      return 'autoService.table.canceled';
    case OrderManagementStatusEnum.ERRO:
      return 'autoService.table.error';
    case OrderManagementStatusEnum.EXPIRED:
      return 'autoService.table.expired';
    default:
      return '';
  }
};

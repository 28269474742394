import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { KeyLiberationAddParams } from '~/domain/model/KeyLiberation/KeyLiberationAddParams';
import { KeyLiberationChangeStatusParams } from '~/domain/model/KeyLiberation/KeyLiberationChangeStatusParams';
import { KeyLiberationFetchParams } from '~/domain/model/KeyLiberation/KeyLiberationFetchParams';
import { KeyLiberationHistoryFetchParams } from '~/domain/model/KeyLiberation/KeyLiberationHistoryFetchParams';
import { handleDefaultErrorApi } from '~/helpers/util';
import * as keyLiberationService from '~/services/producer/keyLiberation';

export const keyLiberationFetch = createAsyncThunk(
  'keyLiberation/keyLiberationFetch',
  async (params: KeyLiberationFetchParams, { rejectWithValue }) => {
    try {
      const response = await keyLiberationService.keyLiberationFetchRequest(
        params
      );
      return response.data;
    } catch (error) {
      handleDefaultErrorApi(error as AxiosError);
      return rejectWithValue([]);
    }
  }
);

export const keyLiberationHistoryFetch = createAsyncThunk(
  'keyLiberation/keyLiberationHistoryFetch',
  async (params: KeyLiberationHistoryFetchParams, { rejectWithValue }) => {
    try {
      const response =
        await keyLiberationService.keyLiberationHistoryFetchRequest(params);
      return response.data;
    } catch (error) {
      handleDefaultErrorApi(error as AxiosError);
      return rejectWithValue([]);
    }
  }
);

export const keyLiberationAdd = createAsyncThunk(
  'keyLiberation/keyLiberationAdd',
  async (params: KeyLiberationAddParams, { rejectWithValue }) => {
    try {
      const response = await keyLiberationService.keyLiberationAddRequest(
        params
      );
      return response.data;
    } catch (error) {
      handleDefaultErrorApi(error as AxiosError);
      return rejectWithValue({});
    }
  }
);

export const keyLiberationChangeStatus = createAsyncThunk(
  'keyLiberation/keyLiberationChangeStatus',
  async (id: number, { rejectWithValue }) => {
    try {
      const response =
        await keyLiberationService.keyLiberationChangeStatusRequest(id);
      return response.data;
    } catch (error) {
      handleDefaultErrorApi(error as AxiosError);
      return rejectWithValue([]);
    }
  }
);

export const keyLiberationChangeAllStatus = createAsyncThunk(
  'keyLiberation/keyLiberationChangeAllStatus',
  async (params: KeyLiberationChangeStatusParams, { rejectWithValue }) => {
    try {
      const response =
        await keyLiberationService.keyLiberationChangeAllStatusRequest(params);
      return response.data;
    } catch (error) {
      handleDefaultErrorApi(error as AxiosError);
      return rejectWithValue([]);
    }
  }
);

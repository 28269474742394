import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PurchaseManagement,
  TransactionStatus,
  CartStatus,
  HistoryPurchaseManagement
} from '~/domain/model';

export type EcommerceManagementState = {
  list: PurchaseManagement[];
  transactionStatusList: TransactionStatus[];
  cartStatusList: CartStatus[];
  historyPurchaseManagement: HistoryPurchaseManagement | null;
};

export type Update = {
  list?: PurchaseManagement[];
  transactionStatusList?: TransactionStatus[];
  cartStatusList?: CartStatus[];
  historyPurchaseManagement?: HistoryPurchaseManagement;
};

export type CancelPurchaseManagement = {
  transactionId?: number;
  reasonCancellation?: string;
};

export type FetchEcommerceManagement = {
  siteId?: number;
  customerId?: number;
  transaction?: string;
  type?: string;
  transactionStatus?: string;
  cartStatus?: string;
  deliveryDateStart?: string;
  deliveryDateEnd?: string;
  billingDateStart?: string;
  billingDateEnd?: string;
  productId?: number;
  pageNumber?: number;
  pageSize?: number;
};

const prefix = '@ecommerceManagement';

export const initialState: EcommerceManagementState = {
  list: [],
  transactionStatusList: [],
  cartStatusList: [],
  historyPurchaseManagement: null
};

const ecommerceManagement = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: state => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = { ...initialState };
    },
    clearHistory: state => {
      state.historyPurchaseManagement = initialState.historyPurchaseManagement;
    },
    update: (state, action: PayloadAction<Update>) => {
      if (action.payload.list) {
        state.list = action.payload.list;
      }
      if (action.payload.cartStatusList) {
        state.cartStatusList = action.payload.cartStatusList;
      }
      if (action.payload.transactionStatusList) {
        state.transactionStatusList = action.payload.transactionStatusList;
      }
      if (action.payload.historyPurchaseManagement) {
        state.historyPurchaseManagement =
          action.payload.historyPurchaseManagement;
      }
    }
  }
});

export const actions = ecommerceManagement.actions;
export const reducer = ecommerceManagement.reducer;

const caseReducers = ecommerceManagement.caseReducers;
export const Types = Object.freeze({
  clear: `${prefix}/${caseReducers.clear.name}`,
  update: `${prefix}/${caseReducers.update.name}`
});

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExpeditionEdit } from '~/domain/api';
import { DefaultPagination, ExpeditionHistory } from '~/domain/model';
import Expedition from '~/domain/model/Expedition/Expedition';

import {
  createExpeditions,
  deleteExpedition,
  editExpeditions,
  fetchExpeditions,
  fetchExpeditionsBySiteId,
  fetchExpeditionsHistory
} from './apiCalls';

export interface ExpeditionsState {
  expeditions: Expedition[];
  expeditionsBySiteId: Expedition[];
  expeditionsPagination: DefaultPagination;
  totalexpedition: number;
  expeditionEdit: ExpeditionEdit[];
  expeditionHistory: ExpeditionHistory[];
  closeExpeditionHistoryModal: boolean;
  closeAddExpeditionModal: boolean;
  closeEditExpeditionModal: boolean;
  submitFormFilter: boolean;
}

const initialState = {
  expeditions: [] as Expedition[],
  expeditionsBySiteId: [],
  expeditionsPagination: { PageSize: 100, PageNumber: 1 } as DefaultPagination,
  totalexpedition: 0,
  expeditionHistory: [],
  expeditionEdit: [],
  closeAddExpeditionModal: false,
  closeEditExpeditionModal: false,
  closeExpeditionHistoryModal: false,
  siteAllowsKlokHoard: false,
  submitFormFilter: false
} as ExpeditionsState;

export const prefix = 'expedition';

const expeditionSlice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setExpeditionsPagination: (
      state,
      action: PayloadAction<DefaultPagination>
    ) => {
      state.expeditionsPagination = action.payload;
    },
    setTotalExpeditions: (state, action: PayloadAction<number>) => {
      state.totalexpedition = action.payload;
    },
    setExpeditions: (state, action: PayloadAction<Expedition[]>) => {
      state.expeditions = action.payload;
    },
    setCloseModal: (state, action: PayloadAction<boolean>) => {
      state.closeAddExpeditionModal = action.payload;
    },
    setCloseEditModal: (state, action: PayloadAction<boolean>) => {
      state.closeEditExpeditionModal = action.payload;
    },
    setExpedition: state => {
      state.expeditionsBySiteId = [];
    },
    setSubmitFormFilter: (state, action: PayloadAction<boolean>) => {
      state.submitFormFilter = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchExpeditions.fulfilled, (state, action) => {
      state.totalexpedition = action.payload.totalCount;
      state.expeditions = action.payload;
    });
    builder.addCase(fetchExpeditionsBySiteId.fulfilled, (state, action) => {
      state.expeditionsBySiteId = action.payload;
    });
    builder.addCase(createExpeditions.fulfilled, state => {
      state.closeAddExpeditionModal = true;
      state.submitFormFilter = true;
    });
    builder.addCase(
      editExpeditions.fulfilled,
      (state, _action: PayloadAction<Expedition>) => {
        state.closeEditExpeditionModal = true;
        state.submitFormFilter = true;
      }
    );
    builder.addCase(
      fetchExpeditionsHistory.fulfilled,
      (state, action: PayloadAction<ExpeditionHistory[]>) => {
        state.expeditionHistory = action.payload;
      }
    );
    builder.addCase(
      deleteExpedition.fulfilled,
      (state, action: PayloadAction<number>) => {
        state.expeditions = state.expeditions.filter(
          expedition => expedition.id !== action.payload
        );
      }
    );
  }
});

export const actions = expeditionSlice.actions;
export const reducer = expeditionSlice.reducer;

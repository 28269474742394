import * as Effects from 'redux-saga/effects';
import { CreateInit } from '~/store/modules/_core/customersGroup/duck';
import { actions, Types } from './duck';
import { Action } from '~/helpers/util/reducers';
import { APIError } from '~/domain/api/errors';
import * as CustomersGroupService from '~/store/modules/_core/customersGroup/service';

const call: any = Effects.call;
const put: any = Effects.put;
const takeLatest: any = Effects.takeLatest;

export function* createSaga(action: Action<CreateInit>) {
  try {
    const { id, description, customersIds, siteId } = action.payload;
    yield call(CustomersGroupService.create, {
      id,
      description,
      customersIds,
      siteId
    });
    yield put(actions.createSuccess());
  } catch (error) {
    if (error instanceof APIError) {
      yield put(actions.createFailure({ errorMessage: error.message }));
    } else {
      yield put(actions.createFailure({}));
    }
  }
}

export default [takeLatest(Types.CREATE_INIT, createSaga)];

import { OfferChannelListState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.ui.offerChannelList as OfferChannelListState;
};

export const getIsLoading = createSelector(
  [getRoot],
  state => state.fetchStatus === 'fetching'
);

export const getIsFetchStatusIdle = createSelector(
  [getRoot],
  state => state.fetchStatus === 'idle'
);

export const getPageNumber = createSelector([getRoot], state => {
  return state.pageNumber;
});

export const getPageSize = createSelector([getRoot], state => state.pageSize);

export const getTotalCount = createSelector(
  [getRoot],
  state => state.totalCount
);

export const getPageNumberHistory = createSelector([getRoot], state => {
  return state.pageNumberHistory;
});

export const getPageSizeHistory = createSelector(
  [getRoot],
  state => state.pageSizeHistory
);

export const getTotalCountHistory = createSelector(
  [getRoot],
  state => state.totalCountHistory
);

export const getShowOfferChannelModalEdit = createSelector(
  [getRoot],
  state => state.showOfferChannelEditModal
);

export const getShowHistoryModal = createSelector(
  [getRoot],
  state => state.showHistoryModal
);

export const getOfferChannelToEditOrHistory = createSelector(
  [getRoot],
  state => state.offerChannelToEditOrHistory
);

export const getUpdateStatus = createSelector(
  [getRoot],
  state => state.offerChannelUpdateStatus
);

export const getHistoryStatus = createSelector(
  [getRoot],
  state => state.historyStatus
);

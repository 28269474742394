import { AxiosError, AxiosResponse } from 'axios';
import FormFilterOrdersGfp from '~/@core/domain/filters/orderGfp/FilterOrderGfp';
import OrderGfp from '~/@core/domain/model/orderGfp/OrderGfp';
import { apiGfp } from '../config';

export const fetchOrdersGfp = (params: FormFilterOrdersGfp) => {
  return new Promise<OrderGfp[]>((resolve, reject) => {
    let url = `/tag/orders?siteId=${params.siteId}`;
    if (params.expeditionId) {
      url += `&expeditionId=${params.expeditionId}`;
    }
    apiGfp
      .get<void, AxiosResponse<OrderGfp[]>>(url)
      .then((response: AxiosResponse<OrderGfp[]>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type StatusRequest = 'idle' | 'pending' | 'success' | 'failure';

export type AnnouncementListState = {
  requestAnnouncementListStatus: StatusRequest;
  activeOrInactiveStatus: StatusRequest;
  errorMessage?: string;
  pageNumber: number;
  pageSize: number;
  totalCount: number;
};

export type ParamRequestAnnouncementList = {
  description?: string;
  validity?: string;
  role?: number;
  status?: string;
  startDate?: string;
  endDate?: string;
  pageNumber?: number;
  pageSize?: number;
};

export type ParamChangePagination = {
  pageNumber: number;
  pageSize: number;
};

export type ParamChangeStatus = {
  id: number;
  isActive: boolean;
};

export type ParamRequestAnnouncementListSuccess = {
  pageNumber?: number;
  pageSize?: number;
  totalCount: number;
};

export type ParamRequestActiveOrInactiveStatus = {
  id: number;
  isActive: boolean;
};

export type Error = {
  errorMessage?: string;
};

const prefix = '@announcementList';

export const initialState: AnnouncementListState = {
  requestAnnouncementListStatus: 'idle',
  activeOrInactiveStatus: 'idle',
  pageNumber: 1,
  pageSize: 10,
  totalCount: 0
};

const announcementList = createSlice({
  name: prefix,
  initialState,
  reducers: {
    requestAnnouncementList: (
      state,
      action: PayloadAction<ParamRequestAnnouncementList>
    ) => {
      state.requestAnnouncementListStatus = 'pending';
      let { pageNumber, pageSize } = action.payload;
      if (!pageNumber) {
        pageNumber = initialState.pageNumber;
        state.pageNumber = pageNumber;
      }
      if (!pageSize) {
        pageSize = initialState.pageSize;
        state.pageSize = pageSize;
      }
    },
    requestAnnouncementListSuccess: (
      state,
      action: PayloadAction<ParamRequestAnnouncementListSuccess>
    ) => {
      state.requestAnnouncementListStatus = 'success';
      state.totalCount = action.payload.totalCount;
      state.pageSize = action.payload.pageSize ?? 10;
      state.pageNumber = action.payload.pageNumber ?? 1;
    },
    requestAnnouncementListFailure: (state, action: PayloadAction<Error>) => {
      state.requestAnnouncementListStatus = 'failure';
      if (action.payload.errorMessage) {
        state.errorMessage = action.payload.errorMessage;
      }
    },
    activeOrInactiveAnnouncement: (
      state,
      _action: PayloadAction<ParamChangeStatus>
    ) => {
      state.activeOrInactiveStatus = 'pending';
    },
    activeOrInactiveAnnouncementSuccess: state => {
      state.activeOrInactiveStatus = 'success';
    },
    activeOrInactiveAnnouncementFailure: state => {
      state.activeOrInactiveStatus = 'failure';
    }
  }
});

export const actions = announcementList.actions;
export const reducer = announcementList.reducer;

const caseReducers = announcementList.caseReducers;

export const Types = Object.freeze({
  requestAnnouncementList: `${prefix}/${caseReducers.requestAnnouncementList.name}`,
  requestAnnouncementListSuccess: `${prefix}/${caseReducers.requestAnnouncementListSuccess.name}`,
  requestAnnouncementListFailure: `${prefix}/${caseReducers.requestAnnouncementListFailure.name}`,
  activeOrInactiveAnnouncement: `${prefix}/${caseReducers.activeOrInactiveAnnouncement.name}`,
  activeOrInactiveAnnouncementSuccess: `${prefix}/${caseReducers.activeOrInactiveAnnouncementSuccess.name}`,
  activeOrInactiveAnnouncementFailure: `${prefix}/${caseReducers.activeOrInactiveAnnouncementFailure.name}`
});

import axios, { AxiosInstance, AxiosResponse } from 'axios';
import MockAdapter from 'axios-mock-adapter';
import moment from 'moment';
import { endpoints } from '~/constants/endpoints';
import * as M from '~/domain/model';
import apiProducer from '../api';

// Crie uma instância do axios para as chamadas que você deseja aplicar o mock
// const api: AxiosInstance = axios.create(apiProducer.defaults);

// // Crie o MockAdapter para a instância do axios do mock
// const mock = new MockAdapter(api, { onNoMatch: 'throwException' });

// export const harvestingOrderMockedValues: {
//   harvestingOrdersByProducts: any;
//   harvestingOrdersByClients: M.HarvestingOrderByClients[];
// } = {
//   harvestingOrdersByProducts: [
//     {
//       id: 1,
//       productDescription: 'PRODUCT TEST',
//       productCode: '01191.011.000.00.00',
//       quality: 'A1',
//       disposablePackage: 24,
//       returnablePackage: 12,
//       totalPackage: 36,
//       totalAmount: 423,
//       customerId: 17,
//       colorId: 71,
//       deliveryDate: '2023-06-10',
//       expeditionId: 1,
//       printStatus: 'UNPRINTED',
//       productCategoryId: 3,
//       productGroupId: 697,
//       productId: 35768,
//       productRecipient: 'C21',
//       siteId: 241,
//       expandRows: [
//         {
//           id: 1,
//           client: 'GARON',
//           clientCode: 1,
//           packages: '070',
//           packageType: 'PORTA VASO P11.',
//           qe: 12,
//           qpe: 12,
//           amount: 144,
//           observation: 'Apenas vermelhas e amarelas.',
//           internalObservation: 'Internal Obs'
//         },
//         {
//           id: 2,
//           client: 'CARREFOUR',
//           clientCode: 299,
//           packages: '070',
//           packageType: 'PORTA VASO P11.',
//           qe: 12,
//           qpe: 12,
//           amount: 144,
//           observation: 'Apenas vermelhas e amarelas.',
//           internalObservation: 'Internal Obs'
//         }
//       ]
//     },
//     {
//       id: 2,
//       productDescription: 'PRODUCT TEST',
//       productCode: '01191.011.000.00.00',
//       quality: 'A1',
//       disposablePackage: 24,
//       returnablePackage: 12,
//       totalPackage: 36,
//       totalAmount: 423,
//       customerId: 17,
//       colorId: 71,
//       deliveryDate: '2023-06-10',
//       expeditionId: 1,
//       printStatus: 'UNPRINTED',
//       productCategoryId: 3,
//       productGroupId: 697,
//       productId: 35768,
//       productRecipient: 'C21',
//       siteId: 241,
//       expandRows: [
//         {
//           id: 1,
//           client: 'GARON',
//           clientCode: 1,
//           packages: '070',
//           packageType: 'PORTA VASO P11.',
//           qe: 12,
//           qpe: 12,
//           amount: 144,
//           observation: 'Apenas vermelhas e amarelas.',
//           internalObservation: 'Internal Obs'
//         },
//         {
//           id: 2,
//           client: 'CARREFOUR',
//           clientCode: 299,
//           packages: '070',
//           packageType: 'PORTA VASO P11.',
//           qe: 12,
//           qpe: 12,
//           amount: 144,
//           observation: 'Apenas vermelhas e amarelas.',
//           internalObservation: 'Internal Obs'
//         }
//       ]
//     }
//   ],
//   harvestingOrdersByClients: [
//     {
//       id: 1,
//       client: 'Gabriel Neto',
//       disposablePackage: 24,
//       returnablePackage: 12,
//       totalPackage: 36,
//       totalAmount: 423,
//       customerId: 17,
//       colorId: 71,
//       deliveryDate: '2023-06-10',
//       expeditionId: 1,
//       printStatus: 'UNPRINTED',
//       productCategoryId: 3,
//       productGroupId: 697,
//       productId: 35768,
//       productRecipient: 'C21',
//       quality: 'A1',
//       siteId: 241,
//       expandRows: [
//         {
//           amount: 500,
//           id: 1,
//           productDescription:
//             'KALANCHOE P11 ASDASDASDASDASDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD',
//           productCode: '01191.011.000.00.00',
//           qlt: 'A1',
//           packages: 666,
//           packageType: 'PORTA VASO P11.',
//           observation: 'Apenas vermelhas e amarelas.',
//           qe: 50,
//           qpe: 10,
//           quality: 'A1'
//         },
//         {
//           amount: 100,
//           id: 2,
//           productDescription: 'KALANCHOE P11',
//           productCode: '01191.011.000.00.00',
//           qlt: 'A1',
//           packages: 999,
//           internalObservation: 'Internal Obs 2',
//           observation: 'Apenas vermelhas e amarelas.',
//           packageType: 'PORTA VASO P11.',
//           qe: 10,
//           qpe: 10,
//           quality: 'A1'
//         },
//         {
//           amount: 100,
//           id: 3,
//           internalObservation: 'Internal Obs 3',
//           observation: 'Apenas vermelhas e amarelas.',
//           packages: '021',
//           packageType: 'KALANCHOE DOBRADO P11',
//           productCode: 112789,
//           productDescription: 'Apenas vermelhas e amarelas.',
//           qe: 10,
//           qpe: 10,
//           quality: 'A1'
//         }
//       ]
//     },
//     {
//       id: 2,
//       client: 'CARREFOUR COMERCIO E INDUSTRIA LTDA',
//       disposablePackage: 24,
//       returnablePackage: 12,
//       totalPackage: 36,
//       totalAmount: 423,
//       customerId: 134,
//       colorId: 82,
//       deliveryDate: '2023-06-20',
//       expeditionId: 2,
//       printStatus: 'UNPRINTED',
//       productCategoryId: 2,
//       productGroupId: 567,
//       productId: 14835,
//       productRecipient: 'C21',
//       quality: 'A2',
//       siteId: 233,
//       expandRows: [
//         {
//           amount: 400,
//           id: 1,
//           internalObservation: 'Internal Obs 1',
//           observation: 'Apenas vermelhas e amarelas.',
//           packages: '021',
//           packageType: 'PORTA VASO P11.',
//           productCode: 212346,
//           productDescription: 'KALANCHOE P11',
//           qe: 40,
//           qpe: 10,
//           quality: 'A2'
//         }
//       ]
//     },
//     {
//       id: 3,
//       client: 'CARREFOUR COMERCIO E INDUSTRIA LTDA',
//       disposablePackage: 24,
//       returnablePackage: 12,
//       totalPackage: 36,
//       totalAmount: 423,
//       customerId: 134,
//       colorId: 82,
//       deliveryDate: '2023-06-20',
//       expeditionId: 2,
//       printStatus: 'UNPRINTED',
//       productCategoryId: 2,
//       productGroupId: 567,
//       productId: 14835,
//       productRecipient: 'C21',
//       quality: 'A2',
//       siteId: 233,
//       expandRows: [
//         {
//           amount: 500,
//           id: 1,
//           internalObservation: 'Internal Obs 1',
//           observation: 'Apenas vermelhas e amarelas.',
//           packages: '021',
//           packageType: 'PORTA VASO P11.',
//           productCode: 112345,
//           productDescription: 'KALANCHOE P11',
//           qe: 50,
//           qpe: 10,
//           quality: 'A1'
//         },
//         {
//           amount: 100,
//           id: 2,
//           internalObservation: 'Internal Obs 2',
//           observation: 'Apenas vermelhas e amarelas.',
//           packages: '814',
//           packageType: 'PORTA VASO P11.',
//           productCode: 112456,
//           productDescription: 'PIMENTA P11',
//           qe: 10,
//           qpe: 10,
//           quality: 'A1'
//         }
//       ]
//     },
//     {
//       id: 4,
//       client: 'Gabriel Neto',
//       disposablePackage: 24,
//       returnablePackage: 12,
//       totalPackage: 36,
//       totalAmount: 423,
//       customerId: 17,
//       colorId: 71,
//       deliveryDate: '2023-06-20',
//       expeditionId: 1,
//       printStatus: 'UNPRINTED',
//       productCategoryId: 3,
//       productGroupId: 697,
//       productId: 35768,
//       productRecipient: 'C21',
//       quality: 'A1',
//       siteId: 241,
//       expandRows: [
//         {
//           amount: 500,
//           id: 1,
//           internalObservation: 'Internal Obs 1',
//           observation: 'Apenas vermelhas e amarelas.',
//           packages: '021',
//           packageType: 'PORTA VASO P11.',
//           productCode: 112345,
//           productDescription: 'KALANCHOE P11',
//           qe: 50,
//           qpe: 10,
//           quality: 'A1'
//         },
//         {
//           amount: 100,
//           id: 2,
//           internalObservation: 'Internal Obs 2',
//           observation: 'Apenas vermelhas e amarelas.',
//           packages: '814',
//           packageType: 'PORTA VASO P11.',
//           productCode: 112456,
//           productDescription: 'PIMENTA P11',
//           qe: 10,
//           qpe: 10,
//           quality: 'A1'
//         },
//         {
//           amount: 100,
//           id: 3,
//           internalObservation: 'Internal Obs 3',
//           observation: 'Apenas vermelhas e amarelas.',
//           packages: '021',
//           packageType: 'KALANCHOE DOBRADO P11',
//           productCode: 112789,
//           productDescription: 'Apenas vermelhas e amarelas.',
//           qe: 10,
//           qpe: 10,
//           quality: 'A1'
//         }
//       ]
//     },
//     {
//       id: 5,
//       client: 'CARREFOUR COMERCIO E INDUSTRIA LTDA',
//       disposablePackage: 24,
//       returnablePackage: 12,
//       totalPackage: 36,
//       totalAmount: 423,
//       customerId: 134,
//       colorId: 82,
//       deliveryDate: '2023-06-10',
//       expeditionId: 2,
//       printStatus: 'UNPRINTED',
//       productCategoryId: 2,
//       productGroupId: 567,
//       productId: 14835,
//       productRecipient: 'C21',
//       quality: 'A2',
//       siteId: 233,
//       expandRows: [
//         {
//           amount: 400,
//           id: 1,
//           internalObservation: 'Internal Obs 1',
//           observation: 'Apenas vermelhas e amarelas.',
//           packages: '021',
//           packageType: 'PORTA VASO P11.',
//           productCode: 212346,
//           productDescription: 'KALANCHOE P11',
//           qe: 40,
//           qpe: 10,
//           quality: 'A2'
//         }
//       ]
//     }
//   ]
// };

// mock.onGet(endpoints.harvestingOrder.base).reply(config => {
//   const {
//     DeliveryDate,
//     SiteId,
//     CustomerId,
//     ColorId,
//     ExpeditionId,
//     PrintStatus,
//     ProductCategoryId,
//     ProductGroupId,
//     ProductId,
//     ProductQuality,
//     ProductRecipient,
//     PageSize,
//     PageNumber
//   }: M.HarvestingOrderFetchParams = config.params;
//   const startIndex = (PageNumber - 1) * PageSize;
//   const endIndex = startIndex + PageSize;

//   const filterItems = (items: any[]) => {
//     return items.filter(item => {
//       if (ColorId && item.colorId !== ColorId) {
//         return false;
//       }
//       if (CustomerId && item.customerId !== CustomerId) {
//         return false;
//       }
//       if (DeliveryDate) {
//         const itemDeliveryDate = moment(item.deliveryDate).startOf('day');
//         const filterDeliveryDate = moment(DeliveryDate).startOf('day');
//         if (!itemDeliveryDate.isSame(filterDeliveryDate, 'day')) {
//           return false;
//         }
//       }
//       if (ExpeditionId && item.expeditionId !== ExpeditionId) {
//         return false;
//       }
//       if (PrintStatus && item.printStatus !== PrintStatus) {
//         return false;
//       }
//       if (ProductCategoryId && item.productCategoryId !== ProductCategoryId) {
//         return false;
//       }
//       if (ProductGroupId && item.productGroupId !== ProductGroupId) {
//         return false;
//       }
//       if (ProductId && item.productId !== ProductId) {
//         return false;
//       }
//       // if (ProductIds && !ProductIds.includes(item.id)) {
//       //   return false;
//       // }
//       if (ProductRecipient && item.productRecipient !== ProductRecipient) {
//         return false;
//       }
//       if (ProductQuality && item.quality !== ProductQuality) {
//         return false;
//       }
//       if (SiteId && item.siteId !== SiteId) {
//         return false;
//       }
//       return true;
//     });
//   };

//   const harvestingOrdersByProducts = filterItems(
//     harvestingOrderMockedValues.harvestingOrdersByProducts
//   ).slice(startIndex, endIndex);

//   const harvestingOrdersByClients = filterItems(
//     harvestingOrderMockedValues.harvestingOrdersByClients
//   ).slice(startIndex, endIndex);

//   const data: M.HarvestingOrderFetchResponse = {
//     harvestingOrdersByProducts,
//     totalHarvestingOrdersByProducts:
//       harvestingOrderMockedValues.harvestingOrdersByProducts.length,
//     harvestingOrdersByClients,
//     totalHarvestingOrdersByClients:
//       harvestingOrderMockedValues.harvestingOrdersByClients.length
//   };

//   return [200, data];
// });

export const findHarvestingOrdersByCustomer = async ({
  PageSize,
  PageNumber,
  ...params
}: M.HarvestingOrderFetchParams) => {
  let url = `${endpoints.harvestingOrder.base}/customer`;
  const entries = Object.entries(params);

  url += `?PageSize=${PageSize}&PageNumber=${PageNumber}`;
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of entries) {
    if (value !== undefined && value !== null) {
      url += `&${key}=${value}`;
    }
  }
  const response = await apiProducer.get(url);
  return response.data;
};
export const findHarvestingOrdersByProduct = async ({
  PageSize,
  PageNumber,
  ...params
}: M.HarvestingOrderFetchParams) => {
  let url = `${endpoints.harvestingOrder.base}/product`;
  const entries = Object.entries(params);

  url += `?PageSize=${PageSize}&PageNumber=${PageNumber}`;
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of entries) {
    if (value !== undefined && value !== null) {
      url += `&${key}=${value}`;
    }
  }
  const response = await apiProducer.get(url);
  return response.data;
};

export const generateListToPrint = async (body: {
  siteId: number;
  interOrderIds: number[];
  volOrderIds: number[];
}) => {
  return apiProducer.post(`${endpoints.harvestingOrder.base}/insert`, body);
};

export const getDataToPrint = async (
  listId: number[],
  viewByClient: boolean
) => {
  let params = '';

  listId.forEach((id, index) => {
    params += `${index === 0 ? '?' : '&'}Ids=${id}`;
  });

  params += `&ViewByClients=${viewByClient}`;

  return apiProducer.get(`${endpoints.harvestingOrder.base}/print${params}`);
};

export const getAllPrintData = async (siteId: number) => {
  const response = await apiProducer.get(
    `${endpoints.harvestingOrder.base}/${siteId}`
  );
  return response.data;
};

export const updateHarvestingOrdersRequest = async (
  params: {
    itemId: number;
    origin: string;
    internalObservation: string;
  }[]
) => {
  return apiProducer.put<void, AxiosResponse<void>>(
    `${endpoints.harvestingOrder.base}/internalObservation`,
    params
  );
};

export const DeletePrintListByIds = async (body: number[]) => {
  await apiProducer.request({
    method: 'delete',
    url: `${endpoints.harvestingOrder.base}`,
    data: body
  });
};

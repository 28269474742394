import { EcommerceManagementState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.core.ecommerceManagement as EcommerceManagementState;
};

export const getList = createSelector([getRoot], state => {
  return state.list;
});

export const getCartStatusList = createSelector([getRoot], state => {
  return state.cartStatusList;
});

export const getTransactionStatusList = createSelector([getRoot], state => {
  return state.transactionStatusList;
});

export const getHistoryPurchaseManagement = createSelector([getRoot], state => {
  return state.historyPurchaseManagement;
});

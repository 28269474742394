import { createSelector } from 'reselect';

import { AnnouncementsListState } from '.';

export const getRoot = ({
  announcementsList
}: {
  announcementsList: AnnouncementsListState;
}) => {
  return announcementsList;
};

export const getAnnouncementsListState = createSelector(
  [getRoot],
  state => state
);

export const getPagination = createSelector([getRoot], state => {
  return state.pagination;
});

import styled from 'styled-components';
import { Carousel, Col, Collapse, Modal } from 'antd';

export const Container = styled.div``;

export const ModalHelp = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-footer {
    display: none;
  }
`;

export const ModalLeft = styled(Col)`
  background-color: #f78e1e;
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 320px) {
    display: none;
  }
`;
export const ModalLogo = styled.img`
  width: 131px;
  height: 49px;
  object-fit: contain;
`;
export const Title = styled.h1`
  color: #f78e1e;
  font-size: 24px;
  font-weight: 500;
  border-bottom: 4px solid #f78e1e;
`;

export const SubTitle = styled.h1`
  color: #f78e1e;
  font-size: 16px;
  font-weight: 500;
  margin-left: 15px;
`;

export const CollapseModal = styled(Collapse)`
  background-color: #fff;
  border: none;
  .ant-collapse-header {
    font-size: 14px;
    font-weight: 400;
    color: #353839;
  }
  .ant-collapse-item {
    border: none;
  }
`;

export const CarouselModal = styled(Carousel)`
  .slick-dots button {
    background-color: #f78e1e !important;
    border-radius: 100% !important;
    width: 5px !important;
    height: 5px !important;
    left: 0 !important;
  }
`;

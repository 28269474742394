import { combineReducers } from 'redux';
import { actions as competenceRegistrationActions } from './CompetenceRegistration';
import * as competenceRegistratioSelectors from './CompetenceRegistration/selectors';
import * as core from './_core';
import * as ui from './_ui';
import { actions as announcementsListActions } from './announcementsList';
import * as announcementsListSelectors from './announcementsList/selectors';
import { actions as autoServiceActions } from './autoService';
import * as autoServiceSelectors from './autoService/selectors';
import { actions as autoserviceClassificationActions } from './autoserviceClassification';
import * as autoserviceClassificationSelectors from './autoserviceClassification/selectors';
import { actions as autoserviceDefinitions } from './autoserviceDefinitions';
import * as autoserviceDefinitionsSelectors from './autoserviceDefinitions/selectors';
import { actions as autoserviceRequestsActions } from './autoserviceRequests';
import * as autoserviceRequestsSelectors from './autoserviceRequests/selectors';
import { actions as biDashboardActions } from './bidashboards';
import * as biDashboardSelectors from './bidashboards/selectors';
import { actions as directedOfferActions } from './directedOffers';
import * as directedOffersSelectors from './directedOffers/selectors';
import { actions as ecommerceManagementActions } from './ecommerceManagement';
import * as ecommerceManagementSelectors from './ecommerceManagement/selectors';
import { actions as expeditionActions } from './expeditions';
import * as expeditionsSelectors from './expeditions/selectors';
import { actions as harvestingOrdersActions } from './harvestingOrders';
import * as harvestingOrdersSelectors from './harvestingOrders/selectors';
import { actions as keyLiberationActions } from './keyLiberation';
import * as keyLiberationSelectors from './keyLiberation/selectors';
import { actions as meActions } from './me';
import * as meSelectors from './me/selectors';
import { actions as ordersActions } from './orders';
import * as ordersSelectors from './orders/selectors';
import { actions as plpActions } from './plp';
import * as plpSelectors from './plp/selectors';
import { actions as productCategoriesActions } from './productCategories';
import * as productCategoriesSelectors from './productCategories/selectors';
import { actions as productPreferencesActions } from './productPreferences';
import * as productPreferencesSelectors from './productPreferences/selectors';
import { productionPlanningActions } from './productionPlanning';
import * as productionPlanningSelectors from './productionPlanning/selectors';
import { actions as propertyActions } from './propertyThunk';
import * as propertySelectors from './propertyThunk/selectors';
import * as salesReportEcommerceSelectors from './salesReportEcommerce';
import { actions as salesReportEcommerceActions } from './salesReportEcommerce';
import { actions as shippingCompaniesActions } from './shippingCompanies';
import * as shippingCompaniesSelectors from './shippingCompanies/selectors';
import { actions as stockActions } from './stocks';
import * as stocksSelectors from './stocks/selectors';
import { actions as tagPrintingActions } from './tagPrinting';
import * as tagPrintingSelectors from './tagPrinting/selectors';
import { actions as technicalSpecificationActions } from './technicalSpecification';
import * as technicalSpecificationSelectors from './technicalSpecification/selectors';
import { actions as unpublishedActions } from './unpublished';
import * as unpublishedSelectors from './unpublished/selectors';
import { actions as volLkpActions } from './volLkp';
import * as volLkpSelectors from './volLkp/selectors';
import { actions as volOffersActions } from './volOffers';
import * as volOffersSelectors from './volOffers/selectors';

export const reducer = combineReducers({
  core: core.reducer,
  ui: ui.reducer
});

export const actions = Object.freeze({
  announcementsList: announcementsListActions,
  core: core.actions,
  competenceRegistration: competenceRegistrationActions,
  ui: ui.actions,
  me: meActions,
  autoService: autoServiceActions,
  autoserviceClassification: autoserviceClassificationActions,
  autorserviceDefinitions: autoserviceDefinitions,
  autoserviceRequests: autoserviceRequestsActions,
  directedOffers: directedOfferActions,
  expeditions: expeditionActions,
  productCategories: productCategoriesActions,
  productionPlanning: productionPlanningActions,
  technicalSpecification: technicalSpecificationActions,
  stocks: stockActions,
  shippingCompanies: shippingCompaniesActions,
  biDashboards: biDashboardActions,
  productPreferences: productPreferencesActions,
  ecommerceManagement: ecommerceManagementActions,
  keyLiberation: keyLiberationActions,
  orders: ordersActions,
  plp: plpActions,
  salesReportEcommerce: salesReportEcommerceActions,
  volLkp: volLkpActions,
  unpublished: unpublishedActions,
  volOffers: volOffersActions,
  property: propertyActions,
  harvestingOrders: harvestingOrdersActions,
  tagPrinting: tagPrintingActions
});

export const selectors = Object.freeze({
  announcementsList: announcementsListSelectors,
  core: core.selectors,
  competenceRegistration: competenceRegistratioSelectors,
  ui: ui.selectors,
  me: meSelectors,
  autoService: autoServiceSelectors,
  autoserviceClassification: autoserviceClassificationSelectors,
  autoserviceRequests: autoserviceRequestsSelectors,
  autoserviceDefinitions: autoserviceDefinitionsSelectors,
  directedOffers: directedOffersSelectors,
  expeditions: expeditionsSelectors,
  productCategories: productCategoriesSelectors,
  productionPlanning: productionPlanningSelectors,
  technicalSpecification: technicalSpecificationSelectors,
  stocks: stocksSelectors,
  shippingCompanies: shippingCompaniesSelectors,
  biDashboards: biDashboardSelectors,
  productPreferences: productPreferencesSelectors,
  ecommerceManagement: ecommerceManagementSelectors,
  keyLiberation: keyLiberationSelectors,
  orders: ordersSelectors,
  plp: plpSelectors,
  salesReportEcommerce: salesReportEcommerceSelectors,
  volLkp: volLkpSelectors,
  unpublished: unpublishedSelectors,
  volOffers: volOffersSelectors,
  property: propertySelectors,
  harvestingOrders: harvestingOrdersSelectors,
  tagPrinting: tagPrintingSelectors
});

import { format } from 'date-fns';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Content } from '~/components/organisms/BiDashboard/ViewBiDashboard/styles';
import {
  BIDashboardContext,
  BIDashboardContextProvider
} from '~/context/BIDashboardContext';
import * as M from '~/domain/model';
import { setSitesAnalyticsToState } from '~/services/producer/Site';
import { alertRequest } from '~/store/modules/alert/actions';
import { fetchBiDashboard } from '~/store/modules/bidashboards/apiCalls';
import {
  getBiDashboardPagination,
  getBiDashboards
} from '~/store/modules/bidashboards/selectors';
import { setLog } from '~/store/modules/logs/apiCalls';
import { deleteTab } from '~/store/modules/tabs/actions';
import { getSelectedTabPermission } from '~/store/modules/tabs/selectors';

interface Props {
  index: number;
}

export default function DefaultBiDashboard({ index }: Props) {
  const biDashboardPagination = useSelector(getBiDashboardPagination);
  const dispatch = useDispatch();
  const [sitesSelected, setSitesSelected] = useState<any[]>([]);
  const selectedTabPermission = useSelector(getSelectedTabPermission);
  const { formFilter } = useContext(BIDashboardContext);
  const biDashboards = useSelector(getBiDashboards);
  const dashboard = useMemo(() => {
    if (biDashboards.length && biDashboards[index]) {
      return biDashboards[index];
    }
    return undefined;
  }, [biDashboards, index]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (dashboard?.expirationTokenDate) {
        const now = format(Date.now(), 'dd-MM-yyyy kk:mm:ss');
        const expirationDateFormated = format(
          new Date(dashboard?.expirationTokenDate),
          'dd-MM-yyyy kk:mm:ss'
        );

        if (now === expirationDateFormated) {
          const biDashboardParams: M.FindBiDashboardParams = {
            name: formFilter?.getFieldValue('name'),
            status: formFilter?.getFieldValue('status'),
            ...biDashboardPagination
          };
          dispatch(fetchBiDashboard(biDashboardParams));
        }
      }
    }, 10000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (sitesSelected.length === 0) {
      setSitesAnalyticsToState(setSitesSelected, selectedTabPermission);
    }
  }, [selectedTabPermission, setSitesSelected, sitesSelected]);

  useEffect(() => {
    const biDashboardParams: M.FindBiDashboardParams = {
      name: formFilter?.getFieldValue('name'),
      status: formFilter?.getFieldValue('status'),
      ...biDashboardPagination
    };
    dispatch(fetchBiDashboard(biDashboardParams));
  }, []);

  useEffect(() => {
    if (dashboard) {
      dispatch(
        setLog({
          description: dashboard?.name,
          dateTime: format(new Date(), 'MM-dd-yyyy kk:mm:ss'),
          input: true
        })
      );
    }
    let unloadHandled = false;
    const closeDashboardTab = () => {
      const dashboardName = String(dashboard?.name.replaceAll(' ', ''));
      if (dashboardName && !unloadHandled) {
        dispatch(deleteTab(dashboardName));
        dispatch(
          setLog({
            description: String(dashboard?.name),
            dateTime: format(new Date(), 'MM-dd-yyyy kk:mm:ss'),
            input: false
          })
        );
        unloadHandled = true;
      }
    };
    window.addEventListener('beforeunload', closeDashboardTab);
    return () => {
      closeDashboardTab();
      window.removeEventListener('beforeunload', closeDashboardTab);
    };
  }, []);

  const renderPowerBIEmbed = useMemo(() => {
    if (!sitesSelected.length) return null;
    if (!dashboard) return null;
    const regex = /\/groups\/([^/]+)\/reports\/([^/]+)/;
    const match = dashboard?.url.match(regex);
    if (!match) {
      dispatch(alertRequest('Url do dashboard inválida.', 'error'));
      return null;
    }
    const groupId = match[1];
    const reportId = match[2];
    return (
      <PowerBIEmbed
        embedConfig={{
          accessToken: dashboard.token,
          embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${reportId}&groupId=${groupId}&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUJSQVpJTC1TT1VUSC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d`,
          filters: [
            {
              $schema: 'http://powerbi.com/product/schema#basic',
              target: { table: 'PO_VENDORS_V', column: 'VENDOR_SITE_CODE' },
              operator: 'In',
              values: sitesSelected?.map(i => i.account),
              filterType: models.FilterType.Basic
            }
          ],
          id: String(reportId),
          settings: {
            navContentPaneEnabled: false,
            panes: {
              filters: {
                visible: false
              }
            }
          },
          type: 'report'
        }}
        cssClassName="my-powerbi-embed"
      />
    );
  }, [biDashboards, dispatch, sitesSelected]);

  return (
    <BIDashboardContextProvider>
      <Content>{renderPowerBIEmbed}</Content>
    </BIDashboardContextProvider>
  );
}

import { createStore, compose, applyMiddleware } from 'redux';

const create = (reducers: any, middlewares: any) => {
  const enhancer =
    process.env.NODE_ENV === 'development'
      ? // ? composeWithDevTools(
        compose(
          // console.tron.createEnhancer(),

          applyMiddleware(...middlewares)
        )
      : applyMiddleware(...middlewares);

  return createStore(reducers, enhancer);
};

export default create;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import PurchaseManagement from '~/domain/model/EcommerceManagement/PurchaseManagement';
import ProductSimple from '~/domain/model/product-simple';
import {
  CartStatus,
  DefaultPagination,
  ResponseApi,
  TransactionStatus
} from '~/domain/model';

import {
  cancelPurchase,
  exportPurchases,
  fetchHistoryPurchase,
  fetchList,
  fetchProducts
} from './apiCalls';

export type FetchStatus = 'idle' | 'pending' | 'success' | 'failure';

export interface EcommerceManagementState {
  list: PurchaseManagement[];
  transactionStatusList: TransactionStatus[];
  cartStatusList: CartStatus[];
  historyPurchaseManagement: any | undefined;
  pagination: DefaultPagination;
  totalCount: number;
  cancelPurchaseManagementStatus: FetchStatus;
  loading: boolean;
  products: ProductSimple[];
}

const initialState: EcommerceManagementState = {
  list: [],
  transactionStatusList: [],
  cartStatusList: [],
  historyPurchaseManagement: undefined,
  pagination: { PageSize: 10 } as DefaultPagination,
  totalCount: 0,
  cancelPurchaseManagementStatus: 'idle',
  loading: false,
  products: []
};
const prefix = 'ecommerceManagement';

const ecommerceManagementSlice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: state => {
      state.list = initialState.list;
      state.transactionStatusList = initialState.transactionStatusList;
      state.cartStatusList = initialState.cartStatusList;
      state.pagination = initialState.pagination;
      state.totalCount = initialState.totalCount;
      state.cancelPurchaseManagementStatus =
        initialState.cancelPurchaseManagementStatus;
      state.loading = initialState.loading;
      state.products = initialState.products;
    },
    setPagination: (state, action: PayloadAction<DefaultPagination>) => {
      state.pagination = action.payload;
    },
    setStatusCancel: (state, action: PayloadAction<FetchStatus>) => {
      state.cancelPurchaseManagementStatus = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchList.pending, state => {
      state.loading = true;
      state.list = initialState.list;
    });
    builder.addCase(fetchList.fulfilled, (state, action) => {
      const apiResponse = action.payload as ResponseApi<PurchaseManagement[]>;
      state.list = apiResponse.results;
      state.totalCount = apiResponse.totalCount;
      state.loading = false;
    });
    builder.addCase(fetchList.rejected, state => {
      state.loading = false;
    });
    builder.addCase(cancelPurchase.pending, state => {
      state.loading = true;
    });
    builder.addCase(cancelPurchase.fulfilled, state => {
      state.cancelPurchaseManagementStatus = 'success';
      state.loading = false;
    });
    builder.addCase(cancelPurchase.rejected, state => {
      state.loading = false;
    });
    builder.addCase(fetchHistoryPurchase.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchHistoryPurchase.fulfilled, (state, action) => {
      state.historyPurchaseManagement = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchHistoryPurchase.rejected, state => {
      state.loading = false;
    });
    builder.addCase(exportPurchases.pending, state => {
      state.loading = true;
    });
    builder.addCase(exportPurchases.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(exportPurchases.rejected, state => {
      state.loading = false;
    });
    builder.addCase(
      fetchProducts.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.products = action.payload;
      }
    );
  }
});

export const actions = ecommerceManagementSlice.actions;
export const reducer = ecommerceManagementSlice.reducer;

import { createSelector } from 'reselect';
import { ShippingCompaniesState } from '.';

export const getRoot = (state: any) => {
  return state.shippingCompanies as ShippingCompaniesState;
};

export const getShippingCompany = createSelector(
  [getRoot],
  state => state.shippingCompany
);

export const getShippingCompaniesPagination = createSelector(
  [getRoot],
  state => state.shippingCompaniesPagination
);

export const getTotalShippingCompanies = createSelector(
  [getRoot],
  state => state.totalShippingCompany
);
export const getShippingCompanies = createSelector(
  [getRoot],
  state => state.shippingCompanies
);
export const getShippingCompanyHistory = createSelector(
  [getRoot],
  state => state.shippingCompanyHistory
);
export const getCloseShippingCompanyHistoryModal = createSelector(
  [getRoot],
  state => state.closeShippingCompanyHistoryModal
);
export const getCloseEditShippingCompanyModal = createSelector(
  [getRoot],
  state => state.closeEditShippingCompanyModal
);

export const getCloseAddShippingCompanyModal = createSelector(
  [getRoot],
  state => state.closeAddShippingCompanyModal
);
export const getSubmitFormFilter = createSelector(
  [getRoot],
  state => state.submitFormFilter
);
export const getCurrentStep = createSelector(
  [getRoot],
  state => state.currentStep
);
export const getSubmitNow = createSelector([getRoot], state => state.submitNow);

export const getShippingCompanyLoading = createSelector(
  [getRoot],
  state => state.loading
);

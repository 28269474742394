import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useFeatureFlagStore } from '~/zustand/featureFlag/useFeatureFlagStore';

export default function useRefreshFeatureFlags() {
  const [hasRefreshed, setHasRefreshed] = useState(false);
  const location = useLocation();
  const refreshFeatureFlags = useFeatureFlagStore(
    state => state.refreshFeatureFlags
  );

  useEffect(() => {
    const isRootPath = location.pathname === '/';
    if (isRootPath && !hasRefreshed) {
      refreshFeatureFlags();
      setHasRefreshed(true);
    }
  }, [location, refreshFeatureFlags, hasRefreshed]);
}

import { AxiosResponse } from 'axios';
import moment from 'moment';
import { endpoints } from '~/constants/endpoints';
import { ResponseApi } from '~/domain/model';
import { TechnicalSpecification } from '~/domain/model/TechnicalSpecification/TechnicalSpecification';
import { TechnicalSpecificationFetchParams } from '~/domain/model/TechnicalSpecification/TechnicalSpecificationFetchParams';
import { TechnicalSpecificationImportParams } from '~/domain/model/TechnicalSpecification/TechnicalSpecificationImportParams';
import api from '../api';

export const fetchRequest = async (
  payload: TechnicalSpecificationFetchParams
) => {
  const params = new URLSearchParams();
  if (payload['Data.CategoryId']) {
    params.append('Data.CategoryId', String(payload['Data.CategoryId']));
  }
  if (payload['Data.Name']) {
    params.append('Data.Name', String(payload['Data.Name']));
  }
  if (payload.PageSize) {
    params.append('PageSize', String(payload.PageSize));
  }
  if (payload.PageNumber) {
    params.append('PageNumber', String(payload.PageNumber));
  }
  const response = await api.get<
    TechnicalSpecificationFetchParams,
    AxiosResponse<ResponseApi<TechnicalSpecification[]>>
  >(endpoints.technicalSpecification.base, {
    params
  });

  return response.data;
};

export const exportRequest = async (fileId: number) => {
  const response = await api.get<number, AxiosResponse<string>>(
    `${endpoints.technicalSpecification.base}/file/${fileId}`
  );
  console.log('response', response);
  const link = document.createElement('a');
  link.href = response.data;
  link.target = 'blank';
  link.rel = 'noopener noreferrer';
  link.style.display = 'none';
  const fileName = 'especificacao-tecnica.pdf';
  link.download = fileName;
  link.click();
};

export const importRequest = async ({
  categoryId,
  creationDateFile,
  revisedDateFile,
  file,
  id,
  name,
  version
}: TechnicalSpecificationImportParams) => {
  const formData = new FormData();
  formData.append('categoryId', categoryId.toString());
  formData.append(
    'creationDateFile',
    moment(creationDateFile).format('YYYY-MM-DD')
  );
  formData.append(
    'revisedDateFile',
    moment(revisedDateFile).format('YYYY-MM-DD')
  );
  formData.append('name', name.toString());
  formData.append('version', version.toString());
  formData.append('id', id?.toString() ?? '0');
  if (file?.originFileObj) {
    formData.append('File', file.originFileObj);
  }
  const response = await api.put(
    endpoints.technicalSpecification.base,
    formData
  );
  return response.data;
};

export const deleteRequest = async (id: number) => {
  const response = await api.delete(
    `${endpoints.technicalSpecification.base}/${id}`
  );
  return response.data;
};

import { Site } from '~/domain/model';
import { createSelector } from '~/helpers/util/selectors';
import { CompetenceEventCreateState } from './duck';
import { selectors as core } from '~/store/modules/_core';

export const getRoot = (state: any) => {
  return state.ui.competenceEventCreate as CompetenceEventCreateState;
};

export const getFetchStatus = createSelector([getRoot], state => {
  return state.fetchStatus;
});

export const getCreateStatus = createSelector([getRoot], state => {
  return state.createStatus;
});

export const getErrorMessage = createSelector([getRoot], state => {
  return state.errorMessage;
});

export const getSiteSearchTerm = createSelector([getRoot], state => {
  return state.siteSearchTerm;
});

export const getIsLoading = createSelector(
  [getFetchStatus, getCreateStatus],
  (fetchStatus, createStatus) => {
    return fetchStatus === 'fetching' || createStatus === 'creating';
  }
);

export const getAllOrFilteredSites = createSelector(
  [core.sites.getSites, getSiteSearchTerm],
  (sites: Site[], siteSearchTerm) => {
    if (!siteSearchTerm || siteSearchTerm === '') return sites;
    return sites.filter(site => {
      return site.getFormatedSite().includes(siteSearchTerm.toUpperCase());
    });
  }
);

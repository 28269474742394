import { BannerProducerManagementCreateState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.ui
    .bannerProducerManagementCreate as BannerProducerManagementCreateState;
};

export const getCreateStatus = createSelector([getRoot], state => {
  return state.createStatus;
});

export const getErrorMessage = createSelector([getRoot], state => {
  return state.errorMessage;
});

export const getBannerImage = createSelector([getRoot], state => {
  return state.bannerImage;
});

export const getIsLoading = createSelector(
  [getRoot],
  state =>
    state.createStatus === 'pending' || state.fetchInitStatus === 'pending'
);

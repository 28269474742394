import { CompetenceEventListState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.ui.competenceEventList as CompetenceEventListState;
};

export const getCompetenceID = (state: CompetenceEventListState) =>
  state.competenceID;

export const getIsLoading = createSelector(
  [getRoot],
  state =>
    state.requestStatus === 'running' || state.inactivateStatus === 'running'
);

export const getIsFetchStatusIdle = createSelector(
  [getRoot],
  state => state.requestStatus === 'idle'
);

export const getPageNumber = createSelector([getRoot], state => {
  return state.pageNumber;
});

export const getPageSize = createSelector([getRoot], state => state.pageSize);

export const getTotalCount = createSelector(
  [getRoot],
  state => state.totalCount
);

export const getInactivateStatus = createSelector([getRoot], state => {
  return state.inactivateStatus;
});

export const getErrorMessage = createSelector([getRoot], state => {
  return state.errorMessage;
});

import IUseCase from '../../IUseCase';
import { CustomerService } from '~/@core/infrastructure/api/services';
import Customers from '~/@core/domain/model/customer/Customers';

export default class GetCustomers
  implements IUseCase<void, Promise<Customers[]>>
{
  execute(): Promise<Customers[]> {
    return CustomerService.fetchCustomers();
  }
}

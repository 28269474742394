import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type FetchStatus = 'idle' | 'pending' | 'success' | 'failure';

export type EcommerceManagementState = {
  fetchInitStatus: FetchStatus;
  fetchListStatus: FetchStatus;
  cancelPurchaseManagementStatus: FetchStatus;
  exportStaus: FetchStatus;
  fetchCartStatusListStatus: FetchStatus;
  fetchTransactionStatusListStatus: FetchStatus;
  fetchProductsStatus: FetchStatus;
  fetchHistoryPurchaseManagementStatus: FetchStatus;
  totalCount?: number;
  pageNumber?: number;
  pageSize?: number;
  errorMessage?: string;
};

export const initialState: EcommerceManagementState = {
  fetchInitStatus: 'idle',
  cancelPurchaseManagementStatus: 'idle',
  exportStaus: 'idle',
  fetchProductsStatus: 'idle',
  fetchListStatus: 'idle',
  fetchCartStatusListStatus: 'idle',
  fetchTransactionStatusListStatus: 'idle',
  fetchHistoryPurchaseManagementStatus: 'idle',
  totalCount: 0,
  pageNumber: 1,
  pageSize: 10,
  errorMessage: ''
};

export type Error = {
  errorMessage?: string;
};

export type PaginationParams = {
  pageNumber?: number;
  pageSize?: number;
};

export type FetchProducts = {
  siteId: number;
};

export type CancelPurchaseManagement = {
  transactionId?: number;
  reasonCancellation?: string;
};

export type FetchEcommerceManagementSuccess = {
  pageNumber?: number;
  pageSize?: number;
  totalCount: number;
};

export type FetchEcommerceManagement = {
  siteId?: number;
  customerId?: number;
  transaction?: string;
  type?: string;
  transactionStatus?: string;
  cartStatus?: string;
  deliveryDateStart?: string;
  deliveryDateEnd?: string;
  billingDateStart?: string;
  billingDateEnd?: string;
  productId?: number;
  pageNumber?: number;
  pageSize?: number;
};

const prefix = '@ecommerceManagementList';

const ecommerceManagementList = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: state => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
      state = initialState;
    },
    clearHistory: state => {
      state.fetchHistoryPurchaseManagementStatus = initialState.fetchInitStatus;
    },
    cancelPurchaseManagement: (
      state,
      _action: PayloadAction<CancelPurchaseManagement>
    ) => {
      state.cancelPurchaseManagementStatus = 'pending';
    },
    cancelPurchaseManagementSuccess: state => {
      state.cancelPurchaseManagementStatus = 'success';
    },
    cancelPurchaseManagementFailure: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
      state.cancelPurchaseManagementStatus = 'failure';
    },
    fetchInit: state => {
      state.fetchInitStatus = 'pending';
    },
    fetchSuccessInit: state => {
      state.fetchInitStatus = 'success';
    },
    fetchFailure: state => {
      state.fetchInitStatus = 'failure';
    },
    exportInit: (state, action: PayloadAction<FetchEcommerceManagement>) => {
      state.exportStaus = 'pending';
      action.payload.pageNumber = state.pageNumber;
      action.payload.pageSize = state.pageSize;
    },
    exportSuccess: state => {
      state.exportStaus = 'success';
    },
    exportFailure: state => {
      state.exportStaus = 'failure';
    },
    fetchHistoryPurchaseManagement: (state, _action: PayloadAction<number>) => {
      state.fetchHistoryPurchaseManagementStatus = 'pending';
    },
    fetchHistoryPurchaseManagementSuccess: state => {
      state.fetchHistoryPurchaseManagementStatus = 'success';
    },
    fetchHistoryPurchaseManagementFailure: state => {
      state.fetchHistoryPurchaseManagementStatus = 'failure';
    },
    fetchList: (state, action: PayloadAction<FetchEcommerceManagement>) => {
      state.fetchListStatus = 'pending';
      action.payload.pageNumber = state.pageNumber;
      action.payload.pageSize = state.pageSize;
    },
    fetchListSuccess: (state, action: PayloadAction<number>) => {
      state.totalCount = action.payload;
      state.fetchListStatus = 'success';
    },
    fetchListFailure: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
      state.fetchListStatus = 'failure';
    },
    fetchCartStatusList: state => {
      state.fetchCartStatusListStatus = 'pending';
    },
    fetchCartStatusListSuccess: state => {
      state.fetchCartStatusListStatus = 'success';
    },
    fetchCartStatusListFailure: state => {
      state.fetchCartStatusListStatus = 'failure';
    },
    fetchTransactionStatusList: state => {
      state.fetchTransactionStatusListStatus = 'pending';
    },
    fetchTransactionStatusListSuccess: state => {
      state.fetchTransactionStatusListStatus = 'success';
    },
    fetchTransactionStatusListFailure: state => {
      state.fetchTransactionStatusListStatus = 'failure';
    },
    fetchProducts: (state, _action: PayloadAction<FetchProducts>) => {
      state.fetchProductsStatus = 'pending';
    },
    fetchProductsSuccess: state => {
      state.fetchProductsStatus = 'success';
    },
    fetchProductsFailure: state => {
      state.fetchProductsStatus = 'failure';
    },
    setPagination: (state, action: PayloadAction<PaginationParams>) => {
      const { pageNumber, pageSize } = action.payload;
      state.pageNumber = pageNumber ?? 1;
      state.pageSize = pageSize ?? 10;
    }
  }
});

export const actions = ecommerceManagementList.actions;
export const reducer = ecommerceManagementList.reducer;

const caseReducers = ecommerceManagementList.caseReducers;
export const Types = Object.freeze({
  clear: `${prefix}/${caseReducers.clear.name}`,
  fetchInit: `${prefix}/${caseReducers.fetchInit.name}`,
  cancelPurchaseManagement: `${prefix}/${caseReducers.cancelPurchaseManagement.name}`,
  cancelPurchaseManagementSuccess: `${prefix}/${caseReducers.cancelPurchaseManagementSuccess.name}`,
  cancelPurchaseManagementFailure: `${prefix}/${caseReducers.cancelPurchaseManagementFailure.name}`,
  fetchSuccessInit: `${prefix}/${caseReducers.fetchSuccessInit.name}`,
  fetchFailure: `${prefix}/${caseReducers.fetchFailure.name}`,
  fetchHistoryPurchaseManagement: `${prefix}/${caseReducers.fetchHistoryPurchaseManagement.name}`,
  fetchHistoryPurchaseManagementSuccess: `${prefix}/${caseReducers.fetchHistoryPurchaseManagementSuccess.name}`,
  fetchHistoryPurchaseManagementFailure: `${prefix}/${caseReducers.fetchHistoryPurchaseManagementFailure.name}`,
  fetchList: `${prefix}/${caseReducers.fetchList.name}`,
  fetchListSuccess: `${prefix}/${caseReducers.fetchListSuccess.name}`,
  fetchListFailure: `${prefix}/${caseReducers.fetchListFailure.name}`,
  exportInit: `${prefix}/${caseReducers.exportInit.name}`,
  exportSuccess: `${prefix}/${caseReducers.exportSuccess.name}`,
  exportFailure: `${prefix}/${caseReducers.exportFailure.name}`,
  fetchCartStatusList: `${prefix}/${caseReducers.fetchCartStatusList.name}`,
  fetchCartStatusListSuccess: `${prefix}/${caseReducers.fetchCartStatusListSuccess.name}`,
  fetchCartStatusListFailure: `${prefix}/${caseReducers.fetchCartStatusListFailure.name}`,
  fetchTransactionStatusList: `${prefix}/${caseReducers.fetchTransactionStatusList.name}`,
  fetchTransactionStatusListSuccess: `${prefix}/${caseReducers.fetchTransactionStatusListSuccess.name}`,
  fetchTransactionStatusListFailure: `${prefix}/${caseReducers.fetchTransactionStatusListFailure.name}`,
  fetchProducts: `${prefix}/${caseReducers.fetchProducts.name}`,
  fetchProductsSuccess: `${prefix}/${caseReducers.fetchProductsSuccess.name}`,
  fetchProductsFailure: `${prefix}/${caseReducers.fetchProductsFailure.name}`
});

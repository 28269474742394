import { combineReducers } from 'redux';
import * as competenceList from './competenceList';
import * as voucherList from './voucherList';
import * as salesReport from './salesReport';
import * as ecommerceManagementList from './ecommerceManagementList';
import * as siteLinksList from './siteLinksList';
import * as bannerApprovalList from './bannerApprovalList';
import * as announcementBanner from './announcementBanner';
import * as bannerProducerManagementList from './bannerProducerManagementList';
import * as bannerProducerManagementCreate from './bannerProducerManagementCreate';
import * as packingAdjustQuantity from './packingAdjustQuantity';
import * as siteLinksEdit from './siteLinksEdit';
import * as announcementList from './announcementList';
import * as announcementCreate from './announcementCreate';
import * as voucherAdd from './voucherAdd';
import * as competenceCreate from './competenceCreate';
import * as productpreferencesList from './productPreferencesList';
import * as productPreferencesCreate from './productPreferences-create';
import * as competenceEventList from './competenceEventList';
import * as competenceEventCreate from './competenceEventCreate';
import * as offerChannelList from './offerChannelList';
import * as offerChannelCreate from './offerChannelCreate';
import * as offerSettings from './offerSettings';
import * as reportsAnnouncement from './reportsAnnouncement';
import * as priceAlertList from './priceAlertList';
import * as priceAlertCreate from './priceAlertCreate';
import * as deliveryPatternCreate from './deliveryPattern-create';
import * as deliveryPatternList from './deliveryPattern-list';
import * as specialDateList from './specialDateList';
import * as specialDateCreate from './specialDateCreate';
import * as specialDateEdit from './specialDateEdit';
import * as customers from './customers';
import * as volOfferList from './volOffersList';
import * as volOfferCreate from './volOffersCreate';
import * as directedOfferList from './directedOffersList';
import * as directedOfferCreate from './directedOffersCreate';
import * as customersGroupCreate from './customersGroupCreate';
import * as loading from './loading';
import * as bannerList from './bannerList';
import * as bannerCreate from './bannerCreate';
import * as lkpOfferList from './lkpOfferList';
import * as lkpOfferEdit from './lkpOfferEdit';
import * as auth from './auth';

export const actions = Object.freeze({
  auth: auth.actions,
  competenceList: competenceList.actions,
  salesReport: salesReport.actions,
  ecommerceManagementList: ecommerceManagementList.actions,
  announcementBanner: announcementBanner.actions,
  reportsAnnouncement: reportsAnnouncement.actions,
  siteLinksList: siteLinksList.actions,
  bannerApprovalList: bannerApprovalList.actions,
  bannerProducerManagementList: bannerProducerManagementList.actions,
  bannerProducerManagementCreate: bannerProducerManagementCreate.actions,
  packingAdjustQuantity: packingAdjustQuantity.actions,
  siteLinksEdit: siteLinksEdit.actions,
  voucherList: voucherList.actions,
  announcementList: announcementList.actions,
  announcementCreate: announcementCreate.actions,
  voucherAdd: voucherAdd.actions,
  competenceCreate: competenceCreate.actions,
  productpreferencesList: productpreferencesList.actions,
  productPreferencesCreate: productPreferencesCreate.actions,
  competenceEventList: competenceEventList.actions,
  competenceEventCreate: competenceEventCreate.actions,
  offerChannelList: offerChannelList.actions,
  offerChannelCreate: offerChannelCreate.actions,
  offerSettings: offerSettings.actions,
  priceAlertList: priceAlertList.actions,
  priceAlertCreate: priceAlertCreate.actions,
  deliveryPatternCreate: deliveryPatternCreate.actions,
  deliveryPatternList: deliveryPatternList.actions,
  specialDateList: specialDateList.actions,
  specialDateCreate: specialDateCreate.actions,
  specialDateEdit: specialDateEdit.actions,
  customers: customers.actions,
  customersGroupCreate: customersGroupCreate.actions,
  volOfferList: volOfferList.actions,
  volOfferCreate: volOfferCreate.actions,
  directedOfferList: directedOfferList.actions,
  directedOfferCreate: directedOfferCreate.actions,
  loading: loading.actions,
  bannerList: bannerList.actions,
  bannerCreate: bannerCreate.actions,
  lkpOfferList: lkpOfferList.actions,
  lkpOfferEdit: lkpOfferEdit.actions
});

export const reducer = combineReducers({
  auth: auth.reducer,
  competenceList: competenceList.reducer,
  salesReport: salesReport.reducer,
  announcementBanner: announcementBanner.reducer,
  reportsAnnouncement: reportsAnnouncement.reducer,
  ecommerceManagementList: ecommerceManagementList.reducer,
  siteLinksList: siteLinksList.reducer,
  bannerApprovalList: bannerApprovalList.reducer,
  bannerProducerManagementList: bannerProducerManagementList.reducer,
  bannerProducerManagementCreate: bannerProducerManagementCreate.reducer,
  packingAdjustQuantity: packingAdjustQuantity.reducer,
  siteLinksEdit: siteLinksEdit.reducer,
  voucherList: voucherList.reducer,
  announcementList: announcementList.reducer,
  announcementCreate: announcementCreate.reducer,
  voucherAdd: voucherAdd.reducer,
  competenceCreate: competenceCreate.reducer,
  productpreferencesList: productpreferencesList.reducer,
  productPreferencesCreate: productPreferencesCreate.reducer,
  competenceEventList: competenceEventList.reducer,
  competenceEventCreate: competenceEventCreate.reducer,
  offerChannelList: offerChannelList.reducer,
  offerChannelCreate: offerChannelCreate.reducer,
  offerSettings: offerSettings.reducer,
  priceAlertList: priceAlertList.reducer,
  priceAlertCreate: priceAlertCreate.reducer,
  deliveryPatternCreate: deliveryPatternCreate.reducer,
  deliveryPatternList: deliveryPatternList.reducer,
  specialDateList: specialDateList.reducer,
  specialDateCreate: specialDateCreate.reducer,
  specialDateEdit: specialDateEdit.reducer,
  customers: customers.reducer,
  customersGroupCreate: customersGroupCreate.reducer,
  volOfferList: volOfferList.reducer,
  volOfferCreate: volOfferCreate.reducer,
  directedOfferList: directedOfferList.reducer,
  directedOfferCreate: directedOfferCreate.reducer,
  loading: loading.reducer,
  bannerList: bannerList.reducer,
  bannerCreate: bannerCreate.reducer,
  lkpOfferList: lkpOfferList.reducer,
  lkpOfferEdit: lkpOfferEdit.reducer
});

export const selectors = Object.freeze({
  auth: auth.selectors,
  competenceList: competenceList.selectors,
  voucherList: voucherList.selectors,
  announcementBanner: announcementBanner.selectors,
  bannerApprovalList: bannerApprovalList.selectors,
  announcementList: announcementList.selectors,
  reportsAnnouncement: reportsAnnouncement.selectors,
  announcementCreate: announcementCreate.selectors,
  bannerProducerManagementList: bannerProducerManagementList.selectors,
  bannerProducerManagementCreate: bannerProducerManagementCreate.selectors,
  packingAdjustQuantity: packingAdjustQuantity.selectors,
  salesReport: salesReport.selectors,
  ecommerceManagementList: ecommerceManagementList.selectors,
  siteLinksList: siteLinksList.selectors,
  siteLinksEdit: siteLinksEdit.selectors,
  voucherAdd: voucherAdd.selectors,
  competenceCreate: competenceCreate.selectors,
  productpreferencesList: productpreferencesList.selectors,
  productPreferencesCreate: productPreferencesCreate.selectors,
  competenceEventList: competenceEventList.selectors,
  competenceEventCreate: competenceEventCreate.selectors,
  offerChannelList: offerChannelList.selectors,
  offerChannelCreate: offerChannelCreate.selectors,
  offerSettings: offerSettings.selectors,
  priceAlertList: priceAlertList.selectors,
  priceAlertCreate: priceAlertCreate.selectors,
  deliveryPatternCreate: deliveryPatternCreate.selectors,
  deliveryPatternList: deliveryPatternList.selectors,
  specialDateList: specialDateList.selectors,
  specialDateCreate: specialDateCreate.selectors,
  specialDateEdit: specialDateEdit.selectors,
  customers: customers.selectors,
  customersGroupCreate: customersGroupCreate.selectors,
  loading: loading.selectors,
  bannerList: bannerList.selectors,
  bannerCreate: bannerCreate.selectors,
  lkpOfferList: lkpOfferList.selectors,
  lkpOfferEdit: lkpOfferEdit.selectors,
  volOfferList: volOfferList.selectors,
  directedOfferList: directedOfferList.selectors
});

export const sagas = [
  ...auth.sagas,
  ...competenceCreate.sagas,
  ...competenceList.sagas,
  ...salesReport.sagas,
  ...bannerApprovalList.sagas,
  ...announcementBanner.sagas,
  ...announcementList.sagas,
  ...reportsAnnouncement.sagas,
  ...announcementCreate.sagas,
  ...bannerProducerManagementList.sagas,
  ...bannerProducerManagementCreate.sagas,
  ...ecommerceManagementList.sagas,
  ...packingAdjustQuantity.sagas,
  ...siteLinksList.sagas,
  ...siteLinksEdit.sagas,
  ...productpreferencesList.sagas,
  ...reportsAnnouncement.sagas,
  ...productPreferencesCreate.sagas,
  ...voucherList.sagas,
  ...voucherAdd.sagas,
  ...competenceEventList.sagas,
  ...competenceEventCreate.sagas,
  ...offerChannelList.sagas,
  ...offerChannelCreate.sagas,
  ...offerSettings.sagas,
  ...priceAlertList.sagas,
  ...priceAlertCreate.sagas,
  ...deliveryPatternCreate.sagas,
  ...deliveryPatternList.sagas,
  ...specialDateList.sagas,
  ...specialDateCreate.sagas,
  ...specialDateEdit.sagas,
  ...customers.sagas,
  ...customersGroupCreate.sagas,
  ...volOfferList.sagas,
  ...volOfferCreate.sagas,
  ...directedOfferList.sagas,
  ...directedOfferCreate.sagas,
  ...bannerList.sagas,
  ...bannerCreate.sagas,
  ...lkpOfferList.sagas,
  ...lkpOfferEdit.sagas
];

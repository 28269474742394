import image from '~/assets/images/loading.gif';
import { Container, Content } from './style';

interface Props {
  isVisible: boolean;
}

export default function Loading(props: Props) {
  const { isVisible } = props;
  return (
    <Container style={{ display: isVisible ? 'flex' : 'none' }}>
      <Content style={{ display: isVisible ? 'flex' : 'none' }} />
      <img src={image} alt="" />
    </Container>
  );
}

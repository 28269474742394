import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { FetchPreferencesParams } from '~/domain/model';
import CreatePreferenceParams from '~/domain/model/ProductPreference/CreatePreferenceParams';
import ProductPreference from '~/domain/model/ProductPreference/ProductPreference';
import { ProductPreferenceDeleteImageParams } from '~/domain/model/ProductPreference/ProductPreferenceDeleteImageParams';
import { ProductPreferenceUpdateImageListOrder } from '~/domain/model/ProductPreference/ProductPreferenceUpdateImageListOrder';
import { ProductPreferenceUpload } from '~/domain/model/ProductPreference/ProductPreferenceUpload';
import * as productPreferenceService from '~/services/producer/ProductPreference';
import { alertRequest } from '../../alert/actions';
import { actions as loadingActions } from '../../_ui/loading';

export const fetchProductPreferences = createAsyncThunk(
  `productPreferences/fetchProductPreferences`,
  async (params: FetchPreferencesParams) => {
    const response = await productPreferenceService.findProdutPreferences(
      params
    );
    return response.data;
  }
);

export const createPreferencesApi = createAsyncThunk(
  `productPreferences/createPreferencesApi`,
  async (
    createPreferencesParams: CreatePreferenceParams[],
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await productPreferenceService.createPreferences(
        createPreferencesParams
      );
      const preferences = response.data as ProductPreference[];
      let message = '';
      preferences.forEach(preference => {
        if (
          preference.messageVolOrLkpPercentage &&
          preference.messageVolOrLkpPercentage.length > 0
        ) {
          message += `${preference.messageVolOrLkpPercentage}<br/>`;
        }
      });
      if (message.length > 0) {
        dispatch(alertRequest(message, 'success'));
      } else {
        dispatch(alertRequest('Prefêrencias salvas com sucesso!', 'success'));
      }
      dispatch(loadingActions.setDisableSave(true));
      dispatch(loadingActions.setCloseModal(true));
      return preferences;
    } catch (err: unknown) {
      const error = err as AxiosError;
      dispatch(
        alertRequest(
          JSON.stringify(error.response?.data.errors.messages),
          'error'
        )
      );
      return rejectWithValue({});
    }
  }
);

export const fetchPreferenceHistory = createAsyncThunk(
  `productPreferences/fetchPreferenceHistory`,
  async (preferenceId: number) => {
    const response = await productPreferenceService.findPreferenceHistory(
      preferenceId
    );
    return response;
  }
);

export const fetchPreferenceColor = createAsyncThunk(
  `productPreferences/fetchPreferenceColor`,
  async () => {
    const response = await productPreferenceService.findPreferenceColor();
    return response;
  }
);
export const fetchImages = createAsyncThunk(
  `productPreferences/fetchImages`,
  async (preferenceId: number) => {
    const response = await productPreferenceService.findImages(preferenceId);
    return response;
  }
);

export const upload = createAsyncThunk(
  `productPreferences/upload`,
  async (payload: ProductPreferenceUpload, { dispatch, rejectWithValue }) => {
    try {
      const response = await productPreferenceService.upload(payload);
      dispatch(fetchImages(payload.preferenceId));
      dispatch(alertRequest('Imagem adicionada com sucesso!', 'success'));
      return response;
    } catch (err: unknown) {
      const error = err as AxiosError;
      dispatch(
        alertRequest(
          JSON.stringify(error?.response?.data?.errors?.messages),
          'error'
        )
      );
      return rejectWithValue({});
    }
  }
);

export const deleteImage = createAsyncThunk(
  `productPreferences/deleteImage`,
  async (
    payload: ProductPreferenceDeleteImageParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await productPreferenceService.deleteImage(payload);
      dispatch(fetchImages(payload.preferenceId));
      dispatch(alertRequest('Imagem deletada com sucesso!', 'success'));
      return response;
    } catch (err: unknown) {
      const error = err as AxiosError;
      dispatch(
        alertRequest(
          JSON.stringify(error?.response?.data?.errors?.messages),
          'error'
        )
      );
      return rejectWithValue({});
    }
  }
);

export const updateImageList = createAsyncThunk(
  `productPreferences/updateImageList`,
  async (
    payload: ProductPreferenceUpdateImageListOrder,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await productPreferenceService.updateImageList(payload);
      dispatch(fetchImages(payload.productPreferenceId));
      dispatch(alertRequest('Lista reordenada com sucesso!', 'success'));
      return response.data;
    } catch (err: unknown) {
      dispatch(alertRequest('Erro ao reordenar imagens', 'error'));
      return rejectWithValue({});
    }
  }
);

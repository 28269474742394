import Expedition from '~/@core/domain/model/expedition/Expedition';
import { ExpeditionService } from '~/@core/infrastructure/api/services';
import IUseCase from '../../IUseCase';

export default class GetExpeditionsBySiteId
  implements IUseCase<number, Promise<Expedition[]>>
{
  execute(siteId: number): Promise<Expedition[]> {
    return ExpeditionService.fetchExpeditionBySiteId(siteId);
  }
}

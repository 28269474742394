import produce from 'immer';

const INITIAL_STATE = {
  data: [],
  loading: false
};

export default function productionAreaUnit(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case '@productionAreaUnit/LIST_REQUEST':
      return produce(state, draft => {
        draft.loading = true;
      });

    case '@productionAreaUnit/LIST_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.data = action.payload.list;
      });

    case '@productionAreaUnit/LIST_FAILURE':
      return produce(state, draft => {
        draft.loading = false;
      });

    default:
      return state;
  }
}

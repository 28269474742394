// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ProductionPlanningExportParams } from '~/domain/model/ProductionPlanning/ProductionPlanningExportParams';
import { ProductionPlanningFetchParams } from '~/domain/model/ProductionPlanning/ProductionPlanningFetchParams';
import { ProductionPlanningHistoryFetchParams } from '~/domain/model/ProductionPlanning/ProductionPlanningHistoryFetchParams';
import { ProductionPlanningImportingHistoryFetchParams } from '~/domain/model/ProductionPlanning/ProductionPlanningImportingHistoryFetchParams';
import { ProductionPlanningImportingLogFetchParams } from '~/domain/model/ProductionPlanning/ProductionPlanningImportingLogFetchParams';
import { ProductionPlanningImportParams } from '~/domain/model/ProductionPlanning/ProductionPlanningImportParams';
import { ProductionPlanningReplicateWeekParams } from '~/domain/model/ProductionPlanning/ProductionPlanningReplicateWeekParams';
import { ProductionPlanningReplicateYearParams } from '~/domain/model/ProductionPlanning/ProductionPlanningReplicateYearParams';
import { generateErrorMessage } from '~/helpers/util/error';
import * as productionPlanningServices from '~/services/producer/ProductionPlanning';
import { alertRequest } from '../../alert/actions';
import { fillMessageError } from '~/components/MessagesErrors/productionPlanning/messages';
import { MessagesEnum } from '~/domain/enum/messagesErrors/productionPlanning/messages.enum';
import { ProductionPlanningReplicateBudgetParams } from '~/domain/model/ProductionPlanning/ProductionPlanningReplicateBudgetParams';
import { FindProductionPlanningReplicate } from '~/domain/model/ProductionPlanning/FindProductionPlanningReplicate';
import { FindProductionPlanningReplicateHistory } from '~/domain/model/ProductionPlanning/FindProductionPlanningReplicateHistory';

export const productionPlanningFetch = createAsyncThunk(
  `productionPlanning/productionPlanningFetch`,
  async (
    params: ProductionPlanningFetchParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await productionPlanningServices.fetchRequest(params);
      dispatch(
        productionPlanningImportingLogFetch({
          'Data.CompetenceId': params.competenceId,
          'Data.SiteId': params.siteId
        })
      );
      return response;
    } catch (err: any) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao buscar planejamento.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const productionPlanningSave = createAsyncThunk(
  `productionPlanning/productionPlanningSave`,
  async (_, { dispatch, rejectWithValue, getState }) => {
    const {
      productionPlanning: { updatedWeeks }
    }: any = getState();
    console.log('updatedWeeks:: ', updatedWeeks);
    try {
      const response = await productionPlanningServices.saveRequest(
        updatedWeeks
      );
      dispatch(alertRequest('Tabela atualizada com sucesso!', 'success'));
      return response;
    } catch (err: any) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao atualizar tabela.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const productionPlanningExport = createAsyncThunk(
  `productionPlanning/productionPlanningExport`,
  async (
    params: ProductionPlanningExportParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await productionPlanningServices.exportRequest(params);
      return response;
    } catch (err: any) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao exportar tabela.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const productionPlanningHistoryFetch = createAsyncThunk(
  `productionPlanning/productionPlanningHistoryFetch`,
  async (
    params: ProductionPlanningHistoryFetchParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await productionPlanningServices.historyFetchRequest(
        params
      );
      return response;
    } catch (err: any) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao buscar histórico.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const productionPlanningImport = createAsyncThunk(
  `productionPlanning/productionPlanningImport`,
  async (
    params: ProductionPlanningImportParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await productionPlanningServices.importRequest(params);
      console.log(response);
      dispatch(
        alertRequest(
          'A tabela enviada está sendo processada, aguarde!',
          'success'
        )
      );
      return response;
    } catch (error: any) {
      const genErrorMessage = generateErrorMessage(error);
      const messageType = genErrorMessage.replace('messages: ', '');

      if (messageType === MessagesEnum.WorksheeStructureOutRang) {
        const errorMessage = fillMessageError(messageType);
        dispatch(alertRequest(errorMessage, 'warning'));
      } else {
        /* eslint-disable no-shadow */
        const genErrorMessage = generateErrorMessage(
          error,
          'Erro ao importar tabela.'
        );
        dispatch(alertRequest(genErrorMessage, 'error'));
        return rejectWithValue(genErrorMessage);
      }
    }
  }
);

export const productionPlanningImportingLogFetch = createAsyncThunk(
  `productionPlanning/productionPlanningImportingLogFetch`,
  async (
    params: ProductionPlanningImportingLogFetchParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response =
        await productionPlanningServices.importingLogFetchRequest(params);
      return response;
    } catch (err: any) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao buscar logs de importação.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const productionPlanningImportingLogDetailsFetch = createAsyncThunk(
  `productionPlanning/productionPlanningImportingLogDetailsFetch`,
  async (worksheetId: number, { dispatch, rejectWithValue }) => {
    try {
      const response =
        await productionPlanningServices.importingLogDetailsFetchRequest(
          worksheetId
        );
      return response;
    } catch (err: any) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao buscar detalhes do log de importação.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const productionPlanningImportingHistoryFetch = createAsyncThunk(
  `productionPlanning/productionPlanningImportingHistoryFetch`,
  async (
    params: ProductionPlanningImportingHistoryFetchParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response =
        await productionPlanningServices.importingHistoryFetchRequest(params);
      return response;
    } catch (err: any) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao buscar histórico de importação.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const productionPlanningReplicateWeek = createAsyncThunk(
  `productionPlanning/productionPlanningReplicateWeek`,
  async (
    params: ProductionPlanningReplicateWeekParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await productionPlanningServices.replicateWeekRequest(
        params
      );
      return response;
    } catch (err: any) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao replicar semana.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const productionPlanningReplicateYear = createAsyncThunk(
  `productionPlanning/productionPlanningReplicateYear`,
  async (
    params: ProductionPlanningReplicateYearParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await productionPlanningServices.replicateYearRequest(
        params
      );
      return response;
    } catch (err: any) {
      const errorMessage = generateErrorMessage(err, 'Erro ao replicar ano.');
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchProductionPlanningReplicate = createAsyncThunk(
  `prodPlanCollaborator/productionPlanningReplicate`,
  async (
    params: FindProductionPlanningReplicate,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response =
        await productionPlanningServices.findProductionPlanningReplicate(
          params
        );
      return response;
    } catch (err: unknown) {
      const errorMessage = generateErrorMessage(err, 'Erro ao consultar.');
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchProductionPlanningReplicateHistory = createAsyncThunk(
  `productionPlanning/productionPlanningReplicateHistoryFetch`,
  async (
    params: FindProductionPlanningReplicateHistory,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response =
        await productionPlanningServices.productionPlanningReplicateHistoryFetchRequest(
          params
        );
      return response;
    } catch (err: any) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao buscar historico.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const productionPlanningReplicateBudget = createAsyncThunk(
  `productionPlanning/productionPlanningReplicateBudget`,
  async (
    params: ProductionPlanningReplicateBudgetParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await productionPlanningServices.replicateBudgetRequest(
        params
      );
      dispatch(alertRequest('Itens adicionado com sucesso!', 'success'));
      return response;
    } catch (err: any) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao replicar orçado.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import * as M from '~/domain/model';
import { generateErrorMessage } from '~/helpers/util/error';
import * as HarvestingOrderServices from '~/services/producer/HarvestingOrder';
import { alertRequest } from '../../alert/actions';

export const fetchHarvestingOrdersByCustomer = createAsyncThunk(
  'harvestingOrders/fetchHarvestingOrdersByCustomer',
  async (
    params: M.HarvestingOrderFetchParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response =
        await HarvestingOrderServices.findHarvestingOrdersByCustomer(params);
      return response;
    } catch (err) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao carregar pedidos para colher.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchHarvestingOrdersByProduct = createAsyncThunk(
  'harvestingOrders/fetchHarvestingOrdersByProducts',
  async (
    params: M.HarvestingOrderFetchParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response =
        await HarvestingOrderServices.findHarvestingOrdersByProduct(params);
      return response;
    } catch (err) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao carregar pedidos para colher.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const generatePrintList = createAsyncThunk(
  'harvestingOrders/generatePrintList',
  async (
    params: { siteId: number; interOrderIds: number[]; volOrderIds: number[] },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await HarvestingOrderServices.generateListToPrint(
        params
      );
      return response.data;
    } catch (err) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao carregar pedidos para colher.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const updateHarvestingOrders = createAsyncThunk(
  'harvestingOrders/updateHarvestingOrders',
  async (
    params: {
      itemId: number;
      origin: string;
      internalObservation: string;
    }[],
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response =
        await HarvestingOrderServices.updateHarvestingOrdersRequest(params);
      return response;
    } catch (err) {
      const errorMessage = generateErrorMessage(err, 'Erro ao alterar pedido.');
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchAllPrintData = createAsyncThunk(
  'harvestingOrders/fetchAllPrintData',
  async (siteId: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await HarvestingOrderServices.getAllPrintData(siteId);
      return response;
    } catch (err) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao carregar gerenciamento de impressão.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const DeletePrintByIds = createAsyncThunk(
  'harvestingOrders/DeletePrintByIds',
  async (ids: number[], { dispatch, rejectWithValue }) => {
    try {
      const response = await HarvestingOrderServices.DeletePrintListByIds(ids);
      dispatch(alertRequest('Excluído com sucesso!', 'success'));
      return response;
    } catch (err) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao deletar lista de impressão.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

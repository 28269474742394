import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/lib/locale/pt_BR';
import { I18nextProvider } from 'react-i18next';
import moment from 'moment';
import * as dotenv from 'dotenv';

import i18n from './i18n';
import store, { persistor } from './store';
import { GlobalProvider } from './context/GlobalContext';
import GlobalStyle from './styles/GlobalStyle';
import GitInfoModal from './components/Modals/GitInfoModal';
import history from './services/history';
import App from './App';

import '~/config/ReactotronConfig';

import 'moment/locale/pt-br';

dotenv.config();

moment.locale('pt-br');
moment().isoWeek();
moment.defineLocale('pt-br', {
  week: {
    dow: 0,
    doy: 4
  }
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <I18nextProvider i18n={i18n}>
            <ConfigProvider locale={ptBR}>
              <GlobalProvider>
                <App />
                <GitInfoModal />
                <GlobalStyle />
              </GlobalProvider>
            </ConfigProvider>
          </I18nextProvider>
        </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefaultPagination } from '~/domain/model';
import ShippingCompany from '~/domain/model/ShippingCompany/ShippingCompany';
import ShippingCompanyEdit from '~/domain/model/ShippingCompany/ShippingCompanyEdit';
import {
  createShippingCompany,
  deleteShippingCompany,
  editShippingCompany,
  favoriteShippingCompany,
  fetchShippingCompanies,
  fetchShippingCompaniesHistory
} from './apiCalls';
import { FindHistory } from '~/domain/api';
import * as API from '~/domain/api';

export interface ShippingCompaniesState {
  shippingCompany: API.ShippingCompany;
  shippingCompanies: ShippingCompany[];
  shippingCompaniesPagination: DefaultPagination;
  totalShippingCompany: number;
  shippingCompanyEdit: ShippingCompanyEdit[];
  shippingCompanyHistory: FindHistory;
  closeShippingCompanyHistoryModal: boolean;
  closeAddShippingCompanyModal: boolean;
  closeEditShippingCompanyModal: boolean;
  submitFormFilter: boolean;
  currentStep: number;
  typeContractedStep: number;
  typeOwnStep: number;
  submitNow: boolean;
  loading: boolean;
}

const initialState: ShippingCompaniesState = {
  shippingCompany: {
    id: 0,
    siteId: 0,
    favorite: false,
    name: '',
    type: 'P',
    cnpj: '',
    address: {
      id: 0,
      cityId: 0,
      stateId: 24,
      address: '',
      stateRegistration: ''
    },
    vehicle: {
      id: 0,
      stateId: 24,
      stateName: null,
      stateAcronym: null,
      type: 'initial',
      shippingAccount: 'E',
      vehicleNumber: 0,
      quantity: 0,
      plate: '',
      grossWeight: 0,
      netWeight: 0,
      color: '',
      brand: 'initial',
      chest: false,
      isolation: false,
      refrigerated: false
    }
  },
  shippingCompanies: [],
  shippingCompaniesPagination: {
    PageSize: 100,
    PageNumber: 1
  },
  submitFormFilter: false,
  totalShippingCompany: 0,
  shippingCompanyEdit: [],
  shippingCompanyHistory: {
    shippingCompany: [],
    shippingCompanyAddressHistoryQuery: [],
    shippingCompanyVehicleHistoryQuery: []
  },
  closeShippingCompanyHistoryModal: false,
  closeAddShippingCompanyModal: false,
  closeEditShippingCompanyModal: false,
  currentStep: 0,
  submitNow: false,
  typeContractedStep: 3,
  typeOwnStep: 1,
  loading: false
};

export const prefix = 'shippingCompanies';

const shippingCompanySlice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: () => initialState,
    setShippingCompaniesPagination: (
      state,
      action: PayloadAction<DefaultPagination>
    ) => {
      state.shippingCompaniesPagination = action.payload;
    },
    setTotalShippingCompanies: (state, action: PayloadAction<number>) => {
      state.totalShippingCompany = action.payload;
    },
    setShippingCompanies: (state, action: PayloadAction<ShippingCompany[]>) => {
      state.shippingCompanies = action.payload;
    },
    setCloseModal: (state, action: PayloadAction<boolean>) => {
      state.closeAddShippingCompanyModal = action.payload;
    },
    setCloseEditModal: (state, action: PayloadAction<boolean>) => {
      state.closeEditShippingCompanyModal = action.payload;
    },
    nextStep: state => {
      state.currentStep += 1;
    },
    city: state => {
      state.shippingCompany.address.cityId = 0;
    },
    state: state => {
      state.shippingCompany.address.stateId = 0;
    },
    previousStep: state => {
      state.currentStep -= 1;
    },
    setCurrentEditContracted: state => {
      state.currentStep = initialState.typeContractedStep;
    },
    setCurrentEditOnw: state => {
      state.currentStep = initialState.typeOwnStep;
    },
    setSubmitNow: state => {
      state.submitNow = true;
    },
    setSubmitFalse: state => {
      state.submitNow = false;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setShippingCompany: (state, action: PayloadAction<API.ShippingCompany>) => {
      state.shippingCompany = action.payload;
    },
    resetShippingCompany: state => {
      state.shippingCompany = initialState.shippingCompany;
    },
    setPropertyValue: (
      state,
      action: PayloadAction<{
        value: any;
        property: string;
      }>
    ) => {
      const { payload } = action;
      state.shippingCompany = {
        ...state.shippingCompany,
        [payload.property]: payload.value
      };
    },
    setAddressPropertyValue: (
      state,
      action: PayloadAction<{
        value: any;
        property: string;
      }>
    ) => {
      const { payload } = action;
      state.shippingCompany = {
        ...state.shippingCompany,
        address: {
          ...state.shippingCompany.address,
          [payload.property]: payload.value
        }
      };
    },
    setVehiclePropertyValue: (
      state,
      action: PayloadAction<{
        value: any;
        property: string;
      }>
    ) => {
      const { payload } = action;
      state.shippingCompany = {
        ...state.shippingCompany,
        vehicle: {
          ...state.shippingCompany.vehicle,
          [payload.property]: payload.value
        }
      };
    },
    setSubmitFormFilter: (state, action: PayloadAction<boolean>) => {
      state.submitFormFilter = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchShippingCompanies.fulfilled, (state, action) => {
      state.totalShippingCompany = action.payload.totalCount;
      state.shippingCompanies = action.payload.results;
      state.currentStep = action.payload.totalCount > 2 ? 2 : 1;
      state.submitFormFilter = false;
    });
    builder.addCase(
      fetchShippingCompaniesHistory.fulfilled,
      (state, action: PayloadAction<FindHistory>) => {
        state.shippingCompanyHistory = action.payload;
      }
    );
    builder.addCase(
      deleteShippingCompany.fulfilled,
      (state, action: PayloadAction<number>) => {
        state.shippingCompanies = state.shippingCompanies.filter(
          shippingCompany => shippingCompany.id !== action.payload
        );
      }
    );
    builder.addCase(
      favoriteShippingCompany.fulfilled,
      (state, action: PayloadAction<{ value: boolean; id: number }>) => {
        state.shippingCompanies = state.shippingCompanies.map(
          shippingCompany => {
            if (action.payload.id === shippingCompany.id) {
              return { ...shippingCompany, favorite: action.payload.value };
            }
            return shippingCompany;
          }
        );
        state.submitFormFilter = true;
      }
    );
    builder.addCase(createShippingCompany.fulfilled, state => {
      state.closeAddShippingCompanyModal = true;
      state.submitFormFilter = true;
      state.submitNow = false;
    });
    builder.addCase(editShippingCompany.fulfilled, state => {
      state.closeEditShippingCompanyModal = true;
      state.submitFormFilter = true;
      state.submitNow = false;
    });
  }
});

export const actions = shippingCompanySlice.actions;
export const reducer = shippingCompanySlice.reducer;

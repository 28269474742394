import IGfp from '~/@core/domain/model/gfp/IGfp';
import {
  BorderStyled,
  BorderStyledSquare,
  Column,
  ColumnItem,
  HorizontalDivider,
  Identifier,
  IdentifierLots,
  NumberBox,
  NumberBoxContainer,
  TextBold,
  VerticalDivider
} from '../styles';

interface ITablePrintLayoutProps {
  gfp: IGfp;
}

export default function TablePrintLayout({ gfp }: ITablePrintLayoutProps) {
  const letters = ['A', 'B', 'C', 'D', 'E', 'F'];

  // FIXME
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const items = [...gfp.items];

  while (items.length < letters.length) {
    items.push({
      productCode: '',
      productDescription: '',
      mcCode: '',
      quantityMC: null,
      quantityEmb: null,
      quantityUnit: null,
      unitPrice: null,
      quality: '',
      obs1: '',
      obs2: ''
    });
  }

  return (
    <>
      <Column height="10.68%">
        <ColumnItem width="2.30%">
          <BorderStyled
            padding="0.10rem"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <IdentifierLots as="h2">
              <TextBold>LOTES</TextBold>
            </IdentifierLots>
          </BorderStyled>
        </ColumnItem>
        <ColumnItem
          width="6.01%"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <BorderStyledSquare
            padding="0.10rem"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Identifier as="h2">
              <TextBold>COD. MC</TextBold>
            </Identifier>
          </BorderStyledSquare>
        </ColumnItem>
        <ColumnItem
          width="4.60%"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <BorderStyledSquare
            padding="0.10rem"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Identifier as="h2">
              <TextBold>QTD. MC</TextBold>
            </Identifier>
          </BorderStyledSquare>
        </ColumnItem>
        <ColumnItem
          width="18.40%"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <BorderStyledSquare
            padding="0.10rem"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '12px',
              textAlign: 'center'
            }}
          >
            <Identifier as="h2" style={{ textAlign: 'center' }}>
              <TextBold>CÓDIGO PRODUTO</TextBold>
            </Identifier>
          </BorderStyledSquare>
        </ColumnItem>
        <ColumnItem
          width="36.99%"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <BorderStyledSquare
            padding="0.10rem"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '12px',
              textAlign: 'center'
            }}
          >
            <NumberBoxContainer
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <TextBold
                style={{
                  fontSize: '25px'
                }}
              >
                {gfp?.box !== null ? gfp?.box : ''}
              </TextBold>
              <HorizontalDivider style={{ top: '40px' }} />
            </NumberBoxContainer>
            <Identifier
              as="h2"
              style={{ textAlign: 'center', marginTop: '20px' }}
            >
              <TextBold>DESCRIÇÃO DO PRODUTO</TextBold>
            </Identifier>
          </BorderStyledSquare>
        </ColumnItem>
        <ColumnItem
          width="4.42%"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <BorderStyledSquare
            padding="0.10rem"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '12px',
              textAlign: 'center'
            }}
          >
            <Identifier as="h2">
              <TextBold>QTD. EMB</TextBold>
            </Identifier>
          </BorderStyledSquare>
        </ColumnItem>
        <ColumnItem
          width="4.42%"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <BorderStyledSquare
            padding="0.10rem"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '12px',
              textAlign: 'center'
            }}
          >
            <Identifier as="h2">
              <TextBold>QTD. POR EMB.</TextBold>
            </Identifier>
          </BorderStyledSquare>
        </ColumnItem>
        <ColumnItem
          width="7.25%"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <BorderStyledSquare
            padding="0.10rem"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '12px',
              textAlign: 'center'
            }}
          >
            <Identifier as="h2">
              <TextBold>PREÇO UNITÁRIO</TextBold>
            </Identifier>
          </BorderStyledSquare>
        </ColumnItem>
        <ColumnItem
          width="4.42%"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <BorderStyledSquare
            padding="0.10rem"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Identifier as="h2">
              <TextBold>QUAL.</TextBold>
            </Identifier>
          </BorderStyledSquare>
        </ColumnItem>
        <ColumnItem width="11.2%">
          <BorderStyledSquare padding="0.10rem">
            <Identifier
              as="h2"
              style={{
                marginTop: '10px',
                textAlign: 'center'
              }}
            >
              <TextBold>OBSERVAÇÕES</TextBold>
            </Identifier>
            <NumberBoxContainer>
              <NumberBox
                style={{
                  marginTop: '10px',
                  textAlign: 'center'
                }}
              >
                01
              </NumberBox>
              <VerticalDivider />
              <HorizontalDivider />
              <NumberBox
                style={{
                  marginTop: '10px',
                  textAlign: 'center'
                }}
              >
                02
              </NumberBox>
            </NumberBoxContainer>
          </BorderStyledSquare>
        </ColumnItem>
      </Column>

      {items.map((item, index) => (
        <Column key={index} height="4.80%">
          <ColumnItem
            width="2.30%"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <BorderStyled
              padding="0.10rem"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Identifier
                as="h2"
                style={{
                  fontSize: '12px',
                  textAlign: 'center',
                  margin: '0 auto'
                }}
              >
                {letters[index]}
              </Identifier>
            </BorderStyled>
          </ColumnItem>
          <ColumnItem
            width="6.01%"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '12px',
              textAlign: 'center'
            }}
          >
            <BorderStyledSquare
              padding="0.10rem"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '12px',
                textAlign: 'center'
              }}
            >
              {item.mcCode}
            </BorderStyledSquare>
          </ColumnItem>
          <ColumnItem
            width="4.60%"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '12px',
              textAlign: 'center'
            }}
          >
            <BorderStyledSquare
              padding="0.10rem"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '12px',
                textAlign: 'center'
              }}
            >
              {item.quantityMC !== null ? item.quantityMC : ''}
            </BorderStyledSquare>
          </ColumnItem>
          <ColumnItem
            width="18.40%"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '12px',
              textAlign: 'center'
            }}
          >
            <BorderStyledSquare
              padding="0.10rem"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '12px',
                textAlign: 'center'
              }}
            >
              {item.productCode}
            </BorderStyledSquare>
          </ColumnItem>
          <ColumnItem
            width="36.99%"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'start',
              fontSize: '12px'
            }}
          >
            <BorderStyledSquare
              padding="0.10rem"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'start',
                fontSize: '12px'
              }}
            >
              {item.productDescription}
            </BorderStyledSquare>
          </ColumnItem>
          <ColumnItem
            width="4.42%"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '12px',
              textAlign: 'center'
            }}
          >
            <BorderStyledSquare
              padding="0.10rem"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '12px',
                textAlign: 'center'
              }}
            >
              {item.quantityEmb !== null ? item.quantityEmb : ''}
            </BorderStyledSquare>
          </ColumnItem>
          <ColumnItem
            width="4.42%"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '12px',
              textAlign: 'center'
            }}
          >
            <BorderStyledSquare
              padding="0.10rem"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '12px',
                textAlign: 'center'
              }}
            >
              {item.quantityUnit !== null ? item.quantityUnit : ''}
            </BorderStyledSquare>
          </ColumnItem>
          <ColumnItem
            width="7.25%"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '12px',
              textAlign: 'center'
            }}
          >
            <BorderStyledSquare
              padding="0.10rem"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '12px',
                textAlign: 'center'
              }}
            >
              {item.unitPrice !== null
                ? item.unitPrice.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })
                : ''}
            </BorderStyledSquare>
          </ColumnItem>
          <ColumnItem
            width="4.42%"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '12px',
              textAlign: 'center'
            }}
          >
            <BorderStyledSquare
              padding="0.10rem"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '12px',
                textAlign: 'center'
              }}
            >
              {item.quality}
            </BorderStyledSquare>
          </ColumnItem>
          <ColumnItem width="5.60%">
            <BorderStyledSquare
              padding="0.10rem"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '12px',
                textAlign: 'center'
              }}
            >
              {item.obs1 !== null ? item.obs1 : '0'}
            </BorderStyledSquare>
          </ColumnItem>
          <ColumnItem width="5.60%">
            <BorderStyledSquare
              padding="0.10rem"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '12px',
                textAlign: 'center'
              }}
            >
              {item.obs2 !== null ? item.obs2 : '0'}
            </BorderStyledSquare>
          </ColumnItem>
        </Column>
      ))}
    </>
  );
}

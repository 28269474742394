import { createSelector } from '~/helpers/util/selectors';
import { OfferChannelCreateState } from './duck';

export const getRoot = (state: any) => {
  return state.ui.offerChannelCreate as OfferChannelCreateState;
};

export const getCreateStatus = createSelector([getRoot], state => {
  return state.createStatus;
});

export const getRequestStatus = createSelector([getRoot], state => {
  return state.requestStatus;
});

export const getIsLoading = createSelector(
  [getCreateStatus, getRequestStatus],
  state => {
    return state === 'running';
  }
);

export const getShowOfferChannelCreate = createSelector([getRoot], state => {
  return state.showOfferChannelCreate;
});

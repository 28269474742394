import { ClearOutlined } from '@ant-design/icons';
import { Button } from 'antd';

interface ButtonClearProps {
  onClick: () => any;
  disabled?: boolean;
}
export default function ButtonClear(props: ButtonClearProps) {
  const { onClick, disabled } = props;
  return (
    <Button disabled={disabled} onClick={onClick}>
      <ClearOutlined />
    </Button>
  );
}

import styled from 'styled-components';
import { Col, DatePicker, Form } from 'antd';

const { RangePicker } = DatePicker;

export const FormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;

export const DeliveryDateDiv = styled(Col).attrs({ md: 20, xs: 36 })``;

export const DeliveryDateRangePicker = styled(RangePicker)`
  width: 100%;
`;

import {
  Packing,
  PackingDistribution,
  HistoryPackingDistribution,
  HistoryPackingDistributionComposition,
  Extract
} from '~/domain/model';

import {
  ActionTypes,
  CreatePacking,
  RegisterPackingModel,
  GetPackingDistributionRequest,
  GetExtractsRequest,
  GetAllPackingDistribution
} from './types';

export function createPacking(packing: CreatePacking) {
  return {
    type: ActionTypes.createPacking,
    payload: { packing }
  };
}

export function registerPackingModel(packingModel: RegisterPackingModel) {
  return {
    type: ActionTypes.registerPackingModel,
    payload: { packingModel }
  };
}

export function setDataRegisterPackingModel(
  packingModel: RegisterPackingModel | null
) {
  return {
    type: ActionTypes.setDataRegisterPackingModel,
    payload: { packingModel }
  };
}

export function deletePackingModel(packingModelId: number) {
  return {
    type: ActionTypes.deletePackingModel,
    payload: { packingModelId }
  };
}

export function savePackingDataRequest(
  packingDistribution: PackingDistribution
) {
  return {
    type: ActionTypes.savePackingDataRequest,
    payload: { packingDistribution }
  };
}

export function savePackingDataSuccess() {
  return {
    type: ActionTypes.savePackingDataSuccess
  };
}

export function savePackingDataFailure() {
  return {
    type: ActionTypes.savePackingDataFailure
  };
}

export function listPackingsRequest() {
  return {
    type: ActionTypes.listPackingsRequest
  };
}

export function listPackingsSuccess(packings: Packing[]) {
  return {
    type: ActionTypes.listPackingsSuccess,
    payload: { packings }
  };
}

export function listPackingsFailure() {
  return {
    type: ActionTypes.listPackingsFailure
  };
}

export function getExtractsRequest(filters: GetExtractsRequest) {
  return {
    type: ActionTypes.getExtractsRequest,
    payload: { filters }
  };
}

export function getExtractsSuccess(extracts: Extract[], totalExtracts: number) {
  return {
    type: ActionTypes.getExtractsSuccess,
    payload: { extracts, totalExtracts }
  };
}

export function getExtractsFailure() {
  return {
    type: ActionTypes.getExtractsFailure
  };
}

export function getByIdPackingDistributionRequest(
  packingDistributionId: number
) {
  return {
    type: ActionTypes.getByIdPackingDistributionRequest,
    payload: { packingDistributionId }
  };
}

export function getByIdPackingDistributionSuccess(
  packingDistribution: PackingDistribution
) {
  return {
    type: ActionTypes.getByIdPackingDistributionSuccess,
    payload: { packingDistribution }
  };
}

export function getByIdPackingDistributionFailure() {
  return {
    type: ActionTypes.getByIdPackingDistributionFailure
  };
}

export function getPackingDistributionRequest(
  filters: GetPackingDistributionRequest
) {
  return {
    type: ActionTypes.getPackingDistributionRequest,
    payload: { filters }
  };
}

export function getPackingDistributionSuccess(
  packingDistribution: PackingDistribution[],
  totalPackingDistribution: number
) {
  return {
    type: ActionTypes.getPackingDistributionSuccess,
    payload: { packingDistribution, totalPackingDistribution }
  };
}

export function getPackingDistributionFailure() {
  return {
    type: ActionTypes.getPackingDistributionFailure
  };
}

export function getAllPackingDistribution(filters: GetAllPackingDistribution) {
  return {
    type: ActionTypes.getAllPackingDistribution,
    payload: { filters }
  };
}

export function clearGetAllPackingDistribution() {
  return {
    type: ActionTypes.clearGetAllPackingDistribution
  };
}

export function getAllPackingDistributionSuccess(
  allPackingDistribution: Packing[]
) {
  return {
    type: ActionTypes.getAllPackingDistributionSuccess,
    payload: { allPackingDistribution }
  };
}

export function getAllPackingDistributionFailure() {
  return {
    type: ActionTypes.getAllPackingDistributionFailure
  };
}

export function getHistoryPackingDistributionRequest(id: number) {
  return {
    type: ActionTypes.getHistoryPackingDistributionRequest,
    payload: { id }
  };
}

export function getHistoryPackingDistributionSuccess(
  historyPackingDistribution: HistoryPackingDistribution[]
) {
  return {
    type: ActionTypes.getHistoryPackingDistributionSuccess,
    payload: { historyPackingDistribution }
  };
}

export function getHistoryPackingDistributionFailure() {
  return {
    type: ActionTypes.getHistoryPackingDistributionFailure
  };
}

export function getHistoryPackingDistributionCompositionRequest(id: number) {
  return {
    type: ActionTypes.getHistoryPackingDistributionCompositionRequest,
    payload: { id }
  };
}

export function getHistoryPackingDistributionCompositionSuccess(
  historyPackingDistributionComposition: HistoryPackingDistributionComposition[]
) {
  return {
    type: ActionTypes.getHistoryPackingDistributionCompositionSuccess,
    payload: { historyPackingDistributionComposition }
  };
}

export function getHistoryPackingDistributionCompositionFailure() {
  return {
    type: ActionTypes.getHistoryPackingDistributionCompositionFailure
  };
}

export function toggleCreateModal(state: boolean) {
  return {
    type: ActionTypes.toggleCreateModal,
    payload: { state }
  };
}

export function loadingCreateModal(state: boolean) {
  return {
    type: ActionTypes.loadingCreateModal,
    payload: { state }
  };
}

export function toggleRegisterModelModal(state: boolean, packingId?: number) {
  return {
    type: ActionTypes.toggleRegisterModelModal,
    payload: { state, packingId }
  };
}

export function loadingRegisterModelModal(state: boolean) {
  return {
    type: ActionTypes.loadingRegisterModelModal,
    payload: { state }
  };
}

export function toggleHistoryModal(
  state: boolean,
  packingDistributionId?: number
) {
  return {
    type: ActionTypes.toggleHistoryModal,
    payload: { state, packingDistributionId }
  };
}

export function toggleHistoryModelModal(
  state: boolean,
  packingDistributionCompositionId?: number
) {
  return {
    type: ActionTypes.toggleHistoryModelModal,
    payload: { state, packingDistributionCompositionId }
  };
}

export function setPagePagination(page: number) {
  return {
    type: ActionTypes.setPagePagination,
    payload: { page }
  };
}

export function setPageSizePagination(pageSize: number) {
  return {
    type: ActionTypes.setPageSizePagination,
    payload: { pageSize }
  };
}

export function resetPackingDistribution() {
  return {
    type: ActionTypes.resetPackingDistribution
  };
}

export function resetPackingState() {
  return {
    type: ActionTypes.resetPackingState
  };
}
export function resetPackingExtractState() {
  return {
    type: ActionTypes.resetPackingExtractState
  };
}
export function setSubmitForm(submitForm: boolean) {
  return {
    type: ActionTypes.setSubmitForm,
    payload: { submitForm }
  };
}

export function removeModel(modelId: number) {
  return {
    type: ActionTypes.removeModel,
    payload: { modelId }
  };
}

export function removeModelSuccess() {
  return {
    type: ActionTypes.removeModelSuccess
  };
}
export function removeModelFailure() {
  return {
    type: ActionTypes.removeModelFailure
  };
}

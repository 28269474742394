import { Col, Form, FormInstance } from 'antd';
import { AxiosError } from 'axios';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GetGfpCarts from '~/@core/application/useCases/gfp/getGfpCarts';
import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';
import GfpCarts from '~/@core/domain/model/gfp/GfpCarts';
import FormFilterGfpCartsModel, {
  MapFormToFilter
} from '~/@core/domain/ui/forms/FormFilterGfpCartsModel';
import ButtonClear from '~/components/atoms/ButtonClear';
import ButtonFilter from '~/components/atoms/ButtonFilter';
import ButtonSearch from '~/components/atoms/ButtonSearch';
import DeliveryDateSelect from '~/components/atoms/Inputs/DeliveryDateSelect';
import SitesSelect from '~/components/atoms/Inputs/SitesSelect';
import DefaultRow from '~/components/bosons/DefaultRow';
import { GFPContext } from '~/context/GFPContext';
import { globalContext } from '~/context/GlobalContext';
import useGfpStore from '~/zustand/gfp/gfp-service-state';
import HideFilters from './HideFilters';

interface Props {
  form: FormInstance<any>;
}

export default function FormFilterManagementGFP(props: Props) {
  const { form } = props;
  const { t } = useTranslation();

  const { setSiteIdSelected, handlePromiseUseCase, activeAlert } =
    useContext(globalContext);
  const {
    gfpCarts,
    setGfpCarts,
    gfpCartsPagination,
    setGfpCartsPagination,
    visibleRegister,
    setVisibleRegister,
    setDeliveryDateSelected,
    isPageLoading,
    setIsPageLoading
  } = useContext(GFPContext);

  const { setFilters, pagination } = useGfpStore();

  useEffect(() => {
    setDeliveryDateSelected([]);
  }, []);

  const [showFilters, setShowFilters] = useState<boolean>(false);

  const resetFieldsOnChangeSite = useCallback(() => {
    form.resetFields([
      'DeliveryDate',
      'CategoryId',
      'GroupId',
      'ProductId',
      'RecipientId',
      'ColorId',
      'ProductQualityId',
      'ExpeditionId',
      'CustomerId',
      'OrderNumber',
      'division',
      'status',
      'gfpNumber',
      'printStatus'
    ]);
  }, [form]);

  const handleChangeSite = useCallback(
    inputValue => {
      setSiteIdSelected(inputValue);
      resetFieldsOnChangeSite();
    },
    [resetFieldsOnChangeSite, setSiteIdSelected]
  );

  const handleClearSite = useCallback(() => {
    form.resetFields(['siteId']);
    setSiteIdSelected(Number.NaN);
    resetFieldsOnChangeSite();
  }, []);

  const handleCalendarChange = useCallback((value: any) => {
    setDeliveryDateSelected(value);
  }, []);

  const onSubmitManagementFilter = useCallback(
    (formValues: FormFilterGfpCartsModel) => {
      setFilters(formValues);
      const filterGfpCarts = MapFormToFilter.toFilter(formValues, {
        pageSize: 10000,
        pageNumber: 1
      });

      if (!visibleRegister) setVisibleRegister(!visibleRegister);
      const getGfpCarts = new GetGfpCarts();
      if (!isPageLoading) setIsPageLoading(true);
      if (gfpCarts.length > 0) setGfpCarts([]);
      handlePromiseUseCase<DefaultPaginated<GfpCarts[]>, AxiosError>(
        getGfpCarts.execute(filterGfpCarts),
        gfpCartsOut => {
          setIsPageLoading(false);
          setGfpCarts(gfpCartsOut.results);
          setGfpCartsPagination({
            ...gfpCartsPagination,
            total: gfpCartsOut.totalCount
          });

          useGfpStore.getState().setSelectedRowKeys([]);
          useGfpStore.getState().setSelectedGfpIds([]);
        },
        error => {
          setIsPageLoading(false);
          activeAlert({
            message: JSON.stringify(
              error.response?.data
                ? error.response?.data
                : 'Houve um erro inesperado'
            ),
            type: 'error',
            timeout: 5000
          });
        }
      );
    },
    [
      activeAlert,
      gfpCartsPagination,
      handlePromiseUseCase,
      isPageLoading,
      gfpCarts,
      setGfpCarts,
      setGfpCartsPagination,
      setIsPageLoading,
      setVisibleRegister,
      visibleRegister,
      setFilters,
      pagination
    ]
  );

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onSubmitManagementFilter}
      style={{ marginTop: '20px' }}
    >
      <DefaultRow>
        <Col lg={15}>
          <SitesSelect
            onChange={handleChangeSite}
            onClear={handleClearSite}
            form={form}
          />
        </Col>
        <Col lg={6}>
          <Form.Item
            name="DeliveryDate"
            label={t('gfp.filters.deliveryDateInput')}
            rules={[
              { required: true, message: 'Selecione a data de entrega.' }
            ]}
          >
            <DeliveryDateSelect
              onCalendarChange={value => handleCalendarChange(value)}
            />
          </Form.Item>
        </Col>
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          <ButtonFilter onClick={() => setShowFilters(!showFilters)} />
        </Col>
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          <ButtonSearch onClick={() => form.submit()} />
        </Col>
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          <ButtonClear onClick={handleClearSite} />
        </Col>
        {showFilters ? <HideFilters form={form} /> : null}
      </DefaultRow>
    </Form>
  );
}

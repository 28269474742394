// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Moment } from 'moment';
import * as Models from '~/domain/model';
import Unpublished, { SalesItems } from '~/domain/model/Orders/Unpublished';
import { currencyParser } from '~/helpers/util/number';
import * as OrderCalls from './apiCalls';

export const prefix = 'unpublished';

export type SetPropertyAction = {
  value: any;
  property: string;
  id: number;
};

export type SubTotalPrice = {
  value: number;
  id: string;
};

export interface UnpublishedState {
  unpublishedPagination: Models.DefaultPagination;
  pagination: Models.DefaultPagination;
  unpublished: Unpublished[];
  selectListIds: React.Key[];
  selectList: Unpublished[];
  unpublishedEdit: Unpublished | undefined;
  unpublishedSinglePublished: Unpublished | undefined;
  unpublishedItems: SalesItems[] | undefined;
  showEditUnpublishedModal: boolean;
  currentStep: number;
  reloadPage: boolean;
  unpublishedUpadeList: Models.UnpublishedUpdate[] | undefined;
  deliveryDate: string;
  commercialDate: string;
  deliveryDateHold: Moment | undefined;
  commercialDateHold: Moment | undefined;
  customerId: number | undefined;
  totalPriceOffers: SubTotalPrice[];
  opNatureIdSelected: number | undefined;
}

const initialState: UnpublishedState = {
  unpublishedPagination: { PageNumber: 1, PageSize: 100 },
  pagination: { PageSize: 100 } as Models.DefaultPagination,
  unpublished: [],
  selectListIds: [],
  selectList: [],
  unpublishedEdit: undefined,
  unpublishedSinglePublished: undefined,
  unpublishedItems: [],
  showEditUnpublishedModal: false,
  currentStep: 1,
  reloadPage: false,
  unpublishedUpadeList: [],
  deliveryDate: '',
  commercialDate: '',
  deliveryDateHold: undefined,
  commercialDateHold: undefined,
  customerId: undefined,
  totalPriceOffers: [],
  opNatureIdSelected: undefined
};
const ordersSlice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setOpNatureIdSelected: (state, { payload }) => {
      state.opNatureIdSelected = payload;
    },

    setUnpublishedPagination: (
      state,
      action: PayloadAction<Models.DefaultPagination>
    ) => {
      state.unpublishedPagination = action.payload;
    },
    setSelectListIds: (state, action: PayloadAction<React.Key[]>) => {
      state.selectListIds = action.payload;
    },
    setSelectList: (state, action: PayloadAction<Unpublished[]>) => {
      state.selectList = action.payload;
    },
    setShowEditUnpublishedModal: (state, action: PayloadAction<boolean>) => {
      state.showEditUnpublishedModal = action.payload;
    },
    setUnpublishedEdit: (state, action: PayloadAction<Unpublished>) => {
      state.unpublishedEdit = action.payload;
    },
    setReloadPage: (state, action: PayloadAction<boolean>) => {
      state.reloadPage = action.payload;
    },
    setCustomerId: (state, action: PayloadAction<number | undefined>) => {
      state.customerId = action.payload;
    },
    setUnpublishedSinglePublished: (
      state,
      action: PayloadAction<Unpublished>
    ) => {
      state.unpublishedSinglePublished = action.payload;
    },
    setPagination: (state, action: PayloadAction<Models.DefaultPagination>) => {
      state.pagination = action.payload;
    },
    setUnpublishedItems: (
      state,
      action: PayloadAction<SalesItems[] | undefined>
    ) => {
      state.unpublishedItems = action.payload;
    },
    setUnpublishedUpdateList: (state, action: PayloadAction<[]>) => {
      state.unpublishedUpadeList = action.payload;
    },
    setDeliveryDate: (state, action: PayloadAction<string>) => {
      state.deliveryDate = action.payload;
    },
    setCommercialDate: (state, action: PayloadAction<string>) => {
      state.commercialDate = action.payload;
    },
    setDeliveryDateHold: (state, action: PayloadAction<Moment>) => {
      state.deliveryDateHold = action.payload;
    },
    setCommercialDateHold: (state, action: PayloadAction<Moment>) => {
      state.commercialDateHold = action.payload;
    },
    setPropertyValue: (state, action: PayloadAction<SetPropertyAction>) => {
      const { payload } = action;
      const updatedTableData: any | undefined = state.unpublishedItems?.map(
        (offer: any) => {
          if (offer.id === payload.id) {
            const updatedData = offer;
            updatedData[payload.property] = payload.value;
            return updatedData;
          }
          return offer;
        }
      );
      state.unpublishedItems = updatedTableData;
      if (state.unpublishedEdit) {
        state.unpublishedEdit.directSaleItems = updatedTableData;
      }
    },
    setDeleteOffer: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      const newTableData: any[] | undefined = state.unpublishedItems?.filter(
        (offer: any) => offer.id !== payload
      );
      const offerDeleted: any | undefined = state.unpublishedItems?.find(
        (offer: any) => offer.id === payload
      );

      const data: Models.UnpublishedUpdate[] | undefined =
        state.unpublishedUpadeList;
      data?.push({
        id: offerDeleted?.id,
        packingId: offerDeleted?.packingId,
        quantityPacking: offerDeleted?.quantityPacking,
        quantityUnit: offerDeleted?.quantityUnit,
        unitPrice: currencyParser(offerDeleted?.unitPrice),
        observationText: offerDeleted?.observationText,
        observationId1: offerDeleted?.idObservation1,
        observationId2: offerDeleted?.idObservation2,
        isDeleted: true,
        commercialDate: offerDeleted.commercialDate,
        deliveryDate: offerDeleted.deliveryDate
      });

      state.unpublishedUpadeList = data;
      state.unpublishedItems = newTableData;
    },
    clearData: state => {
      state.unpublished = [];
    },
    setTotalPriceOffers: (state, action: PayloadAction<SubTotalPrice>) => {
      const { payload } = action;
      const subTotalFind = state.totalPriceOffers?.filter(
        product => product.id === payload.id
      ).length;
      if (subTotalFind > 0) {
        const updatedSubTotalList: any | undefined =
          state.totalPriceOffers?.map((product: any) => {
            if (product.id === payload.id) {
              return payload;
            }
            return product;
          });
        state.totalPriceOffers = updatedSubTotalList;
      } else {
        const addSubTotalList = [...state.totalPriceOffers];
        addSubTotalList.push(payload);
        state.totalPriceOffers = addSubTotalList;
      }
    },
    setClearTotalPriceOffers: state => {
      state.totalPriceOffers = [];
    }
  },
  extraReducers(builder) {
    builder.addCase(
      OrderCalls.fetchUnpublished.fulfilled,
      (state, action: PayloadAction<Unpublished | any>) => {
        state.unpublished = action.payload;
      }
    );
    builder.addCase(OrderCalls.publishUnpublished.rejected, state => {
      state.reloadPage = true;
    });
    builder.addCase(
      OrderCalls.publishUnpublished.fulfilled,
      (state, action: PayloadAction<boolean>) => {
        state.reloadPage = !!action.payload;
      }
    );
    builder.addCase(
      OrderCalls.deleteUnpublished.fulfilled,
      (state, action: PayloadAction<boolean>) => {
        state.reloadPage = action.payload;
      }
    );
    builder.addCase(
      OrderCalls.updateUnpublished.fulfilled,
      (state, action: PayloadAction<boolean>) => {
        state.reloadPage = action.payload;
      }
    );
  }
});

export const actions = ordersSlice.actions;
export const reducer = ordersSlice.reducer;

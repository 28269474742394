import { createSelector } from 'reselect';
import { TagPrintingState } from '.';

// #FIXME
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRoot = (state: any) => state.tagPrinting;

export const getTagsByProduct = createSelector(
  [getRoot],
  (state: TagPrintingState) => state.tagsByProduct
);
export const getTagsByCustomer = createSelector(
  [getRoot],
  (state: TagPrintingState) => state.tagsByCustomer
);

export const getPrintedList = createSelector(
  [getRoot],
  (state: TagPrintingState) => state.printedList
);

export const isTagsLoading = createSelector([getRoot], state => state.loading);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefaultPagination } from '~/domain/model';
import * as M from '~/domain/model';
import {
  createBiDashboard,
  deleteBiDashboard,
  editBiDashboard,
  fetchBiDashboard
} from './apiCalls';

export interface BiDashboardState {
  biDashboard: M.BIDashboard;
  biDashboards: M.BIDashboard[];
  biDashboardPagination: DefaultPagination;
  totalBiDashboard: number;
  biDashboardEdit: M.BiDashboardEdit[];
  closeAddBiDashboardModal: boolean;
  closeEditBiDashboardModal: boolean;
  submitFormFilter: boolean;
  submitNow: boolean;
  loading: boolean;
  viewDashboardVisible: boolean;
}

const initialState: BiDashboardState = {
  biDashboard: {
    id: 0,
    name: '',
    url: '',
    token: '',
    status: true,
    roles: [],
    expirationTokenDate: ''
  },
  biDashboards: [],
  biDashboardPagination: {
    PageSize: 100,
    PageNumber: 1
  },
  submitFormFilter: false,
  totalBiDashboard: 0,
  biDashboardEdit: [],
  closeAddBiDashboardModal: false,
  closeEditBiDashboardModal: false,
  submitNow: false,
  loading: false,
  viewDashboardVisible: false
};

export const prefix = 'biDashboard';

const biDashboardSlice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: () => initialState,
    setBiDashboardPagination: (
      state,
      action: PayloadAction<DefaultPagination>
    ) => {
      state.biDashboardPagination = action.payload;
    },
    setTotalBiDashboard: (state, action: PayloadAction<number>) => {
      state.totalBiDashboard = action.payload;
    },
    setBiDashboards: (state, action: PayloadAction<M.BIDashboard[]>) => {
      state.biDashboards = action.payload;
    },
    setViewDashboardVisible: (state, action: PayloadAction<boolean>) => {
      state.viewDashboardVisible = action.payload;
    },
    setCloseModal: (state, action: PayloadAction<boolean>) => {
      state.closeAddBiDashboardModal = action.payload;
    },
    setCloseEditModal: (state, action: PayloadAction<boolean>) => {
      state.closeEditBiDashboardModal = action.payload;
    },
    setSubmitNow: state => {
      state.submitNow = true;
    },
    setSubmitFalse: state => {
      state.submitNow = false;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setBiDashboard: (state, action: PayloadAction<M.BIDashboard>) => {
      state.biDashboard = action.payload;
    },
    resetBiDashboard: state => {
      state.biDashboard = initialState.biDashboard;
    },
    setSubmitFormFilter: (state, action: PayloadAction<boolean>) => {
      state.submitFormFilter = action.payload;
    },
    setPropertyValue: (
      state,
      action: PayloadAction<{
        value: any;
        property: string;
      }>
    ) => {
      const { payload } = action;
      state.biDashboard = {
        ...state.biDashboard,
        [payload.property]: payload.value
      };
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchBiDashboard.fulfilled, (state, action) => {
      state.totalBiDashboard = action.payload.totalCount;
      state.biDashboards = action.payload.results;
      state.submitFormFilter = false;
    });
    builder.addCase(
      deleteBiDashboard.fulfilled,
      (state, action: PayloadAction<number>) => {
        state.biDashboards = state.biDashboards.filter(
          biDashboard => biDashboard.id !== action.payload
        );
      }
    );
    builder.addCase(createBiDashboard.fulfilled, state => {
      state.closeAddBiDashboardModal = true;
      state.submitFormFilter = true;
      state.submitNow = false;
    });
    builder.addCase(editBiDashboard.fulfilled, state => {
      state.closeEditBiDashboardModal = true;
      state.submitFormFilter = true;
      state.submitNow = false;
    });
  }
});

export const actions = biDashboardSlice.actions;
export const reducer = biDashboardSlice.reducer;

import { BannerListState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.ui.bannerList as BannerListState;
};

export const getIsLoading = createSelector(
  [getRoot],
  state =>
    state.fetchStatus === 'pending' ||
    state.fetchBannersStatus === 'pending' ||
    state.updateStatusStatus === 'pending'
);

export const getFetchStatus = createSelector([getRoot], state => {
  return state.fetchStatus;
});

export const getFetchBannersStatus = createSelector([getRoot], state => {
  return state.fetchBannersStatus;
});

export const getPageNumber = createSelector([getRoot], state => {
  return state.pageNumber;
});

export const getPageSize = createSelector([getRoot], state => state.pageSize);

export const getTotalCount = createSelector(
  [getRoot],
  state => state.totalCount
);

export const getUpdateStatusStatus = createSelector([getRoot], state => {
  return state.updateStatusStatus;
});

export const getCreateStatus = createSelector([getRoot], state => {
  return state.createStatus;
});

export const getFetchBannerUrlStatus = createSelector([getRoot], state => {
  return state.fetchBannerUrlStatus;
});

export const getErrorMessage = createSelector([getRoot], state => {
  return state.errorMessage;
});

export const getViewBannerId = createSelector([getRoot], state => {
  return state.viewBannerId;
});

export const getBannerImage = createSelector([getRoot], state => {
  return state.bannerImage;
});

export const getIsCreateLoading = createSelector(
  [getRoot],
  state =>
    state.createStatus === 'pending' || state.fetchBannerUrlStatus === 'pending'
);

import { OrderService } from '~/@core/infrastructure/api/services';
import IUseCase from '../../IUseCase';
import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';
import FilterOrder from '~/@core/domain/filters/order/FilterOrder';
import Order from '~/@core/domain/model/order/Order';

export default class GetOrders
  implements IUseCase<FilterOrder, Promise<DefaultPaginated<Order[]>>>
{
  execute(filter: FilterOrder): Promise<DefaultPaginated<Order[]>> {
    return OrderService.fetchOrders(filter);
  }
}

import { createSelector } from 'reselect';

import { AutoserviceRequestsState } from './index';

export const getRoot = (state: any) => {
  return state.autoserviceRequests as AutoserviceRequestsState;
};

export const getRequestsObject = createSelector(
  [getRoot],
  state => state.requestsObject
);

export const getDetailsRequest = createSelector(
  [getRoot],
  state => state.detailsRequest
);

export const getDetailsRequestLines = createSelector(
  [getRoot],
  state => state.detailsRequestLines
);

export const getDetailsObjectMock = createSelector(
  [getRoot],
  state => state.detatilsObject
);

export const getDetailsObjectOrder = createSelector(
  [getRoot],
  state => state.detatilsObjectOrder
);

export const getListNewProducts = createSelector(
  [getRoot],
  state => state.listNewProducts
);

export const getListAddProducts = createSelector(
  [getRoot],
  state => state.listAddProducts
);

export const getChosenList = createSelector(
  [getRoot],
  state => state.chosenList
);

export const getPagination = createSelector([getRoot], state => {
  return state.pagination;
});

export const getReloadPageData = createSelector(
  [getRoot],
  state => state.reloadPageData
);

export const getOffersList = createSelector(
  [getRoot],
  state => state.offersList
);

export const getHeaderOffers = createSelector(
  [getRoot],
  state => state.headerOffers
);

export const getListOffers = createSelector(
  [getRoot],
  state => state.listOffers
);

export const getCustomerIdReload = createSelector(
  [getRoot],
  state => state.customerIdReload
);

export const getLineOffersList = createSelector(
  [getRoot],
  state => state.lineOffersList
);

export const getProductsList = createSelector(
  [getRoot],
  state => state.productsList
);

export const getNewProduct = createSelector(
  [getRoot],
  state => state.newProduct
);

export const getFilterProduct = createSelector(
  [getRoot],
  state => state.filterProduct
);

export const getOffersIds = createSelector([getRoot], state => state.offersIds);

export const getcheckout = createSelector([getRoot], state => state.checkout);

export const getfilterReload = createSelector(
  [getRoot],
  state => state.filterReload
);

export const getAddPlan = createSelector([getRoot], state => state.addPlan);

export const getTabSelfService = createSelector(
  [getRoot],
  state => state.tabSelfService
);

export const getHistoryData = createSelector(
  [getRoot],
  state => state.historyData
);

export const getCustomerIdForProducts = createSelector(
  [getRoot],
  state => state.customerIdForProducts
);

export const getSubTotalPriceList = createSelector(
  [getRoot],
  state => state.subTotalPriceList
);

export const getSubmitOffers = createSelector(
  [getRoot],
  state => state.submitOffers
);

export const getIdsProductAccepted = createSelector(
  [getRoot],
  state => state.idsProductsAccepted
);

export const getCountConfirmation = createSelector(
  [getRoot],
  state => state.countConfirmation
);

export const getCheckoutNewProduct = createSelector(
  [getRoot],
  state => state.checkoutNewProduct
);

export const getCheckoutPlus = createSelector(
  [getRoot],
  state => state.checkoutPlus
);

export const getChosenListSelected = createSelector(
  [getRoot],
  state => state.chosenListSelected
);

export const getCleanTrigger = createSelector(
  [getRoot],
  state => state.cleanTrigger
);

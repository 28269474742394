import IUseCase from '~/@core/application/IUseCase';
import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';
import FilterData from '~/@core/domain/filters/rfidReports/FilterData';
import Movement from '~/@core/domain/model/rfidReports/Movement';
import { MovementService } from '~/@core/infrastructure/api/services/rfidReports';

export default class GetDones
  implements IUseCase<FilterData, Promise<DefaultPaginated<Movement[]>>>
{
  execute(filter: FilterData): Promise<DefaultPaginated<Movement[]>> {
    return MovementService.fetchDones(filter);
  }
}

// eslint-disable-next-line import/no-extraneous-dependencies
import { call, put } from '@redux-saga/core/effects';
import { AxiosResponse } from 'axios';
import { DeliveryPatternCreateAction } from 'store/modules/_ui/deliveryPattern-create/duck';
import api from '~/services/api';
import * as API from '~/domain/api';
import { DeliveryPattern } from '~/domain/model';
import { actions } from '~/store/modules/_core';
import {
  DeliveryPatternEnable,
  FetchDeliveryPatterns
} from '../../_ui/deliveryPattern-list/duck';

export function* findManyBySiteId(payload: FetchDeliveryPatterns) {
  const response: AxiosResponse = yield call(
    api.get,
    `/site-delivery-pattern?siteId=${payload.siteId}&enabled=${payload.enabled}`
  );

  const apiResponse: API.DeliveryPattern[] = response.data;
  if (apiResponse.length > 0) {
    const deliveryPatternsById = new Map(
      apiResponse.map(deliveryPattern => [
        String(deliveryPattern.id),
        new DeliveryPattern(deliveryPattern)
      ])
    );
    yield put(actions.deliveryPattern.update({ deliveryPatternsById }));
  }
}

export function* create(payload: DeliveryPatternCreateAction): any {
  yield call(api.put, '/site-delivery-pattern', payload);
}
export function* deletePattern(payload: DeliveryPatternEnable) {
  yield call(
    api.put,
    `/site-delivery-pattern/enabled?siteDeliveryPatternId=${payload.deliveryPatternId}&enabled=${payload.enabled}`
  );
}

export function* favoritePattern(payload: number) {
  yield call(
    api.put,
    `/site-delivery-pattern/favorite?siteDeliveryPatternId=${payload}`
  );
}

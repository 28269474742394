import { call, fork, put, takeLatest } from 'redux-saga/effects';

import * as API from '~/domain/api';

import {
  actions,
  FetchBanners,
  FetchBannerUrl,
  Types,
  UpdateStatus
} from './duck';
import * as BannerService from '~/store/modules/_core/banners/service';
import * as BannerTypesService from '~/store/modules/_core/bannerTypes/service';

import { Action } from '~/helpers/util/reducers';
import { APIError } from '~/domain/api/errors';

export function* watchFetchBannersSaga(action: Action<FetchBanners>) {
  yield fork(fetchBannersSaga, action.payload);
}

export function* watchUpdateStatusSaga(action: Action<UpdateStatus>) {
  yield fork(updateStatusSaga, action.payload);
}

export function* watchFetchBannerUrl(action: Action<FetchBannerUrl>) {
  yield fork(fetchBannerUrlById, action.payload.bannerId);
}

export function* fetchInitSaga(): any {
  try {
    yield call(BannerTypesService.fetchAll);
    yield put(actions.fetchSuccess());
  } catch (err) {
    yield put(actions.fetchFailure());
  }
}

export function* fetchBannersSaga(params: FetchBanners): any {
  const { bannerTypeId, description, isActive, pageNumber, pageSize } = params;
  try {
    const response: API.ServerResponse = yield call(
      BannerService.fetchMany,
      description,
      bannerTypeId,
      isActive,
      pageNumber,
      pageSize
    );
    const data: API.DefaultResponse = response.data;

    const totalCount = data.totalCount ?? 0;

    yield put(
      actions.fetchBannersSuccess({
        pageNumber,
        pageSize,
        totalCount
      })
    );
  } catch (err) {
    yield put(actions.fetchBannersFailure());
  }
}

export function* updateStatusSaga(param: UpdateStatus) {
  try {
    yield call(BannerService.updateStatus, param.id);
    yield put(actions.updateStatusSuccess());
  } catch (error) {
    if (error instanceof APIError) {
      yield put(actions.updateStatusFailure({ errorMessage: error.message }));
    } else {
      yield put(actions.updateStatusFailure({}));
    }
  }
}

export function* fetchBannerUrlById(bannerId: number): any {
  try {
    const base64String: string = yield call(
      BannerService.fetchBannerUrlById,
      bannerId
    );
    yield put(
      actions.fetchBannerUrlSuccess({
        bannerImage: base64String
      })
    );
  } catch (err) {
    yield put(actions.fetchBannerUrlFailure());
  }
}

export default [
  takeLatest(Types.FETCH_INIT, fetchInitSaga),
  takeLatest(Types.FETCH_BANNERS, watchFetchBannersSaga),
  takeLatest(Types.UPDATE_STATUS_INIT, watchUpdateStatusSaga),
  takeLatest(Types.FETCH_BANNER_URL, watchFetchBannerUrl)
];

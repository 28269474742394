import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '~/services/api';

import {
  productionAreaUnitListSuccess,
  productionAreaUnitListFailure
} from './actions';

export function* onList(): any {
  try {
    const response = yield call(api.get, `/production-area-unit`);

    const { data } = response;

    yield put(productionAreaUnitListSuccess(data));
  } catch (err) {
    yield put(productionAreaUnitListFailure());
  }
}

export default all([takeLatest('@productionAreaUnit/LIST_REQUEST', onList)]);

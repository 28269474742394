import { call, fork, put, takeLatest } from 'redux-saga/effects';

import * as API from '~/domain/api';

import { actions, FetchPage, HistoryInit, Types, UpdateInit } from './duck';
import { Action } from '~/helpers/util/reducers';
import * as PriceAlertService from '~/store/modules/_core/priceAlerts/service';

export function* watchFetchPage(action: Action<FetchPage>) {
  yield fork(fetchPage, action.payload);
}

export function* fetchPage(params: FetchPage): any {
  try {
    const { nextPage, nextPageSize } = params;

    const response: API.DefaultResponse = yield call(
      PriceAlertService.findMany,
      {
        nextPage,
        nextPageSize
      }
    );
    const totalCount = response.totalCount ?? 0;

    yield put(actions.fetchPageSuccess({ nextPage, nextPageSize, totalCount }));
  } catch (err) {
    yield put(actions.fetchPageFailure());
  }
}

export function* updateSaga(action: Action<UpdateInit>) {
  try {
    const data = action.payload;

    yield call(PriceAlertService.update, data);
    yield put(actions.updateSuccess());
  } catch (error) {
    yield put(actions.updateFailure({ requestStatus: 'failure' }));
  }
}

export function* watchFetchHistoryPage(action: Action<HistoryInit>) {
  yield fork(fetchHistoryPage, action.payload);
}

export function* fetchHistoryPage(params: HistoryInit): any {
  try {
    const { id, nextPage, nextPageSize } = params;

    const response: API.DefaultResponse = yield call(
      PriceAlertService.history,
      { id, nextPage, nextPageSize }
    );
    const totalCount = response.totalCount ?? 0;

    yield put(actions.historySuccess({ nextPage, nextPageSize, totalCount }));
  } catch (error) {
    yield put(actions.historyFailure({ requestStatus: 'failure' }));
  }
}

export default [
  takeLatest(Types.FETCH_PAGE, watchFetchPage),
  takeLatest(Types.UPDATE_INIT, updateSaga),
  takeLatest(Types.HISTORY_INIT, watchFetchHistoryPage)
];

import { FormInstance, Table } from 'antd';
import { DividerFrame, Frame } from '~/components/Globals';
import useTransitStore from '~/zustand/rfidReports/receipt/transit-state';

interface Props {
  form: FormInstance<any>;
}
export default function TableReceiptTransit(props: Props) {
  const { transits, transitPagination, handleTransitPagination } =
    useTransitStore();
  const { form } = props;

  return (
    <Frame ghost accordion defaultActiveKey="1">
      <Frame.Panel
        header={
          <DividerFrame orientation="left">
            Retiradas em Trânsito para Recebimento
          </DividerFrame>
        }
        key="1"
      >
        <Table
          dataSource={transits}
          pagination={{
            pageSize: transitPagination.pageSize,
            current: transitPagination.current,
            showSizeChanger: true,
            total: transitPagination.total,
            onChange(page, pageSize) {
              handleTransitPagination({
                ...transitPagination,
                current: page,
                pageSize
              });
              form.submit();
            }
          }}
          expandable={{
            expandedRowRender: receiptTransit => (
              <Table
                dataSource={receiptTransit.carts}
                expandable={{
                  expandedRowRender: receiptTransitCarts => (
                    <Table dataSource={receiptTransitCarts.acessories} bordered>
                      <Table.Column
                        dataIndex="productCode"
                        title="Código Material"
                      />
                      <Table.Column dataIndex="type" title="Tipo Material" />
                      <Table.Column dataIndex="results" title="Total" />
                    </Table>
                  )
                }}
                bordered
              >
                <Table.Column dataIndex="productCode" title="Serial Carrinho" />
                <Table.Column dataIndex="numberMC" title="Total MC" />
              </Table>
            )
          }}
        >
          <Table.Column dataIndex="sendDate" title="Data Envio" />
          <Table.Column dataIndex="document" title="Romaneio" />
          <Table.Column dataIndex="numberCart" title="Total Carrinho" />
          <Table.Column dataIndex="numberMC" title="Total MC" />
        </Table>
      </Frame.Panel>
    </Frame>
  );
}

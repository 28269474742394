import { call, put } from 'redux-saga/effects';

import * as ProductionProductGroupService from '~/services/producer/ProductionProductGroup';

import * as API from '~/domain/api';
import { ProductionProductGroup } from '~/domain/model';

import { actions } from './duck';

export function* findMany({
  siteId,
  categoryId
}: ProductionProductGroupService.FindMany) {
  const response: API.ServerResponse = yield call(
    ProductionProductGroupService.findMany,
    {
      siteId,
      categoryId
    }
  );

  const apiResponse = response.data;
  const productionProductGroups: API.ProductionProductGroup[] =
    apiResponse || [];
  const productionProductGroupById = new Map(
    productionProductGroups.map(productionProductGroup => [
      String(productionProductGroup.id),
      new ProductionProductGroup(productionProductGroup)
    ])
  );
  yield put(actions.update({ productionProductGroupById }));
  return apiResponse;
}

import { AxiosError, AxiosResponse } from 'axios';
import * as API from 'domain/api';
import { APIError } from '~/domain/api/errors';
import { generateErrorMessage } from '~/helpers/util/error';

import apiProducer from '../api';

export const create = async (lkpOffer: API.LkpOffer) => {
  try {
    const data: any = {
      ...lkpOffer
    };
    const response: any = await apiProducer.put('/lkp-offer/offers', data);
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const fetchMany = async (
  siteId: number,
  startDate?: any,
  endDateStock?: any,
  productId?: number,
  categoryId?: number,
  expeditionId?: number,
  groupId?: number,
  productQualityId?: number,
  productRecipient?: string,
  pageNumber?: number,
  pageSize?: number,
  status?: boolean,
  ColorId?: number,
  keyword?: string
) => {
  try {
    const params = new URLSearchParams();
    if (siteId) {
      params.append('Data.SiteId', String(siteId));
    }
    if (startDate) {
      params.append('Data.StartDateStock', String(startDate));
    }
    if (endDateStock) {
      params.append('Data.EndDateStock', String(endDateStock));
    }
    if (productId) {
      params.append('Data.ProductId', String(productId));
    }
    if (categoryId) {
      params.append('Data.CategoryId', String(categoryId));
    }
    if (expeditionId) {
      params.append('Data.ExpeditionId', String(expeditionId));
    }
    if (groupId) {
      params.append('Data.groupId', String(groupId));
    }
    if (productQualityId) {
      params.append('Data.ProductQualityId', String(productQualityId));
    }
    if (productRecipient) {
      params.append('Data.ProductRecipient', String(productRecipient));
    }
    if (status !== undefined) {
      params.append('Data.Status', String(status));
    }
    if (pageNumber) {
      params.append('PageNumber', String(pageNumber));
    }
    if (pageSize) {
      params.append('PageSize', String(pageSize));
    }
    if (ColorId) {
      params.append('Data.ColorId', String(ColorId));
    }
    if (keyword) {
      params.append('Data.Keyword', String(keyword));
    }
    const response: AxiosResponse = await apiProducer.get('/lkp-offer/offers', {
      params
    });
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const changeStatus = async (data: any) => {
  try {
    await apiProducer.put('/lkp-offer/status', data);
  } catch (error: any) {
    throw new APIError(generateErrorMessage(error.response.data.errors));
  }
};

export const fetchHistory = async (lkpOfferId: number) => {
  try {
    const params = new URLSearchParams();
    if (lkpOfferId) {
      params.append('lkpOfferId', String(lkpOfferId));
    }
    const response: AxiosResponse = await apiProducer.get(
      '/lkp-offer/offers/history',
      { params }
    );
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const fetchProductPackingSummary = async (
  siteId: number,
  productId: number
) => {
  try {
    const params = new URLSearchParams();
    if (siteId) {
      params.append('siteId', String(siteId));
    }
    if (productId) {
      params.append('productId', String(productId));
    }
    const response: AxiosResponse = await apiProducer.get(
      '/product/packing-summary',
      { params }
    );
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const fetchGfps = async (
  siteId?: number,
  weekId?: number,
  productId?: number,
  productQualityId?: number,
  auctionDate?: string,
  outtingDate?: string,
  pageSize?: number,
  pageNumber?: number
) => {
  try {
    const params = new URLSearchParams();
    if (siteId) {
      params.append('data.siteId', String(siteId));
    }
    if (weekId) {
      params.append('data.weekId', String(weekId));
    }
    if (productId) {
      params.append('data.productId', String(productId));
    }
    if (productQualityId) {
      params.append('data.productQualityId', String(productQualityId));
    }
    if (auctionDate) {
      params.append('data.auctionDate', auctionDate);
    }
    if (outtingDate) {
      params.append('data.outtingDate', outtingDate);
    }
    if (pageSize) {
      params.append('pageSize', String(pageSize));
    }
    if (pageNumber) {
      params.append('pageNumber', String(pageNumber));
    }
    const response: AxiosResponse = await apiProducer.get(
      '/lkp-offer/offers/detail',
      { params }
    );
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const fetchWorksheet = async (
  siteId: number,
  weekId: number,
  productId?: number,
  categoryId?: number,
  groupId?: number,
  productQualityId?: number,
  pageNumber?: number,
  pageSize?: number
) => {
  try {
    const params = new URLSearchParams();
    if (siteId) {
      params.append('data.siteId', String(siteId));
    }
    if (weekId) {
      params.append('data.weekId', String(weekId));
    }
    if (productId) {
      params.append('data.productId', String(productId));
    }
    if (categoryId) {
      params.append('data.categoryId', String(categoryId));
    }
    if (groupId) {
      params.append('data.groupId', String(groupId));
    }
    if (productQualityId) {
      params.append('data.productQualityId', String(productQualityId));
    }
    if (pageNumber) {
      params.append('pageNumber', String(pageNumber));
    }
    if (pageSize) {
      params.append('pageSize', String(pageSize));
    }
    const response: AxiosResponse = await apiProducer.get(
      '/lkp-offer/offers/worksheet',
      {
        params
      }
    );
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const update = async (lkpOffers: API.LkpOfferParams[]) => {
  try {
    const response: any = await apiProducer.put('/lkp-offer/offers', lkpOffers);
    return response;
  } catch (error: any) {
    throw new APIError(generateErrorMessage(error.response.data.errors));
  }
};

export const fetchLkpOfferPriceRanges = async (
  startDate: string,
  endDate: string,
  productId: number,
  productQualityId: number,
  accountCode: string
) => {
  try {
    const params = new URLSearchParams();
    params.append('StartDate', startDate);
    params.append('EndDate', endDate);
    params.append('ProductId', String(productId));
    params.append('ProductQualityId', String(productQualityId));
    params.append('AccountCode', accountCode);

    const response: AxiosResponse<API.LkpOfferPriceRange[]> =
      await apiProducer.get('/lkp-offer/get-lkp-offer-price-ranges', {
        params
      });
    return response;
  } catch (error: unknown) {
    throw new APIError(
      generateErrorMessage((error as AxiosError).response?.data?.errors ?? [])
    );
  }
};

import { Collapse, Table } from 'antd';
import styled from 'styled-components';

export const FrameStyled = styled(Collapse)`
  font: 16px 'Roboto', sans-serif;
  font-weight: 400;
  .ant-collapse-header {
    display: flex;
    flex-wrap: wrap !important;
  }
`;

export const StyledTable = styled(Table)`
  & .ant-table-tbody > tr.gray-background > td {
    background-color: #f0f0f0;
  }
`;

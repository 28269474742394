// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
import produce from 'immer';

interface StateType {
  count: number;
  data: any[];
  dataFull: any[];
  registry: any;
  loading: boolean;
  error: boolean;
  id?: number;
  filterReload: any;
  registryCompleted: boolean;
}

const INITIAL_STATE: StateType = {
  id: undefined,
  count: 0,
  data: [],
  dataFull: [],
  registry: {},
  loading: false,
  error: false,
  filterReload: {},
  registryCompleted: false
};

export default function property(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case '@property/LIST_REQUEST':
    case '@property/LIST_GENERIC_REQUEST':
    case '@property/ADD_REQUEST':
    case '@property/READ_REQUEST':
    case '@property/UPDATE_REQUEST':
    case '@property/DELETE_REQUEST':
    case '@property/ACTIVE_REQUEST':
      return produce(state, draft => {
        draft.loading = true;
        draft.error = false;
      });

    case '@property/LIST_SUCCESS':
    case '@property/LIST_GENERIC_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.data = action.payload.list;
        draft.count = action.payload.count;
      });
    case '@property/LIST_FULL_SUCCESS':
      return produce(state, draft => {
        draft.dataFull = action.payload.list;
      });

    case '@property/ADD_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.id = action.payload.id;
        draft.registryCompleted = true;
      });

    case '@property/UPDATE_SUCCESS':
    case '@property/DELETE_SUCCESS':
    case '@property/ACTIVE_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.registryCompleted = true;
      });

    case '@property/READ_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.registry = action.payload.data;
        draft.id = action.payload.data?.id;
      });

    case '@property/CLEAR_REQUEST':
      return produce(state, draft => {
        draft.loading = false;
        draft.id = undefined;
        draft.registry = {};
      });

    case '@property/LIST_FAILURE':
    case '@property/LIST_GENERIC_FAILURE':
    case '@property/ADD_FAILURE':
    case '@property/READ_FAILURE':
    case '@property/UPDATE_FAILURE':
    case '@property/DELETE_FAILURE':
    case '@property/ACTIVE_FAILURE':
    case '@property/LIST_FULL_FAILURE':
      return produce(state, draft => {
        draft.loading = false;
        draft.error = true;
      });

    case '@property/FILTER_RELOAD':
      return produce(state, draft => {
        draft.filterReload = action.payload;
      });

    case '@property/CLEAR_REGISTRY_COMPLETED':
      return produce(state, draft => {
        draft.registryCompleted = false;
      });

    default:
      return state;
  }
}

import { AxiosRequestConfig, AxiosResponse } from 'axios';
import jwtDecode from 'jwt-decode';
import { endpoints } from '~/constants/endpoints';
import * as API from '~/domain/api';
import {
  getAccessToken,
  getRefreshToken,
  getScope,
  getTokenType,
  getTokens,
  removeTokens,
  setTokens
} from '~/helpers/util/storage';
import * as AuthService from '~/services/producer/Auth';
import store from '~/store';
import { actions as actionsLoading } from '~/store/modules/_ui/loading';
import { Status } from '~/store/modules/_ui/loading/duck';

const getTokenToHeader = (): string => {
  return `${getTokenType()} ${getAccessToken()}`;
};

const changeLoading = (status: Status) => {
  store.dispatch(
    actionsLoading.setLoading({
      status
    })
  );
};

let isRefreshing = false;

const onRefreshToken = async () => {
  const response = await AuthService.sendRefreshToken(
    getRefreshToken(),
    getScope()
  );
  if (response.status === 401) {
    removeTokens();
  }

  const refreshData: API.RefreshData = response.data;
  setTokens({
    refreshToken: refreshData.refresh_token,
    accessToken: refreshData.access_token,
    scope: refreshData.scope,
    expiresIn: refreshData.expires_in,
    tokenType: refreshData.token_type
  });

  setTimeout(() => {
    isRefreshing = false;
  }, 60 * 1000);
};

export default {
  onResponse: async (responseParam: AxiosResponse<any>) => {
    changeLoading('idle');
    const tokens = getTokens();
    const { exp }: any = jwtDecode(tokens.accessToken); // Token expiration timestamp

    const now = new Date().getTime();

    const expirationDiff = exp * 1000 - now;

    const threeMinsInMilliseconds = 180000;

    if (!isRefreshing && expirationDiff <= threeMinsInMilliseconds) {
      isRefreshing = true;
      onRefreshToken();
    }

    return responseParam;
  },
  onError: (error: any) => {
    changeLoading('idle');
    if (error?.response?.status === 401) {
      removeTokens();
    }

    return Promise.reject(error);
  },
  onRequest: (config: AxiosRequestConfig) => {
    config.headers.Authorization = getTokenToHeader();
    const isAsync = config.headers['X-Is-Async-Loading'] === 'true';
    const LOADING_IGNORED_ENDPOINTS = [
      endpoints.productionPlanning.importingLog
    ];
    if (
      config.url &&
      !LOADING_IGNORED_ENDPOINTS.includes(config.url) &&
      !isAsync
    ) {
      changeLoading('fetching');
    }
    return config;
  },
  onRequestStatus: (config: AxiosRequestConfig) => {
    config.headers.Authorization = getTokenToHeader();
    return config;
  },
  onResponseApiSession: async (responseParam: AxiosResponse<any>) => {
    changeLoading('idle');
    return responseParam;
  }
};

import { UploadFile } from 'antd/lib/upload/interface';
import { AxiosResponse } from 'axios';

import { APIError } from '~/domain/api/errors';

import apiProducer from '../api';

export const fetchBannerProducerManagement = async (
  description?: string,
  url?: string,
  status?: string,
  bannerTypeId?: number,
  uploadDate?: string,
  pageNumber?: number,
  pageSize?: number
) => {
  try {
    const params = new URLSearchParams();
    if (description) {
      params.append('data.description', description);
    }
    if (url) {
      params.append('data.url', url);
    }
    if (bannerTypeId) {
      params.append('data.bannerTypeId', String(bannerTypeId));
    }
    if (status) {
      params.append('data.status', status);
    }
    if (uploadDate) {
      params.append('data.uploadDate', uploadDate);
    }
    if (pageNumber) {
      params.append('pageNumber', String(pageNumber));
    }
    if (pageSize) {
      params.append('pageSize', String(pageSize));
    }
    const response: AxiosResponse = await apiProducer.get('/link-site-image', {
      params
    });
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const fetchBannerImage = async (id: number) => {
  try {
    const response: AxiosResponse = await apiProducer.get(
      `/link-site-image/image/${id}`
    );
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const activeOrInactiveBanner = async (id: number) => {
  try {
    const response: any = await apiProducer.put(
      `/link-site-image/active/${id}`
    );
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const create = async (
  description: string,
  url: string,
  bannerTypeId: number,
  bannerImage: UploadFile
) => {
  try {
    const formData = new FormData();
    formData.append('Description', description);
    formData.append('Url', url);
    formData.append('BannerTypeId', bannerTypeId.toString());
    formData.append('Banner', bannerImage.originFileObj!);

    const response: any = await apiProducer.post('/link-site-image', formData);
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 422, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    } else if (error?.response?.status === 422) {
      throw new APIError(error?.response?.data[0]);
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

import { call, takeLatest, put, fork } from 'redux-saga/effects';
import * as API from '~/domain/api';
import * as ProductService from '~/store/modules/_core/products/service';
import * as SiteService from '~/store/modules/_core/sites/service';
import * as SalesReport from '~/store/modules/_core/salesReport/service';
import * as WeekService from '~/store/modules/_core/weeks/service';

import {
  actions,
  FetchProducts,
  FetchSalesReport,
  FetchWorkSheet,
  Types
} from './duck';
import { Action } from '~/helpers/util/reducers';

export function* watchFetchProducts(action: Action<FetchProducts>) {
  yield fork(fetchProductsSaga, action.payload);
}

export function* watchFetchSalesReportVolList(
  action: Action<FetchSalesReport>
) {
  yield fork(fetchSalesReportVolListSaga, action.payload);
}
export function* watchFetchWorkSheetVol(action: Action<FetchWorkSheet>) {
  yield fork(fetchWorkSheetVolSaga, action.payload);
}
export function* watchFetchWorkSheetLkp(action: Action<FetchWorkSheet>) {
  yield fork(fetchWorkSheetLkpSaga, action.payload);
}

export function* watchFetchSalesReportLkpList(
  action: Action<FetchSalesReport>
) {
  yield fork(fetchSalesReportLkpListSaga, action.payload);
}

export function* fetchInitSaga(): any {
  try {
    yield call(SiteService.findMany);
    yield put(actions.fetchSuccessInit());
  } catch (err) {
    yield put(actions.fetchFailure());
  }
}

export function* fetchSalesReportVolListSaga({
  siteId,
  startDate,
  endDate,
  productId,
  pageNumber,
  pageSize,
  ColorId
}: FetchSalesReport): any {
  const weeks: API.Week[] = yield call(WeekService.find, {
    startDate,
    endDate
  });
  let weekId = 0;
  if (weeks && weeks.length > 0) {
    weekId = weeks[0].id;
  }
  yield put(actions.setSelectedWeekId(weekId));
  try {
    const response: API.ServerResponse = yield call(
      SalesReport.findReportSalesVol,
      {
        siteId,
        weekId,
        productId,
        pageNumber,
        pageSize,
        ColorId
      }
    );
    const data: API.DefaultResponse = response.data;
    const totalCount = data.totalCount ?? 0;
    yield put(actions.fetchSalesReportVolListSuccess(totalCount));
  } catch (err) {
    yield put(actions.fetchSalesReportVolListFailure());
  }
}

export function* fetchSalesReportLkpListSaga({
  siteId,
  startDate,
  endDate,
  productId,
  ColorId,
  pageNumber,
  pageSize
}: FetchSalesReport): any {
  const weeks: API.Week[] = yield call(WeekService.find, {
    startDate,
    endDate
  });
  let weekId = 0;
  if (weeks && weeks.length > 0) {
    weekId = weeks[0].id;
  }
  yield put(actions.setSelectedWeekId(weekId));
  try {
    const response: API.ServerResponse = yield call(
      SalesReport.findReportSalesLkp,
      {
        siteId,
        weekId,
        productId,
        pageNumber,
        pageSize,
        ColorId
      }
    );
    const data: API.DefaultResponse = response.data;
    const totalCount = data.totalCount ?? 0;
    yield put(actions.fetchSalesReportLkpListSuccess(totalCount));
  } catch (err) {
    yield put(actions.fetchSalesReportLkpListFailure());
  }
}

export function* fetchProductsSaga(params: FetchProducts): any {
  const { siteId } = params;
  try {
    yield call(ProductService.findProductsSimple, siteId);
    yield put(actions.fetchProductsSuccess());
  } catch (err) {
    yield put(actions.fetchProductsFailure());
  }
}

export function* fetchWorkSheetVolSaga(params: FetchWorkSheet): any {
  const { id } = params;
  try {
    yield call(SalesReport.fetchWorkSheetVol, id);
    yield put(actions.fetchWorkSheetVolSuccess());
  } catch (err) {
    yield put(actions.fetchWorkSheetVolFailure());
  }
}

export function* fetchWorkSheetLkpSaga(params: FetchWorkSheet): any {
  const { id } = params;
  try {
    yield call(SalesReport.fetchWorkSheetLkp, id);
    yield put(actions.fetchWorkSheetLkpSuccess());
  } catch (err) {
    yield put(actions.fetchWorkSheetLkpFailure());
  }
}

export default [
  takeLatest(Types.fetchInit, fetchInitSaga),
  takeLatest(Types.fetchProducts, watchFetchProducts),
  takeLatest(Types.fetchSalesReportVolList, watchFetchSalesReportVolList),
  takeLatest(Types.fetchSalesReportLkpList, watchFetchSalesReportLkpList),
  takeLatest(Types.fetchWorkSheetLkp, watchFetchWorkSheetLkp),
  takeLatest(Types.fetchWorkSheetVol, watchFetchWorkSheetVol)
];

// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Moment } from 'moment';
import {
  DefaultPagination,
  ProductRegister,
  StockHistory,
  StockOfferChannel,
  StockUpdateError
} from '~/domain/model';
import PlannedGroups from '~/domain/model/Stock/PlannedGroups';
import Stocks from '~/domain/model/Stock/Stocks';
import {
  createStocksRegister,
  fetchHistoric,
  fetchOfferChannel,
  fetchStocks,
  fetchStocksRegister,
  updateStockMassAdjust,
  updateStockMassChannels,
  updateStocks,
  updateStocksChannels,
  convertQuality,
  replicateStock
} from './apiCalls';

export interface StocksState {
  selectedStockId?: number;
  stocks: Stocks[];
  stocksPagination: DefaultPagination;
  totalstocks: number;
  productsRegister: ProductRegister[];
  plannedGroups: PlannedGroups[];
  stockOfferChannel?: StockOfferChannel;
  stockHistory?: StockHistory;
  stockUpdateErrors: StockUpdateError[];
  closeAddStockModal: boolean;
  submitFormFilter: boolean;
  siteAllowsKlokHoard: boolean;
  siteAllowsVolHoard: boolean;
  weekCreatedDate: Moment | null;
  loading: boolean;
  showDistributeSalesChannelsModal: boolean;
  siteIdAux: number | undefined;
  FirstWeekDay: Moment | undefined;
  LastWeekDay: Moment | undefined;
  keyword: string | undefined;
  searchByKeyword: boolean;
  submitFormFilterForEditTable: boolean;
}

const initialState: StocksState = {
  selectedStockId: undefined,
  stocks: [],
  stocksPagination: { PageSize: 100 } as DefaultPagination,
  totalstocks: 0,
  productsRegister: [],
  plannedGroups: [],
  stockOfferChannel: undefined,
  stockHistory: undefined,
  stockUpdateErrors: [],
  closeAddStockModal: false,
  siteAllowsKlokHoard: false,
  siteAllowsVolHoard: false,
  submitFormFilter: false,
  weekCreatedDate: null,
  loading: false,
  showDistributeSalesChannelsModal: false,
  siteIdAux: undefined,
  FirstWeekDay: undefined,
  LastWeekDay: undefined,
  keyword: undefined,
  searchByKeyword: false,
  submitFormFilterForEditTable: false
};

export const prefix = 'stocks';

const stocksSlice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setStocksPagination: (state, action: PayloadAction<DefaultPagination>) => {
      state.stocksPagination = action.payload;
    },
    setSubmitFormFilterForEditTable: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.submitFormFilterForEditTable = action.payload;
    },
    setTotalStocks: (state, action: PayloadAction<number>) => {
      state.totalstocks = action.payload;
    },
    setSiteIdAux: (state, action: PayloadAction<number | undefined>) => {
      state.siteIdAux = action.payload;
    },
    setKeyword: (state, action: PayloadAction<string | undefined>) => {
      state.keyword = action.payload;
    },
    setSearchByKeyword: (state, action: PayloadAction<boolean>) => {
      state.searchByKeyword = action.payload;
    },
    setFirstWeekDay: (state, action: PayloadAction<Moment | undefined>) => {
      state.FirstWeekDay = action.payload;
    },
    setLastWeekDay: (state, action: PayloadAction<Moment | undefined>) => {
      state.LastWeekDay = action.payload;
    },
    setStocks: (state, action: PayloadAction<Stocks[]>) => {
      state.stocks = action.payload;
    },
    setProductsRegister: (state, action: PayloadAction<ProductRegister[]>) => {
      state.productsRegister = action.payload;
    },
    setKlokHoard: (state, action: PayloadAction<number>) => {
      if (state.stockOfferChannel?.klok?.klokHoard) {
        state.stockOfferChannel.klok.klokHoard = action.payload;
      }
    },
    setShowDistributeSalesChannelsModal: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.showDistributeSalesChannelsModal = action.payload;
    },
    setSelectedStockId: (state, action: PayloadAction<number>) => {
      state.selectedStockId = action.payload;
    },
    setCloseModal: (state, action: PayloadAction<boolean>) => {
      state.closeAddStockModal = action.payload;
    },
    setSubmitFormFilter: (state, action: PayloadAction<boolean>) => {
      state.submitFormFilter = action.payload;
    },
    setWeekCreatedDate: (state, action: PayloadAction<Moment>) => {
      state.weekCreatedDate = action.payload;
    },
    resetStockReducer: state => {
      state.stocks = [];
      state.stocksPagination = { PageSize: 100 } as DefaultPagination;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchStocks.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchStocks.fulfilled, (state, action) => {
      const keywordsArray = state.keyword?.split(' ');
      const isLetter = /^[a-zA-Z]/.test(state.keyword ?? '');
      let newTableData: any = action.payload.results;

      keywordsArray?.forEach((word: string) => {
        newTableData = newTableData.filter((item: any) =>
          item.productDescription.toLowerCase().includes(word.toLowerCase())
        );
      });

      state.stocks = isLetter ? newTableData : action.payload.results;
      state.totalstocks = action.payload.totalCount;
      state.siteAllowsKlokHoard = action.payload.siteAllowsKlokHoard;
      state.siteAllowsVolHoard = action.payload.siteAllowsVolHoard;
      state.loading = false;
    });
    builder.addCase(fetchStocks.rejected, state => {
      state.loading = false;
    });
    builder.addCase(updateStocks.pending, state => {
      state.loading = true;
    });
    builder.addCase(updateStocks.fulfilled, (state, action) => {
      const stocksResponse = action.payload as Stocks[];

      state.stocks = state.stocks.map(stockRegister => {
        const stockInResponse = stocksResponse.find(
          stockResponse => stockRegister.stockId === stockResponse.stockId
        );
        if (stockInResponse) {
          return { ...stockInResponse };
        }
        return { ...stockRegister };
      });
      state.stockUpdateErrors = [];
      state.submitFormFilter = true;
      state.loading = false;
    });
    builder.addCase(updateStocks.rejected, state => {
      state.loading = false;
    });
    builder.addCase(updateStocksChannels.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      updateStocksChannels.fulfilled,
      (state, action: PayloadAction<Stocks[]>) => {
        state.stocks = state.stocks.map(stockRegister => {
          const stockInResponse = action.payload.find(
            stockResponse => stockRegister.stockId === stockResponse.stockId
          );
          if (stockInResponse) {
            return { ...stockInResponse };
          }
          return { ...stockRegister };
        });
        state.stockUpdateErrors = [];
        state.submitFormFilter = true;
        state.showDistributeSalesChannelsModal = false;
        state.loading = false;
      }
    );
    builder.addCase(updateStocksChannels.rejected, state => {
      state.loading = false;
    });
    builder.addCase(fetchStocksRegister.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchStocksRegister.fulfilled, (state, action) => {
      state.productsRegister = action.payload.items;
      state.plannedGroups = action.payload.plannedGroups;
      state.loading = false;
    });
    builder.addCase(fetchStocksRegister.rejected, state => {
      state.loading = false;
    });
    builder.addCase(createStocksRegister.pending, state => {
      state.loading = true;
    });
    builder.addCase(createStocksRegister.fulfilled, state => {
      state.closeAddStockModal = true;
      state.submitFormFilter = true;
      state.loading = false;
    });
    builder.addCase(createStocksRegister.rejected, state => {
      state.loading = false;
    });
    builder.addCase(replicateStock.fulfilled, state => {
      state.submitFormFilterForEditTable = true;
      state.loading = false;
    });
    builder.addCase(fetchOfferChannel.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchOfferChannel.fulfilled, (state, action) => {
      state.stockOfferChannel = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchOfferChannel.rejected, state => {
      state.loading = false;
    });
    builder.addCase(updateStockMassChannels.pending, state => {
      state.loading = true;
    });
    builder.addCase(updateStockMassChannels.fulfilled, (state, action) => {
      const stocksResponse = action.payload as Stocks[];

      state.stocks = state.stocks.map(stockRegister => {
        const stockInResponse = stocksResponse.find(
          stockResponse => stockRegister.stockId === stockResponse.stockId
        );
        if (stockInResponse) {
          return { ...stockInResponse, loading: false };
        }
        return { ...stockRegister };
      });
      state.submitFormFilter = true;
      state.loading = false;
    });
    builder.addCase(updateStockMassChannels.rejected, state => {
      state.loading = false;
    });
    builder.addCase(updateStockMassAdjust.pending, state => {
      state.loading = true;
    });
    builder.addCase(updateStockMassAdjust.fulfilled, (state, action) => {
      const stocksResponse = action.payload as Stocks[];

      state.stocks = state.stocks.map(stockRegister => {
        const stockInResponse = stocksResponse.find(
          stockResponse => stockRegister.stockId === stockResponse.stockId
        );
        if (stockInResponse) {
          return { ...stockInResponse, loading: false };
        }
        return { ...stockRegister };
      });
      state.loading = false;
    });
    builder.addCase(updateStockMassAdjust.rejected, state => {
      state.loading = false;
    });
    builder.addCase(fetchHistoric.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      fetchHistoric.fulfilled,
      (state, action: PayloadAction<StockHistory>) => {
        state.stockHistory = action.payload;

        state.loading = false;
      }
    );
    builder.addCase(fetchHistoric.rejected, state => {
      state.loading = false;
    });
    builder.addCase(convertQuality.fulfilled, state => {
      state.submitFormFilter = true;
      state.submitFormFilterForEditTable = true;
      state.loading = false;
    });
  }
});

export const actions = stocksSlice.actions;
export const reducer = stocksSlice.reducer;

import { BannerTypesState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.core.bannerTypes as BannerTypesState;
};

export const getById = createSelector([getRoot], state => state.byId);

export const getBannerTypes = createSelector([getRoot], state =>
  Array.from(state.byId.values())
);

import produce from 'immer';
import { CompetenceEvent } from '~/domain/model';
import { createCreator, createReducer, Reducer } from '~/helpers/util/reducers';

export type FetchStatus = 'idle' | 'fetching' | 'success' | 'failure';

export type CompetenceEventsState = {
  competenceEventByID: Map<string, CompetenceEvent>;
  fetchStatus: FetchStatus;
};

export type CreateInit = {
  competenceId: number;
  startDate: string;
  endDate: string;
  firstDayAvailable: string;
  lastDayAvailable: string;
  siteId: number[] | number;
  justification?: string;
  competenceEventType?: number;
};

export type Update = {
  competenceEventByID: Map<string, CompetenceEvent>;
};

const prefix = '@competenceEvents';

export const Types = Object.freeze({
  CLEAR: `${prefix}/CLEAR`,
  UPDATE: `${prefix}/UPDATE`
});

export const initialState: CompetenceEventsState = {
  competenceEventByID: new Map(),
  fetchStatus: 'idle'
};

const clearReducer: Reducer<CompetenceEventsState, void> = state => {
  return produce(state, draft => {
    draft.competenceEventByID = initialState.competenceEventByID;
    draft.fetchStatus = initialState.fetchStatus;
  });
};

const updateReducer: Reducer<CompetenceEventsState, Update> = (
  state,
  action
) => {
  return produce(state, draft => {
    draft.competenceEventByID = action.competenceEventByID as Map<
      string,
      CompetenceEvent
    >;
  });
};

export const actions = Object.freeze({
  clear: createCreator<void>(Types.CLEAR),
  update: createCreator<Update>(Types.UPDATE)
});

export const reducer = createReducer(initialState, {
  [Types.CLEAR]: clearReducer,
  [Types.UPDATE]: updateReducer
});

import { createSelector } from 'reselect';

import { VolOffersState } from './index';

export const getRoot = ({ volOffers }: { volOffers: VolOffersState }) => {
  return volOffers;
};

export const getVolOffersSelected = createSelector(
  [getRoot],
  state => state.volOffersSelected
);

export const getVolOffersObjectSelected = createSelector(
  [getRoot],
  state => state.volOffersObjectSelected
);

export const getKeyword = createSelector([getRoot], state => state.keyword);

export const getSearchByKeyword = createSelector(
  [getRoot],
  state => state.searchByKeyword
);

export const getShowKeysForEdit = createSelector(
  [getRoot],
  state => state.showKeysForEdit
);

export const getClearKeys = createSelector([getRoot], state => state.clearKeys);

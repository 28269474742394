import { ButtonProps, Tooltip } from 'antd';
import { ButtonTop } from './styles';

export type IconButtonProps = ButtonProps & {
  tooltip: string;
  icon: React.ReactNode;
};

export default function IconButton(props: IconButtonProps) {
  const { children, tooltip, icon, ...rest } = props;
  return (
    <Tooltip title={tooltip}>
      <ButtonTop {...rest} title={undefined}>
        {icon}
        {children}
      </ButtonTop>
    </Tooltip>
  );
}

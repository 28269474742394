import IUseCase from '../../../IUseCase';
import { ProductService } from '~/@core/infrastructure/api/services';
import ProductPreferenceColor from '~/@core/domain/model/product/ProductPreferenceColor';

export default class GetProductsPreferenceColor
  implements IUseCase<void, Promise<ProductPreferenceColor[]>>
{
  execute(): Promise<ProductPreferenceColor[]> {
    return ProductService.fetchProductPreferenceColor();
  }
}

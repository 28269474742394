import { Voucher, ProductPreference } from '.';
import * as API from '../api';
import DeliveryPattern from './DeliveryPattern';
import DirectedOfferCustomer from './DirectedOfferCustomer';

export default class VolOffer {
  public json: API.VolOffer;
  public key: number;
  public stockGroupProductId: number;
  public weekId: number;
  public siteId: number;
  public stockId: number;
  public volOfferId: number;
  public parentOfferId: number;
  public status: string;
  public productId: number;
  public productCode: string;
  public productDescription: string;
  public productQualityId: number;
  public productQualityDescription: string;
  public volOfferTypeId: number;
  public volOfferTypeAbbrev: string;
  public volOfferTypeName: string;
  public volQuantityAvailable: number;
  public volAvailabilityNumeralIsDynamic: boolean;
  public volAvailabilityNumeral: number;
  public distributedQuantity: number;
  public observation: string;
  public startDate: Date | string;
  public endDate: Date | string;
  public weekStartDate: Date | string;
  public weekEndDate: Date | string;
  public productPreference: ProductPreference;
  public siteDeliveryPattern: DeliveryPattern;
  public stockRemainingQuantity: number;
  public toSend: boolean;
  public priceByLayer: number;
  public priceByPacking: number;
  public priceByTrolley: number;
  public vouchers: Voucher[];
  public packingDistributionCompositionIds: number[];
  public voucherIds: number[];
  public siteDeliveryPatternId: number;
  public calculatedVolOfferPercentage: number;
  public calculatedVolOfferQuantity: number;
  public defaultOfferImageId: string;
  public defaultOfferImageUrl: string;
  public volOfferQuantityAvailable: number;
  public availableQuantityPlusExclusiveQuantity: number;
  public exclusiveQuantity: number;
  public avgPriceSold: number;
  public percentBelowAverage: number;
  public percentAboveAverage: number;
  public startDateStock: any;
  public endDateStock: any;
  public weekNumber: number;
  public weekYear: number;
  public index: number;
  public volOfferPercentage: number;
  public minimumQuantity: number | null;
  public specialPrice: number | null;
  public volDirectedOfferId: number;
  public offerValue: number;
  public fromMassUpdate: boolean;
  public customers: DirectedOfferCustomer[];
  public quantityConsumedFromExclusiveOffer: number;
  public periodDescription: string;
  public useStockOnlyVol: boolean;

  public getVolOfferQuantityAvailable(): number {
    return this.json.volOfferQuantityAvailable ?? '';
  }

  public getAvailableQuantityPlusExclusiveQuantity(): number {
    return this.json.availableQuantityPlusExclusiveQuantity ?? '';
  }

  public getDefaultOfferImageUrl(): string {
    return this.json.defaultOfferImageUrl ?? '';
  }

  public getDefaultOfferImageId(): string {
    return this.json.defaultOfferImageId ?? '';
  }

  public getCalculatedVolOfferQuantity(): number {
    return this.json.calculatedVolOfferQuantity ?? Number.NaN;
  }

  public getCalculatedVolOfferPercentage(): number {
    return this.json.calculatedVolOfferPercentage ?? Number.NaN;
  }

  public getStockGroupProductId(): number {
    return this.json.stockGroupProductId ?? Number.NaN;
  }

  public getWeekId(): number {
    return this.json.weekId ?? Number.NaN;
  }

  public getStockId(): number {
    return this.json.stockId ?? Number.NaN;
  }

  public getSiteId(): number {
    return this.json.siteId ?? Number.NaN;
  }

  public getVolOfferId(): number {
    return this.json.volOfferId ?? Number.NaN;
  }

  public getParentOfferId(): number {
    return this.json.parentOfferId ?? Number.NaN;
  }

  public getStatus(): string {
    return this.json.status ?? '';
  }

  public getProductId(): number {
    return this.json.productId ?? Number.NaN;
  }

  public getProductCode(): string {
    return this.json.productCode ?? '';
  }

  public getProductDescription(): string {
    return this.json.productDescription ?? '';
  }

  public getProductQualityId(): number {
    return this.json.productQualityId ?? Number.NaN;
  }

  public getProductQualityDescription(): string {
    return this.json.productQualityDescription ?? '';
  }

  public getVolOfferTypeId(): number {
    return this.json.volOfferTypeId ?? Number.NaN;
  }

  public getVolOfferTypeAbbrev(): string {
    return this.json.volOfferTypeAbbrev ?? '';
  }

  public getVolOfferTypeName(): string {
    return this.json.volOfferTypeName ?? '';
  }

  public getVolQuantityAvailable(): number {
    return this.json.volQuantityAvailable ?? Number.NaN;
  }

  public getVolAvailabilityNumeralIsDynamic(): boolean {
    return this.json.volAvailabilityNumeralIsDynamic;
  }

  public getVolAvailabilityNumeral(): number {
    return this.json.volAvailabilityNumeral ?? Number.NaN;
  }

  public getDistributedQuantity(): number {
    return this.json.distributedQuantity ?? Number.NaN;
  }

  public getObservation(): string {
    return this.json.observation ?? '';
  }

  public getStartDate(): Date | string {
    return this.json.startDate;
  }

  public getEndDate(): Date | string {
    return this.json.endDate;
  }

  public getWeekStartDate(): Date | string {
    return this.json.weekStartDate;
  }

  public getWeekEndDate(): Date | string {
    return this.json.weekEndDate;
  }

  public getProductPreference(): ProductPreference {
    if (this.json.productPreference) {
      this.json.productPreference.availablePackingDistributionCompositions.forEach(
        packing => {
          if (packing.selected) {
            this.packingDistributionCompositionIds.push(packing.id);
          }
        }
      );
      return new ProductPreference(this.json.productPreference);
    }
    return {} as ProductPreference;
  }

  public getSiteDeliveryPattern(): DeliveryPattern {
    if (this.json.siteDeliveryPattern) {
      this.siteDeliveryPatternId =
        this.json.siteDeliveryPattern.id ?? Number.NaN;
      return new DeliveryPattern(this.json.siteDeliveryPattern);
    }
    return new DeliveryPattern({} as API.DeliveryPattern);
  }

  public getToSend(): boolean {
    return false;
  }

  public getPriceByLayer(): number {
    return this.json.priceByLayer ?? Number.NaN;
  }

  public getPriceByPacking(): number {
    return this.json.priceByPacking ?? Number.NaN;
  }

  public getPriceByTrolley(): number {
    return this.json.priceByTrolley ?? Number.NaN;
  }

  public getVouchers(): Voucher[] {
    if (this.json.vouchers && this.json.vouchers.length > 0) {
      return this.json.vouchers.map(voucher => {
        if (voucher.id) {
          this.voucherIds.push(voucher.id);
        }
        return new Voucher(voucher);
      });
    }
    return [] as Voucher[];
  }

  public getVouchersById() {
    return [] as number[];
  }

  public getStockRemainingQuantity() {
    return this.json.stockRemainingQuantity ?? Number.NaN;
  }

  public getExclusiveQuantity(): number {
    return this.json.exclusiveQuantity ?? Number.NaN;
  }

  public getAvgPriceSold(): number {
    return this.json.avgPriceSold ?? Number.NaN;
  }

  public getPercentBelowAverage(): number {
    return this.json.percentBelowAverage ?? Number.NaN;
  }

  public getPercentAboveAverage(): number {
    return this.json.percentAboveAverage ?? Number.NaN;
  }

  public getWeekNumber(): number {
    return this.json.weekNumber ?? Number.NaN;
  }

  public getWeekYeary(): number {
    return this.json.weekYear ?? Number.NaN;
  }

  public getIndex(): number {
    return this.json.index ?? Number.NaN;
  }

  public getVolOfferPercentage(): number {
    return this.json.volOfferPercentage ?? Number.NaN;
  }

  public getMinimumQuantity(): number {
    return this.json.minimumQuantity ?? Number.NaN;
  }

  public getSpecialPrice(): number {
    return this.json.specialPrice ?? Number.NaN;
  }

  public getVolDirectedOfferId(): number {
    return this.json.volDirectedOfferId ?? Number.NaN;
  }

  public getOfferValue(): number {
    return this.json.offerValue ?? Number.NaN;
  }

  public getFromMassUpdate(): boolean {
    return this.json.fromMassUpdate;
  }

  public getQuantityConsumedFromExclusiveOffer(): number {
    return this.json.quantityConsumedFromExclusiveOffer ?? Number.NaN;
  }

  private getCustomers(): DirectedOfferCustomer[] {
    return this.json.customers
      ? this.json.customers.map(customer => new DirectedOfferCustomer(customer))
      : ([] as DirectedOfferCustomer[]);
  }

  public getPeriodDescription(): string {
    return this.json.periodDescription ?? '';
  }

  public getUseStockOnlyVol(): boolean {
    return this.json.useStockOnlyVol;
  }

  constructor(json: API.VolOffer) {
    this.json = json;
    this.key = json.index ?? Number.NaN;
    this.stockGroupProductId = this.getStockGroupProductId();
    this.weekId = this.getWeekId();
    this.siteId = this.getSiteId();
    this.volOfferId = this.getVolOfferId();
    this.parentOfferId = this.getParentOfferId();
    this.status = this.getStatus();
    this.productId = this.getProductId();
    this.productCode = this.getProductCode();
    this.productDescription = this.getProductDescription();
    this.productQualityId = this.getProductQualityId();
    this.productQualityDescription = this.getProductQualityDescription();
    this.volOfferId = this.getVolOfferId();
    this.volOfferTypeAbbrev = this.getVolOfferTypeAbbrev();
    this.volOfferTypeId = this.getVolOfferTypeId();
    this.volOfferTypeName = this.getVolOfferTypeName();
    this.volQuantityAvailable = this.getVolQuantityAvailable();
    this.volAvailabilityNumeralIsDynamic =
      this.getVolAvailabilityNumeralIsDynamic();
    this.volAvailabilityNumeral = this.getVolAvailabilityNumeral();
    this.distributedQuantity = this.getDistributedQuantity();
    this.observation = this.getObservation();
    this.startDate = this.getStartDate();
    this.endDate = this.getEndDate();
    this.weekStartDate = this.getWeekStartDate();
    this.weekEndDate = this.getWeekEndDate();
    this.packingDistributionCompositionIds = [];
    this.productPreference = this.getProductPreference();
    this.voucherIds = [];
    this.vouchers = this.getVouchers();
    this.siteDeliveryPatternId = Number.NaN;
    this.siteDeliveryPattern = this.getSiteDeliveryPattern();
    this.toSend = this.getToSend();
    this.priceByLayer = this.getPriceByLayer();
    this.priceByPacking = this.getPriceByPacking();
    this.priceByTrolley = this.getPriceByTrolley();
    this.calculatedVolOfferQuantity = this.getCalculatedVolOfferQuantity();
    this.calculatedVolOfferPercentage = this.getCalculatedVolOfferPercentage();
    this.defaultOfferImageId = this.getDefaultOfferImageId();
    this.defaultOfferImageUrl = this.getDefaultOfferImageUrl();
    this.volOfferQuantityAvailable = this.getVolOfferQuantityAvailable();
    this.availableQuantityPlusExclusiveQuantity =
      this.getAvailableQuantityPlusExclusiveQuantity();
    this.stockRemainingQuantity = this.getStockRemainingQuantity();
    this.exclusiveQuantity = this.getExclusiveQuantity();
    this.avgPriceSold = this.getAvgPriceSold();
    this.percentBelowAverage = this.getPercentBelowAverage();
    this.percentAboveAverage = this.getPercentAboveAverage();
    this.stockId = this.getStockId();
    this.weekNumber = this.getWeekNumber();
    this.weekYear = this.getWeekYeary();
    this.index = this.getIndex();
    this.volOfferPercentage = this.getVolOfferPercentage();
    this.minimumQuantity = this.getMinimumQuantity();
    this.specialPrice = this.getSpecialPrice();
    this.volDirectedOfferId = this.getVolDirectedOfferId();
    this.fromMassUpdate = this.getFromMassUpdate();
    this.offerValue = this.getOfferValue();
    this.customers = this.getCustomers();
    this.quantityConsumedFromExclusiveOffer =
      this.getQuantityConsumedFromExclusiveOffer();
    this.periodDescription = this.getPeriodDescription();
    this.useStockOnlyVol = this.getUseStockOnlyVol();
  }
}

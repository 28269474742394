import { call, fork, put, takeLatest } from 'redux-saga/effects';

import { Action } from '~/helpers/util/reducers';
import * as PackingService from '~/services/producer/Packing';
import { alertRequest } from '../../alert/actions';
import { actions, FetchSavePackingAdjustQuantity, Types } from './duck';

export function* watchFetchAdjustQuantitySaga(
  action: Action<FetchSavePackingAdjustQuantity>
) {
  yield fork(fetchAdjustQuantitySaga, action.payload);
}

export function* fetchAdjustQuantitySaga({
  id,
  quantity
}: FetchSavePackingAdjustQuantity): any {
  try {
    yield call(PackingService.adjustQuantity, { id, quantity });
    yield put(actions.adjustQuantitySuccess());
    yield put(alertRequest('Ajuste realizado com Sucesso', 'success'));
  } catch (err: any) {
    yield put(actions.adjustQuantityFailure());
    alertRequest('Não foi possível ajustar a quantidade.', 'error');
  }
}

export default [
  takeLatest(Types.fetchAdjustQuantity, watchFetchAdjustQuantitySaga)
];

import FilterGetGfpPrint from '~/@core/domain/filters/gfp/FilterGetGfpPrint';
import IGfp from '~/@core/domain/model/gfp/IGfp';
import { GfpService } from '~/@core/infrastructure/api/services';
import IUseCase from '../../IUseCase';

export default class GetGfpPrint
  // FIXME
  // eslint-disable-next-line prettier/prettier
  implements IUseCase<FilterGetGfpPrint, Promise<IGfp[]>> {
  execute(filter: FilterGetGfpPrint): Promise<IGfp[]> {
    return GfpService.fetchGfpPrint(filter);
  }
}

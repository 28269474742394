import * as API from '../api';

export default class Competence {
  private readonly json: API.Competence;
  public readonly id: number;
  public readonly referenceYear: number;
  public readonly historicalYear: number;
  public readonly competenceEvents?: API.CompetenceEvent[];

  constructor(json: API.Competence) {
    this.json = json;
    this.id = this.getId();
    this.referenceYear = this.getReferenceYear();
    this.historicalYear = this.getHistoricalYear();
    this.competenceEvents = this.getCompetenceEvents();
  }

  public getId(): number {
    return this.json.id ?? Number.NaN;
  }

  public getReferenceYear(): number {
    return this.json.referenceYear ?? Number.NaN;
  }

  public getHistoricalYear(): number {
    return this.json.historicalYear ?? Number.NaN;
  }

  public getCompetenceEvents(): API.CompetenceEvent[] {
    return this.json.competenceEvents ?? [];
  }
}

import ContextGFPWrapper from './contextGFPWrapper';
import { GFPProvider } from '~/context/GFPContext';

export default function GFP() {
  return (
    <GFPProvider>
      <ContextGFPWrapper />
    </GFPProvider>
  );
}

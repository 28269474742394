import produce from 'immer';
import { SpecialDate } from '~/domain/model';

import { createCreator, createReducer, Reducer } from '~/helpers/util/reducers';
import { EditInit } from '../../_core/specialDates/duck';

export type EditStatus = 'idle' | 'updating' | 'success' | 'failure';

export type SpecialDateEditState = {
  specialDate: SpecialDate;
  editStatus: EditStatus;
  errorMessage?: string;
};

export type EditError = {
  errorMessage?: string;
};

type SetSpecialDate = {
  specialDate: SpecialDate;
};

export const initialState: SpecialDateEditState = {
  specialDate: new SpecialDate({}),
  editStatus: 'idle'
};

const prefix = '@specialDateEdit';

export const Types = Object.freeze({
  CLEAR: `${prefix}/CLEAR`,
  SET_SPECIAL_DATE: `${prefix}/SET_SPECIAL_DATE`,
  EDIT_INIT: `${prefix}/EDIT_INIT`,
  EDIT_SUCCESS: `${prefix}/EDIT_SUCCESS`,
  EDIT_FAILURE: `${prefix}/EDIT_FAILURE`
});

const clearReducer: Reducer<SpecialDateEditState, void> = state => {
  return produce(state, draft => {
    draft.editStatus = initialState.editStatus;
  });
};

const setSpecialDateReducer: Reducer<SpecialDateEditState, SetSpecialDate> = (
  state,
  action
) => {
  return produce(state, draft => {
    draft.specialDate = action.specialDate;
  });
};

const editInitReducer: Reducer<SpecialDateEditState, void> = state => {
  return produce(state, draft => {
    draft.editStatus = 'updating';
  });
};

const editSuccessReducer: Reducer<SpecialDateEditState, void> = state => {
  return produce(state, draft => {
    draft.editStatus = 'success';
  });
};

const editFailureReducer: Reducer<SpecialDateEditState, EditError> = (
  state,
  action
) => {
  return produce(state, draft => {
    draft.editStatus = 'failure';
    draft.errorMessage = action.errorMessage;
  });
};

export const actions = Object.freeze({
  clear: createCreator<void>(Types.CLEAR),
  setSpecialDate: createCreator<SetSpecialDate>(Types.SET_SPECIAL_DATE),
  editInit: createCreator<EditInit>(Types.EDIT_INIT),
  editSuccess: createCreator<void>(Types.EDIT_SUCCESS),
  editFailure: createCreator<EditError>(Types.EDIT_FAILURE)
});

export const reducer = createReducer(initialState, {
  [Types.CLEAR]: clearReducer,
  [Types.SET_SPECIAL_DATE]: setSpecialDateReducer,
  [Types.EDIT_INIT]: editInitReducer,
  [Types.EDIT_SUCCESS]: editSuccessReducer,
  [Types.EDIT_FAILURE]: editFailureReducer
});

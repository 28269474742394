// eslint-disable-next-line no-shadow
enum VoucherQuantityMap {
  P = 'embalagem',
  L = 'camada',
  T = 'carrinho'
}
// eslint-disable-next-line no-shadow
enum VoucherQuantityPluralMap {
  P = 'embalagens',
  L = 'camadas',
  T = 'carrinhos'
}
// eslint-disable-next-line no-shadow
export enum ReajustmentAmountType {
  Discount = 'desconto',
  Additional = 'adicional'
}

const generateVoucherDescription = (
  reajustmentAmount: number,
  minimumUnitQuantityVoucher: number,
  voucherQuantityType: 'P' | 'L' | 'T'
) => {
  const voucherQuantityTypeMap = (
    minimumUnitQuantityVoucher > 1
      ? VoucherQuantityPluralMap
      : VoucherQuantityMap
  )[voucherQuantityType];
  const isDiscount = reajustmentAmount < 0;
  return `*Para aplicação do ${
    isDiscount
      ? ReajustmentAmountType.Discount
      : ReajustmentAmountType.Additional
  } a quantidade mínima deve ser de ${minimumUnitQuantityVoucher} ${voucherQuantityTypeMap}. O valor ${
    isDiscount
      ? `do ${ReajustmentAmountType.Discount}`
      : ReajustmentAmountType.Additional
  } será aplicado sobre o preço do produto`;
};

export const OfferService = {
  generateVoucherDescription
};

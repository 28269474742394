export function productPropertyAttachmentTypesRequest() {
  return {
    type: '@productPropertyAttachmentTypes/LIST_REQUEST'
  };
}

export function productPropertyAttachmentTypesSuccess(list: any) {
  return {
    type: '@productPropertyAttachmentTypes/LIST_SUCCESS',
    payload: { list }
  };
}

export function productPropertyAttachmentTypesFailure() {
  return {
    type: '@productPropertyAttachmentTypes/LIST_FAILURE'
  };
}

import ShippingCompany from '~/@core/domain/model/site/ShippingCompany';
import { ShippingCompanyService } from '~/@core/infrastructure/api/services';
import IUseCase from '../../IUseCase';
import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';

export default class GetShippingCompany
  implements IUseCase<number, Promise<DefaultPaginated<ShippingCompany[]>>>
{
  execute(siteId: number): Promise<DefaultPaginated<ShippingCompany[]>> {
    return ShippingCompanyService.fetchShippingCompany(siteId);
  }
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as API from '~/domain/api';

const prefix = '@auth';

export type AuthState = {
  permissions: API.AuthPermission[];
  accounts: string;
  profile: API.Profile;
};
const initialState: AuthState = {
  permissions: [] as API.AuthPermission[],
  accounts: '',
  profile: {} as API.Profile
};

type SetPermissionsAction = {
  permissions: API.AuthPermission[];
  accounts: string;
};
const auth = createSlice({
  name: prefix,
  initialState,
  reducers: {
    fetchPermissions: () => {},
    setPermissions: (state, action: PayloadAction<SetPermissionsAction>) => {
      state.permissions = action.payload.permissions;
      state.accounts = action.payload.accounts;
    },
    setProfile: (state, action: PayloadAction<API.Profile>) => {
      state.profile = action.payload;
    }
  }
});

export const actions = auth.actions;
export const reducer = auth.reducer;

const caseReducers = auth.caseReducers;
export const Types = Object.freeze({
  fetchPermissions: `${prefix}/${caseReducers.fetchPermissions.name}`
});

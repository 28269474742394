import React from 'react';

import Routes from './routes';

import useValidateToken from '~/hooks/useValidateToken';
import useRefreshFeatureFlags from '~/hooks/useRefreshFeatureFlags';

export default function App() {
  useValidateToken();
  useRefreshFeatureFlags();

  return <Routes />;
}

import { BannerApprovalState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.core.bannerApproval as BannerApprovalState;
};

export const getBannerApprovalList = createSelector([getRoot], state => {
  return state.bannerApprovalList;
});

export const getIsCleared = createSelector(
  [getRoot],
  state => state.status === 'cleared'
);

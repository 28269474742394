import produce from 'immer';
import { ApplicationError } from '~/domain/api/errors';
import { createCreator, createReducer, Reducer } from '~/helpers/util/reducers';

export type RequestStatus = 'idle' | 'running' | 'success' | 'failure';
export type GetVoucherHistoryStatus =
  | 'idle'
  | 'running'
  | 'success'
  | 'failure';
export type ActiveOrInactiveStatus = 'idle' | 'running' | 'success' | 'failure';

export type VoucherListState = {
  requestStatus: RequestStatus;
  activeOrInactiveStatus: ActiveOrInactiveStatus;
  getVoucherHistoryStatus: GetVoucherHistoryStatus;
  errorMessage?: string;
  pageNumber: number;
  pageSize: number;
  totalCount: number;
};

export type FetchEvents = {
  pageNumber?: number;
  pageSize?: number;
  status?: string;
  customerId?: number;
  customerGroupId?: number;
  description?: string;
  reajustmentAmount?: number;
  reajustmentType?: string;
  siteId?: number;
};

export type FetchPage = {
  pageNumber: number;
  pageSize: number;
};

export type FetchEventSuccess = {
  pageNumber?: number;
  pageSize?: number;
  totalCount: number;
};

export type FetchFailure = {
  error?: ApplicationError;
};

export type ActiveOrInactive = {
  id: number;
};

export type GetVoucherHistory = {
  id: number;
};

export type Error = {
  errorMessage?: string;
};

const prefix = '@voucher';

export const Types = Object.freeze({
  CLEAR: `${prefix}/CLEAR`,
  CLEAR_HISTORY: `${prefix}/CLEAR_HISTORY`,
  FETCH_INIT: `${prefix}/FETCH_INIT`,
  FETCH_SUCCESS: `${prefix}/FETCH_SUCCESS`,
  FETCH_FAILURE: `${prefix}/FETCH_FAILURE`,
  FETCH_EVENTS: `${prefix}/FETCH_EVENTS`,
  FETCH_EVENTS_SUCCESS: `${prefix}/FETCH_EVENTS_SUCCESS`,
  FETCH_EVENTS_FAILURE: `${prefix}/FETCH_EVENTS_FAILURE`,
  ACTIVEORINACTIVE_INIT: `${prefix}/ACTIVEORINACTIVE_INIT`,
  ACTIVEORINACTIVE_SUCCESS: `${prefix}/ACTIVEORINACTIVE_SUCCESS`,
  ACTIVEORINACTIVE_FAILURE: `${prefix}/ACTIVEORINACTIVE_FAILURE`,
  GETVOUCHERHISTORY_INIT: `${prefix}/GETVOUCHERHISTORY_INIT`,
  GETVOUCHERHISTORY_SUCCESS: `${prefix}/GETVOUCHERHISTORY_SUCCESS`,
  GETVOUCHERHISTORY_FAILURE: `${prefix}/GETVOUCHERHISTORY_FAILURE`
});

export const initialState: VoucherListState = {
  requestStatus: 'idle',
  activeOrInactiveStatus: 'idle',
  getVoucherHistoryStatus: 'idle',
  pageNumber: 1,
  pageSize: 10,
  totalCount: 0
};

const clearReducer: Reducer<VoucherListState, void> = (state, _action) => {
  return produce(state, draft => {
    draft.requestStatus = initialState.requestStatus;
    draft.activeOrInactiveStatus = initialState.activeOrInactiveStatus;
    draft.getVoucherHistoryStatus = initialState.getVoucherHistoryStatus;
  });
};

const clearReducerHistory: Reducer<VoucherListState, void> = (
  state,
  _action
) => {
  return produce(state, draft => {
    draft.getVoucherHistoryStatus = initialState.getVoucherHistoryStatus;
  });
};

const fetchInitReducer: Reducer<VoucherListState, void> = state => {
  return produce(state, draft => {
    draft.requestStatus = 'running';
  });
};

const fetchSuccessReducer: Reducer<VoucherListState, void> = state => {
  return produce(state, draft => {
    draft.requestStatus = 'success';
  });
};

const fetchFailureReducer: Reducer<VoucherListState, void> = state => {
  return produce(state, draft => {
    draft.requestStatus = 'success';
  });
};

const fetchEventsReducer: Reducer<VoucherListState, FetchEvents> = (
  state,
  action
) => {
  return produce(state, draft => {
    draft.requestStatus = 'running';
    let { pageNumber, pageSize } = action;
    if (!pageNumber) {
      pageNumber = initialState.pageNumber;
    }
    if (!pageSize) {
      pageSize = initialState.pageSize;
    }
    draft.pageNumber = pageNumber;
    draft.pageSize = pageSize;
  });
};

const fetchEventsSuccessReducer: Reducer<
  VoucherListState,
  FetchEventSuccess
> = (state, action) => {
  return produce(state, draft => {
    const { pageNumber, pageSize, totalCount } = action;
    draft.requestStatus = 'success';
    draft.pageNumber = pageNumber ?? initialState.pageNumber;
    draft.pageSize = pageSize ?? initialState.pageSize;
    draft.totalCount = totalCount;
  });
};

const fetchEventsFailureReducer: Reducer<VoucherListState, void> = state => {
  return produce(state, draft => {
    draft.requestStatus = 'failure';
  });
};

const getVoucherHistoryStatusInitReducer: Reducer<
  VoucherListState,
  ActiveOrInactive
> = state => {
  return produce(state, draft => {
    draft.getVoucherHistoryStatus = 'running';
  });
};

const getVoucherHistoryStatusSuccessReducer: Reducer<
  VoucherListState,
  void
> = state => {
  return produce(state, draft => {
    draft.getVoucherHistoryStatus = 'success';
  });
};

const getVoucherHistoryStatusFailureReducer: Reducer<
  VoucherListState,
  Error
> = (state, action) => {
  return produce(state, draft => {
    draft.getVoucherHistoryStatus = 'failure';
    draft.errorMessage = action.errorMessage;
  });
};

const activeOrInactiveStatusInitReducer: Reducer<
  VoucherListState,
  ActiveOrInactive
> = state => {
  return produce(state, draft => {
    draft.activeOrInactiveStatus = 'running';
  });
};

const activeOrInactiveStatusSuccessReducer: Reducer<
  VoucherListState,
  void
> = state => {
  return produce(state, draft => {
    draft.activeOrInactiveStatus = 'success';
  });
};

const activeOrInactiveStatusFailureReducer: Reducer<VoucherListState, Error> = (
  state,
  action
) => {
  return produce(state, draft => {
    draft.activeOrInactiveStatus = 'failure';
    draft.errorMessage = action.errorMessage;
  });
};

export const actions = Object.freeze({
  clear: createCreator<void>(Types.CLEAR),
  clearHistory: createCreator<void>(Types.CLEAR_HISTORY),
  fetchInit: createCreator<void>(Types.FETCH_INIT),
  fetchSuccess: createCreator<void>(Types.FETCH_SUCCESS),
  fetchFailure: createCreator<void>(Types.FETCH_FAILURE),
  fetchEvents: createCreator<FetchEvents>(Types.FETCH_EVENTS),
  fetchEventsSuccess: createCreator<FetchEventSuccess>(
    Types.FETCH_EVENTS_SUCCESS
  ),
  fetchEventsFailure: createCreator<void>(Types.FETCH_EVENTS_FAILURE),
  activeOrInactiveStatusInit: createCreator<ActiveOrInactive>(
    Types.ACTIVEORINACTIVE_INIT
  ),
  activeOrInactiveStatusSuccess: createCreator<void>(
    Types.ACTIVEORINACTIVE_SUCCESS
  ),
  activeOrInactiveStatusFailure: createCreator<Error>(
    Types.ACTIVEORINACTIVE_FAILURE
  ),
  getVoucherHistoryStatusInit: createCreator<GetVoucherHistory>(
    Types.GETVOUCHERHISTORY_INIT
  ),
  getVoucherHistoryStatusSuccess: createCreator<void>(
    Types.GETVOUCHERHISTORY_SUCCESS
  ),
  getVoucherHistoryStatusFailure: createCreator<Error>(
    Types.GETVOUCHERHISTORY_FAILURE
  )
});

export const reducer = createReducer(initialState, {
  [Types.CLEAR]: clearReducer,
  [Types.CLEAR_HISTORY]: clearReducerHistory,
  [Types.FETCH_INIT]: fetchInitReducer,
  [Types.FETCH_SUCCESS]: fetchSuccessReducer,
  [Types.FETCH_FAILURE]: fetchFailureReducer,
  [Types.FETCH_EVENTS]: fetchEventsReducer,
  [Types.FETCH_EVENTS_SUCCESS]: fetchEventsSuccessReducer,
  [Types.FETCH_EVENTS_FAILURE]: fetchEventsFailureReducer,
  [Types.ACTIVEORINACTIVE_INIT]: activeOrInactiveStatusInitReducer,
  [Types.ACTIVEORINACTIVE_SUCCESS]: activeOrInactiveStatusSuccessReducer,
  [Types.ACTIVEORINACTIVE_FAILURE]: activeOrInactiveStatusFailureReducer,
  [Types.GETVOUCHERHISTORY_INIT]: getVoucherHistoryStatusInitReducer,
  [Types.GETVOUCHERHISTORY_SUCCESS]: getVoucherHistoryStatusSuccessReducer,
  [Types.GETVOUCHERHISTORY_FAILURE]: getVoucherHistoryStatusFailureReducer
});

import { Button, Col, Form, Row, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  ModalBody,
  ModalDefault,
  ModalFooter,
  ModalHeader
} from '~/components/Globals';
import { globalContext } from '~/context/GlobalContext';

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  orderNumber: number | null;
  initialObservation: string;
  onObservationChange: (observations: string) => void;
}

const getLocalStorageKey = (orderNumber: number | null) =>
  `observation_${orderNumber}`;

export default function ObservationModal(props: Props) {
  const { isModalVisible, setIsModalVisible, orderNumber } = props;
  const { t } = useTranslation();
  const [formJustify] = useForm();

  const { activeAlert } = useContext(globalContext);

  const handleCancel = useCallback(() => {
    formJustify.resetFields();
    setIsModalVisible(false);
  }, [formJustify, setIsModalVisible]);

  useEffect(() => {
    if (isModalVisible && orderNumber !== null) {
      const savedObservation = localStorage.getItem(
        getLocalStorageKey(orderNumber)
      );
      if (savedObservation) {
        formJustify.setFieldsValue({ justification: savedObservation });
      }
    }
  }, [isModalVisible, orderNumber, formJustify]);

  const handleConfirm = useCallback(() => {
    formJustify.validateFields().then(values => {
      if (orderNumber !== null) {
        const newObservation = values.justification;

        props.onObservationChange(newObservation);

        localStorage.setItem(getLocalStorageKey(orderNumber), newObservation);
      }
      setIsModalVisible(false);
      activeAlert({
        message: 'Observação inserida com sucesso!',
        type: 'success',
        timeout: 5000
      });
    });
  }, [formJustify, setIsModalVisible, orderNumber, activeAlert, props]);

  return (
    <ModalDefault width={500} visible={isModalVisible} onCancel={handleCancel}>
      <ModalHeader>
        <span>Justificativa</span>
      </ModalHeader>

      <Form form={formJustify}>
        <ModalBody>
          <Form.Item noStyle name="justification">
            <TextArea placeholder={t('generals.typeHere')} />
          </Form.Item>
        </ModalBody>
      </Form>
      <ModalFooter>
        <Row justify="end">
          <Col>
            <Space>
              <Button onClick={handleCancel}>{t('generals.back')}</Button>
              <Button type="primary" onClick={handleConfirm}>
                {t('generals.confirm')}
              </Button>
            </Space>
          </Col>
        </Row>
      </ModalFooter>
    </ModalDefault>
  );
}

import styled from 'styled-components';
import { GenericModal } from '../GenericModal';

export const Modal = styled(GenericModal)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 32px 32px 24px;

  margin: 0 auto;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Icon = styled.div`
  float: left;
  margin-top: 2px;
  margin-right: 15px;
`;

export const Title = styled.div`
  font: 20px 'Roboto', sans-serif !important;
  font-size: 16px;
`;
export const EndTitle = styled.div`
  font: 17px 'Roboto', sans-serif !important;
  font-size: 16px;
`;

export const Question = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 0px 0px;
`;

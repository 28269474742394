// #FIXME
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addTab = (component: any) => {
  return {
    type: '@tabs/ADD_TAB',
    payload: component
  };
};

export const deleteTab = (name: string) => {
  return {
    type: '@tabs/DELETE_TAB',
    payload: name
  };
};

export const changeTab = (tab: string) => {
  return {
    type: '@tabs/CHANGE_TAB',
    payload: tab
  };
};

export const clearTabs = () => {
  return {
    type: '@tabs/CLEAR_TAB'
  };
};

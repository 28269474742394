import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import UnpublishedFilterParams from '~/domain/model/Orders/UnpublishedFilterParam';
import { handleDefaultErrorApi } from '~/helpers/util';
import * as UnpublishedServices from '~/services/producer/Unpublished';
import * as Models from '~/domain/model';
import { alertRequest } from '../../alert/actions';

export interface UpdateItems {
  id: number | undefined;
  customerId: number | undefined;
  removeLocation: string;
  natureOperationId: number;
  directSaleDraftItemsUpdate: Models.UnpublishedUpdate[];
}

export const fetchUnpublished = createAsyncThunk(
  'unpublished/fetchUnpublished',
  async (
    unpublishedFilterParams: UnpublishedFilterParams,
    { rejectWithValue }
  ) => {
    try {
      const response = await UnpublishedServices.findUnpublished(
        unpublishedFilterParams
      );
      return response.data.results;
    } catch (error: unknown) {
      handleDefaultErrorApi(error as AxiosError);
      return rejectWithValue([]);
    }
  }
);

export const publishUnpublished = createAsyncThunk(
  'unpublished/publishUnpublished',
  async (filterParams: number[], { dispatch, rejectWithValue }) => {
    try {
      const response = await UnpublishedServices.postUnpublished(filterParams);
      console.log(response);
      dispatch(alertRequest('Pedido gerado com sucesso.', 'success'));
      return true;
    } catch (error: unknown) {
      handleDefaultErrorApi(error as AxiosError);
      return rejectWithValue([]);
    }
  }
);

export const deleteUnpublished = createAsyncThunk(
  'unpublished/deleteUnpublished',
  async (filterParams: Models.UnpublishedDelete, { rejectWithValue }) => {
    console.log('deletecalls', filterParams);
    try {
      const response = await UnpublishedServices.deleteUnpublished(
        filterParams
      );
      console.log(response);
      return true;
    } catch (error: unknown) {
      handleDefaultErrorApi(error as AxiosError);
      return rejectWithValue([]);
    }
  }
);

export const updateUnpublished = createAsyncThunk(
  'unpublished/updateUnpublished',
  async (filterParams: UpdateItems, { rejectWithValue }) => {
    console.log('deletecalls', filterParams);
    try {
      const response = await UnpublishedServices.updateUnpublished(
        filterParams
      );
      console.log(response);
      return true;
    } catch (error: unknown) {
      handleDefaultErrorApi(error as AxiosError);
      return rejectWithValue([]);
    }
  }
);

// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { TechnicalSpecificationFetchParams } from '~/domain/model/TechnicalSpecification/TechnicalSpecificationFetchParams';
import { TechnicalSpecificationImportParams } from '~/domain/model/TechnicalSpecification/TechnicalSpecificationImportParams';
import { generateErrorMessage } from '~/helpers/util/error';
import * as technicalSpecificationServices from '~/services/producer/TechnicalSpecification';
import { alertRequest } from '../../alert/actions';

export const technicalSpecificationFetch = createAsyncThunk(
  `technicalSpecification/technicalSpecificationFetch`,
  async (
    params: TechnicalSpecificationFetchParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await technicalSpecificationServices.fetchRequest(
        params
      );
      return response;
    } catch (err: any) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao carregar especificações.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const technicalSpecificationExport = createAsyncThunk(
  `technicalSpecification/technicalSpecificationExport`,
  async (fileId: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await technicalSpecificationServices.exportRequest(
        fileId
      );
      return response;
    } catch (err: any) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao exportar especificação.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const technicalSpecificationImport = createAsyncThunk(
  `technicalSpecification/technicalSpecificationImport`,
  async (
    params: TechnicalSpecificationImportParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await technicalSpecificationServices.importRequest(
        params
      );
      dispatch(alertRequest('Especificação salva com sucesso!', 'success'));
      return response;
    } catch (err: any) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao salvar especificação.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const technicalSpecificationDelete = createAsyncThunk(
  `technicalSpecification/technicalSpecificationDelete`,
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await technicalSpecificationServices.deleteRequest(id);
      dispatch(alertRequest('Especificação deletada com sucesso!', 'success'));
      return response;
    } catch (err: any) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao deletar especificação.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

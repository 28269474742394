import { createSelector } from '~/helpers/util/selectors';
import { LoadingState } from './duck';

export const getRoot = (state: any) => {
  return state.ui.loading as LoadingState;
};

export const getLoadingStatus = createSelector(
  [getRoot],
  state => state.status
);
export const getIsLoading = createSelector(
  [getRoot],
  state => state.status === 'fetching'
);
export const getSubmitForm = createSelector(
  [getRoot],
  state => state.submitForm
);

export const getCloseModal = createSelector(
  [getRoot],
  state => state.closeModal
);
export const getDisableSave = createSelector(
  [getRoot],
  state => state.disableSave
);

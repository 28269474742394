import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReportsAnnouncement } from '~/domain/model';

export type ReportsAnnouncementState = {
  reportsAnnouncement: ReportsAnnouncement[];
};

export type Update = {
  reportsAnnouncement?: ReportsAnnouncement[];
};

export type ParamRequestReportsAnnouncement = {
  description?: string;
  startDate?: string;
  endDate?: string;
  role?: string;
  siteId?: number;
  pageNumber?: number;
  pageSize?: number;
};

const prefix = '@reportsAnnouncement';

export const initialState: ReportsAnnouncementState = {
  reportsAnnouncement: []
};

const reportsAnnouncement = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: state => {
      state.reportsAnnouncement = initialState.reportsAnnouncement;
    },
    update: (state, action: PayloadAction<Update>) => {
      if (action.payload.reportsAnnouncement) {
        state.reportsAnnouncement = action.payload.reportsAnnouncement;
      }
    }
  }
});

export const actions = reportsAnnouncement.actions;
export const reducer = reportsAnnouncement.reducer;

const caseReducers = reportsAnnouncement.caseReducers;
export const Types = Object.freeze({
  clear: `${prefix}/${caseReducers.clear.name}`,
  update: `${prefix}/${caseReducers.update.name}`
});

// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Moment } from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE } from '~/config/env/system';
import * as M from '~/domain/model';
import Customer from '~/domain/model/Customer/Customer';
import { validateOrder } from '~/helpers/order';
import * as OrderCalls from './apiCalls';

export const prefix = 'orders';

export interface OrdersState {
  orders: M.Order[];
  totalOrders: number;
  ordersPagination: M.DefaultPagination;
  loading: boolean;
  extraLoading: boolean;
  closeAwnser: boolean;
  customers: Customer[] | undefined;
  publishers: string[] | undefined;
  siteId: number;
  showEditObservationModal: boolean;
  closeAddOrderModal: boolean;
  showAddOrderModal: boolean;
  currentStep: number;
  opNatures: M.OrderOpNature[];
  qualityObservations: M.OrderQualityObservation[];
  removeLocations: M.OrderRemoveLocation[];
  resumeTableData: M.OrderAddTableData[];
  submitAddForm: boolean;
  submitFormFilter: boolean;
  stocks: any[];
  stocksForItems: any[];
  stocksInitial: any[];
  tableData: M.OrderAddTableData[];
  tableInputErrors: M.TableInputError[];
  validated: boolean;
  deliveryDateHold: Moment | undefined;
  commercialDateHold: Moment | undefined;
  reloadDatas: boolean;
  interIdSelected: any[];
  interIDsWaiting: any[];
  removeLocation: string;
  manyKeys: boolean;
  uuid: string;
  opNatureIdSelected: number | undefined;
  customerIdSelected: number | undefined;
}

const initialState: OrdersState = {
  orders: [],
  totalOrders: 0,
  ordersPagination: {
    PageNumber: DEFAULT_PAGE_NUMBER,
    PageSize: DEFAULT_PAGE_SIZE
  },
  loading: false,
  extraLoading: false,
  closeAwnser: false,
  customers: undefined,
  publishers: undefined,
  siteId: 0,
  showEditObservationModal: false,
  closeAddOrderModal: false,
  showAddOrderModal: false,
  currentStep: 0,
  opNatures: [],
  qualityObservations: [],
  removeLocations: [],
  resumeTableData: [],
  submitAddForm: false,
  submitFormFilter: false,
  stocks: [],
  stocksForItems: [],
  stocksInitial: [],
  tableData: [],
  tableInputErrors: [],
  validated: false,
  deliveryDateHold: undefined,
  commercialDateHold: undefined,
  reloadDatas: true,
  interIDsWaiting: [],
  interIdSelected: [],
  removeLocation: 'CVH',
  manyKeys: false,
  uuid: '',
  opNatureIdSelected: undefined,
  customerIdSelected: undefined
};
const ordersSlice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: state => ({
      ...initialState,
      interIDsWaiting: state.interIDsWaiting
    }),
    clearAddData: state => {
      state.resumeTableData = [];
      state.stocks = [];
      state.stocksInitial = [];
      state.tableData = [];
      state.tableInputErrors = [];
      state.validated = false;
    },
    nextStep: state => {
      state.currentStep += 1;
    },
    previousStep: state => {
      state.currentStep -= 1;
    },

    setOpNatureIdSelected: (state, { payload }) => {
      state.opNatureIdSelected = payload;
    },

    setCustomerIdSelected: (state, { payload }) => {
      state.customerIdSelected = payload;
    },

    setUUID: (state, action: PayloadAction<string>) => {
      state.uuid = action.payload;
    },
    setOrdersPagination: (
      state,
      action: PayloadAction<M.DefaultPagination>
    ) => {
      state.ordersPagination = action.payload;
    },
    setCloseAwnser: (state, action: PayloadAction<boolean>) => {
      state.closeAwnser = action.payload;
    },
    setRemoveLocation: (state, action: PayloadAction<string>) => {
      state.removeLocation = action.payload;
    },
    setSiteId: (state, action: PayloadAction<number>) => {
      state.siteId = action.payload;
    },
    setShowAddOrderModal: (state, action: PayloadAction<boolean>) => {
      state.showAddOrderModal = action.payload;
    },
    setShowEditObservationModal: (state, action: PayloadAction<boolean>) => {
      state.showEditObservationModal = action.payload;
    },
    setSubmitAddForm: (state, action: PayloadAction<boolean>) => {
      state.submitAddForm = action.payload;
    },
    setSubmitFormFilter: (state, action: PayloadAction<boolean>) => {
      state.submitFormFilter = action.payload;
    },
    setDeliveryDateHold: (state, action: PayloadAction<Moment>) => {
      state.deliveryDateHold = action.payload;
    },
    setCommercialDateHold: (state, action: PayloadAction<Moment>) => {
      state.commercialDateHold = action.payload;
    },
    setReloadDatas: (state, action: PayloadAction<boolean>) => {
      state.reloadDatas = action.payload;
    },
    setManyKeys: (state, action: PayloadAction<boolean>) => {
      state.manyKeys = action.payload;
    },
    clearStocks: state => {
      state.stocks = [];
    },
    clearFields: state => {
      state.publishers = [];
      state.opNatures = [];
      state.customers = [];
    },
    removeAddTableRow: (state, action: PayloadAction<number>) => {
      const updatedResumeTableData = state.resumeTableData.filter(
        (data: any) => data.stockId !== action.payload
      );
      state.resumeTableData = updatedResumeTableData?.map((data, index) => {
        return {
          ...data,
          index,
          key: index
        };
      });
      state.stocksForItems = updatedResumeTableData?.map((data, index) => {
        return {
          ...data,
          index,
          key: index
        };
      });
      const initialRowValue = state.stocksInitial.find(
        item => item.stockId === action.payload
      );
      state.tableData = state.tableData.map(data => {
        if (data.stockId === action.payload) {
          return initialRowValue;
        }
        return data;
      });
      state.stocks = state.stocks.map(stock => {
        if (stock.stockId === action.payload) {
          return initialRowValue;
        }
        return stock;
      });
    },
    setStocks: (state, action: PayloadAction<any[]>) => {
      state.stocks = action.payload;
    },
    clearData: state => {
      state.tableData = [];
      state.orders = [];
      state.ordersPagination = {
        PageSize: DEFAULT_PAGE_SIZE,
        PageNumber: DEFAULT_PAGE_NUMBER
      } as M.DefaultPagination;
    },
    clearStockWhenChangeDate: state => {
      state.stocksForItems = [];
      state.resumeTableData = [];
    },
    setTableData: (state, action: PayloadAction<any[]>) => {
      state.tableData = action.payload;
    },
    setAddTablePropertyValue: (
      state,
      action: PayloadAction<{
        value: any;
        property: string;
        stockId: number;
      }>
    ) => {
      const { payload } = action;
      const updatedTableData: any[] = state.tableData?.map((offer: any) => {
        if (offer.stockId === payload.stockId) {
          const updatedData = offer;
          updatedData[payload.property] = payload.value;
          return updatedData;
        }
        return offer;
      });
      const updatedStocks = state.stocks.map((stock: any) => {
        if (stock.stockId === payload?.stockId) {
          const updatedData = stock;
          updatedData[payload.property] = payload.value;
          return updatedData;
        }
        return stock;
      });
      const updatedStocksForItems = state.stocksForItems.map((stock: any) => {
        if (stock.stockId === payload?.stockId && state.currentStep === 1) {
          const updatedData = stock;
          updatedData[payload.property] = payload.value;
          return updatedData;
        }
        return stock;
      });
      const updatedResumeTableData = state.resumeTableData.map((data: any) => {
        if (data.stockId === payload?.stockId) {
          const updatedData = data;
          updatedData[payload.property] = payload.value;
          return updatedData;
        }
        return data;
      });
      state.tableData = updatedTableData;
      state.resumeTableData = updatedResumeTableData;
      state.stocks = updatedStocks;
      state.stocksForItems = updatedStocksForItems;
      state.validated = false;
    },
    validateOrders: (state, action: PayloadAction<{ submitNow: boolean }>) => {
      const errorMessages: M.TableInputError[] = [];
      const tableData =
        state.currentStep === 0 ? state.stocksForItems : state.resumeTableData;
      const resumeTableData: M.OrderAddTableData[] = [];
      tableData.forEach((item, i) => {
        if (state.currentStep === 1 && !item.packingCode) {
          validateOrder(item, i)?.forEach(error => errorMessages.push(error));
          resumeTableData.push(item as M.OrderAddTableData);
        }
        if (item.packingCode || item.quantityPackage || item.unitPrice) {
          validateOrder(item, i)?.forEach(error => errorMessages.push(error));
          resumeTableData.push(item as M.OrderAddTableData);
        }
      });
      if (errorMessages.length === 0) {
        state.resumeTableData = resumeTableData;
        state.tableInputErrors = [];
        if (resumeTableData.length) {
          state.validated = true;
          state.submitAddForm = action.payload.submitNow;
          state.currentStep = 1;
        }
        return;
      }
      state.tableInputErrors = errorMessages;
      state.validated = false;
    },
    setStocksForItems: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      const stocksFind = state.stocksForItems?.filter(
        product => product.stockId === payload.stockId
      ).length;
      if (stocksFind > 0) {
        const updatedStocksForItem: any | undefined = state.stocksForItems?.map(
          (product: any) => {
            if (product.stockId === payload.stockId) {
              return payload;
            }
            return product;
          }
        );
        state.stocksForItems = updatedStocksForItem;
      } else {
        const addStocksForItems = [...state.stocksForItems];
        addStocksForItems.push(payload);
        state.stocksForItems = addStocksForItems;
      }
    },
    setInterIDsWainting: (state, action: PayloadAction<any[]>) => {
      state.interIDsWaiting = action.payload;
    },
    setInterIdSelected: (state, action: PayloadAction<any[]>) => {
      state.interIdSelected = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(
      OrderCalls.fetchOrdersOpNaturesForCustomer.pending,
      state => {
        state.loading = true;
      }
    );
    builder.addCase(
      OrderCalls.fetchOrdersOpNaturesForCustomer.fulfilled,
      (state, action) => {
        state.opNatures = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(
      OrderCalls.fetchOrdersOpNaturesForCustomer.rejected,
      state => {
        state.loading = false;
      }
    );
    builder.addCase(OrderCalls.createOrders.pending, state => {
      state.loading = true;
    });
    builder.addCase(OrderCalls.createOrders.fulfilled, state => {
      state.closeAddOrderModal = true;
    });
    builder.addCase(OrderCalls.createOrders.rejected, state => {
      state.loading = false;
      state.uuid = uuidv4();
    });
    builder.addCase(OrderCalls.saveOrders.pending, state => {
      state.loading = true;
    });
    builder.addCase(OrderCalls.saveOrders.fulfilled, state => {
      state.closeAddOrderModal = true;
    });
    builder.addCase(OrderCalls.saveOrders.rejected, state => {
      state.loading = false;
    });
    builder.addCase(OrderCalls.fetchOrders.pending, state => {
      state.loading = true;
      state.orders = [];
    });
    builder.addCase(OrderCalls.fetchOrders.fulfilled, (state, action) => {
      const { results, totalCount } = action.payload as M.ResponseApi<
        M.Order[]
      >;
      state.orders = results;
      state.totalOrders = totalCount;
      state.loading = false;
    });
    builder.addCase(OrderCalls.fetchOrders.rejected, state => {
      state.loading = false;
    });
    builder.addCase(OrderCalls.fetchOrdersStocks.pending, state => {
      state.loading = true;
      state.extraLoading = true;
      state.stocks = [];
      state.stocksInitial = [];
      state.tableData = [];
    });
    builder.addCase(OrderCalls.fetchOrdersStocks.fulfilled, (state, action) => {
      console.log('fetchOrdersStocks > action.payload:: ', action.payload);
      const stockIdsSelecteds: any | undefined = state.stocksForItems?.map(
        (product: any) => {
          return product.stockId;
        }
      );
      const updatedStocksForItem: any | undefined = action.payload?.map(
        (product: any) => {
          if (stockIdsSelecteds.includes(product.stockId)) {
            const stocksFind = state.stocksForItems?.find(
              item => item.stockId === product.stockId
            );
            const updatedSelected = [stocksFind].map((stock: any) => {
              const updatedData = stock;
              updatedData.isSelected = true;
              return updatedData;
            });
            return updatedSelected[0];
          }
          return product;
        }
      );
      state.loading = false;
      state.extraLoading = false;
      state.stocks = updatedStocksForItem;
      state.stocksInitial = updatedStocksForItem;
      state.tableData = updatedStocksForItem;
    });
    builder.addCase(OrderCalls.fetchOrdersStocks.rejected, state => {
      state.loading = false;
      state.extraLoading = false;
    });

    builder.addCase(OrderCalls.fetchOrdersOpNatures.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      OrderCalls.fetchOrdersOpNatures.fulfilled,
      (state, action) => {
        state.opNatures = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(OrderCalls.fetchOrdersOpNatures.rejected, state => {
      state.loading = false;
    });
    builder.addCase(
      OrderCalls.fetchOrdersQualityObservations.pending,
      state => {
        state.loading = true;
      }
    );
    builder.addCase(
      OrderCalls.fetchOrdersQualityObservations.fulfilled,
      (state, action) => {
        state.qualityObservations = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(
      OrderCalls.fetchOrdersQualityObservations.rejected,
      state => {
        state.loading = false;
      }
    );
    builder.addCase(OrderCalls.fetchOrdersRemoveLocations.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      OrderCalls.fetchOrdersRemoveLocations.fulfilled,
      (state, action) => {
        state.removeLocations = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(OrderCalls.fetchOrdersRemoveLocations.rejected, state => {
      state.loading = false;
    });
    builder.addCase(OrderCalls.updateOrders.pending, state => {
      state.loading = true;
    });
    builder.addCase(OrderCalls.updateOrders.fulfilled, state => {
      state.loading = false;
      state.closeAwnser = true;
      const newArrayIds = state.interIDsWaiting;
      state.interIDsWaiting = newArrayIds.concat(state.interIdSelected);
      state.submitFormFilter = true;
    });
    builder.addCase(OrderCalls.updateOrders.rejected, state => {
      state.loading = false;
    });
    builder.addCase(OrderCalls.cancelOrder.pending, state => {
      state.loading = true;
    });
    builder.addCase(OrderCalls.cancelOrder.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(OrderCalls.cancelOrder.rejected, state => {
      state.loading = false;
    });
    builder.addCase(OrderCalls.answerOrderIntentions.pending, state => {
      state.loading = true;
    });
    builder.addCase(OrderCalls.answerOrderIntentions.fulfilled, state => {
      state.loading = false;
      state.submitFormFilter = true;
    });
    builder.addCase(OrderCalls.answerOrderIntentions.rejected, state => {
      state.loading = false;
    });
    builder.addCase(
      OrderCalls.fetchCustomers.fulfilled,
      (state, action: PayloadAction<Customer[]>) => {
        state.customers = action.payload;
      }
    );
    builder.addCase(
      OrderCalls.fetchPublishers.fulfilled,
      (state, action: PayloadAction<M.PublisherResponse | any>) => {
        state.publishers = action.payload.publisherNames;
      }
    );
  }
});

export const actions = ordersSlice.actions;
export const reducer = ordersSlice.reducer;

import { Property, Site } from '~/interface';

export function addRequest(property: Property) {
  return {
    type: '@site/ADD_REQUEST',
    payload: { property }
  };
}

export function addSuccess() {
  return {
    type: '@site/ADD_SUCCESS'
  };
}

export function addFailure() {
  return {
    type: '@site/ADD_FAILURE'
  };
}

export function searchSiteNotFilter() {
  return {
    type: '@site/SEARCH_SITE_NOT_FILTER',
    payload: {}
  };
}
export function searchSite() {
  return {
    type: '@site/SEARCH_SITE',
    payload: {}
  };
}
export function searchSuccess(data: Array<Site>) {
  return {
    type: '@site/SEARCH_SUCCESS',
    payload: data
  };
}
export function searchFailure() {
  return {
    type: '@site/SEARCH_FAILURE'
  };
}

export function siteSetVeilingRequest() {
  return {
    type: '@site/SET_VEILING_REQUEST'
  };
}

export function siteSetProductorRequest(id?: number) {
  return {
    type: '@site/SET_PRODUCTOR_REQUEST',
    payload: { id }
  };
}
export function searchSelecteds(search: string) {
  return {
    type: '@site/SEARCH_SELECTEDS',
    payload: { search }
  };
}
export function setSite(siteId: number) {
  return {
    type: '@site/SET_SITE',
    payload: { siteId }
  };
}
export function removeSites() {
  return {
    type: '@site/REMOVE_SITES'
  };
}

import { SiteLinksListState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.ui.siteLinksList as SiteLinksListState;
};

export const getIsLoading = createSelector(
  [getRoot],
  state =>
    state.fetchInitStatus === 'pending' ||
    state.fetchSiteLinksStatus === 'pending'
);

export const getFetchStatus = createSelector([getRoot], state => {
  return state.fetchInitStatus;
});

export const getFetchSiteLinksStatus = createSelector([getRoot], state => {
  return state.fetchSiteLinksStatus;
});

export const getPageNumber = createSelector([getRoot], state => {
  return state.pageNumber;
});

export const getPageSize = createSelector([getRoot], state => state.pageSize);

export const getTotalCount = createSelector(
  [getRoot],
  state => state.totalCount
);

export const getErrorMessage = createSelector([getRoot], state => {
  return state.errorMessage;
});

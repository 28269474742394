import { call, fork, put, takeLatest } from 'redux-saga/effects';

import * as API from '~/domain/api';

import {
  actions,
  Types,
  FetchBannerProducerManagement,
  ChangeStatus
} from './duck';
import * as BannerProducerManagementService from '~/store/modules/_core/bannerProducerManagement/service';
import * as BannerTypesService from '~/store/modules/_core/bannerTypes/service';
import * as SiteLinksService from '~/store/modules/_core/siteLinks/service';
import { Action } from '~/helpers/util/reducers';
import { alertRequest } from '../../alert/actions';
import { generateErrorMessage } from '~/helpers/util/error';
import { APIError } from '~/domain/api/errors';

export function* watchFetchBannerProducerManagementSaga(
  action: Action<FetchBannerProducerManagement>
) {
  yield fork(fetchBannerProducerManagementSaga, action.payload);
}

export function* watchFetchBannerImageSaga(action: Action<number>) {
  yield fork(fetchBannerImage, action.payload);
}

export function* watchActiveOrInactiveSaga(action: Action<ChangeStatus>) {
  yield fork(activeOrInactiveSaga, action.payload);
}

export function* fetchInitSaga(): any {
  try {
    yield call(SiteLinksService.getAllUrls);
    yield call(BannerTypesService.fetchAll);
    yield call(BannerProducerManagementService.fetchBannerProducerManagement, {
      pageNumber: 1,
      pageSize: 10
    });
    yield put(actions.fetchInitSuccess());
  } catch (err) {
    yield put(actions.fetchInitFailure());
  }
}

export function* fetchBannerProducerManagementSaga({
  description,
  url,
  bannerTypeId,
  uploadDate,
  status,
  pageNumber,
  pageSize
}: FetchBannerProducerManagement): any {
  try {
    const response: API.ServerResponse = yield call(
      BannerProducerManagementService.fetchBannerProducerManagement,
      {
        description,
        url,
        bannerTypeId,
        uploadDate,
        status,
        pageNumber,
        pageSize
      }
    );
    const data: API.DefaultResponse = response.data;

    const totalCount = data.totalCount ?? 0;
    yield put(
      actions.fetchBannerProducerManagementSuccess({
        pageNumber,
        pageSize,
        totalCount
      })
    );
  } catch (err) {
    yield put(actions.fetchBannerProducerManagementFailure());
  }
}

export function* fetchBannerImage(id: number): any {
  try {
    yield call(BannerProducerManagementService.fetchBannerImage, id);

    yield put(actions.fetchBannerImageSuccess());
  } catch (err) {
    yield put(
      alertRequest('Não foi possível obter a imagem do banner', 'error')
    );
    yield put(actions.fetchBannerImageFailure());
  }
}

export function* activeOrInactiveSaga({ id, isActive }: ChangeStatus) {
  try {
    yield call(BannerProducerManagementService.activeOrInactiveBanner, id);
    yield put(actions.activeOrInactiveBannerSuccess());
    if (isActive) {
      yield put(alertRequest('Banner desativado com sucesso.', 'success'));
      yield put(actions.activeOrInactiveBannerSuccess());
    } else {
      yield put(alertRequest('Banner ativado com sucesso.', 'success'));
      yield put(actions.activeOrInactiveBannerSuccess());
    }
  } catch (error) {
    if (error instanceof APIError) {
      yield put(actions.activeOrInactiveBannerFailure());
      yield put(alertRequest(generateErrorMessage(error.message), 'error'));
    } else {
      yield put(alertRequest('Não foi possível alterar o status.', 'error'));
      yield put(actions.activeOrInactiveBannerFailure());
    }
  }
}

export default [
  takeLatest(Types.fetchInit, fetchInitSaga),
  takeLatest(Types.activeOrInactiveBanner, watchActiveOrInactiveSaga),
  takeLatest(Types.fetchBannerImage, watchFetchBannerImageSaga),
  takeLatest(
    Types.fetchBannerProducerManagement,
    watchFetchBannerProducerManagementSaga
  )
];

// #FIXME Remover any
/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, takeLatest, put, fork } from 'redux-saga/effects';
import * as ProductService from '~/store/modules/_core/products/service';
import * as EcommerceManagementService from '~/store/modules/_core/ecommerceManagement/service';
import * as SiteService from '~/store/modules/_core/sites/service';
import * as CustomerService from '~/store/modules/_core/customers/service';
import {
  actions,
  CancelPurchaseManagement,
  FetchEcommerceManagement,
  FetchProducts,
  Types
} from './duck';
import { Action } from '~/helpers/util/reducers';

export function* watchFetchProducts(action: Action<FetchProducts>) {
  yield fork(fetchProductsSaga, action.payload);
}

export function* watchFetchList(action: Action<FetchEcommerceManagement>) {
  yield fork(fetchListSaga, action.payload);
}

export function* watchExportInit(action: Action<FetchEcommerceManagement>) {
  yield fork(exportSaga, action.payload);
}

export function* watchCancelPurchaseManagement(
  action: Action<CancelPurchaseManagement>
) {
  yield fork(cancelPurchaseManagement, action.payload);
}

export function* watchFetchHistoryPurchaseManagement(action: Action<number>) {
  yield fork(fetchHistoryPurchaseManagement, action.payload);
}

export function* fetchInitSaga(): any {
  try {
    yield call(SiteService.findMany);
    yield call(EcommerceManagementService.findCartStatusList);
    yield call(EcommerceManagementService.findTransactionStatusList);
    yield call(CustomerService.findCustomers);
    yield put(actions.fetchSuccessInit());
  } catch (err) {
    yield put(actions.fetchFailure());
  }
}

export function* fetchProductsSaga(params: FetchProducts): any {
  const { siteId } = params;
  try {
    yield call(ProductService.findProductsSimple, siteId);
    yield put(actions.fetchProductsSuccess());
  } catch (err) {
    yield put(actions.fetchProductsFailure());
  }
}

export function* fetchListSaga(params: FetchEcommerceManagement): any {
  try {
    const response = yield call(
      EcommerceManagementService.findListPurchaseManagement,
      params
    );
    yield put(actions.fetchListSuccess(response.data.totalCount));
  } catch (err: any) {
    yield put(actions.fetchListFailure(err.errorMessage));
  }
}

export function* exportSaga(params: FetchEcommerceManagement): any {
  try {
    yield call(EcommerceManagementService.onExport, params);
    yield put(actions.exportSuccess());
  } catch (err: any) {
    yield put(actions.exportFailure());
  }
}

export function* cancelPurchaseManagement(
  params: CancelPurchaseManagement
): any {
  try {
    yield call(EcommerceManagementService.cancelPurchaseManagement, params);
    yield put(actions.cancelPurchaseManagementSuccess());
  } catch (err: any) {
    yield put(actions.cancelPurchaseManagementFailure(err.errorMessage));
  }
}

export function* fetchHistoryPurchaseManagement(transactionId: number): any {
  try {
    yield call(
      EcommerceManagementService.findHistoryPurchaseManagement,
      transactionId
    );
    yield put(actions.fetchHistoryPurchaseManagementSuccess());
  } catch (err: any) {
    yield put(actions.fetchHistoryPurchaseManagementFailure());
  }
}

export default [
  takeLatest(Types.fetchInit, fetchInitSaga),
  takeLatest(Types.fetchList, watchFetchList),
  takeLatest(Types.exportInit, watchExportInit),
  takeLatest(Types.cancelPurchaseManagement, watchCancelPurchaseManagement),
  takeLatest(
    Types.fetchHistoryPurchaseManagement,
    watchFetchHistoryPurchaseManagement
  ),
  takeLatest(Types.fetchProducts, watchFetchProducts)
];

import { createSelector } from 'reselect';

import { CompetenceRegistrationState } from './index';

export const getRoot = (state: any) => {
  return state.competenceRegistration as CompetenceRegistrationState;
};

export const getBlackoutDates = createSelector(
  [getRoot],
  state => state.blackoutDates
);

export const getReloadPage = createSelector(
  [getRoot],
  state => state.reloadPage
);

export const getFilterReload = createSelector(
  [getRoot],
  state => state.filterReload
);

export const getReloadAddPage = createSelector(
  [getRoot],
  state => state.reloadAddPage
);

export const getPagination = createSelector([getRoot], state => {
  return state.pagination;
});

export const getConfirmDate = createSelector([getRoot], state => {
  return state.confirmDate;
});

export const getCreateStatus = createSelector([getRoot], state => {
  return state.createStatus;
});

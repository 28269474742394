/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import produce, { Draft } from 'immer';

import { DefaultPagination, ResponseApi } from '~/domain/model';
import type IPreferenceHistoric from '~/domain/model/ProductPreference/PreferenceHistoric';
import type IProductPreference from '~/domain/model/ProductPreference/ProductPreference';
import type ICompositionItem from '~/domain/model/ProductPreference/CompositionItem';
import * as API from '~/domain/api';

import {
  createPreferencesApi,
  fetchPreferenceHistory,
  fetchProductPreferences,
  fetchPreferenceColor,
  updateImageList,
  upload,
  fetchImages,
  deleteImage
} from './apiCalls';

export type SetPropertyAction = {
  value: any;
  property: string;
  id: number;
};

export interface ProductPreferencesState {
  productPreferences: IProductPreference[];
  preferencesPagination: DefaultPagination;
  totalCount: number;
  preferenceHistoric: IPreferenceHistoric[];
  colors: any;
  images: API.Image[];
  loading: boolean;
  refetchOffersList: boolean;
  closeModal: boolean;
  siteIdAux: number | undefined;
  cleanCheckbox: boolean;
}

const initialState: ProductPreferencesState = {
  productPreferences: [],
  preferencesPagination: {
    PageNumber: 1,
    PageSize: 100
  },
  totalCount: 0,
  preferenceHistoric: [] as IPreferenceHistoric[],
  colors: [],
  images: [],
  loading: false,
  refetchOffersList: false,
  closeModal: false,
  siteIdAux: undefined,
  cleanCheckbox: false
};

interface ISetProductPreference extends IProductPreference {
  [key: string]: string | number | boolean | undefined | ICompositionItem[];
}

export const prefix = 'productPreferences';

const productPreferencesSlice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clearPreferences: state => {
      state.productPreferences = [];
    },
    setCleanCheckbox: state => {
      state.cleanCheckbox = false;
    },
    setCloseModal: (state, action: PayloadAction<boolean>) => {
      state.closeModal = action.payload;
    },
    setSiteIdAux: (state, action: PayloadAction<number | undefined>) => {
      state.siteIdAux = action.payload;
    },
    setPreferencesPagination: (
      state,
      action: PayloadAction<DefaultPagination>
    ) => {
      state.preferencesPagination = action.payload;
    },
    setProductPreference: (
      state,
      action: PayloadAction<{
        value: number;
        property: keyof ISetProductPreference;
        indexTable: number;
      }>
    ) => {
      const { value, property, indexTable } = action.payload;

      state.productPreferences[indexTable] = produce(
        state.productPreferences[indexTable],
        (draft: Draft<ISetProductPreference>) => {
          draft[property] = value;
          draft.changed = true;
        }
      );
    },
    setProductPreferenceAux: (
      state,
      action: PayloadAction<SetPropertyAction>
    ) => {
      const { payload } = action;
      const updatedTableData: any | undefined = state.productPreferences?.map(
        (offer: any, index) => {
          if (index === payload.id) {
            const updatedData = offer;
            updatedData[payload.property] = payload.value;
            updatedData.changed = true;
            return updatedData;
          }
          return offer;
        }
      );
      state.productPreferences = updatedTableData;
    },
    clearPreferenceHistoric: state => {
      state.preferenceHistoric = [];
    },
    setRefetchOffersList: (state, action: PayloadAction<boolean>) => {
      state.refetchOffersList = action.payload;
    },
    setImages: (state, action: PayloadAction<API.Image[]>) => {
      state.images = action.payload;
    },
    setColors: (state, action: PayloadAction<any>) => {
      state.colors = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(
      fetchProductPreferences.fulfilled,
      (state, action: PayloadAction<ResponseApi<IProductPreference[]>>) => {
        const { results, totalCount } = action.payload;
        state.productPreferences = results;
        state.totalCount = totalCount;
        state.loading = false;
      }
    );
    builder.addCase(fetchProductPreferences.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchProductPreferences.rejected, state => {
      state.loading = false;
    });
    builder.addCase(fetchPreferenceHistory.fulfilled, (state, action) => {
      state.preferenceHistoric = action.payload.data;
      state.loading = false;
    });
    builder.addCase(fetchPreferenceHistory.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchPreferenceHistory.rejected, state => {
      state.loading = false;
    });
    builder.addCase(createPreferencesApi.fulfilled, (state, action) => {
      const preferencesResponse = action.payload;
      state.productPreferences = state.productPreferences.map(preference => {
        const preferenceInResponse = preferencesResponse.find(
          preferenceResponse =>
            preferenceResponse.productId === preference.productId
        );
        if (preferenceInResponse) {
          return { ...preferenceInResponse };
        }
        return { ...preference };
      });
      state.loading = false;
      state.cleanCheckbox = true;
    });
    builder.addCase(createPreferencesApi.pending, state => {
      state.loading = true;
    });
    builder.addCase(createPreferencesApi.rejected, state => {
      state.loading = false;
    });
    builder.addCase(fetchPreferenceColor.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.colors = data;
      state.loading = false;
    });
    builder.addCase(fetchPreferenceColor.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchPreferenceColor.rejected, state => {
      state.loading = false;
    });
    builder.addCase(fetchImages.fulfilled, (state, action) => {
      state.images = action.payload.data.images;
      state.loading = false;
    });
    builder.addCase(fetchImages.pending, state => {
      state.images = [];
      state.loading = true;
    });
    builder.addCase(fetchImages.rejected, state => {
      state.loading = false;
    });
    builder.addCase(upload.fulfilled, (state, action) => {
      state.images = action.payload.data;
      state.loading = false;
    });
    builder.addCase(upload.pending, state => {
      state.loading = true;
    });
    builder.addCase(upload.rejected, state => {
      state.loading = false;
    });
    builder.addCase(updateImageList.pending, (state, action) => {
      state.images = action.meta.arg.newList;
      state.loading = true;
    });
    builder.addCase(updateImageList.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(updateImageList.rejected, (state, action) => {
      state.images = action.meta.arg.list;
      state.loading = false;
    });
    builder.addCase(deleteImage.pending, state => {
      state.loading = true;
    });
    builder.addCase(deleteImage.fulfilled, (state, action) => {
      state.loading = false;
      state.refetchOffersList = action.meta.arg.filesArrayLength === 1;
      state.closeModal = action.meta.arg.filesArrayLength === 1;
    });
    builder.addCase(deleteImage.rejected, state => {
      state.loading = false;
    });
  }
});

export const actions = productPreferencesSlice.actions;
export const reducer = productPreferencesSlice.reducer;

import produce from 'immer';
import { Competence } from '~/domain/model';
import { createCreator, createReducer, Reducer } from '~/helpers/util/reducers';

export type Status = 'cleared' | 'updated';

export type CompetencesState = {
  competenceByID: Map<string, Competence>;
  status: Status;
};

export type Update = {
  competenceByID: Map<string, Competence>;
};

const prefix = '@competences';

export const Types = Object.freeze({
  CLEAR: `${prefix}/CLEAR`,
  UPDATE: `${prefix}/UPDATE`
});

export const initialState: CompetencesState = {
  competenceByID: new Map(),
  status: 'cleared'
};

const clearReducer: Reducer<CompetencesState, void> = state => {
  return produce(state, draft => {
    draft.competenceByID = initialState.competenceByID;
    draft.status = initialState.status;
  });
};

const updateReducer: Reducer<CompetencesState, Update> = (state, action) => {
  return produce(state, draft => {
    draft.competenceByID = action.competenceByID as Map<string, Competence>;
  });
};

export const actions = Object.freeze({
  clear: createCreator<void>(Types.CLEAR),
  update: createCreator<Update>(Types.UPDATE)
});

export const reducer = createReducer(initialState, {
  [Types.CLEAR]: clearReducer,
  [Types.UPDATE]: updateReducer
});

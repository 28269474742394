import { AxiosError, AxiosResponse } from 'axios';
import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';
import FilterData from '~/@core/domain/filters/rfidReports/FilterData';
import Movement from '~/@core/domain/model/rfidReports/Movement';
import { apiRfid } from '../../config';

export const fetchDones = (params: FilterData) => {
  return new Promise<DefaultPaginated<Movement[]>>((resolve, reject) => {
    apiRfid
      .get<void, AxiosResponse<DefaultPaginated<Movement[]>>>(
        'api/movement/dones',
        {
          params
        }
      )
      .then((response: AxiosResponse<DefaultPaginated<Movement[]>>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

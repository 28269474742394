import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

export const getRoot = (state: any) => {
  return state.harvestingOrders as any;
};

export const useHarvestingOrdersState = () => {
  return useSelector(createSelector([getRoot], state => state));
};

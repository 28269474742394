import { AxiosError, AxiosResponse } from 'axios';
import RemoveLocation from '~/@core/domain/model/removeLocation/RemoveLocation';
import { endpoints } from '~/constants/endpoints';
import * as M from '~/domain/model';
import OrderFilterParams from '~/domain/model/Orders/OrderFilterParam';
import ResponseApiStock from '~/domain/model/ResponseApiStock';
import Stocks from '~/domain/model/Stock/Stocks';
import { default as api, default as apiProducer } from '../api';

export const createOrdersRequest = async (params: M.OrderCreateParams) => {
  const response = await apiProducer.post(endpoints.orders.directSale, params);
  return response.data;
};

export const saveOrdersRequest = async (params: M.OrderCreateParams) => {
  const response = await apiProducer.post(endpoints.orders.draft, params);
  return response;
};

export const findOrdersRequest = async (
  orderFilterParams: OrderFilterParams
) => {
  return apiProducer.get<void, AxiosResponse<M.ResponseApi<M.Order[]>>>(
    endpoints.orders.base,
    { params: orderFilterParams }
  );
};

export const findOrdersQualityObservationsRequest = async () => {
  const response = await apiProducer.get<void, AxiosResponse>(
    endpoints.orders.qualityObservation
  );
  return response.data?.result ?? response.data?.results;
};

export const findOrdersOpNaturesRequest = async () => {
  const response = await apiProducer.get<void, AxiosResponse>(
    endpoints.orders.opNature
  );
  return response.data?.result ?? response.data?.results;
};

export const findRemoveLocationsRequest = async (
  accountcode: string,
  siteId: number
) => {
  const response = await apiProducer.get<
    void,
    AxiosResponse<M.OrderRemoveLocation[]>
  >(
    `${endpoints.orders.removeLocations}?accountcode=${accountcode}&siteId=${siteId}`
  );
  return response.data;
};

export const fetchOrdersStocksRequest = async (
  fetchStockParams: M.FindOrderStockParams
) => {
  const response = await apiProducer.get<
    M.FetchStockParams,
    AxiosResponse<ResponseApiStock<Stocks[]>>
  >(endpoints.stock.stocks, {
    params: fetchStockParams
  });
  return response.data;
};

export const updateOrdersApi = async (
  orderUpdateParams: M.OrderUpdateParam
) => {
  return apiProducer.put<
    M.OrderUpdateParam,
    AxiosResponse<M.ResponseApi<M.Order[]>>
  >(endpoints.orders.base, orderUpdateParams);
};

export const cancelOrderRequest = async (ids: number[]) => {
  return apiProducer.put(endpoints.orders.cancel, ids);
};

export const findPublishers = async () => {
  const response = await apiProducer.get<
    void,
    AxiosResponse<M.PublisherResponse>
  >(endpoints.orders.publisher);
  return response.data;
};

export const findOpNatures = async () => {
  const response = await apiProducer.get<
    void,
    AxiosResponse<M.OrderOpNature[]>
  >(`${endpoints.orders.opNature}`);
  return response.data;
};

export const findOpNaturesForCustomer = async (customerId: number) => {
  const response = await apiProducer.get<
    void,
    AxiosResponse<M.OrderOpNature[]>
  >(`${endpoints.orders.opNatureForCustomer}?customerId=${customerId}`);
  return response.data;
};

export const answerOrderIntentionsRequest = async (
  params: M.OrderIntentionAnswer[]
) => {
  const response = await apiProducer.post(
    '/order/buy-intention/response',
    params
  );
  return response;
};

export const republishDirectedSale = async (ids: number[]) => {
  return apiProducer.put(`/direct-sale-draft/republish/`, ids);
};

export const fetchRemoveLocationsByCustomerAndSite = (
  accountCode: string,
  siteId: number
) => {
  return new Promise<RemoveLocation[]>((resolve, reject) => {
    api
      .get<void, AxiosResponse<RemoveLocation[]>>(
        `/order/remove-locations?accountcode=${accountCode}&siteId=${siteId}`
      )
      .then((response: AxiosResponse<RemoveLocation[]>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export interface ExportOrderData {
  Order: boolean;
  Origin: boolean;
  DeliveryDate: boolean;
  DeliveryHour: boolean;
  BillingDate: boolean;
  CustomerCode: boolean;
  CustomerName: boolean;
  LongDescription: boolean;
  ProductCode: boolean;
  QualityCode: boolean;
  PackingCode: boolean;
  PackingDescription: boolean;
  QuantityPacking: boolean;
  QuantityPerPackage: boolean;
  QuantityAttend: boolean;
  UnitValue: boolean;
  TotalValue: boolean;
  NatureOperation: boolean;
  RemoveLocation: boolean;
  Observation: boolean;
  Justify: boolean;
}

export interface IParamsExport {
  pageNumber: number;
  pageSize: number;
  'Data.SiteId': number,
  'Data.ProductGroupId': number,
  'Data.ProductCategoryId': number,
  'Data.ProductId': number,
  'Data.CustomerId': number,
  'Data.DeliveryDateInitial': string,
  'Data.DeliveryDateFinal': string,
  'Data.IdNaturezaOp': number,
  'Data.Order': number,
  'Data.ProductQuality': '',
  'Data.PublishedBy': string,
  'Data.Status': string,
  'Data.ColorId': number,
  'Data.ExpeditionId': number
}

export async function exportOrders(
  paramsExport: IParamsExport,
  exportColumns: ExportOrderData
): Promise<AxiosResponse<any>> {
  const { pageNumber, pageSize } = paramsExport;

  return api.post<void, AxiosResponse<any>>(
    `/order/export`,
    exportColumns,
    {params: paramsExport}
  );
}

import { AxiosResponse } from 'axios';
import apiProducer from '../api';
import * as M from '~/domain/model';
import { endpoints } from '~/constants/endpoints';
import { BuySuggestion } from '~/@core/domain/model/orderManagement';

export const fetchOrderPublishersRequest = async () => {
  const response = await apiProducer.get<Array<M.OrderManagement.Publisher>>(
    endpoints.orderManagement.publishers
  );
  return response.data;
};

export const buySuggestionFetchRequest = async (
  params: M.OrderManagement.BuySuggestionFetchParams
) => {
  const entries = Object.entries(params);
  let url = endpoints.orderManagement.buySuggestion;
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of entries) {
    if (key !== 'Data.Status' && value !== undefined) {
      url += `${key}=${value}&`;
    }
  }
  const statusEnum = params['Data.Status'] ?? [];
  const statusArray = Object.values(statusEnum);
  statusArray.forEach((status: any) => {
    url += `Data.Status=${status}&`;
  });
  const response = await apiProducer.get<BuySuggestion[]>(`${url}`);
  return response.data;
};

export const fetchOccurrenceMessagesRequest = async () => {
  const response = await apiProducer.get<M.OrderManagement.Occurrence[]>(
    endpoints.orderManagement.occurrenceMessage,
    {
      headers: {
        'X-Is-Async-Loading': 'true'
      }
    }
  );
  return response.data;
};

export const occurrenceGenerateRequest = async (
  data: M.OrderManagement.OccurrenceGenerateParams
) => {
  const response = await apiProducer.put<AxiosResponse<any>>(
    endpoints.orderManagement.occurrence,
    data
  );
  return response.data;
};

import { VolOffersState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.core.volOffers as VolOffersState;
};

export const getVolOffers = createSelector([getRoot], state => {
  return state.volOffers;
});

export const getIsCleared = createSelector(
  [getRoot],
  state => state.status === 'cleared'
);

export const getDisableSave = createSelector(
  [getRoot],
  state => state.disabledSave
);

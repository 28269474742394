import { Card, FormInstance } from 'antd';
import FormFilterDataModel from '~/@core/domain/ui/forms/FormFilterDataModel';
import FormFilterExpeditionDones from '~/components/molecules/RfidReports/Expedition/Dones/FormFilterExpeditionDones';
import TableExpeditionDones from '~/components/molecules/RfidReports/Expedition/Dones/TableExpeditionDones';
import FormFilterExpeditionNonConformity from '~/components/molecules/RfidReports/Expedition/NonConformity/FormFilterExpeditionNonConformity';
import TableExpeditionNonConformity from '~/components/molecules/RfidReports/Expedition/NonConformity/TableExpeditionNonConformity';
import FormFilterExpeditionNonConformityGeral from '~/components/molecules/RfidReports/Expedition/NonConformityGeral/FormFilterExpeditionNonConformityGeral';
import TableExpeditionNonConformityGeral from '~/components/molecules/RfidReports/Expedition/NonConformityGeral/TableExpeditionNonConformityGeral';
import FormFilterExpeditionTransit from '~/components/molecules/RfidReports/Expedition/Transit/FormFilterExpeditionTransit';
import TableExpeditionTransit from '~/components/molecules/RfidReports/Expedition/Transit/TableExpeditionTransit';
import FormFilterExpeditionVision from '~/components/molecules/RfidReports/Expedition/Vision/FormFilterExpeditionVision';
import TableExpeditionVision from '~/components/molecules/RfidReports/Expedition/Vision/TableExpeditionVision';
import { TabContainerForm } from '~/pages/Home/styles';

interface IProps {
  filterFormDone: FormInstance<FormFilterDataModel>;
  filterFormNonConformity: FormInstance<FormFilterDataModel>;
  filterFormTransit: FormInstance<FormFilterDataModel>;
  filterFormVision: FormInstance<FormFilterDataModel>;
  filterFormNonConformityGeral: FormInstance<FormFilterDataModel>;
}

export default function ExpeditionWrapper(props: IProps) {
  const {
    filterFormDone,
    filterFormNonConformity,
    filterFormTransit,
    filterFormVision,
    filterFormNonConformityGeral
  } = props;
  return (
    <Card>
      <TabContainerForm>
        <TabContainerForm.TabPane tab="Realizados" key="1">
          <FormFilterExpeditionDones form={filterFormDone} />
          <TableExpeditionDones form={filterFormDone} />
        </TabContainerForm.TabPane>

        <TabContainerForm.TabPane tab="Inconformidade" key="2">
          <FormFilterExpeditionNonConformity form={filterFormNonConformity} />
          <TableExpeditionNonConformity form={filterFormNonConformity} />
        </TabContainerForm.TabPane>

        <TabContainerForm.TabPane tab="Trânsito" key="4">
          <FormFilterExpeditionTransit form={filterFormTransit} />
          <TableExpeditionTransit form={filterFormTransit} />
        </TabContainerForm.TabPane>

        <TabContainerForm.TabPane tab="Visão Trânsito Veiling" key="5">
          <FormFilterExpeditionVision form={filterFormVision} />
          <TableExpeditionVision form={filterFormVision} />
        </TabContainerForm.TabPane>

        <TabContainerForm.TabPane tab="Visão Geral" key="6">
          <FormFilterExpeditionNonConformityGeral
            form={filterFormNonConformityGeral}
          />
          <TableExpeditionNonConformityGeral
            form={filterFormNonConformityGeral}
          />
        </TabContainerForm.TabPane>
      </TabContainerForm>
    </Card>
  );
}

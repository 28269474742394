import * as API from 'domain/api';

export default class HistoryPurchaseManagement {
  public readonly json: API.HistoryPurchaseManagement;
  public readonly cartId: number;
  public readonly transactionId: number;
  public readonly orderNumber?: number;
  public readonly customerId: number;
  public readonly customerCode: string;
  public readonly customerName: string;
  public readonly productId: number;
  public readonly productCode: string;
  public readonly productDescription: string;
  public readonly qualityId: number;
  public readonly qualityDescription: string;
  public readonly cancelationDate: string;
  public readonly canceledBy: string;
  public readonly transactionStatusCode: string;
  public readonly transactionStatusDescription: string;
  public readonly cartStatusCode: string;
  public readonly cartStatusDescription: string;

  constructor(json: API.HistoryPurchaseManagement) {
    this.json = json;
    this.cartId = json.cartId;
    this.transactionId = json.transactionId;
    this.orderNumber = json.orderNumber;
    this.customerId = json.customerId;
    this.customerCode = json.customerCode;
    this.customerName = json.customerName;
    this.productId = json.productId;
    this.productCode = json.productCode;
    this.productDescription = json.productDescription;
    this.qualityId = json.qualityId;
    this.qualityDescription = json.qualityDescription;
    this.cancelationDate = json.cancelationDate;
    this.canceledBy = json.canceledBy;
    this.transactionStatusCode = json.transactionStatusCode;
    this.transactionStatusDescription = json.transactionStatusDescription;
    this.cartStatusCode = json.cartStatusCode;
    this.cartStatusDescription = json.cartStatusDescription;
  }
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductPreference } from '~/domain/model';
import PackingDistributionComposition from '~/domain/model/PackingDistributionComposition';

export type Status = 'cleared' | 'updated';

export type ProductPreferenceState = {
  productPreferencesByID: Map<string, ProductPreference>;
  productPreference: ProductPreference;
  compositionsById: Map<string, PackingDistributionComposition>;
  status: Status;
};

export type Update = {
  productPreferencesByID: Map<string, ProductPreference>;
};
export type UpdateProductPreference = {
  productPreference: ProductPreference;
};
export type UpdateCompositions = {
  compositionsById: Map<string, PackingDistributionComposition>;
};

const prefix = '@productPreferences';

export const initialState: ProductPreferenceState = {
  productPreferencesByID: new Map(),
  productPreference: {} as ProductPreference,
  compositionsById: new Map(),
  status: 'cleared'
};

const productPreferences = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: state => {
      state.productPreferencesByID = initialState.productPreferencesByID;
      state.status = initialState.status;
      state.productPreference = {} as ProductPreference;
    },
    clearProductPreference: state => {
      state.productPreference = {} as ProductPreference;
    },
    update: (state, action: PayloadAction<Update>) => {
      state.productPreferencesByID = action.payload
        .productPreferencesByID as Map<string, ProductPreference>;
    },
    updateProductPreference: (
      state,
      action: PayloadAction<UpdateProductPreference>
    ) => {
      state.productPreference = action.payload
        .productPreference as ProductPreference;
    },
    updateCompositions: (state, action: PayloadAction<UpdateCompositions>) => {
      state.compositionsById = action.payload.compositionsById;
    }
  }
});
export const actions = productPreferences.actions;
export const reducer = productPreferences.reducer;

const caseReducers = productPreferences.caseReducers;
export const Types = Object.freeze({
  clear: `${prefix}/${caseReducers.clear.name}`,
  update: `${prefix}/${caseReducers.update.name}`,
  updateProductPreference: `${prefix}/${caseReducers.updateProductPreference.name}`
});

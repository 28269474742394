import { Moment } from 'moment';
import { Dispatch, SetStateAction, createContext, useState } from 'react';
import GfpCarts from '~/@core/domain/model/gfp/GfpCarts';
import Order from '~/@core/domain/model/order/Order';
import QualityObservations from '~/@core/domain/model/order/QualityObservations';
import Stocks from '~/@core/domain/model/stock/Stocks';
import TablePagination from '~/@core/domain/ui/TablePagination';

export interface GFPContextAttributes {
  visibleRegister: boolean;
  setVisibleRegister: Dispatch<SetStateAction<boolean>>;
  activeKey: string;
  setActiveKey: Dispatch<SetStateAction<string>>;
  orderPagination: TablePagination;
  setOrderPagination: Dispatch<SetStateAction<TablePagination>>;
  showAddGFPModal: boolean;
  setShowAddGFPModal: Dispatch<SetStateAction<boolean>>;
  showObservationModal: boolean;
  setShowObservationModal: Dispatch<SetStateAction<boolean>>;
  order: Order[];
  setOrder: Dispatch<SetStateAction<Order[]>>;
  orderSelected: Order[];
  setOrderSelected: Dispatch<SetStateAction<Order[]>>;
  orderTempSelected: Order[];
  setOrderTempSelected: Dispatch<SetStateAction<Order[]>>;
  stocksSelected: Stocks[];
  setStocksSelected: Dispatch<SetStateAction<Stocks[]>>;
  qualityObservations: QualityObservations[];
  setQualityObservations: Dispatch<SetStateAction<QualityObservations[]>>;
  itemsCount: number;
  setItemsCount: Dispatch<SetStateAction<number>>;
  deliveryDateSelected: Moment[];
  setDeliveryDateSelected: Dispatch<SetStateAction<[]>>;
  stockPagination: TablePagination;
  setStockPagination: Dispatch<SetStateAction<TablePagination>>;
  stocks: Stocks[];
  setStocks: Dispatch<SetStateAction<Stocks[]>>;
  isMultiple: boolean;
  setIsMultiple: Dispatch<SetStateAction<boolean>>;
  isPageLoading: boolean;
  setIsPageLoading: Dispatch<SetStateAction<boolean>>;
  outDate: moment.Moment | null;
  setOutDate: Dispatch<SetStateAction<moment.Moment | null>>;
  selectedRowKeysKlok: React.Key[];
  setSelectedRowKeysKlok: Dispatch<SetStateAction<React.Key[]>>;
  gfpCarts: GfpCarts[];
  setGfpCarts: Dispatch<SetStateAction<GfpCarts[]>>;
  gfpCartsPagination: TablePagination;
  setGfpCartsPagination: Dispatch<SetStateAction<TablePagination>>;
  isMultiAddClicked: string;
  setIsMultiAddClicked: Dispatch<SetStateAction<string>>;
  selectedOrder: Order;
  setSelectedOrder: Dispatch<SetStateAction<Order>>;
  editOrderIndex: number;
  setEditOrderIndex: Dispatch<SetStateAction<number>>;
  justifyVisible: boolean;
  setJustifyVisible: Dispatch<SetStateAction<boolean>>;
  refetchProcessingOrders: boolean;
  setRefetchProcessingOrders: Dispatch<SetStateAction<boolean>>;
}

export const GFPContext = createContext<GFPContextAttributes>({
  visibleRegister: false,
  setVisibleRegister: () => {},
  activeKey: 'addGfp',
  setActiveKey: () => {},
  orderPagination: {
    pageSize: 100,
    current: 1,
    total: 1
  },
  setOrderPagination: () => {},
  showAddGFPModal: false,
  setShowAddGFPModal: () => {},
  showObservationModal: false,
  setShowObservationModal: () => {},
  order: [],
  setOrder: () => {},
  orderSelected: [],
  setOrderSelected: () => {},
  orderTempSelected: [],
  setOrderTempSelected: () => {},
  stocksSelected: [],
  setStocksSelected: () => {},
  qualityObservations: [],
  setQualityObservations: () => {},
  itemsCount: 0,
  setItemsCount: () => {},
  deliveryDateSelected: [],
  setDeliveryDateSelected: () => {},
  stockPagination: {
    pageSize: 100,
    current: 1,
    total: 1
  },
  setStockPagination: () => {},
  stocks: [],
  setStocks: () => {},
  isMultiple: false,
  setIsMultiple: () => {},
  isPageLoading: false,
  setIsPageLoading: () => {},
  outDate: null,
  setOutDate: () => {},
  selectedRowKeysKlok: [],
  setSelectedRowKeysKlok: () => {},
  gfpCarts: [],
  setGfpCarts: () => {},
  gfpCartsPagination: {
    pageSize: 100,
    current: 1,
    total: 1
  },
  setGfpCartsPagination: () => {},
  isMultiAddClicked: '',
  setIsMultiAddClicked: () => {},
  selectedOrder: {} as Order,
  setSelectedOrder: () => {},
  editOrderIndex: 0,
  setEditOrderIndex: () => {},
  justifyVisible: false,
  setJustifyVisible: () => {},
  refetchProcessingOrders: false,
  setRefetchProcessingOrders: () => {}
});

export const GFPProvider = ({ children }: any) => {
  const [visibleRegister, setVisibleRegister] = useState(false);
  const [activeKey, setActiveKey] = useState('addGfp');
  const [orderPagination, setOrderPagination] = useState({
    pageSize: 100,
    current: 1,
    total: 0
  });
  const [showAddGFPModal, setShowAddGFPModal] = useState(false);
  const [showObservationModal, setShowObservationModal] = useState(false);
  const [order, setOrder] = useState<Order[]>([]);
  const [orderSelected, setOrderSelected] = useState<Order[]>([]);
  const [orderTempSelected, setOrderTempSelected] = useState<Order[]>([]);
  const [qualityObservations, setQualityObservations] = useState<
    QualityObservations[]
  >([]);
  const [itemsCount, setItemsCount] = useState(0);
  const [deliveryDateSelected, setDeliveryDateSelected] = useState<[]>([]);
  const [stockPagination, setStockPagination] = useState({
    pageSize: 100,
    current: 1,
    total: 0
  });
  const [stocks, setStocks] = useState<Stocks[]>([]);
  const [stocksSelected, setStocksSelected] = useState<Stocks[]>([]);
  const [isMultiple, setIsMultiple] = useState<boolean>(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [outDate, setOutDate] = useState<moment.Moment | null>(null);
  const [selectedRowKeysKlok, setSelectedRowKeysKlok] = useState<React.Key[]>(
    []
  );
  const [gfpCarts, setGfpCarts] = useState<GfpCarts[]>([]);
  const [gfpCartsPagination, setGfpCartsPagination] = useState({
    pageSize: 100,
    current: 1,
    total: 0
  });
  const [isMultiAddClicked, setIsMultiAddClicked] = useState<string>('');
  const [selectedOrder, setSelectedOrder] = useState<Order>({} as Order);
  const [editOrderIndex, setEditOrderIndex] = useState(Number.NaN);
  const [justifyVisible, setJustifyVisible] = useState<boolean>(false);
  const [refetchProcessingOrders, setRefetchProcessingOrders] = useState(true);

  return (
    <GFPContext.Provider
      value={{
        visibleRegister,
        setVisibleRegister,
        activeKey,
        setActiveKey,
        orderPagination,
        setOrderPagination,
        showAddGFPModal,
        setShowAddGFPModal,
        order,
        setOrder,
        orderSelected,
        setOrderSelected,
        orderTempSelected,
        setOrderTempSelected,
        stocksSelected,
        setStocksSelected,
        qualityObservations,
        setQualityObservations,
        itemsCount,
        setItemsCount,
        deliveryDateSelected,
        setDeliveryDateSelected,
        stockPagination,
        stocks,
        setStocks,
        setStockPagination,
        isMultiple,
        setIsMultiple,
        isPageLoading,
        setIsPageLoading,
        outDate,
        setOutDate,
        selectedRowKeysKlok,
        setSelectedRowKeysKlok,
        gfpCarts,
        setGfpCarts,
        gfpCartsPagination,
        setGfpCartsPagination,
        isMultiAddClicked,
        setIsMultiAddClicked,
        selectedOrder,
        setSelectedOrder,
        editOrderIndex,
        setEditOrderIndex,
        justifyVisible,
        setJustifyVisible,
        refetchProcessingOrders,
        setRefetchProcessingOrders,
        showObservationModal,
        setShowObservationModal
      }}
    >
      {children}
    </GFPContext.Provider>
  );
};

import produce from 'immer';
import { Product } from '~/domain/model';
import { createCreator, createReducer, Reducer } from '~/helpers/util/reducers';
import ProductSimple from '../../../../domain/model/product-simple';

export type ProductsState = {
  byId: Map<string, Product>;
  productsSimple: ProductSimple[];
  byIdFiltered: Map<string, Product>;
};

export type Update = {
  byId: Map<string, Product>;
  byIdFiltered: Map<string, Product>;
};

export type UpdateProductsSimple = {
  productsSimple: ProductSimple[];
};

const prefix = '@products';

export const Types = Object.freeze({
  CLEAR: `${prefix}/CLEAR`,
  CLEAR_PRODUCTS_SIMPLE: `${prefix}/CLEAR_PRODUCTS_SIMPLE`,
  UPDATE: `${prefix}/UPDATE`,
  UPDATE_PRODUCTS_SIMPLE: `${prefix}/UPDATE_PRODUCTS_SIMPLE`,
  FILTER: `${prefix}/FILTER`
});

export type Filter = {
  keyword: string;
};

export const initialState: ProductsState = {
  byId: new Map(),
  byIdFiltered: new Map(),
  productsSimple: []
};

const clearReducer: Reducer<ProductsState, void> = state => {
  return produce(state, draft => {
    draft.byId = initialState.byId;
  });
};

const clearProductsSimpleReducer: Reducer<ProductsState, void> = state => {
  return produce(state, draft => {
    draft.productsSimple = initialState.productsSimple;
  });
};

const updateReducer: Reducer<ProductsState, Update> = (state, action) => {
  return produce(state, draft => {
    draft.byId = action.byId as Map<string, Product>;
  });
};

const updateProductsSimpleReducer: Reducer<
  ProductsState,
  UpdateProductsSimple
> = (state, action) => {
  return produce(state, draft => {
    draft.productsSimple = action.productsSimple as ProductSimple[];
  });
};

const filterReducer: Reducer<ProductsState, Filter> = (state, action) => {
  const { keyword } = action;
  return produce(state, draft => {
    const productsArray = Array.from(draft.byId.values());

    const products = productsArray.filter(product => {
      const selectText = product.description.toLocaleLowerCase();

      return selectText.includes(keyword.toLowerCase());
    });

    draft.byIdFiltered = new Map(
      products.map(product => [String(product.id), new Product(product)])
    );
  });
};

export const actions = Object.freeze({
  clear: createCreator<void>(Types.CLEAR),
  clearProductsSimple: createCreator<void>(Types.CLEAR_PRODUCTS_SIMPLE),
  update: createCreator<Update>(Types.UPDATE),
  updateProductsSimple: createCreator<UpdateProductsSimple>(
    Types.UPDATE_PRODUCTS_SIMPLE
  ),
  filter: createCreator<Filter>(Types.FILTER)
});

export const reducer = createReducer(initialState, {
  [Types.CLEAR]: clearReducer,
  [Types.CLEAR_PRODUCTS_SIMPLE]: clearProductsSimpleReducer,
  [Types.UPDATE]: updateReducer,
  [Types.UPDATE_PRODUCTS_SIMPLE]: updateProductsSimpleReducer,
  [Types.FILTER]: filterReducer
});

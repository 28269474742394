import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import AutoserviceClassificationListParam from '~/domain/model/AutoserviceClassificationListParam';
import * as AutoserviceModel from '~/domain/model/RatingPayload';
import * as AutoserviceServices from '~/services/producer/AutoserviceClassification';
import { alertRequest } from '../../alert/actions';

export const fetchProducersWhiteList = createAsyncThunk(
  `autoserviceClassification/fetchProducersWhiteList`,
  async (params: AutoserviceClassificationListParam) => {
    try {
      const data = await AutoserviceServices.findProducersWhiteList(params);
      return data;
    } catch (err: any) {
      console.log('error');
    }
  }
);

export const fetchProducersBlackList = createAsyncThunk(
  `autoserviceClassification/fetchProducersBlackList`,
  async (customerId: number) => {
    try {
      const data = await AutoserviceServices.findProducersBlackList(customerId);
      return data;
    } catch (err: any) {
      console.log('error');
    }
  }
);

export const postProducerClassification = createAsyncThunk(
  `autoserviceClassification/postProducerClassification`,
  async (payload: AutoserviceModel.RatingPayload, { dispatch }) => {
    try {
      const response =
        await AutoserviceServices.postProducerClassificationRequest(payload);
      return response?.data?.results;
    } catch (err: unknown) {
      const error = err as AxiosError;
      dispatch(
        alertRequest(
          JSON.stringify(error.response?.data.Errors.Messages),
          'error'
        )
      );
    }
  }
);

export const postItemBlackOrWhitelist = createAsyncThunk(
  `autoserviceClassification/postItemBlacklist`,
  async (payload: AutoserviceModel.RatingAddPayload, { dispatch }) => {
    try {
      const response =
        await AutoserviceServices.postItemBlackOrWhitelistRequest(payload);
      return response?.data?.results;
    } catch (err: unknown) {
      const error = err as AxiosError;
      dispatch(
        alertRequest(
          JSON.stringify(error.response?.data.Errors.Messages),
          'error'
        )
      );
    }
  }
);

export const updateRating = createAsyncThunk(
  `autoserviceClassification/updateRating`,
  async (payload: AutoserviceModel.RatingUpdatePayload, { dispatch }) => {
    try {
      const response = await AutoserviceServices.ratingUpdateRequest(payload);
      return response?.data?.results;
    } catch (err: unknown) {
      const error = err as AxiosError;
      dispatch(
        alertRequest(
          JSON.stringify(error.response?.data.Errors.Messages),
          'error'
        )
      );
    }
  }
);

export const deleteProducerPriority = createAsyncThunk(
  `autoserviceClassification/deleteProducerPriority`,
  async (payload: number, { dispatch }) => {
    try {
      const response = await AutoserviceServices.RemoveRequest(payload);
      return response?.data?.results;
    } catch (err: unknown) {
      const error = err as AxiosError;
      dispatch(
        alertRequest(
          JSON.stringify(error.response?.data.Errors.Messages),
          'error'
        )
      );
    }
  }
);

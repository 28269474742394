import produce from 'immer';

const INITIAL_STATE = {
  specialDates: [],
  specialDateModal: false,
  failure: false,
  success: false
};

export default function specialDate(state = INITIAL_STATE, action: any) {
  // #FIXME
  // eslint-disable-next-line consistent-return
  return produce(state, _ => {
    switch (action.type) {
      case '@specialDates/SEARCH_SPECIAL_DATES_SUCCESS': {
        return produce(state, draft => {
          draft.specialDates = action.payload.length ? action.payload : [];
          draft.specialDateModal = !!action.payload.length;
        });
      }
      case '@specialDates/REMOVE_SPECIAL_DATES': {
        return produce(state, draft => {
          draft.specialDates = [];
        });
      }
      case '@specialDates/SET_SPECIAL_DATE_MODAL': {
        return produce(state, draft => {
          draft.specialDateModal = action.payload.specialDateModal;
        });
      }
      default:
    }
  });
}

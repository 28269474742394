import { ProductPreferencesCreateState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.ui.productPreferencesCreate as ProductPreferencesCreateState;
};

export const getIsLoading = createSelector(
  [getRoot],
  state => state.fetchStatus === 'fetching'
);
export const getSubmitForm = createSelector(
  [getRoot],
  state => state.submitForm
);

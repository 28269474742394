import * as API from '~/domain/api';

export default class CustomerSegment {
  private json: API.CustomerSegment;
  public readonly id: number;
  public readonly code: string;
  public readonly description: string;

  constructor(json: API.CustomerSegment) {
    this.json = json;
    this.id = this.getId();
    this.code = this.getCode();
    this.description = this.getDescription();
  }

  private getId(): number {
    return this.json.id ?? Number.NaN;
  }

  private getCode(): string {
    return this.json.code ?? '';
  }

  private getDescription(): string {
    return this.json.description ?? '';
  }
}

// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AvailablePackingDistributionComposition,
  CustomerGroup,
  DefaultPagination,
  VolOffer
} from '~/domain/model';
import * as M from '~/domain/model';
import { validDirectedOffer } from '~/helpers/util/validator';
import {
  fetchStocksDirected,
  updateDirectedOffersCustomers,
  fetchConsumptionHistory
} from './apiCalls';

type consumptionHistoryType = {
  volOfferId: number;
  stockId: number;
  currentStock: number;
  productCode: string;
  productDescription: string;
  histories: [
    {
      quantity: number;
      customerId: number;
      customerName: string;
      customerAccount: string;
      creationDate: string;
    }
  ];
};

export interface DirectedOffersState {
  directedOffers: VolOffer[];
  directedOffersPagination: DefaultPagination;
  totaldirectedOffers: number;
  directedOfferOfferChannel: any;
  directedOfferHistory: any;
  stocks: any[];
  tableInputErrors: M.TableInputError[];
  tableData: any[];
  resumeTableData: any[];
  validated: boolean;
  submitNow: boolean;
  currentStep: number;
  selectedOffer?: VolOffer;
  customerGroups: CustomerGroup[];
  customerGroupsArraySelected: CustomerGroup[];
  closeCustomersModal: boolean;
  consumptionHistoryData: consumptionHistoryType | undefined;
  offer: any;
  showKeysForEdit: boolean;
  clearKeys: boolean;
}

const initialState = {
  directedOffers: [] as VolOffer[],
  directedOffersPagination: {} as DefaultPagination,
  totaldirectedOffers: 0,
  directedOfferOfferChannel: {} as any,
  directedOfferHistory: {} as any,
  stocks: [],
  tableInputErrors: [],
  tableData: [],
  resumeTableData: [],
  validated: false,
  submitNow: false,
  currentStep: 0,
  selectedOffer: undefined,
  customerGroups: [],
  customerGroupsArraySelected: [],
  closeCustomersModal: false,
  consumptionHistoryData: undefined,
  offer: undefined,
  showKeysForEdit: false,
  clearKeys: false
} as DirectedOffersState;

export const prefix = 'directedOffers';

export type SetPropertyAction = {
  value: any;
  property: string;
  stockId: number;
};

export type SetComposition = {
  index: number;
  compositionId: number;
  checked: boolean;
};

const directedOffersSlice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: () => initialState,
    // setDirectedOffersPagination: (
    //   state,
    //   action: PayloadAction<DefaultPagination>
    // ) => {
    //   state.directedOffersPagination = action.payload;
    // },
    clearStocks: state => {
      state.stocks = [];
      state.tableData = [];
      state.resumeTableData = [];
      state.validated = false;
    },
    setShowKeysForEdit: (state, action: PayloadAction<boolean>) => {
      state.showKeysForEdit = action.payload;
    },
    setClearKeys: (state, action: PayloadAction<boolean>) => {
      state.clearKeys = action.payload;
    },
    setTotalDirectedOffers: (state, action: PayloadAction<number>) => {
      state.totaldirectedOffers = action.payload;
    },
    setTableData: (state, action: PayloadAction<any[]>) => {
      state.tableData = action.payload;
      state.validated = false;
    },
    setSelectedOffer: (state, action: PayloadAction<VolOffer | undefined>) => {
      state.selectedOffer = action.payload;
    },
    setCustomerGroups: (state, action: PayloadAction<CustomerGroup[]>) => {
      state.customerGroups = action.payload;
    },
    customerGroupsCheck: (state, action: PayloadAction<CustomerGroup>) => {
      if (
        !state.customerGroupsArraySelected.find(
          group => group.id === action.payload.id
        )
      ) {
        state.customerGroupsArraySelected = [
          ...state.customerGroupsArraySelected,
          action.payload
        ];
      } else {
        state.customerGroupsArraySelected =
          state.customerGroupsArraySelected.filter(
            group => group.id !== action.payload.id
          ) ?? [];
      }
    },
    setCustomerGroupsArraySelected: (
      state,
      action: PayloadAction<CustomerGroup[]>
    ) => {
      state.customerGroupsArraySelected = action.payload;
    },
    customerGroupsArraySelectedAdd: (
      state,
      action: PayloadAction<CustomerGroup>
    ) => {
      state.customerGroupsArraySelected = [
        ...state.customerGroupsArraySelected,
        action.payload
      ];
    },
    customerGroupsArraySelectedRemove: (
      state,
      action: PayloadAction<number>
    ) => {
      state.customerGroupsArraySelected =
        state.customerGroupsArraySelected.filter(
          (selected: any) => selected.id !== action.payload
        );
    },
    setCloseCustomersModal: (state, action: PayloadAction<boolean>) => {
      state.closeCustomersModal = action.payload;
    },
    setSubmitNow: (state, action: PayloadAction<boolean>) => {
      state.submitNow = action.payload;
    },
    nextStep: state => {
      state.currentStep += 1;
    },
    previousStep: state => {
      state.currentStep -= 1;
    },
    removeTableRow: (state, action: PayloadAction<number>) => {
      const updatedResumeTableData = state.resumeTableData.filter(
        (data: any) => data.stockId !== action.payload
      );
      state.tableData = state.tableData.map(data => {
        if (
          updatedResumeTableData.find(
            resumeData => resumeData.stockId !== data.stockId
          )
        ) {
          return {
            ...data,
            exclusiveQuantity: 0,
            minimumQuantity: 0,
            specialPrice: 0,
            priceByLayer: 0,
            priceByPacking: 0,
            priceByTrolley: 0,
            observation: ''
          };
        }
        return data;
      });
      state.stocks = state.stocks.map(stock => {
        if (
          updatedResumeTableData.find(
            resumeData => resumeData.stockId !== stock.stockId
          )
        ) {
          return {
            ...stock,
            exclusiveQuantity: 0,
            minimumQuantity: 0,
            specialPrice: 0,
            priceByLayer: 0,
            priceByPacking: 0,
            priceByTrolley: 0,
            observation: ''
          };
        }
        return stock;
      });
      state.resumeTableData = updatedResumeTableData?.map((data, index) => {
        return {
          ...data,
          index,
          key: index
        };
      });
    },
    setPropertyValue: (state, action: PayloadAction<SetPropertyAction>) => {
      const { payload } = action;
      let updatedOffer: any = {};
      const updatedTableData: any[] = state.tableData?.map((offer: any) => {
        if (offer.stockId === payload.stockId) {
          const updatedData = offer;
          updatedData[payload.property] = payload.value;
          updatedOffer = updatedData;
          return updatedData;
        }
        return offer;
      });
      const updatedStocks = state.stocks.map(stock => {
        if (stock.stockId === updatedOffer?.stockId) {
          return updatedOffer;
        }
        return stock;
      });
      const updatedResumeTableData = state.resumeTableData.map(data => {
        if (data.stockId === updatedOffer?.stockId) {
          return updatedOffer;
        }
        return data;
      });
      state.tableData = updatedTableData;
      state.resumeTableData = updatedResumeTableData;
      state.stocks = updatedStocks;
      state.validated = false;
    },
    validateOffers: (state, action: PayloadAction<{ submitNow: boolean }>) => {
      const errorMessages: M.TableInputError[] = [];
      const tableData =
        state.currentStep === 1 ? state.tableData : state.resumeTableData;
      const resumeTableData: any[] = [];
      tableData.forEach((offer: VolOffer, i: number) => {
        if (
          !Number.isNaN(offer.exclusiveQuantity) &&
          Number(offer.exclusiveQuantity) > 0
        ) {
          validDirectedOffer(offer, i)?.forEach(error =>
            errorMessages.push(error)
          );
          resumeTableData.push(offer);
        }
      });
      if (errorMessages.length === 0) {
        state.resumeTableData = resumeTableData;
        state.tableInputErrors = [];
        if (resumeTableData.length) {
          state.validated = true;
          state.submitNow = action.payload.submitNow;
          state.currentStep = 2;
        }
        return;
      }
      state.tableInputErrors = errorMessages;
      state.validated = false;
    },
    setCompositions: (state, action: PayloadAction<SetComposition>) => {
      const { payload } = action;
      let updatedOffer: any = {};
      const updatedTableData = state.tableData?.map((offer, index: number) => {
        if (index === payload.index) {
          const updatedAvailablePackingDistributionCompositions =
            offer.productPreference?.availablePackingDistributionCompositions?.map(
              (available: any) => {
                if (available.id === payload.compositionId) {
                  return { ...available, selected: payload.checked };
                }
                return available;
              }
            );
          const updatedData: any = {
            ...offer,
            productPreference: {
              availablePackingDistributionCompositions:
                updatedAvailablePackingDistributionCompositions
            },
            packingDistributionCompositionIds: !payload.checked
              ? offer.packingDistributionCompositionIds.filter(
                  (id: number) => id !== payload.compositionId
                )
              : [
                  ...offer.packingDistributionCompositionIds,
                  payload.compositionId
                ]
          };
          updatedOffer = updatedData;
          return updatedData;
        }
        return offer;
      });
      const updatedTableDataResume = state.resumeTableData?.map(
        (offer, index: number) => {
          if (index === payload.index) {
            const updatedAvailablePackingDistributionCompositions =
              offer.productPreference?.availablePackingDistributionCompositions?.map(
                (available: any) => {
                  if (available.id === payload.compositionId) {
                    return { ...available, selected: payload.checked };
                  }
                  return available;
                }
              );
            const updatedData: any = {
              ...offer,
              productPreference: {
                availablePackingDistributionCompositions:
                  updatedAvailablePackingDistributionCompositions
              },
              packingDistributionCompositionIds: !payload.checked
                ? offer.packingDistributionCompositionIds.filter(
                    (id: number) => id !== payload.compositionId
                  )
                : [
                    ...offer.packingDistributionCompositionIds,
                    payload.compositionId
                  ]
            };
            updatedOffer = updatedData;
            return updatedData;
          }
          return offer;
        }
      );
      return {
        ...state,
        validated: false,
        stocks: state.stocks.map(stock => {
          if (stock.stockId === updatedOffer?.stockId) {
            return updatedOffer;
          }
          return stock;
        }),
        tableData: updatedTableData,
        resumeTableData: updatedTableDataResume
      };
    },
    setPagination: (state, action: PayloadAction<DefaultPagination>) => {
      state.directedOffersPagination = action.payload;
    },
    setOffer: (state, action: PayloadAction<any>) => {
      state.offer = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(updateDirectedOffersCustomers.fulfilled, state => {
      state.closeCustomersModal = true;
    });
    builder.addCase(fetchStocksDirected.fulfilled, (state, action) => {
      const offers = action.payload.results?.map((data, index) => {
        const packingDistributionCompositionIds: number[] = [];
        data.productPreference?.availablePackingDistributionCompositions?.forEach(
          (packing: AvailablePackingDistributionComposition) => {
            if (packing?.selected) {
              packingDistributionCompositionIds.push(packing.id);
            }
          }
        );
        return {
          volOfferQuantityAvailable: data.remainingQuantity,
          packingDistributionCompositionIds,
          productQualityDescription: data.qualityDescription,
          stockId: data.stockId,
          periodDescription: data.periodDescription,
          productDescription: data.productDescription,
          productCode: data.productCode,
          productPreference: data.productPreference,
          volOfferId: 0,
          startDate: data.startDate,
          endDate: data.endDate,
          exclusiveQuantity: 0,
          priceByPacking: 0,
          priceByLayer: 0,
          priceByTrolley: 0,
          minimumQuantity: 0,
          specialPrice: 0,
          status: 'I',
          observation: '',
          index,
          key: index,
          volDirectedOfferId: 0,
          volOfferTypeId: 4,
          avgPriceSold: data.avgPriceSold,
          percentBelowAverage: data.percentBelowAverage,
          percentAboveAverage: data.percentAboveAverage,
          useStockOnlyVol: data.isVolOnly
        };
      });
      state.stocks = offers;
      state.tableData = offers;
    });
    builder.addCase(
      fetchConsumptionHistory.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.consumptionHistoryData = action.payload;
      }
    );
  }
});

export const actions = directedOffersSlice.actions;
export const reducer = directedOffersSlice.reducer;

import { UnavailableDatesState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

// #FIXME
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRoot = (state: any) => {
  return state.unvailableDates as UnavailableDatesState;
};

export const getIsLoading = createSelector(
  [getRoot],
  state => state.fetchStatus === 'fetching'
);
export const getIsSuccess = createSelector(
  [getRoot],
  state => state.fetchStatus === 'success'
);
export const getFetchStatus = createSelector(
  [getRoot],
  state => state.fetchStatus
);

export const getFetchMessage = createSelector(
  [getRoot],
  state => state.fetchMessage
);
export const getUnavailableDates = createSelector(
  [getRoot],
  state => state.unavailableDates
);
export const getSubmit = createSelector([getRoot], state => state.submit);

export const getPagination = createSelector(
  [getRoot],
  state => state.pagination
);

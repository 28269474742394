import { FormInstance, useForm } from 'antd/lib/form/Form';
import React, { createContext, useMemo, useState } from 'react';
import FormFilterBIDashboardValues from '../domain/forms/FormFilterBIDashboardValues';
import { AddModalFooter, Contexts } from '../domain/model';
import * as M from '~/domain/model';

export interface BIDashboardContextAttributes {
  siteId: number;
  setSiteId: React.Dispatch<React.SetStateAction<number>>;
  stateId: number;
  setStateId: React.Dispatch<React.SetStateAction<number>>;
  bidashboards: M.BIDashboard[];
  setBIDashboards: React.Dispatch<React.SetStateAction<M.BIDashboard[]>>;
  formFilter: FormInstance<FormFilterBIDashboardValues>;
  deleteVisible: boolean;
  setDeleteVisible: React.Dispatch<React.SetStateAction<boolean>>;
  editVisible: boolean;
  setEditVisible: React.Dispatch<React.SetStateAction<boolean>>;
  historyVisible: boolean;
  setHistoryVisible: React.Dispatch<React.SetStateAction<boolean>>;
  saveDisabled: boolean;
  setSaveDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  bidashboardId: number;
  setBIDashboardId: React.Dispatch<React.SetStateAction<number>>;
  showAddBIDashboard: boolean;
  setShowAddBIDashboard: React.Dispatch<React.SetStateAction<boolean>>;
  addModalFooter: AddModalFooter | undefined;
  setAddModalFooter: React.Dispatch<
    React.SetStateAction<AddModalFooter | undefined>
  >;
  typeBIDashboard: number;
  setTypeBIDashboard: React.Dispatch<React.SetStateAction<number>>;
  selectedTableRows: M.BIDashboard[];
  setSelectedTableRows: React.Dispatch<React.SetStateAction<M.BIDashboard[]>>;
}
export const BIDashboardContext = createContext<BIDashboardContextAttributes>(
  {} as BIDashboardContextAttributes
);

export const BIDashboardContextProvider = (props: Contexts) => {
  const { children } = props;
  const [siteId, setSiteId] = useState<number>(Number.NaN);
  const [stateId, setStateId] = useState<number>(Number.NaN);

  const [bidashboards, setBIDashboards] = useState<M.BIDashboard[]>([]);

  const [form] = useForm();

  const [historyVisible, setHistoryVisible] = useState<boolean>(false);

  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);

  const [editVisible, setEditVisible] = useState<boolean>(false);

  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

  const [bidashboardId, setBIDashboardId] = useState<number>(Number.NaN);

  const [addModalFooter, setAddModalFooter] = useState<
    AddModalFooter | undefined
  >();

  const [selectedTableRows, setSelectedTableRows] = useState<M.BIDashboard[]>(
    []
  );

  const [showAddBIDashboard, setShowAddBIDashboard] = useState<boolean>(false);

  const [typeBIDashboard, setTypeBIDashboard] = useState<number>(2);

  const contextParams = useMemo(
    () => ({
      formFilter: form,
      siteId,
      setSiteId,
      stateId,
      setStateId,
      bidashboards,
      setBIDashboards,
      historyVisible,
      setHistoryVisible,
      editVisible,
      setEditVisible,
      deleteVisible,
      setDeleteVisible,
      saveDisabled,
      setSaveDisabled,
      bidashboardId,
      setBIDashboardId,
      addModalFooter,
      setAddModalFooter,
      selectedTableRows,
      setSelectedTableRows,
      showAddBIDashboard,
      setShowAddBIDashboard,
      typeBIDashboard,
      setTypeBIDashboard
    }),
    [
      form,
      siteId,
      stateId,
      bidashboards,
      historyVisible,
      editVisible,
      deleteVisible,
      saveDisabled,
      bidashboardId,
      addModalFooter,
      selectedTableRows,
      showAddBIDashboard,
      typeBIDashboard
    ]
  );
  return (
    <BIDashboardContext.Provider value={contextParams}>
      {children}
    </BIDashboardContext.Provider>
  );
};

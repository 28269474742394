import { FilterOutlined } from '@ant-design/icons';
import { Button } from 'antd';

interface ButtonSaveProps {
  onClick: () => any;
  disabled?: boolean;
}
export default function ButtonFilter(props: ButtonSaveProps) {
  const { onClick, disabled } = props;
  return (
    <Button disabled={disabled} onClick={onClick}>
      <FilterOutlined />
    </Button>
  );
}

import { call, fork, put, takeLatest } from 'redux-saga/effects';

import { actions, FetchCustomers, Types } from './duck';
import { Action } from '~/helpers/util/reducers';
import * as CustomerService from '~/store/modules/_core/customers/service';

export function* watchFetchCustomersSaga(action: Action<FetchCustomers>) {
  yield fork(fetchCustomersSaga, action.payload);
}

export function* watchFetchCustomersGroupSaga(action: Action<number>) {
  yield fork(fetchCustomersGroupSaga, action.payload);
}

export function* watchFetchCustomersByNameAndSegmentSaga(
  action: Action<FetchCustomers>
) {
  yield fork(fetchCustomersByNameAndSegmentSaga, action.payload);
}

export function* watchFetchCustomersByGroupIdSaga(
  action: Action<FetchCustomers>
) {
  yield fork(fetchCustomersByGroupIdSaga, action.payload);
}

export function* watchFetchCustomersSegmentSaga() {
  yield fork(fetchCustomersSegmentSaga);
}

export function* fetchCustomersSegmentSaga() {
  try {
    yield call(CustomerService.findCustomersSegment);
    yield put(actions.fetchCustomersSegmentSuccess());
  } catch (err) {
    yield put(actions.fetchCustomersSegmentFailure());
  }
}

export function* fetchCustomersGroupSaga(siteId: number) {
  try {
    yield call(CustomerService.findCustomerGroups, siteId);
    yield put(actions.fetchCustomersGroupSuccess());
  } catch (err) {
    yield put(actions.fetchCustomersGroupFailure());
  }
}

export function* fetchCustomersSaga(params: FetchCustomers) {
  const { groupId, voucherId, name, segmentId } = params;
  try {
    yield call(
      CustomerService.findCustomersByParams,
      groupId,
      voucherId,
      name,
      segmentId
    );
    yield put(actions.fetchCustomersByNameAndSegmentSuccess());
  } catch (err) {
    yield put(actions.fetchCustomersByNameAndSegmentFailure());
  }
}

export function* fetchCustomersByNameAndSegmentSaga(params: FetchCustomers) {
  const { name, segmentId } = params;
  try {
    yield call(CustomerService.findCustomersByNameAndSegment, name, segmentId);
    yield put(actions.fetchCustomersSuccess());
  } catch (err) {
    yield put(actions.fetchCustomersFailure());
  }
}

export function* fetchCustomersByGroupIdSaga(params: FetchCustomers) {
  const { groupId } = params;
  try {
    yield call(CustomerService.findCustomersByGroupId, groupId);
    yield put(actions.fetchCustomersSuccess());
  } catch (err) {
    yield put(actions.fetchCustomersFailure());
  }
}

export default [
  takeLatest(Types.FETCH_CUSTOMERS, watchFetchCustomersSaga),
  takeLatest(Types.FETCH_CUSTOMERS_GROUP, watchFetchCustomersGroupSaga),
  takeLatest(
    Types.FETCH_CUSTOMERS_BY_NAME_AND_SEGMENT,
    watchFetchCustomersByNameAndSegmentSaga
  ),
  takeLatest(
    Types.FETCH_CUSTOMERS_BY_GROUP_ID,
    watchFetchCustomersByGroupIdSaga
  ),
  takeLatest(Types.FETCH_CUSTOMERS_SEGMENT, watchFetchCustomersSegmentSaga)
];

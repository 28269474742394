import { AxiosResponse } from 'axios';
import { endpoints } from '~/constants/endpoints';
import * as M from '~/domain/model';
import api from '../api';
import { ResponseApi } from '~/domain/model';

export const fetchRequest = async ({
  productIds,
  ...rest
}: M.PlpFetchParams) => {
  console.log(`fetchRequest`);
  const params = new URLSearchParams();
  productIds?.forEach(productId => {
    params.append('Data.ProductIds', String(productId));
  });

  Object.keys(rest).forEach(key => {
    const value = rest[key as keyof typeof rest];
    if (value) {
      params.append(key, String(value));
    }
  });

  const response = await api.get<
    M.PlpFetchParams,
    AxiosResponse<ResponseApi<M.Plp[]>>
  >(endpoints.plp.plp, {
    params
  });

  return {
    ...response.data,
    results: response.data?.results?.map((item, index) => {
      return {
        ...item,
        index,
        a1Price: item.a1Price ?? 0,
        a2Price: item.a2Price ?? 0
        // bPrice: item.bPrice ?? 0
      };
    })
  };
};

export const fetchRegistersRequest = async ({
  siteId,
  startDate,
  endDate
}: M.PlpFetchRegistersParams) => {
  const response = await api.get<
    M.PlpFetchRegistersParams,
    AxiosResponse<M.PlpProductRegister[]>
  >(`${endpoints.plp.plp}/${siteId}/${startDate}/${endDate}`);
  return response.data.map((item, index) => {
    return {
      ...item,
      index,
      startDate,
      endDate
    };
  });
};

export const createRegistersRequest = async (items: M.PlpProductRegister[]) => {
  const params = items.map(item => {
    return {
      id: 0,
      siteId: item.siteId,
      productId: item.id,
      isActive: false,
      startDate: item.startDate,
      endDate: item.endDate,
      a1Price: item.a1Price,
      a2Price: item.a2Price > 0 ? item.a2Price : null,
      bPrice: item.bPrice
    };
  });
  const response = await api.put<M.Plp[], AxiosResponse<ResponseApi<M.Plp[]>>>(
    endpoints.plp.plp,
    params
  );
  return response.data.results;
};

export const updateRequest = async (items: M.Plp[]) => {
  const params = items.map(item => {
    return {
      id: item.id,
      siteId: item.siteId,
      productId: item.productId,
      isActive: item.isActive,
      startDate: item.startDate,
      endDate: item.endDate,
      a1Price: item.a1Price,
      a2Price: item.a2Price > 0 ? item.a2Price : null,
      bPrice: item.bPrice
    };
  });
  const response = await api.put<M.Plp[], AxiosResponse<ResponseApi<M.Plp[]>>>(
    endpoints.plp.plp,
    params
  );
  return response.data.results;
};

export const updateMassRequest = async (params: M.PlpMassUpdateParams) => {
  const response = await api.put(endpoints.plp.mass, params);
  return response;
};

export const fetchHistoricRequest = async (
  params: M.PlpHistoricFetchParams
) => {
  const response = await api.get<
    void,
    AxiosResponse<ResponseApi<M.PlpHistoric[]>>
  >(`${endpoints.plp.historic}`, {
    params: {
      'Data.id': params.id,
      PageSize: params.PageSize,
      PageNumber: params.PageNumber,
      OrderBy: params.OrderBy,
      Sort: params.Sort
    }
  });
  return response.data;
};

export const exportPlps = async ({
  productIds,
  ...rest
}: M.PlpExportParams) => {
  const params = new URLSearchParams();
  productIds?.forEach(productId => {
    params.append('Data.ProductIds', String(productId));
  });
  Object.keys(rest).forEach(key => {
    const value = rest[key as keyof typeof rest];
    if (value) {
      params.append(key, String(value));
    }
  });
  const response = await api.get<M.PlpExportParams, AxiosResponse>(
    endpoints.plp.export,
    {
      params
    }
  );
  return response;
};

import { AxiosResponse } from 'axios';
import { APIError } from '~/domain/api/errors';
import apiProducer from '../api';

export const requestReportsAnnouncement = async (
  description?: string,
  startDate?: string,
  endDate?: string,
  role?: string,
  siteId?: number,
  pageNumber?: number,
  pageSize?: number
) => {
  try {
    const zero = role?.toString();
    const params = new URLSearchParams();
    if (description) {
      params.append('data.description', description);
    }
    if (startDate) {
      params.append('data.startDate', startDate);
    }
    if (endDate) {
      params.append('data.endDate', endDate);
    }
    if (zero) {
      params.append('data.role', zero);
    }
    if (siteId) {
      params.append('data.siteId', siteId.toString());
    }
    if (pageNumber) {
      params.append('pageNumber', String(pageNumber));
    }
    if (pageSize) {
      params.append('pageSize', String(pageSize));
    }
    const response: AxiosResponse = await apiProducer.get(
      '/announcement/report',
      {
        params
      }
    );
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const onExport = async (
  description?: string,
  startDate?: string,
  endDate?: string,
  role?: string,
  siteId?: number
) => {
  try {
    const params = new URLSearchParams();
    if (description) {
      params.append('data.description', description);
    }
    if (startDate) {
      params.append('data.startDate', startDate);
    }
    if (endDate) {
      params.append('data.endDate', endDate);
    }
    if (role) {
      params.append('data.role', role);
    }
    if (siteId) {
      params.append('data.siteId', siteId.toString());
    }
    const response: AxiosResponse = await apiProducer.get(
      '/announcement/report/download',
      {
        params
      }
    );
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

import { AxiosResponse } from 'axios';
import * as API from '~/domain/api';
import { APIError } from '~/domain/api/errors';
// import {
//   UnknownError,
//   CompetenceEventAlreadyRegistered
// } from '~/domain/api/errors';
import apiProducer from '../api';

export const create = async (
  competenceEvent: API.CompetenceEvent,
  siteId?: number
) => {
  try {
    const {
      competenceId,
      startDate,
      endDate,
      firstDayAvailable,
      lastDayAvailable,
      justification,
      competenceEventSites,
      competenceEventType
    } = competenceEvent;

    const data: any = {
      id: 0,
      competenceId,
      startDate,
      endDate,
      firstWeekAnyDate: firstDayAvailable,
      lastWeekAnyDate: lastDayAvailable,
      justification,

      competenceEventSites,
      competenceEventType,
      siteIds: siteId || []
    };
    const response: any = await apiProducer.put('/competence/events', data);
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const fetchCompetenceEventsByCompetenceID = async (
  competenceID: number,
  status?: string,
  pageNumber?: number,
  pageSize?: number
) => {
  try {
    const params = new URLSearchParams();
    if (competenceID) {
      params.append('id', String(competenceID));
    }
    if (status) {
      params.append('status', status);
    }
    if (pageNumber) {
      params.append('pageNumber', String(pageNumber));
    }
    if (pageSize) {
      params.append('pageSize', String(pageSize));
    }
    const response: AxiosResponse = await apiProducer.get(
      '/competence/events',
      {
        params
      }
    );
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const updateStatus = async (id: number, active: boolean) => {
  try {
    let URL = '/competence/event/update';
    if (id) {
      URL += `?id=${id}`;
    }
    if (active) {
      URL += `&active=${active}`;
    }
    const response: AxiosResponse = await apiProducer.get(URL);
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const inactivate = async (id: number) => {
  try {
    const response: any = await apiProducer.delete(
      `/competence/events?competenceEventId=${id}`
    );
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

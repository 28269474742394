import * as M from '~/domain/model';
import { currencyParser } from './number';

export const validatePlpOptionalRules = (plp: M.Plp) => {
  let validAlert = '';

  if (plp.percentBelowAverage > 0 && plp.percentAboveAverage > 0) {
    const minToleranceA1 = plp.avgPriceSoldA1
      ? parseFloat(
          (
            plp.avgPriceSoldA1 -
            (plp.avgPriceSoldA1 * plp.percentBelowAverage) / 100
          ).toFixed(2)
        )
      : undefined;

    const minToleranceA2 = plp.avgPriceSoldA2
      ? parseFloat(
          (
            plp.avgPriceSoldA2 -
            (plp.avgPriceSoldA2 * plp.percentBelowAverage) / 100
          ).toFixed(2)
        )
      : undefined;

    const minToleranceB = plp.avgPriceSoldB
      ? parseFloat(
          (
            plp.avgPriceSoldB -
            (plp.avgPriceSoldB * plp.percentBelowAverage) / 100
          ).toFixed(2)
        )
      : undefined;

    const maxToleranceA1 = plp.avgPriceSoldA1
      ? parseFloat(
          (
            plp.avgPriceSoldA1 +
            (plp.avgPriceSoldA1 * plp.percentAboveAverage) / 100
          ).toFixed(2)
        )
      : undefined;

    const maxToleranceA2 = plp.avgPriceSoldA2
      ? parseFloat(
          (
            plp.avgPriceSoldA2 +
            (plp.avgPriceSoldA2 * plp.percentAboveAverage) / 100
          ).toFixed(2)
        )
      : undefined;

    const maxToleranceB = plp.avgPriceSoldB
      ? parseFloat(
          (
            plp.avgPriceSoldB +
            (plp.avgPriceSoldB * plp.percentAboveAverage) / 100
          ).toFixed(2)
        )
      : undefined;

    if (plp.a1Price && minToleranceA1 && plp.a1Price < minToleranceA1) {
      validAlert += `Preço A1 do ${plp.productLongDescription} na linha ${
        plp.index + 1
      } abaixo do preço minímo <br>`;
    }
    if (plp.a1Price && maxToleranceA1 && plp.a1Price > maxToleranceA1) {
      validAlert += `Preço A1 do ${plp.productLongDescription} na linha ${
        plp.index + 1
      } acima do preço maxímo <br>`;
    }
    if (plp.a2Price && minToleranceA2 && plp.a2Price < minToleranceA2) {
      validAlert += `Preço A2 do ${plp.productLongDescription} na linha ${
        plp.index + 1
      } abaixo do preço minímo <br>`;
    }
    if (plp.a2Price && maxToleranceA2 && plp.a2Price > maxToleranceA2) {
      validAlert += `Preço A2 do ${plp.productLongDescription} na linha ${
        plp.index + 1
      } acima do preço maxímo <br>`;
    }
    if (plp.bPrice && minToleranceB && plp.bPrice < minToleranceB) {
      validAlert += `Preço B do ${plp.productLongDescription} na linha ${
        plp.index + 1
      } abaixo do preço minímo <br>`;
    }
    if (plp.bPrice && maxToleranceB && plp.bPrice > maxToleranceB) {
      validAlert += `Preço B do ${plp.productLongDescription} na linha ${
        plp.index + 1
      } acima do preço maxímo <br>`;
    }
    if (plp.a1Price && plp.a2Price && plp.a1Price < plp.a2Price) {
      validAlert += `O produto ${plp.productLongDescription} na linha ${
        plp.index + 1
      } possui o preço A1 menor que o A2 <br>`;
    }
    if (plp.bPrice && plp.a1Price && plp.a2Price) {
      if (plp.bPrice > plp.a1Price) {
        validAlert += `O produto ${plp.productLongDescription} na linha ${
          plp.index + 1
        } possui o preço B maior que o A1 <br>`;
      }
      if (plp.bPrice > plp.a2Price) {
        validAlert += `O produto ${plp.productLongDescription} na linha ${
          plp.index + 1
        } possui o preço B maior que o A2 <br>`;
      }
    }
    return validAlert;
  }
  return '';
};

export const validatePlpMassUpdateMandatoryRules = (
  plp: any
): M.TableInputError[] => {
  const tableInputErrors: M.TableInputError[] = [];
  if (plp.a1Price && plp.a1Price > 0 && plp.a1Price < plp.plpMinimumValue) {
    tableInputErrors.push({
      tablerow: plp.index,
      property: 'a1Price',
      message: `Valor A1 informado para o produto ${plp.productLongDescription} na linha ${plp.index} não pode ser menor que o preço mínimo`
    });
  }
  if (plp.a2Price && plp.a2Price > 0 && plp.a2Price < plp.plpMinimumValue) {
    tableInputErrors.push({
      tablerow: plp.index,
      property: 'a2Price',
      message: `Valor A2 informado para o produto ${plp.productLongDescription} na linha ${plp.index} não pode ser menor que o preço mínimo`
    });
  }
  return tableInputErrors;
};

export const validatePlpProductRegisterOptionalRules = (
  plp: M.PlpProductRegister
) => {
  let validAlert = '';

  if (plp.percentBelowAverage > 0 && plp.percentAboveAverage > 0) {
    const minToleranceA1 = plp.avgPriceSoldA1
      ? parseFloat(
          (
            plp.avgPriceSoldA1 -
            (plp.avgPriceSoldA1 * plp.percentBelowAverage) / 100
          ).toFixed(2)
        )
      : undefined;

    const minToleranceA2 = plp.avgPriceSoldA2
      ? parseFloat(
          (
            plp.avgPriceSoldA2 -
            (plp.avgPriceSoldA2 * plp.percentBelowAverage) / 100
          ).toFixed(2)
        )
      : undefined;

    const minToleranceB = plp.avgPriceSoldB
      ? parseFloat(
          (
            plp.avgPriceSoldB -
            (plp.avgPriceSoldB * plp.percentBelowAverage) / 100
          ).toFixed(2)
        )
      : undefined;

    const maxToleranceA1 = plp.avgPriceSoldA1
      ? parseFloat(
          (
            plp.avgPriceSoldA1 +
            (plp.avgPriceSoldA1 * plp.percentAboveAverage) / 100
          ).toFixed(2)
        )
      : undefined;

    const maxToleranceA2 = plp.avgPriceSoldA2
      ? parseFloat(
          (
            plp.avgPriceSoldA2 +
            (plp.avgPriceSoldA2 * plp.percentAboveAverage) / 100
          ).toFixed(2)
        )
      : undefined;

    const maxToleranceB = plp.avgPriceSoldB
      ? parseFloat(
          (
            plp.avgPriceSoldB +
            (plp.avgPriceSoldB * plp.percentAboveAverage) / 100
          ).toFixed(2)
        )
      : undefined;

    if (plp.a1Price && minToleranceA1 && plp.a1Price < minToleranceA1) {
      validAlert += `<div style="margin-bottom: 10px;">${plp.fullName} - A1 - <span style="color: white; background-color: red; font-weight: bold; padding: 5px; border-radius: 5px;">Abaixo do preço</span></div>`;
    }
    if (plp.a1Price && maxToleranceA1 && plp.a1Price > maxToleranceA1) {
      validAlert += `<div style="margin-bottom: 10px;">${plp.fullName} - A1 - Acima do preço</div>`;
    }
    if (plp.a2Price && minToleranceA2 && plp.a2Price < minToleranceA2) {
      validAlert += `<div style="margin-bottom: 10px;">${plp.fullName} - A2 - <span style="color: white; background-color: red; font-weight: bold; padding: 5px; border-radius: 5px;">Abaixo do preço</span></div>`;
    }
    if (plp.a2Price && maxToleranceA2 && plp.a2Price > maxToleranceA2) {
      validAlert += `<div style="margin-bottom: 10px;">${plp.fullName} - A2 - Acima do preço</div>`;
    }
    if (plp.bPrice && minToleranceB && plp.bPrice < minToleranceB) {
      validAlert += `<div style="margin-bottom: 10px;">Preço B do ${
        plp.fullName
      } na linha ${plp.index + 1} abaixo do preço minímo</div>`;
    }
    if (plp.bPrice && maxToleranceB && plp.bPrice > maxToleranceB) {
      validAlert += `<div style="margin-bottom: 10px;">Preço B do ${
        plp.fullName
      } na linha ${plp.index + 1} acima do preço maxímo</div>`;
    }
    if (plp.a1Price && plp.a2Price && plp.a1Price < plp.a2Price) {
      validAlert += `<div style="margin-bottom: 10px;">O produto ${
        plp.fullName
      } na linha ${plp.index + 1} possui o preço A1 menor que o A2</div>`;
    }
    if (plp.bPrice && plp.a1Price && plp.a2Price) {
      if (plp.bPrice > plp.a1Price) {
        validAlert += `<div style="margin-bottom: 10px;">O produto ${
          plp.fullName
        } na linha ${plp.index + 1} possui o preço B maior que o A1</div>`;
      }
      if (plp.bPrice > plp.a2Price) {
        validAlert += `<div style="margin-bottom: 10px;">O produto ${
          plp.fullName
        } na linha ${plp.index + 1} possui o preço B maior que o A2</div>`;
      }
    }
    return validAlert;
  }
  return '';
};

export const validatePlpProductRegisterMandatoryRules = (
  plp: M.PlpProductRegister
): M.TableInputError[] => {
  const tableInputErrors: M.TableInputError[] = [];
  if (plp.toSend) {
    if (!plp.a1Price || Number.isNaN(plp.a1Price) || plp.a1Price <= 0) {
      tableInputErrors.push({
        tablerow: plp.index,
        property: 'a1Price',
        message: `Preço A1 do ${plp.fullName} em branco na linha ${plp.index}`
      });
    } else if (plp.a1Price < plp.plpMinimunValue) {
      tableInputErrors.push({
        tablerow: plp.index,
        property: 'a1Price',
        message: `Valor A1 informado para o produto ${plp.fullName} na linha ${plp.index} não pode ser menor que o preço mínimo`
      });
    }
    if (plp.a2Price && plp.a2Price > 0 && plp.a2Price < plp.plpMinimunValue) {
      tableInputErrors.push({
        tablerow: plp.index,
        property: 'a2Price',
        message: `Valor A2 informado para o produto ${plp.fullName} na linha ${plp.index} não pode ser menor que o preço mínimo`
      });
    }
    // if (plp.bPrice && plp.bPrice < plp.plpMinimunValue) {
    //   tableInputErrors.push({
    //     tablerow: plp.index,
    //     property: 'bPrice',
    //     message: `Valor B informado para o produto ${plp.fullName} na linha ${plp.index} não pode ser menor que o preço mínimo`
    //   });
    // }
  }
  return tableInputErrors;
};

export const validatePlpPrice = (value: any) => {
  if (typeof value === 'undefined') {
    return undefined;
  }
  if (Number(currencyParser(value)) > 0) {
    return Number(currencyParser(value));
  }
  return 0;
};

import { call, put, select } from 'redux-saga/effects';

import * as API from '~/domain/api';

import { actions } from './duck';
import { Site } from '~/domain/model';
import * as SiteService from '~/services/producer/Site';

export function* findMany(keyword?: string): any {
  let response: API.ServerResponse;
  const tabs = yield select((store: any) => store.tabs.tabs);
  const selectedTabIndex = yield select((store: any) => store.tabs.selectedTab);
  const selectedTab = tabs[selectedTabIndex];

  if (keyword) {
    response = yield call(SiteService.findManyWithKeyword, keyword);
  } else {
    response = yield call(SiteService.findMany, selectedTab.permissionName);
  }

  const sites: API.Site[] = response.data;
  const siteByID: Map<string, Site> = new Map(
    sites.map(site => [String(site.id), new Site(site)])
  );
  yield put(actions.update({ byId: siteByID, byIdFiltered: siteByID }));
  return response;
}

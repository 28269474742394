import { AxiosResponse } from 'axios';
import FilterTagPrintingList, {
  GenerateTagToPrintProps
} from '~/@core/domain/filters/tag/FilterTagPrintingList';
import { endpoints } from '~/constants/endpoints';
import { TagDataToPrintProps } from '~/domain/model/TagPrinting/TagDataToPrint';
import api from '../api';

export const findManyByProducts = async ({
  pageSize,
  pageNumber,
  viewByCustomer = false,
  ...params
}: FilterTagPrintingList) => {
  let url = `${endpoints.tagPrinting.base}/${
    viewByCustomer ? 'customer' : 'product'
  }`;
  const entries = Object.entries({ ...params });

  url += `?PageSize=${pageSize}&PageNumber=${pageNumber}`;
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of entries) {
    if (value !== undefined && value !== null) {
      url += `&${key}=${value}`;
    }
  }
  const response = await api.get(url);
  return response.data;
};

export const generateTagToPrint = async (params: GenerateTagToPrintProps) => {
  const response = await api.post(`${endpoints.tagPrinting.base}`, params);
  return response;
};

export const getTagDataToPrint = async (tagIds: number[]) => {
  let params = '';

  tagIds.forEach((tag, index) => {
    params += `${index > 0 ? '&' : '?'}tagIds=${tag}`;
  });

  const response = await api.get<number, AxiosResponse<TagDataToPrintProps[]>>(
    `${endpoints.tagPrinting.base}/tag${params}`
  );
  return response;
};

export const getPrintedList = async (props: {
  siteId: number;
  pageSize: number;
  pageNumber: number;
}) => {
  const { data } = await api.get(
    `${endpoints.tagPrinting.base}/printed?Data.SiteId=${props.siteId}&PageNumber=${props.pageNumber}&PageSize=${props.pageSize}`
  );

  return data;
};

export const deletePrintedListItem = async (tagIds: React.Key[]) => {
  const itemDelete = await api.delete(`${endpoints.tagPrinting.base}/tag`, {
    data: tagIds
  });
  return itemDelete;
};

export const updateOrders = async (
  items: { id: number; origin: string; observation: string }[]
) => {
  const response = await api.put<number, AxiosResponse<TagDataToPrintProps[]>>(
    `${endpoints.orders.base}internal-observation`,
    items
  );
  return response;
};

import { isNaN, isNil, isString } from 'lodash';

export function formatDecimal(value: number, locale = 'pt-BR') {
  return value.toLocaleString(locale, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  });
}

export function formatPercent(value: number, locale = 'pt-BR') {
  return Number(value / 100).toLocaleString(locale, {
    style: 'percent',
    maximumFractionDigits: 2
  });
}

export function currencyFormatter(value: any, showPrefix?: boolean) {
  let valueAdjusted = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value);
  if (!showPrefix) {
    valueAdjusted = valueAdjusted.replace('R$', '');
  }
  return valueAdjusted;
}

export function currencyParser(value: any) {
  try {
    // for when the input gets clears
    if (typeof value === 'string' && !value.length) {
      value = '0.0';
    }

    // detecting and parsing between comma and dot
    const group = new Intl.NumberFormat('pt-BR').format(1111).replace(/1/g, '');
    const decimal = new Intl.NumberFormat('pt-BR')
      .format(1.1)
      .replace(/1/g, '');
    let reversedVal = value.replace(new RegExp(`\\${group}`, 'g'), '');
    reversedVal = reversedVal.replace(new RegExp(`\\${decimal}`, 'g'), '.');
    //  => 1232.21 €

    // removing everything except the digits and dot
    reversedVal = reversedVal.replace(/[^0-9.]/g, '');
    //  => 1232.21

    // appending digits properly
    const digitsAfterDecimalCount = (reversedVal.split('.')[1] || []).length;
    const needsDigitsAppended = digitsAfterDecimalCount > 2;

    if (needsDigitsAppended) {
      // eslint-disable-next-line no-restricted-properties
      reversedVal *= Math.pow(10, digitsAfterDecimalCount - 2);
    }

    return Number.isNaN(reversedVal) ? 0 : reversedVal;
  } catch (error) {
    console.error(error);
  }
}

export const stringToFloat = (value: string) => {
  return parseFloat(value.replace('.', '').replace(',', '.'));
};

export const percentFormatter = (value: number | string | undefined | null) => {
  if (isNil(value) || value === '') {
    return '';
  }

  if (isString(value)) {
    const parsedValue = parseFloat(value.replace('%', ''));

    if (isNaN(parsedValue)) {
      return '0%';
    }

    return `${value.trim().replace('%', '')}%`;
  }

  return `${value}%`;
};

export const percentParser = (value: string | undefined | null): number => {
  if (isNil(value) || value.trim() === '') {
    return 0;
  }

  const parsedValue = parseFloat(value.replace('%', ''));

  if (parsedValue < 0) return 0;

  if (isNaN(parsedValue)) {
    return 0;
  }

  return parsedValue || 0;
};

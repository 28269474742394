import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as M from '~/domain/model';

export interface VolOffersState {
  volOffers: M.VolOffer[];
  volOffersSelected: number[];
  volOffersObjectSelected: M.VolOffer[];
  keyword: string | undefined;
  searchByKeyword: boolean;
  showKeysForEdit: boolean;
  clearKeys: boolean;
}
const initialState: VolOffersState = {
  volOffers: [],
  volOffersSelected: [],
  volOffersObjectSelected: [],
  keyword: undefined,
  searchByKeyword: false,
  showKeysForEdit: false,
  clearKeys: false
};

export const prefix = 'volOffers';

const volOffersSlice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: () => initialState,
    clearForKeysStay: state => {
      state.volOffers = [];
      state.keyword = undefined;
      state.searchByKeyword = false;
      state.showKeysForEdit = false;
      state.clearKeys = false;
    },
    setVolOffers: (state, action: PayloadAction<M.VolOffer[]>) => {
      state.volOffers = action.payload;
      state.volOffersObjectSelected = [...state.volOffers].filter(volOffer =>
        state.volOffersSelected.some(key => key === volOffer.volOfferId)
      );
    },
    setShowKeysForEdit: (state, action: PayloadAction<boolean>) => {
      state.showKeysForEdit = action.payload;
    },
    setClearKeys: (state, action: PayloadAction<boolean>) => {
      state.clearKeys = action.payload;
    },
    setKeyword: (state, action: PayloadAction<string | undefined>) => {
      state.keyword = action.payload;
    },
    setSearchByKeyword: (state, action: PayloadAction<boolean>) => {
      state.searchByKeyword = action.payload;
    },
    setVolOffersSelected: (state, action: PayloadAction<number[]>) => {
      state.volOffersSelected = action.payload;
      state.volOffersObjectSelected = [...state.volOffers].filter(volOffer =>
        action.payload.some(key => key === volOffer.volOfferId)
      );
    },
    setSelectedKey: (state, action: PayloadAction<number>) => {
      const selectedKeys = [...state.volOffersSelected].filter(
        key => key !== action.payload
      );
      const updatedKeys = [...selectedKeys, action.payload];
      state.volOffersSelected = [...updatedKeys];
      state.volOffersObjectSelected = [...state.volOffers].filter(volOffer =>
        state.volOffersSelected.some(key => key === volOffer.volOfferId)
      );
    },
    removeSelectedKey: (state, action: PayloadAction<number>) => {
      state.volOffersSelected = [...state.volOffersSelected].filter(
        key => key !== action.payload
      );
      state.volOffersObjectSelected = [...state.volOffers].filter(volOffer =>
        state.volOffersSelected.some(key => key === volOffer.volOfferId)
      );
    },
    selectAllKeys: state => {
      state.volOffersSelected = [...state.volOffers].map(
        volOffer => volOffer.volOfferId
      );
      state.volOffersObjectSelected = [...state.volOffers];
    },
    removeAllSelectedKeys: state => {
      state.volOffersSelected = [];
      state.volOffersObjectSelected = [];
    }
  }
});

export const actions = volOffersSlice.actions;
export const reducer = volOffersSlice.reducer;

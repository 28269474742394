import { useEffect, useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, FormInstance, Select } from 'antd';
import { AxiosError } from 'axios';
import { globalContext } from '~/context/GlobalContext';
import GetCustomers from '~/@core/application/useCases/inputs/getCustomers';
import Customers from '~/@core/domain/model/customer/Customers';

interface Props {
  form: FormInstance<any>;
  siteId: number;
  lgCustomer?: number;
}

export default function CustomerSelect(props: Props) {
  const { form, siteId, lgCustomer } = props;
  const { handlePromiseUseCase, activeAlert } = useContext(globalContext);

  const { t } = useTranslation();
  const [customers, setCustomers] = useState<Customers[]>([]);

  useEffect(() => {
    if (siteId) {
      const getCustomers = new GetCustomers();

      handlePromiseUseCase<Customers[], AxiosError>(
        getCustomers.execute(),
        customerOut => {
          setCustomers(customerOut);
        },
        error => {
          activeAlert({
            message: JSON.stringify(
              error.response?.data
                ? error.response?.data
                : 'Houve um erro inesperado!'
            ),
            type: 'error',
            timeout: 5000
          });
        }
      );
    }
    onChangeSite();
    resetOnClear(['CustomerId']);
  }, [siteId]);

  const resetOnClear = useCallback(
    (fields: string[]) => {
      form.resetFields(fields);
    },
    [form]
  );

  const onChangeSite = useCallback(() => {
    setCustomers([]);
  }, []);

  return (
    <Col lg={lgCustomer ?? 6}>
      <Form.Item name="CustomerId" label={t('generals.customers')}>
        <Select
          placeholder={t('generals.selectPlaceholder')}
          allowClear
          optionFilterProp="children"
          filterOption
          showSearch
        >
          {customers?.map((customer: Customers) => (
            <Select.Option
              key={`customer-${customer.accountCode}`}
              value={customer.id}
            >
              {`${customer.accountCode} - ${customer.name}`}
            </Select.Option>

            //   title={`${customer.accountCode} - ${customer.name}`}
          ))}
        </Select>
      </Form.Item>
    </Col>
  );
}

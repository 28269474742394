// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestsList, RequestsObject } from '~/domain/model/RequestsList';
import { DefaultPagination } from '~/domain/model';

import * as AutoserviceCalls from './apiCalls';

type headerOffersType = {
  productId: number;
  worksheetLineId: number;
  productName: string;
  quantity: number;
  quantityReal: number;
  quantityFound: number;
};

export type SubTotalPrice = {
  value: number;
  id: string;
  quantity: number;
};

export type CheckoutNewProduct = {
  id: number;
  volumeType: string;
  offerId: number;
  quantityByVolume: number;
  packingId: number;
};

export type CheckoutPlus = {
  auxId: string;
  id: number;
  offerId: number;
  packingDescription: string;
  packingId: number;
  quantityByVolume: number;
  siteCode: string;
  siteId: number;
  siteName: string;
  totalPrice: number;
  unitPrice: number;
  quantityPackingTotal: number;
  quantity: number;
  volumeType: string;
};

type listOffersType = {
  id: number;
  offerId: number;
  quantity: number;
  siteCode: string;
  siteId: number;
  siteName: string;
  totalPrice: number;
  unitPrice: number;
};

type lineOffers = {
  offerId: number;
  quantity: number;
  packingId: number;
};

export interface AutoserviceRequestsState {
  requestsObject: RequestsObject | undefined;
  detailsRequest: RequestsList | undefined;
  detailsRequestLines: any | undefined;
  detatilsObject: any | undefined;
  detatilsObjectOrder: any | undefined;
  listNewProducts: any | undefined;
  listAddProducts: any | undefined;
  pagination: DefaultPagination;
  reloadPageData: number;
  offersList: any | undefined;
  headerOffers: headerOffersType;
  listOffers: Array<listOffersType> | undefined;
  customerIdReload: number | undefined;
  lineOffersList: Array<lineOffers> | undefined;
  productsList: any | undefined;
  newProduct: boolean;
  filterProduct: any | undefined;
  offersIds: Array<any>;
  chosenList: any | undefined;
  chosenListSelected: any | undefined;
  checkout: any | undefined;
  filterReload: any | undefined;
  addPlan: boolean;
  tabSelfService: string;
  historyData: any | undefined;
  customerIdForProducts: number | undefined;
  noStatusRefresh: boolean;
  noStatusRefreshCount: number;
  customerIdForReload: number;
  subTotalPriceList: SubTotalPrice[];
  submitOffers: boolean;
  idsProductsAccepted: string[];
  countConfirmation: number;
  checkoutNewProduct: CheckoutNewProduct[];
  checkoutPlus: CheckoutPlus[];
  cleanTrigger: boolean;
}

const initialState: AutoserviceRequestsState = {
  requestsObject: undefined,
  detailsRequest: undefined,
  detailsRequestLines: undefined,
  detatilsObject: undefined,
  detatilsObjectOrder: undefined,
  listNewProducts: undefined,
  listAddProducts: undefined,
  pagination: { PageSize: 10 } as DefaultPagination,
  reloadPageData: 0,
  offersList: undefined,
  headerOffers: {
    productId: 0,
    worksheetLineId: 0,
    productName: '',
    quantity: 0,
    quantityReal: 0,
    quantityFound: 0
  },
  listOffers: undefined,
  customerIdReload: 0,
  lineOffersList: undefined,
  productsList: undefined,
  newProduct: true,
  filterProduct: undefined,
  offersIds: [],
  chosenList: [],
  chosenListSelected: [],
  checkout: [],
  filterReload: undefined,
  addPlan: false,
  tabSelfService: '',
  historyData: undefined,
  customerIdForProducts: undefined,
  noStatusRefresh: false,
  noStatusRefreshCount: 0,
  customerIdForReload: 0,
  subTotalPriceList: [],
  submitOffers: false,
  idsProductsAccepted: [],
  countConfirmation: 0,
  checkoutNewProduct: [],
  checkoutPlus: [],
  cleanTrigger: false
};

export const prefix = 'autoserviceRequests';

const autoserviceRequestsSlice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setRequestsList: (state, action: PayloadAction<RequestsObject>) => {
      state.requestsObject = action.payload;
    },
    setCleanTrigger: (state, action: PayloadAction<boolean>) => {
      state.cleanTrigger = action.payload;
    },
    setDetailsRequest: (
      state,
      action: PayloadAction<RequestsList | undefined>
    ) => {
      state.detailsRequest = action.payload;
    },
    setDetailsRequestLines: (state, action: PayloadAction<any>) => {
      state.detailsRequestLines = action.payload;
    },
    setDetailsObjectMock: (state, action: PayloadAction<any>) => {
      state.detatilsObject = action.payload;
    },
    setDetailsObjectOrder: (state, action: PayloadAction<any>) => {
      state.detatilsObjectOrder = action.payload;
    },
    setLisNewProducts: (state, action: PayloadAction<any>) => {
      state.listNewProducts = action.payload;
    },
    setLisAddProducts: (state, action: PayloadAction<any>) => {
      state.listAddProducts = action.payload;
    },
    setPagination: (state, action: PayloadAction<DefaultPagination>) => {
      state.pagination = action.payload;
    },
    setReloadPageData: (state, action: PayloadAction<number>) => {
      state.reloadPageData = action.payload;
    },
    setOffersList: (state, action: PayloadAction<any>) => {
      state.offersList = action.payload;
    },
    setHeaderOffers: (state, action: PayloadAction<headerOffersType>) => {
      state.headerOffers = action.payload;
    },
    setListOffers: (
      state,
      action: PayloadAction<Array<listOffersType> | undefined>
    ) => {
      state.listOffers = action.payload;
    },
    setCustomerIdReload: (state, action: PayloadAction<number | undefined>) => {
      state.customerIdReload = action.payload;
    },
    setLineOffersList: (
      state,
      action: PayloadAction<Array<lineOffers> | undefined>
    ) => {
      state.lineOffersList = action.payload;
    },
    setNewProduct: (state, action: PayloadAction<boolean>) => {
      state.newProduct = action.payload;
    },
    setOffersIds: (state, action: PayloadAction<Array<number>>) => {
      state.offersIds = action.payload;
    },
    setChosenList: (state, action: PayloadAction<any>) => {
      state.chosenList = action.payload;
    },
    setCheckout: (state, action: PayloadAction<any | undefined>) => {
      state.checkout = action.payload;
    },
    setFilterReload: (state, action: PayloadAction<any | undefined>) => {
      state.filterReload = action.payload;
    },
    setAddPlan: (state, action: PayloadAction<boolean>) => {
      state.addPlan = action.payload;
    },
    setTabSelfservice: (state, action: PayloadAction<string>) => {
      state.tabSelfService = action.payload;
    },
    setCustomerIdForProducts: (state, action: PayloadAction<number>) => {
      state.customerIdForProducts = action.payload;
    },
    setNoStatusRefresh: (state, action: PayloadAction<boolean>) => {
      state.noStatusRefresh = action.payload;
      state.noStatusRefreshCount = 0;
    },
    setCustomerIdForReload: (state, action: PayloadAction<number>) => {
      state.customerIdForReload = action.payload;
    },
    setSubTotalList: (state, action: PayloadAction<SubTotalPrice>) => {
      const { payload } = action;
      const subTotalFind = state.subTotalPriceList?.filter(
        product => product.id === payload.id
      ).length;
      if (subTotalFind > 0) {
        const updatedSubTotalList: any | undefined =
          state.subTotalPriceList?.map((product: any) => {
            if (product.id === payload.id) {
              return payload;
            }
            return product;
          });
        state.subTotalPriceList = updatedSubTotalList;
      } else {
        const addSubTotalList = [...state.subTotalPriceList];
        addSubTotalList.push(payload);
        state.subTotalPriceList = addSubTotalList;
      }
    },
    setSClearSubTotal: state => {
      state.subTotalPriceList = [];
      state.chosenListSelected = [];
      state.productsList = undefined;
    },
    setSubmitOffers: (state, action: PayloadAction<boolean>) => {
      state.submitOffers = action.payload;
    },
    setIdsProductsAccepted: (state, action: PayloadAction<string>) => {
      const newIdsAccepted = [...state.idsProductsAccepted];
      if (newIdsAccepted.indexOf(action.payload) === -1) {
        newIdsAccepted.push(action.payload);
      }
      state.idsProductsAccepted = newIdsAccepted;
    },
    setCountConfirmation: state => {
      state.countConfirmation += 1;
    },
    setClearCountConfirmation: state => {
      state.countConfirmation = 0;
      state.idsProductsAccepted = [];
      state.checkoutNewProduct = [];
      state.checkoutPlus = [];
    },
    setCheckoutNewProduct: (
      state,
      action: PayloadAction<CheckoutNewProduct>
    ) => {
      const newArray = [...state.checkoutNewProduct];
      newArray.push(action.payload);
      state.checkoutNewProduct = newArray;
    },
    setCheckoutPlus: (state, action: PayloadAction<CheckoutPlus>) => {
      const newArray = [...state.checkoutPlus];
      newArray.push(action.payload);
      state.checkoutPlus = newArray;
    },
    setDeleteOffers: (state, action: PayloadAction<string>) => {
      const newArray = state.chosenList.filter(
        (offer: any) => offer.id !== action.payload
      );
      const newSubTotalList = state.subTotalPriceList.filter(
        (offer: any) => offer.id !== action.payload
      );
      state.chosenList = newArray;
      state.chosenListSelected = newArray;
      state.subTotalPriceList = newSubTotalList;
    },
    setChosenListSelected: (state, action: PayloadAction<any>) => {
      state.chosenListSelected = action.payload;
    },
    setListStepTwo: state => {
      state.chosenList = state.chosenListSelected;
    },
    setClearEverything: state => {
      state.countConfirmation = 0;
      state.idsProductsAccepted = [];
      state.checkoutNewProduct = [];
      state.checkoutPlus = [];
      state.chosenList = [];
      state.productsList = [];
      state.subTotalPriceList = [];
      state.offersIds = [];
      state.lineOffersList = [];
      state.cleanTrigger = true;
    }
  },
  extraReducers: builder => {
    builder.addCase(
      AutoserviceCalls.fetchRequestsList.fulfilled,
      (state, action: PayloadAction<RequestsObject>) => {
        state.requestsObject = action.payload;
      }
    );
    builder.addCase(
      AutoserviceCalls.fetchRequestsListStatus.fulfilled,
      (state, action: PayloadAction<RequestsObject>) => {
        if (
          state.noStatusRefresh &&
          state.noStatusRefreshCount >= 3 &&
          state.customerIdForReload === state.customerIdReload
        ) {
          state.requestsObject = action.payload;
        }
        state.noStatusRefresh = state.noStatusRefreshCount >= 3;
        state.noStatusRefreshCount += 1;
      }
    );
    builder.addCase(
      AutoserviceCalls.fetchDetailsRequest.fulfilled,
      (state, action: PayloadAction<RequestsList>) => {
        state.detailsRequest = action.payload;
      }
    );
    builder.addCase(
      AutoserviceCalls.fetchDetailsRequestLines.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.detailsRequestLines = action.payload;
      }
    );
    builder.addCase(
      AutoserviceCalls.cancelRequestDetails.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.reloadPageData = action.payload;
      }
    );
    builder.addCase(
      AutoserviceCalls.postRequestDetails.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.reloadPageData = action.payload?.reload;
        state.addPlan = action.payload?.addplan;
      }
    );
    builder.addCase(
      AutoserviceCalls.cancelRequestDetailsLines.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.reloadPageData = action.payload;
      }
    );
    builder.addCase(
      AutoserviceCalls.purchaseRequestDetails.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.reloadPageData = action.payload;
      }
    );
    builder.addCase(
      AutoserviceCalls.fetchRequestDetailsOffers.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.offersList = action.payload;
      }
    );
    builder.addCase(
      AutoserviceCalls.cancelRequestDetailsOffer.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.reloadPageData = action.payload;
      }
    );
    builder.addCase(
      AutoserviceCalls.postRequestDetailsOffers.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.reloadPageData = action.payload;
      }
    );
    builder.addCase(
      AutoserviceCalls.fetchProductsEcommerce.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.productsList = action.payload;
      }
    );
    builder.addCase(
      AutoserviceCalls.fetchFiltersProductsEcommerce.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.filterProduct = action.payload;
      }
    );
    builder.addCase(
      AutoserviceCalls.fetchProductsEcommerce2.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.productsList = action.payload;
      }
    );
    builder.addCase(
      AutoserviceCalls.fetchChosenListEcommerce.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.chosenList = action.payload;
      }
    );
    builder.addCase(
      AutoserviceCalls.postRequestNewProduct.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.reloadPageData = action.payload;
        state.productsList = undefined;
        state.chosenList = [];
        state.chosenListSelected = [];
      }
    );
    builder.addCase(
      AutoserviceCalls.fetchHistory.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.historyData = action.payload;
      }
    );
  }
});

export const actions = autoserviceRequestsSlice.actions;
export const reducer = autoserviceRequestsSlice.reducer;

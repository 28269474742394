import { AxiosError, AxiosResponse } from 'axios';
import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';
import FilterProductPreference from '~/@core/domain/filters/productPreference/FilterProductPreference';
import ProductCategory from '~/@core/domain/model/product/ProductCategory';
import ProductDetailed from '~/@core/domain/model/product/ProductDetailed';
import ProductGroup from '~/@core/domain/model/product/ProductGroup';
import ProductPreference from '~/@core/domain/model/product/ProductPreference';
import ProductPreferenceColor from '~/@core/domain/model/product/ProductPreferenceColor';
import ProductQuality from '~/@core/domain/model/product/ProductQuality';
import RecipientDetailed from '~/@core/domain/model/product/RecipientDetailed';
import api from '../config/index';

export const fetchProductCategories = (siteId: number) => {
  return new Promise<ProductCategory[]>((resolve, reject) => {
    api
      .get<void, AxiosResponse<ProductCategory[]>>(
        `/product-category/${siteId}`
      )
      .then((response: AxiosResponse<ProductCategory[]>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const fetchProductGroups = (siteId: number) => {
  return new Promise<ProductGroup[]>((resolve, reject) => {
    api
      .get<void, AxiosResponse<ProductGroup[]>>(`/product-group/site/${siteId}`)
      .then((response: AxiosResponse<ProductGroup[]>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const fetchProductsDetailed = (siteId: number) => {
  return new Promise<ProductDetailed[]>((resolve, reject) => {
    api
      .get<void, AxiosResponse<ProductDetailed[]>>(
        `/product/site/${siteId}/detailed`
      )
      .then((response: AxiosResponse<ProductDetailed[]>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const fetchRecipientsDetailed = (siteId: number) => {
  return new Promise<RecipientDetailed[]>((resolve, reject) => {
    api
      .get<void, AxiosResponse<RecipientDetailed[]>>(
        '/product/recipients/detailed',
        {
          params: { siteId }
        }
      )
      .then((response: AxiosResponse<RecipientDetailed[]>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const fetchProductPreferenceColor = () => {
  return new Promise<ProductPreferenceColor[]>((resolve, reject) => {
    api
      .get<void, AxiosResponse<ProductPreferenceColor[]>>('/product/color/all')
      .then((response: AxiosResponse<ProductPreferenceColor[]>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const fetchProductQualities = () => {
  return new Promise<ProductQuality[]>((resolve, reject) => {
    api
      .get<void, AxiosResponse<ProductQuality[]>>('/product/quality')
      .then((response: AxiosResponse<ProductQuality[]>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const fetchProductPreference = (params: FilterProductPreference) => {
  return new Promise<DefaultPaginated<ProductPreference[]>>(
    (resolve, reject) => {
      api
        .get<void, AxiosResponse<DefaultPaginated<ProductPreference[]>>>(
          '/product-preference',
          {
            params
          }
        )
        .then(
          (response: AxiosResponse<DefaultPaginated<ProductPreference[]>>) => {
            resolve(response.data);
          }
        )
        .catch((error: AxiosError) => {
          reject(error);
        });
    }
  );
};

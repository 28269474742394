import { createAsyncThunk } from '@reduxjs/toolkit';
import { LogParams } from '~/domain/model/Logs/Logs';
import * as logService from '~/services/producer/Logs';
import { generateErrorMessage } from '~/helpers/util/error';

export const setLog = createAsyncThunk(
  `log-user`,
  async (logParams: LogParams, { rejectWithValue }) => {
    try {
      const response = await logService.setLog(logParams);
      return response;
    } catch (err: unknown) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao definir log de acesso ao dashboard.'
      );
      return rejectWithValue(errorMessage);
    }
  }
);

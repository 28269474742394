// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '~/services/api';
import { isNullOrEmpty, stringClear } from '~/helpers/util';

import { searchSuccess, searchFailure } from './actions';
import { alertRequest } from '../alert/actions';

export function* onSearch({ payload }: any): any {
  const { id, keyword } = payload;

  try {
    let response = null;
    let data: any[] = [];

    // array de contas
    if (stringClear(id, ',').length > 0) {
      response = yield call(api.get, `/site/accounts?accounts=${id}`);
      data = response.data;
    } else {
      if (isNullOrEmpty(id) && isNullOrEmpty(keyword))
        response = yield call(api.get, `/site`);
      else if (!isNullOrEmpty(id))
        response = yield call(api.get, `/site/search?keyword=${id}`);
      else response = yield call(api.get, `/site/search?keyword=${keyword}`);

      data = response.data;
    }

    yield put(searchSuccess(data));
  } catch (err) {
    if (
      (!isNullOrEmpty(keyword) && keyword.length >= 3) ||
      (!isNullOrEmpty(id) && id.length >= 3)
    )
      yield put(alertRequest('Falha ao localizar dados do produtor', 'error'));
    yield put(searchFailure());
  }
}

export default all([takeLatest('@siteSimpleList/SEARCH_REQUEST', onSearch)]);

import * as API from '~/domain/api';

export default class OfferSettings {
  public readonly json: API.OfferSettings;
  public readonly siteId: number;
  public readonly siteCountingPatternId: number;
  public readonly countingPatternId: number;
  public readonly countingPatternDescription: string;
  public readonly compositionId: number;
  public readonly categoryPottedFlower: boolean;
  public readonly categoryOrnamentalPlant: boolean;
  public readonly minimunAmountId: number;
  public readonly quantity: number;
  public readonly abled: boolean;
  public readonly stockAdjustmentDefault: string;
  public readonly allowKlokHeard: boolean;
  public readonly allowVolHeard: boolean;
  public readonly dailyLimitIncludesKlok: boolean;
  public readonly hasCutItems: boolean;
  public readonly hasEnvasedOrOrnamentalItems: boolean;
  public readonly usesStockOnlyVol: boolean;
  public readonly allowStockOnlyVol: boolean;
  public readonly isVeilingEmployee: boolean;

  constructor(json: API.OfferSettings) {
    this.json = json;
    this.siteId = json.siteId ?? Number.NaN;
    this.siteCountingPatternId = json.countingPattern?.id ?? 0;
    this.countingPatternId = json.countingPattern?.countingPatternId ?? 0;
    this.countingPatternDescription =
      json.countingPattern?.countingPatternDescription ?? '';
    this.compositionId = json.composition?.id ?? 0;
    this.categoryPottedFlower = json.composition?.categoryPottedFlower ?? false;
    this.categoryOrnamentalPlant =
      json.composition?.categoryOrnamentalPlant ?? false;
    this.minimunAmountId = json.minimunAmount?.id ?? 0;
    this.quantity = json.minimunAmount?.quantity ?? 0;
    this.abled = json.minimunAmount?.abled ?? false;
    this.stockAdjustmentDefault = json.stockAdjustmentDefault ?? '';
    this.allowKlokHeard = json.allowKlokHeard;
    this.dailyLimitIncludesKlok = json.dailyLimitIncludesKlok;
    this.hasCutItems = json.hasCutItems;
    this.hasEnvasedOrOrnamentalItems = json.hasEnvasedOrOrnamentalItems;
    this.usesStockOnlyVol = json.usesStockOnlyVol;
    this.allowStockOnlyVol = json.allowStockOnlyVol;
    this.isVeilingEmployee = json.isVeilingEmployee;
    this.allowVolHeard = json.allowVolHeard;
  }
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BannerApproval } from '~/domain/model';

export type Status = 'cleared' | 'updated';

export type BannerApprovalState = {
  bannerApprovalList: BannerApproval[];
  status: Status;
};

export type Update = {
  bannerApprovalList: BannerApproval[];
};

export type FetchBannerApproval = {
  description?: string;
  url?: string;
  status?: string;
  bannerTypeId?: number;
  uploadDate?: string;
  pageNumber?: number;
  pageSize?: number;
};

export type ChangeStatus = {
  id: number;
  message?: string;
  status: string;
};

const prefix = '@bannerApproval';

export const initialState: BannerApprovalState = {
  bannerApprovalList: [],
  status: 'cleared'
};

const bannerApproval = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: state => {
      state.bannerApprovalList = initialState.bannerApprovalList;
      state.status = initialState.status;
    },
    update: (state, action: PayloadAction<Update>) => {
      state.bannerApprovalList = action.payload.bannerApprovalList;
    }
  }
});

export const actions = bannerApproval.actions;
export const reducer = bannerApproval.reducer;

const caseReducers = bannerApproval.caseReducers;
export const Types = Object.freeze({
  clear: `${prefix}/${caseReducers.clear.name}`,
  update: `${prefix}/${caseReducers.update.name}`
});

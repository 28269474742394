import produce from 'immer';
// import { ApplicationError } from '~/domain/api/errors';
import { CompetenceEvent } from '~/domain/model';
import { createCreator, createReducer, Reducer } from '~/helpers/util/reducers';
import { CreateInit } from '../../_core/competenceEvents/duck';

export type FetchStatus = 'idle' | 'fetching' | 'success' | 'failure';

export type CreateStatus = 'idle' | 'creating' | 'success' | 'failure';

export type CompetenceEventCreateState = {
  competenceEvent: CompetenceEvent;
  competenceId: string;
  fetchStatus: FetchStatus;
  createStatus: CreateStatus;
  errorMessage?: string;
  siteSearchTerm?: string;
};

export type Error = {
  errorMessage?: string;
};

export type SearchTerm = {
  siteSearchTerm: string;
};

export const initialState: CompetenceEventCreateState = {
  competenceEvent: new CompetenceEvent({}),
  fetchStatus: 'idle',
  createStatus: 'idle',
  competenceId: ''
};

const prefix = '@competenceEventCreate';

export const Types = Object.freeze({
  CLEAR: `${prefix}/CLEAR`,
  FETCH_INIT: `${prefix}/FETCH_INIT`,
  FETCH_INIT_SUCCESS: `${prefix}/FETCH_INIT_SUCCESS`,
  FETCH_INIT_FAILURE: `${prefix}/FETCH_INIT_FAILURE`,
  SEARCH_SITE: `${prefix}/SEARCH_SITE`,
  CREATE_INIT: `${prefix}/CREATE_INIT`,
  CREATE_SUCCESS: `${prefix}/CREATE_SUCCESS`,
  CREATE_FAILURE: `${prefix}/CREATE_FAILURE`
});

const clearReducer: Reducer<CompetenceEventCreateState, void> = state => {
  return produce(state, draft => {
    draft.competenceEvent = initialState.competenceEvent;
    draft.fetchStatus = initialState.fetchStatus;
    draft.createStatus = initialState.createStatus;
    draft.competenceId = initialState.competenceId;
  });
};

const fetchInitReducer: Reducer<CompetenceEventCreateState, void> = state => {
  return produce(state, draft => {
    draft.fetchStatus = 'fetching';
  });
};

const fetchInitSuccessReducer: Reducer<
  CompetenceEventCreateState,
  void
> = state => {
  return produce(state, draft => {
    draft.fetchStatus = 'success';
  });
};

const fetchInitFailureReducer: Reducer<
  CompetenceEventCreateState,
  void
> = state => {
  return produce(state, draft => {
    draft.fetchStatus = 'failure';
  });
};

const searchSiteReducer: Reducer<CompetenceEventCreateState, SearchTerm> = (
  state,
  action
) => {
  return produce(state, draft => {
    draft.siteSearchTerm = action.siteSearchTerm;
  });
};

const createInitReducer: Reducer<CompetenceEventCreateState, void> = state => {
  return produce(state, draft => {
    draft.createStatus = 'creating';
  });
};

const createSuccessReducer: Reducer<
  CompetenceEventCreateState,
  void
> = state => {
  return produce(state, draft => {
    draft.createStatus = 'success';
  });
};

const createFailureReducer: Reducer<CompetenceEventCreateState, Error> = (
  state,
  action
) => {
  return produce(state, draft => {
    draft.createStatus = 'failure';
    draft.errorMessage = action.errorMessage;
  });
};

export const actions = Object.freeze({
  clear: createCreator<void>(Types.CLEAR),
  fetchInit: createCreator<void>(Types.FETCH_INIT),
  fetchInitSuccess: createCreator<void>(Types.FETCH_INIT_SUCCESS),
  fetchInitFailure: createCreator<void>(Types.FETCH_INIT_FAILURE),
  searchSite: createCreator<SearchTerm>(Types.SEARCH_SITE),
  createInit: createCreator<CreateInit>(Types.CREATE_INIT),
  createSuccess: createCreator<void>(Types.CREATE_SUCCESS),
  createFailure: createCreator<Error>(Types.CREATE_FAILURE)
});

export const reducer = createReducer(initialState, {
  [Types.CLEAR]: clearReducer,
  [Types.FETCH_INIT]: fetchInitReducer,
  [Types.FETCH_INIT_SUCCESS]: fetchInitSuccessReducer,
  [Types.FETCH_INIT_FAILURE]: fetchInitFailureReducer,
  [Types.SEARCH_SITE]: searchSiteReducer,
  [Types.CREATE_INIT]: createInitReducer,
  [Types.CREATE_SUCCESS]: createSuccessReducer,
  [Types.CREATE_FAILURE]: createFailureReducer
});

import { WeeksState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.core.weeks as WeeksState;
};

export const getById = createSelector([getRoot], state => state.byId);

export const getWeeks = createSelector([getRoot], state =>
  Array.from(state.byId.values())
);

import styled from 'styled-components';
import {
  IBorderStyledProps,
  IColStyled,
  IColumnItemProps,
  IColumnProps,
  INumberBoxProps
} from './types';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  display: block;
  position: relative;

  @media print {
    @page {
      size: A5 landscape;
      margin: 0;
    }
    html,
    body {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      overflow: hidden;
    }
    .print-container {
      width: 100%;
      height: 100%;
      transform: scale(1);
      transform-origin: top left;
    }
  }
`;

export const ExternalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: block;
`;

export const ColumnItem = styled.div<IColumnItemProps>`
  width: ${props => props.width};
  height: ${props => props.height ?? '100%'};
`;

export const BorderStyled = styled.div<IBorderStyledProps>`
  border: 0.1px solid #191919;
  border-radius: 0.2rem;
  width: ${props => props.width ?? '100%'};
  height: ${props => props.height ?? '100%'};
  padding: ${props => props.padding ?? ''};
`;

export const BorderStyledSquare = styled.div<IBorderStyledProps>`
  border: 0.1px solid #191919;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${props => props.width ?? '100%'};
  height: ${props => props.height ?? '100%'};
  padding: ${props => props.padding ?? ''};
`;

export const Column = styled.div<IColumnProps>`
  display: flex;
  width: 100%;
  height: ${props => props.height ?? '44px'};
`;

export const TitleGFPe = styled.h2`
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 0.2rem;
`;

export const Text = styled.p`
  font-size: 0.475rem;
  line-height: 0.2rem;
  font-weight: 700;
  padding: 0px 3px;
`;

export const TextBold = styled.span`
  font-weight: 700;
  line-height: 0.15rem;
`;

export const Identifier = styled.h2`
  font-size: 0.4rem;
  font-weight: 700;
`;

export const SubIdentifier = styled.p`
  font-size: 80%;
  padding: 0px 3px;
`;

export const Address = styled.p`
  font-size: 0.46rem;
  line-height: 0.15rem;
`;

export const MarginSpan = styled.span`
  margin-right: 1.625rem;
`;

export const BarcodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const BarcodeNumber = styled.p`
  margin-top: auto;
  font-size: 0.45rem;
  text-align: center;
`;

export const ModalBody = styled.div`
  padding: 10px;
`;

export const ColStyled = styled.div<IColStyled>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: ${props => props.flex};
`;

export const ColStyledStart = styled.div<IColStyled>`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: ${props => props.flex};
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-right: 0.9rem;
  padding-left: 0.9rem;
`;

export const DateText = styled.span`
  font-size: 0.62rem;
  font-weight: bold;
  line-height: 0.1rem;
`;

export const IdentifierDate = styled.h2`
  font-size: 0.4rem;
  font-weight: 700;
`;

export const ContainerFlex = styled.div`
  display: flex;
  align-items: center;
  justfy-content: center;
`;

export const VerticalDividerLeft = styled.div`
  position: absolute;
  bottom: 76%;
  width: 0.3px;
  background-color: #000;
  height: 30%;
  left: 130%;
`;

export const VerticalDividerRight = styled.div`
  position: absolute;
  bottom: 76%;
  width: 0.5px;
  background-color: #000;
  height: 30%;
  left: 130%;
`;

export const IdentifierLots = styled.h2`
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  text-align: center;
  font-size: 0.4rem;
  font-weight: 700;
`;

export const Divider = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  border-top: 1px solid #191919;
`;

export const VerticalDivider = styled.div`
  width: 1px;
  height: 70%;
  background-color: #191919;
  position: absolute;
  left: 50%;
  top: 68%;
  transform: translateY(-50%);
`;

export const VerticalDividerFooterRight = styled.div`
  width: 1px;
  height: 4%;
  background-color: #191919;
  position: absolute;
  left: 48%;
  bottom: 8.8%;
  transform: translateY(-50%);
  z-index: 1;
`;

export const VerticalDividerFooterLeft = styled.div`
  width: 1px;
  height: 4%;
  background-color: #191919;
  position: absolute;
  left: 46%;
  bottom: 8.8%;
  transform: translateY(-50%);
  z-index: 1;
`;

export const HorizontalDivider = styled.div`
  width: 102%;
  height: 1px;
  background-color: #000;
  position: absolute;
  left: -1%;
  top: 33%;
  transform: translateY(-50%);
`;

export const NumberBoxContainer = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  width: 100%;
  height: 100%;
`;

export const NumberBox = styled.div<INumberBoxProps>`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 0.35rem;
`;

export const CancelledMessage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 48px;
  color: rgba(0, 0, 0, 0.1);
  font-weight: bold;
  text-align: center;
  z-index: 999;
  width: 100%;
  pointer-events: none;
  user-select: none;

  @media print {
    page-break-inside: avoid;
    page-break-before: auto;
    page-break-after: auto;
  }
`;

export const PrintContainer = styled.div`
  display: none;

  @media print {
    display: block;
  }
`;

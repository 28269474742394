import { takeLatest, call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { DeliveryPatternCreateAction, Types } from './duck';
import * as DeliveryPatternService from '../../_core/deliveryPattern/service';
import { actions as actionsList } from '../deliveryPattern-list';
import { actions as actionsLoading } from '../loading';
import { actions } from '.';
import { alertRequest } from '../../alert/actions';
import { generateErrorMessage } from '~/helpers/util/error';

export function* createSaga(
  action: PayloadAction<DeliveryPatternCreateAction>
): any {
  try {
    yield put(
      actionsLoading.setLoading({
        status: 'fetching'
      })
    );
    yield call(DeliveryPatternService.create, action.payload);
    yield put(
      actionsLoading.setLoading({
        status: 'idle'
      })
    );
    yield put(alertRequest('Alteração realizada com Sucesso', 'success'));
    yield put(actionsList.setSubmitForm(true));
    yield put(actions.setCloseModal(true));
  } catch (err: any) {
    yield put(
      actionsLoading.setLoading({
        status: 'idle'
      })
    );
    yield put(
      alertRequest(
        generateErrorMessage(err.response.data.errors.messages[0]),
        'error'
      )
    );
  }
}

export default [takeLatest(Types.CREATE, createSaga)];

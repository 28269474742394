/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Modal,
  Row,
  Select
} from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import moment, { Moment } from 'moment';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import CreateGfp from '~/@core/application/useCases/gfp/createGfp';
import FormFilterCreateGfpModel, {
  MapFormToFilter
} from '~/@core/domain/ui/forms/FormFilterCreateGfp';
import {
  ModalBody,
  ModalDefault,
  ModalFooter,
  ModalHeader
} from '~/components/Globals';
import ShippingCompanySelect from '~/components/atoms/Inputs/ShippingCompanySelect';
import { GFPContext } from '~/context/GFPContext';
import { globalContext } from '~/context/GlobalContext';
import {
  RemoveLocation,
  RemoveLocationMap
} from '~/domain/enum/removeLocation.enum';
import { brFormat } from '~/helpers/util';
import useGfpStore from '~/zustand/gfp/gfp-service-state';
import TableAssembleGFP from '../../Tables/TableAssembleGFP';
import { FiltersTableRow } from './style';

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  listOrdersForm: FormInstance;
}

export default function ModalAssembleGFP(props: Props) {
  const { t } = useTranslation();
  const { isModalVisible, setIsModalVisible } = props;
  const { siteIdSelected, handlePromiseUseCase, activeAlert } =
    useContext(globalContext);
  const {
    order,
    setOrderSelected,
    orderSelected,
    setOrderTempSelected,
    isMultiple,
    isPageLoading,
    setIsPageLoading,
    setIsMultiAddClicked
  } = useContext(GFPContext);

  // #FIXME
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
  const [activeTabKey, setActiveTabKey] = useState<string>('1');
  // #FIXME
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
  const [modal, context] = Modal.useModal();
  const [form] = Form.useForm();
  const { setStocks, setStocksSelected } = useGfpStore();
  const [showMultipleInput, setShowMultipleInput] = useState(false);

  useEffect(() => {
    form.setFieldValue('gfpItems', orderSelected);
  }, [form, orderSelected]);

  const handleCancel = useCallback(() => {
    props.listOrdersForm.submit();
    setIsModalVisible(false);
    setActiveTabKey('1');
    setOrderSelected([]);
    setStocks([]);
    setStocksSelected([]);
    setOrderTempSelected([]);
    setIsMultiAddClicked('');
    form.resetFields([
      'emissionDate',
      'outDate',
      'commercialDate',
      'pickupLocation',
      'partitionQuantity',
      'multiple',
      'transporterId',
      'observation',
      'gfpItems',
      'quantityPacking',
      'quantityQeRest'
    ]);
  }, [
    form,
    props.listOrdersForm,
    setIsModalVisible,
    setIsMultiAddClicked,
    setOrderSelected,
    setOrderTempSelected,
    setStocks,
    setStocksSelected
  ]);

  const currentDate = moment(new Date());

  function disabledDate(current: Moment) {
    return current && current < moment().startOf('day');
  }

  let totalPacking = 0;
  totalPacking = orderSelected.reduce(
    (acc, elem) => acc + elem.quantityPacking,
    0
  );

  const onSubmitForm = useCallback(
    (formValues: FormFilterCreateGfpModel) => {
      const filterGfps = MapFormToFilter.toFilter(formValues, siteIdSelected);
      const createGfp = new CreateGfp();

      if (!isPageLoading) setIsPageLoading(true);
      handlePromiseUseCase<AxiosResponse<void>, AxiosError>(
        createGfp.execute(filterGfps),
        () => {
          setIsPageLoading(true);
          activeAlert({
            message: 'GFP criada com sucesso!',
            type: 'success',
            timeout: 6000
          });
          handleCancel();
          props.listOrdersForm.submit();
        },
        error => {
          setIsPageLoading(false);
          activeAlert({
            message: error?.response?.data
              ? error?.response?.data
              : 'Houve um erro inesperado',
            type: 'error',
            timeout: 5000
          });
        }
      );
    },
    [
      activeAlert,
      handleCancel,
      handlePromiseUseCase,
      isPageLoading,
      setIsPageLoading,
      siteIdSelected
    ]
  );

  useEffect(() => {
    setStocksSelected([]);
    setStocks([]);
  }, [isModalVisible, setStocks, setStocksSelected]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const {
      key,
      currentTarget: { value, selectionStart }
    } = e;

    if (
      !/^[0-9]$/.test(key) &&
      key !== 'Backspace' &&
      key !== 'Delete' &&
      key !== 'ArrowLeft' &&
      key !== 'ArrowRight' &&
      key !== 'Tab'
    ) {
      e.preventDefault();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteData = e.clipboardData.getData('Text');
    if (!/^[0-9]*$/.test(pasteData)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    const hasMultipleValue = orderSelected.some(orders => orders.multi > 0);
    setShowMultipleInput(hasMultipleValue);
  }, [orderSelected]);

  const handleShowObservationGFP = () => {
    const orderNumbers = orderSelected.map(orders => orders.orderNumber);
    const hasDifferentOrderNumbers = orderNumbers.some((orderNumber, index) => {
      return index > 0 && orderNumber !== orderNumbers[0];
    });

    return !hasDifferentOrderNumbers;
  };

  const mapPickupLocation = (location: string): number | undefined => {
    return RemoveLocation[location as keyof typeof RemoveLocationMap];
  };

  const initialPickupLocation = orderSelected[0]?.pickupLocation
    ? mapPickupLocation(orderSelected[0]?.pickupLocation)
    : undefined;

  useEffect(() => {
    if (isModalVisible) {
      form.setFieldsValue({
        emissionDate: currentDate,
        outDate: currentDate,
        pickupLocation: initialPickupLocation
      });
    }
  }, [isModalVisible, initialPickupLocation, form, currentDate]);

  return (
    <ModalDefault
      width={1300}
      open={isModalVisible}
      onCancel={handleCancel}
      destroyOnClose
    >
      <ModalHeader>
        <span>{t('gfp.modal.addGfp.create')}</span>
      </ModalHeader>
      {context}
      <ModalBody>
        <Form
          form={form}
          onFinish={onSubmitForm}
          initialValues={{
            emissionDate: currentDate,
            outDate: currentDate,
            pickupLocation: initialPickupLocation
          }}
        >
          <FiltersTableRow gutter={10}>
            <Col flex={1}>
              <Form.Item
                label={t('gfp.modal.addGfp.form.emissionDate')}
                name="emissionDate"
              >
                <DatePicker
                  picker="date"
                  placeholder={t('generals.selectPlaceholder')}
                  format={brFormat}
                  style={{ width: '100%' }}
                  key={1}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col flex={1}>
              <Form.Item
                label={t('gfp.modal.addGfp.form.outDate')}
                name="outDate"
                rules={[
                  { required: true, message: 'Insira uma data de saída' }
                ]}
              >
                <DatePicker
                  picker="date"
                  placeholder={t('generals.selectPlaceholder')}
                  format={brFormat}
                  style={{ width: '100%' }}
                  key={99}
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Col>

            <Col flex={1}>
              <Form.Item
                label={t('gfp.modal.addGfp.form.local')}
                name="pickupLocation"
              >
                <Select disabled>
                  {Object.entries(RemoveLocationMap).map(([key, value]) => (
                    <Select.Option
                      key={RemoveLocation[key as keyof typeof RemoveLocation]}
                      value={RemoveLocation[key as keyof typeof RemoveLocation]}
                    >
                      {value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </FiltersTableRow>
          <FiltersTableRow style={{ marginTop: 0 }} gutter={10}>
            <Col flex={1}>
              <Form.Item
                label={t('gfp.modal.addGfp.form.divider')}
                name="partitionQuantity"
                rules={[{ required: true, message: 'Insira uma divisória' }]}
              >
                <InputNumber
                  key={3}
                  min={0}
                  onPaste={handlePaste}
                  onKeyDown={handleKeyDown}
                  parser={value =>
                    value ? parseInt(value.replace(/[^0-9]/g, ''), 10) : 1
                  }
                />
              </Form.Item>
            </Col>
            {showMultipleInput && (
              <Form.Item label={t('generals.filters.mult')} name="multiple">
                <InputNumber
                  key={4}
                  min={1}
                  defaultValue={
                    orderSelected.length > 0 ? orderSelected[0].multi : 0
                  }
                  width={100}
                  onPaste={handlePaste}
                  onKeyDown={handleKeyDown}
                  parser={value =>
                    value ? parseInt(value.replace(/[^0-9]/g, ''), 10) : 1
                  }
                />
              </Form.Item>
            )}
            <Col flex={1}>
              <Form.Item
                name="shippingCompanyId"
                label={t('generals.filters.shippingCompany')}
                rules={[
                  { required: true, message: 'Insira uma transportadora' }
                ]}
              >
                <ShippingCompanySelect
                  form={form}
                  siteId={siteIdSelected}
                  formFieldName="shippingCompanyId"
                />
              </Form.Item>
            </Col>
            <Form.Item name="gfpItems" />
          </FiltersTableRow>
          <Divider orientation="left">Pedidos</Divider>
          <TableAssembleGFP />
          {!isMultiple || handleShowObservationGFP() ? (
            <Row style={{ marginTop: '20px' }}>
              <Col flex={1}>
                <Form.Item name="observation" label="Observação GFP:">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          ) : null}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Row justify="space-between" gutter={[0, 24]} align="middle">
          <Col>
            <p style={{ color: '#8c8c8c' }}>
              Total de embalagens:
              <span style={{ fontSize: 24, fontWeight: 700, color: 'black' }}>
                {` ${totalPacking}`}
              </span>
            </p>
          </Col>
          <Col style={{ display: 'flex', gap: '10px' }}>
            <Button
              type="default"
              style={{ width: '100%' }}
              onClick={() => handleCancel()}
            >
              Voltar
            </Button>
            <Button
              type="primary"
              style={{ width: '100%' }}
              onClick={() => form.submit()}
            >
              Gerar GFPs
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </ModalDefault>
  );
}

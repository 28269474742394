import { Dispatch, SetStateAction, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Container, Message } from './styles';

interface Props {
  type: 'success' | 'info' | 'warning' | 'error' | undefined;
  message: string;
  timeout: number;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}
export default function Alert({
  type,
  message,
  timeout,
  show,
  setShow
}: Props) {
  let timeToClose = timeout;

  if (!timeToClose || Number(timeToClose) <= 0) timeToClose = 5000;

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShow(false);
      }, timeout);
    }
  }, [setShow, show, timeout]);
  return (
    <>
      <Container
        className={show ? 'active' : ''}
        onClick={() => setShow(false)}
      >
        <Message message={ReactHtmlParser(message)} type={type} showIcon />
      </Container>
    </>
  );
}

import { AxiosResponse } from 'axios';
import apiProducer from '../api';

import { APIError } from '~/domain/api/errors';
import { endpoints } from '~/constants/endpoints';
import Customer from '~/domain/model/Customer/Customer';

export const fetchCustomers = async () => {
  try {
    const URL = '/customers/customers-vd';
    const response: AxiosResponse = await apiProducer.get(URL);
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};
export const findCustomers = async () => {
  const response = await apiProducer.get<void, AxiosResponse<Customer[]>>(
    endpoints.customers.base
  );
  return response.data;
};

export const findCustomersForVD = async () => {
  const response = await apiProducer.get<void, AxiosResponse<Customer[]>>(
    endpoints.customers.baseForVD
  );
  return response.data;
};

export const fetchCustomersByNameAndSegment = async (
  name?: string,
  segmentId?: number
) => {
  try {
    let URL = '/customers';

    let validateIsFirstParam = true;

    if (name) {
      URL += `${getConcatenatedCharacterQueryParams(
        validateIsFirstParam
      )}data.name=${name}`;
      validateIsFirstParam = false;
    }
    if (segmentId) {
      URL += `${getConcatenatedCharacterQueryParams(
        validateIsFirstParam
      )}data.segmentId=${segmentId}`;
      validateIsFirstParam = false;
    }

    const response: AxiosResponse = await apiProducer.get(URL);
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const fetchCustomersByGroupId = async (groupId?: number) => {
  try {
    let URL = '/customers';

    let validateIsFirstParam = true;

    if (groupId) {
      URL += `${getConcatenatedCharacterQueryParams(
        validateIsFirstParam
      )}data.groupId=${groupId}`;
      validateIsFirstParam = false;
    }
    const response: AxiosResponse = await apiProducer.get(URL);
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const fetchCustomersByParam = async (
  groupId?: number,
  voucherId?: number,
  name?: string,
  segmentId?: number
) => {
  try {
    let URL = '/customers/customers-vd';

    let validateIsFirstParam = true;

    if (groupId) {
      URL += `${getConcatenatedCharacterQueryParams(
        validateIsFirstParam
      )}data.groupId=${groupId}`;
      validateIsFirstParam = false;
    }
    if (voucherId) {
      URL += `${getConcatenatedCharacterQueryParams(
        validateIsFirstParam
      )}data.voucherId=${voucherId}`;
      validateIsFirstParam = false;
    }
    if (name) {
      URL += `${getConcatenatedCharacterQueryParams(
        validateIsFirstParam
      )}data.name=${name}`;
      validateIsFirstParam = false;
    }
    if (segmentId) {
      URL += `${getConcatenatedCharacterQueryParams(
        validateIsFirstParam
      )}data.segmentId=${segmentId}`;
      validateIsFirstParam = false;
    }

    const response: AxiosResponse = await apiProducer.get(URL);
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const fetchCustomersSegment = async () => {
  try {
    const URL = '/customers/segment';
    const response: AxiosResponse = await apiProducer.get(URL);
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

function getConcatenatedCharacterQueryParams(isFirstParam: boolean): string {
  if (isFirstParam) {
    return '?';
  }
  return '&';
}

export const fetchCustomerGroups = async (siteId?: number) => {
  try {
    const URL = `/customers/group?siteId=${siteId}`;
    const response: AxiosResponse = await apiProducer.get(URL);
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

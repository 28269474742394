import IUseCase from '~/@core/application/IUseCase';
import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';
import FilterData from '~/@core/domain/filters/rfidReports/FilterData';
import Area from '~/@core/domain/model/rfidReports/Area';
import { ReceiptService } from '~/@core/infrastructure/api/services/rfidReports';

export default class GetAreas
  implements IUseCase<FilterData, Promise<DefaultPaginated<Area[]>>>
{
  execute(filter: FilterData): Promise<DefaultPaginated<Area[]>> {
    return ReceiptService.fetchAreas(filter);
  }
}

import { useDispatch, useSelector } from 'react-redux';
import { Row, Tabs } from 'antd';
import { Suspense } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import SideBar from '~/components/Menu';
import Alert from '~/components/Alert';
import Announcement from '~/pages/Announcement';

import { changeTab, deleteTab } from '~/store/modules/tabs/actions';

import {
  Container,
  Content,
  TabContainer,
  TabGuideStyle,
  TabsStyle
} from './styles';

import Loading from '~/components/Loading';
import { closeButtonStyle } from '~/components/Menu/styles';

export default function MasterPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const tabs = useSelector((store: any) => store.tabs);
  const { TabPane } = Tabs;

  const onChange = (activeKey: any) => {
    dispatch(changeTab(activeKey));
  };

  const onClose = (
    key: string,
    onCloseAction: any,
    cantClose: boolean | undefined
  ) => {
    if (onCloseAction) {
      onCloseAction();
    }
    if (cantClose) {
      return;
    }
    dispatch(deleteTab(key));
  };

  const tabNameComponent = (
    tabName: any,
    key: any,
    onCloseAction?: any,
    cantClose?: boolean
  ) => {
    return (
      <Row justify="space-between">
        <span>{t(tabName)}</span>
        <CloseOutlined
          style={closeButtonStyle}
          onClick={() => onClose(key, onCloseAction, cantClose)}
        />
      </Row>
    );
  };

  return (
    <Container>
      <SideBar />

      <Content>
        <TabContainer
          tabBarStyle={TabGuideStyle}
          className={`tab-guides ${
            tabs.tabs.length <= 0 ? 'no-tabs' : 'tab-padding'
          }`}
          onChange={onChange}
          activeKey={`${tabs.selectedTab}`}
        >
          {tabs.tabs.map((component: any, index: any) => (
            <TabPane
              style={TabsStyle}
              tab={tabNameComponent(
                component.name,
                component.key,
                component.onCloseAction,
                component.cantClose
              )}
              key={String(index)} // Convert key to string
            >
              <Suspense fallback={<Loading loading />} key={String(index)}>
                {' '}
                {/* Add key prop to Suspense */}
                {component.component()} {/* Render the component directly */}
              </Suspense>
            </TabPane>
          ))}
        </TabContainer>
        <Announcement />
      </Content>
      <Loading />
      <Alert timeout={5000} />
    </Container>
  );
}

import * as API from '~/domain/api';

export default class ProductCategory {
  public readonly json: API.ProductCategory;
  public readonly id: number;
  public readonly description: string;
  public readonly code?: string;

  constructor(json: API.ProductCategory) {
    this.json = json;
    this.id = this.getId();
    this.description = this.getDescription();
    this.code = this.getCode();
  }

  private getId(): number {
    return this.json.id ?? Number.NaN;
  }

  private getDescription(): string {
    return this.json.description ?? '';
  }

  private getCode(): string {
    return this.json.code ?? '';
  }
}

import produce from 'immer';
import { createCreator, createReducer, Reducer } from '~/helpers/util/reducer';
import { UnavailableDate } from '~/domain/model';

export type FetchStatus = 'idle' | 'fetching' | 'success' | 'failure';

export type UnavailableDatesState = {
  fetchStatus: FetchStatus;
  fetchMessage: string;
  unavailableDates: UnavailableDate[];
  submit: boolean;
  pagination: Pagination;
};

export type UnavailableDateCreate = {
  siteId: number;
  description: string;
  unavailableDate: Date;
};
export type UnavailableDateUpdate = {
  id: number;
  siteId: number;
  description: string;
  unavailableDate: Date;
};
export type Pagination = {
  pageSize: number | undefined;
  pageNumber: number | undefined;
  totalRows?: number | undefined;
};

const prefix = '@unavailableDates';

export const Types = Object.freeze({
  SEARCH: `${prefix}/SEARCH`,
  SEARCH_SUCCESS: `${prefix}/SEARCH_SUCCESS`,
  SEARCH_FAILURE: `${prefix}/SEARCH_FAILURE`,
  CREATE: `${prefix}/CREATE`,
  CREATE_SUCCESS: `${prefix}/CREATE_SUCCESS`,
  CREATE_FAILURE: `${prefix}/CREATE_FAILURE`,
  UPDATE: `${prefix}/UPDATE`,
  UPDATE_SUCCESS: `${prefix}/UPDATE_SUCCESS`,
  UPDATE_FAILURE: `${prefix}/UPDATE_FAILURE`,
  DELETE: `${prefix}/DELETE`,
  DELETE_SUCCESS: `${prefix}/DELETE_SUCCESS`,
  DELETE_FAILURE: `${prefix}/DELETE_FAILURE`,
  RESET_SUBMIT: `${prefix}/RESET_SUBMIT`,
  RESET_DATES: `${prefix}/RESET_DATES`,
  RESET_FETCH: `${prefix}/RESET_FETCH`,
  SET_PAGINATION: `${prefix}/SET_PAGINATION`
});

export const initialState: UnavailableDatesState = {
  fetchStatus: 'idle',
  fetchMessage: '',
  unavailableDates: [] as UnavailableDate[],
  submit: false,
  pagination: {} as Pagination
};

const searchReducer: Reducer<UnavailableDatesState, number> = state => {
  return produce(state, draft => {
    draft.fetchStatus = 'fetching';
  });
};

const searchSuccessReducer: Reducer<
  UnavailableDatesState,
  UnavailableDate[]
> = (state, _action) => {
  return produce(state, draft => {
    draft.fetchStatus = 'idle';
    draft.unavailableDates = _action;
  });
};
const searchFailureReducer: Reducer<UnavailableDatesState, string> = (
  state,
  _action
) => {
  return produce(state, draft => {
    draft.fetchStatus = 'failure';
    draft.fetchMessage = _action;
  });
};
const createDateReducer: Reducer<UnavailableDatesState, void> = state => {
  return produce(state, draft => {
    draft.fetchStatus = 'fetching';
  });
};

const createSuccessReducer: Reducer<UnavailableDatesState, string> = (
  state,
  _action
) => {
  return produce(state, draft => {
    draft.fetchStatus = 'success';
    draft.submit = true;
    draft.fetchMessage = _action;
  });
};
const createFailureReducer: Reducer<UnavailableDatesState, string> = (
  state,
  _action
) => {
  return produce(state, draft => {
    draft.fetchStatus = 'failure';
    draft.fetchMessage = _action;
  });
};
const updateDateReducer: Reducer<UnavailableDatesState, void> = state => {
  return produce(state, draft => {
    draft.fetchStatus = 'fetching';
  });
};

const updateSuccessReducer: Reducer<UnavailableDatesState, string> = (
  state,
  _action
) => {
  return produce(state, draft => {
    draft.fetchStatus = 'success';
    draft.submit = true;
    draft.fetchMessage = _action;
  });
};
const updateFailureReducer: Reducer<UnavailableDatesState, string> = (
  state,
  _action
) => {
  return produce(state, draft => {
    draft.fetchStatus = 'failure';
    draft.fetchMessage = _action;
  });
};
const deleteDateReducer: Reducer<UnavailableDatesState, void> = state => {
  return produce(state, draft => {
    draft.fetchStatus = 'fetching';
  });
};

const deleteSuccessReducer: Reducer<UnavailableDatesState, string> = (
  state,
  _action
) => {
  return produce(state, draft => {
    draft.fetchStatus = 'success';
    draft.submit = true;
    draft.fetchMessage = _action;
  });
};
const deleteFailureReducer: Reducer<UnavailableDatesState, string> = (
  state,
  _action
) => {
  return produce(state, draft => {
    draft.fetchStatus = 'failure';
    draft.fetchMessage = _action;
  });
};
const resetSubmitReducer: Reducer<UnavailableDatesState, void> = (
  state,
  _action
) => {
  return produce(state, draft => {
    draft.submit = false;
  });
};
const resetDatesReducer: Reducer<UnavailableDatesState, void> = state => {
  return produce(state, draft => {
    draft.unavailableDates = [];
  });
};
const resetFetchReducer: Reducer<UnavailableDatesState, void> = state => {
  return produce(state, draft => {
    draft.fetchMessage = '';
    draft.fetchStatus = 'idle';
  });
};
const setPaginationReducer: Reducer<UnavailableDatesState, Pagination> = (
  state,
  _action
) => {
  return produce(state, draft => {
    draft.pagination = _action;
  });
};

export const actions = Object.freeze({
  search: createCreator<number>(Types.SEARCH),
  searchSuccess: createCreator<UnavailableDate[]>(Types.SEARCH_SUCCESS),
  searchFailure: createCreator<string>(Types.SEARCH_FAILURE),
  create: createCreator<UnavailableDateCreate>(Types.CREATE),
  createSuccess: createCreator<string>(Types.CREATE_SUCCESS),
  createFailure: createCreator<string>(Types.CREATE_FAILURE),
  update: createCreator<UnavailableDateUpdate>(Types.UPDATE),
  updateSuccess: createCreator<string>(Types.UPDATE_SUCCESS),
  updateFailure: createCreator<string>(Types.UPDATE_FAILURE),
  delete: createCreator<number>(Types.DELETE),
  deleteSuccess: createCreator<string>(Types.DELETE_SUCCESS),
  deleteFailure: createCreator<string>(Types.DELETE_FAILURE),
  resetSubmit: createCreator<void>(Types.RESET_SUBMIT),
  resetDates: createCreator<void>(Types.RESET_DATES),
  resetFetch: createCreator<void>(Types.RESET_FETCH),
  setPagination: createCreator<Pagination>(Types.SET_PAGINATION)
});

export const reducer = createReducer(initialState, {
  [Types.SEARCH]: searchReducer,
  [Types.SEARCH_SUCCESS]: searchSuccessReducer,
  [Types.SEARCH_FAILURE]: searchFailureReducer,
  [Types.CREATE]: createDateReducer,
  [Types.CREATE_SUCCESS]: createSuccessReducer,
  [Types.CREATE_FAILURE]: createFailureReducer,
  [Types.UPDATE]: updateDateReducer,
  [Types.UPDATE_SUCCESS]: updateSuccessReducer,
  [Types.UPDATE_FAILURE]: updateFailureReducer,
  [Types.DELETE]: deleteDateReducer,
  [Types.DELETE_SUCCESS]: deleteSuccessReducer,
  [Types.DELETE_FAILURE]: deleteFailureReducer,
  [Types.RESET_SUBMIT]: resetSubmitReducer,
  [Types.RESET_DATES]: resetDatesReducer,
  [Types.RESET_FETCH]: resetFetchReducer,
  [Types.SET_PAGINATION]: setPaginationReducer
});

import { DefaultRootState } from 'react-redux';
import { LkpOffer } from '~/domain/model';
import { LkpOffersState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return (state?.core?.lkpOffers as LkpOffersState) ?? [];
};

export const getLkpOffers = createSelector([getRoot], state => {
  return state.byId;
});

export const getListToEdit = createSelector([getRoot], state => {
  return state.listToEdit;
});

export const getIsCleared = createSelector(
  [getRoot],
  state => state.status === 'cleared'
);

export const getDisableSave = createSelector(
  [getRoot],
  state => state.disabledSave
);

const selectOfferById = (state: DefaultRootState, id: number) =>
  getRoot(state).byId.find((offer: LkpOffer) => offer.id === id);

export const getByLkpOfferId = createSelector(
  [selectOfferById],
  offer => offer
);

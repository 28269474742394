import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefaultPagination } from '~/domain/model';
import {
  fetchBlackoutDates,
  updateBlackoutDates,
  createBlackoutDates,
  createSpecialDate
} from './apiCalls';

type blackoutDatesType = {
  id: number;
  creationDate: any;
  description: string;
  status: boolean;
  unavailableDate: any;
};

export interface CompetenceRegistrationState {
  blackoutDates: Array<blackoutDatesType> | undefined;
  reloadPage: boolean;
  reloadAddPage: boolean;
  pagination: DefaultPagination;
  confirmDate: boolean;
  createStatus: string;
  filterReload:
    | {
        year: number;
      }
    | undefined;
}

const initialState: CompetenceRegistrationState = {
  blackoutDates: undefined,
  reloadPage: false,
  reloadAddPage: false,
  createStatus: '',
  pagination: { PageSize: 10 } as DefaultPagination,
  filterReload: undefined,
  confirmDate: false
};

export const prefix = 'competenceRegistration';

const competenceRegistrationSlice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setBlackoutDates: (state, action: PayloadAction<any>) => {
      state.blackoutDates = action.payload;
    },
    setFilterReload: (state, action: PayloadAction<{ year: number }>) => {
      state.filterReload = action.payload;
    },
    setPagination: (state, action: PayloadAction<DefaultPagination>) => {
      state.pagination = action.payload;
    },
    setConfirmDate: (state, action: PayloadAction<boolean>) => {
      state.confirmDate = action.payload;
    },
    setReloadPage: (state, action: PayloadAction<boolean>) => {
      state.reloadPage = action.payload;
    },
    setReloadAddPage: (state, action: PayloadAction<boolean>) => {
      state.reloadAddPage = action.payload;
    },
    setCreateStatus: (state, action: PayloadAction<string>) => {
      state.createStatus = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(
      fetchBlackoutDates.fulfilled,
      (state, action: PayloadAction<Array<blackoutDatesType>>) => {
        state.blackoutDates = action.payload;
      }
    );
    builder.addCase(
      updateBlackoutDates.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.reloadPage = action.payload;
      }
    );
    builder.addCase(
      createBlackoutDates.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.reloadAddPage = action.payload;
        state.confirmDate = action.payload;
      }
    );
    builder.addCase(
      createSpecialDate.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createStatus = action.payload;
      }
    );
  }
});
export const actions = competenceRegistrationSlice.actions;
export const reducer = competenceRegistrationSlice.reducer;

import produce from 'immer';
import { Site } from '~/domain/model';
import { createCreator, createReducer, Reducer } from '~/helpers/util/reducers';

export type SitesState = {
  byId: Map<string, Site>;
  byIdFiltered: Map<string, Site>;
};

export type Update = {
  byId: Map<string, Site>;
  byIdFiltered: Map<string, Site>;
};

export type Filter = {
  keyword: string;
};

const prefix = '@sites';

export const Types = Object.freeze({
  CLEAR: `${prefix}/CLEAR`,
  UPDATE: `${prefix}/UPDATE`,
  FILTER: `${prefix}/FILTER`
});

export const initialState: SitesState = {
  byId: new Map(),
  byIdFiltered: new Map()
};

const clearReducer: Reducer<SitesState, void> = state => {
  return produce(state, draft => {
    draft.byId = initialState.byId;
    draft.byIdFiltered = initialState.byIdFiltered;
  });
};

const updateReducer: Reducer<SitesState, Update> = (state, action) => {
  return produce(state, draft => {
    draft.byId = action.byId as Map<string, Site>;
    draft.byIdFiltered = action.byId as Map<string, Site>;
  });
};

const filterReducer: Reducer<SitesState, Filter> = (state, action) => {
  const { keyword } = action;
  return produce(state, draft => {
    const sitesArray = Array.from(draft.byId.values());

    const sites = sitesArray.filter(site => {
      const selectText =
        `${site.account} - ${site.corporateName} (${site.tradingName})`.toLocaleLowerCase();

      return selectText.includes(keyword.toLowerCase());
    });

    draft.byIdFiltered = new Map(
      sites.map(site => [String(site.id), new Site(site)])
    );
  });
};

export const actions = Object.freeze({
  clear: createCreator<void>(Types.CLEAR),
  update: createCreator<Update>(Types.UPDATE),
  filter: createCreator<Filter>(Types.FILTER)
});

export const reducer = createReducer(initialState, {
  [Types.CLEAR]: clearReducer,
  [Types.UPDATE]: updateReducer,
  [Types.FILTER]: filterReducer
});

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as API from '~/domain/api';

import {
  changeFlagUseProductPreference,
  deleteImage,
  fetchImages,
  fetchPreferenceColor,
  reorderImages,
  uploadImages
} from './apiCalls';

export interface VolLkpState {
  closeModal: boolean;
  // #FIXME Remover any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  colors: any;
  useProductPreference?: boolean;
  loading: boolean;
  images: API.Image[];
  refetchOffersList: boolean;
  keyword: string | undefined;
  searchByKeyword: boolean;
  showKeysForEdit: boolean;
  clearKeys: boolean;
}
const initialState: VolLkpState = {
  closeModal: false,
  colors: [],
  useProductPreference: undefined,
  loading: false,
  images: [],
  refetchOffersList: false,
  keyword: undefined,
  searchByKeyword: false,
  showKeysForEdit: false,
  clearKeys: false
};

export const prefix = 'volLkp';

const productPreferencesSlice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: () => initialState,
    setImages: (state, action: PayloadAction<API.Image[]>) => {
      state.images = action.payload;
    },
    setShowKeysForEdit: (state, action: PayloadAction<boolean>) => {
      state.showKeysForEdit = action.payload;
    },
    setClearKeys: (state, action: PayloadAction<boolean>) => {
      state.clearKeys = action.payload;
    },
    setKeyword: (state, action: PayloadAction<string | undefined>) => {
      state.keyword = action.payload;
    },
    setSearchByKeyword: (state, action: PayloadAction<boolean>) => {
      state.searchByKeyword = action.payload;
    },
    // #FIXME Remover any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setPreferencesColor: (state, action: PayloadAction<any>) => {
      state.colors = action.payload;
    },
    setUseProductPreference: (state, action: PayloadAction<boolean>) => {
      state.useProductPreference = action.payload;
    },
    setRefetchOffersList: (state, action: PayloadAction<boolean>) => {
      state.refetchOffersList = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchPreferenceColor.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.colors = data;
    });
    builder.addCase(changeFlagUseProductPreference.pending, state => {
      state.loading = true;
    });
    builder.addCase(changeFlagUseProductPreference.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(changeFlagUseProductPreference.rejected, state => {
      state.loading = false;
    });
    builder.addCase(fetchImages.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchImages.fulfilled, (state, action) => {
      state.images = action.payload.images;
      state.useProductPreference =
        action.payload.useProductPreference !== undefined
          ? action.payload.useProductPreference
          : true;
      state.loading = false;
    });
    builder.addCase(fetchImages.rejected, state => {
      state.loading = false;
    });
    builder.addCase(reorderImages.pending, (state, action) => {
      state.images = action.meta.arg.newList;
      state.loading = true;
    });
    builder.addCase(reorderImages.fulfilled, state => {
      state.refetchOffersList = true;
      state.loading = false;
    });
    builder.addCase(reorderImages.rejected, (state, action) => {
      state.images = action.meta.arg.list;
      state.loading = false;
    });
    builder.addCase(uploadImages.pending, state => {
      state.loading = true;
    });
    builder.addCase(uploadImages.fulfilled, state => {
      state.refetchOffersList = true;
      state.loading = false;
      state.closeModal = true;
    });
    builder.addCase(uploadImages.rejected, state => {
      state.loading = false;
    });
    builder.addCase(deleteImage.pending, state => {
      state.loading = true;
    });
    builder.addCase(deleteImage.fulfilled, (state, action) => {
      state.loading = false;
      state.refetchOffersList = action.meta.arg.filesArrayLength === 1;
    });
    builder.addCase(deleteImage.rejected, state => {
      state.loading = false;
    });
  }
});

export const actions = productPreferencesSlice.actions;
export const reducer = productPreferencesSlice.reducer;

import { AnnouncementState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.core.announcement as AnnouncementState;
};

export const getAnnouncementList = createSelector([getRoot], state => {
  return state.announcementList;
});

export const getAnnouncementBanner = createSelector([getRoot], state => {
  return state.announcementBanner;
});

export const getAnnouncementForEdit = createSelector([getRoot], state => {
  return state.announcementForEdit;
});

export const getFiles = createSelector([getRoot], state => {
  return state.files;
});

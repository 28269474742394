export function attachmentClearAll() {
  return {
    type: '@attachment/CLEAR_ALL'
  };
}

export function attachmentListRequest(filters?: any) {
  return {
    type: '@attachment/LIST_REQUEST',
    payload: { filters }
  };
}

export function attachmentListSuccess(list: any) {
  return {
    type: '@attachment/LIST_SUCCESS',
    payload: { list }
  };
}

export function attachmentListFailure() {
  return {
    type: '@attachment/LIST_FAILURE'
  };
}

export function attachmentFullListRequest(propertyId: number) {
  return {
    type: '@attachment/FULL_LIST_REQUEST',
    payload: { id: propertyId }
  };
}

export function attachmentFullListSuccess(list: any) {
  return {
    type: '@attachment/FULL_LIST_SUCCESS',
    payload: { list }
  };
}

export function attachmentFullListFailure() {
  return {
    type: '@attachment/FULL_LIST_FAILURE'
  };
}

export function attachmentAddRequest(data: any) {
  return {
    type: '@attachment/ADD_REQUEST',
    payload: { data }
  };
}

export function attachmentAddSuccess(id: number) {
  return {
    type: '@attachment/ADD_SUCCESS',
    payload: { id }
  };
}

export function attachmentAddFailure() {
  return {
    type: '@attachment/ADD_FAILURE'
  };
}

export function attachmentReadRequest(id: number) {
  return {
    type: '@attachment/READ_REQUEST',
    payload: { id }
  };
}

export function attachmentReadSuccess(data: any) {
  return {
    type: '@attachment/READ_SUCCESS',
    payload: { data }
  };
}

export function attachmentReadFailure() {
  return {
    type: '@attachment/READ_FAILURE'
  };
}

export function attachmentDeleteRequest(id: number, filters?: any) {
  return {
    type: '@attachment/DELETE_REQUEST',
    payload: { id, filters }
  };
}

export function attachmentDeleteSuccess() {
  return {
    type: '@attachment/DELETE_SUCCESS'
  };
}

export function attachmentDeleteFailure() {
  return {
    type: '@attachment/DELETE_FAILURE'
  };
}

import { createSelector } from '~/helpers/util/selectors';
import { SpecialDateEditState } from './duck';

export const getRoot = (state: any) => {
  return state.ui.specialDateEdit as SpecialDateEditState;
};

export const getEditStatus = createSelector([getRoot], state => {
  return state.editStatus;
});

export const getErrorMessage = createSelector([getRoot], state => {
  return state.errorMessage;
});

export const getIsLoading = createSelector([getEditStatus], editStatus => {
  return editStatus === 'updating';
});

export const getSpecialDate = createSelector([getRoot], state => {
  return state.specialDate;
});

import { FormInstance, Table } from 'antd';
import { Frame, DividerFrame } from '~/components/Globals';
import useDoneStore from '~/zustand/rfidReports/movement/done-state';

interface Props {
  form: FormInstance<any>;
}
export default function TableMovementDones(props: Props) {
  const { dones, donePagination, handleDonePagination } = useDoneStore();
  const { form } = props;

  return (
    <Frame ghost accordion defaultActiveKey="1">
      <Frame.Panel
        header={
          <DividerFrame orientation="left">
            Transferências Realizadas
          </DividerFrame>
        }
        key="1"
      >
        <Table
          dataSource={dones}
          pagination={{
            pageSize: donePagination.pageSize,
            current: donePagination.current,
            showSizeChanger: true,
            total: donePagination.total,
            onChange(page, pageSize) {
              handleDonePagination({
                ...donePagination,
                current: page,
                pageSize
              });
              form.submit();
            }
          }}
          expandable={{
            expandedRowRender: movementDones => (
              <Table
                dataSource={movementDones.items}
                expandable={{
                  expandedRowRender: movementDonesItem => (
                    <Table dataSource={movementDonesItem.carts} bordered>
                      <Table.Column
                        dataIndex="serial"
                        title="Código do Carrinho"
                      />
                      <Table.Column dataIndex="epc" title="Epc" />
                    </Table>
                  )
                }}
                bordered
              >
                <Table.Column
                  dataIndex="productCode"
                  title="Código do Material"
                />
                <Table.Column dataIndex="type" title="Tipo" />
                <Table.Column dataIndex="results" title="Total Transferido" />
              </Table>
            )
          }}
        >
          <Table.Column dataIndex="moveDate" title="Data Transferência" />
          <Table.Column dataIndex="areaFrom" title="De" />
          <Table.Column dataIndex="areaTo" title="Para" />
          <Table.Column dataIndex="numberCart" title="Total Carrinho" />
          <Table.Column dataIndex="numberMC" title="Total MC" />
        </Table>
      </Frame.Panel>
    </Frame>
  );
}

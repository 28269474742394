import * as API from '../api';

export default class HistoryPriceAlert {
  private readonly json: API.HistoryPriceAlert;
  public readonly id: number;
  public readonly createdBy: string;
  public readonly eventDate: string;
  public readonly percentAboveAverage: number;
  public readonly percentAboveAveragePrevious: number;
  public readonly percentBelowAverage: number;
  public readonly percentBelowAveragePrevious: number;

  constructor(json: API.HistoryPriceAlert) {
    this.json = json;
    this.id = this.getId();
    this.createdBy = this.getCreatedBy();
    this.eventDate = this.getEventDate();
    this.percentAboveAverage = this.getPercentAboveAverage();
    this.percentAboveAveragePrevious = this.getPercentAboveAveragePrevious();
    this.percentBelowAverage = this.getPercentBelowAverage();
    this.percentBelowAveragePrevious = this.getPercentBelowAveragePrevious();
  }

  private getId() {
    return this.json.id || Number.NaN;
  }

  private getCreatedBy() {
    return this.json.createdBy || '';
  }

  private getEventDate() {
    return this.json.eventDate || '';
  }

  private getPercentAboveAverage() {
    return this.json.percentAboveAverage || 0;
  }

  private getPercentAboveAveragePrevious() {
    return this.json.percentAboveAveragePrevious || 0;
  }

  private getPercentBelowAverage() {
    return this.json.percentBelowAverage || 0;
  }

  private getPercentBelowAveragePrevious() {
    return this.json.percentBelowAveragePrevious || 0;
  }
}

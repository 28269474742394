import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type StatusRequest = 'idle' | 'pending' | 'success' | 'failure';

export type AnnouncementBannerState = {
  requestAnnouncementBannerStatus: StatusRequest;
  postReadAnnouncementStatus: StatusRequest;
  requestGetFilesByIdStatus: StatusRequest;
  errorMessage?: string;
  finished: boolean;
};

const prefix = '@announcementBanner';

export const initialState: AnnouncementBannerState = {
  requestAnnouncementBannerStatus: 'idle',
  requestGetFilesByIdStatus: 'idle',
  postReadAnnouncementStatus: 'idle',
  finished: false
};

const announcementBanner = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clearStatusPost: state => {
      state.postReadAnnouncementStatus = 'idle';
    },
    requestAnnouncementBanner: (state, _action: PayloadAction<void>) => {
      state.requestAnnouncementBannerStatus = 'pending';
    },
    requestAnnouncementBannerSuccess: (state, _action: PayloadAction<void>) => {
      state.requestAnnouncementBannerStatus = 'success';
    },
    requestAnnouncementBannerFailure: (state, _action: PayloadAction<void>) => {
      state.requestAnnouncementBannerStatus = 'failure';
    },
    getFilesById: (state, _action: PayloadAction<number>) => {
      state.requestGetFilesByIdStatus = 'pending';
    },
    getFilesByIdSuccess: (state, _action: PayloadAction<void>) => {
      state.requestGetFilesByIdStatus = 'success';
    },
    getFilesByIdFailure: (state, _action: PayloadAction<void>) => {
      state.requestGetFilesByIdStatus = 'failure';
    },
    postReadAnnouncement: (state, _action: PayloadAction<number>) => {
      state.postReadAnnouncementStatus = 'pending';
    },
    postReadAnnouncementSuccess: (state, _action: PayloadAction<void>) => {
      state.postReadAnnouncementStatus = 'success';
      state.finished = true;
    },
    postReadAnnouncementFailure: (state, _action: PayloadAction<void>) => {
      state.postReadAnnouncementStatus = 'failure';
    },
    changeFinished: state => {
      state.finished = false;
    }
  }
});

export const actions = announcementBanner.actions;
export const reducer = announcementBanner.reducer;

const caseReducers = announcementBanner.caseReducers;

export const Types = Object.freeze({
  requestAnnouncementBanner: `${prefix}/${caseReducers.requestAnnouncementBanner.name}`,
  requestAnnouncementBannerSuccess: `${prefix}/${caseReducers.requestAnnouncementBannerSuccess.name}`,
  requestAnnouncementBannerFailure: `${prefix}/${caseReducers.requestAnnouncementBannerFailure.name}`,
  getFilesById: `${prefix}/${caseReducers.getFilesById.name}`,
  getFilesByIdSuccess: `${prefix}/${caseReducers.getFilesByIdSuccess.name}`,
  getFilesByIdFailure: `${prefix}/${caseReducers.getFilesByIdFailure.name}`,
  postReadAnnouncement: `${prefix}/${caseReducers.postReadAnnouncement.name}`,
  postReadAnnouncementSuccess: `${prefix}/${caseReducers.postReadAnnouncementSuccess.name}`,
  postReadAnnouncementFailure: `${prefix}/${caseReducers.postReadAnnouncementFailure.name}`
});

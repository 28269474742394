import { call, fork, put, takeLatest } from 'redux-saga/effects';

import { actions, Create, Types } from './duck';
import { Action } from '~/helpers/util/reducers';
import { APIError } from '~/domain/api/errors';
import * as BannerProducerManagementService from '~/store/modules/_core/bannerProducerManagement/service';
import * as BannerTypesService from '~/store/modules/_core/bannerTypes/service';
import * as SiteLinksService from '~/store/modules/_core/siteLinks/service';

export function* watchCreateSaga(action: Action<Create>) {
  yield fork(createSaga, action.payload);
}

export function* createSaga(param: Create) {
  try {
    const { description, url, bannerTypeId, bannerImage } = param;
    yield call(
      BannerProducerManagementService.create,
      description,
      url,
      bannerTypeId,
      bannerImage
    );
    yield put(actions.createSuccess());
  } catch (error: any) {
    if (error instanceof APIError) {
      yield put(actions.createFailure({ errorMessage: error.message }));
    } else {
      yield put(
        actions.createFailure({ errorMessage: error?.response?.data[0] })
      );
    }
  }
}

export function* fetchInitSaga(): any {
  try {
    yield call(SiteLinksService.getAllUrls);
    yield call(BannerTypesService.fetchAll);
    yield put(actions.fetchInitSuccess());
  } catch (err) {
    yield put(actions.fetchInitFailure());
  }
}

export default [
  takeLatest(Types.CREATE_INIT, watchCreateSaga),
  takeLatest(Types.FETCH_INIT, fetchInitSaga)
];

import { CountingPatternsState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.core.countingPatterns as CountingPatternsState;
};

export const getById = createSelector([getRoot], state => state.byId);

export const getCountingPatterns = createSelector([getRoot], state =>
  Array.from(state.byId.values())
);

import { call, put } from 'redux-saga/effects';

import * as API from '~/domain/api';

import { actions } from './duck';
import { Week } from '~/domain/model';
import * as WeekService from '~/services/producer/Week';

type Find = {
  startDate: string;
  endDate: string;
};

export function* find({ startDate, endDate }: Find) {
  const response: API.ServerResponse = yield call(WeekService.find, {
    startDate,
    endDate
  });

  const weeks: API.Week[] = response.data;
  const weekByID: Map<string, Week> = new Map(
    weeks.map(week => [String(week.id), new Week(week)])
  );
  yield put(actions.update({ byId: weekByID }));
  return response.data;
}

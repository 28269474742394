import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as AutoserviceCalls from './apiCalls';

export interface AutoserviceDefinitionsState {
  definitions: any | undefined;
  reloadPageData: boolean;
}

const initialState: AutoserviceDefinitionsState = {
  definitions: undefined,
  reloadPageData: false
};

export const prefix = 'autoserviceDefinitions';

const autoserviceDefinitionsSlice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setDefinitions: (state, action: PayloadAction<any[]>) => {
      state.definitions = action.payload;
    },
    setReloadPageData: (state, action: PayloadAction<boolean>) => {
      state.reloadPageData = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(
      AutoserviceCalls.fetchDefinitions.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.definitions = action.payload;
      }
    );
    builder.addCase(AutoserviceCalls.updateDefinitions.fulfilled, state => {
      state.reloadPageData = true;
    });
  }
});
export const actions = autoserviceDefinitionsSlice.actions;
export const reducer = autoserviceDefinitionsSlice.reducer;

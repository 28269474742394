import { AxiosResponse } from 'axios';
import { endpoints } from '~/constants/endpoints';
import * as M from '~/domain/model';
import FindBiDashboardParams from '~/domain/model/BiDashboard/FindBiDashboardParams';
import { CreateBiDashboardParams } from '~/domain/model/BiDashboard/CreateBiDashboardParams';
import api from '../api';

export type FindMany = {
  siteId: number;
  pageNumber?: number;
  pageSize?: number;
};

export const findMany = async ({
  PageNumber = 1,
  PageSize = 100,
  ...params
}: FindBiDashboardParams) => {
  let url = endpoints.bidashboard.bidashboard;
  const entries = Object.entries({ ...params });

  url += `?PageSize=${PageSize}&PageNumber=${PageNumber}`;
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of entries) {
    if (value !== undefined && value !== null) {
      url += `&Data.${key}=${value}`;
    }
  }
  const response = await api.get<M.ResponseApi<M.BIDashboard[]>>(url);
  return response.data;
};

export const deleteBiDashboard = async (biDashboardId: number) => {
  const response = await api.put<void, AxiosResponse<boolean>>(
    `${endpoints.bidashboard.delete}${biDashboardId}`
  );
  return !!response.data;
};

export const createBiDashboard = async (params: CreateBiDashboardParams) => {
  const response = await api.put<void, AxiosResponse<M.BIDashboard>>(
    endpoints.bidashboard.bidashboard,
    params
  );
  return response.data;
};

export const editBiDashboard = async (params: CreateBiDashboardParams) => {
  const response = await api.put<void, AxiosResponse<M.BIDashboard>>(
    endpoints.bidashboard.bidashboard,
    params
  );
  return response.data;
};

export const editBiDashboardStatus = async (id: number) => {
  const response = await api.put<void, AxiosResponse<M.BIDashboard>>(
    `${endpoints.bidashboard.status}/${id}`
  );
  return response.data;
};

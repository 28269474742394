import { AxiosError, AxiosResponse } from 'axios';
import api from '../config';

export const getBalance = (customerId: number) => {
  return new Promise<number>((resolve, reject) => {
    api
      .get<number, AxiosResponse<number>>(`customers/${customerId}/balance`)
      .then((response: AxiosResponse<number>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

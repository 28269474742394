import { SalesReportState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.ui.salesReport as SalesReportState;
};

export const getPageNumberVol = createSelector([getRoot], state => {
  return state.pageNumberVolList;
});

export const getPageSizeVol = createSelector([getRoot], state => {
  return state.pageSizeVolList;
});

export const getPageNumberLkp = createSelector([getRoot], state => {
  return state.pageNumberLkpList;
});

export const getPageSizeLkp = createSelector([getRoot], state => {
  return state.pageSizeLkpList;
});

export const getTotalCountVol = createSelector([getRoot], state => {
  return state.totalCountVolList;
});

export const getTotalCountLkp = createSelector([getRoot], state => {
  return state.totalCountLkpList;
});

export const getStatusRequestVol = createSelector([getRoot], state => {
  return state.fetchSalesReportVolListStatus;
});

export const getStatusRequestLkp = createSelector([getRoot], state => {
  return state.fetchSalesReportLkpListStatus;
});

export const getStatusWorkSheetVol = createSelector([getRoot], state => {
  return state.fetchWorkSheetVolStatus;
});

export const getStatusWorkSheetLkp = createSelector([getRoot], state => {
  return state.fetchWorkSheetLkpStatus;
});

export const isLoading = createSelector([getRoot], state => {
  return (
    state.fetchInitStatus === 'pending' ||
    state.fetchSalesReportVolListStatus === 'pending' ||
    state.fetchWorkSheetVolStatus === 'pending' ||
    state.fetchWorkSheetLkpStatus === 'pending' ||
    state.fetchSalesReportLkpListStatus === 'pending' ||
    state.fetchProductsStatus === 'pending'
  );
});

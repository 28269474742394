import { createSelector } from 'reselect';
import { BiDashboardState } from '.';

export const getRoot = (state: any) => {
  return state.biDashboards as BiDashboardState;
};

export const getBiDashboard = createSelector(
  [getRoot],
  state => state.biDashboard
);

export const getBiDashboardVisible = createSelector(
  [getRoot],
  state => state.viewDashboardVisible
);

export const getBiDashboardPagination = createSelector(
  [getRoot],
  state => state.biDashboardPagination
);

export const getTotalBiDashboards = createSelector(
  [getRoot],
  state => state.totalBiDashboard
);

export const getBiDashboards = createSelector(
  [getRoot],
  state => state.biDashboards
);

export const getCloseEditBiDashboardModal = createSelector(
  [getRoot],
  state => state.closeEditBiDashboardModal
);

export const getCloseAddBiDashboardModal = createSelector(
  [getRoot],
  state => state.closeAddBiDashboardModal
);

export const getSubmitFormFilter = createSelector(
  [getRoot],
  state => state.submitFormFilter
);

export const getSubmitNow = createSelector([getRoot], state => state.submitNow);

export const getBiDashboardLoading = createSelector(
  [getRoot],
  state => state.loading
);

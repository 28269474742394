import React, { lazy, Suspense } from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';
import Loading from '~/components/Loading';

// Importar componentes com React.lazy()
const SignIn = lazy(() => import('~/pages/SignIn'));
const Oauth = lazy(() => import('~/pages/SignIn/oauth'));
const SignOut = lazy(() => import('~/pages/SignOut'));
const DashboardHome = lazy(() => import('~/pages/Dashboard'));

const Routes: React.FC = () => (
  <Suspense fallback={<Loading loading />}>
    <Switch>
      <Route path="/login" exact component={SignIn} />
      <Route path="/oauth" component={Oauth} />
      <Route
        path="/"
        tabName="Dashboard"
        exact
        component={DashboardHome}
        isPrivate
      />
      <Route path="/signout" component={SignOut} isPrivate />
    </Switch>
  </Suspense>
);

export default Routes;

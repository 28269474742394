import { FormInstance, Table } from 'antd';
import { DividerFrame, Frame } from '~/components/Globals';
import useDoneStore from '~/zustand/rfidReports/receipt/done-state';

interface Props {
  form: FormInstance<any>;
}
export default function TableReceiptDones(props: Props) {
  const { dones, donePagination, handleDonePagination } = useDoneStore();
  const { form } = props;

  return (
    <Frame ghost accordion defaultActiveKey="1">
      <Frame.Panel
        header={
          <DividerFrame orientation="left">
            Recebimentos Realizados
          </DividerFrame>
        }
        key="1"
      >
        <Table
          dataSource={dones}
          pagination={{
            pageSize: donePagination.pageSize,
            current: donePagination.current,
            showSizeChanger: true,
            total: donePagination.total,
            onChange(page, pageSize) {
              handleDonePagination({
                ...donePagination,
                current: page,
                pageSize
              });
              form.submit();
            }
          }}
          expandable={{
            expandedRowRender: receiptDones => (
              <Table
                dataSource={receiptDones.carts}
                expandable={{
                  expandedRowRender: receiptDonesItem => (
                    <Table dataSource={receiptDonesItem.acessories} bordered>
                      <Table.Column
                        dataIndex="productCode"
                        title="Código Material"
                      />
                      <Table.Column dataIndex="type" title="Tipo Material" />
                      <Table.Column
                        dataIndex="results"
                        title="Resultado/Esperado"
                      />
                    </Table>
                  )
                }}
                bordered
              >
                <Table.Column dataIndex="productCode" title="Serial Carrinho" />
                <Table.Column dataIndex="area" title="Área" />
                <Table.Column dataIndex="numberMC" title="Total MC" />
              </Table>
            )
          }}
        >
          <Table.Column dataIndex="receiptDate" title="Data Recebimento" />
          <Table.Column dataIndex="document" title="Romaneio" />
          <Table.Column
            dataIndex="numberCart"
            title="Carrinho Resultado/Esperado"
          />
          <Table.Column dataIndex="numberMC" title="MC Resultado/Esperado" />
        </Table>
      </Frame.Panel>
    </Frame>
  );
}

import produce from 'immer';
import { OfferSettings } from '~/domain/model';
import { createCreator, createReducer, Reducer } from '~/helpers/util/reducers';
import * as API from '~/domain/api';

export type OfferSettingsState = {
  byId: Map<number, OfferSettings>;
};

export type Replace = {
  byId: Map<number, OfferSettings>;
};

export type Merge = {
  siteId: number;
  offerSettings: OfferSettings;
};

export type Upsert = {
  offerSettings: API.OfferSettingsParam;
};

const prefix = '@offerSettings';

export const Types = Object.freeze({
  CLEAR: `${prefix}/CLEAR`,
  REPLACE: `${prefix}/REPLACE`,
  MERGE: `${prefix}/MERGE`
});

export const initialState: OfferSettingsState = {
  byId: new Map()
};

const clearReducer: Reducer<OfferSettingsState, void> = state => {
  return produce(state, draft => {
    draft.byId = initialState.byId;
  });
};

const replaceReducer: Reducer<OfferSettingsState, Replace> = (
  state,
  action
) => {
  return produce(state, draft => {
    draft.byId = action.byId as Map<number, OfferSettings>;
  });
};

const mergeReducer: Reducer<OfferSettingsState, Merge> = (state, action) => {
  return produce(state, draft => {
    draft.byId.set(action.siteId, action.offerSettings);
  });
};

export const actions = Object.freeze({
  clear: createCreator<void>(Types.CLEAR),
  replace: createCreator<Replace>(Types.REPLACE),
  merge: createCreator<Merge>(Types.MERGE)
});

export const reducer = createReducer(initialState, {
  [Types.CLEAR]: clearReducer,
  [Types.REPLACE]: replaceReducer,
  [Types.MERGE]: mergeReducer
});

import produce from 'immer';
import { ApplicationError } from '~/domain/api/errors';
import { createCreator, createReducer, Reducer } from '~/helpers/util/reducers';

export type FetchStatus = 'idle' | 'pending' | 'success' | 'failure';
export type UpdateStatusStatus = 'idle' | 'pending' | 'success' | 'failure';
export type CreateStatus = 'idle' | 'pending' | 'success' | 'failure';
export type FetchBannerUrlStatus = 'idle' | 'pending' | 'success' | 'failure';

export type BannerListState = {
  fetchStatus: FetchStatus;
  fetchBannersStatus: FetchStatus;
  updateStatusStatus: UpdateStatusStatus;
  createStatus: CreateStatus;
  fetchBannerUrlStatus: FetchBannerUrlStatus;
  errorMessage?: string;
  bannerTypeId?: number;
  description: string;
  updateStatus: boolean;
  pageNumber: number;
  pageSize: number;
  totalCount: number;
  viewBannerId?: number;
  bannerImage: string;
};

export type FetchBanners = {
  bannerTypeId?: number;
  description?: string;
  isActive?: boolean;
  pageNumber?: number;
  pageSize?: number;
};

export type FetchPage = {
  pageNumber: number;
  pageSize: number;
};

export type FetchBannerSuccess = {
  pageNumber?: number;
  pageSize?: number;
  totalCount: number;
};

export type FetchFailure = {
  error?: ApplicationError;
};

export type UpdateStatus = {
  id: number;
};

export type Create = {
  description: string;
  bannerTypeId: number;
  bannerImage: string;
};

export type FetchBannerUrl = {
  bannerId: number;
};

export type FetchBannerUrlSuccess = {
  bannerImage: string;
};

export type Error = {
  errorMessage?: string;
};

const prefix = '@bannerList';

export const Types = Object.freeze({
  CLEAR: `${prefix}/CLEAR`,
  FETCH_INIT: `${prefix}/FETCH_INIT`,
  FETCH_SUCCESS: `${prefix}/FETCH_SUCCESS`,
  FETCH_FAILURE: `${prefix}/FETCH_FAILURE`,
  FETCH_BANNERS: `${prefix}/FETCH_BANNERS`,
  FETCH_BANNERS_SUCCESS: `${prefix}/FETCH_BANNERS_SUCCESS`,
  FETCH_BANNERS_FAILURE: `${prefix}/FETCH_BANNERS_FAILURE`,
  UPDATE_STATUS_INIT: `${prefix}/UPDATE_STATUS_INIT`,
  UPDATE_STATUS_SUCCESS: `${prefix}/UPDATE_STATUS_SUCCESS`,
  UPDATE_STATUS_FAILURE: `${prefix}/UPDATE_STATUS_FAILURE`,
  CREATE_INIT: `${prefix}/CREATE_INIT`,
  CREATE_SUCCESS: `${prefix}/CREATE_SUCCESS`,
  CREATE_FAILURE: `${prefix}/CREATE_FAILURE`,
  FETCH_BANNER_URL: `${prefix}/FETCH_BANNER_URL`,
  FETCH_BANNER_URL_SUCCESS: `${prefix}/FETCH_BANNER_URL_SUCCESS`,
  FETCH_BANNER_URL_FAILURE: `${prefix}/FETCH_BANNER_URL_FAILURE`
});

export const initialState: BannerListState = {
  fetchStatus: 'idle',
  fetchBannersStatus: 'idle',
  updateStatusStatus: 'idle',
  createStatus: 'idle',
  fetchBannerUrlStatus: 'idle',
  bannerTypeId: Number.NaN,
  description: '',
  updateStatus: false,
  pageNumber: 1,
  pageSize: 10,
  totalCount: 0,
  viewBannerId: undefined,
  bannerImage: ''
};

const clearReducer: Reducer<BannerListState, void> = (state, _action) => {
  return produce(state, draft => {
    draft.fetchStatus = initialState.fetchStatus;
    draft.fetchBannersStatus = initialState.fetchBannersStatus;
    draft.updateStatusStatus = initialState.updateStatusStatus;
    draft.fetchBannerUrlStatus = initialState.fetchBannerUrlStatus;
    draft.createStatus = initialState.createStatus;
    draft.bannerTypeId = initialState.bannerTypeId;
    draft.description = initialState.description;
    draft.viewBannerId = initialState.viewBannerId;
    draft.bannerImage = initialState.bannerImage;
  });
};

const fetchInitReducer: Reducer<BannerListState, void> = state => {
  return produce(state, draft => {
    draft.fetchStatus = 'pending';
  });
};

const fetchSuccessReducer: Reducer<BannerListState, void> = state => {
  return produce(state, draft => {
    draft.fetchStatus = 'success';
  });
};

const fetchFailureReducer: Reducer<BannerListState, void> = state => {
  return produce(state, draft => {
    draft.fetchStatus = 'success';
  });
};

const fetchBannersReducer: Reducer<BannerListState, FetchBanners> = (
  state,
  action
) => {
  return produce(state, draft => {
    draft.fetchBannersStatus = 'pending';
    draft.bannerTypeId = action.bannerTypeId;
    let { pageNumber, pageSize } = action;
    if (!pageNumber) {
      pageNumber = initialState.pageNumber;
    }
    if (!pageSize) {
      pageSize = initialState.pageSize;
    }
    draft.pageNumber = pageNumber;
    draft.pageSize = pageSize;
  });
};

const fetchBannersSuccessReducer: Reducer<
  BannerListState,
  FetchBannerSuccess
> = (state, action) => {
  return produce(state, draft => {
    const { pageNumber, pageSize, totalCount } = action;
    draft.fetchBannersStatus = 'success';
    draft.pageNumber = pageNumber ?? initialState.pageNumber;
    draft.pageSize = pageSize ?? initialState.pageSize;
    draft.totalCount = totalCount;
  });
};

const fetchBannersFailureReducer: Reducer<BannerListState, void> = state => {
  return produce(state, draft => {
    draft.fetchBannersStatus = 'failure';
  });
};

const updateStatusInitReducer: Reducer<
  BannerListState,
  UpdateStatus
> = state => {
  return produce(state, draft => {
    draft.updateStatusStatus = 'pending';
  });
};

const updateStatusSuccessReducer: Reducer<BannerListState, void> = state => {
  return produce(state, draft => {
    draft.updateStatusStatus = 'success';
  });
};

const updateStatusFailureReducer: Reducer<BannerListState, Error> = (
  state,
  action
) => {
  return produce(state, draft => {
    draft.updateStatusStatus = 'failure';
    draft.errorMessage = action.errorMessage;
  });
};

const createInitReducer: Reducer<BannerListState, Create> = state => {
  return produce(state, draft => {
    draft.createStatus = 'pending';
  });
};

const createSuccessReducer: Reducer<BannerListState, void> = state => {
  return produce(state, draft => {
    draft.createStatus = 'success';
  });
};

const createFailureReducer: Reducer<BannerListState, Error> = (
  state,
  action
) => {
  return produce(state, draft => {
    draft.createStatus = 'failure';
    draft.errorMessage = action.errorMessage;
  });
};

const fetchBannerUrlReducer: Reducer<BannerListState, FetchBannerUrl> = (
  state,
  action
) => {
  return produce(state, draft => {
    draft.fetchBannerUrlStatus = 'pending';
    const { bannerId } = action;
    draft.viewBannerId = bannerId;
  });
};

const fetchBannerUrlSuccessReducer: Reducer<
  BannerListState,
  FetchBannerUrlSuccess
> = (state, action) => {
  return produce(state, draft => {
    const { bannerImage } = action;
    draft.fetchBannerUrlStatus = 'success';
    draft.bannerImage = bannerImage;
  });
};

const fetchBannerUrlFailureReducer: Reducer<BannerListState, void> = state => {
  return produce(state, draft => {
    draft.fetchBannerUrlStatus = 'failure';
  });
};

export const actions = Object.freeze({
  clear: createCreator<void>(Types.CLEAR),
  fetchInit: createCreator<void>(Types.FETCH_INIT),
  fetchSuccess: createCreator<void>(Types.FETCH_SUCCESS),
  fetchFailure: createCreator<void>(Types.FETCH_FAILURE),
  fetchBanners: createCreator<FetchBanners>(Types.FETCH_BANNERS),
  fetchBannersSuccess: createCreator<FetchBannerSuccess>(
    Types.FETCH_BANNERS_SUCCESS
  ),
  fetchBannersFailure: createCreator<void>(Types.FETCH_BANNERS_FAILURE),
  updateStatusInit: createCreator<UpdateStatus>(Types.UPDATE_STATUS_INIT),
  updateStatusSuccess: createCreator<void>(Types.UPDATE_STATUS_SUCCESS),
  updateStatusFailure: createCreator<Error>(Types.UPDATE_STATUS_FAILURE),
  createInit: createCreator<Create>(Types.CREATE_INIT),
  createSuccess: createCreator<void>(Types.CREATE_SUCCESS),
  createFailure: createCreator<Error>(Types.CREATE_FAILURE),
  fetchBannerUrl: createCreator<FetchBannerUrl>(Types.FETCH_BANNER_URL),
  fetchBannerUrlSuccess: createCreator<FetchBannerUrlSuccess>(
    Types.FETCH_BANNER_URL_SUCCESS
  ),
  fetchBannerUrlFailure: createCreator<void>(Types.FETCH_BANNER_URL_FAILURE)
});

export const reducer = createReducer(initialState, {
  [Types.CLEAR]: clearReducer,
  [Types.FETCH_INIT]: fetchInitReducer,
  [Types.FETCH_SUCCESS]: fetchSuccessReducer,
  [Types.FETCH_FAILURE]: fetchFailureReducer,
  [Types.FETCH_BANNERS]: fetchBannersReducer,
  [Types.FETCH_BANNERS_SUCCESS]: fetchBannersSuccessReducer,
  [Types.FETCH_BANNERS_FAILURE]: fetchBannersFailureReducer,
  [Types.UPDATE_STATUS_INIT]: updateStatusInitReducer,
  [Types.UPDATE_STATUS_SUCCESS]: updateStatusSuccessReducer,
  [Types.UPDATE_STATUS_FAILURE]: updateStatusFailureReducer,
  [Types.CREATE_INIT]: createInitReducer,
  [Types.CREATE_SUCCESS]: createSuccessReducer,
  [Types.CREATE_FAILURE]: createFailureReducer,
  [Types.FETCH_BANNER_URL]: fetchBannerUrlReducer,
  [Types.FETCH_BANNER_URL_SUCCESS]: fetchBannerUrlSuccessReducer,
  [Types.FETCH_BANNER_URL_FAILURE]: fetchBannerUrlFailureReducer
});

import moment from 'moment';
import { ProductionPlanningProductGroup } from '~/domain/model/ProductionPlanning/ProductionPlanningProductGroup';
import ProductionPlanningTableCell from '~/domain/model/ProductionPlanning/ProductionPlanningTableCell';

export const formatProductionPlanningColumnWeeks = (
  weeksLength?: number,
  referenceYear?: number
) => {
  const results: number[] = [];
  const limit = weeksLength ?? moment(referenceYear).weeksInYear();
  for (let i = 0; i <= limit; i++) {
    results.push(i);
  }
  return results;
};
interface WeekValues {
  lastYear: number;
  budgeted: number;
  adjusted: number;
  done: number;
  competenceEventType: number;
}
export const formatProductionPlanningVirtualTableDataSource = (
  groupProducts?: ProductionPlanningProductGroup[]
): {
  tableData: ProductionPlanningTableCell[][];
  totalColumn: WeekValues[];
  totalRow: WeekValues[];
} => {
  const tableData: ProductionPlanningTableCell[][] = [];
  const totalColumn: WeekValues[] = [];
  const totalRow: WeekValues[] = Array<WeekValues>(52).fill({
    lastYear: 0,
    budgeted: 0,
    adjusted: 0,
    done: 0,
    competenceEventType: Number.NaN
  });
  if (groupProducts?.length) {
    groupProducts.forEach(item => {
      const row: ProductionPlanningTableCell[] = [];
      let totalColumnLastYear = 0;
      let totalColumnBudgeted = 0;
      let totalColumnAdjusted = 0;
      let totalColumnDone = 0;
      item.weeks.forEach((week, columnIndex) => {
        totalColumnLastYear += week.lastYear;
        totalColumnBudgeted += week.budgeted;
        totalColumnAdjusted += week.adjusted;
        totalColumnDone += week.done;
        row.push({
          productionProductGroupCode: item.productionProductGroupCode,
          productionProductGroupId: item.productionProductGroupId,
          productionProductGroupDescription:
            item.productionProductGroupDescription,
          week: {
            ...week,
            budgeted: week.budgeted ?? 0,
            adjusted: week.adjusted ?? 0
          }
        });
        const updatedTotalWeek = { ...totalRow[columnIndex] };
        updatedTotalWeek.lastYear += week.lastYear;
        updatedTotalWeek.budgeted += week.budgeted;
        updatedTotalWeek.adjusted += week.adjusted;
        updatedTotalWeek.done += week.done;
        totalRow[columnIndex] = updatedTotalWeek;
      });
      totalColumn.push({
        adjusted: totalColumnAdjusted,
        budgeted: totalColumnBudgeted,
        done: totalColumnDone,
        lastYear: totalColumnLastYear,
        competenceEventType: Number.NaN
      });
      tableData.push(row);
    });
  }
  return { tableData, totalColumn, totalRow };
};

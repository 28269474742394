import { CompetenceListState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.ui.competenceList as CompetenceListState;
};

export const getIsLoading = createSelector(
  [getRoot],
  state => state.fetchStatus === 'fetching' || state.removeStatus === 'removing'
);

export const getIsFetchStatusIdle = createSelector(
  [getRoot],
  state => state.fetchStatus === 'idle'
);

export const getPageNumber = createSelector([getRoot], state => {
  return state.pageNumber;
});

export const getPageSize = createSelector([getRoot], state => state.pageSize);

export const getTotalCount = createSelector(
  [getRoot],
  state => state.totalCount
);

export const getRemoveStatus = createSelector(
  [getRoot],
  state => state.removeStatus
);

import { call, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import service from '~/services/api';
import * as API from '~/domain/api';
import { actions } from './duck';
import { actions as uiActions } from '../../_ui';
import { DirectedOffersListAction } from '../../_ui/directedOffersList/duck';
import { VolOffer, VolOfferHistory } from '~/domain/model';
import { generateErrorMessage } from '~/helpers/util/error';
import { DirectedOffersUpdateAction } from '../../_ui/directedOffersCreate/duck';

export function* findMany(payload: DirectedOffersListAction): any {
  try {
    let url = `/vol-offer/directed?`;
    const entries = Object.entries(payload);

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of entries) {
      if (key !== 'pagination' && value !== undefined) {
        url += `Data.${key}=${value}&`;
      }
    }

    if (payload.pagination) {
      url += `PageSize=${payload.pagination.pageSize}&PageNumber=${payload.pagination.pageNumber}`;
    }
    const response: AxiosResponse = yield call(service.get, url);
    const apiResponse = response.data as API.DefaultResponse;
    const results = apiResponse.results as API.VolOffer[];
    const directedOffers = results.map(
      (value: API.VolOffer, index: number) => new VolOffer({ ...value, index })
    );

    yield put(
      uiActions.directedOfferList.setPagination({
        totalCount: apiResponse.totalCount,
        pageNumber: payload.pagination?.pageNumber,
        pageSize: payload.pagination?.pageSize
      })
    );
    yield put(actions.updateDirectedOffersList(directedOffers));
    yield put(actions.updateDirectedOffers({ directedOffers }));
  } catch (err) {
    console.log('directedOffers > service > findMany:: ', err);
  }
}
export function* create(payload: any): any {
  yield call(service.post, '/vol-offer/directed', payload);
}
export function* updateDirectedVolOffers(payload: API.DirectedVolOffer[]): any {
  yield call(service.put, '/vol-offer/directed', payload);
}

export function* update(payload: DirectedOffersUpdateAction) {
  yield call(service.put, '/vol-offer', payload);
}

export function* active(payload: number) {
  yield call(service.put, `/vol-offer/${payload}/active`);
}
export function* deleteSubOffer(payload: number) {
  yield call(service.delete, `/vol-offer/${payload}`);
}
export function* findHistory(payload: number): any {
  const response = yield call(service.get, `/vol-offer/${payload}/history`);
  if (response?.response?.status >= 400) {
    throw new Error(generateErrorMessage(response.response.data.Errors));
  }
  const historyResult = response.data as API.History;
  const history = new VolOfferHistory(historyResult);
  yield put(uiActions.directedOfferList.setHistory(history));
}

export function productionLocalTypeListRequest() {
  return {
    type: '@productionLocalType/LIST_REQUEST'
  };
}

export function productionLocalTypeListSuccess(data: any) {
  return {
    type: '@productionLocalType/LIST_SUCCESS',
    payload: { data }
  };
}

export function productionLocalTypeListFailure() {
  return {
    type: '@productionLocalType/LIST_FAILURE'
  };
}

import { AxiosResponse } from 'axios';
import apiProducer from '../api';

import { APIError } from '~/domain/api/errors';
import { FetchEcommerceManagement, ResponseApi } from '~/domain/model';
import PurchaseManagement from '~/domain/model/EcommerceManagement/PurchaseManagement';
import HistoryPurchaseManagement from '~/domain/model/EcommerceManagement/HistoryPurchaseManagement';
import { endpoints } from '~/constants/endpoints';

export type CancelPurchaseManagement = {
  transactionId?: number;
  reasonCancellation?: string;
};

export const findListPurchaseManagement = (
  params: FetchEcommerceManagement
) => {
  return apiProducer.get<
    void,
    AxiosResponse<ResponseApi<PurchaseManagement[]>>
  >(endpoints.purchaseManagement.base, {
    params
  });
};

export const onExport = async (params: FetchEcommerceManagement) => {
  const entries = Object.entries(params);
  let url = endpoints.purchaseManagement.download;
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of entries) {
    if (value && key !== 'Data.siteId') {
      url += `Data.${key}=${value}&`;
    }
    if (value) {
      if (key === 'Data.siteId' || key === 'Data.customerId') {
        url += `${key}=${value}&`;
      }
    }
  }
  return apiProducer.get(url);
};

export const findTransactionStatus = async () => {
  try {
    const response: AxiosResponse = await apiProducer.get(
      endpoints.purchaseManagement.transactionStatus
    );
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if (errorStatus === 400) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const findCartStatus = async () => {
  try {
    const response: AxiosResponse = await apiProducer.get(
      endpoints.purchaseManagement.cartStatus
    );
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if (errorStatus === 400) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const findHistoryPurchaseManagement = async (transactionId: number) => {
  return apiProducer.get<void, AxiosResponse<HistoryPurchaseManagement>>(
    `${endpoints.purchaseManagement.history}${transactionId}`
  );
};

export const cancelPurchaseManagement = async ({
  transactionId,
  reasonCancellation
}: CancelPurchaseManagement) => {
  return apiProducer.put(
    `${endpoints.purchaseManagement.base}${transactionId}/${reasonCancellation}`
  );
};

export const findProducts = async () => {
  return apiProducer.get<void, AxiosResponse<HistoryPurchaseManagement>>(
    `${endpoints.purchaseManagement.products}`
  );
};

import * as API from '../api';

export default class HistoryOfferChannel {
  private readonly json: API.HistoryOfferChannel;
  public readonly id: number;
  public readonly createdBy: number;
  public readonly createdByName: string;
  public readonly eventDate: string;
  public readonly percentLkp: number;
  public readonly percentLkpPrevious: number;
  public readonly percentVol: number;
  public readonly percentVolPrevious: number;

  constructor(json: API.HistoryOfferChannel) {
    this.json = json;
    this.id = this.getId();
    this.createdBy = this.getCreatedBy();
    this.createdByName = this.getCreatedByName();
    this.eventDate = this.getEventDate();
    this.percentLkp = this.getPercentLkp();
    this.percentLkpPrevious = this.getPercentLkpPrevious();
    this.percentVol = this.getPercentVol();
    this.percentVolPrevious = this.getPercentVolPrevious();
  }

  private getId() {
    return this.json.id || Number.NaN;
  }

  private getCreatedBy() {
    return this.json.createdBy || Number.NaN;
  }

  private getCreatedByName() {
    return this.json.createdByName || '';
  }

  private getEventDate() {
    return this.json.eventDate || '';
  }

  private getPercentLkp() {
    return this.json.percentLkp || 0;
  }

  private getPercentLkpPrevious() {
    return this.json.percentLkpPrevious || 0;
  }

  private getPercentVol() {
    return this.json.percentVol || 0;
  }

  private getPercentVolPrevious() {
    return this.json.percentVolPrevious || 0;
  }
}

import { SitesState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.core.sites as SitesState;
};

export const getById = createSelector([getRoot], state => state.byId);

export const getSites = createSelector([getRoot], state =>
  Array.from(state.byId.values())
);

export const getSitesFiltered = createSelector([getRoot], state =>
  Array.from(state.byIdFiltered.values())
);

/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Status = 'idle' | 'fetching' | 'success' | 'failure';

export type VolOffersCreateState = {
  status: Status;
};

export type VolOffersCreateAction = {
  volOfferId?: number | null;
  parentOfferId: number;
  volOfferTypeId: number;
  exclusiveQuantity?: number;
  status: string;
  startDate?: Date | string;
  endDate?: Date | string;
  priceByPacking: number;
  priceByLayer: number;
  priceByTrolley: number;
  voucherIds: number[];
  packingDistributionCompositionIds: number[];
  siteDeliveryPatternId: number;
  observation: string;
  index?: number;
  stockId?: number;
  productDescription?: string;
  productQualityDescription?: string;
};

export type VolOffersUpdateAction = {
  status: string;
  startDate?: string | null;
  endDate?: string | null;
  voucherIds: number[];
  volOfferIds: number[];
  siteDeliveryPatternId: number;
  observation: string;
  volOfferPercentage: number;
  priceByPacking?: number;
  priceByLayer?: number;
  priceByTrolley?: number;
};

const prefix = '@volOffersCreate';

export const initialState: VolOffersCreateState = {
  status: 'idle'
};

const volOffersCreate = createSlice({
  name: prefix,
  initialState,
  reducers: {
    create: (
      state: VolOffersCreateState,
      action: PayloadAction<VolOffersCreateAction[]>
    ) => {
      state.status = 'fetching';
    },
    update: (
      state: VolOffersCreateState,
      action: PayloadAction<VolOffersUpdateAction>
    ) => {},
    deleteSubOffer: (
      state: VolOffersCreateState,
      action: PayloadAction<number>
    ) => {}
  }
});
export const actions = volOffersCreate.actions;
export const reducer = volOffersCreate.reducer;

const caseReducers = volOffersCreate.caseReducers;
export const Types = Object.freeze({
  create: `${prefix}/${caseReducers.create.name}`,
  update: `${prefix}/${caseReducers.update.name}`,
  deleteSubOffer: `${prefix}/${caseReducers.deleteSubOffer.name}`
});

import * as M from '~/domain/model';

export const validateOrder = (
  item: any,
  index: number
): M.TableInputError[] => {
  const tableInputErrors: M.TableInputError[] = [];
  if (!item.packingCode) {
    tableInputErrors.push({
      tablerow: index,
      property: 'quantityPackage',
      message: `Campo de embalagem está em branco`
    });
  }

  if (!item.quantityPackage) {
    tableInputErrors.push({
      tablerow: index,
      property: 'quantityPackage',
      message: `Quantidade de embalagem do ${item.productDescription} - ${item.productQualityDescription} em branco`
    });
  }
  if (!item.quantityPerPackage) {
    tableInputErrors.push({
      tablerow: index,
      property: 'quantityPerPackage',
      message: `Quantidade por embalagem do ${item.productDescription} - ${item.productQualityDescription} em branco`
    });
  }
  if (!item.unitPrice) {
    tableInputErrors.push({
      tablerow: index,
      property: 'unitPrice',
      message: `Preço unitário do ${item.productDescription} - ${item.productQualityDescription} em branco`
    });
  }
  // if (!item.observationId1) {
  //   tableInputErrors.push({
  //     tablerow: index,
  //     property: 'observation',
  //     message: `Observação do ${item.productDescription} - ${item.productQualityDescription} em branco`
  //   });
  // }
  // if (!item.observationId2) {
  //   tableInputErrors.push({
  //     tablerow: index,
  //     property: 'observation',
  //     message: `Observação do ${item.productDescription} - ${item.productQualityDescription} em branco`
  //   });
  // }
  if (item.productQualityDescription === 'A2' && item.observation === '') {
    tableInputErrors.push({
      tablerow: index,
      property: 'observation',
      message: `Observação do ${item.productDescription} - ${item.productQualityDescription} em branco`
    });
  }
  return tableInputErrors;
};

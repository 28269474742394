import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import * as API from '~/domain/api';
import { OrderManagementStatusIdEnum } from '~/domain/enum/orderManagement.enum';
import * as M from '~/domain/model';
import { generateErrorMessage } from '~/helpers/util/error';
import * as autoServiceServices from '~/services/producer/AutoService';
import * as OrdersService from '~/services/producer/Order';
import * as orderManagementServices from '~/services/producer/OrderManagement';
import { AutoServiceState, actions } from '..';
import { alertRequest } from '../../alert/actions';

export const fetchOrders = createAsyncThunk(
  `autoService/fetchOrders`,
  async (params: M.AutoServiceFetchParams, { dispatch, rejectWithValue }) => {
    try {
      const response = await autoServiceServices.fetchOrdersRequest(params);
      return response;
    } catch (err: unknown) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao carregar pedidos.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchPendingOrdersReview = createAsyncThunk(
  `autoService/fetchPendingOrdersReview`,
  async (
    params: M.AutoServiceFetchPendingOrdersReviewParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response =
        await autoServiceServices.fetchPendingOrdersReviewRequest(params);
      return response;
    } catch (err: unknown) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao carregar pedidos.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchOrdersStocks = createAsyncThunk(
  `stocks/fetchOrdersStocks`,
  async (params: M.FindOrderStockParams, { dispatch, rejectWithValue }) => {
    try {
      const response = await OrdersService.fetchOrdersStocksRequest(params);
      if (!response.results?.length) {
        throw new Error(
          'Não existe Estoque cadastrado para o período informado!'
        );
      }
      console.log('fetchOrdersStocks > response:: ', response);
      const tableData: any = response.results.map((data, index) => {
        const packingDistributionCompositionIds: number[] = [];
        data.productPreference?.availablePackingDistributionCompositions?.forEach(
          (packing: API.AvailablePackingDistributionComposition) => {
            if (packing?.selected) {
              packingDistributionCompositionIds.push(packing.id);
            }
          }
        );
        return {
          packingId: data.productPreference
            ?.availablePackingDistributionCompositions
            ? data.productPreference
                ?.availablePackingDistributionCompositions[0]?.composition
                .packingId
            : undefined,
          packingCode: data.productPreference
            ?.availablePackingDistributionCompositions
            ? data.productPreference
                ?.availablePackingDistributionCompositions[0]?.composition
                .packingCode
            : undefined,
          quantityPerPackage: data.productPreference
            ?.availablePackingDistributionCompositions
            ? data.productPreference
                ?.availablePackingDistributionCompositions[0]?.composition
                .unitsPerPackage
            : undefined,
          packingDistributionCompositionIds: [],
          productQualityDescription: data.qualityDescription,
          stockId: data.stockId,
          periodDescription: data.periodDescription,
          productDescription: data.productDescription,
          productCode: data.productCode,
          productId: data.productId,
          productPreference: data.productPreference,
          index,
          key: index,
          useStockOnlyVol: data.isVolOnly,
          remainingQuantity: data.remainingQuantity,
          observationId1: '',
          observationId2: '',
          observationText: '',
          isSelected: false
        };
      });
      console.log('fetchOrdersStocks > tableData:: ', tableData);
      return tableData;
    } catch (err: any) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao carregar produtos.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const reproccessPendingOrdersReview = createAsyncThunk(
  `autoService/reproccessPendingOrdersReview`,
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const storeState: any = getState();
      const { pendingOrders, pendingOrdersSelectedKeys } =
        storeState.autoService as AutoServiceState;
      const ordersToReprocess = pendingOrders.filter(order =>
        pendingOrdersSelectedKeys.includes(order.id)
      );

      const orderTest = ordersToReprocess.map(order => {
        console.log('order:: ', order);
        return {
          lineId: order.id,
          qualityId: order.qualityId,
          packingId: order.packingId,
          packingQuantity: order.packingQuantity,
          unitQuantity: order.unitQuantity,
          price: order.price,
          productId: order.productId,
          deliveryDate: order.deliveryDate,
          billingDate: order.billingDate,
          siteId: order.siteId,
          fromPendingOrderScreen: true
        };
      });

      if (
        ordersToReprocess.some(i =>
          moment(i.deliveryDate).isAfter(moment().add(30, 'days'))
        )
      ) {
        dispatch(
          alertRequest(
            'Data Comercial é superior a 30 dias em relação à data atual!',
            'warning'
          )
        );
      }
      const response = await autoServiceServices.revalidateOrdersRequest({
        createNewOrder: true,
        orderToCreate: 0,
        lines: orderTest
      });
      dispatch(alertRequest('Pedidos processados com sucesso!', 'success'));
      return response;
    } catch (err: unknown) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao processar pedido.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const editOrder = createAsyncThunk(
  `autoService/editOrder`,
  async (
    params: M.AutoServiceGenerateRepublishOrderParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await autoServiceServices.republishOrderRequest(params);
      dispatch(alertRequest('Pedido editado com sucesso!', 'success'));
      return response;
    } catch (err: unknown) {
      const errorMessage = generateErrorMessage(err, 'Erro ao editar pedido.');
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const republishOrder = createAsyncThunk(
  `autoService/republishOrder`,
  async (
    params: M.AutoServiceGenerateRepublishOrderParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await autoServiceServices.republishOrderRequest(params);
      dispatch(alertRequest('Pedido republicado com sucesso!', 'success'));
      return response;
    } catch (err: unknown) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao republicar pedido.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const republishCancelled = createAsyncThunk(
  `autoService/republishCancelled`,
  async (
    params: M.AutoServiceGenerateRepublishCancelledParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await autoServiceServices.republishCancelledRequest(
        params
      );
      dispatch(alertRequest('Republicado com sucesso!', 'success'));
      return response;
    } catch (err: unknown) {
      const errorMessage = generateErrorMessage(err, 'Erro ao republicar!');
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const MassOrderRepublish = createAsyncThunk(
  'autoService/MassOrderRepublish',
  async (params: number[], { dispatch, rejectWithValue }) => {
    try {
      const res = await autoServiceServices.massRepublishOrders(params);
      dispatch(alertRequest(res, 'warning'));
      return res;
    } catch (err) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao republicar pedido.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const importOrders = createAsyncThunk(
  `autoService/importOrders`,
  async (
    params: M.AutoServiceImportOrdersParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await autoServiceServices.importOrdersRequest(params);
      dispatch(
        alertRequest(
          'Importação do arquivo finalizada. Verifique se houve inconsistências para prosseguir com a geração dos pedidos!',
          'success'
        )
      );
      return response;
    } catch (err: unknown) {
      console.log('err', err);
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao importar pedidos.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const revalidateOrders = createAsyncThunk(
  `autoService/revalidateOrders`,
  async (
    params: M.AutoServiceGenerateRevalidateOrdersParams,
    /* eslint-disable @typescript-eslint/no-unused-vars */
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      if (
        params.lines.some(i =>
          moment(i.deliveryDate).isAfter(moment().add(30, 'days'))
        )
      ) {
        dispatch(
          alertRequest(
            'Data Comercial é superior a 30 dias em relação à data atual!',
            'warning'
          )
        );
      }
      console.log('Parametros Revalidate', params);

      const response = await autoServiceServices.revalidateOrdersRequest(
        params
      );
      if (
        response.some(i => i.statusId === OrderManagementStatusIdEnum.ERROR)
      ) {
        dispatch(
          alertRequest(
            'Validação finalizada. Verifique se houve inconsistências para prosseguir com a geração dos pedidos!',
            'success'
          )
        );
        return response;
      }
      dispatch(alertRequest('Pedidos validados com sucesso!', 'success'));
      return response;
    } catch (err: unknown) {
      console.log('err', err);
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao validar pedidos.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const processOrders = createAsyncThunk(
  `autoService/processOrders`,
  async (
    params: M.AutoServiceProcessOrdersParams,
    /* eslint-disable @typescript-eslint/no-unused-vars */
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      // if (params.linesIds.some(i =>
      //   moment(i.lineIds).isAfter(moment().add(30, 'days'))
      // )) {
      // dispatch(
      //   alertRequest(
      //     'Data Comercial é superior a 30 dias em relação à data atual!',
      //     'warning'
      //   ));
      // }
      console.log('Parametros Process', params);
      if (params) {
        const response = await autoServiceServices.processOrdersRequest(params);
        dispatch(alertRequest('Pedidos registrados com sucesso!', 'success'));
        return response;
      }
      throw new Error('Nenhum pedido para processar.');
    } catch (err: unknown) {
      const errorMessage = generateErrorMessage(err, 'Erro ao gerar pedidos.');
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchOrderPublishers = createAsyncThunk(
  `autoService/fetchOrderPublishers`,
  async (userId: number, { dispatch, rejectWithValue }) => {
    try {
      const publishers =
        await orderManagementServices.fetchOrderPublishersRequest();
      dispatch(
        actions.setPublishedById(
          publishers?.find(publisher => publisher.id === userId)?.id ??
            Number.NaN
        )
      );
      return publishers;
    } catch (err: unknown) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao buscar usuários.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const deleteColumnArchive = createAsyncThunk(
  `autoService/remove-line`,
  async (params: number, { dispatch, rejectWithValue }) => {
    try {
      await autoServiceServices.deleteColumnArchive(params);
      dispatch(alertRequest('Item removido com sucesso!', 'success'));
      return params;
    } catch (err: unknown) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao editar dashboard.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

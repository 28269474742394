import { createSelector } from 'reselect';
import { ProductCategoriesState } from '.';

const getRoot = ({
  productCategories
}: {
  productCategories: ProductCategoriesState;
}) => {
  return productCategories;
};

export const getProductCategoriesState = createSelector(
  [getRoot],
  state => state
);

import * as API from '../api';

export default class ProductSimple {
  private readonly json: API.ProductSimple;
  public readonly fullName: string;
  public readonly id: number;
  public readonly simpleName: string;

  private getFullName(): string {
    return this.json.fullName ?? '';
  }

  private getId(): number {
    return this.json.id ?? Number.NaN;
  }

  private getSimpleName(): string {
    return this.json.simpleName ?? '';
  }

  constructor(json: API.ProductSimple) {
    this.json = json;
    this.id = this.getId();
    this.fullName = this.getFullName();
    this.simpleName = this.getSimpleName();
  }
}

import { Button, FormInstance, Table } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { useCallback, useContext, useMemo } from 'react';
import UpdateConsolidateds from '~/@core/application/useCases/rfidReports/inventory/updateConsolidateds';
import Consolidated from '~/@core/domain/model/rfidReports/Consolidated';
import { DebouncedInput } from '~/components/atoms/DebouncedInput';
import { DividerFrame, Frame } from '~/components/Globals';
import { globalContext } from '~/context/GlobalContext';
import useConsolidatedStore from '~/zustand/rfidReports/inventory/consolidated-state';

interface Props {
  form: FormInstance<any>;
}
export default function TableInventoryConsolidated(props: Props) {
  const { handlePromiseUseCase, activeAlert } = useContext(globalContext);

  const {
    consolidateds,
    addConsolidateds,
    consolidatedPagination,
    handleConsolidatedPagination
  } = useConsolidatedStore();
  const { form } = props;

  const focusControlledColumns = useMemo(() => {
    return ['quantity'];
  }, []);

  const changeProductBarDescription = useCallback(
    (
      valueParam: number,
      property: string,
      indexTable: number,
      areaName: string
    ) => {
      let value: string | number = 0;
      if (typeof valueParam === 'string') {
        value = valueParam;
      }
      if (typeof valueParam !== 'string' && !Number.isNaN(valueParam)) {
        value = valueParam;
      }
      const newConsolidated = consolidateds.map((newArea, indexMap) => {
        if (indexMap === indexTable) {
          return {
            ...newArea,
            [property]: newArea.areas.map((area: any) => {
              if (area.name === areaName) {
                return {
                  ...area,
                  quantity: value
                };
              }
              return {
                ...area
              };
            }),
            changed: true
          };
        }
        return {
          ...newArea
        };
      });
      addConsolidateds(newConsolidated);
    },
    [consolidateds, addConsolidateds]
  );

  const consolid = useCallback(
    (params: Consolidated) => {
      const useCase = new UpdateConsolidateds();

      handlePromiseUseCase<AxiosResponse<void>, AxiosError>(
        useCase.execute(params),
        () => {
          activeAlert({
            message: 'Status atualizado com sucesso',
            type: 'success',
            timeout: 5000
          });
          form.submit();
        },
        error => {
          activeAlert({
            message: JSON.stringify(
              error.response?.data
                ? error.response?.data
                : 'Houve um erro inesperado!'
            ),
            type: 'error',
            timeout: 5000
          });
        }
      );
    },
    [activeAlert, form, handlePromiseUseCase]
  );

  return (
    <Frame ghost accordion defaultActiveKey="1">
      <Frame.Panel
        header={
          <DividerFrame orientation="left">
            Consolidação de Inventário
          </DividerFrame>
        }
        key="1"
      >
        <Table
          dataSource={consolidateds}
          pagination={{
            pageSize: consolidatedPagination.pageSize,
            current: consolidatedPagination.current,
            showSizeChanger: true,
            total: consolidatedPagination.total,
            onChange(page, pageSize) {
              handleConsolidatedPagination({
                ...consolidatedPagination,
                current: page,
                pageSize
              });
              form.submit();
            }
          }}
        >
          <Table.Column dataIndex="modificationDate" title="Data" />
          <Table.Column dataIndex="type" title="Tipo Material" />
          <Table.Column
            dataIndex="areas"
            key="areas"
            title="Áreas"
            align="center"
            render={(_, consolidated: Consolidated, index: number) => {
              const inputId = `idToFocus-${focusControlledColumns.indexOf(
                'observation'
              )}-${index}`;
              return consolidated.areas.map(item =>
                consolidated.type.indexOf('001') === -1 &&
                consolidated.status === '0' ? (
                  <td>
                    <tr>
                      <span style={{ alignSelf: 'center', marginLeft: '50px' }}>
                        <b>{item.name}</b>
                      </span>
                    </tr>
                    <tr>
                      <DebouncedInput
                        id={inputId}
                        style={{ width: '40px', marginLeft: '50px' }}
                        value={item.quantity}
                        maxLength={4}
                        onChange={value => {
                          changeProductBarDescription(
                            value ?? '',
                            'areas',
                            index,
                            item.name
                          );
                        }}
                      />{' '}
                      <td>/ {item.expected}</td>
                    </tr>
                  </td>
                ) : (
                  <span style={{ marginLeft: '40px' }}>
                    {' '}
                    <tr>
                      <b>{item.name}</b>
                    </tr>
                    <tr>{`${item.quantity} / ${item.expected}`}</tr>
                  </span>
                )
              );
            }}
          />

          <Table.Column dataIndex="results" title="Resultado/Esperado" />
          <Table.Column dataIndex="balance" title="Saldo" />
          <Table.Column
            dataIndex="status"
            title="Status"
            render={(_, consolidated: Consolidated) =>
              consolidated.status === '0' ? (
                <Button
                  style={{
                    border: 'none',
                    backgroundColor: '#F78E1E',
                    color: '#ffffff'
                  }}
                  htmlType="submit"
                  onClick={() => consolid(consolidated)}
                >
                  Consolidar
                </Button>
              ) : (
                <Button
                  disabled
                  style={{
                    border: 'none',
                    backgroundColor: 'gray',
                    color: '#ffffff'
                  }}
                >
                  Consolidado
                </Button>
              )
            }
          />
        </Table>
      </Frame.Panel>
    </Frame>
  );
}

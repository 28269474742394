import { AxiosResponse } from 'axios';
import { LogParams } from '~/domain/model/Logs/Logs';
import api from '../api';
import { endpoints } from '~/constants/endpoints';

export const setLog = async (params: LogParams) => {
  const response = await api.put<void, AxiosResponse<LogParams>>(
    endpoints.log,
    params
  );
  return response.data;
};

import { call, fork, put, takeLatest } from 'redux-saga/effects';

import { actions, Create, FetchBannerUrl, Types } from './duck';
import * as BannerService from '~/store/modules/_core/banners/service';
import { Action } from '~/helpers/util/reducers';
import { APIError } from '~/domain/api/errors';
import * as BannerServiceProducer from '~/services/producer/Banner';

export function* watchCreateSaga(action: Action<Create>) {
  yield fork(createSaga, action.payload);
}

export function* watchFetchBannerUrl(action: Action<FetchBannerUrl>) {
  yield fork(fetchBannerUrlById, action.payload.bannerId);
}

export function* createSaga(param: Create) {
  try {
    const { description, bannerTypeId, bannerImage } = param;
    yield call(
      BannerServiceProducer.create,
      description,
      bannerTypeId,
      bannerImage
    );
    yield put(actions.createSuccess());
  } catch (error: any) {
    if (error instanceof APIError) {
      yield put(actions.createFailure({ errorMessage: error.message }));
    } else {
      yield put(
        actions.createFailure({ errorMessage: error?.response?.data[0] })
      );
    }
  }
}

export function* fetchBannerUrlById(bannerId: number): any {
  try {
    const base64String: string = yield call(
      BannerService.fetchBannerUrlById,
      bannerId
    );
    yield put(
      actions.fetchBannerUrlSuccess({
        bannerImage: base64String
      })
    );
  } catch (err) {
    yield put(actions.fetchBannerUrlFailure());
  }
}

export default [
  takeLatest(Types.CREATE_INIT, watchCreateSaga),
  takeLatest(Types.FETCH_BANNER_URL, watchFetchBannerUrl)
];

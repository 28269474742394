// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderTabEnum } from '~/domain/enum/orders.enum';
import * as HarvestingOrderCalls from './apiCalls';

export const prefix = 'orders';

type HarvestingOrderProductProps = {
  id: number;
  origin: string;
  productId: number;
  productCode: string;
  productDescription: string;
  productQuality: string;
  packingId: number;
  packingCode: string;
  packingName: string;
  packingQuantity: number;
  unitQuantity: number;
  quantity: number;
  observation: string;
  internalObservation: string;
};

export type HarvestingOrdersByCustomer = {
  customerId: number;
  customerCode: string;
  customerName: string;
  disposablePackingQuantity: number;
  returnablePackingQuantity: number;
  totalPacking: number;
  totalQuantity: number;
  products: HarvestingOrderProductProps[];
};

type HarvestingOrderCustomerProps = {
  id: number;
  origin: string;
  customerId: number;
  customerCode: string;
  customerName: string;
  packingId: number;
  packingCode: string;
  packingName: string;
  packingQuantity: number;
  unitQuantity: number;
  quantity: number;
  observation: string;
  internalObservation: string;
};

export type HarvestingOrdersByProducts = {
  id: number;
  productId: number;
  productCode: string;
  productDescription: string;
  productQuality: string;
  disposablePackingQuantity: number;
  returnablePackingQuantity: number;
  totalPacking: number;
  totalQuantity: number;
  customers: HarvestingOrderCustomerProps[];
};

export interface OrdersState {
  activeKey: OrderTabEnum;
  harvestingOrdersByProducts: HarvestingOrdersByProducts[];
  totalHarvestingOrdersByProducts: number;
  harvestingOrdersByClients: HarvestingOrdersByCustomer[];
  totalHarvestingOrdersByClients: number;
  loading: boolean;
  printListGenerationLoading: boolean;
  showEditObservationModal: boolean;
  showTableByClients: boolean;
  harvestingOrdersPrint: any[];
  totalHarvestingOrdersPrint: number;
  keySelected: React.Key[];
}

const initialState: OrdersState = {
  activeKey: OrderTabEnum.order,
  harvestingOrdersByProducts: [],
  totalHarvestingOrdersByProducts: 0,
  harvestingOrdersByClients: [],
  totalHarvestingOrdersByClients: 0,
  loading: false,
  printListGenerationLoading: false,
  showEditObservationModal: false,
  showTableByClients: false,
  harvestingOrdersPrint: [],
  totalHarvestingOrdersPrint: 0,
  keySelected: []
};
const harvestingOrdersSlice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: () => initialState,
    setShowEditObservationModal: (state, action: PayloadAction<boolean>) => {
      state.showEditObservationModal = action.payload;
    },

    setShowTableByClients: (state, action: PayloadAction<boolean>) => {
      state.showTableByClients = action.payload;
      state.harvestingOrdersByClients = [];
      state.harvestingOrdersByProducts = [];
    },

    setInternalObservation: (
      state,
      {
        payload
      }: { payload: { rowId: number; itemId: number; observation: string } }
    ) => {
      const { rowId, itemId, observation } = payload;

      if (state.showTableByClients) {
        const rowIndex = state.harvestingOrdersByClients.findIndex(
          order => order.customerId === rowId
        );

        const itemIndex = state.harvestingOrdersByClients[
          Number(rowIndex)
        ].products?.findIndex(item => item.id === itemId);

        state.harvestingOrdersByClients[rowIndex].products[
          itemIndex
        ].internalObservation = observation;
      } else {
        const rowIndex = state.harvestingOrdersByProducts.findIndex(
          order => order.id === rowId
        );

        const itemIndex = state.harvestingOrdersByProducts[
          Number(rowIndex)
        ].customers.findIndex(item => item.id === itemId);

        state.harvestingOrdersByProducts[rowIndex].customers[
          itemIndex
        ].internalObservation = observation;
      }
    },

    setTablePropertyValue: (
      state,
      action: PayloadAction<{
        value: any;
        property: string;
        id: number;
        parentId: number;
      }>
    ) => {
      const { payload } = action;

      const updatedTableData: any[] = state.harvestingOrdersByProducts?.map(
        (item: any) => {
          const updatedExpandRows: any[] = item.expandRows?.map((row: any) => {
            if (row.id === payload.id) {
              const updatedRow = row;

              updatedRow[payload.property] = payload.value;

              return updatedRow;
            }
            return row;
          });

          return { ...item, expandRows: updatedExpandRows };
        }
      );

      state.harvestingOrdersByProducts = updatedTableData;
    }
  },
  extraReducers(builder) {
    builder.addCase(
      HarvestingOrderCalls.fetchHarvestingOrdersByCustomer.pending,
      state => {
        state.loading = true;
        state.harvestingOrdersByClients = [];
        state.totalHarvestingOrdersByClients = 0;
      }
    );
    builder.addCase(
      HarvestingOrderCalls.fetchHarvestingOrdersByCustomer.fulfilled,
      (state, action) => {
        const { results, totalCount } = action.payload;

        state.harvestingOrdersByClients = results;
        state.totalHarvestingOrdersByClients = totalCount;
        state.loading = false;
      }
    );
    builder.addCase(
      HarvestingOrderCalls.fetchHarvestingOrdersByCustomer.rejected,
      state => {
        state.loading = false;
      }
    );

    builder.addCase(
      HarvestingOrderCalls.fetchHarvestingOrdersByProduct.pending,
      state => {
        state.loading = true;
        state.harvestingOrdersByProducts = [];
        state.totalHarvestingOrdersByProducts = 0;
      }
    );

    builder.addCase(
      HarvestingOrderCalls.fetchHarvestingOrdersByProduct.fulfilled,
      (state, action) => {
        const { results, totalCount } = action.payload;

        state.harvestingOrdersByProducts = results;
        state.totalHarvestingOrdersByProducts = totalCount;
        state.loading = false;
      }
    );

    builder.addCase(
      HarvestingOrderCalls.fetchHarvestingOrdersByProduct.rejected,
      state => {
        state.loading = false;
      }
    );

    builder.addCase(HarvestingOrderCalls.generatePrintList.pending, state => {
      state.printListGenerationLoading = true;
    });
    builder.addCase(HarvestingOrderCalls.generatePrintList.fulfilled, state => {
      state.printListGenerationLoading = false;
    });
    builder.addCase(HarvestingOrderCalls.generatePrintList.rejected, state => {
      state.printListGenerationLoading = false;
    });

    builder.addCase(
      HarvestingOrderCalls.updateHarvestingOrders.pending,
      state => {
        state.loading = true;
      }
    );
    builder.addCase(
      HarvestingOrderCalls.updateHarvestingOrders.fulfilled,
      (state, _action) => {
        // const { results, totalCount } = action.payload;
        // state.orders = state.orders.map(orderState => {
        //   const orderInResponse = results.find(
        //     orderResponse =>
        //       orderResponse.saleContactId === orderState.saleContactId
        //   );
        //   if (orderInResponse) {
        //     return { ...orderInResponse };
        //   }

        //   return { ...orderState };
        // });
        // state.totalOrders = totalCount;
        state.loading = false;
      }
    );
    builder.addCase(
      HarvestingOrderCalls.updateHarvestingOrders.rejected,
      state => {
        state.loading = false;
      }
    );

    builder.addCase(HarvestingOrderCalls.fetchAllPrintData.pending, state => {
      state.loading = true;
      state.harvestingOrdersPrint = [];
      state.totalHarvestingOrdersPrint = 0;
    });
    builder.addCase(
      HarvestingOrderCalls.fetchAllPrintData.fulfilled,
      (state, action) => {
        const response = action.payload;

        state.harvestingOrdersPrint = response;
        state.totalHarvestingOrdersPrint = response.length;
        state.loading = false;
      }
    );
    builder.addCase(HarvestingOrderCalls.fetchAllPrintData.rejected, state => {
      state.loading = false;
    });

    builder.addCase(HarvestingOrderCalls.DeletePrintByIds.pending, state => {
      state.loading = true;
      state.keySelected = [];
    });
    builder.addCase(
      HarvestingOrderCalls.DeletePrintByIds.fulfilled,
      (state, _) => {
        state.loading = false;
        state.keySelected = [];
      }
    );
    builder.addCase(HarvestingOrderCalls.DeletePrintByIds.rejected, state => {
      state.loading = false;
    });
  }
});

export const actions = harvestingOrdersSlice.actions;
export const reducer = harvestingOrdersSlice.reducer;

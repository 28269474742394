import produce from 'immer';

interface StateType {
  data: any[];
  list: any[];
  loading: boolean;
}

const INITIAL_STATE: StateType = {
  data: [],
  list: [],
  loading: false
};

export default function attachment(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case '@attachment/LIST_REQUEST':
    case '@attachment/FULL_LIST_REQUEST':
    case '@attachment/READ_REQUEST':
    case '@attachment/DELETE_REQUEST':
      return produce(state, draft => {
        draft.loading = true;
      });

    case '@attachment/ADD_REQUEST':
      return produce(state, draft => {
        draft.loading = false;
        draft.data.push(action.payload.data);
      });

    case '@attachment/LIST_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.data = action.payload.list;
      });

    case '@attachment/FULL_LIST_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
        draft.list = action.payload.list;
      });

    case '@attachment/ADD_SUCCESS':
    case '@attachment/DELETE_SUCCESS':
      return produce(state, draft => {
        draft.loading = false;
      });

    case '@attachment/CLEAR_ALL':
      return produce(state, draft => {
        draft.loading = false;
        draft.data = [];
      });

    case '@attachment/LIST_FAILURE':
    case '@attachment/FULL_LIST_FAILURE':
    case '@attachment/ADD_FAILURE':
    case '@attachment/READ_FAILURE':
    case '@attachment/DELETE_FAILURE':
      return produce(state, draft => {
        draft.loading = false;
      });

    default:
      return state;
  }
}

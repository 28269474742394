import { ProductionPlanningEventWeekState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.core
    .productionPlanningEventWeeks as ProductionPlanningEventWeekState;
};

export const getProductionPlanningEventWeekById = createSelector(
  [getRoot],
  state => {
    return state.productionPlanningEventWeekById;
  }
);

export const getIsCleared = createSelector(
  [getRoot],
  state => state.status === 'cleared'
);

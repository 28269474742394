import { createSlice } from '@reduxjs/toolkit';
import { QualityColorEnum } from '~/domain/enum/stockModalTabs.enum';
import * as M from '~/domain/model';
import * as tagPrintingApi from './apiCalls';

export type ProductProps = {
  id: number;
  origin: string;
  productId: number;
  productCode: string;
  productDescription: string;
  productQuality: QualityColorEnum;
  packingId: number;
  packingCode: string;
  packingName: string;
  packingQuantity: number;
  unitQuantity: number;
  quantity: number;
  observation: string;
  internalObservation: string;
};

export type TagsByCustomerProps = {
  customerId: number;
  customerCode: string;
  customerName: string;
  disposablePackingQuantity: number;
  returnablePackingQuantity: number;
  totalPacking: number;
  totalQuantity: number;
  products: ProductProps[];
};

export type CustomerProps = {
  id: number;
  origin: string;
  customerId: number;
  customerCode: string;
  customerName: string;
  packingId: number;
  packingCode: string;
  packingName: string;
  packingQuantity: number;
  unitQuantity: number;
  quantity: number;
  observation: string;
  internalObservation: string;
};

export type TagsByProductProps = {
  id: number;
  productId: number;
  productCode: string;
  productDescription: string;
  productQuality: string;
  disposablePackingQuantity: number;
  returnablePackingQuantity: number;
  totalPacking: number;
  totalQuantity: number;
  customers: CustomerProps[];
};

export type TagsByProduct = {
  tags: TagsByProductProps[];
  totalTags: number;
  pagination: M.DefaultPagination;
};

export type TagsByCustomer = {
  tags: TagsByCustomerProps[];
  totalTags: number;
  pagination: M.DefaultPagination;
};

export type OrderProps = {
  id: number;
  orderId: number;
  productCode: string;
  productDescription: string;
  customerCode: string;
  customerName: string;
  quality: string;
  quantityPacking: number;
  unitQuantity: number;
  quantity: number;
};

export type PrintedItemProps = {
  id: number;
  printedDate: string;
  quantity: number;
  userId: number;
  userName: string;
  siteId: number;
  siteCode: string;
  siteName: string;
  orders: OrderProps[];
};

export type PrintedListProps = {
  printedItems: PrintedItemProps[];
  totalPrintedItems: number;
  pagination: M.DefaultPagination;
};

export interface TagPrintingState {
  tagsByProduct: TagsByProduct;
  tagsByCustomer: TagsByCustomer;
  printedList: PrintedListProps;
  loading: boolean;
}

const initialState = {
  tagsByProduct: {
    tags: [],
    pagination: { PageNumber: 1, PageSize: 100 },
    totalTags: 0
  },
  tagsByCustomer: {
    tags: [],
    pagination: { PageNumber: 1, PageSize: 100 },
    totalTags: 0
  },
  printedList: {
    printedItems: [],
    pagination: { PageNumber: 1, PageSize: 10 },
    totalPrintedItems: 0
  },
  loading: false
};

const tagPrintingSlice = createSlice({
  name: 'tag-print',
  initialState,
  reducers: {
    clear: () => initialState,

    setPrintedListPagination: (state, { payload }) => {
      state.printedList.pagination = {
        PageNumber: payload.PageNumber,
        PageSize: payload.PageSize
      };
    },

    setInternalObservation: (
      state,
      {
        payload
      }: {
        payload: {
          itemId: number;
          observation: string;
          rowId: number;
          viewByClient: boolean;
        };
      }
    ) => {
      const { itemId, observation, rowId, viewByClient } = payload;

      if (viewByClient) {
        const index = state.tagsByCustomer.tags.findIndex(
          (tag: TagsByCustomerProps) => tag.customerId === rowId
        );

        const expandendRowIndex = (
          state.tagsByCustomer.tags[index] as TagsByCustomerProps
        ).products.findIndex(c => c.id === itemId);

        (state.tagsByCustomer.tags[index] as TagsByCustomerProps).products[
          expandendRowIndex
        ].internalObservation = observation;
      } else {
        const index = state.tagsByProduct.tags.findIndex(
          (tag: TagsByProductProps) => tag.id === rowId
        );

        const expandendRowIndex = (
          state.tagsByProduct.tags[index] as TagsByProductProps
        ).customers.findIndex(c => c.id === itemId);

        (state.tagsByProduct.tags[index] as TagsByProductProps).customers[
          expandendRowIndex
        ].internalObservation = observation;
      }
    }
  },
  extraReducers: builder => {
    // Fetch tags by product view
    builder.addCase(tagPrintingApi.fetchTagsByProductView.pending, state => {
      state.loading = true;
      state.tagsByProduct.tags = [];
      state.tagsByProduct.totalTags = 0;
      state.tagsByCustomer.tags = [];
      state.tagsByCustomer.totalTags = 0;
    });
    builder.addCase(
      tagPrintingApi.fetchTagsByProductView.fulfilled,
      (state, { payload }) => {
        const { results, totalCount } = payload;
        state.loading = false;
        state.tagsByProduct.tags = results;
        state.tagsByProduct.totalTags = totalCount;
      }
    );
    builder.addCase(tagPrintingApi.fetchTagsByProductView.rejected, state => {
      state.loading = false;
    });

    // Fetch tags by customer view
    builder.addCase(tagPrintingApi.fetchTagsByCustomerView.pending, state => {
      state.loading = true;
      state.tagsByProduct.tags = [];
      state.tagsByProduct.totalTags = 0;
      state.tagsByCustomer.tags = [];
      state.tagsByCustomer.totalTags = 0;
    });
    builder.addCase(
      tagPrintingApi.fetchTagsByCustomerView.fulfilled,
      (state, { payload }) => {
        const { results, totalCount } = payload;
        state.loading = false;
        state.tagsByCustomer.tags = results;
        state.tagsByCustomer.totalTags = totalCount;
      }
    );
    builder.addCase(tagPrintingApi.fetchTagsByCustomerView.rejected, state => {
      state.loading = false;
    });

    // Fetch Printed List
    builder.addCase(tagPrintingApi.fetchPrintedList.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      tagPrintingApi.fetchPrintedList.fulfilled,
      (state, { payload }) => {
        const { results, totalCount } = payload;
        state.loading = false;
        state.printedList.printedItems = results;
        state.printedList.totalPrintedItems = totalCount;
      }
    );
    builder.addCase(tagPrintingApi.fetchPrintedList.rejected, state => {
      state.loading = false;
    });

    // Update orders
    builder.addCase(tagPrintingApi.updateOrders.pending, state => {
      state.loading = true;
    });
    builder.addCase(tagPrintingApi.updateOrders.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(tagPrintingApi.updateOrders.rejected, state => {
      state.loading = false;
    });
  }
});

export const actions = tagPrintingSlice.actions;
export const reducer = tagPrintingSlice.reducer;

import { Moment } from 'moment';
import Stock from '~/@core/domain/model/stock/Stocks';
import { usFormat } from '~/helpers/util';
import FilterCreateGfp from '../../filters/gfp/FilterCreateGfp';
import { MapStockSelectedToGfpItems } from './MappingKlokGfpItem';

export default interface FormFilterCreateGfpModel {
  emissionDate: Moment;
  outDate: Moment;
  commercialDate: Moment;
  transporterId: number;
  partitionQuantity: number;
  pickupLocation?: string;
  siteId: number;
  multiple?: number;
  isKlok?: boolean;
  gfpItems: Stock[];
  observation?: string;
}

export class MapFormToFilter {
  static toFilter(
    formData: FormFilterCreateGfpModel,
    siteIdSelected: number
  ): FilterCreateGfp {
    const filter: FilterCreateGfp = {
      emissionDate: usFormat(formData.emissionDate).toString(),
      outDate: usFormat(formData.outDate).toString(),
      commercialDate: usFormat(formData.commercialDate).toString(),
      transporterId: formData.transporterId,
      partitionQuantity: formData.partitionQuantity,
      pickupLocation: formData.pickupLocation,
      siteId: siteIdSelected,
      multiple: formData.multiple,
      observation: formData.observation,
      isKlok: true,
      items: MapStockSelectedToGfpItems.toGfpItems(formData.gfpItems)
    };
    return filter;
  }
}

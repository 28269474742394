import produce from 'immer';
import { PriceAlert, HistoryPriceAlert } from '~/domain/model';
import { createCreator, createReducer, Reducer } from '~/helpers/util/reducers';

export type Status = 'cleared' | 'updated';

export type PriceAlertState = {
  priceAlertByID: Map<string, PriceAlert>;
  priceAlertHistoryById: Map<string, HistoryPriceAlert>;
  status: Status;
};

export type Update = {
  priceAlertByID?: Map<string, PriceAlert>;
  priceAlertHistoryById?: Map<string, HistoryPriceAlert>;
};

const prefix = '@priceAlert';

export const Types = Object.freeze({
  CLEAR: `${prefix}/CLEAR`,
  CLEAR_PRICE_ALERT_HISTORY: `${prefix}/CLEAR_PRICE_ALERT_HISTORY`,
  UPDATE: `${prefix}/UPDATE`
});

export const initialState: PriceAlertState = {
  priceAlertByID: new Map(),
  priceAlertHistoryById: new Map(),
  status: 'cleared'
};

const clearReducer: Reducer<PriceAlertState, void> = state => {
  return produce(state, draft => {
    draft.priceAlertByID = initialState.priceAlertByID;
    draft.priceAlertHistoryById = initialState.priceAlertHistoryById;
    draft.status = initialState.status;
  });
};

const clearPriceAlertHistoryReducer: Reducer<PriceAlertState, void> = state => {
  return produce(state, draft => {
    draft.priceAlertHistoryById = initialState.priceAlertHistoryById;
  });
};

const updateReducer: Reducer<PriceAlertState, Update> = (state, action) => {
  return produce(state, draft => {
    if (action.priceAlertByID) {
      draft.priceAlertByID = action.priceAlertByID as Map<string, PriceAlert>;
    }

    if (action.priceAlertHistoryById) {
      draft.priceAlertHistoryById = action.priceAlertHistoryById as Map<
        string,
        HistoryPriceAlert
      >;
    }
  });
};

export const actions = Object.freeze({
  clear: createCreator<void>(Types.CLEAR),
  clearPriceAlertHistory: createCreator<void>(Types.CLEAR_PRICE_ALERT_HISTORY),
  update: createCreator<Update>(Types.UPDATE)
});

export const reducer = createReducer(initialState, {
  [Types.CLEAR]: clearReducer,
  [Types.CLEAR_PRICE_ALERT_HISTORY]: clearPriceAlertHistoryReducer,
  [Types.UPDATE]: updateReducer
});

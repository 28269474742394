import { all } from 'redux-saga/effects';

import alert from './alert/sagas';
import property from './property/sagas';
import site from './site/sagas';
import siteSimpleList from './siteSimpleList/sagas';
import tabs from './tabs/sagas';
import section from './section/sagas';
import propertyLocal from './propertyLocal/sagas';
import localization from './localization/sagas';
import productionPlan from './productionPlan/sagas';
import packing from './packing/sagas';
import stock from './stock/sagas';
import productionAreaUnit from './productionAreaUnit/sagas';
import productionLocalType from './productionLocalType/sagas';
import product from './product/sagas';
import { sagas as ui } from './_ui';
import competency from './competency/sagas';
import partnership from './partnership/sagas';
import specialDate from './specialDate/sagas';
import productPropertyAttachmentTypes from './productPropertyAttachmentTypes/sagas';
import attachment from './attachment/sagas';
import dates from './dates/sagas';
import propertyExport from './propertyExport/sagas';
import unvailableDates from './unavailableDates/sagas';

export default function* rootSaga(): any {
  return yield all([
    ...ui,
    alert,
    competency,
    tabs,
    property,
    site,
    siteSimpleList,
    section,
    propertyLocal,
    localization,
    productionPlan,
    packing,
    stock,
    productionAreaUnit,
    productionLocalType,
    product,
    partnership,
    specialDate,
    productPropertyAttachmentTypes,
    attachment,
    dates,
    propertyExport,
    unvailableDates
  ]);
}

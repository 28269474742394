/* eslint-disable no-restricted-syntax */
import { call, put, select } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import service from '~/services/api';
import * as API from '~/domain/api';

import { actions } from './duck';
import { actions as uiActions } from '../../_ui';
import { ProductPreference } from '~/domain/model';
import { FetchPreferencesAction } from '~/store/modules/_ui/productPreferencesList/duck';
import {
  ProductPreferencesCreateAction,
  ProductPreferencesUpdateAction
} from '../../_ui/productPreferences-create/duck';
import PackingDistributionComposition from '~/domain/model/PackingDistributionComposition';
import { getPagination } from '../../_ui/productPreferencesList/selectors';

export function* findMany(payload: FetchPreferencesAction): any {
  let url = `/product-preference?`;
  const entries = Object.entries(payload);

  for (const [key, value] of entries) {
    if (value) {
      url += `Data.${key}=${value}&`;
    }
  }

  const pagination = yield select(getPagination);
  if (pagination) {
    url += `&PageSize=${pagination.pageSize}&PageNumber=${pagination.pageNumber}`;
  }
  const response: AxiosResponse = yield call(service.get, url);
  const apiResponse = response.data as API.DefaultResponse;
  const results = apiResponse.results as API.ProductPreferences[];
  const productPreferencesByID = new Map(
    results.map((value: API.ProductPreferences) => [
      String(value.productId),
      new ProductPreference(value)
    ])
  );
  yield put(
    uiActions.productpreferencesList.setPagination({
      pagination: {
        totalCount: apiResponse.totalCount,
        pageNumber: pagination.pageNumber,
        pageSize: pagination.pageSize
      }
    })
  );
  yield put(actions.update({ productPreferencesByID }));
}
export function* findById(payload: number) {
  const URL = `/product-preference/${payload}`;
  const response: AxiosResponse = yield call(service.get, URL);
  const result = response.data as API.ProductPreferences;
  const productPreference = new ProductPreference(result);
  yield put(actions.updateProductPreference({ productPreference }));
}
export function* findCompositions(payload: number) {
  const URL = `/product-preference/packing-composition/site/${payload}`;
  const response: AxiosResponse = yield call(service.get, URL);
  const results = response.data as API.PackingDistributionComposition[];
  const compositionsById = new Map(
    results.map(composition => [
      String(composition.id),
      new PackingDistributionComposition(composition)
    ])
  );
  yield put(actions.updateCompositions({ compositionsById }));
}

export function* create(payload: ProductPreferencesCreateAction) {
  yield call(service.post, '/product-preference', payload);
  yield put(uiActions.productPreferencesCreate.setSubmitForm(true));
}

export function* update(payload: ProductPreferencesUpdateAction): any {
  const response: any = yield call(service.put, '/product-preference', payload);

  if (response?.response?.status >= 400) {
    throw new Error();
  }
  yield put(uiActions.productPreferencesCreate.setSubmitForm(true));
}

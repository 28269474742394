import { AxiosResponse } from 'axios';
import * as API from '~/domain/api';
import { APIError } from '~/domain/api/errors';
import apiProducer from '../api';

export async function setSitesToState(
  setToStateAction: (data: any) => void,
  selectedTabPermission: string
) {
  const responseSites = await findSites(selectedTabPermission);
  setToStateAction(responseSites.data);
}

export async function setSitesAnalyticsToState(
  setToStateAction: (data: any) => void,
  selectedTabPermission: string
) {
  const responseSites = await findSitesAnalytics(selectedTabPermission);
  setToStateAction(responseSites.data);
}

export const findSites = (permissionName: string) => {
  return apiProducer.get(`/site/${permissionName}`);
};

export const findSitesAnalytics = (permissionName: string) => {
  return apiProducer.get(`/site/analytics/${permissionName}`);
};

export const findSitesByProduct = (productId?: number) => {
  if (!productId) {
    return apiProducer.get(`/site`, {
      headers: {
        'X-Is-Async-Loading': 'true'
      }
    });
  }
  return apiProducer.get<API.Site[]>(`/site/by-product/${productId}`, {
    headers: {
      'X-Is-Async-Loading': 'true'
    }
  });
};

export const findMany = async (permissionName: string) => {
  try {
    const URL = `/site/${permissionName}`;
    const response: AxiosResponse = await apiProducer.get(URL);
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const findManyWithKeyword = async (keyword: string) => {
  try {
    const URL = `/site/search?keyword=${keyword}`;
    const response: AxiosResponse = await apiProducer.get(URL);
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

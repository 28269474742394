import { SiteLinksState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.core.siteLinks as SiteLinksState;
};

export const getSiteLinks = createSelector([getRoot], state => {
  return state.siteLinks;
});

export const getUrls = createSelector([getRoot], state => {
  return state.urlsList;
});

export const getIsCleared = createSelector(
  [getRoot],
  state => state.status === 'cleared'
);

import * as API from '~/domain/api';

export default class Week {
  public readonly json: API.Week;
  public readonly id: number;
  public readonly weekNumber: number;
  public readonly year: number;
  public readonly startDate: string;
  public readonly endDate: string;

  constructor(json: API.Week) {
    this.json = json;
    this.id = this.getId();
    this.weekNumber = this.getWeekNumber();
    this.year = this.getYear();
    this.startDate = this.getStartDate();
    this.endDate = this.getEndDate();
  }

  private getId(): number {
    return this.json.id ?? Number.NaN;
  }

  private getWeekNumber(): number {
    return this.json.weekNumber ?? Number.NaN;
  }

  private getYear(): number {
    return this.json.year ?? Number.NaN;
  }

  private getStartDate(): string {
    return this.json.startDate ?? '';
  }

  private getEndDate(): string {
    return this.json.endDate ?? '';
  }
}

import IUseCase from '~/@core/application/IUseCase';
import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';
import FilterText from '~/@core/domain/filters/rfidReports/FilterText';
import Consolidated from '~/@core/domain/model/rfidReports/Consolidated';
import { InventoryService } from '~/@core/infrastructure/api/services/rfidReports';

export default class GetConsolidateds
  implements IUseCase<FilterText, Promise<DefaultPaginated<Consolidated[]>>>
{
  execute(filter: FilterText): Promise<DefaultPaginated<Consolidated[]>> {
    return InventoryService.fetchConsolidateds(filter);
  }
}

import { CustomersGroupState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.core.customersGroup as CustomersGroupState;
};

export const getIsLoading = createSelector(
  [getRoot],
  state => state.fetchStatus === 'fetching'
);

import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import cvhnetImage from '~/assets/images/cvhnet1.png';
import cvhnetImage2 from '~/assets/images/cvhnet2.png';
import { getAccessToken } from '~/helpers/util/storage';
import {
  CardSystem,
  TabContainerMenus,
  Title,
  Card
} from '~/components/Globals';

export default function OtherPlatforms() {
  const { t } = useTranslation();

  return (
    <Card style={{ padding: 20 }}>
      <TabContainerMenus
        defaultActiveKey="1"
        onChange={() => {}}
        style={{ padding: 8 }}
      >
        <TabContainerMenus.TabPane tab={t('otherPlatforms.systems')} key="1">
          <Row style={{ marginTop: 10 }} gutter={16}>
            <Col lg={3}>
              <CardSystem
                style={{ borderRadius: 5, textAlign: 'center' }}
                onClick={() =>
                  window.open(
                    `${
                      process.env.REACT_APP_CVH_URL
                    }?token=${getAccessToken()}`,
                    '_blank'
                  )
                }
              >
                <img
                  alt="cvhnet"
                  loading="lazy"
                  style={{ objectFit: 'contain', marginTop: 20, width: '100%' }}
                  height={66}
                  src={cvhnetImage}
                />
                <div>
                  <br />
                  <Title>CVHNet</Title>
                  <p>{t('otherPlatforms.cvhnetDesc')}</p>
                </div>
              </CardSystem>
            </Col>
            <Col lg={3}>
              <CardSystem
                style={{ borderRadius: 5, textAlign: 'center' }}
                onClick={() =>
                  window.open(
                    `${
                      process.env.REACT_APP_CVH_2_URL
                    }?token=${getAccessToken()}`,
                    '_blank'
                  )
                }
              >
                <img
                  alt="cvhnet2"
                  loading="lazy"
                  height={66}
                  style={{ objectFit: 'contain', marginTop: 20, width: '100%' }}
                  src={cvhnetImage2}
                />
                <div>
                  <br />
                  <Title>CVHNet 2.0</Title>
                  <p>{t('otherPlatforms.cvhnet2Desc')}</p>
                </div>
              </CardSystem>
            </Col>
          </Row>
        </TabContainerMenus.TabPane>
      </TabContainerMenus>
    </Card>
  );
}

import { call, put } from 'redux-saga/effects';

import * as API from '~/domain/api';

import {
  actions,
  CancelPurchaseManagement,
  FetchEcommerceManagement
} from './duck';
import * as PurchaseManagementService from '~/services/producer/PurchaseManagement';
import {
  CartStatus,
  HistoryPurchaseManagement,
  PurchaseManagement,
  TransactionStatus
} from '~/domain/model';

export function* findListPurchaseManagement(params: FetchEcommerceManagement) {
  const response: API.ServerResponse = yield call(
    PurchaseManagementService.findListPurchaseManagement,
    params
  );
  const data: API.DefaultResponse = response.data;
  const listPurchaseManagemenResult: API.PurchaseManagement[] =
    data.results ?? [];
  let listPurchaseManagemen: PurchaseManagement[] = [];
  if (listPurchaseManagemenResult && listPurchaseManagemenResult.length) {
    listPurchaseManagemen = listPurchaseManagemenResult.map(
      (purchase: API.PurchaseManagement) => new PurchaseManagement(purchase)
    );
  }
  yield put(actions.update({ list: listPurchaseManagemen }));
  return response;
}

export function* onExport(params: FetchEcommerceManagement) {
  const response: API.ServerResponse = yield call(
    PurchaseManagementService.onExport,
    params
  );
  const data: string = response?.data?.data;
  const binaryString = window.atob(data);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  const fileData = bytes;

  const blob = new Blob([fileData], { type: 'application/octet-stream' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.style.display = 'none';
  const fileName = 'gestao_ecommerce.xlsx';
  link.download = fileName;
  link.click();
  return response;
}

export function* findCartStatusList() {
  const response: API.ServerResponse = yield call(
    PurchaseManagementService.findCartStatus
  );
  const listCartStatusResult: API.CartStatus[] = response.data ?? [];
  let cartStatusList: CartStatus[] = [];
  if (listCartStatusResult && listCartStatusResult.length) {
    cartStatusList = listCartStatusResult.map(
      (cartStatusItem: API.CartStatus) => new CartStatus(cartStatusItem)
    );
  }
  yield put(actions.update({ cartStatusList }));
  return response;
}

export function* findTransactionStatusList() {
  const response: API.ServerResponse = yield call(
    PurchaseManagementService.findTransactionStatus
  );
  const listTransactionStatusResult: API.TransactionStatus[] =
    response.data ?? [];
  let transactionStatusList: TransactionStatus[] = [];
  if (listTransactionStatusResult && listTransactionStatusResult.length) {
    transactionStatusList = listTransactionStatusResult.map(
      (transactionStatusItem: API.TransactionStatus) =>
        new TransactionStatus(transactionStatusItem)
    );
  }
  yield put(actions.update({ transactionStatusList }));
  return response;
}

export function* findHistoryPurchaseManagement(transactionId: number) {
  const response: API.ServerResponse = yield call(
    PurchaseManagementService.findHistoryPurchaseManagement,
    transactionId
  );
  const historyPurchaseManagementResult: API.HistoryPurchaseManagement =
    response.data ?? null;
  const historyPurchaseManagement: HistoryPurchaseManagement =
    new HistoryPurchaseManagement(historyPurchaseManagementResult);
  yield put(
    actions.update({
      historyPurchaseManagement
    })
  );
  return response;
}

export function* cancelPurchaseManagement(params: CancelPurchaseManagement) {
  const response: API.ServerResponse = yield call(
    PurchaseManagementService.cancelPurchaseManagement,
    params
  );
  return response;
}

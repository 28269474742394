import { Moment } from 'moment';
import { usFormat } from '~/helpers/util';
import FilterOrder from '../../filters/order/FilterOrder';
import BasePagination from '../BasePagination';

export default interface FormFilterGfpCartsModel {
  siteId: number;
  DeliveryDate: Moment[];
  CategoryId?: number;
  GroupId?: number;
  ProductId?: number;
  ColorId?: number;
  ProductRecipient?: string;
  QualityId?: number;
  OrderNumber?: number;
  ExpeditionId?: number;
  CustomerId?: number;
  division?: string;
  status?: string;
  gfpNumber?: number;
  printStatus?: number;
}
export class MapFormToFilter {
  static toFilter(
    formData: FormFilterGfpCartsModel,
    pagination: BasePagination
  ): FilterOrder {
    const filter: FilterOrder = {
      'Data.siteId': formData.siteId,
      'Data.startDeliveryDate': usFormat(formData.DeliveryDate[0]).toString(),
      'Data.endDeliveryDate': usFormat(formData.DeliveryDate[1]).toString(),
      'Data.categoryId': formData.CategoryId,
      'Data.groupId': formData.GroupId,
      'Data.productId': formData.ProductId,
      'Data.colorId': formData.ColorId,
      'Data.productRecipient': formData.ProductRecipient,
      'Data.QualityId': formData.QualityId,
      'Data.orderNumber': formData.OrderNumber,
      'Data.expeditionId': formData.ExpeditionId,
      'Data.customerId': formData.CustomerId,
      'Data.division': formData.division,
      'Data.status': formData.status,
      'Data.gfpNumber': formData.gfpNumber,
      'Data.printStatus': formData.printStatus,
      pageSize: pagination.pageSize,
      pageNumber: pagination.pageNumber
    };
    return filter;
  }
}

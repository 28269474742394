import { Card, FormInstance } from 'antd';
import FormFilterDataModel from '~/@core/domain/ui/forms/FormFilterDataModel';
import FormFilterTextModel from '~/@core/domain/ui/forms/FormFilterTextModel';
import FormFilterMovementDones from '~/components/molecules/RfidReports/Movement/Dones/FormFilterMovementDones';
import TableMovementDones from '~/components/molecules/RfidReports/Movement/Dones/TableMovementDones';
import { TabContainerForm } from '~/pages/Home/styles';

interface IProps {
  filterForm: FormInstance<FormFilterTextModel>;
  filterFormData: FormInstance<FormFilterDataModel>;
}
export default function MovementWrapper(props: IProps) {
  const { filterForm, filterFormData } = props;
  return (
    <Card>
      <TabContainerForm>
        <TabContainerForm.TabPane tab="Realizados" key="1">
          <FormFilterMovementDones form={filterFormData} />
          <TableMovementDones form={filterForm} />
        </TabContainerForm.TabPane>
      </TabContainerForm>
    </Card>
  );
}

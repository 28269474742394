import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '~/services/api';

import {
  searchSpecialDatesSuccess,
  searchSpecialDatesFailure
} from './actions';

// #FIXME
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* onSearchSpecialDates({ payload }: any): any {
  try {
    const response = yield call(
      api.get,
      `/special-date/dates?startdate=${payload.formatStart}&finalDate=${payload.formatEnd}`
    );
    const { data } = response;
    yield put(searchSpecialDatesSuccess(data));
  } catch (err) {
    yield put(searchSpecialDatesFailure());
  }
}

export default all([
  takeLatest('@specialDates/SEARCH_SPECIAL_DATES', onSearchSpecialDates)
]);

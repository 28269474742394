import { createSelector } from '~/helpers/util/selectors';
import { CompetenceCreateState } from './duck';

export const getRoot = (state: any) => {
  return state.ui.competenceCreate as CompetenceCreateState;
};

export const getCreateStatus = createSelector([getRoot], state => {
  return state.createStatus;
});

export const getIsLoading = createSelector([getCreateStatus], state => {
  return state === 'submitting';
});

import * as API from '~/domain/api';

export default class CustomerGroup {
  private json: API.CustomerGroup;
  public readonly id: number;
  public readonly description: string;
  public readonly customersIds: number[];
  public readonly checked: boolean;

  constructor(json: API.CustomerGroup) {
    this.json = json;
    this.id = this.getId();
    this.description = this.getDescription();
    this.customersIds = this.getCustomersIds();
    this.checked = this.getChecked();
  }

  private getId(): number {
    return this.json.id ?? Number.NaN;
  }

  private getDescription(): string {
    return this.json.description ?? '';
  }

  private getCustomersIds(): number[] {
    return this.json.customersIds ?? [];
  }

  private getChecked(): boolean {
    return this.json.checked ?? false;
  }
}

// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as AutoserviceCalls from './apiCalls';
import { DefaultPagination } from '~/domain/model';

export interface AutoserviceClassificationState {
  producersWhiteList: any[];
  producersBlackList: any[];
  reloadPageData: boolean;
  pagination: DefaultPagination;
}

const initialState: AutoserviceClassificationState = {
  producersWhiteList: [],
  producersBlackList: [],
  reloadPageData: false,
  pagination: { PageSize: 10 } as DefaultPagination
};

export const prefix = 'autoserviceClassification';

const autoserviceClassificationSlice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setProducersWhiteList: (state, action: PayloadAction<any[]>) => {
      state.producersWhiteList = action.payload;
    },
    setProducersBlackList: (state, action: PayloadAction<any[]>) => {
      state.producersBlackList = action.payload;
    },
    setReloadPageData: (state, action: PayloadAction<boolean>) => {
      state.reloadPageData = action.payload;
    },
    setPagination: (state, action: PayloadAction<DefaultPagination>) => {
      state.pagination = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(
      AutoserviceCalls.fetchProducersWhiteList.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.producersWhiteList = action.payload;
      }
    );
    builder.addCase(
      AutoserviceCalls.fetchProducersBlackList.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.producersBlackList = action.payload;
      }
    );
    builder.addCase(
      AutoserviceCalls.postItemBlackOrWhitelist.fulfilled,
      state => {
        state.reloadPageData = true;
      }
    );
    builder.addCase(
      AutoserviceCalls.deleteProducerPriority.fulfilled,
      state => {
        state.reloadPageData = true;
      }
    );
    builder.addCase(AutoserviceCalls.updateRating.fulfilled, state => {
      state.reloadPageData = true;
    });
    builder.addCase(
      AutoserviceCalls.postProducerClassification.fulfilled,
      state => {
        state.reloadPageData = true;
      }
    );
  }
});

export const actions = autoserviceClassificationSlice.actions;
export const reducer = autoserviceClassificationSlice.reducer;

import * as API from 'domain/api';

export default class PurchaseManagement {
  public readonly json: API.PurchaseManagement;
  public readonly transactionId: number;
  public readonly cartId: number;
  public readonly cartItemId: number;
  public readonly orderNumber?: number;
  public readonly volOfferId: number;
  public readonly lkpOfferId?: number;
  public readonly customerId: number;
  public readonly customerCode: string;
  public readonly customerName: string;
  public readonly producerId: number;
  public readonly producerCode: string;
  public readonly producerName: string;
  public readonly producerTradingName: string;
  public readonly productId: number;
  public readonly productCode: string;
  public readonly productDescription: string;
  public readonly productLongDescription: string;
  public readonly qualityId: number;
  public readonly qualityDescription: string;
  public readonly packingId: number;
  public readonly packingCode: string;
  public readonly packingPrice: number;
  public readonly totalUnits: number;
  public readonly quantityPackages: number;
  public readonly unitsPerPackage: number;
  public readonly unitPrice: number;
  public readonly totalPrice: number;
  public readonly deliveryDate: string;
  public readonly billingDate: string;
  public readonly type: string;
  public readonly boughtBy: string;
  public readonly transactionStatusCode: string;
  public readonly transactionStatusDescription: string;
  public readonly cartStatusCode: string;
  public readonly cartStatusDescription: string;
  public readonly saleDate: string;

  constructor(json: API.PurchaseManagement) {
    this.json = json;
    this.transactionId = json.transactionId;
    this.cartId = json.cartId;
    this.cartItemId = json.cartItemId;
    this.orderNumber = json.orderNumber;
    this.volOfferId = json.volOfferId;
    this.lkpOfferId = json.lkpOfferId;
    this.customerId = json.customerId;
    this.customerCode = json.customerCode;
    this.customerName = json.customerName;
    this.producerId = json.producerId;
    this.producerCode = json.producerCode;
    this.producerName = json.producerName;
    this.producerTradingName = json.producerTradingName;
    this.productId = json.productId;
    this.productCode = json.productCode;
    this.productDescription = json.productDescription;
    this.productLongDescription = json.productLongDescription;
    this.qualityId = json.qualityId;
    this.qualityDescription = json.qualityDescription;
    this.packingId = json.packingId;
    this.packingCode = json.packingCode;
    this.packingPrice = json.packingPrice;
    this.totalUnits = json.totalUnits;
    this.quantityPackages = json.quantityPackages;
    this.unitsPerPackage = json.unitsPerPackage;
    this.unitPrice = json.unitPrice;
    this.totalPrice = json.totalPrice;
    this.deliveryDate = json.deliveryDate;
    this.billingDate = json.billingDate;
    this.type = json.type;
    this.boughtBy = json.boughtBy;
    this.transactionStatusCode = json.transactionStatusCode;
    this.transactionStatusDescription = json.transactionStatusDescription;
    this.cartStatusCode = json.cartStatusCode;
    this.cartStatusDescription = json.cartStatusDescription;
    this.saleDate = json.saleDate;
  }
}

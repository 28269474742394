import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationError } from '~/domain/api/errors';

export type FetchStatus = 'idle' | 'pending' | 'success' | 'failure';

export type SiteLinksListState = {
  fetchInitStatus: FetchStatus;
  fetchSiteLinksStatus: FetchStatus;
  errorMessage?: string;
  pageNumber: number;
  pageSize: number;
  totalCount: number;
};

export type FetchPage = {
  pageNumber: number;
  pageSize: number;
};

export type FetchSiteLinksSuccess = {
  pageNumber?: number;
  pageSize?: number;
  totalCount: number;
};

export type FetchFailure = {
  error?: ApplicationError;
};

export type Error = {
  errorMessage?: string;
};

const prefix = '@siteLinksList';

export const initialState: SiteLinksListState = {
  fetchInitStatus: 'idle',
  fetchSiteLinksStatus: 'idle',
  pageNumber: 1,
  pageSize: 10,
  totalCount: 0
};

export type FetchSiteLinks = {
  description: string;
  url: string;
  pageNumber?: number;
  pageSize?: number;
};

const siteLinksList = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: state => {
      state.fetchInitStatus = initialState.fetchInitStatus;
      state.fetchSiteLinksStatus = initialState.fetchSiteLinksStatus;
      state.pageNumber = 1;
      state.pageSize = 10;
      state.totalCount = 0;
      state.errorMessage = '';
    },
    fetchInit: state => {
      state.fetchInitStatus = 'pending';
    },
    fetchInitSuccess: state => {
      state.fetchInitStatus = 'success';
    },
    fetchInitFailure: state => {
      state.fetchInitStatus = 'failure';
    },
    setPagination: (state, action: PayloadAction<FetchPage>) => {
      state.pageNumber = action.payload.pageNumber;
      state.pageSize = action.payload.pageSize;
    },
    fetchSiteLinks: (state, action: PayloadAction<FetchSiteLinks>) => {
      state.fetchSiteLinksStatus = 'pending';
      let { pageNumber, pageSize } = action.payload;
      if (!pageNumber) {
        pageNumber = initialState.pageNumber;
        state.pageNumber = pageNumber;
      }
      if (!pageSize) {
        pageSize = initialState.pageSize;
        state.pageSize = pageSize;
      }
    },
    fetchSiteLinksSuccess: (
      state,
      action: PayloadAction<FetchSiteLinksSuccess>
    ) => {
      state.fetchSiteLinksStatus = 'success';
      state.totalCount = action.payload.totalCount;
      state.pageSize = action.payload.pageSize ?? 10;
      state.pageNumber = action.payload.pageNumber ?? 1;
    },
    fetchSiteLinksFailure: (state, _action: PayloadAction<Error | void>) => {
      state.fetchSiteLinksStatus = 'failure';
    }
  }
});
export const actions = siteLinksList.actions;
export const reducer = siteLinksList.reducer;

const caseReducers = siteLinksList.caseReducers;
export const Types = Object.freeze({
  clear: `${prefix}/${caseReducers.clear.name}`,
  fetchInit: `${prefix}/${caseReducers.fetchInit.name}`,
  fetchInitSuccess: `${prefix}/${caseReducers.fetchInitSuccess.name}`,
  fetchFailureInit: `${prefix}/${caseReducers.fetchInitFailure.name}`,
  setPagination: `${prefix}/${caseReducers.setPagination.name}`,
  fetchSiteLinks: `${prefix}/${caseReducers.fetchSiteLinks.name}`,
  fetchSiteLinksSuccess: `${prefix}/${caseReducers.fetchSiteLinksSuccess.name}`,
  fetchSiteLinksFailure: `${prefix}/${caseReducers.fetchSiteLinksFailure.name}`
});

import * as API from '../api';

export default class ProductGroup implements API.ProductGroup {
  private _id: number;
  private _code: string;
  private _description: string;
  private _categoryId: number;

  public get id(): number {
    return this._id;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get code(): string {
    return this._code;
  }

  public set code(value: string) {
    this._code = value;
  }

  public get description(): string {
    return this._description;
  }

  public set description(value: string) {
    this._description = value;
  }

  public get categoryId(): number {
    return this._categoryId;
  }

  public set categoryId(value: number) {
    this._categoryId = value;
  }

  constructor(json: API.ProductGroup) {
    this._id = json.id;
    this._code = json.code;
    this._description = json.description;
    this._categoryId = json.categoryId;
  }
}

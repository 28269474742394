import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '~/services/api';

import {
  productionLocalTypeListSuccess,
  productionLocalTypeListFailure
} from './actions';

export function* onList(): any {
  try {
    const response = yield call(api.get, `/production-local-type`);

    const { data } = response;

    yield put(productionLocalTypeListSuccess(data));
  } catch (err) {
    yield put(productionLocalTypeListFailure());
  }
}

export default all([takeLatest('@productionLocalType/LIST_REQUEST', onList)]);

// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import apiProducer from '../api';
import { endpoints } from '~/constants/endpoints';
import * as Models from '~/domain/model';
import UnpublishedFilterParams from '~/domain/model/Orders/UnpublishedFilterParam';
import { UpdateItems } from '~/store/modules/unpublished/apiCalls';

export const findUnpublished = async (
  unpublishdFilterParams: UnpublishedFilterParams
) => {
  return apiProducer.get<
    void,
    AxiosResponse<Models.ResponseApi<Models.Order[]>>
  >(endpoints.unpublished.base, { params: unpublishdFilterParams });
};

export const postUnpublished = async (filterParams: number[]) => {
  return apiProducer.post<void, AxiosResponse<Models.ResponseApi<any>>>(
    endpoints.unpublished.publish,
    filterParams
  );
};

export const deleteUnpublished = async (
  filterParams: Models.UnpublishedDelete
) => {
  return apiProducer.delete<void, AxiosResponse<Models.ResponseApi<any>>>(
    endpoints.unpublished.delete,
    { data: filterParams }
  );
};

export const updateUnpublished = async (filterParams: UpdateItems) => {
  return apiProducer.put<void, AxiosResponse<Models.ResponseApi<any>>>(
    endpoints.unpublished.base,
    filterParams
  );
};

import { AxiosError, AxiosResponse } from 'axios';
import api from '../../config/index';
import { PrintRule } from '~/@core/domain/model/tag';

export const getPrintRules = () => {
  return new Promise<PrintRule[]>((resolve, reject) => {
    api
      .get<void, AxiosResponse<PrintRule[]>>('/tag/rules')
      .then((response: AxiosResponse<PrintRule[]>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

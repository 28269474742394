import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Announcement from '~/domain/model/Announcements/Announcement';
import { announcementsFetch, announcementFilesFetchById } from './apiCalls';
import { File, DefaultPagination } from '~/domain/model';

export type FetchStatus = 'idle' | 'pending' | 'success' | 'failure';

export interface AnnouncementsListState {
  files: File[];
  list: Announcement[];
  loading: boolean;
  pagination: DefaultPagination;
}

const initialState: AnnouncementsListState = {
  files: [],
  list: [],
  loading: false,
  pagination: { PageSize: 10 } as DefaultPagination
};
const prefix = 'Announcement';

const AnnouncementSlice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    resetState: () => initialState,
    clearFiles: state => {
      state.files = [];
    },
    setPagination: (state, action: PayloadAction<DefaultPagination>) => {
      state.pagination = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(announcementsFetch.pending, state => {
      state.loading = true;
    });
    builder.addCase(announcementsFetch.fulfilled, (state, action) => {
      state.list = action.payload.results;
      state.loading = false;
    });
    builder.addCase(announcementsFetch.rejected, state => {
      state.loading = false;
    });
    builder.addCase(announcementFilesFetchById.pending, state => {
      state.loading = true;
    });
    builder.addCase(announcementFilesFetchById.fulfilled, (state, action) => {
      state.files = action.payload;
      state.loading = false;
    });
    builder.addCase(announcementFilesFetchById.rejected, state => {
      state.loading = false;
    });
  }
});

export const actions = AnnouncementSlice.actions;
export const reducer = AnnouncementSlice.reducer;

import { create } from 'zustand';
import Frequency from '~/@core/domain/model/rfidReports/Frequency';
import TablePagination from '~/@core/domain/ui/TablePagination';

interface FrequencyState {
  frequencies: Frequency[];
  frequencyPagination: TablePagination;
  handleFrequencyPagination: (newPagination: TablePagination) => void;
  addFrequencies: (frequenciesValues: Frequency[]) => void;
}

const useFrequencyStore = create<FrequencyState>()(set => ({
  frequencies: [],
  frequencyPagination: {
    pageSize: 10,
    current: 1,
    total: 1
  },
  handleFrequencyPagination: newPagination =>
    set({ frequencyPagination: newPagination }),
  addFrequencies: frequenciesValues => set({ frequencies: frequenciesValues })
}));

export default useFrequencyStore;

import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';

import service from '~/services/api';
import * as API from '~/domain/api';

import { actions } from './duck';
import { Competence } from '~/domain/model';
import {
  CompetenceAlreadyRegistered,
  CompetenceRemoveError,
  UnknownError
} from '~/domain/api/errors';

export function* findMany(
  nextPage?: number,
  nextPageSize?: number,
  referenceYear?: number | null
) {
  let URL = '/competence/full?Sort=Asc&OrderBy=ReferenceYear';
  if (nextPage) {
    URL += `&PageNumber=${nextPage}`;
  } else {
    URL += '&PageNumber=1';
  }
  if (nextPageSize) {
    URL += `&PageSize=${nextPageSize}`;
  } else {
    URL += '&PageSize=100000';
  }
  if (referenceYear) {
    URL += `&Data.ReferenceYear=${referenceYear}`;
  }
  const response: AxiosResponse = yield call(service.get, URL);
  const apiResponse = response.data as API.DefaultResponse;
  const competencies: API.Competence[] = apiResponse.results ?? [];
  const competenceByID = new Map(
    competencies.map(competence => [
      String(competence.id),
      new Competence(competence)
    ])
  );
  yield put(actions.update({ competenceByID }));
  return apiResponse;
}

export const create = async (referenceYear: number, historicalYear: number) => {
  const serverResponse: any = await service.post('/competence', {
    referenceYear,
    historicalYear
  });
  const { response } = serverResponse;
  if (response) {
    const { status } = response;
    if (![200, 202].includes(status)) {
      const { Status: dataStatus } = response.data;
      if (dataStatus === 400) {
        throw new CompetenceAlreadyRegistered();
      }
      throw new UnknownError();
    }
  }
};

export const remove = async (competenceIDs: number[]) => {
  // TODO Substituir pelo comentado quando possível
  // const params = new URLSearchParams();
  // competenceIDs.forEach(competenceID => {
  //   params.append('id', String(competenceID));
  // });
  // const response: AxiosResponse<any> = await service.delete('/competence', {
  //   params
  // });
  try {
    let params = '';
    competenceIDs.forEach(competenceID => {
      params += `&id=${String(competenceID)}`;
    });
    params = params.replace('&', '?');
    await service.delete(`/competence/${params}`);
  } catch (error: any) {
    const { status } = error.response;

    if (status === 422) {
      const { invalidIDs, iDsWithEvents, message } = error.response.data;

      throw new CompetenceRemoveError(invalidIDs, iDsWithEvents, message);
    }
    throw new UnknownError();
  }
};

import { ProductCategoryState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.core.productCategories as ProductCategoryState;
};

export const getProductCategoriesById = createSelector([getRoot], state => {
  return state.productCategoriesByID;
});

export const getProductCategories = createSelector([getRoot], state =>
  Array.from(state.productCategoriesByID.values())
);
export const getOfferSettingsCategories = createSelector([getRoot], state => {
  return Array.from(state.productCategoriesByID.values()).filter(({ code }) => {
    const categoryCode = code ?? '';
    return ['02', '03'].includes(categoryCode);
  });
});

export const getIsCleared = createSelector(
  [getRoot],
  state => state.status === 'cleared'
);

import produce from 'immer';

const INITIAL_STATE = {
  competencies: [],
  categories: [],
  failure: false,
  success: false
};

export default function competency(state = INITIAL_STATE, action: any) {
  return produce(state, _ => {
    switch (action.type) {
      case '@product/SEARCH_COMPETENCES_SUCCESS': {
        return produce(state, draft => {
          draft.competencies = action.payload;
        });
      }

      default:
    }
  });
}

import { BannerProducerManagementState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.core.bannerProducerManagement as BannerProducerManagementState;
};

export const getBannerProducerManagementList = createSelector(
  [getRoot],
  state => {
    return state.bannerProducerManagementList;
  }
);

export const getBannerImage = createSelector([getRoot], state => {
  return state.bannerImage;
});

export const getIsCleared = createSelector(
  [getRoot],
  state => state.status === 'cleared'
);

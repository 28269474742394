import { SearchOutlined } from '@ant-design/icons';
import { ButtonProps } from 'antd';
import IconButton from './IconButton';

type Props = ButtonProps & {
  tooltip?: string;
};

export default function SearchButton(props: Props) {
  return (
    <IconButton
      {...props}
      type="primary"
      tooltip={props.tooltip || 'Pesquisar'}
      icon={<SearchOutlined />}
    />
  );
}

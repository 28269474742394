/* eslint-disable consistent-return */
// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import moment from 'moment';
import { OfferService } from '~/services/offerService';
import commomImage from '~/assets/default/image.png';
import ProductOffer from '~/domain/model/ProductOffer';
import AutoserviceRequestsListParam from '~/domain/model/AutoserviceRequestsListParam';
import * as AutoserviceServices from '~/services/producer/AutoserviceRequests';
import { alertRequest } from '../../alert/actions';

moment.locale('pt');

export const fetchRequestsList = createAsyncThunk(
  `autoserviceRequests/fetchRequestsList`,
  async (params: AutoserviceRequestsListParam) => {
    try {
      const data = await AutoserviceServices.findAutoserviceRequestsList(
        params
      );
      return data;
    } catch (err: any) {
      console.log('error');
    }
  }
);

export const fetchRequestsListStatus = createAsyncThunk(
  `autoserviceRequests/fetchRequestsListStatus`,
  async (params: AutoserviceRequestsListParam) => {
    try {
      const data = await AutoserviceServices.findAutoserviceRequestsListStatus(
        params
      );
      return data;
    } catch (err: any) {
      console.log('error');
    }
  }
);

export const postRequestDetails = createAsyncThunk(
  `autoserviceClassification/postRequestDetails`,
  async (payload: any, { dispatch }) => {
    try {
      await AutoserviceServices.postAutoserviceDetailsRequest(payload);
      dispatch(alertRequest('Planilha cadastrada com sucesso.', 'success'));
      return { reload: Math.random(), addplan: true };
    } catch (err: any) {
      const error = err as AxiosError;
      dispatch(
        alertRequest(
          JSON.stringify(error.response?.data.errors.messages),
          'error'
        )
      );
    }
  }
);

export const cancelRequestDetails = createAsyncThunk(
  `autoserviceRequests/cancelRequestDetails`,
  async (params: number) => {
    await AutoserviceServices.cancelAutoserviceDetailsRequest(params);
    return Math.random();
  }
);

export const fetchDetailsRequest = createAsyncThunk(
  `autoserviceRequests/fetchDetailsRequest`,
  async (worksheetId: number) => {
    const data = await AutoserviceServices.findAutoserviceDetailsRequest(
      worksheetId
    );
    return data;
  }
);

export const fetchDetailsRequestLines = createAsyncThunk(
  `autoserviceRequests/fetchDetailsRequestLines`,
  async (worksheetId: number) => {
    try {
      const data = await AutoserviceServices.findAutoserviceDetailsRequestLines(
        worksheetId
      );
      return data;
    } catch (err: any) {
      console.log('error');
    }
  }
);

export const cancelRequestDetailsLines = createAsyncThunk(
  `autoserviceRequests/cancelRequestDetailsLines`,
  async (params: number) => {
    try {
      await AutoserviceServices.cancelAutoserviceDetailsLine(params);
      return Math.random();
    } catch (err: any) {
      console.log('error');
    }
  }
);

export const purchaseRequestDetails = createAsyncThunk(
  `autoserviceRequests/purchaseRequestDetails`,
  async (params: number) => {
    await AutoserviceServices.finishAutoserviceDetails(params);
    return Math.random();
  }
);

export const fetchRequestDetailsOffers = createAsyncThunk(
  `autoserviceRequests/fetchRequestDetailsOffers`,
  async (params: number) => {
    const data = await AutoserviceServices.findAutoserviceDetailsOffers(params);
    return data;
  }
);

export const cancelRequestDetailsOffer = createAsyncThunk(
  `autoserviceRequests/cancelRequestDetailsOffer`,
  async (params: number) => {
    await AutoserviceServices.cancelAutoserviceDetailsOffer(params);
    return Math.random();
  }
);

export const postRequestDetailsOffers = createAsyncThunk(
  `autoserviceClassification/postRequestDetailsOffers`,
  async (payload: any, { dispatch }) => {
    try {
      await AutoserviceServices.postAutoserviceDetailsOffers(payload);
      return Math.random();
    } catch (err: any) {
      const error = err as AxiosError;
      dispatch(
        alertRequest(
          JSON.stringify(error.response?.data.errors.messages),
          'error'
        )
      );
    }
  }
);

export const fetchProductsEcommerce = createAsyncThunk(
  `autoserviceRequests/fetchProductsEcommerce`,
  async (params: { new: boolean; id: string | number }) => {
    const data = await AutoserviceServices.findAProductsEcommerce(
      params.new
        ? `&OfferTypes=OnSite${params.id}`
        : `&productsIds=${params.id}&OfferTypes=OnSite&offerTypes=directed`
    );
    return data;
  }
);

export const fetchProductsEcommerce2 = createAsyncThunk(
  `autoserviceRequests/fetchProductsEcommerce2`,
  async (params: {
    new: boolean;
    id: string | number;
    customerId?: number;
    deliveryDate?: string;
    commercialDate?: string;
  }) => {
    console.log('entrou');
    const data = await AutoserviceServices.findAProductsEcommerce(
      params.new
        ? `&OfferTypes=OnSite&offerTypes=directed${params.id}`
        : `&productsIds=${params.id}&OfferTypes=OnSite&offerTypes=directed&customerId=${params.customerId}`
    );

    const toDay = moment().weekday() - 1;
    const hourNow = moment().format('HH:mm:ss');

    const dataValidTime: any =
      data.map((product: any) => ({
        ...product,
        validTime: moment(hourNow, 'HH:mm:ss').isAfter(
          moment(product.siteDeliveryPatterns[toDay].orderEndTime, 'HH:mm:ss')
        )
      })) || [];

    const dataFiltered: any = dataValidTime.filter(
      (product: any) => product.validTime === false
    );

    const newdata: ProductOffer[] =
      dataFiltered.map((product: any) => ({
        ...product,
        imagesFormatted:
          product.images?.length > 0
            ? product.images
            : [product.defaultImage || commomImage],
        id: (Math.random() + 1).toString(36).substring(7),
        infoVoucher: OfferService.generateVoucherDescription(
          product.reajustmentAmount,
          product.minimumUnitQuantityVoucher,
          product.voucherQuantityType
        ),
        endDateFormatted: moment(product.endDate).format('DD/MM'),
        siteDeliveryPatterns: product.siteDeliveryPatterns.sort(
          (a: any, b: any) => a.orderEndDayNumber - b.orderEndDayNumber
        )
      })) || [];
    console.log('newData', newdata);
    return newdata;
  }
);

export const fetchChosenListEcommerce = createAsyncThunk(
  `autoserviceRequests/fetchChosenListEcommerce`,
  async (params: string) => {
    const data = await AutoserviceServices.findAProductsEcommerce(params);
    const newdata: ProductOffer[] =
      data.map((product: any) => ({
        ...product,
        imagesFormatted:
          product.images?.length > 0
            ? product.images
            : [product.defaultImage || commomImage],
        id: (Math.random() + 1).toString(36).substring(7),
        infoVoucher: OfferService.generateVoucherDescription(
          product.reajustmentAmount,
          product.minimumUnitQuantityVoucher,
          product.voucherQuantityType
        ),
        endDateFormatted: moment(product.endDate).format('DD/MM'),
        siteDeliveryPatterns: product.siteDeliveryPatterns.sort(
          (a: any, b: any) => a.orderEndDayNumber - b.orderEndDayNumber
        )
      })) || [];
    return newdata;
  }
);

export const fetchFiltersProductsEcommerce = createAsyncThunk(
  `autoserviceRequests/fetchFiltersProductsEcommerce`,
  async (params: number) => {
    const data = await AutoserviceServices.findFiltersProductsEcommerce(params);
    return data;
  }
);

export const postRequestNewProduct = createAsyncThunk(
  `autoserviceClassification/postRequestNewProduct`,
  async (payload: any, { dispatch }) => {
    try {
      await AutoserviceServices.postNewProduct(payload);
      dispatch(alertRequest('Produto adicionado com sucesso', 'success'));
      return Math.random();
    } catch (err: any) {
      const error = err as AxiosError;
      dispatch(
        alertRequest(
          JSON.stringify(error.response?.data.errors.messages),
          'error'
        )
      );
    }
  }
);

export const fetchHistory = createAsyncThunk(
  `autoserviceRequests/fetchHistory`,
  async (params: number) => {
    const data = await AutoserviceServices.findHistory(params);
    return data;
  }
);

// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-shadow */
import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import api from '~/services/api';

import {
  addProductionPlanSuccess,
  addProductionPlanFailure,
  searchProductionPlanSuccess,
  searchProductionPlanFailure,
  searchLocalsSuccess,
  setHistorySuccess,
  replicateLastYearSuccess,
  replicateLastYearFailure,
  replicateWeekSuccess,
  replicateWeekFailure,
  deleteProductsSuccess,
  deleteProductsFailure,
  setHistoryFailure,
  searchPlanWeeksSuccess,
  searchPlanWeeksFailure
} from './actions';

export function* onCreate({ payload }: any): any {
  try {
    yield call(api.put, '/production-planning', payload);
    yield put(addProductionPlanSuccess());
  } catch (err) {
    yield put(addProductionPlanFailure());
  }
}

export function* onSearch({ payload }: any): any {
  try {
    const keys = Object.keys(payload.data);
    let filter = '';

    keys.forEach((key, index) => {
      if (payload.data[key]) {
        filter +=
          index > 0
            ? `&Data.${key}=${payload.data[key]}`
            : `?Data.${key}=${payload.data[key]}`;
      }
    });
    const pageNumber = yield select(
      (state: any) => state.productionPlan.pageNumber
    );
    const pageSize = yield select(
      (state: any) => state.productionPlan.pageSize
    );
    filter += `&PageSize=${pageSize}&PageNumber=${pageNumber}`;
    const response = yield call(api.get, `/production-planning${filter}`);

    if (response?.response?.status >= 400) {
      yield put(searchProductionPlanFailure(response.response.data.Errors));
      return;
    }
    const { data } = response;

    data.productionPlanningGroups?.forEach((group: any, index: number) => {
      group.key = index;
      group.productionPlanningGroupProducts.forEach(
        (product: any, index: number) => (product.key = index)
      );
    });

    yield put(searchProductionPlanSuccess(data));
  } catch (err) {
    yield put(searchProductionPlanFailure(err));
  }
}

export function* onSearchLocals({ payload }: any): any {
  try {
    const response = yield call(
      api.get,
      `/production-property/${payload.selected}`
    );
    const { data } = response;
    if (Object.keys(data).length > 0) {
      const selectLocals = {
        data,
        index: payload.index,
        indexGroup: payload.indexGroup,
        productionPlannningGroupProduct: payload.productionPlannningGroupProduct
      };
      yield put(searchLocalsSuccess(selectLocals));
    } else {
      // yield put(searchWeekIdFailure());
    }
  } catch (err) {
    // yield put(searchWeekIdFailure());
  }
}
export function* onSetHistory({ payload }: any): any {
  try {
    const {
      productionProductGroupId,
      startYear,
      endYear,
      groupType,
      dataType
    } = payload;
    const site = yield select((state: any) => state.site.site);
    let url = `/production-planning/done-planned/graphics?Data.SiteId=${
      site.id
    }&Data.YearStart=${startYear.getUTCFullYear()}&Data.YearEnd=${endYear.getUTCFullYear()}&Data.GroupType=${groupType}&Data.DataType=${dataType}`;
    if (typeof productionProductGroupId !== 'undefined') {
      url += `&Data.ProductionProductGroupId=${productionProductGroupId}`;
    }
    const response = yield call(api.get, url);
    const { data } = response;
    yield put(setHistorySuccess(data));
  } catch (err) {
    yield put(setHistoryFailure());
  }
}
export function* onReplicateLastYear({ payload }: any): any {
  try {
    const site = yield select((state: any) => state.site.site);
    const startWeek = yield select((state: any) => state.dates.startWeek);
    const dateStrings = startWeek.split('/');
    const yearToReplicate = new Date(
      `${dateStrings[1]}/${dateStrings[0]}/${dateStrings[2]}`
    );
    yearToReplicate.setFullYear(yearToReplicate.getUTCFullYear() - 1);
    const yearTo = new Date(
      `${dateStrings[1]}/${dateStrings[0]}/${dateStrings[2]}`
    );
    const url = `/production-planning/replicatePlanning`;

    const replicateBody = {
      SiteId: site.id,
      YearFrom: yearToReplicate.getUTCFullYear(),
      YearTo: yearTo.getUTCFullYear(),
      AdjustmentPercentage: payload.percent,
      ProductionProductGroupsIds: payload.productionPlanningGroupsIds
    };
    const response = yield call(api.put, url, replicateBody);
    if (response?.response?.status >= 400) {
      yield put(replicateLastYearFailure(response.response.request.response));
    } else {
      yield put(replicateLastYearSuccess());
    }
  } catch (err) {
    yield put(replicateLastYearFailure(err));
  }
}
export function* onReplicateWeek({ payload }: any): any {
  try {
    const response = yield call(
      api.put,
      '/production-planning/replicate-planning-weeks',
      payload.replicateWeekAction
    );
    if (response?.response?.status >= 400) {
      let message = '';
      response?.response?.data?.Errors?.Messages.map((error: string) => {
        message += error;
      });
      yield put(replicateWeekFailure(message));
    } else {
      yield put(replicateWeekSuccess());
    }
  } catch (err) {
    yield put(replicateWeekFailure(err));
  }
}

export function* onDeleteProducts({ payload }: any): any {
  try {
    yield call(api.delete, `/production-planning/group/products`, {
      data: payload.ids
    });
    yield put(deleteProductsSuccess());
  } catch (err) {
    yield put(deleteProductsFailure());
  }
}
export function* onSearchPlanWeeks(): any {
  try {
    const site = yield select((state: any) => state.site.site);
    const response = yield call(
      api.get,
      `/production-planning/event-weeks?siteId=${site.id}`
    );
    if (!Array.isArray(response.data)) {
      yield put(searchPlanWeeksSuccess([]));
    } else {
      yield put(searchPlanWeeksSuccess(response.data));
    }
  } catch (err) {
    yield put(searchPlanWeeksFailure());
  }
}

export default all([
  takeLatest('@productionPlan/ADD_PRODUCTION_PLAN', onCreate),
  takeLatest('@productionPlan/SEARCH_PRODUCTION_PLAN_SECTION', onSearch),
  // takeLatest('@productionPlan/SET_SELECT_LOCALS', onSearchLocals),
  takeLatest('@productionPlan/SET_HISTORY', onSetHistory),
  takeLatest('@productionPlan/REPLICATE_LAST_YEAR', onReplicateLastYear),
  takeLatest('@productionPlan/REPLICATE_WEEK', onReplicateWeek),
  takeLatest('@productionPlan/DELETE_PRODUCTS', onDeleteProducts),
  takeLatest('@productionPlan/SEARCH_PLAN_WEEKS', onSearchPlanWeeks)
]);

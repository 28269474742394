import { call, fork, put, takeLatest } from 'redux-saga/effects';

import * as API from '~/domain/api';

import { actions, Types, FetchSiteLinks } from './duck';
import * as SiteLinksService from '~/store/modules/_core/siteLinks/service';
import { Action } from '~/helpers/util/reducers';

export function* watchFetchSiteLinksSaga(action: Action<FetchSiteLinks>) {
  yield fork(fetchSiteLinksSaga, action.payload);
}

export function* fetchInitSaga(): any {
  try {
    yield call(SiteLinksService.fetchSiteLinks, {
      pageNumber: 1,
      pageSize: 10
    });
    yield put(actions.fetchInitSuccess());
  } catch (err) {
    yield put(actions.fetchInitFailure());
  }
}

export function* fetchSiteLinksSaga({
  description,
  url,
  pageNumber,
  pageSize
}: FetchSiteLinks): any {
  try {
    const response: API.ServerResponse = yield call(
      SiteLinksService.fetchSiteLinks,
      {
        description,
        url,
        pageNumber,
        pageSize
      }
    );
    const data: API.DefaultResponse = response.data;

    const totalCount = data.totalCount ?? 0;
    yield put(
      actions.fetchSiteLinksSuccess({
        pageNumber,
        pageSize,
        totalCount
      })
    );
  } catch (err) {
    yield put(actions.fetchSiteLinksFailure());
  }
}

export default [
  takeLatest(Types.fetchInit, fetchInitSaga),
  takeLatest(Types.fetchSiteLinks, watchFetchSiteLinksSaga)
];

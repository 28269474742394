import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import * as API from '~/domain/api';
import { refreshEnum } from '~/domain/enum/refresh.enum';
import * as AuthService from '~/services/producer/Auth';
import {
  getRefreshToken,
  getScope,
  getTokens,
  removeTokens,
  setTokens
} from './storage';

import store from '~/store';
import { getIsLoading } from '~/store/modules/_ui/loading/selectors';

const ACCESS_TOKEN = 'accessToken';
const REFRESH_TOKEN = 'refreshToken';
const EXPIRES_IN = 'expiresIn';

type Tokens = {
  refreshToken: string;
  accessToken: string;
  expiresIn: string;
};

export const getCookiesTokens = (): Tokens => {
  return {
    refreshToken: Cookies.get(REFRESH_TOKEN) ?? '',
    accessToken: Cookies.get(ACCESS_TOKEN) ?? '',
    expiresIn: Cookies.get(EXPIRES_IN) ?? ''
  };
};

export const updateTokens = () => {
  const tokens = getTokens();
  Cookies.set(ACCESS_TOKEN, tokens.accessToken, { expires: 1, secure: true });
  Cookies.set(REFRESH_TOKEN, tokens.refreshToken, { expires: 1, secure: true });
  Cookies.set(EXPIRES_IN, tokens.expiresIn, { expires: 1, secure: true });
};

export const getTokenExpirationTime = () => {
  const tokens = getTokens();
  const { exp }: any = jwtDecode(tokens.accessToken);
  const expireDate = moment(exp * 1000);
  const now = moment().format('DD/MM/YYYY HH:mm:ss');

  const timeLeftInMs = moment(expireDate, 'DD/MM/YYYY HH:mm:ss').diff(
    moment(now, 'DD/MM/YYYY HH:mm:ss')
  );

  const timeLeftInMin = Math.floor(timeLeftInMs / 60000);

  return timeLeftInMin;
};

export const onRefreshToken = async () => {
  const response = await AuthService.sendRefreshToken(
    getRefreshToken(),
    getScope()
  );
  if (response.status === 401) {
    removeTokens();
  }

  const refreshData: API.RefreshData = response.data;
  setTokens({
    refreshToken: refreshData.refresh_token,
    accessToken: refreshData.access_token,
    scope: refreshData.scope,
    expiresIn: refreshData.expires_in,
    tokenType: refreshData.token_type
  });
  updateTokens();
  setInterval(() => getTokenExpirationTime(), 60000);
};

interface EventAtributes {
  mouseEvent: MouseEvent | null;
  imputEvent: Event | null;
}

export const getValidadeToken = (event: EventAtributes) => {
  let isButton = false;
  const state = store.getState();
  const isLoading = getIsLoading(state);

  if (
    event.mouseEvent instanceof MouseEvent &&
    (event.mouseEvent.target instanceof HTMLElement ||
      event.mouseEvent.target instanceof SVGElement)
  ) {
    const target = event.mouseEvent.target;
    if (target.tagName.toLowerCase() === 'button') {
      isButton = true;
    } else {
      let parentElement = target.parentElement;
      while (
        parentElement &&
        parentElement.tagName.toLowerCase() !== 'button'
      ) {
        parentElement = parentElement.parentElement;
      }

      if (parentElement && parentElement.tagName.toLowerCase() === 'button') {
        isButton = true;
      }
    }
  }

  if (!isButton && !isLoading) {
    checkAndRefreshToken();
  }
};

export const checkAndRefreshToken = async () => {
  if (getTokenExpirationTime() <= refreshEnum.TIME_TO_REFRESH_TOKEN) {
    await onRefreshToken();
  }
};

import { AxiosError, AxiosResponse } from 'axios';
import api from '../config/index';
import FilterProductBarCode from '~/@core/domain/filters/productBarCode/FilterProductBarCode';
import ProductBarCode from '~/@core/domain/model/productBarCode/ProductBarCode';
import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';
import RegisterProductBarCodeDescription from '~/@core/domain/model/productBarCode/RegisterProductBarCodeDescription';
import ParamsProductBarCodeHistory from '~/@core/domain/model/productBarCode/ParamsProductBarCodeHistory';
import ProductBarCodeHistory from '~/@core/domain/model/productBarCode/ProductBarCodeHistory';
import CreateProductBarCodeDescription from '~/@core/domain/model/productBarCode/CreateProductBarCodeDescription';
import Dimensions from '~/@core/domain/model/productBarCode/Dimensions';
import UpdateDefaultDimension from '~/@core/domain/model/productBarCode/UpdateDefaultDimension';

export const fetchProductBarCode = (params: FilterProductBarCode) => {
  return new Promise<DefaultPaginated<ProductBarCode[]>>((resolve, reject) => {
    api
      .get<void, AxiosResponse<DefaultPaginated<ProductBarCode[]>>>(
        '/barcode',
        {
          params
        }
      )
      .then((response: AxiosResponse<DefaultPaginated<ProductBarCode[]>>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const fetchProductBarCodeHistories = (
  params: ParamsProductBarCodeHistory
) => {
  return new Promise<DefaultPaginated<ProductBarCodeHistory[]>>(
    (resolve, reject) => {
      api
        .get<void, AxiosResponse<DefaultPaginated<ProductBarCodeHistory[]>>>(
          `/barcode/history?Data.SiteId=${params.siteId}&Data.ProductId=${params.productId}`
        )
        .then(
          (
            response: AxiosResponse<DefaultPaginated<ProductBarCodeHistory[]>>
          ) => {
            resolve(response.data);
          }
        )
        .catch((error: AxiosError) => {
          reject(error);
        });
    }
  );
};

export const createProductBarCodeDescription = (
  params: RegisterProductBarCodeDescription
) => {
  return new Promise<AxiosResponse<void>>((resolve, reject) => {
    api
      .post<void, AxiosResponse<void>>('/barcode', params)
      .then((response: AxiosResponse<void>) => {
        resolve(response);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const updateProductBarCode = (
  params: CreateProductBarCodeDescription
) => {
  return new Promise<AxiosResponse<void>>((resolve, reject) => {
    api
      .put<void, AxiosResponse<void>>('/barcode', params)
      .then((response: AxiosResponse<void>) => {
        resolve(response);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const fetchDimensions = (siteId: number) => {
  return new Promise<AxiosResponse<Dimensions[]>>((resolve, reject) => {
    api
      .get<void, AxiosResponse<Dimensions[]>>(`/barcode/dimension/${siteId}`)
      .then((response: AxiosResponse<Dimensions[]>) => {
        resolve(response);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const updateDefaultDimension = (params: UpdateDefaultDimension) => {
  return new Promise<AxiosResponse<void>>((resolve, reject) => {
    api
      .put<void, AxiosResponse<void>>('/barcode/dimension-site', params)
      .then((response: AxiosResponse<void>) => {
        resolve(response);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

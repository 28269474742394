import { BannerProducerManagementListState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.ui
    .bannerProducerManagementList as BannerProducerManagementListState;
};

export const getIsLoading = createSelector(
  [getRoot],
  state =>
    state.fetchInitStatus === 'pending' ||
    state.fetchBannerProducerManagementStatus === 'pending' ||
    state.activeOrInactiveStatus === 'pending' ||
    state.fetchBannerImageStatus === 'pending'
);

export const getFetchStatus = createSelector([getRoot], state => {
  return state.fetchInitStatus;
});

export const getFetchBannerImageStatus = createSelector([getRoot], state => {
  return state.fetchBannerImageStatus;
});

export const getActiveOrInactiveStatus = createSelector([getRoot], state => {
  return state.activeOrInactiveStatus;
});

export const getFetchBannerProducerManagementStatus = createSelector(
  [getRoot],
  state => {
    return state.fetchBannerProducerManagementStatus;
  }
);

export const getPageNumber = createSelector([getRoot], state => {
  return state.pageNumber;
});

export const getPageSize = createSelector([getRoot], state => state.pageSize);

export const getTotalCount = createSelector(
  [getRoot],
  state => state.totalCount
);

export const getErrorMessage = createSelector([getRoot], state => {
  return state.errorMessage;
});

import { AxiosResponse } from 'axios';
import { endpoints } from '~/constants/endpoints';
import { ProductCategory } from '~/domain/api';
import api from '../api';

export const fetchRequest = async () => {
  const response = await api.get<any, AxiosResponse<ProductCategory[]>>(
    endpoints.product.categories
  );

  return response.data;
};

import { memoize } from 'lodash';
import { ProductState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.core.product as ProductState;
};
export const getProductsById = createSelector(
  [getRoot],
  state => state.productsById
);
export const getProductsByCategoryId = createSelector([getRoot], state =>
  memoize((categoryId: number) => {
    const values = Array.from(state.productsById.values());
    const productsByCategory = values.filter(product => {
      if (categoryId && !Number.isNaN(categoryId)) {
        return product.categoryId === categoryId;
      }
      return true;
    });
    return productsByCategory;
  })
);
export type FilterProduct = {
  categoryId: number;
  groupId: number;
};

export const getProductsByCategoryAndGroup = createSelector([getRoot], state =>
  memoize((filter: FilterProduct) => {
    const values = Array.from(state.productsById.values());
    const productsByCategory = values.filter(product => {
      if (
        filter.groupId &&
        filter.categoryId &&
        !Number.isNaN(filter.groupId) &&
        !Number.isNaN(filter.categoryId)
      ) {
        return (
          product.categoryId === filter.categoryId &&
          product.groupId === filter.groupId
        );
      }
      if (filter.groupId && !Number.isNaN(filter.groupId)) {
        return product.groupId === filter.groupId;
      }
      if (filter.categoryId && !Number.isNaN(filter.categoryId)) {
        return product.categoryId === filter.categoryId;
      }
      return true;
    });
    return productsByCategory;
  })
);

export const getGroupsByCategory = createSelector([getRoot], state =>
  memoize((categoryId: number) => {
    const products = Array.from(state.productsById.values());
    const filteredProducts = products.filter(product => {
      if (categoryId && !Number.isNaN(categoryId)) {
        return product.categoryId === categoryId;
      }
      return true;
    });
    const groups = state.productGroups.filter(group => {
      if (categoryId && !Number.isNaN(categoryId)) {
        const hasProduct = filteredProducts.find(
          product => product.groupId === group.id
        );
        return hasProduct !== undefined;
      }

      return true;
    });
    return groups;
  })
);

export const getDetailedRecipients = createSelector(
  [getRoot],
  state => state.detailedRecipients
);

export const getProductQualities = createSelector(
  [getRoot],
  state => state.productQualities
);

export const getProductGroups = createSelector(
  [getRoot],
  state => state.productGroups
);

export const getRecipients = createSelector(
  [getRoot],
  state => state.recipients
);
export const getRecipientsDetailed = createSelector(
  [getRoot],
  state => state.detailedRecipients
);

import produce from 'immer';
import { VoucherHistory, Voucher } from '~/domain/model';
import { createCreator, createReducer, Reducer } from '~/helpers/util/reducers';

export type FetchStatus = 'idle' | 'fetching' | 'success' | 'failure';

export type VoucherState = {
  voucherById: Map<string, Voucher>;
  voucherHistoryById: Map<string, VoucherHistory>;
  fetchStatus: FetchStatus;
};

export type CreateInit = {
  id?: number;
  description?: string;
  siteId?: number;
  reajustmentAmount?: number;
  reajustmentType?: string;
  quantityUnitMinimun?: number;
  quantityType?: string;
  observation?: string;
  isActive?: string;
  customerGroupId?: number;
  customersIds?: string[];
};

export type Update = {
  voucherById?: Map<string, Voucher>;
  voucherHistoryById?: Map<string, VoucherHistory>;
};

const prefix = '@voucher';

export const Types = Object.freeze({
  CLEAR: `${prefix}/CLEAR`,
  CLEAR_HISTORY: `${prefix}/CLEAR_HISTORY`,
  UPDATE: `${prefix}/UPDATE`,
  RELOAD_STATE: `${prefix}/RELOAD_STATE`
});

export const initialState: VoucherState = {
  voucherById: new Map(),
  voucherHistoryById: new Map(),
  fetchStatus: 'idle'
};

const clearReducer: Reducer<VoucherState, void> = state => {
  return produce(state, draft => {
    draft.voucherById = initialState.voucherById;
    draft.voucherHistoryById = initialState.voucherHistoryById;
    draft.fetchStatus = initialState.fetchStatus;
  });
};

const clearReducerHistory: Reducer<VoucherState, void> = state => {
  return produce(state, draft => {
    draft.voucherHistoryById = initialState.voucherHistoryById;
  });
};

const reloadStateReducer: Reducer<VoucherState, void> = state => {
  return produce(state, draft => {
    draft.voucherById = state.voucherById;
  });
};

const updateReducer: Reducer<VoucherState, Update> = (state, action) => {
  return produce(state, draft => {
    if (action.voucherById) {
      draft.voucherById = action.voucherById as Map<string, Voucher>;
    }
    if (action.voucherHistoryById) {
      draft.voucherHistoryById = action.voucherHistoryById as Map<
        string,
        VoucherHistory
      >;
    }
  });
};

export const actions = Object.freeze({
  clear: createCreator<void>(Types.CLEAR),
  clearHistory: createCreator<void>(Types.CLEAR_HISTORY),
  update: createCreator<Update>(Types.UPDATE),
  reloadStateReducer: createCreator<void>(Types.RELOAD_STATE)
});

export const reducer = createReducer(initialState, {
  [Types.CLEAR]: clearReducer,
  [Types.CLEAR_HISTORY]: clearReducerHistory,
  [Types.UPDATE]: updateReducer,
  [Types.RELOAD_STATE]: reloadStateReducer
});

import { CustomersState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.ui.customers as CustomersState;
};

export const getIsLoading = createSelector(
  [getRoot],
  state => state.requestStatus === 'running'
);

export const getIsLoadingCustomersSegment = createSelector(
  [getRoot],
  state => state.customersSegmentStatus === 'running'
);

export const getErrorMessage = createSelector([getRoot], state => {
  return state.errorMessage;
});

import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ProductPreferencesCreateAction,
  ProductPreferencesUpdateAction,
  Types
} from './duck';
import { Action } from '~/helpers/util/reducers';
import { actions } from '../productPreferencesList';
import * as ProductPreferencesService from '~/store/modules/_core/productPreferences/service';
import { alertRequest } from '../../alert/actions';

export function* createPreference(
  action: Action<ProductPreferencesCreateAction>
): any {
  try {
    yield put(actions.setLoading());
    yield call(ProductPreferencesService.create, action.payload);
    yield put(actions.fetchSuccess());
  } catch (err: any) {
    const message = JSON.stringify(err.response.data.Errors.Messages);
    yield put(alertRequest(message, 'error'));
    yield put(actions.setIdle());
  }
}
export function* updatePreference(
  action: Action<ProductPreferencesUpdateAction>
): any {
  try {
    yield put(actions.setLoading());
    yield call(ProductPreferencesService.update, action.payload);
    yield put(actions.fetchSuccess());
  } catch (err: any) {
    const message = JSON.stringify(err.response.data.Errors.Messages);
    yield put(alertRequest(message, 'error'));
    yield put(actions.setIdle());
  }
}
export default [
  takeLatest(Types.createPreference, createPreference),
  takeLatest(Types.updatePreference, updatePreference)
];

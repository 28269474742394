import { AxiosResponse } from 'axios';
import AutoserviceClassificationListParam from '~/domain/model/AutoserviceClassificationListParam';
import api from '../api';
import { endpoints } from '~/constants/endpoints';
import * as ClassificationModels from '../../domain/model/RatingPayload';

export const findProducersWhiteList = async (
  params: AutoserviceClassificationListParam
) => {
  const response = await api.get<void, AxiosResponse<any>>(
    endpoints.selfService.witheListsearch,
    { params }
  );
  return response.data;
};

export const findProducersBlackList = async (customerId: number) => {
  const response = await api.get<void, AxiosResponse<any>>(
    `${endpoints.selfService.blackListSearch}${customerId}`
  );
  return response.data;
};

export const postProducerClassificationRequest = async (
  payload: ClassificationModels.RatingPayload
) => {
  const response = await api.post(
    endpoints.selfService.postClassification,
    payload
  );
  return response;
};

export const postItemBlackOrWhitelistRequest = async (
  payload: ClassificationModels.RatingAddPayload
) => {
  const response = await api.put(
    `${endpoints.selfService.updatePriority}${payload.ratingId}`
  );
  return response;
};

export const ratingUpdateRequest = async (
  payload: ClassificationModels.RatingUpdatePayload
) => {
  const response = await api.put(
    `${endpoints.selfService.updateRating}${payload.ratingId}/${payload.rating}`
  );
  return response;
};

export const RemoveRequest = async (payload: number) => {
  const response = await api.put(
    `${endpoints.selfService.deleteProducer}${payload}`
  );
  return response;
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { handleDefaultErrorApi } from '~/helpers/util';
import * as announcementService from '~/services/producer/Announcement';
import AnnouncementsFetchParams from '~/domain/model/Announcements/AnnouncementsFetchParams';

export const announcementsFetch = createAsyncThunk(
  'announcement/announcementsFetch',
  async (params: AnnouncementsFetchParams, { rejectWithValue }) => {
    try {
      const response = await announcementService.announcementsFetch(params);
      return response.data;
    } catch (error) {
      handleDefaultErrorApi(error as AxiosError);
      return rejectWithValue([]);
    }
  }
);

export const announcementFilesFetchById = createAsyncThunk(
  'announcement/announcementFilesFetchById',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await announcementService.getFilesById(id);
      return response.data;
    } catch (error) {
      handleDefaultErrorApi(error as AxiosError);
      return rejectWithValue([]);
    }
  }
);

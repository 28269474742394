/* eslint-disable no-shadow */
import produce from 'immer';

import type { ISiteState, ISite } from './types';

const INITIAL_STATE: ISiteState = {
  sites: [],
  sitesSelected: [],
  site: {} as ISite,
  isProducer: false,
  producerId: 0
};

export default function site(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case '@site/SET_PRODUCTOR_REQUEST':
      return produce(state, draft => {
        draft.isProducer = true;
        draft.producerId = action.payload?.id;
      });

    case '@site/SET_VEILING_REQUEST':
      return produce(state, draft => {
        draft.isProducer = false;
      });

    case '@site/SEARCH_SUCCESS':
      return produce(state, draft => {
        draft.sites = action.payload;
        draft.sitesSelected = action.payload;
        if (action.payload.length === 1) {
          draft.site = action.payload[0];
        }
      });
    case '@site/SEARCH_FAILURE':
      return produce(state, draft => {
        draft.sites = [];
        draft.sitesSelected = [];
      });
    case '@site/SEARCH_SELECTEDS': {
      return produce(state, draft => {
        draft.sitesSelected = draft.sites.filter((site: any) => {
          let selectText = `${site.account} - ${site.corporateName} (${site.tradingName})`;
          selectText = selectText.toLocaleLowerCase();
          if (
            selectText.includes(action.payload.search.toLowerCase()) ||
            action.payload.search.length === 0
          ) {
            return site;
          }
        });
      });
    }
    case '@site/SET_SITE':
      return produce(state, draft => {
        const sites = draft.sites.filter(
          (site: any) => site.id === action.payload.siteId
        );
        draft.site = sites.length > 0 ? sites[0] : ({} as ISite);
      });
    case '@site/REMOVE_SITES':
      return produce(state, draft => {
        draft.sites = [];
        draft.sitesSelected = [];
      });
    default:
      return state;
  }
}

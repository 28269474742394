import { FormInstance, Table } from 'antd';
import { Frame, DividerFrame } from '~/components/Globals';
import useFrequencyStore from '~/zustand/rfidReports/inventory/frequency-state';

interface Props {
  form: FormInstance<any>;
}
export default function TableInventoryFrequency(props: Props) {
  const { frequencies, frequencyPagination, handleFrequencyPagination } =
    useFrequencyStore();
  const { form } = props;

  return (
    <Frame ghost accordion defaultActiveKey="1">
      <Frame.Panel
        header={
          <DividerFrame orientation="left">Inventários Realizados</DividerFrame>
        }
        key="1"
      >
        <Table
          dataSource={frequencies}
          pagination={{
            pageSize: frequencyPagination.pageSize,
            current: frequencyPagination.current,
            showSizeChanger: true,
            total: frequencyPagination.total,
            onChange(page, pageSize) {
              handleFrequencyPagination({
                ...frequencyPagination,
                current: page,
                pageSize
              });
              form.submit();
            }
          }}
        >
          <Table.Column dataIndex="producer" title="Produtor" />
          <Table.Column
            dataIndex="totalInventories"
            title="Total Inventários"
          />
          <Table.Column dataIndex="incorfomity" title="% Inconformidade" />
        </Table>
      </Frame.Panel>
    </Frame>
  );
}

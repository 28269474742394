import IUseCase from '~/@core/application/IUseCase';
import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';
import FilterText from '~/@core/domain/filters/rfidReports/FilterText';
import Owned from '~/@core/domain/model/rfidReports/Owned';
import { InventoryService } from '~/@core/infrastructure/api/services/rfidReports';

export default class GetOwneds
  implements IUseCase<FilterText, Promise<DefaultPaginated<Owned[]>>>
{
  execute(filter: FilterText): Promise<DefaultPaginated<Owned[]>> {
    return InventoryService.fetchOwned(filter);
  }
}

import * as API from '../api';

export default class ProductionProductGroup {
  private readonly json: API.ProductionProductGroup;
  public readonly id: number;
  public readonly productCategoryId: number;
  public readonly groupCode: string;
  public readonly groupDescription: string;

  constructor(json: API.ProductionProductGroup) {
    this.json = json;
    this.id = this.getId();
    this.productCategoryId = this.getProductCategoryId();
    this.groupCode = this.getGroupCode();
    this.groupDescription = this.getGroupDescription();
  }

  public toJSON() {
    return this.json;
  }

  private getId() {
    return this.json.id || Number.NaN;
  }

  private getProductCategoryId() {
    return this.json.productCategoryId || Number.NaN;
  }

  private getGroupCode() {
    return this.json.groupCode || '';
  }

  private getGroupDescription() {
    return this.json.groupDescription || '';
  }
}

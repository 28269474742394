import { MessagesEnum } from '~/domain/enum/messagesErrors/productionPlanning/messages.enum';

interface IHash {
  [key: string]: string;
}

const messagesErrors: IHash = {};

messagesErrors[MessagesEnum.WorksheeStructureOutRang] =
  'Planilha importada com sucesso, porém existem valores fora do range liberado.';

export const fillMessageError = (key: string): string => {
  return (
    messagesErrors[key] ??
    'Não foi possível concluir a operação, tente mais tarde.'
  );
};
export const generateErrorMessage = (messages: string[]): string => {
  let completeMessage = '';
  messages.forEach(message => (completeMessage += message));
  return completeMessage;
};

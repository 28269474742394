/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VolOffer } from '~/domain/model';

export type Status = 'cleared' | 'updated';

export type DirectedOffersState = {
  directedOffers: VolOffer[];
  directedOffersList: VolOffer[];
  status: Status;
  disabledSave: boolean;
};

export type SetPropertyAction = {
  value: any;
  property: string;
  index: number;
};
export type UpdateDirectedOffers = {
  directedOffers: VolOffer[];
};

export type SetComposition = {
  index: number;
  compositionId: number;
  checked: boolean;
};

const prefix = '@directedOffers';

export const initialState: DirectedOffersState = {
  directedOffers: [],
  directedOffersList: [],
  status: 'cleared',
  disabledSave: true
};

const directedOffers = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: state => {
      state.directedOffers = initialState.directedOffers;
      state.directedOffersList = initialState.directedOffersList;
      state.status = initialState.status;
      state.disabledSave = true;
    },
    updateVolOffersLine: (state, action: PayloadAction<VolOffer[]>) => {
      const updatedList = state.directedOffersList.map(offer => {
        const offerInResponse = action.payload.find(
          offerResponse => offerResponse.volOfferId === offer.volOfferId
        );
        if (offerInResponse) {
          return new VolOffer({
            ...offerInResponse,
            index: offer.index,
            defaultOfferImageUrl: offer.defaultOfferImageUrl
          });
        }
        return offer;
      });
      state.directedOffersList = updatedList;
      state.disabledSave = true;
    },
    setPropertyValue: (state, action: PayloadAction<SetPropertyAction>) => {
      const { payload } = action;
      const updatedOffers = state.directedOffersList.map(
        (offer, index: number) => {
          if (payload.index === index) {
            return {
              ...offer,
              toSend: true,
              [payload.property]: payload.value
            } as VolOffer;
          }
          return { ...offer } as VolOffer;
        }
      );
      state.directedOffersList = updatedOffers;
      state.disabledSave = false;
    },
    enableSave: state => {
      state.disabledSave = false;
    },
    disableSave: draft => {
      draft.disabledSave = initialState.disabledSave;
    },
    updateDirectedOffersList: (state, action: PayloadAction<VolOffer[]>) => {
      state.directedOffersList = action.payload;
    },
    updateDirectedOffers: (
      state,
      action: PayloadAction<UpdateDirectedOffers>
    ) => {
      state.directedOffers = action.payload.directedOffers;
    },
    setCompositions: (state, action: PayloadAction<SetComposition>) => {
      const { payload } = action;
      if (!payload.checked) {
        return {
          ...state,
          disabledSave: false,
          directedOffersList: state.directedOffersList.map(
            (offer, index: number) => {
              if (index === payload.index) {
                const packingDistributionCompositionIds = [
                  ...offer.packingDistributionCompositionIds
                ];
                const packings = packingDistributionCompositionIds.filter(
                  (compositionId: number, _indexComposition: number) => {
                    if (compositionId === payload.compositionId) {
                      return false;
                    }
                    return true;
                  }
                );
                return {
                  ...offer,
                  packingDistributionCompositionIds: packings,
                  productPreference: {
                    ...offer.productPreference,
                    availablePackingDistributionCompositions:
                      offer.productPreference.availablePackingDistributionCompositions.map(
                        available => {
                          if (available.id === payload.compositionId) {
                            return { ...available, selected: payload.checked };
                          }
                          return available;
                        }
                      )
                  },
                  toSend: true
                };
              }
              return offer;
            }
          )
        };
      }
      return {
        ...state,
        disabledSave: false,
        directedOffersList: state.directedOffersList.map(
          (offer, index: number) => {
            if (index === payload.index) {
              const packingDistributionCompositionIds = [
                ...offer.packingDistributionCompositionIds
              ];
              packingDistributionCompositionIds.push(payload.compositionId);
              return {
                ...offer,
                packingDistributionCompositionIds,
                productPreference: {
                  ...offer.productPreference,
                  availablePackingDistributionCompositions:
                    offer.productPreference.availablePackingDistributionCompositions.map(
                      available => {
                        if (available.id === payload.compositionId) {
                          return { ...available, selected: payload.checked };
                        }
                        return available;
                      }
                    )
                },
                toSend: true
              };
            }
            return offer;
          }
        )
      };
    }
  }
});
export const actions = directedOffers.actions;
export const reducer = directedOffers.reducer;

const caseReducers = directedOffers.caseReducers;
export const Types = Object.freeze({
  clear: `${prefix}/${caseReducers.clear.name}`,
  updateDirectedOffersList: `${prefix}/${caseReducers.updateDirectedOffersList.name}`
});

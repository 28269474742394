import produce from 'immer';

const INITIAL_STATE = {
  startWeek: '',
  endWeek: '',
  weekId: '',
  competencyId: ''
};

export default function dates(state = INITIAL_STATE, action: any) {
  return produce(state, draft => {
    switch (action.type) {
      case '@dates/SEARCH_WEEK_ID_SUCCESS': {
        draft.weekId = action.payload[0].id;
        break;
      }
      case '@dates/SET_COMPETENCY_ID': {
        draft.competencyId = action.payload;
        break;
      }
      case '@dates/CHANGE_DATES': {
        draft.startWeek = action.payload.startFormat;
        draft.endWeek = action.payload.endFormat;
        break;
      }
      default:
    }
  });
}

import { AxiosResponse } from 'axios';

import { APIError } from '~/domain/api/errors';

import apiProducer from '../api';

export const fetchBannerApproval = async (
  description?: string,
  url?: string,
  status?: string,
  bannerTypeId?: number,
  uploadDate?: string,
  pageNumber?: number,
  pageSize?: number
) => {
  try {
    const params = new URLSearchParams();
    if (description) {
      params.append('data.description', description);
    }
    if (url) {
      params.append('data.url', url);
    }
    if (bannerTypeId) {
      params.append('data.bannerTypeId', String(bannerTypeId));
    }
    if (status) {
      params.append('data.status', status);
    }
    if (uploadDate) {
      params.append('data.uploadDate', uploadDate);
    }
    if (pageNumber) {
      params.append('pageNumber', String(pageNumber));
    }
    if (pageSize) {
      params.append('pageSize', String(pageSize));
    }
    const response: AxiosResponse = await apiProducer.get('/link-site-image', {
      params
    });
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const approveOrDisapproveBanner = async (
  id: number,
  status: string,
  message?: string
) => {
  try {
    const response: any = await apiProducer.put('/link-site-image/approve', {
      id,
      status,
      message
    });
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 422, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

import { AxiosError, AxiosResponse } from 'axios';
import api from '../config/index';

export const finishOrder = (ids: number[]) => {
  return new Promise<AxiosResponse<void>>((resolve, reject) => {
    api
      .put<void, AxiosResponse<void>>('/order-management/finish-order', ids)
      .then((response: AxiosResponse<void>) => {
        resolve(response);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const cancelOrder = (ids: number[]) => {
  return new Promise<AxiosResponse<void>>((resolve, reject) => {
    api
      .put<void, AxiosResponse<void>>('/order-management/cancel', ids)
      .then((response: AxiosResponse<void>) => {
        resolve(response);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

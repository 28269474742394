import { Card, Collapse, Table } from 'antd';
import styled from 'styled-components';

export const CheckboxContainer = styled.label`
  display: inline-block;
  padding: 8px;
  cursor: pointer;
  .ant-checkbox {
    padding: 50px;
    display: none;
  }
`;

export const StyledTable = styled(Table)`
  .ant-table-tbody > td {
    background: #f5f5f5;
  }

  .ant-table-row-expand-icon-cell {
    display: none !important;
  }

  .printed-row {
    background-color: #d4edda !important;
  }

  .printed-row:hover {
    background-color: #d4edda !important;
  }

  .ant-table-tbody > tr:not(.printed-row):hover > td {
    background-color: #e6f7ff;
  }

  .ant-table-tbody > tr.printed-row:hover > td {
    background-color: #d4edda !important;
  }

  .ant-table-cell-row-hover {
    transition: none !important;
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: #d4edda !important;
  }
`;

export const GroupTitleInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const SpanLabel = styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  color: #8c8c8c;
`;

export const StyledText = styled.p`
  padding: 0;
  margin: 0;
`;

export const FrameStyled = styled(Collapse)`
  font: 16px 'Roboto', sans-serif;
  font-weight: 400;
  .ant-collapse-header {
    display: flex;
    flex-wrap: wrap !important;
  }
`;

export const CardContainer = styled(Card)`
  margin-bottom: 16px;
  .ant-card-body {
    padding: 10px;
  }
`;

export const FlexContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.div`
  width: 300px;
`;

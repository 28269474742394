import IUseCase from '../../../IUseCase';
import { ProductService } from '~/@core/infrastructure/api/services';
import RecipientDetailed from '~/@core/domain/model/product/RecipientDetailed';

export default class GetRecipientsDetailed
  implements IUseCase<number, Promise<RecipientDetailed[]>>
{
  execute(siteId: number): Promise<RecipientDetailed[]> {
    return ProductService.fetchRecipientsDetailed(siteId);
  }
}

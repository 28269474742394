// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import moment from 'moment';
import {
  CreateStockParams,
  FetchStocksRegisterParams,
  FindStockParams,
  StockMassUpdateParams,
  StockReplicateParams,
  StocksUpdate,
  StocksUpdateChannel,
  StockUpdateError
} from '~/domain/model';
import Stocks from '~/domain/model/Stock/Stocks';
import StockConvertQuality from '~/domain/model/StockConvertQuality';
import { generateErrorMessage } from '~/helpers/util/error';
import {
  convertQualityApi,
  createStocksRegisterApi,
  findHistoric,
  findOfferChannel,
  findStocks,
  findStocksRegister,
  replicateStockApi,
  updateStockMassApi,
  updateStocksApi
} from '~/services/producer/Stock';
import { actions as actionsStock, StocksState } from '..';
import { actions } from '../../_ui/loading';
import { alertRequest } from '../../alert/actions';

export const fetchStocks = createAsyncThunk(
  `stocks/fetchStocks`,
  async (params: FindStockParams, { dispatch, rejectWithValue }) => {
    try {
      const stocksData = await findStocks(params);
      console.log('apicalls', stocksData);
      return stocksData;
    } catch (err: unknown) {
      const errorMessage = generateErrorMessage(err, 'Erro ao buscar estoque.');
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const updateStocks = createAsyncThunk(
  `stocks/updateStocks`,
  async (
    updateStockParams: StocksUpdate,
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const response = await updateStocksApi(updateStockParams);
      dispatch(actions.setCloseModal(true));
      dispatch(actions.setSubmitForm(true));
      dispatch(alertRequest('Estoque atualizado com sucesso!', 'success'));
      return response.data.results;
    } catch (err: unknown) {
      const error = err as AxiosError;

      if (error.response?.status === 422) {
        const errorData = error.response?.data as StockUpdateError[];

        const state: any = getState();
        const stocks = state.stocks as StocksState;
        dispatch(
          alertRequest(
            handleMassChannelsError(errorData, stocks.stocks),
            'error'
          )
        );
        return rejectWithValue([]);
      }
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao atualizar estoque.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);
export const updateStocksChannels = createAsyncThunk(
  `stocks/updateStocksChannels`,
  async (
    data: { updateStockParams: StocksUpdateChannel; isLKP: boolean },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await updateStocksApi(data.updateStockParams);
      dispatch(actions.setCloseModal(true));
      dispatch(actions.setSubmitForm(true));
      dispatch(alertRequest('Estoque atualizado com sucesso!', 'success'));
      dispatch(fetchOfferChannel(data.updateStockParams.stockId));
      return response.data.results;
    } catch (err: unknown) {
      const error = err as AxiosError;

      if (error.response?.status === 422) {
        const errorData = error.response?.data as StockUpdateError[];

        const stocks = [
          {
            productDescription: data.updateStockParams.productDescription,
            qualityDescription: data.updateStockParams.qualityDescription,
            stockId: data.updateStockParams.stockId
          }
        ] as Stocks[];
        dispatch(
          alertRequest(handleMassChannelsError(errorData, stocks), 'error')
        );
        return rejectWithValue([]);
      }
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao atualizar estoque.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchStocksRegister = createAsyncThunk(
  `stocks/fetchStocksRegister`,
  async (
    fetchStocksRegisterParams: FetchStocksRegisterParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const stocksRegistersData = await findStocksRegister({
        SiteId: fetchStocksRegisterParams.siteId,
        WeekDay: fetchStocksRegisterParams.vigency,
        PlannedOnly: fetchStocksRegisterParams.plannedOnly
      });
      return stocksRegistersData;
    } catch (err: unknown) {
      const errorMessage = generateErrorMessage(err, 'Erro ao buscar estoque.');
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

export const createStocksRegister = createAsyncThunk(
  `stocks/createStocksRegister`,
  async (
    createStockParams: CreateStockParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await createStocksRegisterApi(createStockParams);
      dispatch(alertRequest('Estoque criado com sucesso!', 'success'));
      const weekCreatedDate = moment(createStockParams.weekDay);
      dispatch(actionsStock.setWeekCreatedDate(weekCreatedDate));
      return response.data.results;
    } catch (err: unknown) {
      const errorMessage = generateErrorMessage(err, 'Erro ao criar estoque.');
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);
export const fetchOfferChannel = createAsyncThunk(
  `stocks/fetchOfferChannel`,
  async (stockId: number, { dispatch, rejectWithValue }) => {
    try {
      const stockOfferChannel = await findOfferChannel(stockId);
      return stockOfferChannel;
    } catch (err: unknown) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao buscar canais de oferta.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);
export const updateStockMassChannels = createAsyncThunk(
  `stocks/updateStockMassChannels`,
  async (
    updateStockMassParams: StockMassUpdateParams,
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const response = await updateStockMassApi(updateStockMassParams);
      dispatch(actions.setCloseModal(true));
      dispatch(alertRequest('Estoque atualizado com sucesso!', 'success'));
      return response.data.results;
    } catch (err: unknown) {
      const error = err as AxiosError;
      const errorData = error?.response?.data as StockUpdateError[];
      const state: any = getState();
      const stocks = state.stocks as StocksState;

      if (error.response?.status === 422) {
        dispatch(
          alertRequest(
            handleMassChannelsError(errorData, stocks.stocks),
            'error'
          )
        );
        return rejectWithValue({});
      }
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao atualizar estoque.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);
const handleMassChannelsError = (
  errors: StockUpdateError[],
  stocks: Stocks[]
) => {
  let message = '';
  errors.forEach(error => {
    const stock = stocks.find(stockLoop => stockLoop.stockId === error.stockId);
    if (!error.volPercentageValidated) {
      message += `O ${stock?.productDescription} - ${stock?.qualityDescription} precisa no mínimo ${error.minimumVolPercentage}% no ENP<br/>`;
    }
    if (!error.lkpPercentageValidated) {
      message += `O ${stock?.productDescription} - ${stock?.qualityDescription} precisa no mínimo ${error.minimumLkpPercentage}% no LKP<br/>`;
    }
    if (!error.quantityValidated) {
      message += `O ${stock?.productDescription} - ${stock?.qualityDescription} precisa no mínimo ${error.minimumQuantityAllowed} de quantidade<br/>`;
    }
    if (!error.klokHoardValidated) {
      if (error.minimumKlokHoardAllowed > 0) {
        message += `O ${stock?.productDescription} - ${stock?.qualityDescription} A quantidade do KLOK total não deverá ser menor que ${error.minimumKlokHoardAllowed}`;
      }
      if (error.maximumKlokHoardAllowed > 0) {
        message += `O ${stock?.productDescription} - ${stock?.qualityDescription} A quantidade do KLOK total não deverá ser superior a quantidade do Estoque atual`;
      }
    }
    if (!error.volHoardValidated) {
      if (error.minimumVolHoardAllowed > 0) {
        message += `O ${stock?.productDescription} - ${stock?.qualityDescription} A quantidade do ENP total não deverá ser menor que ${error.minimumKlokHoardAllowed}`;
      }
      if (error.maximumVolHoardAllowed > 0) {
        message += `O ${stock?.productDescription} - ${stock?.qualityDescription} A quantidade do ENP total não deverá ser superior a quantidade do Estoque atual`;
      }
    }
  });
  return message;
};

export const updateStockMassAdjust = createAsyncThunk(
  `stocks/updateStockMassAdjust`,
  async (
    updateStockMassParams: StockMassUpdateParams,
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const response = await updateStockMassApi(updateStockMassParams);
      dispatch(actions.setCloseModal(true));
      dispatch(alertRequest('Estoque atualizado com sucesso!', 'success'));
      return response.data.results;
    } catch (err: unknown) {
      const error = err as AxiosError;
      const errorData = error?.response?.data as StockUpdateError[];
      const state: any = getState();
      const stocks = state.stocks as StocksState;

      dispatch(
        alertRequest(handleAdjustError(errorData, stocks.stocks), 'error')
      );
      return rejectWithValue({});
    }
  }
);

const handleAdjustError = (errors: StockUpdateError[], stocks: Stocks[]) => {
  let message = '';
  errors.forEach(error => {
    const stock = stocks.find(stockLoop => stockLoop.stockId === error.stockId);

    if (!error.quantityValidated) {
      message += `Quantidade  para ${stock?.productDescription} - ${stock?.qualityDescription} invalida<br/>`;
    }
  });
  return message;
};

export const replicateStock = createAsyncThunk(
  `stocks/replicateStock`,
  async (
    stockReplicateParams: StockReplicateParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await replicateStockApi(stockReplicateParams);
      dispatch(actions.setCloseModal(true));
      dispatch(alertRequest('Estoque replicado com sucesso!', 'success'));
      return response;
    } catch (err: unknown) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao replicar estoque.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);
export const convertQuality = createAsyncThunk(
  `stocks/convertQuality`,
  async (
    stockConvertQualityParams: StockConvertQuality,
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const response = await convertQualityApi(stockConvertQualityParams);
      dispatch(actions.setCloseModal(true));
      dispatch(alertRequest('Qualidade convertida com sucesso!', 'success'));
      return response;
    } catch (err: unknown) {
      const error = err as AxiosError;

      if (error.response?.status === 422) {
        const errorData = error?.response?.data as StockUpdateError[];
        const state: any = getState();
        const stocks = state.stocks as StocksState;
        dispatch(
          alertRequest(
            handleConvertQualityError(errorData, stocks.stocks),
            'error'
          )
        );
        return rejectWithValue({});
      }
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao converter qualidade.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);
const handleConvertQualityError = (
  errors: StockUpdateError[],
  stocks: Stocks[]
) => {
  let message = '';
  errors.forEach(error => {
    const stock = stocks.find(stockLoop => stockLoop.stockId === error.stockId);
    if (!error.quantityValidate) {
      message += `O ${stock?.productDescription} - ${stock?.qualityDescription} A quantidade é invalída.<br/>`;
    }
  });
  return message;
};

export const fetchHistoric = createAsyncThunk(
  `stocks/fetchHistoric`,
  async (stockId: number, { dispatch, rejectWithValue }) => {
    try {
      const data = await findHistoric(stockId);
      return data;
    } catch (err: unknown) {
      const errorMessage = generateErrorMessage(
        err,
        'Erro ao buscar histórico.'
      );
      dispatch(alertRequest(errorMessage, 'error'));
      return rejectWithValue(errorMessage);
    }
  }
);

import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '~/services/api';

import { searchCompetenceSuccess, searchCompetenceFailure } from './actions';

export function* onSearchCompetences(): any {
  try {
    const response = yield call(api.get, `/competence`);
    const { data } = response;
    yield put(searchCompetenceSuccess(data));
  } catch (err) {
    yield put(searchCompetenceFailure());
  }
}

export default all([
  takeLatest('@product/SEARCH_COMPETENCES', onSearchCompetences)
]);

import { FormInstance, Table, Tooltip } from 'antd';
import CartExpedition from '~/@core/domain/model/rfidReports/CartExpedition';
import ExpeditionDone from '~/@core/domain/model/rfidReports/ExpeditionDone';
import { Frame, DividerFrame, StatusCircle } from '~/components/Globals';
import useNonConformityStore from '~/zustand/rfidReports/expedition/nonConformity-state';

interface Props {
  form: FormInstance<any>;
}
export default function TableExpeditionNonConformity(props: Props) {
  const {
    nonConformities,
    nonConformityPagination,
    handleNonConformityPagination
  } = useNonConformityStore();
  const { form } = props;

  return (
    <Frame ghost accordion defaultActiveKey="1">
      <Frame.Panel
        header={
          <DividerFrame orientation="left">
            Expedições com Inconformidades
          </DividerFrame>
        }
        key="1"
      >
        <Table
          dataSource={nonConformities}
          pagination={{
            pageSize: nonConformityPagination.pageSize,
            current: nonConformityPagination.current,
            showSizeChanger: true,
            total: nonConformityPagination.total,
            onChange(page, pageSize) {
              handleNonConformityPagination({
                ...nonConformityPagination,
                current: page,
                pageSize
              });
              form.submit();
            }
          }}
          expandable={{
            expandedRowRender: expeditionNonConformities => (
              <Table
                dataSource={expeditionNonConformities.carts}
                expandable={{
                  expandedRowRender: expeditionNonConformitiesItems => (
                    <Table
                      dataSource={expeditionNonConformitiesItems.acessories}
                      bordered
                    >
                      <Table.Column
                        dataIndex="productCode"
                        title="Código Material"
                      />
                      <Table.Column dataIndex="type" title="Tipo Material" />
                      <Table.Column
                        dataIndex="results"
                        title="Resultado/Esperado"
                      />
                      <Table.Column
                        dataIndex="nonConformity"
                        title="Inconformidade"
                      />
                    </Table>
                  )
                }}
                bordered
              >
                <Table.Column dataIndex="codeGFP" title="GFP" />
                <Table.Column dataIndex="sequency" title="Sequência" />
                <Table.Column
                  dataIndex="numberMC"
                  title="Total MC Realizado/Esperado"
                />
                <Table.Column
                  dataIndex="status"
                  render={(_, carts: CartExpedition) =>
                    carts.status !== '2' ? (
                      <Tooltip>
                        <StatusCircle color="#FA541C" />
                        <span>{carts.statusDescription}</span>
                      </Tooltip>
                    ) : (
                      <Tooltip>
                        <StatusCircle color="#52C41A" />
                        <span>{carts.statusDescription}</span>
                      </Tooltip>
                    )
                  }
                  title="Status"
                />
              </Table>
            )
          }}
        >
          <Table.Column
            dataIndex="status"
            render={(_, expedition: ExpeditionDone) =>
              expedition.status !== '2' ? (
                <Tooltip>
                  <StatusCircle color="#FA541C" />
                </Tooltip>
              ) : (
                <Tooltip>
                  <StatusCircle color="#52C41A" />
                </Tooltip>
              )
            }
            title="Status"
          />
          <Table.Column dataIndex="expeditionDate" title="Data Expedição" />
          <Table.Column dataIndex="receiptDate" title="Data Recebimento" />
          <Table.Column dataIndex="area" title="Area" />
          <Table.Column dataIndex="productCode" title="Serial do Carrinho" />
          <Table.Column dataIndex="numberGFP" title="Total GFP" />
          <Table.Column dataIndex="numberMC" title="MC" />
        </Table>
      </Frame.Panel>
    </Frame>
  );
}

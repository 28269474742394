import { all, call, put, takeLatest } from 'redux-saga/effects';

import { actions as actionsLoading } from '../_ui/loading';
import { apiGeo as api } from '~/services/api';
import { isNullOrEmpty, stringClear } from '~/helpers/util';

import { alertRequest } from '../alert/actions';

import {
  localizationSearchSuccess,
  localizationSearchFailure,
  localizationStateSuccess,
  localizationStateFailure,
  localizationCitySuccess,
  localizationCityFailure
} from './actions';

export function* onSearch({ payload }) {
  try {
    const { cep } = payload;

    let response = yield call(api.get, `/ZipCode/localization/${cep}`);

    const data = response.data;

    response = yield call(api.get, `/city?stateId=${data.stateId}`);

    const cities = response.data;

    yield put(localizationSearchSuccess(data, cities));
    yield put(
      actionsLoading.setLoading({
        status: 'idle'
      })
    );
  } catch (e) {
    const err = !Object(e)?.response?.data
      ? String(e)
      : Object(e).response.data;

    if (!isNullOrEmpty(stringClear(payload.cep, '1234567890'))) {
      yield put(alertRequest(err, 'error'));
    }

    yield put(localizationSearchFailure());
    yield put(
      actionsLoading.setLoading({
        status: 'idle'
      })
    );
  }
}

export function* onState() {
  try {
    const response = yield call(api.get, `/State`);

    const data = response.data;

    yield put(localizationStateSuccess(data));
    yield put(
      actionsLoading.setLoading({
        status: 'idle'
      })
    );
  } catch (err) {
    yield put(localizationStateFailure());
    yield put(
      actionsLoading.setLoading({
        status: 'idle'
      })
    );
  }
}

export function* onCity({ payload }) {
  try {
    const { stateId } = payload;

    const response = yield call(api.get, `/City/?stateId=${Number(stateId)}`);

    const data = response.data;

    yield put(localizationCitySuccess(data));
    yield put(
      actionsLoading.setLoading({
        status: 'idle'
      })
    );
  } catch (err) {
    yield put(localizationCityFailure());
    yield put(
      actionsLoading.setLoading({
        status: 'idle'
      })
    );
  }
}

export default all([
  takeLatest('@localization/SEARCH_REQUEST', onSearch),
  takeLatest('@localization/STATE_REQUEST', onState),
  takeLatest('@localization/CITY_REQUEST', onCity)
]);

// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
import produce from 'immer';

const prodPlan: any = {};
const selectLocals: any[] = [];
const selectSections: any[] = [];
const history: any[] = [];
const historyDates: any[] = [];
const selectedProperties: any[] = [];
const expandRowsKeys: number[] = [];
const planWeeks: any[] = [];
const INITIAL_STATE = {
  productionPlan: prodPlan,
  pageSize: 250,
  pageNumber: 1,
  totalGroupRows: 0,
  loading: false,
  selectLocals,
  selectSections,
  history,
  historyDates,
  loadingHistory: false,
  validSave: false,
  validWeekSave: false,
  searchEnabled: false,
  failure: false,
  success: false,
  successDelete: false,
  successReplicate: false,
  failureReplicate: false,
  failureReplicateMessage: '',
  successReplicateWeek: false,
  failureReplicateWeek: false,
  invalid: false,
  saving: false,
  disableCalendar: true,
  calendarMessage: 'Escolha uma chave antes',
  alertMessage: '',
  replicatingLastYear: false,
  replicatingWeek: false,
  selectedProperties,
  pendentSelection: [],
  selectedRowsProduct: {},
  planWeeks,
  expandRowsKeys,
  errorMessage: ''
};

export default function productionPlan(state = INITIAL_STATE, action: any) {
  return produce(state, draft => {
    switch (action.type) {
      case '@productionPlan/ADD_PRODUCTION_PLAN_SECTION': {
        break;
      }

      case '@productionPlan/ADD_PRODUCTION_PLAN_SUCCESS': {
        draft.failure = false;
        draft.loading = false;
        draft.saving = false;
        draft.success = true;
        break;
      }

      case '@productionPlan/ADD_PRODUCTION_PLAN_FAILURE': {
        draft.failure = true;
        draft.success = false;
        draft.loading = false;
        draft.saving = false;

        break;
      }
      case '@productionPlan/SEARCH_PRODUCTION_PLAN_SECTION': {
        break;
      }
      case '@productionPlan/SEARCH_PRODUCTION_PLAN_SUCCESS': {
        draft.productionPlan = {};
        draft.productionPlan = action.payload;
        draft.totalGroupRows = action.payload.totalCount;
        draft.loading = false;
        draft.expandRowsKeys = [];
        break;
      }
      case '@productionPlan/SEARCH_PRODUCTION_PLAN_MESSAGE': {
        draft.productionPlan = {};
        draft.loading = false;
        draft.alertMessage = action.payload.alertMessage;
        break;
      }
      case '@productionPlan/SEARCH_PRODUCTION_PLAN_FAILURE': {
        draft.failure = true;
        draft.success = false;
        draft.loading = false;
        draft.errorMessage = action.payload.error;
        break;
      }
      case '@productionPlan/CHANGE_TOTAL_PLAN': {
        draft.productionPlan.productionPlanningGroups[
          action.payload.index
        ].quantity = action.payload.value;
        draft.productionPlan.productionPlanningGroups[
          action.payload.index
        ].toSend = true;
        draft.validSave = true;
        break;
      }

      case '@productionPlan/SET_VALID_SAVE': {
        draft.validSave = action.payload;
        break;
      }
      case '@productionPlan/SET_SELECT_LOCALS': {
        const selecteds = draft.selectLocals.filter(
          local =>
            local.index !== action.payload.index &&
            local.indexGroup !== action.payload.indexGroup
        );

        selecteds.push({
          index: action.payload.index,
          productionPlanningGroupProductId:
            action.payload.productionPlannningGroupProduct
              .productionPlanningGroupProductId,
          groupKey: action.payload.indexGroup,
          locals: action.payload.propertySelected.locals
        });
        draft.selectLocals = selecteds;

        break;
      }
      case '@productionPlan/SET_SECTIONS': {
        draft.selectLocals.map(local => {
          if (
            local.index === action.payload.index &&
            local.groupKey === action.payload.groupKey
          ) {
            const sections = local.locals.filter((dataLocal: any) => {
              if (dataLocal.id === action.payload.selected) {
                return dataLocal.sections;
              }
            });
            const selecteds = draft.selectSections.filter(
              section =>
                section.index !== action.payload.index &&
                section.groupKey !== action.payload.groupKey
            );
            selecteds.push({
              index: local.index,
              groupKey: local.groupKey,
              sections
            });
            draft.selectSections = selecteds;
          }
        });
        break;
      }
      case '@productionPlan/SET_PRODUCT_TO_CREATE': {
        const { groupKey, indexProduct, selected } = action.payload;
        draft.productionPlan.productionPlanningGroups[
          groupKey
        ].productionPlanningGroupProducts[indexProduct].productionSectionId =
          selected;
        draft.productionPlan.productionPlanningGroups[
          groupKey
        ].productionPlanningGroupProducts[indexProduct].toSend = true;
        draft.productionPlan.productionPlanningGroups[groupKey].toSend = true;
        draft.validSave = true;
        break;
      }
      case '@productionPlan/CHANGE_LOADING': {
        draft.loading = action.payload.loading;
        break;
      }
      case '@productionPlan/CHANGE_SEARCH': {
        draft.searchEnabled = action.payload.searchEnabled;
        break;
      }
      case '@productionPlan/SET_HISTORY': {
        draft.loadingHistory = true;
        break;
      }
      case '@productionPlan/SET_HISTORY_SUCCESS': {
        draft.history = action.payload.history;
        draft.loadingHistory = false;
        break;
      }
      case '@productionPlan/SET_HISTORY_FAILURE': {
        draft.loadingHistory = false;
        break;
      }
      case '@productionPlan/REPLICATE_LAST_YEAR': {
        draft.replicatingLastYear = true;

        break;
      }
      case '@productionPlan/REPLICATE_LAST_YEAR_SUCCESS': {
        draft.replicatingLastYear = false;
        draft.successReplicate = true;
        draft.failureReplicate = false;
        draft.loading = false;

        break;
      }
      case '@productionPlan/REPLICATE_LAST_YEAR_FAILURE': {
        draft.replicatingLastYear = false;
        draft.successReplicate = false;
        draft.failureReplicate = true;
        draft.failureReplicateMessage = action.payload.failureReplicateMessage;
        draft.loading = false;

        break;
      }
      case '@productionPlan/REPLICATE_WEEK': {
        draft.replicatingWeek = true;
        draft.loading = true;

        break;
      }
      case '@productionPlan/REPLICATE_WEEK_SUCCESS': {
        draft.replicatingWeek = false;
        draft.successReplicateWeek = true;
        draft.failureReplicateWeek = false;
        draft.loading = false;
        break;
      }
      case '@productionPlan/REPLICATE_WEEK_FAILURE': {
        draft.replicatingWeek = false;
        draft.successReplicateWeek = false;
        draft.failureReplicateWeek = true;
        draft.loading = false;
        draft.alertMessage = action.payload.alertMessage;
        break;
      }
      case '@productionPlan/SET_FAILURE_REPLICATE_WEEK': {
        draft.failureReplicateWeek = action.payload.failureReplicateWeek;
        draft.alertMessage = '';
        break;
      }
      case '@productionPlan/SET_PROPERTY': {
        const { propertyId, groupKey, productIndex } = action.payload;
        draft.productionPlan.productionPlanningGroups[
          groupKey
        ].productionPlanningGroupProducts[productIndex].propertyId = propertyId;

        break;
      }
      case '@productionPlan/SET_LOCAL': {
        const { localId, groupKey, productIndex } = action.payload;
        draft.productionPlan.productionPlanningGroups[
          groupKey
        ].productionPlanningGroupProducts[productIndex].localId = localId;
        break;
      }
      case '@productionPlan/SET_SECTION': {
        const { productionSectionId, groupKey, productIndex } = action.payload;
        draft.productionPlan.productionPlanningGroups[
          groupKey
        ].productionPlanningGroupProducts[productIndex].productionSectionId =
          productionSectionId;
        draft.productionPlan.productionPlanningGroups[
          groupKey
        ].productionPlanningGroupProducts[productIndex].toSend = false;
        break;
      }
      case '@productionPlan/DUPLICATE_PRODUCT': {
        const { groupKey, productIndex } = action.payload;
        const newProduct = {
          ...draft.productionPlan.productionPlanningGroups[groupKey]
            .productionPlanningGroupProducts[productIndex]
        };
        newProduct.productionPlanningGroupProductId = 0;
        newProduct.isDuplicate = true;
        newProduct.localId = null;
        newProduct.productionSectionId = null;
        newProduct.propertyId = null;
        draft.productionPlan.productionPlanningGroups[
          groupKey
        ].productionPlanningGroupProducts.splice(
          productIndex + 1,
          0,
          newProduct
        );
        draft.productionPlan.productionPlanningGroups[
          groupKey
        ].productionPlanningGroupProducts.forEach(
          (product: any, index: number) => {
            product.key = index;
          }
        );
        break;
      }
      case '@productionPlan/REMOVE_DUPLICATED_PRODUCT': {
        const { groupKey, productIndex } = action.payload;
        draft.productionPlan.productionPlanningGroups[
          groupKey
        ].productionPlanningGroupProducts.splice(productIndex, 1);
        break;
      }
      case '@productionPlan/REMOVE_PLAN': {
        draft.productionPlan = {};
        break;
      }
      case '@productionPlan/SET_HISTORY_DATES': {
        draft.historyDates = action.payload.historyDates;
        break;
      }
      case '@productionPlan/DELETE_PRODUCTS_SUCCESS': {
        draft.successDelete = true;
        draft.failure = false;
        draft.productionPlan = {};
        break;
      }
      case '@productionPlan/DELETE_PRODUCTS_FAILURE': {
        draft.success = false;
        draft.failure = true;
        break;
      }
      case '@productionPlan/SET_SUCCESS': {
        draft.success = false;
        break;
      }
      case '@productionPlan/SET_SUCCESS_DELETE': {
        draft.successDelete = false;
        break;
      }
      case '@productionPlan/SET_SAVING': {
        draft.saving = action.payload.saving;
        break;
      }
      case '@productionPlan/SET_SELECTD_ROWS_PRODUCT': {
        draft.selectedRowsProduct = action.payload.selectedProducts;
        break;
      }
      case '@productionPlan/SET_PAGE_NUMBER': {
        draft.pageNumber = action.payload.pageNumber;
        break;
      }
      case '@productionPlan/SET_SUCCESS_REPLICATE': {
        draft.successReplicate = action.payload.successReplicate;
        break;
      }
      case '@productionPlan/SET_SUCCESS_REPLICATE_WEEK': {
        draft.successReplicateWeek = action.payload.successReplicateWeek;
        break;
      }
      case '@productionPlan/SET_FAILURE': {
        draft.failure = action.payload.failure;
        break;
      }
      case '@productionPlan/SET_FAILURE_REPLICATE': {
        draft.failureReplicate = action.payload.failureReplicate;
        break;
      }
      case '@productionPlan/SET_PAGE_SIZE': {
        draft.pageSize = action.payload.pageSize;
        break;
      }
      case '@productionPlan/SET_VALID_WEEK_SAVE': {
        draft.validWeekSave = action.payload.validWeekSave;
        break;
      }
      case '@productionPlan/SET_EXPAND_ROWS_KEYS': {
        draft.expandRowsKeys = action.payload.expandRowsKeys;
        break;
      }
      case '@productionPlan/SET_INVALID_PRODUCTION_PLAN': {
        draft.invalid = action.payload.invalid;
        draft.saving = false;
        draft.loading = false;
        break;
      }
      case '@productionPlan/RESET_PAGINATION': {
        draft.pageSize = 250;
        draft.pageNumber = 1;
        break;
      }
      case '@productionPlan/SET_DISABLE_CALENDAR': {
        draft.disableCalendar = action.payload.disableCalendar;
        break;
      }
      case '@productionPlan/SET_CALENDAR_MESSAGE': {
        draft.calendarMessage = action.payload.calendarMessage;
        break;
      }
      case '@productionPlan/SEARCH_PLAN_WEEKS_SUCCESS': {
        draft.calendarMessage = 'Escolha uma data';
        draft.disableCalendar = false;
        draft.planWeeks = action.payload.planWeeks;
        break;
      }

      default:
    }
  });
}

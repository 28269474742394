import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Types, VolOffersListAction } from './duck';
import * as VolOfferService from '../../_core/volOffers/service';
import * as DeliveryPatternService from '../../_core/deliveryPattern/service';
import * as VoucherService from '../../_core/vouchers/service';
import { getPagination } from './selectors';
import * as ProductService from '~/store/modules/_core/product/service';
import * as ProductCategoriesService from '~/store/modules/_core/productCategories/service';
import * as ProductGroupService from '~/store/modules/_core/productionProductGroups/service';
import { alertRequest } from '../../alert/actions';
import { actions } from '.';

export function* fetchFilterData(
  action: PayloadAction<VolOffersListAction>
): any {
  try {
    yield put(actions.setLoading(true));
    if (action.payload.isVol) {
      yield call(DeliveryPatternService.findManyBySiteId, {
        siteId: action.payload.SiteId,
        enabled: true
      });
    }
    yield call(ProductCategoriesService.findManyById, action.payload.SiteId);
    yield call(ProductService.findQualities);
    yield call(ProductService.findMany, action.payload.SiteId);
    if (action.payload.isVol) {
      yield call(VolOfferService.findVolOfferTypes);
    }
    yield call(ProductGroupService.findMany, { siteId: action.payload.SiteId });
    yield call(ProductService.findGroups, action.payload.SiteId);
    yield call(ProductService.findDetailedRecipients, action.payload.SiteId);
    yield put(actions.setLoading(false));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    yield put(alertRequest(error.message, 'error'));
  }
}

export function* fetch(action: PayloadAction<VolOffersListAction>): any {
  try {
    if (action.payload.isVol) {
      yield call(DeliveryPatternService.findManyBySiteId, {
        siteId: action.payload.SiteId,
        enabled: true
      });
    }
    yield put(actions.setLoading(true));
    const pagination = yield select(getPagination);
    yield call(
      VoucherService.findVouchersByFilters,
      undefined,
      undefined,
      'true',
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      action.payload.SiteId
    );
    yield call(VolOfferService.findMany, { ...action.payload, pagination });
    yield put(actions.setLoading(false));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    yield put(alertRequest(error.message, 'error'));
  }
}
export function* fetchHistory(action: PayloadAction<number>) {
  try {
    yield put(actions.setLoading(true));
    yield call(VolOfferService.findHistory, action.payload);
    yield put(actions.setLoading(false));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    yield put(alertRequest(error.message, 'error'));
  }
}

export default [
  takeLatest(Types.fetch, fetch),
  takeLatest(Types.fetchHistory, fetchHistory),
  takeLatest(Types.fetchFilterData, fetchFilterData)
];

import { call, put, takeLatest } from 'redux-saga/effects';
import { FetchPreferencesAction, Types, actions } from './duck';
import { Action } from '~/helpers/util/reducers';
import * as ProductCategoriesService from '~/store/modules/_core/productCategories/service';
import * as ProductPreferencesService from '~/store/modules/_core/productPreferences/service';
import * as ProductService from '~/store/modules/_core/product/service';
import { alertRequest } from '../../alert/actions';

export function* fetchFormData(action: Action<number>): any {
  try {
    yield call(ProductCategoriesService.findManyById, action.payload);
    yield call(ProductService.findGroups, action.payload);
    yield call(ProductService.findMany, action.payload);
    yield call(ProductService.findRecipients, action.payload);
    yield call(ProductPreferencesService.findCompositions, action.payload);
    yield put(actions.fetchSuccess());
  } catch (err: any) {
    yield put(alertRequest(err.message, 'error'));
    yield put(actions.fetchFailure());
  }
}

export function* fetchProductPreferences(
  action: Action<FetchPreferencesAction>
): any {
  try {
    yield call(ProductPreferencesService.findMany, action.payload);
    yield call(
      ProductPreferencesService.findCompositions,
      action.payload.SiteId
    );

    yield put(actions.fetchSuccess());
  } catch (err) {
    yield put(actions.fetchFailure());
  }
}

export function* fetchProductPreferencesById(action: Action<number>): any {
  try {
    yield call(ProductPreferencesService.findById, action.payload);
    yield put(actions.fetchSuccess());
  } catch (err) {
    yield put(actions.fetchFailure());
  }
}

export default [
  takeLatest(Types.fetchFormData, fetchFormData),
  takeLatest(Types.fetchProductPreferences, fetchProductPreferences),
  takeLatest(Types.fetchById, fetchProductPreferencesById)
];

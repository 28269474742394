import { createSelector } from 'reselect';

import { ProductPreferencesState } from '.';

export const getRoot = ({
  productPreferences
}: {
  productPreferences: ProductPreferencesState;
}) => {
  return productPreferences;
};

export const getProductPreferencesState = createSelector(
  [getRoot],
  state => state
);

export const getPreferences = createSelector(
  [getRoot],
  state => state.productPreferences
);

export const getTotalPreferences = createSelector(
  [getRoot],
  state => state.totalCount
);

export const getPreferencesPagination = createSelector(
  [getRoot],
  state => state.preferencesPagination
);

export const getPreferenceHistorics = createSelector(
  [getRoot],
  state => state.preferenceHistoric
);

export const getPreferenceColors = createSelector(
  [getRoot],
  state => state.colors
);

export const getPreferenceRefetchOffersList = createSelector(
  [getRoot],
  state => state.refetchOffersList
);

export const getSiteIdAux = createSelector([getRoot], state => state.siteIdAux);

export const getCleanCheckbox = createSelector(
  [getRoot],
  state => state.cleanCheckbox
);

// eslint-disable-next-line max-classes-per-file
export interface ApplicationError {
  errorKey: string;
}

export class CompetenceAlreadyRegistered implements ApplicationError {
  errorKey = 'competence-already-registered';
}
export class CompetenceRemoveError implements ApplicationError {
  errorKey = 'competence-event-already-registered';
  public readonly invalidIDs = [];
  public readonly iDsWithEvents = [];
  public readonly message = '';

  constructor(invalidIDs: [], iDsWithEvents: [], message: '') {
    this.invalidIDs = invalidIDs;
    this.iDsWithEvents = iDsWithEvents;
    this.message = message;
  }
}

export class CompetenceEventAlreadyRegistered implements ApplicationError {
  errorKey = 'competence-event-already-registered';
}

export class UnknownError implements ApplicationError {
  errorKey = 'unknown-error';
}

export class APIError implements ApplicationError {
  errorKey = 'api-error';
  public readonly message: string;

  constructor(message: string) {
    this.message = message;
  }
}

import * as API from '../api';

export default class DirectedOfferCustomer {
  public json: API.DirectedOfferCustomer;
  public key: number;
  accountCode: string;
  customerGroupId: number;
  customerId: number;
  description: string;
  name: string;

  public getAccountCode(): string {
    return this.json.accountCode ?? '';
  }

  public getCustomerGroupId(): number {
    return this.json.customerGroupId ?? Number.NaN;
  }

  public getCustomerId(): number {
    return this.json.customerId ?? Number.NaN;
  }

  public getDescription(): string {
    return this.json.description ?? '';
  }

  public getName(): string {
    return this.json.name ?? '';
  }

  constructor(json: API.DirectedOfferCustomer) {
    this.json = json;
    this.key = Number.NaN;
    this.accountCode = this.getAccountCode();
    this.customerGroupId = this.getCustomerGroupId();
    this.customerId = this.getCustomerId();
    this.description = this.getDescription();
    this.name = this.getName();
  }
}

import { createSelector } from '~/helpers/util/selectors';
import { VoucherCreateState } from './duck';

export const getRoot = (state: any) => {
  return state.ui.voucherAdd as VoucherCreateState;
};

export const getFetchStatus = createSelector([getRoot], state => {
  return state.fetchStatus;
});

export const getCreateStatus = createSelector([getRoot], state => {
  return state.createStatus;
});

export const getErrorMessage = createSelector([getRoot], state => {
  return state.errorMessage;
});

export const getIsLoading = createSelector(
  [getFetchStatus, getCreateStatus],
  (fetchStatus, createStatus) => {
    return fetchStatus === 'fetching' || createStatus === 'creating';
  }
);

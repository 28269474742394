import { AxiosError, AxiosResponse } from 'axios';
import api from '../config/index';
import SiteCustomNameUpdate from '~/@core/domain/model/site/SiteCustomNameUpdate';
import Site from '~/@core/domain/model/site/Site';

export const fetchSites = (permissionName: string) => {
  return new Promise<Site[]>((resolve, reject) => {
    api
      .get<void, AxiosResponse<Site[]>>(`/site/${permissionName}`)
      .then((response: AxiosResponse<Site[]>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const updateCustomName = (params: SiteCustomNameUpdate) => {
  return new Promise<AxiosResponse<void>>((resolve, reject) => {
    api
      .put<void, AxiosResponse<AxiosResponse<void>>>(
        '/site/custom_name',
        params
      )
      .then((response: AxiosResponse<AxiosResponse<void>>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

export const getCustomNameBySiteId = (siteId: number) => {
  return new Promise<AxiosResponse<void>>((resolve, reject) => {
    api
      .get<void, AxiosResponse<AxiosResponse<void>>>(
        `/site/custom_name/${siteId}`
      )
      .then((response: AxiosResponse<AxiosResponse<void>>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

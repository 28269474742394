import { AxiosResponse } from 'axios';
import moment from 'moment';
import { endpoints } from '~/constants/endpoints';
import * as M from '~/domain/model';
import api from '../api';

// const mockedApi: AxiosInstance = axios.create(api.defaults);

// const mock = new MockAdapter(mockedApi, {
//   delayResponse: 500,
//   onNoMatch: 'passthrough'
// });

// const mockedOrders = [
//   {
//     id: 66,
//     customerId: 17,
//     customerName: 'GARON FLORES LTDA',
//     producerName: 'FAZENDA LUPA',
//     producerCode: '245111',
//     packingCode: '070',
//     packingDescription: 'PORTA VASO P11',
//     productQuality: 'A1',
//     customerCode: '999',
//     quantityAttended: 10,
//     description: 'Teste Produto',
//     longProductDescription: 'PIMENTA P11',
//     productCode: '03274.011.000.00.00',
//     qeXQpe: '20 x 4',
//     requestDate: '2023-03-29T09:11:26.691',
//     deliveryDate: '2023-03-29T00:00:00',
//     statusId: 2,
//     status: OrderStatusEnum.PENDENT,
//     billingDate: '2023-03-29T00:00:00',
//     unitPrice: 4,
//     totalPrice: 320,
//     order: 6,
//     observation: ''
//   },
//   {
//     id: 65,
//     customerId: 17,
//     customerName: 'GARON FLORES LTDA',
//     producerName: 'FAZENDA LUPA',
//     producerCode: '245111',
//     packingCode: '070',
//     packingDescription: 'PORTA VASO P11',
//     productQuality: 'A1',
//     customerCode: '999',
//     quantityAttended: 10,
//     description: 'Teste Qualidade',
//     longProductDescription: 'PIMENTA P11',
//     productCode: '03274.011.000.00.00',
//     qeXQpe: '20 x 4',
//     requestDate: '2023-03-29T09:07:14.4654',
//     deliveryDate: '2023-03-29T00:00:00',
//     statusId: 2,
//     status: OrderStatusEnum.PENDENT,
//     billingDate: '2023-03-29T00:00:00',
//     unitPrice: 4,
//     totalPrice: 320,
//     order: 5,
//     observation: ''
//   },
//   {
//     id: 64,
//     customerId: 17,
//     customerName: 'GARON FLORES LTDA',
//     producerName: 'FAZENDA LUPA',
//     producerCode: '245111',
//     packingCode: '070',
//     packingDescription: 'PORTA VASO P11',
//     productQuality: 'A1',
//     customerCode: '999',
//     description: 'Teste Embalagem',
//     longProductDescription: 'PIMENTA P11',
//     productCode: '03274.011.000.00.00',
//     qeXQpe: '20 x 4',
//     requestDate: '2023-03-29T08:54:35.8742',
//     deliveryDate: '2023-03-29T00:00:00',
//     statusId: 3,
//     status: OrderStatusEnum.PENDENT,
//     billingDate: '2023-03-29T00:00:00',
//     unitPrice: 4,
//     totalPrice: 320,
//     order: 4,
//     observation: ''
//   },
//   {
//     id: 63,
//     customerId: 17,
//     customerName: 'GARON FLORES LTDA',
//     producerName: 'FAZENDA LUPA',
//     producerCode: '245111',
//     packingCode: '070',
//     packingDescription: 'PORTA VASO P11',
//     productQuality: 'A1',
//     customerCode: '999',
//     description: 'Teste 4',
//     longProductDescription: 'PIMENTA P11',
//     productCode: '03274.011.000.00.00',
//     qeXQpe: '20 x 4',
//     requestDate: '2023-03-29T08:39:55.8474',
//     deliveryDate: '2023-03-29T00:00:00',
//     statusId: 3,
//     status: OrderStatusEnum.PENDENT,
//     billingDate: '2023-03-29T00:00:00',
//     unitPrice: 4,
//     totalPrice: 320,
//     order: 3,
//     observation: ''
//   },
//   {
//     id: 62,
//     customerId: 17,
//     customerName: 'GARON FLORES LTDA',
//     producerName: 'FAZENDA LUPA',
//     producerCode: '245111',
//     packingCode: '070',
//     packingDescription: 'PORTA VASO P11',
//     productQuality: 'A1',
//     customerCode: '999',
//     description: 'Teste 3',
//     longProductDescription: 'PIMENTA P11',
//     productCode: '03274.011.000.00.00',
//     qeXQpe: '20 x 4',
//     requestDate: '2023-03-29T08:36:31.2441',
//     deliveryDate: '2023-03-29T00:00:00',
//     statusId: 3,
//     status: OrderStatusEnum.PENDENT,
//     billingDate: '2023-03-29T00:00:00',
//     unitPrice: 4,
//     totalPrice: 320,
//     order: 2,
//     observation: ''
//   },
//   {
//     id: 61,
//     customerId: 17,
//     customerName: 'GARON FLORES LTDA',
//     producerName: 'FAZENDA LUPA',
//     producerCode: '245111',
//     packingCode: '070',
//     packingDescription: 'PORTA VASO P11',
//     productQuality: 'A1',
//     customerCode: '999',
//     description: 'Teste 2',
//     longProductDescription: 'PIMENTA P11',
//     productCode: '03274.011.000.00.00',
//     qeXQpe: '20 x 4',
//     requestDate: '2023-03-29T08:32:06.5799',
//     deliveryDate: '2023-03-29T00:00:00',
//     statusId: 3,
//     status: OrderStatusEnum.PENDENT,
//     billingDate: '2023-03-29T00:00:00',
//     unitPrice: 4,
//     totalPrice: 320,
//     order: 1,
//     observation: ''
//   },
//   {
//     id: 60,
//     customerId: 17,
//     customerName: 'GARON FLORES LTDA',
//     producerName: 'FAZENDA LUPA',
//     producerCode: '245111',
//     packingCode: '070',
//     packingDescription: 'PORTA VASO P11',
//     productQuality: 'A1',
//     customerCode: '999',
//     description: 'Teste 1',
//     longProductDescription: 'PIMENTA P11',
//     productCode: '03274.011.000.00.00',
//     qeXQpe: '20 x 4',
//     requestDate: '2023-03-29T08:15:32.8718',
//     deliveryDate: '2023-03-29T00:00:00',
//     statusId: 3,
//     status: OrderStatusEnum.PENDENT,
//     billingDate: '2023-03-29T00:00:00',
//     unitPrice: 4,
//     totalPrice: 320,
//     order: 90,
//     observation: ''
//   },
//   {
//     id: 59,
//     customerId: 17,
//     customerName: 'GARON FLORES LTDA',
//     producerName: 'FAZENDA LUPA',
//     producerCode: '245111',
//     packingCode: '070',
//     packingDescription: 'PORTA VASO P11',
//     productQuality: 'A1',
//     customerCode: '999',
//     description: 'Teste Consumo Embalagens',
//     longProductDescription: 'PIMENTA P11',
//     productCode: '03274.011.000.00.00',
//     qeXQpe: '20 x 4',
//     requestDate: '2023-03-29T07:45:15.5573',
//     deliveryDate: '2023-03-29T00:00:00',
//     statusId: 3,
//     status: OrderStatusEnum.PENDENT,
//     billingDate: '2023-03-29T00:00:00',
//     unitPrice: 4,
//     totalPrice: 320,
//     order: 9,
//     observation: ''
//   },
//   {
//     id: 58,
//     customerId: 17,
//     customerName: 'GARON FLORES LTDA',
//     producerName: 'FAZENDA LUPA',
//     producerCode: '245111',
//     packingCode: '070',
//     packingDescription: 'PORTA VASO P11',
//     productQuality: 'A1',
//     customerCode: '999',
//     description: 'teste1111',
//     longProductDescription: 'PIMENTA P11',
//     productCode: '03274.011.000.00.00',
//     qeXQpe: '20 x 4',
//     requestDate: '2023-03-28T15:18:13.6881',
//     deliveryDate: '2023-03-29T00:00:00',
//     statusId: 3,
//     status: OrderStatusEnum.PENDENT,
//     billingDate: '2023-03-29T00:00:00',
//     unitPrice: 4,
//     totalPrice: 320,
//     order: 8,
//     observation: ''
//   },
//   {
//     id: 56,
//     customerId: 17,
//     customerName: 'GARON FLORES LTDA',
//     producerName: 'FAZENDA LUPA',
//     producerCode: '245111',
//     packingCode: '070',
//     packingDescription: 'PORTA VASO P11',
//     productQuality: 'A1',
//     customerCode: '999',
//     description: 'AAA SUCULENTAS',
//     longProductDescription: 'PIMENTA P11',
//     productCode: '03274.011.000.00.00',
//     qeXQpe: '20 x 4',
//     requestDate: '2023-03-28T15:06:42.3205',
//     deliveryDate: '2023-03-29T00:00:00',
//     statusId: 3,
//     status: OrderStatusEnum.PENDENT,
//     billingDate: '2023-03-30T00:00:00',
//     unitPrice: 4,
//     totalPrice: 320,
//     order: 6,
//     observation: ''
//   },
//   {
//     id: 55,
//     customerId: 17,
//     customerName: 'GARON FLORES LTDA',
//     producerName: 'FAZENDA LUPA',
//     producerCode: '245111',
//     packingCode: '070',
//     packingDescription: 'PORTA VASO P11',
//     productQuality: 'A1',
//     customerCode: '999',
//     description: 'TESTE SUCULENTAS',
//     longProductDescription: 'PIMENTA P11',
//     productCode: '03274.011.000.00.00',
//     qeXQpe: '20 x 4',
//     requestDate: '2023-03-28T15:05:26.6695',
//     deliveryDate: '2023-03-29T00:00:00',
//     statusId: 3,
//     status: OrderStatusEnum.PENDENT,
//     billingDate: '2023-03-30T00:00:00',
//     unitPrice: 4,
//     totalPrice: 320,
//     order: 5,
//     observation: ''
//   },
//   {
//     id: 54,
//     customerId: 17,
//     customerName: 'GARON FLORES LTDA',
//     producerName: 'FAZENDA LUPA',
//     producerCode: '245111',
//     packingCode: '070',
//     packingDescription: 'PORTA VASO P11',
//     productQuality: 'A1',
//     customerCode: '999',
//     description: 'fffff',
//     longProductDescription: 'PIMENTA P11',
//     productCode: '03274.011.000.00.00',
//     qeXQpe: '20 x 4',
//     requestDate: '2023-03-28T14:59:46.0429',
//     deliveryDate: '2023-03-29T00:00:00',
//     statusId: 3,
//     status: OrderStatusEnum.PENDENT,
//     billingDate: '2023-03-30T00:00:00',
//     unitPrice: 4,
//     totalPrice: 320,
//     order: 4,
//     observation: ''
//   },
//   {
//     id: 53,
//     customerId: 17,
//     customerName: 'GARON FLORES LTDA',
//     producerName: 'FAZENDA LUPA',
//     producerCode: '245111',
//     packingCode: '070',
//     packingDescription: 'PORTA VASO P11',
//     productQuality: 'A1',
//     customerCode: '999',
//     description: 'aaaa',
//     longProductDescription: 'PIMENTA P11',
//     productCode: '03274.011.000.00.00',
//     qeXQpe: '20 x 4',
//     requestDate: '2023-03-28T14:58:00.488',
//     deliveryDate: '2023-03-29T00:00:00',
//     statusId: 3,
//     status: OrderStatusEnum.PENDENT,
//     billingDate: '2023-03-29T00:00:00',
//     unitPrice: 4,
//     totalPrice: 320,
//     order: 3,
//     observation: ''
//   }
// ];

// mock.onGet(endpoints.orders.base).reply(config => {
//   const params = config.params as M.AutoServiceFetchParams;
//   console.log('params:: ', params);
//   return [200, { results: mockedOrders, totalCount: mockedOrders.length }];
// });

export const fetchOrdersRequest = async (params: M.AutoServiceFetchParams) => {
  const entries = Object.entries(params);
  let url = endpoints.orderManagement.base;
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of entries) {
    if (key !== 'Data.Status' && value !== undefined) {
      url += `${key}=${value}&`;
    }
  }
  const statusEnum = params['Data.Status'] ?? [];
  const statusArray = Object.values(statusEnum);
  statusArray.forEach((status: any) => {
    url += `Data.Status=${status}&`;
  });
  const response = await api.get<void, AxiosResponse<M.ResponseApi<any[]>>>(
    `${url}`
  );

  return {
    totalCount: response.data?.totalCount || 0,
    results: response.data.results.map(order => {
      return {
        ...order,
        isExpired: Boolean(
          order.expirationTime &&
            moment(order.expirationTime).isBefore(moment())
        ),
        isFinished: Boolean(params['Data.FinishedOrders'] || order.isFinished)
      };
    })
  };
};

export const fetchPendingOrdersReviewRequest = async (
  params: M.AutoServiceFetchPendingOrdersReviewParams
) => {
  const response = await api.get<void, AxiosResponse<M.ResponseApi<any[]>>>(
    endpoints.orderManagement.pending,
    { params }
  );

  return {
    totalCount: response.data?.totalCount || 0,
    results: response.data.results.map(order => {
      return {
        ...order
      };
    })
  };
};

export const reproccessPendingOrdersReviewRequest = async (params: any) => {
  const response = await api.put<void, AxiosResponse<any>>(
    endpoints.orderManagement.republish,
    params
  );
  return response.data;
};

export const republishOrderRequest = async (
  params: M.AutoServiceGenerateRepublishOrderParams
) => {

  const response = await api.put<void, AxiosResponse<any>>(
    endpoints.orderManagement.republish,
    params
  );
  return response.data;
};

export const republishCancelledRequest = async (
  params: M.AutoServiceGenerateRepublishCancelledParams
) => {
  const response = await api.put<void, AxiosResponse<any>>(
    endpoints.orderManagement.republishCancelled,
    params
  );
  return response.data;
};

export const massRepublishOrders = async (params: number[]) => {
  const response = await api.put<number[], AxiosResponse<string[]>>(
    endpoints.orderManagement.massRepublish,
    params
  );
  return response.data;
};

export const importOrdersRequest = async (
  payload: M.AutoServiceImportOrdersParams
) => {
  const response = await api.post<void, AxiosResponse<Array<any>>>(
    endpoints.selfService.import,
    payload
  );
  return response.data;
};

export const revalidateOrdersRequest = async (
  payload: M.AutoServiceGenerateRevalidateOrdersParams
) => {
  const response = await api.put<void, AxiosResponse<Array<any>>>(
    endpoints.selfService.importRevalidate,
    payload
  );
  return response.data;
};

export const processOrdersRequest = async (
  payload: M.AutoServiceProcessOrdersParams
) => {
  const response = await api.put<void, AxiosResponse<Array<any>>>(
    endpoints.selfService.process,
    payload
  );
  return response.data;
};

export const exportExcelRequest = async (
  filters: M.AutoServiceFetchParams,
  data: M.AutoServiceExportBody
) => {
  const entries = Object.entries(filters);
  let url = endpoints.orderManagement.exportExcel;
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of entries) {
    if (key !== 'Data.Status' && value !== undefined) {
      url += `${key}=${value}&`;
    }
  }
  const statusEnum = filters['Data.Status'] ?? [];
  const statusArray = Object.values(statusEnum);
  statusArray.forEach((status: any) => {
    url += `Data.Status=${status}&`;
  });
  const response = await api.post<void, AxiosResponse<{ data: string }>>(
    `${url}`,
    data
  );
  return response.data.data;
};

export const deleteColumnArchive = async (params: number) => {
  const response = await api.delete<void, AxiosResponse<boolean>>(
    `${endpoints.selfService.delete}${params}`
  );
  return !!response.data;
};

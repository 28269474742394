import { InputProps } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';
import * as S from './style';

interface Props extends InputProps {
  onChange: (value: any) => void;
  timeout?: number;
  value: string | undefined;
}

export const DebouncedInput: FC<Props> = ({
  onChange,
  timeout = 50,
  value,
  ...props
}) => {
  const [displayValue, setDisplayValue] = useState<string | undefined>('');

  const changeValue = useCallback(
    newValue => {
      onChange(newValue);
      setDisplayValue(newValue);
    },
    [onChange]
  );

  useEffect(() => {
    if (value !== displayValue) {
      setDisplayValue(value);
    }
  }, [value]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (displayValue !== value) {
        if (displayValue) {
          changeValue(displayValue);
          return;
        }
        onChange('');
        setDisplayValue('');
      }
    }, timeout);
    return () => clearTimeout(handler);
  }, [displayValue]);

  return (
    <S.InputTable
      {...props}
      autoComplete="off"
      onChange={e => setDisplayValue(e.target?.value)}
      type="text"
      value={displayValue}
    />
  );
};

import { AxiosResponse } from 'axios';
import { endpoints } from '~/constants/endpoints';
import { FindProductionPlanningReplicate } from '~/domain/model/ProductionPlanning/FindProductionPlanningReplicate';
import { ProductionPlanningExportParams } from '~/domain/model/ProductionPlanning/ProductionPlanningExportParams';
import { ProductionPlanningFetchParams } from '~/domain/model/ProductionPlanning/ProductionPlanningFetchParams';
import { ProductionPlanningFetchResponse } from '~/domain/model/ProductionPlanning/ProductionPlanningFetchResponse';
import { ProductionPlanningHistory } from '~/domain/model/ProductionPlanning/ProductionPlanningHistory';
import { ProductionPlanningHistoryFetchParams } from '~/domain/model/ProductionPlanning/ProductionPlanningHistoryFetchParams';
import { ProductionPlanningImportParams } from '~/domain/model/ProductionPlanning/ProductionPlanningImportParams';
import { ProductionPlanningImportingHistory } from '~/domain/model/ProductionPlanning/ProductionPlanningImportingHistory';
import { ProductionPlanningImportingHistoryFetchParams } from '~/domain/model/ProductionPlanning/ProductionPlanningImportingHistoryFetchParams';
import { ProductionPlanningImportingLog } from '~/domain/model/ProductionPlanning/ProductionPlanningImportingLog';
import { ProductionPlanningImportingLogDetails } from '~/domain/model/ProductionPlanning/ProductionPlanningImportingLogDetails';
import { ProductionPlanningImportingLogFetchParams } from '~/domain/model/ProductionPlanning/ProductionPlanningImportingLogFetchParams';
import { ProductionPlanningReplicateBudgetParams } from '~/domain/model/ProductionPlanning/ProductionPlanningReplicateBudgetParams';
import { ProductionPlanningReplicateResponse } from '~/domain/model/ProductionPlanning/ProductionPlanningReplicateResponse';
import { ProductionPlanningReplicateWeekParams } from '~/domain/model/ProductionPlanning/ProductionPlanningReplicateWeekParams';
import { ProductionPlanningReplicateWeekResponse } from '~/domain/model/ProductionPlanning/ProductionPlanningReplicateWeekResponse';
import { ProductionPlanningReplicateYearParams } from '~/domain/model/ProductionPlanning/ProductionPlanningReplicateYearParams';
import { ProductionPlanningReplicateYearResponse } from '~/domain/model/ProductionPlanning/ProductionPlanningReplicateYearResponse';
import { ProductionPlanningUpdatedWeek } from '~/domain/model/ProductionPlanning/ProductionPlanningUpdatedWeek';
import {
  formatProductionPlanningColumnWeeks,
  formatProductionPlanningVirtualTableDataSource
} from '~/helpers/productionPlanning';
import api from '../api';

import * as M from '~/domain/model';
import { FindProductionPlanningReplicateHistory } from '~/domain/model/ProductionPlanning/FindProductionPlanningReplicateHistory';

export const fetchRequest = async ({
  siteId,
  competenceId,
  referenceYear,
  productCategoryId,
  productionProductGroupId,
  letterFilter,
  pageSize,
  pageNumber
}: ProductionPlanningFetchParams) => {
  const params = new URLSearchParams();

  params.append('PageNumber', String(pageNumber));
  params.append('PageSize', String(pageSize));

  if (siteId) {
    params.append('Data.SiteId', String(siteId));
  }
  if (competenceId) {
    params.append('Data.CompetenceId', String(competenceId));
  }
  if (productCategoryId) {
    params.append('Data.ProductCategoryId', String(productCategoryId));
  }
  if (productionProductGroupId) {
    params.append(
      'Data.ProductionProductGroupId',
      String(productionProductGroupId)
    );
  }
  if (letterFilter && letterFilter !== 'all') {
    params.append('Data.Initial', String(letterFilter));
  }

  const response = await api.get<
    ProductionPlanningFetchParams,
    AxiosResponse<ProductionPlanningFetchResponse>
  >(endpoints.productionPlanning.base, {
    params
  });
  if (
    Array.isArray(response?.data?.groupProducts) &&
    !response.data.groupProducts.length
  ) {
    throw new Error(
      'Nenhum grupo de produtos foi encontrado para o sítio informado.'
    );
  }
  const columnWeeks = formatProductionPlanningColumnWeeks(
    response.data.groupProducts[0].weeks.length,
    referenceYear
  );
  const { tableData, totalColumn, totalRow } =
    formatProductionPlanningVirtualTableDataSource(response.data.groupProducts);
  return {
    ...response.data,
    columnWeeks,
    virtualTableDataSource: tableData,
    totalColumn,
    totalRow
  };
};

export const saveRequest = async (
  formData: ProductionPlanningUpdatedWeek[]
) => {
  console.log('formData:: ', formData);
  const response = await api.put(endpoints.productionPlanning.base, formData);
  return response.data;
};

export const exportRequest = async ({
  competenceId,
  siteId,
  productCategoryId,
  productionProductGroupId,
  competenceEventType
}: ProductionPlanningExportParams) => {
  const params = new URLSearchParams();
  if (siteId) {
    params.append('Data.SiteId', String(siteId));
  }
  if (competenceId) {
    params.append('Data.CompetenceId', String(competenceId));
  }
  if (productCategoryId) {
    params.append('Data.ProductCategoryId', String(productCategoryId));
  }
  if (productionProductGroupId) {
    params.append(
      'Data.ProductionProductGroupId',
      String(productionProductGroupId)
    );
  }
  if (competenceEventType) {
    params.append('Data.CompetenceEventType', String(competenceEventType));
  }
  const response = await api.get<
    ProductionPlanningExportParams,
    AxiosResponse<any>
  >(endpoints.productionPlanning.export, { params });

  const binaryString = window.atob(response.data.data);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  const fileData = bytes;

  const blob = new Blob([fileData], { type: 'application/octet-stream' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.style.display = 'none';
  const fileName = 'planejamento-de-producao-.xlsx';
  link.download = fileName;
  link.click();
};

export const historyFetchRequest = async ({
  competenceId,
  siteId,
  productCategoryId,
  productionProductGroupId
}: ProductionPlanningHistoryFetchParams) => {
  const params = new URLSearchParams();
  params.append(
    'Data.CompetenceId',
    competenceId ? competenceId.toString() : '0'
  );
  params.append('Data.SiteId', siteId ? siteId.toString() : '0');
  params.append(
    'Data.ProductCategoryId',
    productCategoryId ? productCategoryId.toString() : '0'
  );
  params.append(
    'Data.ProductionProductGroupId',
    productionProductGroupId ? productionProductGroupId.toString() : '0'
  );
  const response = await api.get<
    ProductionPlanningHistoryFetchParams,
    AxiosResponse<ProductionPlanningHistory[]>
  >(endpoints.productionPlanning.history, { params });
  return response.data;
};

export const importRequest = async ({
  competenceId,
  siteId,
  file
}: ProductionPlanningImportParams) => {
  const formData = new FormData();
  formData.append('CompetenceId', competenceId.toString());
  formData.append('SiteId', siteId.toString());
  if (file.originFileObj) {
    formData.append('File', file.originFileObj ?? '');
  }
  const response = await api.post('/production-planning/import', formData);
  return response.data;
};

export const importingLogFetchRequest = async (
  params: ProductionPlanningImportingLogFetchParams
) => {
  const response = await api.get<
    ProductionPlanningImportingLogFetchParams,
    AxiosResponse<ProductionPlanningImportingLog>
  >(endpoints.productionPlanning.importingLog, { params });
  return response.data;
};

export const importingLogDetailsFetchRequest = async (worksheetId: number) => {
  const response = await api.get<
    number,
    AxiosResponse<ProductionPlanningImportingLogDetails[]>
  >(`${endpoints.productionPlanning.importingLogDetails}/${worksheetId}`);
  return response.data;
};

export const importingHistoryFetchRequest = async ({
  competenceId,
  siteId,
  productCategoryId,
  productionProductGroupId
}: ProductionPlanningImportingHistoryFetchParams) => {
  const params = new URLSearchParams();
  params.append(
    'Data.CompetenceId',
    competenceId ? competenceId.toString() : '0'
  );
  params.append('Data.SiteId', siteId ? siteId.toString() : '0');
  if (productCategoryId) {
    params.append('Data.ProductCategoryId', String(productCategoryId));
  }
  if (productionProductGroupId) {
    params.append(
      'Data.ProductionProductGroupId',
      String(productionProductGroupId)
    );
  }
  const response = await api.get<
    ProductionPlanningImportingHistoryFetchParams,
    AxiosResponse<ProductionPlanningImportingHistory[]>
  >(endpoints.productionPlanning.importingHistory, { params });
  return response.data;
};

export const replicateWeekRequest = async (
  formData: ProductionPlanningReplicateWeekParams
) => {
  const response = await api.put<
    ProductionPlanningReplicateWeekParams,
    AxiosResponse<ProductionPlanningReplicateWeekResponse>
  >(endpoints.productionPlanning.replicateWeek, formData);
  return response.data;
};

export const replicateYearRequest = async (
  formData: ProductionPlanningReplicateYearParams
) => {
  const response = await api.put<
    ProductionPlanningReplicateYearParams,
    AxiosResponse<ProductionPlanningReplicateYearResponse>
  >(endpoints.productionPlanning.replicateYear, formData);
  return response.data;
};

export const findProductionPlanningReplicate = async ({
  PageNumber = 1,
  PageSize = 100,
  ...params
}: FindProductionPlanningReplicate) => {
  let url = endpoints.productionPlanning.replicate;
  const entries = Object.entries({ ...params });

  url += `?PageSize=${PageSize}&PageNumber=${PageNumber}`;
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of entries) {
    if (value !== undefined && value !== null) {
      url += `&Data.${key}=${value}`;
    }
  }
  const response = await api.get<
    M.ResponseApi<ProductionPlanningReplicateResponse[]>
  >(url);
  return response.data;
};

export const productionPlanningReplicateHistoryFetchRequest = async ({
  PageNumber = 1,
  PageSize = 100,
  ...params
}: FindProductionPlanningReplicateHistory) => {
  let url = endpoints.productionPlanning.replicateHistory;
  const entries = Object.entries({ ...params });

  url += `?PageSize=${PageSize}&PageNumber=${PageNumber}`;
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of entries) {
    if (value !== undefined && value !== null) {
      url += `&Data.${key}=${value}`;
    }
  }
  const response = await api.get<
    M.ResponseApi<ProductionPlanningReplicateResponse[]>
  >(url);
  return response.data;
};

export const replicateBudgetRequest = async (
  params: ProductionPlanningReplicateBudgetParams
) => {
  const response = await api.put<void, AxiosResponse<any>>(
    endpoints.productionPlanning.replicateBudget,
    params
  );
  return response.data;
};

import * as API from '~/domain/api';

export default class ReportsAnnouncement {
  public readonly json: API.ReportsAnnouncement;
  public readonly id: number;
  public readonly userId: number;
  public readonly description: string;
  public readonly startDate: string;
  public readonly endDate: string;
  public readonly userName: string;
  public readonly visualizationDate: string;

  constructor(json: API.ReportsAnnouncement) {
    this.json = json;
    this.id = this.getId();
    this.userId = this.getUserId();
    this.description = this.getDescription();
    this.startDate = this.getStartDate();
    this.endDate = this.getEndDate();
    this.userName = this.getUserName();
    this.visualizationDate = this.getVisualizationDate();
  }

  private getId(): number {
    return this.json.id ?? Number.NaN;
  }

  private getUserId(): number {
    return this.json.userId ?? Number.NaN;
  }

  private getDescription(): string {
    return this.json.description ?? '';
  }

  private getStartDate(): string {
    return this.json.startDate ?? '';
  }

  private getEndDate(): string {
    return this.json.endDate ?? '';
  }

  private getUserName(): string {
    return this.json.userName ?? '';
  }

  private getVisualizationDate(): string {
    return this.json.visualizationDate ?? '';
  }
}

import * as API from '~/domain/api';

export default class LkpOffer {
  public readonly json: API.LkpOffer;
  public readonly productPreference: API.ProductPreferences;
  public readonly id: number;
  public readonly stockId: number;
  public readonly stockGroupProductId: number;
  public readonly productCode: string;
  public key: number;
  public toSend: boolean;
  public readonly productDescription: string;
  public readonly productQuality: string;
  public readonly productId: number;
  public readonly productQualityId: number;
  public price: number;
  public initialPrice: number;
  public weekId: number;
  public weekStartDate: any;
  public weekEndDate: any;
  public lowestPrice: number | null;
  public highestPrice: number | null;

  public percentage: number;
  public readonly quantity: number;
  public initialDate: string;
  public finalDate: string;
  public remarks: string;
  public readonly plp: number;
  public readonly defaultImagePath: string;
  public readonly gfpSummary: API.GfpSummary[];
  public isActive: boolean;
  public readonly avgPriceSold: number;
  public readonly percentAboveAverage: number;
  public readonly percentBelowAverage: number;
  public lkpOfferId: any;
  public weekNumber: number;
  public weekYear: number;
  public messageLkpPercentageWasAdjusted: string;
  public readonly accountCode: string;
  public priceVariationRuleEnabled: boolean;

  constructor(json: API.LkpOffer) {
    this.json = json;
    this.id = json.lkpOfferId ?? Number.NaN;
    this.key = Number.NaN;
    this.productPreference = json.productPreference;
    this.toSend = false;
    this.stockGroupProductId = json.stockGroupProductId ?? Number.NaN;
    this.productCode = json.productCode ?? '';
    this.productDescription = json.productDescription ?? '';
    this.productQuality = json.productQuality ?? '';
    this.productId = json.productId ?? Number.NaN;
    this.productQualityId = json.productQualityId ?? Number.NaN;
    this.price = json.price ?? Number.NaN;
    this.percentage = json.percentage ?? Number.NaN;
    this.quantity = json.quantity ?? Number.NaN;
    this.initialDate = json.initialDate ?? '';
    this.finalDate = json.finalDate ?? '';
    this.remarks = json.remarks ?? '';
    this.plp = json.plp ?? Number.NaN;
    this.defaultImagePath = json.defaultImagePath ?? '';
    this.gfpSummary = json.gfpSummary ?? [];
    this.isActive = json.isActive;
    this.avgPriceSold = json.avgPriceSold;
    this.percentAboveAverage = json.percentAboveAverage;
    this.percentBelowAverage = json.percentBelowAverage;
    this.lkpOfferId = json.lkpOfferId;
    this.stockId = json.stockId;
    this.weekId = json.weekId;
    this.weekStartDate = json.weekStartDate;
    this.weekEndDate = json.weekEndDate;
    this.weekNumber = json.weekNumber;
    this.weekYear = json.weekYear;
    this.messageLkpPercentageWasAdjusted = json.messageLkpPercentageWasAdjusted;
    this.lowestPrice = json.lowestPrice ?? null;
    this.highestPrice = json.highestPrice ?? null;
    this.accountCode = json.accountCode ?? '';
    this.initialPrice = json.initialPrice ?? 0;
    this.priceVariationRuleEnabled = json.priceVariationRuleEnabled ?? false;
  }
}

import { AxiosResponse } from 'axios';
import IUseCase from '~/@core/application/IUseCase';
import Cart from '~/@core/domain/model/rfidReports/Cart';
import { ReceiptService } from '~/@core/infrastructure/api/services/rfidReports';

export default class UpdateNonConformity
  implements IUseCase<Cart, Promise<AxiosResponse<void>>>
{
  execute(params: Cart): Promise<AxiosResponse<void>> {
    return ReceiptService.UpdateNonConformity(params);
  }
}

/* eslint-disable react-hooks/exhaustive-deps */
import { InputProps } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';
import * as S from './style';

interface Props extends InputProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (value: any) => void;
  timeout?: number;
  value: string | undefined;
  max?: number;
}

export const DebouncedNumberInput: FC<Props> = ({
  onChange,
  timeout = 50,
  value,
  max,
  ...props
}) => {
  const [displayValue, setDisplayValue] = useState<string | undefined>('');

  const changeValue = useCallback(
    newValue => {
      if (newValue.startsWith('0') && newValue.length === 1) {
        return;
      }
      const parsedValue = Number(newValue);
      if (max !== undefined && parsedValue > max) {
        newValue = String(max);
      }
      onChange(newValue);
      setDisplayValue(newValue);
    },
    [onChange, max]
  );

  useEffect(() => {
    if (value !== displayValue) {
      setDisplayValue(value);
    }
  }, [value]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (displayValue !== value) {
        if (displayValue) {
          changeValue(displayValue);
          return;
        }
        onChange('');
        setDisplayValue('');
      }
    }, timeout);
    return () => clearTimeout(handler);
  }, [displayValue]);

  return (
    <S.InputTable
      {...props}
      autoComplete="off"
      onChange={e => {
        const newValue = e.target?.value;
        if (newValue.startsWith('0') && newValue.length === 1) {
          return;
        }
        setDisplayValue(newValue);
      }}
      onKeyPress={event => {
        const isFirstDigit = displayValue === '' || displayValue === undefined;
        if (isFirstDigit && event.key === '0') {
          event.preventDefault();
        } else if (!/[0-9]/.test(event.key)) {
          event.preventDefault();
        }
      }}
      type="text"
      value={displayValue}
    />
  );
};

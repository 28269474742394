import { createSelector } from '~/helpers/util/selectors';
import { AuthState } from './duck';

export const getRoot = (state: any) => {
  return state.ui.auth as AuthState;
};

export const getPermissions = createSelector([getRoot], state => {
  return state.permissions;
});

export const getProfile = createSelector([getRoot], state => {
  return state.profile;
});
export const getAccounts = createSelector([getRoot], state => {
  return state.accounts;
});

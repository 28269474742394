import { AxiosResponse } from 'axios';
import apiProducer from '../api';

import { APIError } from '~/domain/api/errors';

export type FindMany = {
  categoryId?: number;
  siteId?: number;
};

export const findMany = async ({ siteId, categoryId }: FindMany) => {
  try {
    let URL = `/production-product-group?`;

    if (categoryId) {
      URL += `categoryId=${categoryId}&`;
    }
    if (siteId) {
      URL += `siteId=${siteId}&`;
    }

    const response: AxiosResponse = await apiProducer.get(URL);

    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

import * as API from '../api';

export default class ProductsPackingSummary {
  private readonly json: API.ProductsPackingSummary;

  public readonly productCode: string;
  public readonly productDescription: string;
  public readonly packingCode: string;
  public readonly packingDescription: string;
  public readonly minimum: number;
  public readonly multiple: number;
  public readonly compositionName: string;
  public readonly unitPerPacking: number;
  public readonly packingPerLayer: number;
  public readonly layerPerTrolley: number;

  constructor(json: API.ProductsPackingSummary) {
    this.json = json;
    this.productCode = this.getProductCode();
    this.productDescription = this.getProductDescription();
    this.packingCode = this.getPackingCode();
    this.packingDescription = this.getPackingDescription();
    this.minimum = this.getMinimum();
    this.multiple = this.getMultiple();
    this.compositionName = this.getCompositionName();
    this.unitPerPacking = this.getUnitPerPacking();
    this.packingPerLayer = this.getPackingPerLayer();
    this.layerPerTrolley = this.getLayerPerTrolley();
  }

  public toJSON() {
    return this.json;
  }

  private getProductCode() {
    return this.json.productCode || '';
  }

  private getProductDescription() {
    return this.json.productDescription || '';
  }

  private getPackingCode() {
    return this.json.packingCode || '';
  }

  private getPackingDescription() {
    return this.json.packingDescription || '';
  }

  private getMinimum() {
    return this.json.minimum || Number.NaN;
  }

  private getMultiple() {
    return this.json.multiple || Number.NaN;
  }

  private getCompositionName() {
    return this.json.compositionName || '';
  }

  private getUnitPerPacking() {
    return this.json.unitPerPacking || Number.NaN;
  }

  private getPackingPerLayer() {
    return this.json.packingPerLayer || Number.NaN;
  }

  private getLayerPerTrolley() {
    return this.json.layerPerTrolley || Number.NaN;
  }
}

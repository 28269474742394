import { call, put } from 'redux-saga/effects';
import { UploadFile } from 'antd/lib/upload/interface';
import { actions, ParamRequestAnnouncementList } from './duck';
import * as API from '~/domain/api';
import * as AnnouncementService from '~/services/producer/Announcement';
import { Announcement, AnnouncementVisualization, File } from '~/domain/model';

export function* requestAnnouncementList({
  description,
  validity,
  role,
  status,
  startDate,
  endDate,
  pageNumber,
  pageSize
}: ParamRequestAnnouncementList) {
  yield put(actions.update({ announcementList: [] }));
  const response: API.ServerResponse = yield call(
    AnnouncementService.requestAnnouncementList,
    description,
    validity,
    role,
    status,
    startDate,
    endDate,
    pageNumber,
    pageSize
  );
  const data: API.DefaultResponse = response.data;
  const announcementListResult: API.Announcement[] = data.results ?? [];
  let announcementList: Announcement[] = [];
  if (announcementListResult) {
    announcementList = announcementListResult.map(
      (announcement: API.Announcement) => new Announcement(announcement)
    );
  }
  yield put(actions.update({ announcementList }));
  return response;
}

export function* requestAnnouncementBanner() {
  yield put(actions.update({ announcementBanner: [] }));
  const response: API.ServerResponse = yield call(
    AnnouncementService.requestAnnouncementBanner
  );

  const data: API.AnnouncementVisualization[] = response.data;
  const announcementResult = data ?? undefined;
  let announcementBanner: AnnouncementVisualization[] | [];
  if (announcementResult) {
    announcementBanner = announcementResult.map(
      (announcement: API.AnnouncementVisualization) =>
        new AnnouncementVisualization(announcement)
    );
    yield put(actions.update({ announcementBanner }));
  }
  return response;
}

export function* getById(id: number) {
  yield put(actions.update({ announcementForEdit: undefined }));
  const response: API.ServerResponse = yield call(
    AnnouncementService.getById,
    id
  );

  const data: API.Announcement = response.data;
  const announcementResult = data ?? undefined;
  let announcementForEdit: Announcement | undefined;
  if (announcementResult) {
    announcementForEdit = new Announcement(announcementResult);
  }
  yield put(actions.update({ announcementForEdit }));
  return response;
}

export function* getFilesById(id: number) {
  yield put(actions.update({ files: [] }));
  const response: API.ServerResponse = yield call(
    AnnouncementService.getFilesById,
    id
  );

  const filesResult: File[] = response.data ?? [];
  yield put(actions.update({ files: filesResult }));
  return response;
}

export function* postReadAnnouncement(id: number) {
  yield call(AnnouncementService.postReadAnnouncement, id);
}

export function* create(
  environment: number,
  role: number,
  description: string,
  required: boolean,
  startDate: string,
  endDate: string,
  content: string,
  documents?: UploadFile[],
  image?: UploadFile,
  id?: number
) {
  const response: API.ServerResponse = yield call(
    AnnouncementService.createOrPut,
    environment,
    role,
    description,
    required,
    startDate,
    endDate,
    content,
    documents,
    image,
    id
  );
  return response;
}

export function* activeOrInactiveAnnouncement(id: number) {
  yield call(AnnouncementService.activeOrInactiveAnnouncement, id);
}

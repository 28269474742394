import * as API from '../api';

export default class ProductDetailed {
  private readonly json: API.ProductDetailed;
  public readonly categoryDescription: string;
  public readonly categoryId: number;
  public readonly fullName: string;
  public readonly groupDescription: string;
  public readonly groupId: number;
  public readonly id: number;
  public readonly simpleName: string;

  private getCategoryDescription(): string {
    return this.json.categoryDescription ?? '';
  }

  private getCategoryId(): number {
    return this.json.categoryId ?? Number.NaN;
  }

  private getFullName(): string {
    return this.json.fullName ?? '';
  }

  private getGroupDescription(): string {
    return this.json.groupDescription ?? '';
  }

  private getGroupId(): number {
    return this.json.groupId ?? Number.NaN;
  }

  private getId(): number {
    return this.json.id ?? Number.NaN;
  }

  private getSimpleName(): string {
    return this.json.simpleName ?? '';
  }

  constructor(json: API.ProductDetailed) {
    this.json = json;
    this.id = this.getId();
    this.categoryDescription = this.getCategoryDescription();
    this.categoryId = this.getCategoryId();
    this.fullName = this.getFullName();
    this.groupDescription = this.getGroupDescription();
    this.groupId = this.getGroupId();
    this.simpleName = this.getSimpleName();
  }
}

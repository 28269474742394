import { Button, Modal, Spin } from 'antd';
import styled from 'styled-components';

export const ModalAnnouncement = styled(Modal)`
  min-width: max-content;
  .ant-modal-body {
    padding: 0 !important;
  }
`;

export const ModalAnnouncementHeader = styled.div`
  background-color: #f78e1e;
  width: 100%;
  height: 94px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  .title,
  .date {
    padding: 0 32px;
    color: #fbfcfe;
    font-weight: 400;
  }

  .title {
    font-size: 20px;
    line-height: 20px;
  }

  .date {
    margin-top: 4px;
    font-size: 14px;
    line-height: 22px;
  }
`;

export const ModalAnnouncementBody = styled.div`
  > div {
    margin-top: 16px;
  }
  padding: 32px;
`;

export const ButtonsActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const DocumentsDownload = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  padding: 8px;
`;

export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  cursor: pointer;
`;

export const Loader = styled(Spin)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
`;

export const ButtonPrevius = styled(Button)`
  margin-right: 16px;
`;

export const ButtonNext = styled(Button)`
  margin-right: 16px;
`;

export const ButtonOk = styled(Button).attrs({ type: 'primary' })`
  margin-right: 16px;
`;

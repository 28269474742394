import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefaultPagination } from '~/domain/model';
import { KeyLiberation } from '~/domain/model/KeyLiberation/KeyLiberation';
import { KeyLiberationHistory } from '~/domain/model/KeyLiberation/KeyLiberationHistory';
import {
  keyLiberationAdd,
  keyLiberationChangeAllStatus,
  keyLiberationChangeStatus,
  keyLiberationFetch,
  keyLiberationHistoryFetch
} from './apiCalls';

export type FetchStatus = 'idle' | 'pending' | 'success' | 'failure';

export interface KeyLiberationState {
  list: KeyLiberation[];
  keyLiberation: KeyLiberation | null;
  historic: KeyLiberationHistory[];
  pagination: DefaultPagination;
  totalCount: number;
  loading: boolean;
  showModalAdd: boolean;
  showModalHistory: boolean;
  submitFormFilter: boolean;
}

const initialState: KeyLiberationState = {
  list: [],
  keyLiberation: null,
  historic: [],
  pagination: { PageSize: 10, PageNumber: 1 },
  totalCount: 0,
  loading: false,
  showModalAdd: false,
  showModalHistory: false,
  submitFormFilter: false
};
const prefix = 'KeyLiberation';

const KeyLiberationSlice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    resetState: () => initialState,
    setPagination: (state, action: PayloadAction<DefaultPagination>) => {
      state.pagination = action.payload;
    },
    setSelected: (state, action: PayloadAction<KeyLiberation>) => {
      state.keyLiberation = action.payload;
    },
    setShowModalAdd: (state, action: PayloadAction<boolean>) => {
      state.showModalAdd = action.payload;
    },
    setShowModalHistory: (state, action: PayloadAction<boolean>) => {
      state.showModalHistory = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(keyLiberationFetch.pending, state => {
      state.loading = true;
    });
    builder.addCase(keyLiberationFetch.fulfilled, (state, action) => {
      state.list = action.payload.results;
      state.totalCount = action.payload.totalCount;
      state.loading = false;
      state.submitFormFilter = false;
    });
    builder.addCase(keyLiberationFetch.rejected, state => {
      state.loading = false;
    });
    builder.addCase(keyLiberationAdd.pending, state => {
      state.loading = true;
    });
    builder.addCase(keyLiberationAdd.fulfilled, state => {
      state.loading = false;
      state.showModalAdd = false;
      state.submitFormFilter = true;
    });
    builder.addCase(keyLiberationAdd.rejected, state => {
      state.loading = false;
    });
    builder.addCase(keyLiberationHistoryFetch.pending, state => {
      state.loading = true;
    });
    builder.addCase(keyLiberationHistoryFetch.fulfilled, (state, action) => {
      state.historic = action.payload;
      state.loading = false;
    });
    builder.addCase(keyLiberationHistoryFetch.rejected, state => {
      state.loading = false;
    });
    builder.addCase(keyLiberationChangeStatus.pending, state => {
      state.loading = true;
    });
    builder.addCase(keyLiberationChangeStatus.fulfilled, state => {
      state.loading = false;
      state.submitFormFilter = true;
    });
    builder.addCase(keyLiberationChangeStatus.rejected, state => {
      state.loading = false;
    });
    builder.addCase(keyLiberationChangeAllStatus.fulfilled, state => {
      state.loading = false;
      state.submitFormFilter = true;
    });
  }
});

export const actions = KeyLiberationSlice.actions;
export const reducer = KeyLiberationSlice.reducer;

import { AxiosResponse } from 'axios';
import IUseCase from '~/@core/application/IUseCase';
import Consolidated from '~/@core/domain/model/rfidReports/Consolidated';
import { InventoryService } from '~/@core/infrastructure/api/services/rfidReports';

export default class UpdateConsolidateds
  implements IUseCase<Consolidated, Promise<AxiosResponse<void>>>
{
  execute(params: Consolidated): Promise<AxiosResponse<void>> {
    return InventoryService.UpdateConsolidateds(params);
  }
}

import { useCallback, useRef } from 'react';

export default function useDebounce<Func extends (...args: any[]) => void>(
  func: Func,
  delay: number
): (...args: Parameters<Func>) => void {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const debouncedFunction = useCallback(
    (...args: Parameters<Func>) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        func(...args);
      }, delay);
    },
    [func, delay]
  );

  return debouncedFunction;
}

import * as API from '~/domain/api';
import { APIError } from '~/domain/api/errors';
import apiProducer from '../api';

export const create = async (customersGroup: API.CustomerGroup) => {
  try {
    const { id, description, customersIds, siteId } = customersGroup;

    const data: any = {
      id,
      description,
      customersIds,
      siteId
    };
    const response: any = await apiProducer.put('/customers/group', data);
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if (error?.response?.status === 422) {
      throw new APIError(String(error?.response?.data[0]));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

import { createSelector } from 'reselect';

import { DirectedOffersState } from '.';

export const getRoot = (state: any) => {
  return state.directedOffers as DirectedOffersState;
};
export const getDirectedOffers = createSelector(
  [getRoot],
  state => state.directedOffers
);
export const getDirectedOffersStocks = createSelector(
  [getRoot],
  state => state.stocks
);
export const getDirectedOffersTableErrors = createSelector(
  [getRoot],
  state => state.tableInputErrors
);
export const getValidated = createSelector([getRoot], state => state.validated);

export const getTableData = createSelector([getRoot], state => state.tableData);

export const getResumeTableData = createSelector(
  [getRoot],
  state => state.resumeTableData
);

export const getSubmitNow = createSelector([getRoot], state => state.submitNow);

export const getCurrentStep = createSelector(
  [getRoot],
  state => state.currentStep
);

export const getSelectedOffer = createSelector(
  [getRoot],
  state => state.selectedOffer
);

export const getCustomerGroups = createSelector(
  [getRoot],
  state => state.customerGroups
);

export const getCustomerGroupsArraySelected = createSelector(
  [getRoot],
  state => state.customerGroupsArraySelected
);

export const getCloseCustomersModal = createSelector(
  [getRoot],
  state => state.closeCustomersModal
);

export const getConsumptionHistoryData = createSelector(
  [getRoot],
  state => state.consumptionHistoryData
);

export const getDirectedOffersPagination = createSelector(
  [getRoot],
  state => state.directedOffersPagination
);

export const getOffer = createSelector([getRoot], state => state.offer);

export const getShowKeysForEdit = createSelector(
  [getRoot],
  state => state.showKeysForEdit
);

export const getClearKeys = createSelector([getRoot], state => state.clearKeys);

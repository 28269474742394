import { createSelector } from 'reselect';

import { EcommerceManagementState } from '.';

export const getRoot = (state: any) => {
  return state.ecommerceManagement as EcommerceManagementState;
};

export const getList = createSelector([getRoot], state => {
  return state.list;
});
export const getTotal = createSelector([getRoot], state => {
  return state.totalCount;
});

export const getPagination = createSelector([getRoot], state => {
  return state.pagination;
});

export const cancelStatus = createSelector([getRoot], state => {
  return state.cancelPurchaseManagementStatus;
});

export const isLoading = createSelector([getRoot], state => {
  return state.loading;
});

export const getHistoryPurchaseManagement = createSelector([getRoot], state => {
  return state.historyPurchaseManagement;
});

export const getProducts = createSelector([getRoot], state => {
  return state.products;
});

/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DeliveryPattern } from '~/domain/model';

export type DeliveryPatternState = {
  deliveryPatternsById: Map<string, DeliveryPattern>;
};

const initialState: DeliveryPatternState = {
  deliveryPatternsById: new Map()
};
export type UpdateDeliveryPattern = {
  deliveryPatternsById: Map<string, DeliveryPattern>;
};
const prefix = '@deliveryPattern';

const deliveryPattern = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear(state) {
      state.deliveryPatternsById = initialState.deliveryPatternsById;
    },
    update(state, action: PayloadAction<UpdateDeliveryPattern>) {
      state.deliveryPatternsById = action.payload.deliveryPatternsById as Map<
        string,
        DeliveryPattern
      >;
    }
  }
});

export const actions = deliveryPattern.actions;
export const reducer = deliveryPattern.reducer;

const caseReducers = deliveryPattern.caseReducers;
export const Types = Object.freeze({
  UPDATE: `${prefix}/${caseReducers.update.name}`,
  CLEAR: `${prefix}/${caseReducers.clear.name}`
});

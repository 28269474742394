import { AxiosError } from 'axios';
import moment, { Moment } from 'moment';
import store from '~/store';
import { VolOffersCreateAction } from '~/store/modules/_ui/volOffersCreate/duck';
import { alertRequest } from '~/store/modules/alert/actions';
/* eslint-disable no-unsafe-finally */
export const isCPF = (value: string) => {
  const cpf = stringClear(value, '1234567890');

  // Verifica se foi informado todos os digitos corretamente
  if (isNullOrEmpty(cpf) || cpf.length !== 11) return false;

  // Verifica se foi informada uma sequência de digitos repetidos.
  switch (cpf) {
    case '00000000000':
    case '11111111111':
    case '22222222222':
    case '33333333333':
    case '44444444444':
    case '55555555555':
    case '66666666666':
    case '77777777777':
    case '88888888888':
    case '99999999999':
      return false;
    default:
      return true;
  }
};

export const isValidCpfCnpj = (cpf: string) => {
  if (
    !cpf ||
    (cpf.length !== 11 && cpf.length !== 14) ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999' ||
    cpf === '00000000000000' ||
    cpf === '11111111111111' ||
    cpf === '22222222222222' ||
    cpf === '33333333333333' ||
    cpf === '44444444444444' ||
    cpf === '55555555555555' ||
    cpf === '66666666666666' ||
    cpf === '77777777777777' ||
    cpf === '88888888888888' ||
    cpf === '99999999999999'
  ) {
    return false;
  }
  return true;
};

export const isNullOrEmpty = (value: any) => {
  if (!value) return true;

  if (value === undefined) return true;

  if (value === 'undefined') return true;

  if (value === '') return true;

  if (value.length <= 0) return true;

  return false;
};

export const stringClear = (value: any, valid: string) => {
  let result = '';

  if (isNullOrEmpty(value)) return result;

  let aux = 0;
  for (let i = 0; i < `${value}`.length; i++) {
    aux = valid.indexOf(`${value}`.substring(i, i + 1));

    if (aux >= 0) result += `${value}`.substring(i, i + 1);
  }
  return result;
};

export const stringReplace = (
  value: string,
  charOrigin: string,
  charChange: string
) => {
  let result = '';
  let isChange = false;

  if (isNullOrEmpty(value)) return result;

  if (
    charOrigin.length !== charChange.length ||
    isNullOrEmpty(charOrigin) ||
    isNullOrEmpty(charChange)
  )
    return value;

  for (let i = 0; i < value.length; i++) {
    isChange = false;

    for (let j = 0; j < charOrigin.length; j++) {
      if (value.substring(i, i + 1) === charOrigin.substring(j, j + 1)) {
        isChange = true;

        result += charChange.substring(j, j + 1);

        j = charOrigin.length;
      }
    }

    if (!isChange) result += value.substring(i, i + 1);
  }

  return result;
};

export const getBase64 = (file: any) => {
  let base64Str = '';

  try {
    const reader = new FileReader();

    reader.onloadend = () => {
      base64Str = String(reader.result);
    };

    reader.readAsDataURL(file);
    return base64Str;
  } catch (err) {
    //
  }
};

export const checkDateValid = (planWeeks: any, date: any): any => {
  const planned = planWeeks?.find((planWeek: any) => {
    const initialDatePlan = moment(new Date(planWeek.initialDate)).format(
      'YYYY-MM-DD'
    );
    const endDatePlan = moment(new Date(planWeek.endDate)).format('YYYY-MM-DD');

    if (initialDatePlan <= date && endDatePlan >= date) {
      return true;
    }
  });
  return planned;
};
export const checkCompetence = (competencies: any, date: any): any => {
  const check = { valid: false, competencyId: '' };
  competencies.map((competency: any) => {
    competency.competenceEvents.map((competencyEvent: any) => {
      const start = new Date(competencyEvent.startDate);
      const end = new Date(competencyEvent.endDate);

      if (date >= start && date <= end) {
        check.valid = true;
        check.competencyId = competency.id;
      }
    });
  });
  return check;
};

export const weekFormat = (value: any) => {
  return `Semana: ${parseInt(value.week(), 10)} - ${value.format('YYYY')}`;
};
export const brFormat = (value: Moment) => {
  return value.format('DD/MM/YYYY');
};

export const usFormat = (value: Moment) => {
  return value.format('YYYY-MM-DD');
};

export const dayMonthFormat = (value: Moment) => {
  return value.format('DD/MM');
};
export const formtDateBr = (date: Date): string => {
  return `${date.getUTCDate()}/${
    date.getUTCMonth() + 1
  }/${date.getUTCFullYear()}`;
};
export const formatDefault = (date: Date): string => {
  return `${date.getUTCFullYear()}-${
    date.getUTCMonth() + 1
  }-${date.getUTCDate()}`;
};

export const formtDateUs = (date: Date): string => {
  return `${
    date.getUTCMonth() + 1
  }/${date.getUTCDate()}/${date.getUTCFullYear()}`;
};

export const getFirstDayWeek = (date: any): string => {
  const newDate = new Date(
    `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()}`
  );
  newDate.setDate(date.getUTCDate() - date.getUTCDay());
  return formtDateUs(newDate);
};

export const getLastDayWeek = (date: any): string => {
  const newDate = new Date(
    `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()}`
  );
  newDate.setDate(date.getUTCDate() - date.getUTCDay());
  newDate.setDate(newDate.getUTCDate() + 6);
  return formtDateUs(newDate);
};

export function onlyNumber(evt: any) {
  const numberPattern = /\d+/g;
  const keysAccepteds = [
    'Delete',
    'Backspace',
    'ArrowLeft',
    'ArrowRight',
    'Tab'
  ];

  if (!numberPattern.test(evt.key) && !keysAccepteds.includes(evt.key)) {
    return evt.preventDefault();
  }
}

export function onlyNumberDotAndComma(evt: any) {
  const numberPattern = /^[\d,.]+$/;
  const keysAccepteds = [
    'Delete',
    'Backspace',
    'ArrowLeft',
    'ArrowRight',
    'Tab'
  ];

  if (!numberPattern.test(evt.key) && !keysAccepteds.includes(evt.key)) {
    return evt.preventDefault();
  }
}

export function onlyLetters(evt: any) {
  const lettersPattern = /^[A-Za-z]+$/;
  const keysAccepteds = [
    'Delete',
    'Backspace',
    'ArrowLeft',
    'ArrowRight',
    'Tab'
  ];

  if (!lettersPattern.test(evt.key) && !keysAccepteds.includes(evt.key)) {
    return evt.preventDefault();
  }
}

export const locale = {
  lang: {
    locale: 'pt_BR',
    placeholder: 'Select date',
    rangePlaceholder: ['Start date', 'End date'],
    today: 'Today',
    now: 'Now',
    backToToday: 'Back to today',
    ok: 'OK',
    clear: 'Clear',
    month: 'Month',
    year: 'Year',
    timeSelect: 'Select time',
    dateSelect: 'Select date',
    monthSelect: 'Choose a month',
    yearSelect: 'Choose a year',
    decadeSelect: 'Choose a decade',
    yearFormat: 'YYYY',
    dateFormat: 'M/D/YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'M/D/YYYY HH:mm:ss',
    monthFormat: 'MMMM',
    monthBeforeYear: true,
    previousMonth: 'Previous month (PageUp)',
    nextMonth: 'Next month (PageDown)',
    previousYear: 'Last year (Control + left)',
    nextYear: 'Next year (Control + right)',
    previousDecade: 'Last decade',
    nextDecade: 'Next decade',
    previousCentury: 'Last century',
    nextCentury: 'Next century'
  },
  timePickerLocale: {
    placeholder: 'Select time'
  },
  dateFormat: 'YYYY-MM-DD',
  dateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
  weekFormat: 'YYYY-wo',
  monthFormat: 'YYYY-MM'
};
export const normalize = (collection: any[]) => {
  return new Map(
    collection.map((elem: any) => {
      return [elem.key, elem.val];
    })
  );
};
export const generateMinTolerance = (
  avgPriceSold: number,
  percentBelowAverage: number
) => {
  return parseFloat(
    (avgPriceSold - (avgPriceSold * percentBelowAverage) / 100).toFixed(2)
  );
};

export const generateMaxTolerance = (
  avgPriceSold: number,
  percentAboveAverage: number
) => {
  return parseFloat(
    (avgPriceSold + (avgPriceSold * percentAboveAverage) / 100).toFixed(2)
  );
};

export const validToleranceVol = (
  volOffer: VolOffersCreateAction,
  minTolerance: number,
  maxTolerance: number
) => {
  let validAlert = '';
  const productNameOfferCatch = volOffer.productDescription ?? '';
  const productName =
    productNameOfferCatch.length > 35
      ? `${productNameOfferCatch.substring(0, 35)}...`
      : productNameOfferCatch;
  let lowPrice = '';
  let highPrice = '';

  if (volOffer.priceByPacking < minTolerance) {
    lowPrice += 'EMB, ';
  }
  if (volOffer.priceByPacking > maxTolerance) {
    highPrice += 'EMB, ';
  }
  if (volOffer.priceByLayer < minTolerance) {
    lowPrice += 'CAM, ';
  }
  if (volOffer.priceByLayer > maxTolerance) {
    highPrice += 'CAM, ';
  }
  if (volOffer.priceByTrolley < minTolerance) {
    lowPrice += 'CAR, ';
  }
  if (volOffer.priceByTrolley > maxTolerance) {
    highPrice += 'CAR, ';
  }
  validAlert = `<abbr title="${
    volOffer.productDescription
  }">${productName}</abbr> -
    ${volOffer.productQualityDescription} -
    ${highPrice.length > 0 ? highPrice : ''} ${
    highPrice.length > 0 ? 'Acima do preço ' : ''
  } ${lowPrice.length > 0 && highPrice.length > 0 ? '/' : ''}
    ${lowPrice.length > 0 ? lowPrice : ''} ${
    lowPrice.length > 0
      ? '<span style="color: white; background-color: red; font-weight: bold; padding: 5px; border-radius: 5px;">Abaixo do preço</span> <br>'
      : '<br>'
  }`;
  return lowPrice.length === 0 && highPrice.length === 0 ? '' : validAlert;
};

export const verifyObject = (object: any) => {
  if (object && Object.keys(object).length > 0) {
    return true;
  }
  return false;
};

export const convertInt = (value: string) => parseInt(value, 10);

export const handleDefaultErrorApi = (error: AxiosError) => {
  let messageAlert = ``;
  error.response?.data.errors.messages.forEach((message: string) => {
    messageAlert += message;
  });
  store.dispatch(alertRequest(JSON.stringify(messageAlert), 'error'));
};

export const isMobileOrTablet = () => {
  return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
};

export const getShortPackingDescription = (description: string) => {
  return description?.length > 10
    ? `${description.substring(0, 10)}...`
    : description;
};

import produce from 'immer';
import { CustomerGroup } from '~/domain/model';
import { createCreator, createReducer, Reducer } from '~/helpers/util/reducers';
import { CreateInit } from '../../_core/customersGroup/duck';

export type CreateStatus = 'idle' | 'creating' | 'success' | 'failure';

export type CustomerGroupCreateState = {
  customerGroup: CustomerGroup;
  createStatus: CreateStatus;
  errorMessage?: string;
};

export type Error = {
  errorMessage?: string;
};

export const initialState: CustomerGroupCreateState = {
  customerGroup: new CustomerGroup({}),
  createStatus: 'idle'
};

const prefix = '@CustomersGroupCreate';

export const Types = Object.freeze({
  CLEAR: `${prefix}/CLEAR`,
  CREATE_INIT: `${prefix}/CREATE_INIT`,
  CREATE_SUCCESS: `${prefix}/CREATE_SUCCESS`,
  CREATE_FAILURE: `${prefix}/CREATE_FAILURE`
});

const clearReducer: Reducer<CustomerGroupCreateState, void> = state => {
  return produce(state, draft => {
    draft.customerGroup = initialState.customerGroup;
    draft.createStatus = initialState.createStatus;
  });
};

const createInitReducer: Reducer<CustomerGroupCreateState, void> = state => {
  return produce(state, draft => {
    draft.createStatus = 'creating';
  });
};

const createSuccessReducer: Reducer<CustomerGroupCreateState, void> = state => {
  return produce(state, draft => {
    draft.createStatus = 'success';
  });
};

const createFailureReducer: Reducer<CustomerGroupCreateState, Error> = (
  state,
  action
) => {
  return produce(state, draft => {
    draft.createStatus = 'failure';
    draft.errorMessage = action.errorMessage;
  });
};

export const actions = Object.freeze({
  clear: createCreator<void>(Types.CLEAR),
  createInit: createCreator<CreateInit>(Types.CREATE_INIT),
  createSuccess: createCreator<void>(Types.CREATE_SUCCESS),
  createFailure: createCreator<Error>(Types.CREATE_FAILURE)
});

export const reducer = createReducer(initialState, {
  [Types.CLEAR]: clearReducer,
  [Types.CREATE_INIT]: createInitReducer,
  [Types.CREATE_SUCCESS]: createSuccessReducer,
  [Types.CREATE_FAILURE]: createFailureReducer
});

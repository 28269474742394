import { OfferSettingsState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.ui.offerSettings as OfferSettingsState;
};

export const getCountingPatternID = (state: OfferSettingsState) =>
  state.countingPatternID;

export const getIsLoading = createSelector(
  [getRoot],
  state =>
    state.fetchStatus === 'pending' ||
    state.fetchOfferSettingsStatus === 'pending' ||
    state.upsertStatus === 'pending'
);

export const getFetchStatus = createSelector(
  [getRoot],
  state => state.fetchStatus
);

export const getFetchOfferSettingsStatus = createSelector(
  [getRoot],
  state => state.fetchOfferSettingsStatus
);

export const getUpsertStatus = createSelector(
  [getRoot],
  state => state.upsertStatus
);

export const getCountingPatternHistory = createSelector(
  [getRoot],
  state => state.countingPatternHistory
);

export const getCompositionHistory = createSelector(
  [getRoot],
  state => state.compositionHistory
);

export const getMinimumAmountCutProductHistory = createSelector(
  [getRoot],
  state => state.minimumAmountCutProductHistory
);

export const getFetchCountingPatternHistoryStatus = createSelector(
  [getRoot],
  state => state.fetchCountingPatternHistoryStatus
);

export const getFetchCompositionHistoryStatus = createSelector(
  [getRoot],
  state => state.fetchCompositionHistoryStatus
);

export const getFetchMinimumAmountCutProductHistoryStatus = createSelector(
  [getRoot],
  state => state.fetchMinimumAmountHistoryStatus
);

export const getPageNumber = createSelector([getRoot], state => {
  return state.pageNumber;
});

export const getPageSize = createSelector([getRoot], state => state.pageSize);

export const getTotalCount = createSelector(
  [getRoot],
  state => state.totalCount
);

export const getErrorMessage = createSelector([getRoot], state => {
  return state.errorMessage;
});

import { AxiosError, AxiosResponse } from 'axios';
import api from '../../config/index';
import FilterTagPrintingList from '~/@core/domain/filters/tag/FilterTagPrintingList';
import { TagByClient } from '~/@core/domain/model/tag';
import DefaultPaginated from '~/@core/domain/@shared/DefaultPaginated';

export const getTagsByClient = (params: FilterTagPrintingList) => {
  return new Promise<DefaultPaginated<TagByClient[]>>((resolve, reject) => {
    api
      .get<void, AxiosResponse<DefaultPaginated<TagByClient[]>>>(
        '/tags/by-order',
        {
          params
        }
      )
      .then((response: AxiosResponse<DefaultPaginated<TagByClient[]>>) => {
        resolve(response.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
};

import React from 'react';
import { Row } from 'antd';
import type { RowProps } from 'antd';

interface DefaultRowProps extends RowProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export default function DefaultRow(props: DefaultRowProps) {
  const { children, style } = props;

  return (
    <Row style={style} gutter={16}>
      {children}
    </Row>
  );
}

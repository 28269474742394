import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SalesReportLkp, SalesReportVol } from '~/domain/model';

export type Status = 'cleared' | 'updated';

export type SalesReportState = {
  salesReportVolList: SalesReportVol[];
  salesReportLkpList: SalesReportLkp[];
  statusSalesReportVolList: Status;
  statusSalesReportLkpList: Status;
};

export type Update = {
  salesReportVolList?: SalesReportVol[];
  salesReportLkpList?: SalesReportLkp[];
};

export type FetchSalesReport = {
  siteId: number;
  weekId: number;
  productId?: number;
  ColorId?: number;
  pageNumber?: number;
  pageSize?: number;
};

const prefix = '@salesReportCore';

export const initialState: SalesReportState = {
  salesReportVolList: [],
  salesReportLkpList: [],
  statusSalesReportVolList: 'cleared',
  statusSalesReportLkpList: 'cleared'
};

const salesReport = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: state => {
      state.salesReportLkpList = initialState.salesReportLkpList;
      state.statusSalesReportLkpList = initialState.statusSalesReportLkpList;
      state.salesReportVolList = initialState.salesReportVolList;
      state.statusSalesReportVolList = initialState.statusSalesReportVolList;
    },
    clearVol: state => {
      state.salesReportVolList = initialState.salesReportVolList;
      state.statusSalesReportVolList = initialState.statusSalesReportVolList;
    },
    clearLkp: state => {
      state.salesReportLkpList = initialState.salesReportLkpList;
      state.statusSalesReportLkpList = initialState.statusSalesReportLkpList;
    },
    update: (state, action: PayloadAction<Update>) => {
      if (action.payload.salesReportLkpList) {
        state.salesReportLkpList = action.payload.salesReportLkpList;
      } else if (action.payload.salesReportVolList) {
        state.salesReportVolList = action.payload.salesReportVolList;
      }
    }
  }
});

export const actions = salesReport.actions;
export const reducer = salesReport.reducer;

const caseReducers = salesReport.caseReducers;
export const Types = Object.freeze({
  clear: `${prefix}/${caseReducers.clear.name}`,
  clearVol: `${prefix}/${caseReducers.clearVol.name}`,
  clearLkp: `${prefix}/${caseReducers.clearLkp.name}`,
  update: `${prefix}/${caseReducers.update.name}`
});

// #FIXME
/* eslint-disable @typescript-eslint/no-explicit-any */
export function searchRequest(id: any, keyword: any) {
  return {
    type: '@siteSimpleList/SEARCH_REQUEST',
    payload: { id, keyword }
  };
}

export function searchSuccess(data: any) {
  return {
    type: '@siteSimpleList/SEARCH_SUCCESS',
    payload: { data }
  };
}

export function searchFailure() {
  return {
    type: '@siteSimpleList/SEARCH_FAILURE'
  };
}

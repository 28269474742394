import * as API from '~/domain/api';
import { DeliveryPatternDetail } from '.';

export default class DeliveryPattern {
  private json: API.DeliveryPattern;
  public id: number;
  public patternDescription: string;
  public enabledStartDate: Date | null;
  public enabledEndDate: Date | null;
  public deliveryPatternDetails: DeliveryPatternDetail[];
  public isActive: boolean;
  public isFavorite: boolean;
  public creationDate: Date | null;

  public getId(): number {
    return this.json.id ?? Number.NaN;
  }

  public getPatternDescription(): string {
    return this.json.patternDescription ?? '';
  }

  public getEnabledStartDate(): Date | null {
    return this.json.enabledStartDate ?? null;
  }

  public getEnabledEndDate(): Date | null {
    return this.json.enabledEndDate ?? null;
  }

  public getDeliveryPatternDetails(): DeliveryPatternDetail[] {
    return this.json.siteDeliveryPatternDetails
      ? this.json.siteDeliveryPatternDetails.map(
          deliveryDetail => new DeliveryPatternDetail(deliveryDetail)
        )
      : ([] as DeliveryPatternDetail[]);
  }

  public getIsActive(): boolean {
    return this.json.isActive ?? false;
  }

  public getIsFavorite(): boolean {
    return this.json.isFavorite ?? false;
  }

  public getCreationDate(): Date | null {
    return this.json.creationDate ?? null;
  }

  constructor(json: API.DeliveryPattern) {
    this.json = json;
    this.id = this.getId();
    this.patternDescription = this.getPatternDescription();
    this.enabledStartDate = this.getEnabledStartDate();
    this.enabledEndDate = this.getEnabledEndDate();
    this.deliveryPatternDetails = this.getDeliveryPatternDetails();
    this.isActive = this.getIsActive();
    this.isFavorite = this.getIsFavorite();
    this.creationDate = this.getCreationDate();
  }
}

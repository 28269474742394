import { call, put } from 'redux-saga/effects';

import * as API from '~/domain/api';

import { actions } from './duck';
import { Product } from '~/domain/model';
import * as ProductService from '~/services/producer/Product';
import ProductSimple from '../../../../domain/model/product-simple';

type FindMany = {
  siteId: number;
  groupId?: number;
};

export function* findMany({ siteId, groupId }: FindMany) {
  const response: API.ServerResponse = yield call(ProductService.findMany, {
    siteId,
    groupId
  });

  const apiResponse = response.data;
  const products: API.Product[] = apiResponse || [];
  const productById = new Map(
    products.map(product => [String(product.id), new Product(product)])
  );
  yield put(actions.update({ byId: productById, byIdFiltered: productById }));
  return apiResponse;
}

export function* findProductsSimple(siteId: number) {
  const response: API.ServerResponse = yield call(
    ProductService.findProductsSimple,
    siteId
  );

  const apiResponse = response.data;
  const products: API.ProductSimple[] = apiResponse || [];
  const productsSimple = products.map(product => new ProductSimple(product));
  yield put(actions.updateProductsSimple({ productsSimple }));
  return apiResponse;
}

import * as API from '../api';

export default class SpecialDate {
  private readonly json: API.SpecialDate;
  public readonly id: number;
  public readonly creationDate: string;
  public readonly date: string;
  public readonly description: string;
  public readonly modificationDate: string;
  public readonly weekId: number;
  public readonly weekIdEndAlert: number;
  public readonly weekIdStartAlert: number;
  public readonly weekNumber: number;
  public readonly weekNumberEndAlert: number;
  public readonly weekNumberStartAlert: number;

  constructor(json: API.SpecialDate) {
    this.json = json;
    this.id = this.getId();
    this.creationDate = this.getCreationDate();
    this.date = this.getDate();
    this.description = this.getDescription();
    this.modificationDate = this.getModificationDate();
    this.weekId = this.getWeekId();
    this.weekIdEndAlert = this.getWeekIdEndAlert();
    this.weekIdStartAlert = this.getWeekIdStartAlert();
    this.weekNumber = this.getWeekNumber();
    this.weekNumberEndAlert = this.getWeekNumberEndAlert();
    this.weekNumberStartAlert = this.getWeekNumberStartAlert();
  }

  private getId() {
    return this.json.id || Number.NaN;
  }

  private getCreationDate() {
    return this.json.creationDate || '';
  }

  private getDate() {
    return this.json.date || '';
  }

  private getDescription() {
    return this.json.description || '';
  }

  private getModificationDate() {
    return this.json.modificationDate || '';
  }

  private getWeekId() {
    return this.json.weekId || Number.NaN;
  }

  private getWeekIdEndAlert() {
    return this.json.weekIdEndAlert || Number.NaN;
  }

  private getWeekIdStartAlert() {
    return this.json.weekIdStartAlert || Number.NaN;
  }

  private getWeekNumber() {
    return this.json.weekNumber || Number.NaN;
  }

  private getWeekNumberEndAlert() {
    return this.json.weekNumberEndAlert || Number.NaN;
  }

  private getWeekNumberStartAlert() {
    return this.json.weekNumberStartAlert || Number.NaN;
  }
}

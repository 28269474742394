import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import * as API from 'domain/api';
import { APIError } from '~/domain/api/errors';
import * as M from '~/domain/model';
import * as DirectedOfferService from '~/services/producer/DirectedOffer';
import { findStocksDirected } from '~/services/producer/Stock';
import { alertRequest } from '../../alert/actions';
import { actions } from '../../_ui/loading';

export const fetchDirectedOffers = createAsyncThunk(
  `directedOffers/fetchDirectedOffers`,
  async (params: any) => {
    const directedOffersData = await DirectedOfferService.fetchMany(params);
    return directedOffersData;
  }
);

export const fetchStocksDirected = createAsyncThunk(
  `stocks/fetchStocks`,
  async (params: M.FindDirectedOfferStockParams) => {
    const stocksData = await findStocksDirected(params);
    return stocksData;
  }
);

export const createDirectedOffers = createAsyncThunk(
  `directedOffers/createDirectedOffers`,
  async (params: API.DirectedOffer | any, { dispatch, rejectWithValue }) => {
    dispatch(
      actions.setLoading({
        status: 'fetching'
      })
    );
    try {
      await DirectedOfferService.create({
        ...params,
        offers: params.offers.map((offer: any) => {
          return {
            ...offer,
            minimumQuantity:
              offer.minimumQuantity && offer.minimumQuantity > 0
                ? offer.minimumQuantity
                : null,
            specialPrice:
              offer.specialPrice && offer.specialPrice > 0
                ? offer.specialPrice
                : null
          };
        })
      });
      dispatch(alertRequest('Estoque atualizado com sucesso!', 'success'));
      dispatch(
        actions.setLoading({
          status: 'success'
        })
      );
    } catch (err: unknown) {
      const error = err as AxiosError;
      const apiError = err as APIError;
      dispatch(
        actions.setLoading({
          status: 'failure'
        })
      );
      dispatch(alertRequest(`Erro: ${error}`, 'error'));

      if (error.response?.status === 422) {
        return rejectWithValue(error.response?.data);
      }
      if (apiError.message.length) {
        dispatch(alertRequest(String(apiError.message), 'error'));
        return rejectWithValue(apiError.message);
      }
    }
  }
);
export const updateDirectedOffersCustomers = createAsyncThunk(
  `directedOffers/updateDirectedOffersCustomers`,
  async (
    createCustomerParams: {
      volOfferId: number;
      customers: {
        customerId: number;
        customerGroupId: number;
      }[];
    },
    { dispatch, rejectWithValue }
  ) => {
    dispatch(
      actions.setLoading({
        status: 'fetching'
      })
    );
    try {
      await DirectedOfferService.updateCustomers(createCustomerParams);
      dispatch(alertRequest('Clientes atualizados com sucesso!', 'success'));
      dispatch(
        actions.setLoading({
          status: 'success'
        })
      );
      dispatch(actions.setSubmitForm(true));
    } catch (err: unknown) {
      const error = err as AxiosError;
      const apiError = err as APIError;
      dispatch(
        actions.setLoading({
          status: 'failure'
        })
      );
      dispatch(alertRequest(`Erro: ${error}`, 'error'));

      if (error.response?.status === 422) {
        return rejectWithValue(error.response?.data);
      }
      if (apiError.message.length) {
        dispatch(alertRequest(String(apiError.message), 'error'));
        return rejectWithValue(apiError.message);
      }
    }
  }
);

export const fetchConsumptionHistory = createAsyncThunk(
  `directedOffers/fetchConsumptionHistory`,
  async (params: number) => {
    const data = await DirectedOfferService.fetchConsumptionHistory(params);
    return data;
  }
);

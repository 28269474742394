/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as API from '~/domain/api';

export type Status = 'idle' | 'fetching' | 'success' | 'failure';

export type DirectedOffersListState = {
  status: Status;
  pagination: Pagination;
  history: API.History;
  confirmSave: boolean;
  waitEditOpen:
    | 'idle'
    | 'waiting'
    | 'waitingCloseTab'
    | 'openEdit'
    | 'closeTab';
};
export type DirectedOfferReloadAction = {
  directedOfferFilters: DirectedOffersListAction;
};
export type DirectedOffersListAction = {
  SiteId: number;
  WeekId?: number;
  pagination?: Pagination;
  CategoryId?: number;
  ExpeditionId?: number;
  GroupId?: number;
  ProductId?: number;
  ProductQualityId?: number;
  DirectedOfferTypeId?: number;
  SiteDeliveryPatternId?: number;
  StartDate?: any;
  EndDate?: any;
  Status?: string;
  ColorId?: number;
};

const prefix = '@directedOffersList';
export type Pagination = {
  pageNumber?: number;
  pageSize?: number;
  totalCount?: number;
};
export const initialState: DirectedOffersListState = {
  status: 'idle',
  pagination: {
    pageNumber: 1,
    pageSize: 100,
    totalCount: 0
  },
  history: {} as API.History,
  confirmSave: false,
  waitEditOpen: 'idle'
};

const directedOffersList = createSlice({
  name: prefix,
  initialState,
  reducers: {
    clear: () => initialState,
    fetch: (
      state: DirectedOffersListState,
      action: PayloadAction<DirectedOffersListAction>
    ) => {},
    fetchFilterData: (
      state: DirectedOffersListState,
      action: PayloadAction<number>
    ) => {},
    fetchHistory: (
      state: DirectedOffersListState,
      action: PayloadAction<number>
    ) => {},
    reloadAllData: (
      state,
      action: PayloadAction<DirectedOfferReloadAction>
    ) => {},
    // inactive: (state, action: PayloadAction<number>) => {},
    active: (state, action: PayloadAction<number>) => {},
    setPagination: (state, action: PayloadAction<Pagination>) => {
      state.pagination = action.payload;
    },
    setHistory: (state, action: PayloadAction<API.History>) => {
      state.history = action.payload;
    },
    setConfirmSave: (state, action: PayloadAction<boolean>) => {
      state.confirmSave = action.payload;
    },
    setWaitEditOpen: (
      state,
      action: PayloadAction<
        'idle' | 'waiting' | 'waitingCloseTab' | 'openEdit' | 'closeTab'
      >
    ) => {
      state.waitEditOpen = action.payload;
    }
  }
});
export const actions = directedOffersList.actions;
export const reducer = directedOffersList.reducer;

const caseReducers = directedOffersList.caseReducers;
export const Types = Object.freeze({
  fetch: `${prefix}/${caseReducers.fetch.name}`,
  fetchFilterData: `${prefix}/${caseReducers.fetchFilterData.name}`,
  fetchHistory: `${prefix}/${caseReducers.fetchHistory.name}`,
  active: `${prefix}/${caseReducers.active.name}`,
  reloadAllData: `${prefix}/${caseReducers.reloadAllData.name}`
});

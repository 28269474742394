import { SiteLinksEditState } from './duck';
import { createSelector } from '~/helpers/util/selectors';

export const getRoot = (state: any) => {
  return state.ui.siteLinksEdit as SiteLinksEditState;
};

export const getIsLoading = createSelector(
  [getRoot],
  state =>
    state.fetchInitStatus === 'pending' ||
    state.editStatus === 'pending' ||
    state.activeOrInactiveStatus === 'pending'
);

export const getFetchInitStatus = createSelector([getRoot], state => {
  return state.fetchInitStatus;
});

export const getEditStatus = createSelector([getRoot], state => {
  return state.editStatus;
});

export const getActiveOrInactiveStatus = createSelector([getRoot], state => {
  return state.activeOrInactiveStatus;
});

export const getErrorMessage = createSelector([getRoot], state => {
  return state.errorMessage;
});

import { AxiosResponse } from 'axios';
import apiProducer from '../api';

import { APIError } from '~/domain/api/errors';

type FindSalesReport = {
  siteId: number;
  weekId: number;
  productId?: number;
  pageNumber?: number;
  pageSize?: number;
  ColorId?: number;
};

export const findReportSalesVol = async ({
  siteId,
  weekId,
  productId,
  pageNumber,
  pageSize,
  ColorId
}: FindSalesReport) => {
  try {
    const params = new URLSearchParams();
    if (siteId) {
      params.append('data.siteId', String(siteId));
    }
    if (weekId) {
      params.append('data.weekId', String(weekId));
    }
    if (productId) {
      params.append('data.productId', String(productId));
    }
    if (pageNumber) {
      params.append('pageNumber', String(pageNumber));
    }
    if (pageSize) {
      params.append('pageSize', String(pageSize));
    }
    if (ColorId) {
      params.append('data.ColorId', String(ColorId));
    }
    const response: AxiosResponse = await apiProducer.get('/report/vol', {
      params
    });
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const findReportSalesLkp = async ({
  siteId,
  weekId,
  productId,
  pageNumber,
  pageSize,
  ColorId
}: FindSalesReport) => {
  try {
    const params = new URLSearchParams();
    if (siteId) {
      params.append('data.siteId', String(siteId));
    }
    if (weekId) {
      params.append('data.weekId', String(weekId));
    }
    if (productId) {
      params.append('data.productId', String(productId));
    }
    if (pageNumber) {
      params.append('pageNumber', String(pageNumber));
    }
    if (pageSize) {
      params.append('pageSize', String(pageSize));
    }
    if (ColorId) {
      params.append('data.ColorId', String(ColorId));
    }
    const response: AxiosResponse = await apiProducer.get('/report/lkp', {
      params
    });
    return response;
  } catch (error: any) {
    const { data: dataError } = error.response;
    const { Status: errorStatus } = dataError;

    if (typeof dataError === 'string') {
      throw new APIError(String(dataError));
    }

    if ([400, 500].includes(errorStatus)) {
      throw new APIError(String(dataError.Errors.Messages[0]));
    }

    const messageError =
      dataError && dataError.length > 0 ? dataError[0] : error.message;

    throw new APIError(String(messageError));
  }
};

export const fetchWorksheetVol = async (id: number) => {
  const response: AxiosResponse = await apiProducer.get(
    `/report/vol-download/${id}`
  );
  return response;
};

export const fetchWorksheetLkp = async (id: number) => {
  const response: AxiosResponse = await apiProducer.get(
    `/report/lkp-download/${id}`
  );
  return response;
};

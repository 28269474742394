import * as M from '~/domain/model';
import { VolOffer } from '~/domain/model';
import { convertInt } from '../util';

export const validQuantity = (value: any, maxValue?: any) => {
  if (!value || value < 1) {
    return Promise.reject(new Error('Digite uma quantidade valida'));
  }
  if (maxValue && value > maxValue) {
    return Promise.reject(new Error(`A quantidade máxima é ${maxValue}`));
  }
  return Promise.resolve();
};

export const validPercentage = (value: string) => {
  const valueNumber = convertInt(value);
  if (valueNumber > 100) {
    return Promise.reject(new Error('Digite uma porcentagem válida'));
  }
  return Promise.resolve();
};

export const validOffer = (volOffer: VolOffer) => {
  let message = '';
  if (volOffer.packingDistributionCompositionIds?.length === 0) {
    message += `Escolha as embalagens do ${volOffer.productDescription} - ${volOffer.productQualityDescription} <br>`;
  }
  if (!volOffer.startDate || !volOffer.endDate) {
    message += `Insira a vigência do ${volOffer.productDescription} - ${volOffer.productQualityDescription} <br>`;
  }
  if (!volOffer.siteDeliveryPatternId) {
    message += `Escolha a entrega do ${volOffer.productDescription} - ${volOffer.productQualityDescription} <br>`;
  }
  if (
    volOffer.priceByLayer === 0 &&
    volOffer.priceByPacking === 0 &&
    volOffer.priceByTrolley === 0
  ) {
    message += `Escolha pelo menos um preço do ${volOffer.productDescription} - ${volOffer.productQualityDescription} <br>`;
  }
  if (volOffer.specialPrice && !volOffer.minimumQuantity) {
    message += `Quantidade mínima do ${volOffer.productDescription} - ${volOffer.productQualityDescription} em branco <br>`;
  }
  if (volOffer.minimumQuantity && !volOffer.specialPrice) {
    message += `Preço especial do ${volOffer.productDescription} - ${volOffer.productQualityDescription} em branco <br>`;
  }

  return message;
};
export const validPrices = (volOffer: VolOffer) => {
  let validAlert = '';
  const productName =
    volOffer.productDescription.length > 35
      ? `${volOffer.productDescription.substring(0, 35)}...`
      : volOffer.productDescription;
  let lowPrice = '';
  let highPrice = '';

  if (
    volOffer.avgPriceSold > 0 &&
    volOffer.percentBelowAverage > 0 &&
    volOffer.percentAboveAverage > 0
  ) {
    const minTolerance = parseFloat(
      (
        volOffer.avgPriceSold -
        (volOffer.avgPriceSold * volOffer.percentBelowAverage) / 100
      ).toFixed(2)
    );

    const maxTolerance = parseFloat(
      (
        volOffer.avgPriceSold +
        (volOffer.avgPriceSold * volOffer.percentAboveAverage) / 100
      ).toFixed(2)
    );

    if (volOffer.priceByPacking < minTolerance && volOffer.priceByPacking) {
      lowPrice += 'EMB, ';
    }
    if (volOffer.priceByPacking > maxTolerance && volOffer.priceByPacking) {
      highPrice += 'EMB, ';
    }
    if (volOffer.priceByLayer < minTolerance && volOffer.priceByLayer) {
      lowPrice += 'CAM, ';
    }
    if (volOffer.priceByLayer > maxTolerance && volOffer.priceByLayer) {
      highPrice += 'CAM, ';
    }
    if (volOffer.priceByTrolley < minTolerance && volOffer.priceByTrolley) {
      lowPrice += 'CAR, ';
    }
    if (volOffer.priceByTrolley > maxTolerance && volOffer.priceByTrolley) {
      highPrice += 'CAR, ';
    }

    validAlert = `<abbr title="${
      volOffer.productDescription
    }">${productName}</abbr> - 
      ${volOffer.productQualityDescription} - 
      ${highPrice.length > 0 ? highPrice : ''} ${
      highPrice.length > 0 ? 'Acima do preço ' : ''
    } ${lowPrice.length > 0 && highPrice.length > 0 ? '/' : ''}
      ${lowPrice.length > 0 ? lowPrice : ''} ${
      lowPrice.length > 0
        ? '<span style="color: white; background-color: red; font-weight: bold; padding: 5px; border-radius: 5px;">Abaixo do preço</span> <br>'
        : '<br>'
    }`;
    return lowPrice.length === 0 && highPrice.length === 0 ? '' : validAlert;
  }
  return '';
};

export const validDirectedOffer = (
  offer: VolOffer,
  index: number
): M.TableInputError[] => {
  const tableInputErrors: M.TableInputError[] = [];
  if (
    !Number.isNaN(offer.priceByLayer) &&
    offer.priceByLayer === 0 &&
    !Number.isNaN(offer.priceByPacking) &&
    offer.priceByPacking === 0 &&
    !Number.isNaN(offer.priceByTrolley) &&
    offer.priceByTrolley === 0
  ) {
    tableInputErrors.push({
      tablerow: index,
      property: 'priceByLayer',
      message: `Produto ${offer.productDescription} -
        ${offer.productQualityDescription} deve ter pelo menos um dos preços informados`
    });
  }
  if (Number.isNaN(offer.exclusiveQuantity) || offer.exclusiveQuantity <= 0) {
    tableInputErrors.push({
      tablerow: index,
      property: 'exclusiveQuantity',
      message: `Quantidade exclusiva do ${offer.productDescription} - ${offer.productQualityDescription} em branco`
    });
  }
  if (offer.specialPrice && !offer.minimumQuantity) {
    tableInputErrors.push({
      tablerow: index,
      property: 'minimumQuantity',
      message: `Quantidade mínima do ${offer.productDescription} - ${offer.productQualityDescription} em branco`
    });
  }
  if (offer.minimumQuantity && !offer.specialPrice) {
    tableInputErrors.push({
      tablerow: index,
      property: 'specialPrice',
      message: `Preço especial do ${offer.productDescription} - ${offer.productQualityDescription} em branco`
    });
  }
  if (offer.productQualityDescription === 'A2' && offer.observation === '') {
    tableInputErrors.push({
      tablerow: index,
      property: 'observation',
      message: `Observação do ${offer.productDescription} - ${offer.productQualityDescription} em branco`
    });
  }
  return tableInputErrors;
};

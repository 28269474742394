import { call, fork, put, takeLatest } from 'redux-saga/effects';

import * as API from '~/domain/api';

import * as AnnouncementService from '~/store/modules/_core/announcement/service';
import {
  actions,
  ParamChangeStatus,
  ParamRequestAnnouncementList,
  Types
} from './duck';
import { Action } from '~/helpers/util/reducers';
import { APIError } from '~/domain/api/errors';
import { alertRequest } from '../../alert/actions';
import { generateErrorMessage } from '~/helpers/util/error';

export function* watchAnnouncementListSaga(
  action: Action<ParamRequestAnnouncementList>
) {
  yield fork(requestAnnouncementListSaga, action.payload);
}

export function* watchActiveOrInactiveAnnouncementSaga(
  action: Action<ParamChangeStatus>
) {
  yield fork(activeOrInactiveAnnouncementSaga, action.payload);
}

export function* requestAnnouncementListSaga(
  params: ParamRequestAnnouncementList
) {
  try {
    const response: API.ServerResponse = yield call(
      AnnouncementService.requestAnnouncementList,
      params
    );
    const data: API.DefaultResponse = response.data;

    const totalCount = data.totalCount ?? 0;
    yield put(
      actions.requestAnnouncementListSuccess({
        pageNumber: params.pageNumber,
        pageSize: params.pageSize,
        totalCount
      })
    );
  } catch (error: any) {
    if (error instanceof APIError) {
      yield put(
        actions.requestAnnouncementListFailure({ errorMessage: error.message })
      );
    } else {
      yield put(
        actions.requestAnnouncementListFailure({
          errorMessage: error?.response?.data[0]
        })
      );
    }
  }
}

export function* activeOrInactiveAnnouncementSaga({
  id,
  isActive
}: ParamChangeStatus) {
  try {
    yield call(AnnouncementService.activeOrInactiveAnnouncement, id);
    yield put(actions.activeOrInactiveAnnouncementSuccess());
    if (isActive) {
      yield put(alertRequest('Comunicado desativado com sucesso.', 'success'));
      yield put(actions.activeOrInactiveAnnouncementSuccess());
    } else {
      yield put(alertRequest('Comunicado ativado com sucesso.', 'success'));
      yield put(actions.activeOrInactiveAnnouncementSuccess());
    }
  } catch (error) {
    if (error instanceof APIError) {
      yield put(actions.activeOrInactiveAnnouncementFailure());
      yield put(alertRequest(generateErrorMessage(error.message), 'error'));
    } else {
      yield put(alertRequest('Não foi possível alterar o status.', 'error'));
      yield put(actions.activeOrInactiveAnnouncementFailure());
    }
  }
}

export default [
  takeLatest(Types.requestAnnouncementList, watchAnnouncementListSaga),
  takeLatest(
    Types.activeOrInactiveAnnouncement,
    watchActiveOrInactiveAnnouncementSaga
  )
];

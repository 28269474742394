import { call, put } from 'redux-saga/effects';

import * as API from '~/domain/api';
import { SalesReportLkp, SalesReportVol } from '~/domain/model';

import * as ReportService from '~/services/producer/Report';
import { actions, FetchSalesReport } from './duck';

export function* findReportSalesVol(params: FetchSalesReport) {
  const response: API.ServerResponse = yield call(
    ReportService.findReportSalesVol,
    params
  );
  const data: API.DefaultResponse = response.data;
  const salesReportVolResult: API.SalesReportVol[] = data.results ?? [];
  let salesReportVolList: SalesReportVol[] = [];
  if (salesReportVolResult && salesReportVolResult.length) {
    salesReportVolList = salesReportVolResult.map(
      (sale: API.SalesReportVol) => new SalesReportVol(sale)
    );
  }
  yield put(actions.update({ salesReportVolList }));
  return response;
}

export function* findReportSalesLkp(params: FetchSalesReport) {
  const response: API.ServerResponse = yield call(
    ReportService.findReportSalesLkp,
    params
  );
  const data: API.DefaultResponse = response.data;
  const salesReportLkpResult: API.SalesReportLkp[] = data.results ?? [];
  let salesReportLkpList: SalesReportLkp[] = [];
  if (salesReportLkpResult && salesReportLkpResult.length) {
    salesReportLkpList = salesReportLkpResult.map(
      (sale: API.SalesReportLkp) => new SalesReportLkp(sale)
    );
  }
  yield put(actions.update({ salesReportLkpList }));
  return response;
}

export function* fetchWorkSheetVol(id: number) {
  const response: API.ServerResponse = yield call(
    ReportService.fetchWorksheetVol,
    id
  );
  const json: API.ReportWorkSheet = response.data;
  downloadCSV(json.data, 'Relatório de Vendas ENP');
  return json;
}

export function* fetchWorkSheetLkp(id: number) {
  const response: API.ServerResponse = yield call(
    ReportService.fetchWorksheetLkp,
    id
  );
  const json: API.ReportWorkSheet = response.data;
  downloadCSV(json.data, 'Relatório de vendas LKP');
  return json;
}

export function downloadCSV(baseUrlData: string, nameFile: string): void {
  const binaryString = window.atob(baseUrlData);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  const fileData = bytes;

  const blob = new Blob([fileData], { type: 'application/octet-stream' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.style.display = 'none';
  const fileName = `${nameFile ?? 'default-name'}.xlsx`;
  link.download = fileName;
  link.click();
}
